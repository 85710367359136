import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { IN_PROCESS_KEY, MINDALAY_ADMIN, RESOLVED_KEY, TICKET_KEY } from "../../../../Constants/urlKeys";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from '../../../../Store/Actions/generalLeftMenu';

const TicketsSubMenu = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${TICKET_KEY}/${IN_PROCESS_KEY}`}
            className={props.location.pathname.includes(`/${IN_PROCESS_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_IN_PROCESS")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${TICKET_KEY}/${RESOLVED_KEY}`}
            className={props.location.pathname.includes(`/${RESOLVED_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_RESOLVED")}
          </NavLink>
        </li>

      </ul>
    </div>
  </aside> : null;
}

export default withRouter(TicketsSubMenu);