import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import Notification from "./SubComponents/notification";
import ProfileDropdown from "./SubComponents/profileDropdown";
import OrganizationUserDropdown from "./SubComponents/organizationUserDropdown";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import HelpSvg from "./../../../Components/Svg/helpSvg";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import {
	getHelpData,
	setBackUrl,
	showOrHideHelpAlert,
} from "../../../Store/Actions/main";
import Loader from "react-loader-spinner";
import { HELP_KEY } from "../../../Constants/urlKeys";
import HelpService from "../../../Services/helpService";
import Dropdown from "./../../../Components/Dropdown/dropdown";
import {
	ADMIN_USER_TYPE,
	LANGUAGE_KEY,
	NUMBER_KEY,
	REFRESH_TOKEN_KEY,
	STUDENT_USER_TYPE,
} from "../../../Constants/mainKeys";
import { setLanguage } from "../../../Store/Actions/language";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import GameSvg from "./../../../Components/Svg/gameSvg";
import MessagesSvg from './../../../Components/Svg/messagesSvg';

const OrganizationUserHeader = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { unreadMessgesCount } = useSelector(state => state.signalR);
	const {
		isShowQuestionMarkSpinner,
		isShowB2BWebinarNotification,
		isShowHelpAlert,
	} = useSelector(state => state.main);
	const { showOrHideGeneralLeftMenu } = useSelector(
		state => state.generalLeftMenu,
	);
	const { language, languages } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = () => {
		dispatch(showOrHideGeneralDashboardLeftMenu(!showOrHideGeneralLeftMenu));
	};

	const getHelpDataLocal = () => {
		if (isShowHelpAlert) {
			dispatch(showOrHideHelpAlert(false));
		} else {
			if (getHelpData(HelpService.setPageName(props.location.pathname))) {
				dispatch(getHelpData(HelpService.setPageName(props.location.pathname)));
				dispatch(showOrHideHelpAlert(true));
			}
		}
	};

	const changeLanguage = isocode => {
		if (isocode) {
			localStorage.setItem(LANGUAGE_KEY, isocode);
			setLanguageHandler(isocode);
			const newPath = props.location.pathname
				.toString()
				.replace(props.match.path.toString(), `/${isocode}`);
			props.history.push(newPath.toString());
		}
	};

	const setLanguageHandler = useCallback(language => {
		dispatch(setLanguage(language));
	}, []);

	const setUrl = () => {
		dispatch(setBackUrl(props.location.pathname));
	};

	return translationService ? (
		<Auxiliary>
			<header className="relative-layout admin-dashboard-header header instructor-header-zindex white-background">
				<nav
					className={`navbar navbar-expand-md navbar-light navbar-shadow d-flex justify-content-between instructor-consultant-navbar ${props.location.pathname.includes("/help")
							? ""
							: "justify-content-md-end"
						} `}>
					{!showOrHideGeneralLeftMenu ? (
						<Link
							to={`/${language}`}
							className="navbar-brand instructor-consultant-header-logo d-none"></Link>
					) : null}
					{props.location.pathname.includes("/help") ? (
						<Link
							to={`/${language}`}
							className="burger-btn open-burger-menu nav-link m-0 p-0 d-block">
							<MindalaySvg height={30} />
						</Link>
					) : (
						<Link
							to="#"
							className="burger-btn open-burger-menu nav-link mb-2 d-flex align-items-center d-block d-md-none"
							onClick={showOrHideLeftMenu}>
							<span></span>
							{isShowB2BWebinarNotification ? (
								<div className="circle-impulse circle-impulse d-block d-md-none" />
							) : null}
							{/* <div
								className="ml-3 d-block d-md-none"
								style={{
									fontSize: 16,
									fontWeight: 400,
									transform: "none",
									color: "var(--mindalay--brand-color)",
								}}>
								(Beta)
							</div> */}
						</Link>
					)}
					<div>
						<ul className="navbar-nav align-items-left align-items-lg-center flex-wrap">
							<li className="mx-1">
								<Dropdown
									className="language-dropdown-dark"
									items={languages}
									title={language.toUpperCase()}
									selected={changeLanguage}
								/>
							</li>
							{props.location.pathname.includes("/help") ? null : (
								<li className="nav-item not-hover position-relative dropdown header-dropdown d-none d-md-block">
									<div className="nav-item nav-item-help-organization">
										<Link
											className="nav-link"
											to={`/${language}/${HELP_KEY}`}
											onClick={setUrl}>
											{translationService.translate("TR_HELP")}
										</Link>
									</div>
								</li>
							)}
							{user.isGamifyAvailable &&
								(user.userTypeId === STUDENT_USER_TYPE ||
									user.userTypeId === ADMIN_USER_TYPE ||
									(user.defaultUserTypeIds &&
										user.defaultUserTypeIds.length &&
										user.defaultUserTypeIds.includes(ADMIN_USER_TYPE))) ? (
								<Auxiliary>
									<li className="nav-item not-hover position-relative dropdown header-dropdown ">
										<Link
											to={`/${language}/gamification/dashboard`}
											className="nav-link">
											<GameSvg className="org-student-header-gamify-icon" />
										</Link>
									</li>
								</Auxiliary>
							) : null}
							{isShowQuestionMarkSpinner ? (
								<li className="nav-item not-hover mr-2">
									<Loader type="Oval" color="#32064A" height={25} width={25} />
								</li>
							) : (
								<li className="nav-item not-hover">
									<Link to="#" className="nav-link" onClick={getHelpDataLocal}>
										<HelpSvg />
									</Link>
								</li>
							)}

							<li className="nav-item not-hover position-relative dropdown header-dropdown">
								<Notification />
							</li>
							<li
								title={translationService.translate("TR_MESSAGES")}
								className="nav-item not-hover position-relative dropdown header-dropdown">
								{/* <Messages /> */}
								<Link
									to={user && user.userTypeId === ADMIN_USER_TYPE ? `/${language}/organization-admin/messages` : `/${language}/message`}
									className={`nav-link ${typeof unreadMessgesCount === NUMBER_KEY && unreadMessgesCount > 0 ? "has-number" : ""}`}
									rel={typeof unreadMessgesCount === NUMBER_KEY && unreadMessgesCount}
								>
									<MessagesSvg />
								</Link>
							</li>
							<li className="nav-item navbar-profile-container header-dropdown">
								{user && user.isOrganizationUser ? (
									<OrganizationUserDropdown />
								) : (
									<ProfileDropdown />
								)}
							</li>
						</ul>
					</div>
				</nav>
			</header>
		</Auxiliary>
	) : null;
};

export default withRouter(OrganizationUserHeader);
