import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TranslationService from '../../../Services/translationService';
import BackSvg from "../../../Components/Svg/backSvg";
import CourseDashboardLeftMenu from '../LeftMenu/CourseDashboardLeftMenu';
import { COURSES_KEY, INSTRUCTOR_KEY, VIEW_KEY } from '../../../Constants/urlKeys';

const CourseHeader = () => {
    const currentCourse = useSelector(state => state.course.currentCourse);
    const language = useSelector(state => state.language.language);
    const courseData = useSelector(state => state.course.currentCourse);
    const translations = useSelector(state => state.translation.translations);
    const [translationService, setTranslationService] = useState(null);
    const [toggleBurgerMenu, setToggleBurgerMenu] = useState(false);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const convertSecondsToHoursAndMinutes = (d) => {
        if (translationService) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            var hDisplay = h > 0 ? h + `${translationService.translate("TR_SHORT_HOUR")}` + `${'\u00A0'}` : "";
            var mDisplay = m > 0 ? m + `${translationService.translate("TR_SHORT_MINUTE")}` + `${'\u00A0'}` : "";
            var sDisplay = s > 0 ? s + `${translationService.translate("TR_SHORT_SECOND")}` + `${'\u00A0'}` : "";
            return hDisplay + mDisplay + sDisplay;
        }
    }

    return translationService ? <header className="header course-header relative-layout">
        <nav className="navbar navbar-expand-md justify-content-md-between justify-content-end purple-background">
            <div className="d-md-flex d-none align-items-center justify-content-between w-100 header-white-content">
                <ul className="d-flex align-items-center my-1">
                    <li className="nav-item">
                        <Link
                            to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`}
                            className="nav-link--course-navbar nav-mindalay--btn-link--course-navbar">
                            <BackSvg />{translationService.translate("TR_BACK_TO_COURSES")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="#"
                            title={currentCourse.name}
                            className="nav-link--course-navbar course-title-in-header cursor-default"
                        >
                            {currentCourse.name}
                        </Link>
                    </li>
                    {
                        courseData && courseData.published ?
                            <li className="nav-item course-status--course-header">
                                <Link to="#" className="nav-link--course-navbar text-uppercase secondary-color cursor-default">
                                    {translationService.translate("TR_LIVE")}
                                </Link>
                            </li>
                            : null
                    }
                </ul>
                <ul className="my-1">
                    <li className="nav-item">
                        {
                            courseData.allVideosDuration ?
                                <span className="course-ducarion">{convertSecondsToHoursAndMinutes(courseData.allVideosDuration)}</span>
                                : `0 ${translationService.translate("TR_MIN")} `
                        }
                        {translationService.translate("TR_OF_VIDEO_UPLOADED")}
                    </li>
                </ul>
            </div>
            <div className="d-md-none d-block w-100">
                <ul className="my-1 d-flex align-items-center justify-content-between w-100">
                    <li className="nav-item">
                        <Link to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`} className="nav-link--course-navbar nav-mindalay--btn-link--course-navbar">
                            <BackSvg />{translationService.translate("TR_BACK_TO_COURSES")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="#" className="burger-btn open-course-burger-menu course-burger nav-link m-0" onClick={() => setToggleBurgerMenu(true)}>
                            <span></span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className={`burger-menu-container side-menu ${toggleBurgerMenu ? "toggle-burger" : ""}`}>
                <div className={`burger-menu-body ${toggleBurgerMenu ? "toggle-burger-menu" : ""}`}>
                    <CourseDashboardLeftMenu wrapperClasses="px-3 py-2" setToggleBurgerMenu={setToggleBurgerMenu} />
                    <div className="right-close-btn side-menu-close-btn close-side-menu burger-close-button-scala" onClick={() => setToggleBurgerMenu(false)}>
                        <span></span>
                    </div>
                </div>
            </div>
        </nav>
    </header > : null;
}

export default CourseHeader;
