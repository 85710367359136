import { SET_FAQ_PAGE_CONTENT, SET_PAGE_CONTENT, SET_TOPIC_PAGE_CONTENT, SET_IS_SHOW_HELP_LEFT_MENU } from "../../Constants/reduxKeys";

let initialState = {
  faqPageContent: null,
  topicPageContent: null,
  pageContent: null,
  isShowHelpLeftMenu: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAQ_PAGE_CONTENT:
      return {
        ...state,
        faqPageContent: action.payload,
      };
    case SET_TOPIC_PAGE_CONTENT:
      return {
        ...state,
        topicPageContent: action.payload
      };
    case SET_PAGE_CONTENT:
      return {
        ...state,
        pageContent: action.payload
      };
    case SET_IS_SHOW_HELP_LEFT_MENU:
      return {
        ...state,
        isShowHelpLeftMenu: action.payload
      };
    default:
      break;
  }
  return state;
};

export default reducer;