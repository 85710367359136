import { NUMBER_KEY, SHOPPING_CART_COUNT_KEY, WISH_LIST_COUNT_KEY } from "../../Constants/mainKeys";
import {
  CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT,
  CHANGE_SHOPPING_CART_COUNT,
  CHANGE_WISH_LIST_COUNT,
} from "../../Constants/reduxKeys";

let initialState = {
  wishListCount: null,
  shoppingCartCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT:
      if (typeof +action.payload.shoppingCartCount === NUMBER_KEY) { localStorage.setItem(SHOPPING_CART_COUNT_KEY, action.payload.shoppingCartCount) }
      if (typeof +action.payload.wishListCount === NUMBER_KEY) { localStorage.setItem(WISH_LIST_COUNT_KEY, action.payload.wishListCount) }
      return {
        ...state,
        wishListCount: typeof action.payload.wishListCount === NUMBER_KEY && action.payload.wishListCount,
        shoppingCartCount: typeof action.payload.shoppingCartCount && action.payload.shoppingCartCount,
      };
    case CHANGE_SHOPPING_CART_COUNT:
      if (typeof +action.payload.shoppingCartCount === NUMBER_KEY) { localStorage.setItem(SHOPPING_CART_COUNT_KEY, action.payload.shoppingCartCount) }
      return {
        ...state,
        shoppingCartCount: typeof action.payload.shoppingCartCount && action.payload.shoppingCartCount,
      };
    case CHANGE_WISH_LIST_COUNT:
      if (typeof +action.payload.wishListCount === NUMBER_KEY) { localStorage.setItem(WISH_LIST_COUNT_KEY, action.payload.wishListCount) }
      return {
        ...state,
        wishListCount: typeof action.payload.wishListCount === NUMBER_KEY && action.payload.wishListCount,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
