import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { compose } from 'redux';
import { ERROR_KEY, SUCCESS_KEY, TR_DELETE_CONFIRM_MESSAGE_KEY, TR_NO, TR_YES } from '../../Constants/mainKeys';
import { SEARCH_KEY } from '../../Constants/requestKeys';
import { SPECIALITY_KEY } from '../../Constants/urlKeys';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { addPartialViewSpinner, removePartialViewSpinner, addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import ApiService from './../../Services/apiService';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import ReactPaginate from 'react-paginate';
import InputCheckBox from '../../Components/Inputs/inputCheckBox';
import ActionButton from '../../Components/ActionButton/actionButton';

class KeyWords extends Component {

  state = {
    translationService: null,
    keyWords: [],
    activePageNumber: 0,
    pageSize: 10,
    pagination: null,
    spinnerId: uuid(),
    selectedKeyWordsArray: [],
    selectedAll: false
  }

  componentDidMount() {
    this.setTranslations();
    this.getKeyWords(1);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ activePageNumber: 0, selectedAll: false, selectedKeyWordsArray: [] }, () => this.getKeyWords(1));
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  handlePageClick = (event) => {
    this.getKeyWords(event.selected + 1);
    this.setState({ activePageNumber: event.selected, selectedAll: false });
  }

  getKeyWords = (currentPage) => {
    if (this.props.location.pathname.includes(`/${SPECIALITY_KEY}`)) {
      this.setState({ keyWords: [] }, () => {
        this.getSpecialityKeyWords(currentPage);
      })
    }
    if (this.props.location.pathname.includes(`/${SEARCH_KEY}`)) {
      this.setState({ keyWords: [] }, () => {
        this.getSearchKeyWords(currentPage);
      })
    }
  }

  getSpecialityKeyWords = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getSpecialityKeyWords(currentPage, pageSize).then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response && response.data && response.pagination) {
        const data = [...response.data]
        data.forEach(keyWord => keyWord.checked = false)
        this.setState({ keyWords: data, pagination: JSON.parse(response.pagination) })
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  getSearchKeyWords = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getSearchKeyWords(currentPage, pageSize).then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response && response.data && response.pagination) {
        const data = [...response.data]
        data.forEach(keyWord => keyWord.checked = false)
        this.setState({ keyWords: data, pagination: JSON.parse(response.pagination) })
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  confirmKeyWord = (event) => {
    const { translationService } = this.state;
    if (!event) { return; }
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE_CONFIRM")} "${event.keyword ? event.keyword : event.displayName}" ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      if (this.props.location.pathname.includes(`/${SPECIALITY_KEY}`)) {
        this.confirmSpecialityKeyWord(event.id)
      }
      if (this.props.location.pathname.includes(`/${SEARCH_KEY}`)) {
        this.confirmSearchKeyWord(event.id)
      }
    })
  }

  declineKeyWord = (event) => {
    const { translationService } = this.state;
    if (!event) { return; }
    AlertService.alertConfirm(
      `${translationService.translate("TR_DECLINE_CONFIRM_MESSAGE")} "${event.keyword ? event.keyword : event.displayName}" ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      if (this.props.location.pathname.includes(`/${SPECIALITY_KEY}`)) {
        this.declineSpecialityKeyWord(event.id)
      }
      if (this.props.location.pathname.includes(`/${SEARCH_KEY}`)) {
        this.declineSearchKeyWord(event.id)
      }
    })
  }

  confirmSpecialityKeyWord = (id) => {
    var { keyWords, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.confirmSpecialityKeyWord(id).then(() => {
      this.props.removePartialViewSpinner(spinnerId);
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
      keyWords = keyWords.filter(keyWord => keyWord.id !== id);
      this.setState({ keyWords });
    }).catch(error => this.getFail(error, spinnerId));
  }

  confirmSearchKeyWord = (id) => {
    var { keyWords, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.confirmSearchKeyWord(id).then(() => {
      this.props.removePartialViewSpinner(spinnerId);
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
      keyWords = keyWords.filter(keyWord => keyWord.id !== id);
      this.setState({ keyWords });
    }).catch(error => this.getFail(error, spinnerId));
  }

  declineSpecialityKeyWord = (id) => {
    var { keyWords, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.declineSpecialityKeyWord(id).then(() => {
      this.props.removePartialViewSpinner(spinnerId);
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
      keyWords = keyWords.filter(keyWord => keyWord.id !== id);
      this.setState({ keyWords });
    }).catch(error => this.getFail(error, spinnerId));
  }

  declineSearchKeyWord = (id) => {
    var { keyWords, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.declineSearchKeyWord(id).then(() => {
      this.props.removePartialViewSpinner(spinnerId);
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
      keyWords = keyWords.filter(keyWord => keyWord.id !== id);
      this.setState({ keyWords });
    }).catch(error => this.getFail(error, spinnerId));
  }

  onSelectKeyWord = (id) => {
    if (!id) { return; }
    const { keyWords, selectedKeyWordsArray } = this.state;
    const updatedKeyWords = [...keyWords];
    updatedKeyWords.forEach(keyWord => {
      if (keyWord.id === id) {
        keyWord.checked = !keyWord.checked;
        if (keyWord.checked) {
          keyWord.checked && selectedKeyWordsArray.push(id)
          this.setState({ selectedKeyWordsArray })
        } else {
          const updatedSelectedKeyWordsArray = selectedKeyWordsArray.filter(selectedKeyword => selectedKeyword !== id)
          this.setState({ selectedKeyWordsArray: updatedSelectedKeyWordsArray })
        }
      }
    })
    this.setState({ keyWords: updatedKeyWords })
  }

  onSelectAllKeyWords = (selectedAll) => {
    const { keyWords, selectedKeyWordsArray } = this.state;
    if (!selectedAll) {
      keyWords.forEach(keyWord => {
        keyWord.checked = true;
        selectedKeyWordsArray.push(keyWord.id);
      })
    } else {
      keyWords.forEach(keyWord => {
        keyWord.checked = false;
        selectedKeyWordsArray.length = 0;
      })
    }
    this.setState({ keyWords, selectedKeyWordsArray, selectedAll: !selectedAll });
  }

  onBulkRemove = () => {
    const { selectedKeyWordsArray, spinnerId, translationService } = this.state;
    var isInSpecialitiesPage = this.props.location.pathname.includes(`/${SPECIALITY_KEY}`)
    if (!selectedKeyWordsArray.length) { return; }
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${translationService.translate("TR_SELECTED_FIELDS")}?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)).then(() => {
        this.props.addButtonSpinner(spinnerId);
        (isInSpecialitiesPage ?
          ApiService.buldRemoveSpecialities(selectedKeyWordsArray)
          : ApiService.buldRemoveKeyWords(selectedKeyWordsArray))
          .then(() => {
            isInSpecialitiesPage ? this.getSpecialityKeyWords(1) : this.getSearchKeyWords(1);
            AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SELECTED_FIELDS_SUCCESSFULLY_DELETED"))
            this.props.removeButtonSpinner(spinnerId);
            this.setState({ selectedAll: false });
          }).catch(error => this.getFail(error, spinnerId))
      })
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
    spinnerId && this.props.removeButtonSpinner(spinnerId);
  }

  render() {
    const {
      translationService,
      keyWords,
      activePageNumber,
      pagination,
      spinnerId,
      selectedKeyWordsArray,
      selectedAll
    } = this.state;

    const isSpecialtyWordsPage = this.props.location.pathname.includes(`/${SPECIALITY_KEY}`) ? true : false;

    let fileLibraryColumns;

    if (translationService && this.props.location.pathname.includes(`/${SPECIALITY_KEY}`)) {
      fileLibraryColumns = [
        {
          name: translationService.translate("TR_KEY_WORD"),
          selector: 'displayName',
        },
        {
          name: `${translationService.translate("TR_CONFIRM")} / ${translationService.translate("TR_REMOVE")}`,
          selector: 'confirmdecline',
        },
      ];
    };

    if (translationService && this.props.location.pathname.includes(`/${SEARCH_KEY}`)) {
      fileLibraryColumns = [
        {
          name: translationService.translate("TR_KEY_WORD"),
          selector: 'keyword',
        },
        {
          name: translationService.translate("TR_SEARCH_COUNT"),
          selector: 'count',
        },
        {
          name: `${translationService.translate("TR_CONFIRM")} / ${translationService.translate("TR_REMOVE")}`,
          selector: 'confirmdecline',
        },
      ];
    };

    if (keyWords && keyWords.length && translationService) {
      keyWords.forEach(keyWord => {
        keyWord.confirmdecline = (() => {
          return <div className="d-flex">
            <button
              className="mindalay--btn mindalay--btn-default float-right mr-2"
              onClick={() => this.confirmKeyWord(keyWord)}
            >
              {translationService.translate("TR_CONFIRM")}
            </button>
            <button
              className="mindalay--btn mindalay--btn-default float-left ml-2"
              onClick={() => this.declineKeyWord(keyWord)}
            >
              {translationService.translate("TR_REMOVE")}
            </button>
          </div>
        })()
      });
    };

    return (
      translationService ?
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                isSpecialtyWordsPage
                  ? <h2 className="content-title p-0">{translationService.translate("TR_SPECIALITY_KEY_WORDS")}</h2>
                  : <h2 className="content-title p-0">{translationService.translate("TR_SEARCH_KEY_WORDS")}</h2>
              }
              <hr />
            </div>
          </div>
          {
            keyWords && keyWords.length && pagination ?
              <div className="row">
                <div className="col-12">
                  <div className="mindayal-admin-table table-responsive">
                    <table id='table' className="table">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <InputCheckBox
                              id={"All"}
                              checked={selectedAll}
                              labelValue={" "}
                              blockClassName="mb-0"
                              onChange={() => this.onSelectAllKeyWords(selectedAll)}
                            />
                          </th>
                          <th>{translationService.translate("TR_KEY_WORD")}</th>
                          {
                            !isSpecialtyWordsPage ? <th>{translationService.translate("TR_SEARCH_COUNT")}</th> : null
                          }
                          <th>{`${translationService.translate("TR_CONFIRM")} / ${translationService.translate("TR_REMOVE")}`}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          keyWords.map((keyWord, index) => {
                            return <tr key={index} className="cursor-default">
                              <td>
                                <InputCheckBox
                                  id={index}
                                  checked={keyWord.checked}
                                  blockClassName="keyword-ckeckbox-block"
                                  labelValue={" "}
                                  onChange={() => this.onSelectKeyWord(keyWord.id)}
                                />
                              </td>
                              <td><div className="mindalay-admin-table-td-item">{keyWord.displayName ? keyWord.displayName : keyWord.keyword}</div></td>
                              {
                                !isSpecialtyWordsPage ?
                                  <td><div className="mindalay-admin-table-td-item">{keyWord.count}</div></td>
                                  : null
                              }
                              <td><div className="mindalay-admin-table-td-item">
                                <div className="d-flex">
                                  <button
                                    className="mindalay--btn mindalay--btn-default float-right mr-2"
                                    onClick={() => this.confirmKeyWord(keyWord)}
                                  >
                                    {translationService.translate("TR_CONFIRM")}
                                  </button>
                                  <button
                                    className="mindalay--btn mindalay--btn-default float-left ml-2"
                                    onClick={() => this.declineKeyWord(keyWord)}
                                  >
                                    {translationService.translate("TR_REMOVE")}
                                  </button>
                                </div>
                              </div></td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              : <div className="no-data-container">
                <div className="no-data-wrapper">
                  <p>{translationService.translate("TR_NO_DATA")}</p>
                  <img src={NoDataImage} alt="/" />
                </div>
              </div>
          }
          {
            pagination ?
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <ReactPaginate
                      nextLabel={translationService.translate("TR_NEXT")}
                      onPageChange={this.handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pagination.TotalPages}
                      previousLabel={translationService.translate("TR_PREVIOUS")}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      forcePage={activePageNumber}
                    />
                    {
                      selectedKeyWordsArray.length ?
                        <ActionButton
                          type="button"
                          spinnerId={spinnerId}
                          clicked={this.onBulkRemove}
                          className="mindalay--btn-default position-relative"
                          name={translationService.translate("TR_REMOVE_SELECTED")}
                        />
                        : null
                    }
                  </div>
                </div>
              </div>
              : null
          }
        </div>
        : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  addButtonSpinner,
  removeButtonSpinner,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(KeyWords);

