import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";
import {
	ERROR_KEY,
	STRING_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../Constants/mainKeys";
import AlertService from "../Services/alertService";
import TranslationService from "../Services/translationService";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	addButtonSpinner,
	removeButtonSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import Input from "../Components/Inputs/input";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import { addModalSpinner } from "./../Store/Actions/spinner";
import ModalComponent from "./../Components/ModalComponent/modalComponent";
import Textarea from "../Components/Inputs/textArea";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import ModalComponentWithoutActionButton from "../Components/ModalComponent/modalComponentWithoutActionButton";
import RichTextEditor from "../Pages/Questions/Components/RichTextEditor";
import ActionButton from "../Components/ActionButton/actionButton";
import Parser from "html-react-parser";
import ParserService from "../Services/parserService";
import ParserComponent from "../Components/ParserComponent/ParserComponent";

const libraryTypes = [
	{ id: 0, value: 0, label: "Text" },
	{ id: 1, value: 1, label: "Single list" },
	{ id: 2, value: 2, label: "Multilist" },
	{ id: 3, value: 3, label: "Radio list" },
	{ id: 4, value: 4, label: "Checkbox list" },
];

const buttonSpinnerId = uuid();

const Library = () => {
	const dispatch = useDispatch();
	const pageSize = 10;
	const translations = useSelector(state => state.translation.translations);
	const { languages, language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [library, setLibrary] = useState([]);
	const [libraryById, setLibraryById] = useState([]);
	const [librarydataByLibId, setLibrarydataByLibId] = useState([]);
	const [tabKey, setTabKey] = useState("en");
	const [searchText, setSearchText] = useState("");
	const [search, setSearch] = useState();
	const [openModal, setOpenModal] = useState(false);
	const [openChildrenModal, setOpenChildrenModal] = useState(null);
	const [isChange, setIsChange] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isShowForm, setIsShowForm] = useState(false);
	const [values, setValues] = useState({
		displayString: "",
		languageId: 1,
		name: "",
		parentId: null,
		type: null,
	});
	const [valuesChildrenModal, setValuesChildrenModal] = useState({
		description: null,
		displayName: "",
		languageId: null,
		libraryId: null,
		name: "",
		parentId: null,
	});

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getLibraryDataByIso(language);
	}, []);

	const getLibraryDataByIsoAndLibId = libId => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getLibraryDataByIsoAndLibId(tabKey, libId)
			.then(response => {
				const data = [...response.data];
				setLibraryById(data);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const getLibrarydataByLibId = libId => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getLibrarydataByLibId(libId)
			.then(response => {
				const data = [...response.data];
				setLibrarydataByLibId(data);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const getLibraryDataByIso = isocode => {
		if (!isocode) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getLibraryDataByIso(isocode)
			.then(response => {
				const data = [...response.data];
				setLibrary(data);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onEditorChange = event => {
		const newRichText = event.editor.getData();
		setValuesChildrenModal(values => ({ ...values, description: newRichText }));
	};

	const onChange = (event, fieldname, cb, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		cb(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsChange(true);
		setIsInvalidSubmit(false);
	};

	const onSelectOptionChange = (item, fieldName, cb) => {
		if (!item) {
			return false;
		}
		setIsChange(true);
		setIsInvalidSubmit(false);
		cb(values => ({
			...values,
			[fieldName]: item.value,
		}));
	};

	const onSubmit = e => {
		e.preventDefault();
		if (
			!valuesChildrenModal.name.trim().length ||
			!valuesChildrenModal.displayName.trim().length
		) {
			setIsInvalidSubmit(true);
			return;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		(!valuesChildrenModal?.id
			? ApiService.createLibrarydata(valuesChildrenModal)
			: ApiService.updateLibrarydata(valuesChildrenModal)
		)
			.then(response => {
				if (response.data) {
					setLibraryById([response.data, ...libraryById]);
				}
				if (valuesChildrenModal?.id) {
					let data = libraryById.map(el => {
						if (el.id === valuesChildrenModal.id) {
							return { ...el, ...valuesChildrenModal };
						}
						return el;
					});

					setLibraryById(data);
					// setUserTypes([...userTypes, response.data]);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setIsShowForm(false);
				setValuesChildrenModal({
					description: null,
					displayName: "",
					languageId: null,
					libraryId: null,
					name: "",
					parentId: null,
				});
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const onClose = (close = true) => {
		setValues({
			displayString: "",
			languageId: null,
			name: "",
			parentId: null,
			type: null,
		});
		setValuesChildrenModal({
			description: null,
			displayName: "",
			languageId: null,
			libraryId: null,
			name: "",
			parentId: null,
		});
		setOpenChildrenModal(null);
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
		setIsShowForm(false);
		setLibraryById([]);
		setLibrarydataByLibId([]);
	};

	const updateLibrary = item => {
		if (!item) return null;
		setValues(item);
		setOpenModal(true);
	};

	const getChildren = item => {
		if (!item) return false;
		getLibraryDataByIsoAndLibId(item.id);
		if (item.parentId) {
			getLibrarydataByLibId(item.parentId);
		}
		setOpenChildrenModal(item);
	};

	const onSave = () => {
		const spinerID = uuid();
		if (
			!values.name.trim().length ||
			!values.displayString.trim().length ||
			values.type === null
		) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createLibrary(values)
			: ApiService.updateLibrary(values)
		)
			.then(response => {
				if (response.data) {
					setLibrary([response.data, ...library]);
				}
				if (values?.id) {
					setLibrary(
						library.map(el => {
							if (el.id === values.id) {
								return { ...el, ...values };
							}
							return el;
						}),
					);
					// setUserTypes([...userTypes, response.data]);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const deleteLibrarydata = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteLibrarydata(item.id)
				.then(() => {
					setLibraryById(libraryById.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	const deleteLibrary = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteLibrary(item.id)
				.then(() => {
					setLibrary(library.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	return translationService && languages ? (
		<div className="container mt-4">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave();
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<Input
								type="text"
								id="name"
								name="name"
								inputClassName="pr--5"
								value={values.name}
								fieldLength={40}
								isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
								labelValue={`${translationService.translate("TR_NAME")}*`}
								onChange={event => onChange(event, "name", setValues, 40)}
							/>
						</div>
						<div className="col-12">
							<Input
								type="text"
								id="displayString"
								name="displayString"
								inputClassName="pr--5"
								value={values.displayString}
								fieldLength={40}
								isInvalidSubmit={
									isInvalidSubmit && !values.displayString.trim().length
								}
								labelValue={`${translationService.translate(
									"TR_DISPLAY_STRING",
								)}*`}
								onChange={event =>
									onChange(event, "displayString", setValues, 40)
								}
							/>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>{translationService.translate("TR_LANGUAGES")}</label>
								<ReactSelectOption
									value={values.languageId}
									selectedValue={(() => {
										const selectedItem = {
											...languages.find(
												data => data.languageId === values.languageId,
											),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label = selectedItem.name;
											selectedItem.value = selectedItem.languageId;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={languages
										.filter(item => item.id !== values.languageId)
										.map(data => ({
											...data,
											label: data.name,
											value: data.languageId,
										}))}
									onChange={item =>
										onSelectOptionChange(item, "languageId", setValues)
									}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>
									{translationService.translate("TR_ELEMENT_TYPE")}*
								</label>
								<ReactSelectOption
									value={values.type}
									isInvalidSubmit={isInvalidSubmit}
									selectedValue={(() => {
										const selectedItem = {
											...libraryTypes.find(data => data.id === values.type),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label = selectedItem.label;
											selectedItem.value = selectedItem.id;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={libraryTypes
										.filter(item => item.id !== values.type)
										.map(data => ({
											...data,
											label: data.label,
											value: data.id,
										}))}
									onChange={item =>
										onSelectOptionChange(item, "type", setValues)
									}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>{translationService.translate("TR_PARENT")}</label>
								<ReactSelectOption
									value={values.parentId}
									selectedValue={(() => {
										const selectedItem = {
											...library.find(data => data.id === values.parentId),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label = selectedItem.name;
											selectedItem.value = selectedItem.id;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={library
										.filter(item => item.id !== values.parentId)
										.map(data => ({
											...data,
											label: data.name,
											value: data.id,
										}))}
									onChange={item =>
										onSelectOptionChange(item, "parentId", setValues)
									}
								/>
							</div>
						</div>
					</div>
				</ModalComponent>
			)}
			{openChildrenModal && (
				<ModalComponentWithoutActionButton
					isLarge={true}
					title={openChildrenModal?.name}
					cancel={onClose}>
					<div className="row">
						<div className="col-12">
							<div className="col-12 d-flex justify-content-end">
								<button
									className="mindalay--btn-default"
									onClick={() => {
										if (isShowForm) {
											setIsShowForm(false);
											setValuesChildrenModal({
												description: null,
												displayName: "",
												languageId: null,
												libraryId: null,
												name: "",
												parentId: null,
											});
										} else {
											setIsShowForm(true);
											setValuesChildrenModal(values => ({
												...values,
												description: "",
												languageId: languages?.find(
													el => el.isocode2 === tabKey,
												)?.languageId,
												libraryId: openChildrenModal.id,
											}));
										}
									}}>
									{!isShowForm ? "Create library" : "Close form"}
								</button>
							</div>
							{isShowForm && (
								<form className="row" onSubmit={onSubmit}>
									<div className="col-12 col-md-6">
										<Input
											type="text"
											id="name"
											name="name"
											inputClassName=""
											value={valuesChildrenModal.name}
											isInvalidSubmit={
												isInvalidSubmit &&
												!valuesChildrenModal.name.trim().length
											}
											labelValue={`${translationService.translate("TR_NAME")}*`}
											onChange={event =>
												onChange(event, "name", setValuesChildrenModal)
											}
										/>
									</div>
									<div className="col-12">
										<Textarea
											rows="3"
											id="displayName"
											name="displayName"
											textAreaClassName=""
											value={valuesChildrenModal.displayName}
											isInvalidSubmit={
												isInvalidSubmit &&
												!valuesChildrenModal.displayName.trim().length
											}
											labelValue={`${translationService.translate(
												"TR_DISPLAY_STRING",
											)}*`}
											onChange={event =>
												onChange(event, "displayName", setValuesChildrenModal)
											}
										/>
									</div>
									<div className="col-12">
										{typeof valuesChildrenModal.description === STRING_KEY ? (
											<>
												<label>
													{translationService.translate("TR_DESCRIPTION")}
												</label>
												<RichTextEditor
													value={valuesChildrenModal.description}
													config={{
														toolbar: [
															["Bold", "Italic", "Strike", "-", "RemoveFormat"],
															["Undo", "Redo"],
															[
																"NumberedList",
																"BulletedList",
																"-",
																"Outdent",
																"Indent",
																"-",
																"Blockquote",
															],
															["Styles", "Format"],
															// ['Maximize']
														],
													}}
													onEditorChange={event => onEditorChange(event)}
												/>
											</>
										) : null}
									</div>
									{librarydataByLibId && !!librarydataByLibId.length && (
										<div className="col-12">
											<div className="form-group">
												<label>
													{translationService.translate("TR_PARENT")}
												</label>
												<ReactSelectOption
													value={valuesChildrenModal.parentId}
													selectedValue={(() => {
														const selectedItem = {
															...librarydataByLibId.find(
																data =>
																	data.id === valuesChildrenModal.parentId,
															),
														};
														if (Object.keys(selectedItem).length) {
															selectedItem.label = selectedItem.name;
															selectedItem.value = selectedItem.id;
															return selectedItem;
														} else {
															return {
																value: null,
																label:
																	translationService.translate("TR_CHOOSE"),
															};
														}
													})()}
													items={librarydataByLibId
														.filter(
															item => item.id !== valuesChildrenModal.parentId,
														)
														.map(data => ({
															...data,
															label: data.name,
															value: data.id,
														}))}
													onChange={item =>
														onSelectOptionChange(
															item,
															"parentId",
															setValuesChildrenModal,
														)
													}
												/>
											</div>
										</div>
									)}
									<div className="col-12">
										<hr />
									</div>
									<div className="col-12">
										<div>
											<ActionButton
												spinnerId={buttonSpinnerId}
												type="submit"
												className="mindalay--btn-default float-right mb-4 px-5"
												name={translationService.translate("TR_SAVE")}
											/>
										</div>
									</div>
								</form>
							)}
							{libraryById && libraryById.length ? (
								<div className="mt-4">
									<div className="mindayal-admin-table table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="text-nowrap">
														{translationService.translate("TR_NAME")}
													</th>
													<th className="text-nowrap">
														{translationService.translate("TR_DISPLAY_STRING")}
													</th>
													<th className="text-nowrap">
														{translationService.translate("TR_DESCRIPTION")}
													</th>
													<th className="text-nowrap">
														{translationService.translate("TR_ACTION")}
													</th>
												</tr>
											</thead>
											<tbody>
												{libraryById.map(item => {
													return (
														<tr key={item.id} className="">
															<td>
																<div className="mindalay-admin-table-td-item">
																	{item.name}
																</div>
															</td>
															<td>
																<div
																	className="mindalay-admin-table-td-item"
																	title={item.displayName}>
																	{item.displayName}
																</div>
															</td>
															<td>
																<div className="mindalay-admin-table-td-item">
																	{item.description ? (
																		<ParserComponent text={item.description} />
																	) : null}
																</div>
															</td>
															<td>
																<div className="table-action-btn-group d-flex align-items-center">
																	<>
																		<Link
																			to={`#`}
																			onClick={e => {
																				if (isShowForm) {
																					setValuesChildrenModal(values => ({
																						...values,
																						description: null,
																					}));
																				}
																				let values = {
																					languageId: languages?.find(
																						el => el.isocode2 === tabKey,
																					)?.isocode2,
																					libraryId: openChildrenModal.parentId,
																					id: item.id,
																				};
																				Object.keys(
																					valuesChildrenModal,
																				).forEach(key => {
																					if (key === "description") {
																						values[key] = item[key]
																							? item[key]
																							: "";
																					} else {
																						values[key] = item[key];
																					}
																				});
																				setValuesChildrenModal(values);
																				setIsShowForm(true);
																			}}
																			title={translationService.translate(
																				"TR_EDIT",
																			)}
																			className="table-action-btn edit-btn">
																			<EditSvg />
																		</Link>
																		<Link
																			to="#"
																			title={translationService.translate(
																				"TR_DELETE",
																			)}
																			className="table-action-btn delete-btn"
																			onClick={e => {
																				deleteLibrarydata(item);
																			}}>
																			<DeleteSvg />
																		</Link>
																	</>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							) : searchText ? (
								<div className="no-data-container mt-3">
									<div className="no-data-wrapper">
										<p>{translationService.translate("TR_NO_DATA")}</p>
										<img src={NoDataImage} alt="/" />
									</div>
								</div>
							) : null}
						</div>
					</div>
				</ModalComponentWithoutActionButton>
			)}
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_LIBRARY")}
					</h2>
					<hr />
				</div>
			</div>
			<div className="row align-items-center justify-content-end">
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default"
						onClick={() => {
							setOpenModal(true);
						}}>
						Create library
					</button>
				</div>
			</div>
			{languages && languages.length ? (
				<div className="row">
					<div className="col-12">
						<div className="tab-title-group tab-content">
							<Tabs
								activeKey={tabKey}
								onSelect={key => {
									if (key !== tabKey) {
										setTabKey(key);
										getLibraryDataByIso(key);
									}
								}}>
								{languages.map(language => {
									return (
										<Tab
											key={language.languageId}
											eventKey={language.isocode2}
											title={language.name}>
											{library && library.length ? (
												<div className="mt-4">
													<div className="mindayal-admin-table table-responsive">
														<table className="table">
															<thead>
																<tr>
																	<th className="text-nowrap">
																		{translationService.translate("TR_NAME")}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate(
																			"TR_DISPLAY_STRING",
																		)}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate(
																			"TR_ELEMENT_TYPE",
																		)}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate("TR_PARENT")}
																	</th>
																	<th className="text-nowrap">
																		{translationService.translate("TR_ACTION")}
																	</th>
																</tr>
															</thead>
															<tbody>
																{library.map(item => {
																	return (
																		<tr
																			key={item.id}
																			onClick={() => {
																				getChildren(item);
																			}}
																			className="">
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{item.name}
																				</div>
																			</td>
																			<td>
																				<div
																					className="mindalay-admin-table-td-item"
																					title={item.displayString}>
																					{item.displayString}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{item.type !== null &&
																						libraryTypes[item.type].label}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{
																						library.find(
																							el => el.id === item.parentId,
																						)?.name
																					}
																				</div>
																			</td>
																			<td>
																				<div className="table-action-btn-group d-flex align-items-center">
																					<>
																						<Link
																							to={`#`}
																							onClick={e => {
																								e.stopPropagation();
																								updateLibrary(item);
																							}}
																							title={translationService.translate(
																								"TR_EDIT",
																							)}
																							className="table-action-btn edit-btn">
																							<EditSvg />
																						</Link>
																						<Link
																							to="#"
																							title={translationService.translate(
																								"TR_DELETE",
																							)}
																							className="table-action-btn delete-btn"
																							onClick={e => {
																								e.stopPropagation();
																								deleteLibrary(item);
																							}}>
																							<DeleteSvg />
																						</Link>
																					</>
																				</div>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													</div>
												</div>
											) : searchText ? (
												<div className="no-data-container mt-3">
													<div className="no-data-wrapper">
														<p>{translationService.translate("TR_NO_DATA")}</p>
														<img src={NoDataImage} alt="/" />
													</div>
												</div>
											) : null}
										</Tab>
									);
								})}
							</Tabs>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default Library;
