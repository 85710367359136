import React, { useEffect, useMemo, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import uuid from "react-uuid";
import AlertService from "../Services/alertService";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import SettingsSvg from "../Components/Svg/settingsSvg";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import ModalComponent from "../Components/ModalComponent/modalComponent";

const selectOption = [{}];

function Categorys(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [categories, setCategories] = useState([]);
	const [ids, setIds] = useState([]);
	const [search, setSearch] = useState("");
	const [categorieId, setCategorieId] = useState(null);
	const [values, setValues] = useState({
		name: "",
		parentId: null,
		parentName: "",
	});
	const [openModal, setOpenModal] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isChange, setIsChange] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getCategories();
	}, []);

	const selectOption = useMemo(() => {
		if (translationService) {
			return [
				{
					label: translationService.translate("TR_ALL"),
					value: null,
				},
				{
					label: translationService.translate("TR_QUIZ_REVIEW_OPTIONS"),
					value: 0,
				},
				{
					label: translationService.translate("TR_COURSE_CATEGORIES"),
					value: 2,
				},
			];
		}
		return [
			{
				label: "All",
				value: null,
			},
			{
				label: "Quiz review optiones",
				value: 0,
			},
			{
				label: "Course categories",
				value: 2,
			},
		];
	}, [translationService]);

	const getCategories = (type = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCategories(type)
			.then(response => {
				setCategories(response.data);
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onChangeIds = id => {
		if (ids.includes(id)) {
			const newIds = ids.filter(el => el !== id);
			setIds(newIds);
		} else {
			setIds([...ids, id]);
		}
	};

	const onSelectOptionChange = (item, name) => {
		if (name === "categorieId") {
			setCategorieId(item.value);
			getCategories(item.value);
			return;
		}
	};

	const onClose = () => {
		setOpenModal(false);
		setValues({
			name: "",
			parentId: null,
			parentName: "",
		});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};
	const editCategory = item => {
		setOpenModal(true);
		setValues(item);
	};

	const onSave = () => {
		const spinerID = uuid();
		if (!values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createCategory(values)
			: ApiService.updateCategory(values)
		)
			.then(response => {
				getCategories(categorieId);
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const deleteCategory = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteCategory(item.id)
				.then(() => {
					getCategories(categorieId);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	return (
		translationService && (
			<div className="container">
				{openModal && (
					<ModalComponent
						cancel={onClose}
						onSubmit={() => {
							onSave(openModal);
						}}
						addButtonIsDisabled={!isChange}
						title={
							!values?.id
								? translationService.translate("TR_CREATE")
								: translationService.translate("TR_UPDATE")
						}
						actionButtonTitle={translationService.translate("TR_SAVE")}>
						<div className="row">
							<div className="col-12 my-1">
								<label>Choose parent categorie</label>
								<ReactSelectOption
									value={values.parentId}
									selectedValue={(() => {
										const selectedItem = {
											...selectOption.find(data => data.id === values.parentId),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label = selectedItem.name;
											selectedItem.value = selectedItem.value;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={selectOption
										.filter(el => el.value !== values.parentId)
										.map(data => ({
											...data,
											label: translationService.translate(data.name),
											value: data.value,
										}))}
									onChange={item => onSelectOptionChange(item, "categorieId")}
								/>
							</div>
							<div className="col-12">
								<Input
									type="text"
									id="name"
									name="name"
									inputClassName="pr--5"
									value={values.name}
									fieldLength={40}
									isInvalidSubmit={
										isInvalidSubmit && !values.name.trim().length
									}
									labelValue="Name*"
									onChange={event => onChange(event, "name", 40)}
								/>
							</div>
						</div>
					</ModalComponent>
				)}
				<div className="row mt-4">
					<div className="col-12">
						<h2 className="section-title">
							{translationService.translate("TR_CATEGORIES")}
						</h2>
						<PageInfoBlock
							pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
							infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
							translationService={translationService}
						/>
					</div>
				</div>
				<hr />
				<div className="col-12">
					<div className="row">
						<div className="col-12 col-md-6 my-1">
							<ReactSelectOption
								value={categorieId}
								selectedValue={(() => {
									const selectedItem = {
										...selectOption.find(data => data.value === categorieId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.label;
										selectedItem.value = selectedItem.value;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={selectOption
									.filter(el => el.value !== categorieId)
									.map(data => ({
										...data,
										label: data.label,
										value: data.value,
									}))}
								onChange={item => onSelectOptionChange(item, "categorieId")}
							/>
						</div>
						<div className="col-12 col-md-6 my-1 d-flex justify-content-end align-items-end">
							<Link
								to={`#`}
								className="mindalay--btn-default"
								onClick={() => {
									setOpenModal(true);
								}}>
								Create category
							</Link>
						</div>
					</div>
				</div>
				{categories && categories.length ? (
					<div className="row">
						<div className="col-12">
							<CategoryAcardion
								data={categories}
								open={onChangeIds}
								ids={ids}
								translationService={translationService}
								deleteCategory={deleteCategory}
								updateCategory={editCategory}
							/>
						</div>
					</div>
				) : (
					<div className="no-data-container mt-3">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
		)
	);
}

export default withRouter(Categorys);

const CategoryAcardion = ({
	data = [],
	translationService = {},
	ids = [],
	open = () => {},
	background = "white-background",
	deleteCategory = () => {},
	updateCategory = () => {},
}) => {
	const language = useSelector(state => state.language.language);
	if (Array.isArray(data) && !data?.length) return null;

	return (
		data &&
		!!data.length && (
			<div
				className="accordion-wrapper m-0"
				style={{ position: "relative", zIndex: 0 }}>
				<div className="row" style={{ position: "relative", zIndex: 0 }}>
					{data.map((item, i) => {
						return (
							<div
								key={i}
								className={`col-12 ${item?.children ? "" : "mt-2"}`}
								style={{ position: "relative", zIndex: `${0 - i}` }}>
								<div className="card mindalay-card">
									<div
										className="card-header"
										style={{ zIndex: "0 !important" }}>
										<div
											className="d-flex align-items-center"
											aria-controls={`section_${item.id}`}
											aria-expanded={ids.includes(item.id)}
											onClick={() => open(item.id)}>
											<h5 className="text-elipsis fz-md w-auto">
												{translationService?.translate(item.name)
													? translationService?.translate(item.name)
													: item.name}
											</h5>
										</div>

										<div className="dropleft setting-dropdown">
											<div
												className="setting-dropdown-btn"
												id={`demo-${item.id}`}
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												onClick={e => {
													e.preventDefault();
												}}>
												<SettingsSvg />
											</div>
											<div
												id={`demo-${item.id}`}
												className="dropdown-menu collapse">
												<Link
													to={{
														state: { item: item.childDivisions },
													}}
													state={item?.childDivisions}
													className="dropdown-item"
													onClick={() => {
														updateCategory(item);
													}}>
													{translationService.translate("TR_EDIT")}
												</Link>
												<Link
													to="#"
													className="dropdown-item"
													onClick={() => {
														deleteCategory(item);
													}}>
													{translationService.translate("TR_DELETE")}
												</Link>
											</div>
										</div>
									</div>

									<Collapse in={ids.includes(item.id)}>
										<div
											id={`topic_${item.id}`}
											className={`pl-5 pr-2 ${
												background === "white-background"
													? "white-background"
													: "light-blue-background"
											} content-block m-0`}>
											{item.children && !!item.children.length && (
												<CategoryAcardion
													open={open}
													ids={ids}
													data={item.children}
													translationService={translationService}
													deleteCategory={deleteCategory}
													updateCategory={updateCategory}
													background={
														background === "white-background"
															? "light-blue-background"
															: "white-background"
													}
												/>
											)}
										</div>
									</Collapse>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		)
	);
};
