import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TranslationService from './../../Services/translationService';
import uuid from 'react-uuid';
import { addPartialViewSpinner, removePartialViewSpinner } from '../../Store/Actions/spinner';
import AlertService from '../../Services/alertService';
import { ERROR_KEY, INVALID_DATE_KEY, MONTH_ARR_KEY, SUCCESS_KEY, TR_NO, TR_YES, WEEK_DAYS_KEY } from '../../Constants/mainKeys';
import ApiService from './../../Services/apiService';
import { Link, withRouter } from 'react-router-dom';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import EditSvg from '../../Components/Svg/editSvg';
import { FORM_KEY, WEBINAR_KEY } from './../../Constants/urlKeys';
import ModalComponentWithoutActionButton from '../../Components/ModalComponent/modalComponentWithoutActionButton';
import Input from "../../Components/Inputs/input";
import * as moment from "moment";
import MainService from '../../Services/mainService';
import DeleteSvg from '../../Components/Svg/deleteSvg';
import SaveSvg from '../../Components/Svg/saveSvg';
import ReactPaginate from 'react-paginate';
import _InfoSvg from './../../Components/Svg/_infoSvg';
import PageInfoBlock from '../../Components/PageInfoBlock/PageInfoBlock';

const CuratorWebinars = (props) => {

  const weekDaysArr = WEEK_DAYS_KEY;
  const monthArr = MONTH_ARR_KEY;

  const pageSize = 10;
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);

  const language = useSelector(state => state.language.language);
  const [translationService, setTranslationService] = useState(null);
  const [webinars, setWebinars] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [currentWebinar, setCurrentWebinar] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);

  var [rangeDays, setRangeDays] = useState([]);
  var monthDaysItem = [];
  var rangeDaysItem = [];

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getWebinars(1);
  }, []);

  const getWebinars = (currentPage) => {
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getWebinarsByCurator(currentPage, pageSize).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      response.data && setWebinars(response.data);
      dispatch(removePartialViewSpinner(spinnerId));
      cancel();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }).catch(error => getFail(error, spinnerId));
  }

  const setDayDiff = (dayDiff, months, startMonth, endMonth, endDay, year) => {
    for (let j = 0; j < months; j++) {
      let nextMonth = startMonth + 1 + j;
      if (nextMonth === endMonth) {
        dayDiff += endDay;
      } else {
        let lastDayOfNextMonth = new Date(year, nextMonth, 0).getDate();
        dayDiff += lastDayOfNextMonth;
      }
    }
    return dayDiff;
  }

  const setMonths = (months, startMonth, endMonth, endDay, year) => {
    for (let j = 0; j < months; j++) {
      let nextMonth = startMonth + 1 + j;
      let lastDayOfNextMonth = new Date(year, nextMonth, 0).getDate();
      if (nextMonth === endMonth) {
        lastDayOfNextMonth = endDay;
      }
      rangeDaysItem.push(monthDaysItem);
      setRangeDays(rangeDaysItem);
      monthDaysItem = [];
      for (let i = 1; i <= lastDayOfNextMonth; i++) {
        const day = new Date(`${nextMonth}/${i}/${year}`);
        const dayName = weekDaysArr[day.getDay()];
        monthDaysItem.push({
          day: i,
          month: nextMonth,
          year: year,
          dayName: dayName,
          data: null,
          dataArr: []
        });
      }
    }
  }

  const openCalendarRange = (start, end) => {
    if (!start || !end) { return; }
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = startDate.getMonth() + 1;
    const endMonth = endDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const lastDayOfStartMonth = new Date(startYear, startMonth, 0).getDate();
    let month = startMonth;
    let monthsDiff;
    let monthsDiffOfStartYear;
    let dayDiff;

    if (endMonth - startMonth > 1) {
      monthsDiff = endMonth - startMonth;
      dayDiff = (lastDayOfStartMonth - startDay + 1) + endDay + 1;
      dayDiff = setDayDiff(dayDiff, monthsDiff, startMonth, 0, 0, startYear);
    } else if (startMonth !== endMonth) {
      dayDiff = (lastDayOfStartMonth - startDay) + endDay + 1;

      if (startYear !== endYear && Math.abs(endMonth - startMonth) > 1) {
        dayDiff = lastDayOfStartMonth - startDay + 1;
        monthsDiffOfStartYear = 12 - startMonth;
        dayDiff = setDayDiff(dayDiff, monthsDiffOfStartYear, startMonth, 0, 0, startYear);
        dayDiff = setDayDiff(dayDiff, endMonth, 0, endMonth, endDay, endYear);
      }
    } else {
      dayDiff = endDay - startDay;
    }

    for (let i = 0; i <= dayDiff; i++) {
      if (i == 0) {
        const day = new Date(`${startMonth}/${startDay}/${startYear}`);
        const dayName = weekDaysArr[day.getDay()];
        monthDaysItem.push({
          day: startDay,
          month: startMonth,
          year: startYear,
          dayName: dayName,
          data: null,
          dataArr: []
        });
        continue;
      }
      let nextDay = startDay + i;
      if (nextDay > lastDayOfStartMonth) {
        if (startYear !== endYear && Math.abs(endMonth - startMonth) > 1) {
          setMonths(monthsDiffOfStartYear, startMonth, 0, 0, startYear);
          setMonths(endMonth, 0, endMonth, endDay, endYear);
          rangeDaysItem.push(monthDaysItem);
          setRangeDays(rangeDaysItem);
          return;
        } else if (monthsDiff) {
          setMonths(monthsDiff, startMonth, endMonth, endDay, startYear);
          rangeDaysItem.push(monthDaysItem);
          setRangeDays(rangeDaysItem);
          return;
        } else {
          rangeDaysItem.push(monthDaysItem);
          setRangeDays(rangeDaysItem);
          monthDaysItem = [];
          month = endMonth;
          for (let j = 1; j <= endDay; j++) {
            const day = new Date(`${month}/${j}/${startYear}`);
            const dayName = weekDaysArr[day.getDay()];
            monthDaysItem.push({
              day: j,
              month: month,
              year: startYear,
              dayName: dayName,
              data: null,
              dataArr: []
            });
          }
          rangeDaysItem.push(monthDaysItem);
          setRangeDays(rangeDaysItem);
          return;
        }
      }
      const day = new Date(`${month}/${nextDay}/${startYear}`);
      const dayName = weekDaysArr[day.getDay()];
      monthDaysItem.push({
        day: nextDay,
        month: month,
        year: startYear,
        dayName: dayName,
        data: null,
        dataArr: []
      });
    }
    rangeDaysItem.push(monthDaysItem);
    setRangeDays(rangeDaysItem);
  }

  const redirectToWebinarForm = (webinarId) => {
    props.history.push(`/${language}/${WEBINAR_KEY}/${FORM_KEY}/${webinarId}`);
  }

  const onChangeCalendarStartTime = (event, index) => {
    const webinarCalendars = [...currentWebinar.days];
    const currentWebinarCalendar = webinarCalendars[index];
    currentWebinarCalendar.startTime = event.target.value;
    webinarCalendars[index] = currentWebinarCalendar;
    currentWebinar.days = webinarCalendars;
    setCurrentWebinar(currentWebinar);
  }

  const onChangeCalendarEndTime = (event, index) => {
    const webinarCalendars = [...currentWebinar.days];
    const currentWebinarCalendar = webinarCalendars[index];
    currentWebinarCalendar.endTime = event.target.value;
    webinarCalendars[index] = currentWebinarCalendar;
    currentWebinar.days = webinarCalendars;
    setCurrentWebinar(currentWebinar);
  }

  const removeCalendarFromCurrentWebinarDay = (currentWebinar, calendarId) => {
    AlertService.alertConfirm(
      `${translationService.translate("TR_WEBINAR_DELETE_QUESTION")}`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      ApiService.removeCalendarFromCurrentWebinarDay(calendarId).then(() => {
        const allWebinars = [...webinars];
        const webinar = { ...currentWebinar };
        var index = webinar.days.findIndex(function (o) {
          return o.id === calendarId;
        })
        if (index !== -1) webinar.days.splice(index, 1);
        allWebinars.forEach(web => {
          if (web.id === currentWebinar.id) {
            web = webinar;
          }
        });
        setWebinars(allWebinars);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_WEBINAR_SUCCESSFULLY_DELETED"));
      }).catch(error => getFail(error));
    })
  }

  const handlePageClick = (event) => {
    getWebinars(event.selected + 1);
    setActivePageNumber(event.selected);
  };


  const updateWebinarCalendar = (currentCalendar) => {
    const formData = new FormData();
    const form = {
      id: +currentCalendar.id,
      webinarDate: currentCalendar.webinarDate,
      startTime: currentCalendar.startTime,
      endTime: currentCalendar.endTime,
    }
    const data = {
      calendar: JSON.stringify(form),
      timeZoneOffset: new Date().getTimezoneOffset() / 60,
    }
    for (const i in data) {
      formData.append(`${i}`, data[i]);
    }
    ApiService.updateWebinarCalendar(formData).then(() => {
      getWebinars(activePageNumber + 1);
      cancel();
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_WEBINAR_SUCCESSFULLY_UPDATED"));
    }).catch(error => getFail(error, null));
  }

  const openModal = (currentWebinar) => {
    const webinar = { ...currentWebinar };
    if (webinar.days && webinar.days.length) {
      webinar.days.forEach(calendar => {
        if (moment(MainService.convertUTCDateToLocalDate(new Date(calendar.startTime))).format("HH:mm") !== INVALID_DATE_KEY) {
          const startTime = calendar.startTime;
          calendar.startTime = moment(MainService.convertUTCDateToLocalDate(new Date(startTime))).format("HH:mm");
          calendar.webinarStartDay = moment(MainService.convertUTCDateToLocalDate(new Date(startTime))).format();
        }
        if (moment(MainService.convertUTCDateToLocalDate(new Date(calendar.endTime))).format("HH:mm") !== INVALID_DATE_KEY) {
          calendar.endTime = moment(MainService.convertUTCDateToLocalDate(new Date(calendar.endTime))).format("HH:mm")
        }
      })
      setCurrentWebinar(webinar);
      openCalendarRange(webinar.startDate, webinar.endDate)
      setIsShowModal(true);
    }
  }

  const cancel = () => {
    setIsShowModal(false);
    setCurrentWebinar(null);
  }

  const divideDate = (date) => {
    if (!date) { return; }
    const currentDate = new Date(date);
    const data = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    }
    return data;
  }

  const deleteWebinar = (currentWebinar) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate("TR_CURATOR_WEBINAR_DELETE_CONFIRM_MESSAGE")}`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      dispatch(addPartialViewSpinner(spinnerId));
      ApiService.deleteKuratorWebinar(currentWebinar.id).then(response => {
        if (!response.data) {
          let updatedWebinars = webinars.filter(webinar => webinar.id !== currentWebinar.id);
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_WEBINAR_SUCCESSFULLY_DELETED"));
          setWebinars(updatedWebinars);
        } else {
          let updatedWebinars = [...webinars];
          updatedWebinars.forEach(webinar => {
            if (webinar.id === currentWebinar.id) {
              webinar = response.data;
            }
          })
          setWebinars(updatedWebinars);
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
        }
        dispatch(removePartialViewSpinner(spinnerId));
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
  }

  let fileLibraryColumns;
  if (translationService && webinars) {
    fileLibraryColumns = [
      {
        name: translationService.translate("TR_WEBINARNAME"),
        selector: 'webinarName',
      },
      {
        name: translationService.translate("TR_GROUP_NAME"),
        selector: 'groupName',
      },
      {
        name: translationService.translate("TR_WEBINAR_LECTURER"),
        selector: 'lecturerName',
      },
      {
        name: translationService.translate("TR_ACTIONS"),
        selector: 'action',
      },
    ];
  };

  if (webinars && webinars.length) {

    webinars.forEach(webinar => {
      webinar.webinarName = (() => {
        return <div
          key={webinar.id}
          className="payment-name"
          onClick={() => openModal(webinar)}
        >
          <span>
            {webinar.name}
          </span>
        </div>
      })();

      webinar.action = (() => {
        return <div className="d-flex">
          <div className="edit-group">
            <div
              className="edit-group-item mr-2"
              onClick={() => redirectToWebinarForm(webinar.id)}>
              <EditSvg />
            </div>
          </div>
          <div className="delete-group">
            <div
              className="delete-group-item ml-2"
              onClick={() => deleteWebinar(webinar)}>
              <DeleteSvg />
            </div>
          </div>
        </div>
      })();
    })

  }

  return translationService ?
    <div className="container">
      <div className="row mt-4">
        <div className="col-12">
          <h2 className="section-title">{translationService.translate("TR_WEBINARS")}</h2>
          <PageInfoBlock
            pageTitle={translationService.translate("TR_WEBINARS")}
            pageName="curator_webinar"
            translationService={translationService}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <div className="my-2">
            <Link to={`/${language}/${WEBINAR_KEY}/${FORM_KEY}`} className="mindalay--btn-default">{translationService.translate("TR_CREATE_WEBINAR")}</Link>
          </div>
        </div>
      </div>
      {/* <hr className="my-2" /> */}
      {
        isShowModal && currentWebinar ?
          <ModalComponentWithoutActionButton
            title={currentWebinar.name}
            cancel={cancel}
            isLarge={true}
          >
            <div className="row">
              {
                rangeDays && rangeDays.length && (currentWebinar.periodicity === 2 || currentWebinar.periodicity === 3) ?
                  rangeDays.map((month, monthIndex) => {
                    return <div key={monthIndex} className="col-12">
                      {
                        month[0] ?
                          <h2 className="section-title mt-2 mb-0">{translationService.translate(monthArr[month[0].month - 1])} {month[0].year}</h2>
                          : null
                      }
                      <div className="row">
                        {
                          month.length ?
                            month.map((monthItem, index) => {
                              return <div key={index} className="col-md-6 col-12 my-1">
                                <div className={`day-of-the-week-block day-of-the-week-block-item`}>
                                  <div className="title-block">
                                    <b className="my-2 w-100 text-center">{`${translationService.translate(monthItem.dayName)} | ${monthItem.day}`}</b>
                                  </div>
                                  <div className="week-days">
                                    {
                                      // Periodic webinar
                                      currentWebinar.days && currentWebinar.days.length ?
                                        currentWebinar.days.map((currentWebinarDay, index) => {
                                          // const currentWebinarDayDivide = divideDate(currentWebinarDay.webinarDate);
                                          const currentWebinarDayDivide = divideDate(currentWebinarDay.webinarStartDay);
                                          if (currentWebinarDayDivide && currentWebinarDayDivide.year === monthItem.year && currentWebinarDayDivide.month === monthItem.month && currentWebinarDayDivide.day === monthItem.day) {
                                            // return null
                                            return <div key={index} className="d-flex align-items-center">
                                              <Input
                                                type="time"
                                                id="startTime"
                                                name="startTime"
                                                defaultValue={currentWebinarDay.startTime}
                                                blockClassName='m-2 flex-1'
                                                inputClassName={`${!currentWebinarDay.inProcess && !currentWebinarDay.isFinished ? "" : "disabled-input"} `}
                                                onChange={(event) => onChangeCalendarStartTime(event, index)}
                                              />
                                              <p>-</p>
                                              <Input
                                                type="time"
                                                id="endTime"
                                                name="endTime"
                                                defaultValue={currentWebinarDay.endTime}
                                                inputClassName={`${!currentWebinarDay.inProcess && !currentWebinarDay.isFinished ? "" : "disabled-input"} `}
                                                blockClassName='m-2 flex-1'
                                                onChange={(event) => onChangeCalendarEndTime(event, index)}
                                              />
                                              {
                                                !currentWebinarDay.inProcess && !currentWebinarDay.isFinished ?
                                                  <div className="table-action-btn-group d-flex align-items-center">
                                                    <Link
                                                      to="#"
                                                      className="table-action-btn add-btn"
                                                      onClick={() => updateWebinarCalendar(currentWebinarDay)}
                                                    >
                                                      <SaveSvg />
                                                    </Link>
                                                    <Link
                                                      to="#"
                                                      className="table-action-btn delete-btn"
                                                      onClick={() => removeCalendarFromCurrentWebinarDay(currentWebinar, currentWebinarDay.id)}
                                                    >
                                                      <DeleteSvg />
                                                    </Link>
                                                  </div>
                                                  : null
                                              }
                                            </div>
                                          }
                                        })
                                        : null
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                            : null
                        }
                      </div>
                    </div>
                  })
                  : null
              }
              {
                currentWebinar.days && currentWebinar.days.length && currentWebinar.periodicity === 1 ?
                  currentWebinar.days.map((currentWebinarDay, index) => {
                    return <div key={index} className="col-md-6 col-12 my-1">
                      <div className={`day-of-the-week-block`}>
                        <div className="title-block">
                          <b className="my-2 w-100 text-center">{moment(MainService.convertUTCDateToLocalDate(new Date(currentWebinarDay.webinarDate))).format("LL")}</b>
                        </div>
                        <div className="week-days">
                          {
                            currentWebinar.days && currentWebinar.days.length ?
                              currentWebinar.days.map((currentWebinarDay, index) => {
                                return <div key={index} className="d-flex align-items-center">
                                  <Input
                                    type="time"
                                    id="startTime"
                                    name="startTime"
                                    defaultValue={currentWebinarDay.startTime}
                                    blockClassName='m-2 flex-1'
                                    inputClassName={`${!currentWebinarDay.inProcess && !currentWebinarDay.isFinished ? "" : "disabled-input"} `}
                                    onChange={(event) => onChangeCalendarStartTime(event, index)}
                                  />
                                  <p>-</p>
                                  <Input
                                    type="time"
                                    id="endTime"
                                    name="endTime"
                                    defaultValue={currentWebinarDay.endTime}
                                    blockClassName='m-2 flex-1'
                                    inputClassName={`${!currentWebinarDay.inProcess && !currentWebinarDay.isFinished ? "" : "disabled-input"} `}
                                    onChange={(event) => onChangeCalendarEndTime(event, index)}
                                  />
                                  {
                                    !currentWebinarDay.inProcess && !currentWebinarDay.isFinished ?
                                      <div className="table-action-btn-group d-flex align-items-center">
                                        <Link
                                          to="#"
                                          className="table-action-btn add-btn"
                                          onClick={() => updateWebinarCalendar(currentWebinarDay)}
                                        >
                                          <SaveSvg />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="table-action-btn delete-btn"
                                          onClick={() => removeCalendarFromCurrentWebinarDay(currentWebinar, currentWebinarDay.id)}
                                        >
                                          <DeleteSvg />
                                        </Link>
                                      </div>
                                      : null
                                  }
                                </div>
                              })
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  })
                  : null
              }
            </div>
          </ModalComponentWithoutActionButton>
          : null
      }
      {
        webinars && webinars.length ?
          <div className="mindayal-admin-table mt-3 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>{translationService.translate("TR_WEBINARNAME")}</th>
                  <th>{translationService.translate("TR_GROUP_NAME")}</th>
                  <th>{translationService.translate("TR_WEBINAR_LECTURER")}</th>
                  <th>{translationService.translate("TR_ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  webinars.map((webinar, index) => {
                    return <tr key={index} className="cursor-default">
                      <td >
                        <div
                          key={webinar.id}
                          className="payment-name word-break-break-word"
                          onClick={() => openModal(webinar)}
                        >
                          <span title={webinar.name}>
                            {webinar.name}
                          </span>
                        </div>
                      </td>
                      <td className='word-break-break-word'>{webinar.groupName}</td>
                      <td>{webinar.lecturerName}</td>
                      <td>
                        <div className="d-flex">
                          <div className="edit-group m-1">
                            <div
                              className="edit-group-item"
                              onClick={() => redirectToWebinarForm(webinar.id)}>
                              <EditSvg />
                            </div>
                          </div>
                          <div className="delete-group m-1">
                            <div
                              className="delete-group-item"
                              onClick={() => deleteWebinar(webinar)}>
                              <DeleteSvg />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          : <div className="no-data-container mt-5">
            <div className="no-data-wrapper">
              <p>{translationService.translate("TR_NO_DATA")}</p>
              <img src={NoDataImage} alt="/" />
            </div>
          </div>
      }
      {
        pagination ?
          <div className='row my-3'>
            <div className="col-12">
              <div className='d-flex justify-content-end'>
                <ReactPaginate
                  nextLabel={translationService.translate("TR_NEXT")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pagination.TotalPages}
                  previousLabel={translationService.translate("TR_PREVIOUS")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={activePageNumber}
                />
              </div>
            </div>
          </div>
          : null
      }
    </div>
    : null
}

export default withRouter(CuratorWebinars);
