// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import TranslationService from "../../../Services/translationService";
// import { withRouter } from "react-router";
// import _InfoSvg from "../../../Components/Svg/_infoSvg";
// import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
// import { Card, Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import resourcesPng from "./../../../assets/gamification/resources.png";
// import shopItemsPng from "./../../../assets/gamification/shopItems.png";
// import { STUDENT_USER_TYPE } from "../../../Constants/mainKeys";

// const GamificationDashboard = props => {
// 	const dispatch = useDispatch();
// 	const { history } = props;
// 	const { studentId } = props.match.params;
// 	const { translations } = useSelector(state => state.translation);
// 	const { user } = useSelector(state => state.user);
// 	const { language } = useSelector(state => state.language);
// 	const [translationService, setTranslationService] = useState(null);

// 	useEffect(() => {
// 		setTranslationService(new TranslationService(translations));
// 	}, [translations]);

// 	return translationService ? (
// 		<div className="container">
// 			<div className="row mt-4">
// 				<div className="col-12">
// 					<h2 className="section-title">Gamification Dashboard</h2>
// 					<PageInfoBlock
// 						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
// 						// pageName="curator_lecturer_selection_committee_student_courses"
// 						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
// 						translationService={translationService}
// 					/>
// 				</div>
// 			</div>
// 			<hr />
// 			<div className="row align-self-stretch">
// 				<div className="col-12 col-md-6">
// 					<div className="border rounded h-100 p-3 shadow">
// 						<Card className="w-100 h-100 border-none gamification-card">
// 							<div
// 								className="w-100 position-relative"
// 								style={{
// 									backgroundImage: `url(${resourcesPng})`,
// 									height: "300px",
// 									backgroundPosition: "center",
// 									backgroundSize: "cover",
// 									backgroundRepeat: "no-repeat",
// 								}}>
// 								<div className="gamification-card-text-block">
// 									<h3 className="text-white text-center">Resources</h3>
// 									<p
// 										className="description text-white text-center max-line-5"
// 										style={{ fontWeight: "500" }}>
// 										Lorem Ipsum is simply dummy text of the printing and
// 										typesetting industry. Lorem Ipsum has been the industry's
// 										standard dummy text ever since the 1500s Lorem Ipsum is
// 										simply dummy text of the printing and typesetting industry.
// 										Lorem Ipsum has been the industry's standard dummy text ever
// 										since the 1500s Lorem Ipsum is simply dummy text of the
// 										printing and typesetting industry. Lorem Ipsum has been the
// 										industry's standard dummy text ever since the 1500s
// 									</p>
// 								</div>
// 							</div>
// 							{/* <Card.Body className="d-flex flex-column justify-content-between p-2">
// 								<Link
// 									className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
// 									to={`${
// 										studentId
// 											? `/${language}/gamification/resources/${studentId}`
// 											: `/${language}/gamification/resources`
// 									}`}>
// 									{studentId || user.userTypeId === STUDENT_USER_TYPE
// 										? "View Resources"
// 										: "Manage Resources"}
// 								</Link>
// 							</Card.Body> */}
// 						</Card>
// 					</div>
// 				</div>

// 				{user.userTypeId === STUDENT_USER_TYPE || studentId ? (
// 					<>
// 						<div className="col-12 col-md-6 p-3">
// 							<div className="border rounded h-100">
// 								<Card className="w-100 h-100 border-none gamification-card">
// 									<div
// 										className="w-100 position-relative "
// 										style={{
// 											backgroundImage: `url(https://gamipress.com/wp-content/uploads/2018/12/leaderboards.svg)`,
// 											height: "300px",
// 											backgroundPosition: "center",
// 											backgroundSize: "cover",
// 											backgroundRepeat: "no-repeat",
// 										}}>
// 										<div className="gamification-card-text-block">
// 											<h3 className="text-white text-center">Leaderboards</h3>
// 											<p
// 												className="description text-white text-center max-line-5"
// 												style={{ fontWeight: "500" }}>
// 												Lorem Ipsum is simply dummy text of the printing and
// 												typesetting industry. Lorem Ipsum has been the
// 												industry's standard dummy text ever since the 1500s
// 												Lorem Ipsum is simply dummy text of the printing and
// 												typesetting industry. Lorem Ipsum has been the
// 												industry's standard dummy text ever since the 1500s
// 												Lorem Ipsum is simply dummy text of the printing and
// 												typesetting industry. Lorem Ipsum has been the
// 												industry's standard dummy text ever since the 1500s
// 											</p>
// 										</div>
// 									</div>
// 									{/* <Card.Body className="d-flex flex-column justify-content-between p-2">
// 										<Link
// 											className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
// 											to={`${
// 												studentId
// 													? `/${language}/gamification/leaderboards/${studentId}`
// 													: `/${language}/gamification/leaderboards`
// 											}`}>
// 											{studentId || user.userTypeId === STUDENT_USER_TYPE
// 												? "View Leaderboards"
// 												: "Manage Leaderboards"}
// 										</Link>
// 									</Card.Body> */}
// 								</Card>
// 							</div>
// 						</div>

// 						<div className="col-12 col-md-6 p-3">
// 							<div className="border rounded h-100">
// 								<Card className="w-100 h-100 border-none gamification-card">
// 									<div
// 										className="w-100 position-relative "
// 										style={{
// 											backgroundImage: `url(https://groupboss.io/blog/content/images/2022/01/Facebook-group-badges.png)`,
// 											height: "300px",
// 											backgroundPosition: "center",
// 											backgroundSize: "cover",
// 											backgroundRepeat: "no-repeat",
// 										}}>
// 										<div className="gamification-card-text-block">
// 											<h3 className="text-white text-center">Badges</h3>
// 											<p
// 												className="description text-white text-center  max-line-5"
// 												style={{ fontWeight: "500" }}>
// 												Lorem Ipsum is simply dummy text of the printing and
// 												typesetting industry. Lorem Ipsum has been the
// 												industry's standard dummy text ever since the 1500s
// 												Lorem Ipsum is simply dummy text of the printing and
// 												typesetting industry. Lorem Ipsum has been the
// 												industry's standard dummy text ever since the 1500s
// 												Lorem Ipsum is simply dummy text of the printing and
// 												typesetting industry. Lorem Ipsum has been the
// 												industry's standard dummy text ever since the 1500s
// 											</p>
// 										</div>
// 									</div>
// 									{/* <Card.Body className="d-flex flex-column justify-content-between p-2">
// 										<Link
// 											className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
// 											to={`${
// 												studentId
// 													? `/${language}/gamification/badges/${studentId}`
// 													: `/${language}/gamification/badges`
// 											}`}>
// 											{studentId || user.userTypeId === STUDENT_USER_TYPE
// 												? "View Badges"
// 												: "Manage Badges"}
// 										</Link>
// 									</Card.Body> */}
// 								</Card>
// 							</div>
// 						</div>
// 					</>
// 				) : null}

// 				<div className="col-12 col-md-6 p-3">
// 					<div className="border rounded h-100">
// 						<Card className="w-100 h-100 border-none gamification-card">
// 							<div
// 								className="w-100 position-relative "
// 								style={{
// 									backgroundImage: `url(${shopItemsPng})`,
// 									height: "300px",
// 									backgroundPosition: "center",
// 									backgroundSize: "cover",
// 									backgroundRepeat: "no-repeat",
// 								}}>
// 								<div className="gamification-card-text-block">
// 									{/* <h3 className="text-white text-center">Shop Items</h3> */}
// 									<h3 className="text-white text-center">
// 										{studentId || user.userTypeId === STUDENT_USER_TYPE
// 											? "My Items"
// 											: " Shop Items"}
// 									</h3>
// 									<p
// 										className="description text-white text-center max-line-5"
// 										style={{ fontWeight: "500" }}>
// 										Lorem Ipsum is simply dummy text of the printing and
// 										typesetting industry. Lorem Ipsum has been the industry's
// 										standard dummy text ever since the 1500s Lorem Ipsum is
// 										simply dummy text of the printing and typesetting industry.
// 										Lorem Ipsum has been the industry's standard dummy text ever
// 										since the 1500s Lorem Ipsum is simply dummy text of the
// 										printing and typesetting industry. Lorem Ipsum has been the
// 										industry's standard dummy text ever since the 1500s
// 									</p>
// 								</div>
// 							</div>

// 							{/* <Card.Body className="d-flex flex-column justify-content-between p-2">
// 								<Link
// 									className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
// 									to={`${
// 										studentId
// 											? `/${language}/gamification/shop-items/${studentId}`
// 											: `/${language}/gamification/shop-items`
// 									}`}>
// 									{studentId || user.userTypeId === STUDENT_USER_TYPE
// 										? "View My Items"
// 										: " Manage Shop Items"}
// 								</Link>
// 							</Card.Body> */}
// 						</Card>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	) : null;
// };

// export default withRouter(GamificationDashboard);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import resourcesPng from "./../../../assets/gamification/resources.png";
import shopItemsPng from "./../../../assets/gamification/shopItems.png";
import {
	ADMIN_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../../../Constants/mainKeys";
import { ADMIN_KEY } from "../../../Constants/requestKeys";

const GamificationDashboard = props => {
	const dispatch = useDispatch();
	const { history } = props;
	const { studentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { user } = useSelector(state => state.user);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_GAMIFICATION_DASHBOARD")}
					</h2>
					<PageInfoBlock
						pageTitle="Gamification Dashboard"
						pageName={
							user && user.userTypeId === ADMIN_USER_TYPE
								? "admin_gamification_dashboard"
								: "lecturer_student_gamification_dashboard"
						}
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-self-stretch">
				<div className="col-12 col-md-6 mt-4">
					<Link
						to={`${
							studentId
								? `/${language}/gamification/resources/${studentId}`
								: `/${language}/gamification/resources`
						}`}>
						<div className="border rounded h-100 p-3 shadow">
							<Card className="w-100 h-100 border-none gamification-card">
								<div
									className="w-100 position-relative"
									style={{
										backgroundImage: `url(${resourcesPng})`,
										height: "300px",
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}>
									<div className="gamification-card-text-block">
										<h3 className="text-white text-center">
											{translationService.translate("TR_RESOURCES")}
										</h3>
										<p
											className="description text-white text-center max-line-5"
											style={{ fontWeight: "500" }}>
											{translationService.translate("TR_USERS_RESOURCES")}
										</p>
									</div>
								</div>
							</Card>
						</div>
					</Link>
				</div>

				{user.userTypeId === STUDENT_USER_TYPE || studentId ? (
					<>
						<div className="col-12 col-md-6 mt-4">
							<Link
								to={`${
									studentId
										? `/${language}/gamification/leaderboards/${studentId}`
										: `/${language}/gamification/leaderboards`
								}`}>
								<div className="border rounded h-100 p-3 shadow">
									<Card className="w-100 h-100 border-none gamification-card">
										<div
											className="w-100 position-relative "
											style={{
												backgroundImage: `url(https://gamipress.com/wp-content/uploads/2018/12/leaderboards.svg)`,
												height: "300px",
												backgroundPosition: "center",
												backgroundSize: "cover",
												backgroundRepeat: "no-repeat",
											}}>
											<div className="gamification-card-text-block">
												<h3 className="text-white text-center">
													{translationService.translate("TR_LEADERBOARDS")}
												</h3>
												<p
													className="description text-white text-center max-line-5"
													style={{ fontWeight: "500" }}>
													{translationService.translate(
														"TR_GAMIFICATION_DASHBOARD_LEADERBOARD",
													)}
												</p>
											</div>
										</div>
									</Card>
								</div>
							</Link>
						</div>

						<div className="col-12 col-md-6 mt-4">
							<Link
								to={`${
									studentId
										? `/${language}/gamification/badges/${studentId}`
										: `/${language}/gamification/badges`
								}`}>
								<div className="border rounded h-100 p-3 shadow">
									<Card className="w-100 h-100 border-none gamification-card">
										<div
											className="w-100 position-relative "
											style={{
												backgroundImage: `url(https://groupboss.io/blog/content/images/2022/01/Facebook-group-badges.png)`,
												height: "300px",
												backgroundPosition: "center",
												backgroundSize: "cover",
												backgroundRepeat: "no-repeat",
											}}>
											<div className="gamification-card-text-block">
												<h3 className="text-white text-center">
													{translationService.translate("TR_BADGES")}
												</h3>
												<p
													className="description text-white text-center  max-line-5"
													style={{ fontWeight: "500" }}>
													{translationService.translate(
														"TR_GAMIFICATION_DASHBOARD_BADGES",
													)}
												</p>
											</div>
										</div>
									</Card>
								</div>
							</Link>
						</div>
					</>
				) : null}

				<div className="col-12 col-md-6 mt-4">
					<Link
						to={`${
							studentId
								? `/${language}/gamification/shop-items/${studentId}`
								: `/${language}/gamification/shop-items`
						}`}>
						<div className="border rounded h-100 p-3 shadow">
							<Card className="w-100 h-100 border-none gamification-card">
								<div
									className="w-100 position-relative "
									style={{
										backgroundImage: `url(${shopItemsPng})`,
										height: "300px",
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}>
									<div className="gamification-card-text-block">
										{/* <h3 className="text-white text-center">Shop Items</h3> */}
										<h3 className="text-white text-center">
											{studentId || user.userTypeId === STUDENT_USER_TYPE
												? translationService.translate("TR_MY_ITEMS")
												: translationService.translate("TR_SHOP_ITEMS")}
										</h3>
										<p
											className="description text-white text-center max-line-5"
											style={{ fontWeight: "500" }}>
											{translationService.translate("TR_USERS_SHOP_ITEMS")}
										</p>
									</div>
								</div>
							</Card>
						</div>
					</Link>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(GamificationDashboard);
