import React, { Component } from 'react';
import MessageSend from '../../../Components/Svg/messageSend';
import * as moment from "moment";
import { connect } from 'react-redux';
import MainService from '../../../Services/mainService';
import TranslationService from '../../../Services/translationService';

class Messages extends Component {

  state = {
    translationService: null,
    message: '',
    keyName: this.props.keyName,
    messages: this.props.messages,
    messageMaxLength: 2000
  }

  componentDidMount() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if ((this.state.keyName !== this.props.keyName) || (this.state.messages.length !== this.props.messages.length)) {
      this.setState({ keyName: this.props.keyName, messages: this.props.messages });
      this.chatScrolToBottom();
    }
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  chatScrolToBottom = () => {
    var element = document.getElementById("mindalay--consultation-chat");
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  sendMessage = (event) => {
    event && event.preventDefault();
    var { message } = this.state;
    if (message) {
      message = message.trim();
    }
    message && this.props.sendMessage(message);
    this.setState({ message: '' });
  }

  render() {

    const { message, translationService } = this.state;
    const { messages, messageMaxLength } = this.props;
    
    return translationService ? <div className="tab-pane " id="consultation-contract--messages-tab" aria-labelledby="consultation-contract--messages-tab">
      <div className="consultation-chat-content-wrapper">
        <div id="mindalay--consultation-chat" className="consultation-chat-message-container">
          {
            messages.length ?
              messages.map((mess, index) =>  mess.receiver ?
                  <div key={index} className="consultation-chat-message-wrapper consultation-chat-currnet-user">
                    <div className="consultation-chat-message">
                      <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                      <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                    </div>
                  </div>
                  :
                 <div key={index} className="consultation-chat-message-wrapper consultant-chat-sender">
                    <div className="consultation-chat-sender-wrapper">
                    </div>
                    <div className="consultation-chat-message">
                      <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                      <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                    </div>
                  </div>
              )
              : null
          }
        </div>
        <div className="consultation-chat-input-wrapper">
          <form onSubmit={this.sendMessage}>
            <div className="consultation-chat-send-button bg-transparent" id="send-message-button"  title={translationService.translate("TR_SEND")} onClick={this.sendMessage}>
              <MessageSend />
            </div>
            <input
              type="text"
              className="form-control"
              value={message}
              name="message"
              placeholder={translationService.translate("TR_TYPE_TEXT")}
              autoComplete="off"
              onChange={(event) => this.onChange(event, messageMaxLength)}
            />
          </form>
        </div>
      </div>
    </div>
      : null
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  user: state.user.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Messages)

