import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import TranslationService from '../../Services/translationService';
import ApiService from "../../Services/apiService";
import AlertService from '../../Services/alertService';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import TextSpinner from '../../Components/Spinners/textSpinner';
import { COURSES_KEY } from '../../Constants/urlKeys';
import { changeShoppingCartOrWishListCount, changeWishListCount, changeShoppingCartCount } from "../../Store/Actions/wishListOrShoppingCart";
import { addTextSpinner, removeTextSpinner } from './../../Store/Actions/spinner';
import {
  ERROR_KEY,
  MOVE_KEY,
  NUMBER_KEY,
  REMOVE_KEY,
  SHOPPING_CART_COUNT_KEY,
  SHOPPING_CART_PRODUCTS_IDS_KEYS,
  SUCCESS_KEY, WARNING_KEY,
  WISH_LIST_COUNT_KEY,
  WISH_LIST_PRODUCTS_IDS_KEYS
} from '../../Constants/mainKeys';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';

const WishListItems = props => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const user = useSelector(state => state.user.user);
  const textSpinners = useSelector(state => state.spinner.textSpinners);

  const [translationService, setTranslationService] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const [operation, setOperation] = useState(null);

  const language = useSelector(state => state.language.language);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const removeCourseFromWishList = (event, course) => {
    event.stopPropagation();
    setOperation(REMOVE_KEY);
    setDeleteFileId(course.id);
    const spinnerId = `file-${course.id}`;
    var wishListProductsByIds = null;
    if (localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS)) {
      wishListProductsByIds = JSON.parse(localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS))
    }
    if (user) {
      dispatch(addTextSpinner(spinnerId));
      ApiService.removeCourseFromWishList(course.id).then(response => {
        dispatch(removeTextSpinner(spinnerId));
        if (response) {
          props.updateCoursesData("wishListCourses", course.id);
          if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
            localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
            dispatch(changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount));
          }
          AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate('TR_REMOVE_WISH_LIST_MSG')}`);
        }
      }).catch(error => getFail(error));
    }
    if (!user && wishListProductsByIds) {
      wishListProductsByIds = wishListProductsByIds.filter(productId => productId !== course.id);
      dispatch(changeWishListCount(wishListProductsByIds.length));
      localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
      props.updateCoursesData("wishListCourses", course.id);
    }
  }

  const addCourseToWishListOrCart = (event, bool, course) => {
    //move to cart
    event.stopPropagation();
    setOperation(MOVE_KEY);
    setDeleteFileId(course.id);
    const spinnerId = `file-${course.id}`;
    var wishListProductsByIds = localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS);
    var shoppingCartProductsByIds = localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS);
    if (user) {
      dispatch(addTextSpinner(spinnerId));
      ApiService.moveCourseToWishListOrCart(course.id, bool).then(response => {
        dispatch(removeTextSpinner(spinnerId));
        if (response) {
          AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate(!bool ? 'TR_ADDED_WISH_LIST_MSG' : 'TR_ADDED_SHOPPING_CART_MSG')}`)
          props.updateCoursesData("wishListCourses", course.id, course);
          if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
            localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
            dispatch(changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount));
          }
        }
      }).catch(error => getFail(error, spinnerId));
    } else if (!user && wishListProductsByIds) {
      if (shoppingCartProductsByIds) {
        shoppingCartProductsByIds = JSON.parse(shoppingCartProductsByIds);
        const isExist = shoppingCartProductsByIds.find(productId => productId === course.id);
        if (!isExist) {
          props.updateCoursesData("wishListCourses", course.id, course);
          shoppingCartProductsByIds.push(course.id);
          dispatch(changeShoppingCartCount(shoppingCartProductsByIds.length));
          localStorage.setItem(SHOPPING_CART_PRODUCTS_IDS_KEYS, JSON.stringify(shoppingCartProductsByIds));
          wishListProductsByIds = JSON.parse(wishListProductsByIds).filter(productId => productId !== course.id);
          dispatch(changeWishListCount(wishListProductsByIds.length))
          localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
        } else {
          AlertService.alert(WARNING_KEY, translationService.translate("TR_COURSE_IS_IN_YOUR_SHOPPING_CART"));
        }
      } else {
        shoppingCartProductsByIds = [];
        props.updateCoursesData("wishListCourses", course.id, course);
        shoppingCartProductsByIds.push(course.id);
        dispatch(changeShoppingCartCount(shoppingCartProductsByIds.length));
        localStorage.setItem(SHOPPING_CART_PRODUCTS_IDS_KEYS, JSON.stringify(shoppingCartProductsByIds));
        wishListProductsByIds = JSON.parse(wishListProductsByIds).filter(productId => productId !== course.id);
        dispatch(changeWishListCount(wishListProductsByIds.length))
        localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
      }
    }
  }

  const redirectToCouresView = (courseId) => {
    props.history.push(`/${language}/${COURSES_KEY}/${courseId}`);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removeTextSpinner(spinnerId));
  }

  const currentButtonSpinner = textSpinners.find(spinner => spinner === `file-${deleteFileId}`);

  return translationService ? <Auxiliary>
      <hr className="mt-5" />
      <h3 className="section-title">{`${translationService.translate('TR_WISH_LIST')}`}</h3>
      {
        (props.courses && props.courses.length) ? props.courses.map(course => {
          return <div key={course.id} className="cart-wrapper d-sm-flex mt-3">
            <div className="cart-body-wrapper d-flex w-100">
              <div className="cart-image d-flex cursor-pointer" style={{ backgroundImage: `url(${course.imagePath})` }} onClick={() => redirectToCouresView(course.id)}></div>
              <div className="cart-info-wrapper d-sm-flex d-block w-100">
                <div className="cart-item-info">
                  <h3 className='word-break-break-word'>{course.name}</h3>
                  <p>{course.creatorUserName}</p>
                  <div className="cart-item-ruls d-sm-flex d-block remove-or-move-course">
                    <div className="mr-2">
                      {
                        currentButtonSpinner && course.id === deleteFileId && operation === REMOVE_KEY
                          ? <TextSpinner spinner={currentButtonSpinner} className="ml-2"/>
                          : <Link
                            className={`text-button ${currentButtonSpinner ? "pointer-events-none" : ""}`}
                            to="#"
                            onClick={(event) => removeCourseFromWishList(event, course)}
                          >
                            {`${translationService.translate('TR_REMOVE')}`}
                          </Link>
                      }
                    </div>
                    <div className="mr-2">
                      {
                        currentButtonSpinner && course.id === deleteFileId && operation === MOVE_KEY
                          ? <TextSpinner spinner={currentButtonSpinner} className="ml-2"/>
                          : <Link
                            className={`text-button ${currentButtonSpinner ? "pointer-events-none" : ""}`}
                            to="#"
                            onClick={(event) => addCourseToWishListOrCart(event, true, course)}
                          >
                            {translationService.translate('TR_MOVE_TO_CART')}
                          </Link>
                      }
                    </div>
                  </div>
                </div>
                <div className="cart-price-wrapper d-sm-block d-flex flex-1 text-sm-right">
                  {
                    course.cost ?
                      <Auxiliary>
                        <div className="cart-item-new-price m-sm-0 mr-2">{course.discount === 100 ? `${translationService.translate("TR_FREE")}` : course.cost}</div>
                        <div className="cart-item-old-price">
                          {course.oldCost ? <span>{course.oldCost}</span> : null}
                        </div>
                      </Auxiliary>
                      : <div className="cart-item-new-price m-sm-0 mr-2 h-100 d-flex align-items-center justify-content-end">
                        {translationService.translate('TR_FREE')}
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }) :
          <div className="no-data-container">
            <div className="no-data-wrapper">
              <p>{translationService.translate("TR_EMPTY_WISH_LIST")}</p>
              <img src={NoDataImage} alt="/" />
            </div>
          </div>
      }
    </Auxiliary>
      : null
}

export default withRouter(WishListItems)

