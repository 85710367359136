import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import ModalComponent from "../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import HelpService from "../Services/helpService";
import { getCurrentQuestionMarkData } from "../Store/Actions/main";

const INFO_TYPES_KEY = [
	"organization-name",
	"input-string",
	"input-short-string",
	"input-long-string",
	"table-input-string",
	"table-input-date",
	"table-input-number",
	"table-number-and-date",
	"input-date-to-right",
	"input-number",
	"input-date",
	"input-create-date",
	"table-input-start-end-time",
	"table-list",
	"input-time",
	"drop-down-group",
	"library",
	"table-library",
	"list",
	"students-list",
	"inputs-list",
	"sign-row",
	"table-sign-part",
	"text-area",
	"sign-row-arr",
	"add-row-button",
	"remove-row-button",
	"students-in-table",
	"drop-down-group-not-draw",
];
const placeHolderFields = [
	"input-string",
	"inputs-list",
	"input-short-string",
	"input-long-string",
	"sign-row-arr",
	"table-input-string",
	"table-string-and-date",
];

const libraryFields = ["library", "table-library"];

function TemplatesInfo(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [documentTemplateInfo, setDocumentTemplateInfo] = useState([]);
	const [values, setValues] = useState({
		documentTemplateId: "",
		hidden: false,
		placeholder: "",
		position: "",
		type: null,
		entityId: null,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [documentTemplate, setDocumentTemplate] = useState([]);
	const [documentTemplateId, setDocumentTemplateId] = useState(null);
	const [openPlaceHolderField, setOpenPlaceHolderField] = useState(false);
	const [libraries, setLibraries] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDocumentTemplateInfo();
		getDocumentTemplate();
	}, []);

	const getDocumentTemplate = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentTemplate()
			.then(response => {
				if (response && response.data) {
					setDocumentTemplate([{ id: null, name: "All" }, ...response.data]);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getDocumentTemplateInfo = (templateId = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		(!templateId
			? ApiService.getDocumentTemplateInfo()
			: ApiService.getDocumentTemplateInfoInTemplateId(templateId)
		)
			.then(response => {
				if (response && response.data) {
					setDocumentTemplateInfo(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getLibrary = () => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getLibrary()
			.then(response => {
				if (response && response.data && response.data.length) {
					setLibraries(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) {
			return false;
		}
		if (fieldName === "type" && libraryFields.includes(item.value)) {
			getLibrary();
		} else {
			setLibraries([]);
		}
		if (fieldName === "type" && placeHolderFields.includes(item.value)) {
			setOpenPlaceHolderField(true);
		} else {
			setOpenPlaceHolderField(true);
		}
		setValues(values => ({
			...values,
			[fieldName]: item.value,
			placeholder:
				fieldName === "type" && !placeHolderFields.includes(item.value)
					? ""
					: values.placeholder,
		}));
		setIsChange(true);
		setIsInvalidSubmit(false);
	};

	const onCheckboxChange = event => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			setValues(values => ({
				...values,
				[event.target.name]: event.target.checked,
			}));
			setIsInvalidSubmit(false);
			setIsChange(true);
		}
	};

	// const deletedocumentTemplateInfo = item => {
	// 	if (!item) return;
	// 	const spinnerId = uuid();
	// 	AlertService.alertConfirm(
	// 		`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
	// 		"",
	// 		translationService.translate(TR_YES),
	// 		translationService.translate(TR_NO),
	// 	).then(() => {
	// 		dispatch(addPartialViewSpinner(spinnerId));
	// 		ApiService.deletedocumentTemplateInfo(item.id)
	// 			.then(() => {
	// 				setDocumentTemplateInfo(
	// 					documentTemplateInfo.filter(_item => _item.id !== item.id),
	// 				);
	// 				AlertService.alert(
	// 					SUCCESS_KEY,
	// 					translationService.translate("TR_DATA_SAVED"),
	// 				);
	// 			})
	// 			.catch(error =>
	// 				AlertService.alert(
	// 					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
	// 					error,
	// 				),
	// 			)
	// 			.finally(() => {
	// 				dispatch(removePartialViewSpinner(spinnerId));
	// 			});
	// 	});
	// };

	const onClose = (close = true) => {
		setValues({
			documentTemplateId: "",
			hidden: false,
			placeholder: "",
			position: "",
			type: null,
			entityId: null,
		});
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
		setLibraries([]);
		setOpenPlaceHolderField(false);
	};

	const getModalData = item => {
		setOpenModal(true);
		if (item) {
			if (libraryFields.includes(item.type)) {
				getLibrary();
			}
			if (placeHolderFields.includes(item.type)) {
				setOpenPlaceHolderField(true);
			}
			setValues(item);
		}
	};

	const onNumberChange = (event, fieldname, maxValue = Infinity) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsChange(true);
		setIsInvalidSubmit(false);
		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			setValues(values => ({ ...values, [fieldname]: event.target.value }));
		}
	};

	const onSave = () => {
		const spinerID = uuid();
		if (
			!values.type ||
			!values.documentTemplateId ||
			!values.position.trim().length
		) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createDocumentTemplateInfo(values)
			: ApiService.updateDocumentTemplateInfo(values)
		)
			.then(response => {
				if (response.data) {
					setDocumentTemplateInfo([...documentTemplateInfo, response.data]);
				} else if (values.id) {
					setDocumentTemplateInfo(
						documentTemplateInfo.map(item => {
							if (item.id === values.id) {
								return {
									...item,
									...values,
								};
							}
							return item;
						}),
					);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	const filterTamplateInputs = item => {
		setDocumentTemplateId(item.id);
		getDocumentTemplateInfo(item.id);
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave();
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<label>{translationService.translate("TR_TEMPLATEE")}*</label>
							<ReactSelectOption
								value={values.documentTemplateId}
								isInvalidSubmit={isInvalidSubmit && !values.documentTemplateId}
								selectedValue={(() => {
									const selectedItem = {
										...documentTemplate.find(
											data => data.id === values.documentTemplateId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label =
											selectedItem.displayString || selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentTemplate
									.filter(item => item.id !== values.documentTemplateId)
									.map(data => ({
										...data,
										label: data.name,
										value: data.id,
									}))}
								onChange={item =>
									onSelectOptionChange(item, "documentTemplateId")
								}
							/>
						</div>

						<div className="col-12 mt-2">
							<label>{translationService.translate("TR_INFO_TYPES")}*</label>
							<ReactSelectOption
								value={values.type}
								isInvalidSubmit={isInvalidSubmit && !values.type}
								selectedValue={(() => {
									const selectedItem = INFO_TYPES_KEY.find(
										data => data === values.type,
									);

									if (selectedItem) {
										return {
											value: selectedItem,
											label: selectedItem,
										};
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={INFO_TYPES_KEY.filter(item => item !== values.type).map(
									data => ({
										label: data,
										value: data,
									}),
								)}
								onChange={item => onSelectOptionChange(item, "type")}
							/>
						</div>
						{libraries && !!libraries.length && (
							<div className="col-12 mt-2">
								<label>{translationService.translate("TR_LIBRARY")}</label>
								<ReactSelectOption
									value={values.entityId}
									selectedValue={(() => {
										const selectedItem = {
											...libraries.find(data => data.id === values.entityId),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label =
												selectedItem.displayString || selectedItem.name;
											selectedItem.value = selectedItem.id;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={libraries
										.filter(item => item.id !== values.entityId)
										.map(data => ({
											...data,
											label: data.displayString || data.name,
											value: data.id,
										}))}
									onChange={item => onSelectOptionChange(item, "entityId")}
								/>
							</div>
						)}
						<div className="col-12 mt-2">
							<Input
								type="text"
								id="position"
								name="position"
								inputClassName="pr--5"
								value={values.position}
								isInvalidSubmit={
									isInvalidSubmit && !values.position.trim().length
								}
								labelValue={`${translationService.translate("TR_POSITION")}*`}
								onChange={event => onNumberChange(event, "position")}
							/>
						</div>
						{openPlaceHolderField && (
							<div className="col-12 mt-2">
								<Input
									type="text"
									id="placeholder"
									name="placeholder"
									inputClassName="pr--5"
									value={values.placeholder}
									labelValue={`${translationService.translate(
										"TR_INPUT_PLACEHOLDER",
									)}`}
									onChange={event => onChange(event, "placeholder")}
								/>
							</div>
						)}
						<div className="col-12 mt-2">
							<div>
								<label htmlFor="hidden">
									{translationService.translate("TR_HIDDEN_INFO")}
								</label>
							</div>
							<InputCheckBox
								id={`hidden`}
								name={`hidden`}
								checked={values.hidden}
								labelValue={translationService.translate("TR_YES")}
								onChange={event => {
									onCheckboxChange(event);
								}}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_TEMPLATE_INFO")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6">
					<ReactSelectOption
						value={documentTemplateId}
						selectedValue={(() => {
							const selectedItem = {
								...documentTemplate.find(
									data => data.id === documentTemplateId,
								),
							};
							if (Object.keys(selectedItem).length) {
								selectedItem.label = selectedItem.name;
								selectedItem.value = selectedItem.id;
								return selectedItem;
							} else {
								return {
									value: null,
									label: translationService.translate("TR_CHOOSE"),
								};
							}
						})()}
						items={documentTemplate
							.filter(item => item.id !== documentTemplateId)
							.map(data => ({
								...data,
								label: data.name,
								value: data.id,
							}))}
						onChange={item => filterTamplateInputs(item, "parentId")}
					/>
				</div>
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default mt-3"
						onClick={() => {
							getModalData();
						}}>
						Create {translationService.translate("TR_DOCUMENT_CATEGORY")}
					</button>
				</div>
			</div>
			{documentTemplateInfo && documentTemplateInfo.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_INFO_TYPE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_TEMPLATE_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_POSITION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{documentTemplateInfo.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.type}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.templateName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.position}
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={"#"}
																title={translationService.translate("TR_EDIT")}
																onClick={e => {
																	e.stopPropagation();
																	getModalData(item);
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															{/* <Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deletedocumentTemplateInfo(item);
																}}>
																<DeleteSvg />
															</Link> */}
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
}

export default withRouter(TemplatesInfo);
