export const IDENTITY_GET_TOKEN_PATH_KEY = '/identity/GetPWDAccessToken';

export const USER_PATH_KEY = 'user';
export const CURRENT_PATH_KEY = 'current';
export const UPDATE_CREDENTIALS_PATH_KEY = 'updatecredentials';
export const CHANGE_CREDENTIALS_PATH_KEY = 'changecredentials';
export const CHANGE_CREDENTIALS_LINK_PATH_KEY = 'changecredentialslink';
export const SELF_REGISTRATION_PATH_KEY = 'selfregistration';
export const TEMP_REGISTRATION_PATH_KEY = 'tempRegistration';
export const TRANSLATION_PATH_KEY = 'translation';
export const SYSTEM_LANG_CHANGE_PATH_KEY = 'systemlangchange';
export const COURSE_PATH_KEY = 'course';
export const COURSE_ID_PATH_KEY = 'courseId=';
export const MY_COURSES_PATH_KEY = 'mycourses';
export const COURSE_QUIZ_PATH_KEY = 'coursequiz';
export const VERIFIED_PATH_KEY = 'verified';
export const UNVERIFIED_PATH_KEY = 'unverified';
export const PUBLISHED_PATH_KEY = 'published';
export const TOP_PATH_KEY = 'top';
export const HOME_PATH_KEY = 'home';
export const RECOMMENDED_PATH_KEY = 'recommended';
export const PUBLISH_PATH_KEY = 'publish';
export const COST_PATH_KEY = 'cost';
export const TARGET_STUDENTS_PATH_KEY = 'targetstudents';
export const MESSAGES_PATH_KEY = 'messages';
export const MESSAGE_PATH_KEY = 'message';
export const AVAILABLE_USERS_PATH_KEY = 'availableusers';
export const TICKETS_PATH_KEY = 'tickets';
export const SUBMIT_FOR_VERIFICATION_PATH_KEY = 'submitforverification';
export const MY_COURSES_AS_INSTRUCTOR_PATH_KEY = 'mycoursesasinstructor';
export const MY_COURSES_AS_STUDENT_PATH_KEY = 'mycoursesasstudent';
export const INSTRUCTOR_COURSES_PATH_KEY = 'instructorcourses';
export const FINISH_KEY = 'finish';
export const PRICE_PATH_KEY = 'price';
export const PRICES_PATH_KEY = 'prices';
export const CURRENCY_PATH_KEY = 'currency';
export const SECTION_PATH_KEY = 'section';
export const SECTION_CHANGE_ORDER_KEY = 'sectionchangeorder';
export const TOPIC_PATH_KEY = 'topic';
export const TOPIC_ID_PATH_KEY = 'topicid';
export const TOPIC_CHANGE_ORDER_KEY = 'topicchangeorder';
export const LIBRARY_PATH_KEY = 'library';
export const FILE_LIBRARY_PATH_KEY = 'filelibrary';
export const COURSE_TOPIC_FILE_PATH_KEY = 'courseTopicFile';
export const VIDEO_PROFESSIONALISM_PATH_KEY = 'Video Professionalism';
export const CATEGORY = 'category';
export const THEME_KEY = 'theme';
export const BYBTYPE = 'bytype';
export const BYTOPIC = 'bytopic';
export const BY_CATEGORY_PATH_KEY = 'bycategory';
export const QUESTION_PATH_KEY = 'question';
export const SUBMIT_QUESTIONS_PATH_KEY = 'submitquestions';
export const QUESTIONS_GROUPS_PATH_KEY = 'questionsGroup';
export const BY_QUESTIONS_GROUP_PATH_KEY = 'byquestionsgroup';
export const QUIZ_QUESTION_PATH_KEY = 'quizquestion';
export const QUIZ_ATTEMPT_PATH_KEY = 'quizattempt';
export const QUIZ_REVIEW_OPTIONS_PATH_KEY = 'quizreviewoptions';
export const INSTRUCTOR_PATH_KEY = 'instructor';
export const SUBMIT_PATH_KEY = 'submit';
export const ON_GOING_PATH_KEY = 'ongoing';
export const REVIEW_PATH_KEY = 'review';
export const BY_NAME_PATH_KEY = 'byname';
export const FILTER_PATH_KEY = 'filter';
export const NOTIFICATION_PATH_KEY = 'notification';
export const SET_AS_READ_PATH_KEY = 'setasread';
export const ID_PARAM_KEY = 'id=';
export const ISO_CODE_PARAM_KEY = 'isocode=';
export const QUESTIONS_GROUP_ID_PARAM_KEY = 'questionsGroupId=';
export const TYPE_PARAM_KEY = 'type=';
export const ENTITY_TYPE_PARAM_KEY = 'entitytype=';
export const ENTITY_ID_PARAM_KEY = 'entityId=';
export const EMAIL_PARAM_KEY = 'email=';
export const TOTAL_PRICE_PARAM_KEY = 'totalPrice=';
export const CURRENCY_ID_PARAM_KEY = 'currencyid=';
export const PAGE_KEY = "page";
export const PAGE_CONTENT_KEY = "pagecontent";
export const STUDENT_COURSE_KEY = 'studentcourse';
export const STUDENT_COURSE_ID_KEY = 'studentCourseId';
export const START_CONTINUE_COURSE_KEY = 'startcontinuecourse';
export const GET_TOPIC_KEY = 'getTopic';
export const GET_TOPIC_AND_COURSE = 'gettopicandcourse';
export const UPDATE_TIME_STAMP_KEY = 'updatetimestamp';
export const VIDEO_TIME_STAMP_KEY = 'videoTimestamp';
export const STUDENT_KEY = 'student';
export const WISHLIST_KEY = 'wishlist';
export const SHOPPINGCART_KEY = 'shoppingcart';
export const WISHLIST_OR_CART_KEY = 'wishlistorcart';
export const CHECK_PRICE_PATH_KEY = 'checkprice';
export const CHECK_PRICE_BUY_NOW_PATH_KEY = 'checkpricebuynow';
export const BULK_CREATE_PATH_KEY = 'bulkcreate';
export const CONSULTATION_HOURS_KEY = "consultation_hours";
export const POTENTIAL_CONSULTATIONS_KEY = "potentialconsultations";
export const POTENTIAL_CONSULTATIONS_COUNT_KEY = "potentialconsultationsCount";
export const CONSULTATION_KEY = "consultation";
export const CONSULTANT_KEY = "consultant";
export const CONSULTANT_CONTRACTS_KEY = "consultantcontracts";
export const CURRENT_BY_CONSULTANT_KEY = "currentbyConsultant";
export const STUDENT_CONTRACTS_KEY = "studentcontracts";
export const CONTRACT_BY_CONSULTANT_KEY = "contractbyconsultant";
export const CONTRACT_BY_STUDENT_KEY = "contractbystudent";
export const SIGN_CONTRACT_KEY = "signcontract";
export const CONTRACT_ID_KEY = "contractid";
export const ACCEPT_CONTRACT_CONSULTANT_KEY = "acceptcontractconsultant";
export const ACCEPT_CONTRACT_STUDENT_KEY = "acceptcontractstudent";
export const DECLINE_KEY = "decline";
export const DECLINE_CONTRACT_CONSULTANT_KEY = "declinecontractconsultant";
export const DECLINE_CONTRACT_STUDENT_KEY = "declinecontractstudent";
export const APPLY_FOR_CONSULTATION_KEY = "applyforconsultation";
export const AVAILABLE_HOURS_KEY = "available_hours";
export const EXPERIENCE_LEVEL_KEY = "experience_level";
export const SENDER_ID_KEY = "senderid=";
export const ONLY_BY_CONCRETE_SENDER_KEY = "onlybyconcretesender";
export const UPDATE_DATE_BY_STUDENT_KEY = "updatedatebystudent";
export const UPDATE_DATE_BY_CONSULTANT_KEY = "updatedatebyconsultant";
export const WEBINAR_KEY = "webinar";
export const WEBINAR_CALENDAR_KEY = "webinarCalendar";
export const ONE_TIME_WEBINAR_CALENDAR_KEY = "onetimewebinarCalendar";
export const SITE_WEBINAR_KEY = "sitewebinar";
export const ONE_TIME_KEY = "onetime";
export const ONE_TIME_WEBINAR_USER_KEY = "onetimewebinaruser";
export const CREATE_ONE_TIME_WEBINAR_KEY = "create-onetime-webinar";
export const UPDATE_ONE_TIME_WEBINAR_KEY = "update-onetime-webinar";
export const LANGUAGE_LEVELS_KEY = "language_levels";
export const LANGUAGES_KEY = "languages";
export const PAYMENT_KEY = "payment";
export const PAYMENT_AND_PURCHASE_KEY = "paymentandpurchase";
export const BALANCE_KEY = "balance";
export const PAYMENT_SYSTEM_KEY = "paymentSystem";
export const PAYOUT_KEY = "payout";
export const SUCCESS_AND_CHECKOUT_KEY = "successandcheckout ";
export const REVIEW_KEY = "review";
export const REPLY_KEY = "reply";
export const COMPLAINT_KEY = "complaint";
export const COMPLAINT_SUBJECT_KEY = "complaint_subject";
export const COURSE_TICKET_SUBJECT_KEY = "course_ticket_subject";
export const CONSULTATION_TICKET_SUBJECT_KEY = "consultation_ticket_subject";
export const ALL_MESSAGES_KEY = "allMessages";
export const REMOVE_FROM_SHOPPINGCART_KEY = "removefromshoppingcart";
export const EMAIL_ACTIVATION_KEY = "emailactivation";
export const UNAUTHORIZED_KEY = "unauthorized";
export const KEYWORD_KEY = "keyword";
export const SPECIALTY_KEY = "specialty";
export const LIBRARYDATA_KEY = "librarydata";
export const SEARCH_KEY = "search";
export const SUCCESS_KEY = 'success';
export const CANCEL_KEY = 'cancel';
export const APPROVE_KEY = 'approve';
export const NOT_APPROVED_KEY = 'notapproved';
export const ADMIN_KEY = 'admin';
export const INPROCESS_KEY = 'inprocess';
export const VERIFY_KEY = 'verify';
export const DENY_VERIFICATION_KEY = 'denyverification';
export const PROFILE_KEY = 'profile';
export const RESOLVED_KEY = 'resolved';
export const RESOLVE_KEY = 'resolve';
export const REFUND_KEY = 'refund';
export const DECLINE_REFUND_KEY = 'declinerefund';
export const SPECIALTIES_KEY = 'specialties';
export const CONFIRM_KEY = 'confirm';
export const UNCONFIRMED_KEY = 'unconfirmed';
export const DISCOUNT_KEY = 'discount';
export const BUY_NOW_KEY = 'buynow';
export const CHECK_BALANCE_KEY = 'checkbalance';
export const ALL_KEY = 'all';
export const TICKET_KEY = 'ticket';
export const ADMIN_TICKET_KEY = 'ADMIN_TICKET_KEY';
export const BY_TYPE_ADMIN_CONSULTANT_KEY = 'bytypeadminconsultant';
export const BY_TYPE_KEY = 'bytype';
export const CURRENT_STUDENT_KEY = 'currentstudent';
export const MAINS_KEY = 'mains';
export const BY_MAIN_KEY = 'bymain';
export const ORG_KEY = 'org';
export const BY_USER_ID_KEY = 'byuserid';
export const STRUCT_KEY = 'struct';
export const CHILDREN_KEY = 'children';
export const ORGANIZATION_EDUCATION_LEVEL_KEY = 'organizationEducationLevel';
export const ORGANIZATION_KEY = 'organization';
export const PROGRAM_NAMES_KEY = 'programnames';
export const FRONT_END_PAGE_FALSE_KEY = '?frontendpage=false';
export const LECTURER_KEY = 'lecturer';
export const CURATOR_KEY = 'curator';
export const PUBLISH_COURSE_KEY = 'publishcourse';
export const ALL_STUDENTS_KEY = 'allstudents';
export const BY_STUDENT_KEY = 'bystudent';
export const PAUSE_COURSE_KEY = 'pausecourse';
export const BLOCK_COURSE_KEY = 'blockcourse';
export const GROUP_KEY = 'group';
export const GROUPSTUDENT_KEY = 'groupstudent';
export const GROUPID_KEY = 'groupId';
export const PAYPAL_KEY = 'paypal';
export const STIPE_KEY = 'stripe';
export const CRYLLEX_KEY = 'cryllex';
export const SUBJECT_LECTURER_KEY = 'subjectLecturer';
export const SUBJECTID_KEY = 'subjectId';
export const GETPAGE_KEY = 'getpage';
export const BYID_KEY = 'byId';
export const ONGOING_ONE_TIME_KEY = 'ongoingonetime';
export const SEEALL_KEY = 'seeall';
export const UPDATE_PASSWORD_KEY = 'updatepassword';
export const AUTO_REGISTRATION_KEY = 'autoregistration';
export const PAYMENT_PACKAGE_KEY = 'paymentPackage';
export const FOR_SALE_KEY = 'forsale';
export const COURSE_PURCHASE_KEY = 'coursepurchase';
export const PACKAGE_PURCHASE_KEY = 'packagepurchase';
export const SUCCESS_AND_PURCHASE_COURSES_KEY = 'successandpurchasecourses';
export const SUCCESS_AND_PURCHASE_PACKAGE_KEY = 'successandpurchasepackage';
export const WITH_PRICES_KEY = 'withprices';
export const USER_TYPE_ID_KEY = 'usertypeId';
export const PAGE_NUMBER_KEY = 'pageNumber';
export const PAGE_SIZE_KEY = 'pageSize';
export const ORGANIZATION_PAYMENT_PACKAGE_KEY = 'OrganizationPaymentPackage';
export const SAVE_KEY = 'save';
export const PROMO_KEY = 'promo';
export const CHECKOUT_RESPONSE_KEY = 'chekoutResponse';
export const CONTACTUS_KEY = 'contactus';
export const RECURRING_KEY = 'recurring';
export const SYSTEMLOG_KEY = 'systemlog';
export const SETTINGS_KEY = 'settings';
export const CLOSE_WEBINAR_KEY = 'closeWebinar';
export const LOCAL_PAYMENTS_KEY = 'localpayments';
export const HISTORY_KEY = 'history';
export const PAYMENT_ID_KEY = 'paymentId';
export const HOME_PAGE_KEY = 'homepage';
export const BULK_DELETE_KEY = 'bulkdelete';
export const SUBJECT_BY_PROGRAM_NAME_KEY = 'subjectbyprogramname';
export const ORGANIZATION_PAYMENTS_KEY = 'organizationPayments';
export const CONFIRM_PAYMENT_KEY = 'confirmpayment';
export const SELECT_PACKAGE_KEY = 'selectpackage';
export const ACTIVATE_PACKAGE_KEY = 'activatepackage';
export const REJECT_KEY = 'reject';
export const WEBINAR_CALENDAR_RESOURCES_KEY = 'webinarCalendarResources';
export const FILES_AND_STUDENTS_KEY = 'filesandstudents';
export const STUDENTS_BY_FILES_KEY = 'studentsbyfiles';
export const FILES_BY_STUDENTS_KEY = 'filesbystudents';
export const ATTACH_FILE_TO_STUDENTS_KEY = 'attachfiletostudents';
export const DATA_INFO_KEY = 'datainfo';
export const QUIZ_INFO_KEY = 'quizinfo';
export const FILE_LIB_FOLDER_KEY = 'filelibfolder';
export const HOW_TO_KEY = 'howto';
export const PAGE_NAME_KEY = 'pagename';
export const SINGLE_KEY = 'single';
export const MINDALAY_HISTORY_KEY = 'mindalayhistory';
export const DATA_NAME_KEY = 'dataname';
export const PAGINATION_KEY = 'pagination';
export const DETAILS_WITH_DOCS_KEY = 'detailswithdocs';
export const ORGANIZATION_DOCUMENT_KEY = 'organizationDocument';
export const ORG_AND_GROUP_KEY = 'organdgroupname';
export const DEFAULT_USER_TYPE_ID_KEY = 'defaultUserTypeId';
export const SIGN_KEY = 'sign';
export const LECTURER_DOC_KEY = 'lecturerdoc';
export const CURATOR_DOC_KEY = 'curatordoc';
export const STUDENT_DOC_KEY = 'studentdoc';
export const FILE_NAME_KEY = 'filename';
export const ORGANIZATION_COPY_NAME_KEY = 'organizationcopy';
export const PACKAGE_PROPERTY_KEY = 'packageProperty';
export const CALCULATE_KEY = 'calculate';
export const CREATE_KEY = 'create';
export const DOCUMENT_TEMPLATE_INFO_DATA_KEY = 'documentTemplateInfoData';
export const TEMPLATE_INFO_ID_KEY = 'templateInfoId';
export const GET_BY_ADMIN_KEY = 'byadmin';
export const AS_ADMIN_KEY = 'asadmin';
export const BY_SUBJECT_KEY = 'bysubject';
export const INNER_PAYMENT_KEY = 'innerPayment';
export const BY_COURSE_KEY = 'bycourse';
export const STUDENT_INNER_PAYMENT_KEY = 'studentInnerPayment';
export const FULL_PAYMENT_KEY = 'fullpayment';
export const SCHEDULE_KEY = 'schedule';
export const DEPOSIT_KEY = 'deposit';
export const BY_ISO_CODE_KEY = 'byisocode';

