import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import { Link } from "react-router-dom";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import MainService from "../../Services/mainService";
import HelpLeftMenu from "./Components/HelpLeftMenu";
import {
	setIsShowLelpLeftMenu,
	setPageContent,
	setTopicPageContent,
} from "../../Store/Actions/help";
import Parser from "html-react-parser";
import useScroll from "../../Components/Hooks/UseScroll";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const Help = props => {
	const scrollY = useScroll();
	const dispatch = useDispatch();
	const containerRef = useRef();
	const pathName = props.location.pathname;
	const { user } = useSelector(state => state.user);
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const { faqPageContent, topicPageContent, pageContent } = useSelector(
		state => state.help,
	);
	const { topicId, faqId } = props.match.params;
	const [currentTopicContent, setCurrentTopicContent] = useState(null);
	const { isShowHelpLeftMenu } = useSelector(state => state.help);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getHelpPageContent();
	}, []);

	useEffect(() => {
		if (topicId) {
			getTopicById(+topicId);
		} else {
			setCurrentTopicContent(null);
		}
	}, [topicId, language]);

	const getHelpPageContent = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		(MainService.isShowOnlyB2B()
			? ApiService.getHelpPageContentBySpecialDomain()
			: ApiService.getHelpPageContent()
		)
			.then(response => {
				dispatch(removePageSpinner(spinnerId));
				if (response.data) {
					dispatch(setPageContent({ ...response.data }));
					if (response.data.children.length === 1) {
						const data = { ...response.data };
						if (data.children[0].children && data.children[0].children.length) {
							//TOPIC
							dispatch(setTopicPageContent(data.children[0]));
						}
					}
				}
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getTopicById = topicId => {
		if (!topicId) {
			return;
		}
		const spinnerId = uuid();
		ApiService.getPageById(topicId)
			.then(response => {
				if (response.data) {
					setCurrentTopicContent(response.data);
				}
				dispatch(removePageSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const scrollToCurrentPosition = id => {
		const elems = [...document.getElementsByTagName("h2")];
		const currentElement = elems.find(elem => +elem.id === +id);
		if (currentElement) {
			setTimeout(() => {
				currentElement &&
					window.scrollTo({
						top:
							currentElement?.getBoundingClientRect().top +
							document.documentElement.scrollTop -
							100,
						behavior: "smooth",
					});
			}, 1);
		}
	};

	const showOrHideLeftMenu = bool => {
		dispatch(setIsShowLelpLeftMenu(bool));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
		props.history.push(`/${language}`);
	};

	return translationService ? (
		<div className="mb-5">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<i
								className={`fa fa-bars d-lg-none d-block cursor-pointer ${
									!user ? "mt-3" : ""
								}`}
								onClick={() => showOrHideLeftMenu(true)}
							/>
						</div>
					</div>
				</div>
				<div className="row d-lg-none d-block">
					<div className="col-12">
						<hr />
					</div>
				</div>
				<div className="row">
					<div className="col-md-3 d-lg-block d-none">
						<div>
							{pageContent &&
							pageContent.children &&
							pageContent.children.length ? (
								<HelpLeftMenu
									topicId={topicId}
									hideMenu={() => showOrHideLeftMenu(false)}
								/>
							) : null}
						</div>
					</div>
					<div className="col-lg-6">
						<div
							id="full-content-container"
							className="faq-body"
							ref={containerRef}>
							{currentTopicContent &&
							currentTopicContent.pageContentResponses &&
							currentTopicContent.pageContentResponses.length ? (
								currentTopicContent.pageContentResponses.map(
									pageContentResponse => {
										if (pageContentResponse.fullContent) {
											return (
												<div key={pageContentResponse.id}>
													<div className="row">
														<div className="col-12">
															<h2
																id={pageContentResponse.id}
																className="section-title mb-2">
																{translationService.translate(
																	pageContentResponse.name,
																)
																	? translationService.translate(
																			pageContentResponse.name,
																	  )
																	: pageContentResponse.name}
															</h2>
														</div>
														<div className="col-12 list-style font_family">
															<ParserComponent
																text={pageContentResponse.fullContent}
															/>
														</div>
														<div className="col-12">
															<hr />
														</div>
													</div>
												</div>
											);
										} else {
											return;
										}
									},
								)
							) : (
								<div className="no-data-container">
									<div className="no-data-wrapper">
										<p>{translationService.translate("TR_NO_DATA")}</p>
										<img src={NoDataImage} />
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-lg-3">
						<ul
							className={`list-unstyled bg-white ${
								scrollY > 70 ? "list-fixed" : ""
							}`}>
							{currentTopicContent &&
							currentTopicContent.pageContentResponses &&
							currentTopicContent.pageContentResponses.length
								? currentTopicContent.pageContentResponses.map(
										pageContentResponse => {
											return (
												<li key={pageContentResponse.id} className="mt-2 test">
													<Link
														to="#"
														onClick={() =>
															scrollToCurrentPosition(pageContentResponse.id)
														}>
														{translationService.translate(
															pageContentResponse.name,
														)
															? translationService.translate(
																	pageContentResponse.name,
															  )
															: pageContentResponse.name}
													</Link>
												</li>
											);
										},
								  )
								: null}
						</ul>
					</div>
				</div>
			</div>
			<section
				className={`d-md-none help-side-menu-container ${
					isShowHelpLeftMenu ? "show-help-left-menu" : ""
				}`}>
				<div
					className={`${isShowHelpLeftMenu ? "layer" : ""} `}
					onClick={() => showOrHideLeftMenu(false)}
				/>
				<aside
					className={`side-left bg-white p-3`}
					style={{ width: "350px", zIndex: 16 }}>
					<div className="side-left-container">
						<div className="side-left-inner-wrapper">
							{pageContent &&
							pageContent.children &&
							pageContent.children.length ? (
								<HelpLeftMenu
									topicId={topicId}
									hideMenu={() => showOrHideLeftMenu(false)}
								/>
							) : null}
						</div>
					</div>
				</aside>
			</section>
		</div>
	) : null;
};

export default withRouter(Help);
