import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import { addTextSpinner, removeTextSpinner } from './../../Store/Actions/spinner';
import TranslationService from '../../Services/translationService';
import { changeShoppingCartOrWishListCount, changeWishListCount, changeShoppingCartCount } from "../../Store/Actions/wishListOrShoppingCart";
import { COURSES_KEY } from '../../Constants/urlKeys';
import {
  ERROR_KEY,
  MOVE_KEY,
  NUMBER_KEY,
  REMOVE_KEY,
  SHOPPING_CART_COUNT_KEY,
  SHOPPING_CART_PRODUCTS_IDS_KEYS,
  SUCCESS_KEY, TR_NO, TR_YES, WARNING_KEY,
  WISH_LIST_COUNT_KEY,
  WISH_LIST_PRODUCTS_IDS_KEYS
} from '../../Constants/mainKeys';
import TextSpinner from '../../Components/Spinners/textSpinner';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';

const ShoppingCartList = (props) => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const user = useSelector(state => state.user.user);
  const textSpinners = useSelector(state => state.spinner.textSpinners);
  const language = useSelector(state => state.language.language);

  const [translationService, setTranslationService] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const [operation, setOperation] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const removeCourseFromShoppingCart = (event, course) => {
    event.stopPropagation();
    AlertService.alertConfirm(
      `${translationService.translate("TR_REMOVE_COURSE_FROM_SHOPPNG_CART_CONFIRM_MESSAGE").replace("{0}", `"${course.name}"`)} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      setOperation(REMOVE_KEY);
      setDeleteFileId(course.id);
      const spinnerId = `file-${course.id}`;
      var shoppingCartProductsByIds = null;
      if (localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS)) {
        shoppingCartProductsByIds = JSON.parse(localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS));
      }
      if (user) {
        dispatch(addTextSpinner(spinnerId))
        ApiService.removeCourseFromShoppingCart(course.id).then(response => {
          if (response) {
            // props.updateCoursesData("shoppingCartCourses", course.id);
            if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
              localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
              localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
              dispatch(changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount));
              props.getShoppingCartCourses();
            }
            AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate('TR_REMOVE_SHOPPING_CART_MSG')}`);
          }
          dispatch(removeTextSpinner(spinnerId));
        }).catch(error => getFail(error, spinnerId));
      }
      if (!user && shoppingCartProductsByIds) {
        shoppingCartProductsByIds = shoppingCartProductsByIds.filter(productId => productId !== course.id);
        localStorage.setItem(SHOPPING_CART_COUNT_KEY, shoppingCartProductsByIds.length);
        dispatch(changeShoppingCartCount(shoppingCartProductsByIds.length));
        localStorage.setItem(SHOPPING_CART_PRODUCTS_IDS_KEYS, JSON.stringify(shoppingCartProductsByIds));
        props.updateCoursesData("shoppingCartCourses", course.id);
      }
    })
  }

  const moveCourseToWishList = (event, course) => {
    //move to wish-list
    event.stopPropagation();
    setOperation(MOVE_KEY);
    setDeleteFileId(course.id);
    const spinnerId = `file-${course.id}`;
    var wishListProductsByIds = localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS);
    var shoppingCartProductsByIds = localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS);
    if (user) {
      dispatch(addTextSpinner(spinnerId));
      ApiService.moveCourseToWishListOrCart(course.id, false).then(response => {
        dispatch(removeTextSpinner(spinnerId));
        if (response) {
          response && AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate('TR_ADDED_WISH_LIST_MSG')}`);
          props.updateCoursesData("shoppingCartCourses", course.id, course);
          if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
            localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
            dispatch(changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount));
          }
        }
      }).catch(error => getFail(error, spinnerId));
    } else if (!user && shoppingCartProductsByIds) {
      if (wishListProductsByIds) {
        wishListProductsByIds = JSON.parse(wishListProductsByIds);
        const isExist = wishListProductsByIds.find(productId => productId === course.id);
        if (!isExist) {
          props.updateCoursesData("shoppingCartCourses", course.id, course);
          wishListProductsByIds.push(course.id);
          dispatch(changeWishListCount(wishListProductsByIds.length));
          localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
          shoppingCartProductsByIds = JSON.parse(shoppingCartProductsByIds).filter(productId => productId !== course.id);
          dispatch(changeShoppingCartCount(shoppingCartProductsByIds.length));
          localStorage.setItem(SHOPPING_CART_PRODUCTS_IDS_KEYS, JSON.stringify(shoppingCartProductsByIds));
        } else {
          AlertService.alert(WARNING_KEY, "Course is already in your wishlist");
        }
      } else {
        wishListProductsByIds = [];
        props.updateCoursesData("shoppingCartCourses", course.id, course);
        wishListProductsByIds.push(course.id);
        dispatch(changeWishListCount(wishListProductsByIds.length))
        localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
        shoppingCartProductsByIds = JSON.parse(shoppingCartProductsByIds).filter(productId => productId !== course.id);
        dispatch(changeShoppingCartCount(shoppingCartProductsByIds.length));
        localStorage.setItem(SHOPPING_CART_PRODUCTS_IDS_KEYS, JSON.stringify(shoppingCartProductsByIds));
      }
    }
  }

  const redirectToCouresView = (courseId) => {
    props.history.push(`/${language}/${COURSES_KEY}/${courseId}`)
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removeTextSpinner(spinnerId));
  };

  const currentButtonSpinner = textSpinners.find(spinner => spinner === `file-${deleteFileId}`);

  return (
    translationService ? <Auxiliary>
      <h3 className="section-title">{translationService.translate('TR_SHOPPING_CART')}</h3>
      {
        props.courses && props.courses.length ? props.courses.map(course => {
          return <div key={course.id} className="cart-wrapper d-sm-flex mt-3 ">
            <div className="cart-body-wrapper d-flex w-100">
              <div className="cart-image d-flex cursor-pointer" style={{ backgroundImage: `url(${course.imagePath})` }} onClick={() => redirectToCouresView(course.id)}></div>
              <div className="cart-info-wrapper d-sm-flex d-block w-100">
                <div className="cart-item-info">
                  <h3 className='word-break-break-word'>{course.name}</h3>
                  <p>{course.creatorUserName}</p>
                  <div className="cart-item-ruls d-sm-flex d-block remove-or-move-course">
                    <div className="mr-2">
                      {
                        currentButtonSpinner && course.id === deleteFileId && operation === REMOVE_KEY
                          ? <TextSpinner spinner={currentButtonSpinner} className="ml-2" />
                          : <Link
                            className={`text-button ${currentButtonSpinner ? "pointer-events-none" : ""}`}
                            to="#"
                            onClick={(event) => removeCourseFromShoppingCart(event, course)}
                          >
                            {`${translationService.translate('TR_REMOVE')}`}
                          </Link>
                      }
                    </div>
                    <div className="mr-2">
                      {
                        currentButtonSpinner && course.id === deleteFileId && operation === MOVE_KEY
                          ? <TextSpinner spinner={currentButtonSpinner} className="ml-2" />
                          : <Link
                            className={`text-button ${currentButtonSpinner ? "pointer-events-none" : ""}`}
                            to="#"
                            onClick={(event) => moveCourseToWishList(event, course)}
                          >
                            {translationService.translate('TR_MOVE_TO_WISH_LIST')}
                          </Link>
                      }
                    </div>
                  </div>
                </div>
                <div className="cart-price-wrapper d-sm-block d-flex flex-1 text-sm-right">
                  {
                    course.cost ?
                      <Auxiliary>
                        <div className="cart-item-new-price m-sm-0 mr-2">{course.discount === 100 ? `${translationService.translate("TR_FREE")}` : course.cost}</div>
                        <div className="cart-item-old-price">
                          {course.oldCost ? <span>{course.oldCost}</span> : null}
                        </div>
                      </Auxiliary>
                      : <div className="cart-item-new-price m-sm-0 mr-2 h-100 d-flex align-items-center justify-content-end">
                        {translationService.translate('TR_FREE')}
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        })
          :
          <div className="no-data-container">
            <div className="no-data-wrapper">
              <p>{translationService.translate("TR_EMPTY_SHOPPING_CART")}</p>
              <img src={NoDataImage} alt="/" />
            </div>
          </div>
      }
    </Auxiliary>
      : null
  );
}

export default withRouter(ShoppingCartList)

