import React from 'react';
import logo from "./../../../assets/logo/logo_214x214.png"
import font from "./../../../fonts/ArialMT.ttf";
import moment from 'moment';
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image
} from '@react-pdf/renderer';

const GeneratePDF = (props) => {

  const PDFData = { ...props.PDFData };
  const translationService = { ...props.translationService };
  const invoice = [...props.invoice];

  return translationService && PDFData ? <Document
    author="Mindalay"
  >
    <Page size="A4">
      <View style={styles.body}>
        <View style={styles.header}>
          <View style={styles.headerLeftBlock}>
            <Image
              src={logo}
              style={styles.logo}
            />
            <Text style={[styles.ml5, styles.boldText]}>
              Mindalay
            </Text>
          </View>
          <View style={styles.headerRightBlock}>
            <View style={styles.textBlock}>
              <Text style={[styles.boldText]}>{translationService.translate("TR_INVOICE")}: </Text>
              <Text style={styles.normalText}>{PDFData.id}</Text>
            </View>
            <View style={styles.textBlock}>
              <Text style={[styles.boldText]}>{translationService.translate("TR_DATE_OF_INVOICE")}: </Text>
              <Text style={styles.normalText}>{moment(new Date()).format('L')}</Text>
            </View>
          </View>
        </View>
        <View style={styles.subHeader}>

          <View style={{ marginBottom: 5, marginTop: 5 }}>
            <Text style={[styles.boldText]}>{translationService.translate("TR_STATUS")}: {PDFData.isPaid ? translationService.translate("TR_PAID") : translationService.translate("TR_UNPAID")} </Text>
          </View>

          <View style={{ marginBottom: 5, marginTop: 5, flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
            <View>
              <Text style={[styles.boldText]}>{translationService.translate("TR_FROM")}: </Text>
            </View>
            <View style={{ marginBottom: 4 }}>
              {
                invoice && invoice.length ?
                  invoice.map((invoiceItem, index) => {
                    if (invoiceItem.name === "sender_info") {
                      return <View key={index} style={{ display: "flex" }}>
                        <Text style={[styles.normalText, styles.mt10]}>{invoiceItem.displayName}</Text>
                        <Text style={[styles.normalText, styles.mt5]}>{invoiceItem.description}</Text>
                      </View>
                    }
                  })
                  : null
              }
            </View>
          </View>
          <View style={styles.hr} />
          <View style={{ marginBottom: 5, marginTop: 5, flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start" }}>
            <View style={{ marginTop: 8 }}>
              <Text style={[styles.boldText]}>{translationService.translate("TR_TO")}: </Text>
            </View>
            <View >
              {
                invoice && invoice.length ?
                  invoice.map((invoiceItem, index) => {
                    if (invoiceItem.name === "recipient") {
                      return <Text key={index} style={[styles.normalText, styles.mt10]}>{invoiceItem.description}</Text>
                    }
                    if (invoiceItem.name === "address" || invoiceItem.name === "phone_number") {
                      return <Text key={index} style={[styles.normalText, styles.mt5]}>{invoiceItem.description}</Text>
                    }
                  })
                  : null
              }
            </View>
          </View>


        </View>

        <View>
          <View style={styles.tableHeader}>
            <View style={styles.w25}><Text style={styles.boldText}>{translationService.translate("TR_PACKAGE_NAME")}</Text></View>
            <View style={styles.w25}><Text style={styles.boldText}>{translationService.translate("TR_MONTH_COUNT")}</Text></View>
            <View style={styles.w25}><Text style={styles.boldText}>{translationService.translate("TR_PRICE")}</Text></View>
          </View>
          <View style={styles.tableBody}>
            <View style={styles.w25}><Text style={styles.normalText}>{PDFData.packageName}</Text></View>
            <View style={styles.w25}><Text style={styles.normalText}>{PDFData.monthCount}</Text></View>
            <View style={styles.w25}><Text style={styles.normalText}>{PDFData.totalPrice}</Text></View>
          </View>
          <View style={styles.hr} />
          <View style={styles.tableBody}>
            <View style={styles.w25}><Text style={styles.normalText}></Text></View>
            <View style={styles.w25}><Text style={styles.normalText}></Text></View>
            <View style={styles.w25}><Text style={styles.normalText}></Text></View>
            <View style={styles.w25}><Text style={styles.boldText}>{translationService.translate("TR_TOTAL")}: {PDFData.totalPrice}</Text></View>
          </View>
        </View>

        <View style={styles.footer}>
          <View>
            {
              invoice && invoice.length ?
                invoice.map((invoiceItem, index) => {
                  if (invoiceItem.name === "recipient") {
                    return <View key={index}>
                      <View style={[styles.textBlock, styles.mt5]}>
                        <Text style={[styles.boldText, styles.w25]}>{invoiceItem.displayName}: </Text>
                        <Text style={[styles.normalText, styles.w75]}>{invoiceItem.description}</Text>
                      </View>
                      <View style={styles.hr} />
                    </View>
                  }
                  if (invoiceItem.name === "address") {
                    return <View key={index}>
                      <View style={[styles.textBlock, styles.mt5]}>
                        <Text style={[styles.boldText, styles.w25]}>{invoiceItem.displayName}: </Text>
                        <Text style={[styles.normalText, styles.w75]}>{invoiceItem.description}</Text>
                      </View>
                      <View style={styles.hr} />
                    </View>
                  }
                  if (invoiceItem.name === "tin") {
                    return <View key={index}>
                      <View style={[styles.textBlock, styles.mt5]}>
                        <Text style={[styles.boldText, styles.w25]}>{invoiceItem.displayName}: </Text>
                        <Text style={[styles.normalText, styles.w75]}>{invoiceItem.description}</Text>
                      </View>
                      <View style={styles.hr} />
                    </View>
                  }
                  if (invoiceItem.name === "account_number") {
                    return <View key={index}>
                      <View style={[styles.textBlock, styles.mt5]}>
                        <Text style={[styles.boldText, styles.w25]}>{invoiceItem.displayName}: </Text>
                        <Text style={[styles.normalText, styles.w75]}>{invoiceItem.description}</Text>
                      </View>
                      <View style={styles.hr} />
                    </View>
                  }
                  if (invoiceItem.name === "bank") {
                    return <View key={index}>
                      <View style={[styles.textBlock, styles.mt5]}>
                        <Text style={[styles.boldText, styles.w25]}>{invoiceItem.displayName}: </Text>
                        <Text style={[styles.normalText, styles.w75]}>{invoiceItem.description}</Text>
                      </View>
                      <View style={styles.hr} />
                    </View>
                  }

                })
                : null
            }
          </View>
        </View>

      </View>
    </Page>
  </Document> : null
}

Font.register({
  family: "Arial",
  src: font
});

const styles = StyleSheet.create({
  body: {
    flexGrow: 1,
    padding: 40
  },
  logo: {
    height: 40,
    width: 40
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerLeftBlock: {
    flexDirection: "row",
    alignItems: "flex-end"
  },
  boldText: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Arial",
    textTransform: 'capitalize'
  },
  normalText: {
    fontSize: 12,
    fontWeight: "thin",
    fontFamily: "Arial",
    marginTop: 2
  },
  textBlock: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  subHeader: {
    // flexDirection: 'row',
    marginTop: 80,
    justifyContent: 'space-between'
  },
  tableHeader: {
    marginTop: 60,
    padding: 5,
    backgroundColor: "#F0F0F0",
    flexDirection: "row",
    justifyContent: 'space-between',
  },
  tableBody: {
    padding: 5,
    flexDirection: "row",
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 60,
  },
  w25: {
    width: "25%",
    textAlign: "left"
  },
  w75: {
    width: "75%",
    textAlign: "left"
  },
  w100: {
    width: "100%",
    textAlign: "left"
  },
  hr: {
    height: 1,
    backgroundColor: "#F0F0F0",
    marginTop: 2,
  },

  mt10: {
    marginTop: 10
  },

  mt5: {
    marginTop: 5
  },

  ml5: {
    marginLeft: 5
  },
  ml7: {
    marginLeft: 7
  },
});

export default GeneratePDF;