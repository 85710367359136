import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import { KEY_WORD_KEY, MINDALAY_ADMIN, SPECIALITY_KEY, SEARCH_KEY } from "../../../../Constants/urlKeys";
import { showOrHideGeneralDashboardLeftMenu } from '../../../../Store/Actions/generalLeftMenu';

const HintWordsSubMenu = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${KEY_WORD_KEY}/${SPECIALITY_KEY}`}
            className={props.location.pathname.includes(`/${SPECIALITY_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_SPECIALITY")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${KEY_WORD_KEY}/${SEARCH_KEY}`}
            className={props.location.pathname.includes(`/${SEARCH_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_SEARCH")}
          </NavLink>
        </li>

      </ul>
    </div>
  </aside> : null;
}

export default withRouter(HintWordsSubMenu);