import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import TranslationService from "../../Services/translationService";

const GamificationModalComponent = ({ cancel, gamificationData }) => {
	const [translationService, setTranslationService] = useState(null);
	const { translations } = useSelector(state => state.translation);
	const [isAnimation, setIsAnimation] = useState(true);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<Modal
			show={!!gamificationData}
			onHide={cancel}
			aria-labelledby="contained-modal-title-vcenter"
			className="congratulations_modal"
			size="lg"
			centered>
			<Modal.Header className="border-none pb-0" closeButton></Modal.Header>
			<Modal.Body>
				<div style={{ maxHeight: "70vh", overflowY: "auto" }}>
					<div className="congratulations_modal_star">
						<img
							src={`${
								gamificationData &&
								gamificationData.badges &&
								gamificationData.badges.length
									? gamificationData.badges[0].imagePath
									: "https://png.pngtree.com/png-vector/20221109/ourmid/pngtree-red-gold-badge-png-image_6427773.png"
							}`}
							className={`p-2 rounded-circle main-image ${
								isAnimation ? "scale-up-center" : ""
							}`}
							alt="/"
						/>
					</div>
					{gamificationData.quest ? (
						<div>
							<h5 className="d-block mb-1">
								<b>{translationService.translate("TR_QUEST")}:</b>
							</h5>
							<div className="d-flex align-items-center pl-3">
								<h6 className="text-muted">{gamificationData.quest.name}</h6>
								<img
									className="ml-3 quest-image"
									src={gamificationData.quest.imagePath}
								/>
							</div>
						</div>
					) : null}

					{gamificationData.badges && gamificationData.badges.length ? (
						<div className="mt-3">
							<h5 className="d-block mb-1">
								<b>{translationService.translate("TR_BADGES")}:</b>
							</h5>
							<div className="d-flex flex-wrap pl-3">
								{gamificationData.badges.map((item, index) => {
									return (
										<div
											key={index}
											className="d-flex align-items-center mb-3 mr-3">
											<h6 className="text-muted">{item.name}</h6>
											<img className="ml-2 item-image" src={item.imagePath} />
											{index + 1 !== gamificationData.badges.length ? (
												<span className="d-block ml-3">|</span>
											) : null}
										</div>
									);
								})}
							</div>
						</div>
					) : null}

					{gamificationData.resources && gamificationData.resources.length ? (
						<div className="mt-3">
							<h5 className="d-block mb-1">
								<b>{translationService.translate("TR_RESOURCES")}:</b>
							</h5>
							<div className="d-flex flex-wrap pl-3">
								{gamificationData.resources.map((item, index) => {
									return (
										<div
											key={index}
											className="d-flex align-items-center mb-3 mr-3">
											<h6 className="text-muted">{item.name}</h6>
											<div className="d-flex align-items-center ml-2">
												<h5 className="mr-1">{item.amount}</h5>
												<img className="ml-1 item-image" src={item.imagePath} />
											</div>
											{index + 1 !== gamificationData.resources.length ? (
												<span className="d-block ml-3">|</span>
											) : null}
										</div>
									);
								})}
							</div>
						</div>
					) : null}

					{gamificationData.fragments && gamificationData.fragments.length ? (
						<div className="mt-3">
							<h5 className="d-block mb-1">
								<b>{translationService.translate("TR_FRAGMENTS")}:</b>
							</h5>
							<div className="d-flex flex-wrap pl-3">
								{gamificationData.fragments.map((item, index) => {
									return (
										<div
											key={index}
											className="d-flex align-items-center mb-3 mr-4">
											<h6 className="text-muted">{item.name}</h6>
											<div className="d-flex align-items-center ml-1">
												<h5 className="mr-1">{item.amount}</h5>
												<img className="ml-1 item-image" src={item.imagePath} />
											</div>
										</div>
									);
								})}
							</div>
						</div>
					) : null}
					{gamificationData.leaderboards &&
					gamificationData.leaderboards.length ? (
						<div className="mt-3">
							<h5 className="d-block mb-1">
								<b>{translationService.translate("TR_LEADERBOARDS")}:</b>
							</h5>
							{gamificationData.leaderboards.map((item, index) => {
								return (
									<div
										key={index}
										className=" mb-3 mr-3 pl-3 d-flex align-items-center">
										<h6 className="text-muted mr-2">{item.name}</h6>
										<div className="d-flex align-items-center">
											<small className="ml-2 d-flex">
												<span className="mr-2 d-flex">
													<span className="text-danger">
														{translationService.translate("TR_OLD_POSITION")}
													</span>
													<b className="d-block ml-1">{item.oldPosition}</b>
												</span>
												<span> </span>
												<span className="text-success text-nowrap">
													{translationService.translate("TR_NEW_POSITION")}
												</span>
												<b className="d-block ml-1">{item.newPosition}</b>
											</small>

											<span> </span>
											{item.newPosition && item.oldPosition ? (
												<span className="d-flex ml-2 align-items-center">
													<span className="d-block ml-1">
														{item.oldPosition - item.newPosition < 0 ? (
															<i
																class="fa-solid fa-arrow-down"
																style={{
																	color: "var(--mindalay--red-color)",
																	fill: "var(--mindalay--red-color)",
																}}></i>
														) : (
															<i
																class="fa-sharp fa-solid fa-arrow-up"
																style={{
																	color: "var(--mindalay--green-color)",
																	fill: "var(--mindalay--green-color)",
																}}></i>
														)}
													</span>
												</span>
											) : null}
										</div>
									</div>
								);
							})}
						</div>
					) : null}

					{gamificationData.progressBadges &&
					gamificationData.progressBadges.length ? (
						<div className="mt-3">
							<h5 className="d-block mb-1">
								<b>{translationService.translate("TR_PROGRESS_BADGES")}:</b>
							</h5>
							{gamificationData.progressBadges.map((item, index) => {
								return (
									<div
										key={index}
										className="d-flex align-items-center mb-3 mr-3 pl-3">
										<h6 className="text-muted">{item.name}</h6>
										<img className="mx-2 item-image" src={item.imagePath} />
										<small className="d-flex">
											<span className="text-success">
												{translationService.translate("TR_COMPLETED")}
											</span>
											<b className="d-block ml-1">{item.completedTasks}</b>
										</small>
										<span> </span>
										<small className="ml-2 d-flex">
											<span className="text-danger text-nowrap">
												{translationService.translate("TR_NOT_COMPLETED")}
											</span>
											<b className="d-block ml-1">
												{item.numberOfTasks - item.completedTasks}
											</b>
										</small>
									</div>
								);
							})}
						</div>
					) : null}

					{gamificationData.message ? (
						<div className="mt-3">
							<span className="d-block mb-2">
								<b>{translationService.translate("TR_MESSAGE")}:</b>
							</span>
							<p>{gamificationData.message}</p>
						</div>
					) : null}
				</div>
			</Modal.Body>
		</Modal>
	) : null;
};

export default GamificationModalComponent;
