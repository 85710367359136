import React, { useEffect, useState } from "react";
import ApiService from "../../Services/apiService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import uuid from "react-uuid";
import { useDispatch } from "react-redux";

const TemplateDocumentForm = ({
	templateData,
	documentBodyData,
	templateDataEmiter,
}) => {
	const [templateModel, setTemplateModel] = useState({
		a1: "",
		a2: "",
		a3: "",
		a4: "",
		a5: "",
		a6: "",
		a7: "",
		a8: "",
		a9: "",
		a10: "",
		a11: "",
		a12: "",
		a13: "",
		a14: "",
		a15: "",
		a16: "",
		a17: "",
		a18: "",
		a19: "",
		a20: "",
		a21: "",
		a22: "",
		a23: "",
		a24: "",
		a25: "",
		a26: "",
		a27: "",
		a28: "",
		a29: "",
		a30: "",
		a31: "",
		a32: "",
		a33: "",
		tableData: [],
	});
	const [templateInfo, setTemplateInfo] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableTds, setTableTds] = useState([]);
	const [tableHasFirstRow, setTableHasFirstRow] = useState(false);
	const [inputsListData, setInputsListData] = useState([]);
	const [listData, setListData] = useState([]);
	const [groupData, setGroupData] = useState([]);
	const [divisions, setDivisions] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (templateData) {
			getTemplatesInfoByTemplateId(templateData.id);
			getAllStudentDivisions([]);
		}
	}, [templateData]);

	useEffect(() => {
		setTemplateInfoData();
	}, [documentBodyData, templateData]);

	const setTemplateInfoData = () => {
		if (documentBodyData) {
			const documentData = JSON.parse(documentBodyData);
			setTemplateModel(prev => ({ ...prev, ...documentData }));
			if (documentData.tableData && documentData.tableData.length) {
				setTableData(documentData.tableData);
			}
		}

		if (templateModel.tableData && templateModel.tableData.length) {
			const valueForCheck = templateModel.tableData.find(
				item => item.number === 1,
			);
			if (!valueForCheck) {
				setTableHasFirstRow(false);
				templateData.body = templateData.body.replace(
					`<tr><td>${1}</td></tr>`,
					``,
				);
			} else {
				setTableHasFirstRow(true);
			}
		}

		templateInfo.forEach((data, index) => {
			const position = data.position;
			let updatedBody = templateData.body;

			switch (data.type) {
				case "table-input-string":
				case "table-input-date":
				case "table-input-number":
				case "table-library":
				case "table-list":
				case "table-sign-part":
				case "table-number-and-date":
				case "table-input-start-end-time":
				case "remove-row-button":
					setTableTds(prev => [...prev, `<td>{a${data.position}}</td>`]);
					if (templateModel.tableData.length) {
						updatedBody = updatedBody.replace(
							`<td>{a${data.position}}</td>`,
							`<td class="col${data.position}-${templateModel.tableData[0].number}">{a${data.position}}</td>`,
						);
						updatedBody = updatedBody.replace(
							`<td>№ {a${data.position}}</td>`,
							`<td class="col${data.position}-${templateModel.tableData[0].number}">№ {a${data.position}}</td>`,
						);
					}
					break;

				case "organization-name":
					templateModel[`a${position}`] = "Your Organization Name"; // Replace with actual organization name
					updatedBody = updatedBody.replace(
						`<p>{a${position}}</p>`,
						`<input id="a${position}" name="a${position}" autocomplete="off" class="custom-input organization-title center" value="Your Organization Name">`,
					);
					break;

				// Add cases for other types similarly

				default:
					break;
			}

			templateData.body = updatedBody;
		});

		// templateDataEmiter(templateModel);
	};

	const getTemplatesInfoByTemplateId = id => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentById(id)
			.then(response => {
				// if (response && response.data) {
				// 	setTemplateModel;
				// 	// setDivisions(response.data);
				// }
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};
	const getAllStudentDivisions = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAllStudentDivisions()
			.then(response => {
				if (response && response.data) {
					setDivisions(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	return <div>{/* Render your component UI */}</div>;
};

export default TemplateDocumentForm;
