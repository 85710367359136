import React, { useState } from 'react';
import pdfIcon from "../../../assets/icons/pdf.png";
import txtIcon from "../../../assets/icons/txt.png";
import xlsIcon from "../../../assets/icons/xls.png";
import docIcon from "../../../assets/icons/doc.png";
import rtfIcon from "../../../assets/icons/rtf.png";
import {
  OBJECT_KEY,
  STRING_KEY,
  VALID_FILE_TYPES_KEY,
  VALID_IMAGE_TYPES_FOR_WEBINAR_KEY,
  VALID_VIDEO_TYPES_FOR_WEBINAR_KEY,
} from '../../../Constants/mainKeys';
import PosterImage from "./../../../assets/images/video-poster.png";
import MainService from '../../../Services/mainService';

const includesData = (file, type) => {
  if (typeof type === STRING_KEY && file.extention.includes(type)) {
    return file.extention
  }
  if (typeof type === OBJECT_KEY) {
    var validType = type.find(element => file.extention.includes(element))
    if (validType) return file.extention
  }
}

const Fiels = (props) => {

  const [ongoingVideoId, setOngoingVideoId] = useState(null);
  const { files } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div className="mt-4">
          <div className="course-pass-media-file-container">
            <div className="row">
              {
                files ? files.map(file => <div key={file.id} className="col-lg-4 col-md-6 col-12 mt-3">
                  <div className="course-pass-media-file-wrapper box-shadow-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        {
                          (() => {
                            switch (file.extention) {
                              case includesData(file, VALID_IMAGE_TYPES_FOR_WEBINAR_KEY):
                                return <img src={file.filePath} alt="/" />;
                              case includesData(file, VALID_VIDEO_TYPES_FOR_WEBINAR_KEY):
                                return <video
                                  className="file-video cursor-pointer"
                                  poster={PosterImage}
                                  autoPlay={true}
                                  onPointerDown={() => setOngoingVideoId(file.id)}
                                >
                                  {
                                    ongoingVideoId === file.id ?
                                      <source src={file.filePath} />
                                      : null
                                  }
                                </video>
                              case includesData(file, VALID_FILE_TYPES_KEY):
                                switch (file.extention) {
                                  case includesData(file, "pdf"):
                                    return <img src={pdfIcon} alt="/" />;
                                  case includesData(file, "txt"):
                                    return <img src={txtIcon} alt="/" />;
                                  case includesData(file, "doc"):
                                    return <img src={docIcon} alt="/" />;
                                  case includesData(file, "rtf"):
                                    return <img src={rtfIcon} alt="/" />;
                                  case includesData(file, "xls"):
                                    return <img src={xlsIcon} alt="/" />;
                                  case includesData(file, "xlsx"):
                                    return <img src={xlsIcon} alt="/" />;
                                  default:
                                    break
                                }
                              default:
                                break;
                            }
                          })()
                        }
                        <p className="ml-3">{file.fileName}</p>
                      </div>
                      <a href={MainService.getDownloadUrl(file.downloadPath ? file.downloadPath : file.filePath)} target="blank" className="float-right brand-color">
                        <i className="fas fa-download" />
                      </a>
                    </div>
                  </div>
                </div>) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fiels;
