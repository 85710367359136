import React, { useCallback, useEffect, useState } from 'react';
import { compose } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import uuid from 'react-uuid';
import TranslationService from "../../Services/translationService";
import Input from "../../Components/Inputs/input";
import ApiService from '../../Services/apiService';
import ButtonSpinner from '../../Components/Spinners/buttonSpinner';
import CloseSvg from '../../Components/Svg/closeSvg';
import { setCurrentCourse } from "../../Store/Actions/course";
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { COURSE_DASHBOARD_KEY, GENERAL_INFO_KEY } from '../../Constants/urlKeys';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';

const spinnerId = uuid();

const CreateCourseForm = props => {
  const dispatch = useDispatch();
  const nameMaxLength = 200;
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const courseCategories = useSelector(state => state.course.courseCategories);
  const buttonSpinners = useSelector(state => state.spinner.buttonSpinners);

  const [translationService, setTranslationService] = useState(null);
  const [name, setName] = useState('');

  const [firstLevelCategoryId, setFirstLevelCategoryId] = useState(null);
  const [secondLevelCategoryId, setSecondLevelCategoryId] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [secondLevelCategories, setSecondLevelCategories] = useState([]);
  const [thirdLevelCategories, setThirdLevelCategories] = useState([]);

  const [currentSpinner, setCurrentSpinner] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setCurrentSpinner(buttonSpinners.find(spinner => spinner === spinnerId));
  }, [buttonSpinners]);

  const setFirstLevelCategory = selectedCategory => {
    if (selectedCategory) {
      setFirstLevelCategoryId(selectedCategory.id);
      setSecondLevelCategories(selectedCategory.children ? selectedCategory.children : selectedCategory.themeChildren);
    }
  }

  const setSecondLevelCategory = selectedCategory => {
    if (selectedCategory) {
      setSecondLevelCategoryId(selectedCategory.id);
      setThirdLevelCategories(selectedCategory.children ? selectedCategory.children : selectedCategory.themeChildren);
    }
  }

  const setThirdLevelCategory = selectedCategory => {
    if (selectedCategory) {
      const currentCategories = [...selectedCategories];
      if (!currentCategories.find(data => +data.themeId === +selectedCategory.id)) {
        currentCategories.push({ themeId: selectedCategory.id, categoryName: selectedCategory.name, categoryId: secondLevelCategoryId, mainCategoryId: firstLevelCategoryId });
        setSelectedCategories(currentCategories);
      }
    }
  }

  const removeCategory = themeId => {
    const categories = [...selectedCategories];
    setSelectedCategories(categories.filter(data => data.themeId !== themeId));
  }

  const onSubmit = event => {
    event.preventDefault();
    if (!name || name.trim().length < 3 || (!selectedCategories || !selectedCategories.length)) {
      setIsInvalidSubmit(true);
    } else {
      setButtonSpinner(spinnerId);
      const formData = new FormData();
      formData.append('name', name);
      // selectedCategories.forEach(category => {
      //   delete category.categoryName;
      // });
      formData.append('newThemes', JSON.stringify(selectedCategories));

      ApiService.courseCreate(formData).then(response => {
        if (response) {
          setCurrentSpinner(null);
          if (response.data && response.data.id) {
            setCurrentCourseHandler(response.data);
            extractButtonSpinner(spinnerId);
            props.history.push(`/${language}/${COURSE_DASHBOARD_KEY}/${response.data.id}/${GENERAL_INFO_KEY}`);
          }
        }
      }).catch(() => setCurrentSpinner(null));
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setCurrentCourseHandler = useCallback(courseData => {
    dispatch(setCurrentCourse(courseData));
  }, []);

  return translationService ?
    <div className="container">
      <div className="row">
        <div className="col-lg-7 col-md-8 col-12">
          <form onSubmit={onSubmit}>
            <div>
              <span className="section-title">{translationService.translate("TR_WRITE_A_TITLE_FOR_YOUR_COURSE")}</span>
              <Input
                type="text"
                id="course-title"
                name="coursetitle"
                value={name}
                isInvalidField={isInvalidSubmit && name.trim().length < 3 ? true : false}
                isInvalidSubmit={isInvalidSubmit}
                placeholder={`${translationService.translate("TR_TYPE")}...`}
                labelValue={translationService.translate("TR_CREATE_COURSE_NAME_MSG")}
                labelClassName="mt-2"
                onChange={event => {
                  if (event.target.value.length <= nameMaxLength) {
                    setName(event.target.value);
                  } else { return; }
                }}
              />
              {
                name.length < 3 && isInvalidSubmit ?
                  <small className="red-color">{translationService.translate("TR_AT_LASTED_THREE_CHARACTERS")}</small>
                  : null
              }
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <span className="title mt-4">{translationService.translate("TR_CREATE_COURSE_CATEGORIES_TITLE")}?</span>
              </div>
              <div className="col-12">
                <label className="mt-2">{translationService.translate("TR_CREATE_COURSE_CATEGORIES_SUB_TITLE")}.</label>
              </div>
              <div className="col-md-6 col-12 my-1">
                <ReactSelectOption
                  name="firstLevelCategoryId"
                  value={firstLevelCategoryId}
                  defaultValue=" "
                  isDisabled={selectedCategories.length}
                  isInvalidSubmit={isInvalidSubmit}
                  items={courseCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                  onChange={setFirstLevelCategory}
                />
              </div>
              <div className="col-md-6 col-12 my-1">
                {
                  firstLevelCategoryId ? <ReactSelectOption
                    name="secondLevelCategoryId"
                    value={secondLevelCategoryId}
                    defaultValue=" "
                    isInvalidSubmit={isInvalidSubmit}
                    items={secondLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                    onChange={setSecondLevelCategory}
                  /> : null
                }
              </div>
              <div className="col-md-6 col-12 my-1">
                {
                  secondLevelCategoryId ? <ReactSelectOption
                    name="themeId"
                    value={selectedCategories.length}
                    defaultValue=" "
                    isInvalidSubmit={isInvalidSubmit}
                    items={thirdLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                    onChange={setThirdLevelCategory}
                  /> : null
                }
              </div>
              <div className="col-12 mt-3">
                {
                  isInvalidSubmit && !selectedCategories.length
                    ? <small className="red-color">{translationService.translate("TR_EMPTY_CATEGORIES_WARNING_MSG")}</small> : null
                }
                <div className="categories-wrapper">
                  {
                    selectedCategories && selectedCategories.map(category => {
                      return <div key={category.themeId} className="selected-category">
                        {category.categoryName}
                        <CloseSvg onClick={() => removeCategory(category.themeId)} />
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
            <button type="submit" className="mindalay--btn-default mt-4">
              {translationService.translate("TR_CREATE_COURSE")}
              {
                currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null
              }
            </button>
          </form>
        </div>
      </div>
    </div>
    : null;
}

export default compose(
  React.memo,
  withRouter
)(CreateCourseForm);