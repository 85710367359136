import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import MainService from '../../Services/mainService';
import TranslationService from "../../Services/translationService";
import UploadSvg from "../Svg/uploadSvg";
import InfoSvg from './../Svg/infoSvg';

const UploadButton = props => {
  const mainService = new MainService();
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);


  return translationService ? <Auxiliary>
    {
      props.labelValue ? <label htmlFor={props.id}>{props.labelValue}</label> : null
    }
    {
      props.infoText ?
        <div className="info-title d-inline-block">
          <div>
            <InfoSvg />
            <span style={{ display: "none" }}>
              {props.infoText}
            </span>
          </div>
        </div>
        : null
    }
    <div
      className={`upload-file-bordered-wrapper
      ${(props.isInvalidSubmit && !props.value) || props.isInvalidField ? "error-bordered is-invalid" : ""}
      ${props.blockClassName}
      `}>
      <button type="button" onClick={mainService.triggerUploadClick} className={`upload-btn m-0 w-100 ${props.className ? props.className : ''}`}>
        <UploadSvg /> {props.text}
        <input multiple={props.isMultiple} type="file" hidden accept={props.accept? props.accept.join(","):null} onChange={props.clicked} />
      </button>
    </div>
  </Auxiliary> : null;
}

export default UploadButton;