import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	STRING_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import ModalComponent from "../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";

function TemplatesInfoData(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [documentTemplat, setDocumentTemplat] = useState([]);
	const [documentTemplatId, setDocumentTemplatId] = useState(null);
	const [documentTemplateInfo, setDocumentTemplateInfo] = useState([]);
	const [documentTemplateInfoId, setDocumentTemplateInfoId] = useState(null);
	const [values, setValues] = useState({
		dtiid: null,
		data: "",
		position: null,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [documentTemplateInfoData, setDocumentCategory] = useState([]);
	const [positions, setPositions] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDocumentTemplate();
		getDocumentTemplateInfo();
		getDocumentTemplateInfoDatas({
			templateId: null,
			templateInfoId: null,
		});
	}, []);

	const changeDocumentTemplatId = item => {
		setDocumentTemplatId(item.value);
		setDocumentTemplateInfoId(null);
		getDocumentTemplateInfoDatas({
			templateId: item.value,
			templateInfoId: null,
		});
	};

	const changeDocumentTemplateInfoID = item => {
		setDocumentTemplatId(null);
		setDocumentTemplateInfoId(item.value);
		getDocumentTemplateInfoDatas({
			templateId: null,
			templateInfoId: item.value,
		});
	};

	const getDocumentTemplateInfo = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentTemplateInfo()
			.then(response => {
				if (response && response.data) {
					setDocumentTemplateInfo([
						{ name: "All", id: null },
						...response.data,
					]);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getDocumentTemplateInfoDatas = ({
		templateId = null,
		templateInfoId = null,
	}) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		(templateId
			? ApiService.getDocumentTemplateInfoDataInTemplateId(templateId)
			: templateInfoId
			? ApiService.getDocumentTemplateInfoDataInTemplateInfoId(templateInfoId)
			: ApiService.getDocumentTemplateInfoDatas()
		)
			.then(response => {
				if (response && response.data) {
					setDocumentCategory(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getDocumentTemplate = (templateId = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentTemplate()
			.then(response => {
				if (response && response.data) {
					setDocumentTemplat([{ id: null, name: "All" }, ...response.data]);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getPositions = id => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getDocumentTemplateInfoDataInTemplateId(id)
			.then(response => {
				if (response && response.data) {
					setPositions(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (fieldName === "dtiid") {
			getPositions(item.id);
			setValues(values => ({
				...values,
				[fieldName]: item.value,
				position: null,
			}));
		} else {
			setValues(values => ({
				...values,
				[fieldName]: item.value,
			}));
		}

		setIsChange(true);
		setIsInvalidSubmit(false);
	};

	const onClose = (close = true) => {
		setValues({
			dtiid: null,
			data: "",
			position: null,
		});
		setPositions([]);
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
	};

	const getModalData = item => {
		if (item && item.dtiid) {
			getPositions(item.dtiid);
		}
		setOpenModal(true);
		if (item) {
			setValues(item);
		}
	};

	const onSave = () => {
		if (!values.dtiid || !values.data.trim().length || !values.position) {
			setIsInvalidSubmit(true);
			return false;
		}
		const spinerID = uuid();
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createDocumentTemplateInfoData(values)
			: ApiService.updateDocumentTemplateInfoData(values)
		)
			.then(response => {
				if (response.data) {
					setDocumentTemplat([...documentTemplat, response.data]);
				} else if (values.id) {
					setDocumentTemplat(
						documentTemplat.map(item => {
							if (item.id === values.id) {
								return {
									...item,
									...values,
								};
							}
							return item;
						}),
					);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					isLarge={true}
					onSubmit={() => {
						onSave();
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<label>{translationService.translate("TR_TEMPLATEE")}*</label>
							<ReactSelectOption
								value={values?.dtiid}
								isInvalidSubmit={isInvalidSubmit && !values?.dtiid}
								selectedValue={(() => {
									const selectedItem = {
										...documentTemplat
											.slice(1)
											.find(data => data.id === values?.dtiid),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label =
											selectedItem.displayString || selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentTemplat
									.slice(1)
									.filter(item => item.id !== values?.dtiid)
									.map(data => ({
										...data,
										label: data.name,
										value: data.id,
									}))}
								onChange={item => onSelectOptionChange(item, "dtiid")}
							/>
						</div>
						<div className="col-12">
							<label>{translationService.translate("TR_INFO_TYPES")}*</label>
							<ReactSelectOption
								value={values.position}
								isInvalidSubmit={isInvalidSubmit && !values.position}
								selectedValue={(() => {
									const selectedItem = {
										...positions.find(data => data.id === values.position),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label =
											selectedItem.name ||
											`${selectedItem.position} ${selectedItem.data}`;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={positions
									.filter(item => item.id !== values.position)
									.map(data => ({
										...data,
										label: data.name || `${data.position} ${data.data}`,
										value: data.id,
									}))}
								onChange={item => onSelectOptionChange(item, "position")}
							/>
						</div>
						<div className="col-12 mt-1">
							<Input
								type="text"
								id="data"
								name="data"
								value={values.data}
								isInvalidSubmit={isInvalidSubmit && !values.data.trim().length}
								labelValue={`${translationService.translate("TR_DATA")}*`}
								onChange={event => onChange(event, "data")}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_TEMPLATE_INFO_DATA")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6">
					<div className="row">
						<div className="col-12 col-md-6 mt-1">
							<ReactSelectOption
								value={documentTemplatId}
								selectedValue={(() => {
									const selectedItem = {
										...documentTemplat.find(
											data => data.id === documentTemplatId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.type || selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentTemplat
									.filter(item => item.id !== documentTemplatId)
									.map(data => ({
										...data,
										label: data.name,
										value: data.id,
									}))}
								onChange={item => changeDocumentTemplatId(item)}
							/>
						</div>
						<div className="col-12 col-md-6 mt-1 ">
							<ReactSelectOption
								value={documentTemplateInfoId}
								selectedValue={(() => {
									const selectedItem = {
										...documentTemplateInfo.find(
											data => data.id === documentTemplateInfoId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label =
											selectedItem.name ||
											`${selectedItem.position} ${selectedItem.type}`;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentTemplateInfo
									.filter(item => item.id !== documentTemplateInfoId)
									.map(data => ({
										...data,
										label: data.name || `${data.position} ${data.type}`,
										value: data.id,
									}))}
								onChange={item => changeDocumentTemplateInfoID(item)}
							/>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default mt-3"
						onClick={() => {
							getModalData();
						}}>
						Create {translationService.translate("TR_TEMPLATE_INFO_DATA")}
					</button>
				</div>
			</div>
			{documentTemplat && documentTemplat.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_POSITION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_DATA")}
										</th>

										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{documentTemplateInfoData.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item ">
														{item.position}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item ">
														{item.data}
													</div>
												</td>

												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={"#"}
																title={translationService.translate("TR_EDIT")}
																onClick={e => {
																	e.stopPropagation();
																	getModalData(item);
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															{/* <Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deletedocumentTemplat(item);
																}}>
																<DeleteSvg />
															</Link> */}
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
}

export default withRouter(TemplatesInfoData);
