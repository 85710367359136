import React from 'react';

const LogSvg = () => {
  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 318.5 360" xmlSpace="preserve">
<path d="M319.2,153.3c-0.2-17.1-6.3-22.2-29.1-25.6c0-10.2,0.9-20.8-0.3-31.2c-0.9-7.4-3.1-15.8-7.6-21.4
 C263.1,52,243.2,29.5,223,7.4c-3.6-3.9-10.2-7-15.4-7C154.7-0.1,101.8,0,48.9,0.1c-15.7,0-19.5,4-19.6,20.5
 c-0.2,30.4-0.1,60.9-0.1,91.3c0,5.1,0,10.1,0,14.9C2.1,133.6,0,136.2,0,163.1c0,25.8,0,51.5,0,77.3c0,26.1,2.4,29.1,29.2,35.6
 c-0.6,30.3-0.1,53.1,0.4,62.3c0.2,3.3,0.7,8.8,3.8,14.7c1.8,3.4,3.9,5.8,5.2,7.1c80.6,0,161.2,0,241.8,0c1-0.9,2.8-2.5,4.4-4.8
 c4.1-5.9,4.5-12.1,4.7-15.5c0.5-9,1-32.3,0.4-63.6c3.3-0.9,5.8-2,8.5-2.2c14.5-1.3,20.5-10.3,20.6-23.6
 C319.5,217.9,319.6,185.6,319.2,153.3z M44.7,15.3c53.1,0,106.6,0,162.2,0c0,21.4,0.5,42-0.2,62.5c-0.4,11.4,3.3,16.2,15.2,15.6
 c17.2-0.8,34.5-0.2,52.8-0.2c0,12.1,0,23.1,0,34.9c-76.5,0-152.7,0-230.1,0C44.7,90.9,44.7,53.7,44.7,15.3z M188,200.9
 c-0.5,29.3-19.1,48.8-46.5,48.8c-26.9-0.1-45.5-20.7-44.8-50c0.7-29.9,19.8-49.1,48.1-48.4C171.5,152,188.5,171.5,188,200.9z
  M34.4,246.9c0-31.1,0-62.1,0-93.9c6.9,0,13.1,0,20.9,0c0,25.5,0,50.5,0,76.9c12.9,0,24.4,0,36.8,0c0,6.3,0,11.1,0,17
 C73.3,246.9,54.4,246.9,34.4,246.9z M274.7,341.2c-76.5,0-153,0-230.4,0c0-22.3,0-44,0-66.5c76.9,0,153.1,0,230.4,0
 C274.7,296.8,274.7,318.5,274.7,341.2z M282.2,237.8c-0.1,2.7-4.8,7.2-7.7,7.4c-14.8,1-30,3-44.3,0.5c-17.6-3.1-28.3-16.2-30.9-34.1
 c-3-20.2,1.1-38.6,19.5-50.6c18.6-12.1,38.5-11.5,60.1-5.1c-1.5,6-2.7,10.8-4.1,16.4c-18.8-3.3-38-7.8-49.6,12
 c-6.8,11.7-6,24.6,2.2,35.8c8.2,11.2,19.9,12.3,33.2,9.7c0-6.1,0-11.6,0-18c-4-0.8-8.3-1.7-13.2-2.7c0-4.8,0-9.3,0-14.7
 c11.3,0,22.3,0,35.1,0C282.6,209.1,283,223.5,282.2,237.8z"/>
<ellipse fill="#fff" cx="142.3" cy="200.7" rx="23.4" ry="32.8"/>
</svg>

}

export default LogSvg;
