import React, { Component } from 'react';
import Auxiliary from './../../../hoc/auxiliary/auxiliary';
import * as moment from "moment";
import MainService from '../../../Services/mainService';
import TranslationService from '../../../Services/translationService';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StarSvg from '../../../Components/Svg/starSvg';
import StarFilled from '../../../Components/Svg/starFilled';
import Textarea from '../../../Components/Inputs/textArea';
import ApiService from '../../../Services/apiService';
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import AlertService from '../../../Services/alertService';
import { ERROR_KEY, RATINGS } from '../../../Constants/mainKeys';
import ShowMoreText from "react-show-more-text";
import EditSvg from '../../../Components/Svg/editSvg';
import InfoSvg from '../../../Components/Svg/infoSvg';

class CurrentStudentFeedback extends Component {

  ratings = RATINGS;

  state = {
    feedBackForm: {
      rating: 0,
      feedBackDescription: "",
      entitytype: 0,
    },
    translationService: null,
    descriptionMaxLength: 2000
  }

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  onMessageChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmitFeedBackFromStudent = (event) => {
    const { courseData, feedBackDataId } = this.props;
    if (!courseData) { return; }
    event.preventDefault();
    const spinnerId = uuid();
    var { rating, feedBackDescription, entitytype } = this.state.feedBackForm;
    if (!rating || !feedBackDescription) {
      this.setState({ isInvalidFeedBackSubmit: true });
    } else {
      this.props.addPageSpinner(spinnerId);
      feedBackDescription = feedBackDescription.trim();
      let data = {
        entitytype,
        entityid: courseData.id,
        title: courseData.name,
        reviewText: feedBackDescription,
        rating,
        id: feedBackDataId
      };
      if (feedBackDataId) {
        for (const i in data) {
          if (i === "title") delete data[i];
          if (i === "entityid") delete data[i];
          if (i === "entitytype") delete data[i];
        }
      } else { delete data.id }

      (!feedBackDataId ? ApiService.sendFeedBackFromStudent(data) : ApiService.updateFeedBackFromStudent(data)).then(() => {
        this.props.removePageSpinner(spinnerId);
        this.props.getCourseById(courseData.id);
        this.setState(prevState => ({
          ...prevState,
          feedBackForm: {
            ...prevState.feedBackForm,
            rating: 0,
            feedBackDescription: "",
          }
        }));
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  onChangeRating = (rating) => {
    if (!rating) return;
    this.setState(prevState => ({
      ...prevState,
      feedBackForm: {
        ...prevState.feedBackForm,
        rating
      }
    }))
  }

  onDescriptionChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      feedBackForm: {
        ...prevState.feedBackForm,
        [event.target.name]: event.target.value,
      }
    }));
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  annulFeedbackData = () => {
    const { feedBackData } = this.props;
    this.setState(prevState => ({
      ...prevState,
      feedBackForm: {
        ...prevState.feedBackForm,
        feedBackDescription: feedBackData.reviewText,
        rating: feedBackData.rating
      }
    }), () => {
      this.props.annulFeedbackData();
    })
  }

  render() {

    const {
      translationService,
      isInvalidFeedBackSubmit,
      descriptionMaxLength
    } = this.state;

    const { rating, feedBackDescription } = this.state.feedBackForm;

    const { feedBackData, feedBackDataId } = this.props;

    return translationService ?
      !feedBackData ?
        <Auxiliary>
          <hr />
          <div className="mindalay--consultation-tab p-0">
            <div className="consultation-feeback-wrapper p-0">
              <h3 className="title">{translationService.translate("TR_YOUR_FEEDBACK")}</h3>
              <div className="rating-process-container justify-content-start my-3">
                {
                  this.ratings && this.ratings.map(r => {
                    if (rating < r) {
                      return <div key={r} onClick={() => this.onChangeRating(r)}><StarSvg /></div>
                    } else {
                      return <div key={r} onClick={() => this.onChangeRating(r)}><StarFilled /></div>
                    }
                  })
                }
              </div>
            </div>
            {
              isInvalidFeedBackSubmit && !rating ?
                <div className="col-12 mb-2 ml-0">
                  <small className="small-error-msg red">{translationService.translate("TR_RATE_THE_COURSE")}</small>
                </div>
                : null
            }
            <form onSubmit={this.onSubmitFeedBackFromStudent}>
              <div className="rating-process-container d-flex">
                {
                  this.ratings && this.ratings.map(r => {
                    if (rating < r) {
                      return <div key={r} onClick={() => this.onChangeRating(r)}><StarSvg /></div>
                    } else {
                      return <div key={r} onClick={() => this.onChangeRating(r)}><StarFilled /></div>
                    }
                  })
                }
              </div>
              <Textarea
                id="feedBackDescription"
                rows="4"
                name="feedBackDescription"
                value={feedBackDescription}
                max={descriptionMaxLength}
                placeholder={translationService.translate("TR_TYPE_YOUR_FEEDBACK")}
                isInvalidSubmit={isInvalidFeedBackSubmit}
                onChange={(event) => this.onDescriptionChange(event, descriptionMaxLength)}
              />
              <div className="text-right">
                <button
                  type="submit"
                  className="mindalay--btn-default"
                >
                  {translationService.translate(!feedBackDataId ? "TR_SUBMIT_FEEDBACK" : "TR_UPDATE_FEEDBACK")}
                </button>
                {
                  feedBackDataId ? <button
                    type="submit"
                    className="mindalay--btn-default ml-2"
                    onClick={() => this.props.cancelUpdateFeedback()}
                  >
                    {translationService.translate("TR_CANCEL")}
                  </button> : null
                }
              </div>
            </form>
          </div>
        </Auxiliary>
        : feedBackData.isApproved ? null : <Auxiliary>
          <hr />
          <div className={`course-feedback p-4 ${!feedBackData.isApproved && !feedBackData.isDeclined ? "light-blue-background" : ""}`}>
            <div className="feedback-wrapper">
              <div className="d-flex">
                <div style={{ backgroundImage: `url(${feedBackData.reviewerAvatarFilePath})` }} className="image-wrapper" ></div>
                <div className="feedback-body">
                  <div className="d-flex align-items-center">
                    <h4 className="feedback-usernam">{feedBackData.reviewerName}</h4>
                    {
                      !feedBackData.isApproved && !feedBackData.isDeclined ?
                        <div className="info-title d-inline-block ml-1">
                          <div>
                            <InfoSvg />
                            <span style={{ display: "none" }}>
                              {translationService.translate("TR_REVIEW_TEXT_INFO")}
                            </span>
                          </div>
                        </div> : null
                    }
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <div className="rating"><div style={{ width: `${feedBackData.rating * 20}%` }}></div></div>
                    <div className="d-flex align-items-center">
                      <p className="feedback-date-time">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBackData.createdOn))).format("MMM DD, H:mm")}</p>
                      {
                        feedBackData.isDeclined ?
                          <div className="table-action-btn-group d-flex align-items-center ml-2 ">
                            <Link
                              to="#"
                              className="edit-btn table-action-btn-mini-svg"
                              onClick={this.annulFeedbackData}
                            >
                              <EditSvg />
                            </Link>
                          </div>
                          : null
                      }
                    </div>
                  </div>
                  {
                    feedBackData.reviewText ?
                      <div className="mt-3">
                        <ShowMoreText
                          lines={2}
                          more={translationService.translate("TR_SHOW_MORE")}
                          less={translationService.translate("TR_LESS")}
                          className="content-css word-break-break-word"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <p> {feedBackData.reviewText}</p>
                        </ShowMoreText>
                      </div>
                      : null
                  }
                </div>
              </div>
              {
                feedBackData.replyText ?
                  <div className="feedback-reply-body">
                    <div className="d-flex">
                      <div style={{ backgroundImage: `url(${feedBackData.replierAvatarFilePath})` }} className="image-wrapper" ></div>
                      <div className="feedback-body">
                        <h4 className="feedback-usernam">{feedBackData.replierName}</h4>
                        <div className="d-flex align-items-center mt-1">
                          <p className="feedback-date-time">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBackData.createdOn))).format("MMM DD, H:mm")}</p>
                        </div>
                        <div className="mt-3 ">
                          <ShowMoreText
                            lines={2}
                            more={translationService.translate("TR_SHOW_MORE")}
                            less={translationService.translate("TR_LESS")}
                            className="content-css word-break-break-word"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            <p> {feedBackData.replyText}</p>
                          </ShowMoreText>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              }
              {
                feedBackData.declineDescription ?
                  <div className="feedback-reply-body">
                    <div className="d-flex">
                      <div className="feedback-body">
                        <h4 className="feedback-usernam">{translationService.translate("TR_REVIEW_WAS_DECLINED")}</h4>
                        <div>
                          <ShowMoreText
                            lines={2}
                            more={translationService.translate("TR_SHOW_MORE")}
                            less={translationService.translate("TR_LESS")}
                            className="content-css word-break-break-word"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            <p> {feedBackData.declineDescription}</p>
                          </ShowMoreText>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>
          </div>
        </Auxiliary>
      : null
  }
}

const mapStateToProps = state => ({
  language: state.language.language,
  user: state.user.user,
  translations: state.translation.translations,
});

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CurrentStudentFeedback);

