import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
import DeleteSvg from '../../Components/Svg/deleteSvg';
import EditSvg from '../../Components/Svg/editSvg';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import { ERROR_KEY, SUCCESS_KEY, TR_DELETE_CONFIRM_MESSAGE_KEY, TR_NO, TR_YES } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { addPageSpinner, removePageSpinner } from '../../Store/Actions/spinner';
import ApiService from './../../Services/apiService';
import { CATEGORIES_KEY, MINDALAY_ADMIN, THEME_FORM_KEY } from '../../Constants/urlKeys';
import InfoSvg from './../../Components/Svg/infoSvg';

const Themes = (props) => {

  const categoryType = 2;
  const defaultValue = { label: "", value: "" };
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const [translationService, setTranslationService] = useState(null);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [mainCategories, setMainCategories] = useState([]);
  const [showThemesCount, setShowThemesCount] = useState(20);

  var [themes, setThemes] = useState([]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getMainCategories(categoryType);
  }, []);

  const getMainCategories = (categoryType) => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getMainCategories(categoryType).then(response => {
      response.data && setMainCategories(response.data);
      dispatch(removePageSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  }

  const getThemesByMainCategoryId = (selectedCategory) => {
    if (!selectedCategory) { return; }
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    setMainCategoryId(selectedCategory.value);
    ApiService.getThemesByMainCategoryId(selectedCategory.value).then(response => {
      if (response.data) {
        setThemes(response.data.reverse());
      } else setThemes([]);
      dispatch(removePageSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  }

  const removeTheme = (currentTheme) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${currentTheme.name} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      dispatch(addPageSpinner(spinnerId));
      ApiService.removeTheme(currentTheme.id).then(() => {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_THEME_CATEGORY_INFO"));
        themes = themes.filter(theme => theme.id !== currentTheme.id);
        setThemes(themes);
        dispatch(removePageSpinner(spinnerId));
      }).catch(error => getFail(error, spinnerId));
    });
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    dispatch(removePageSpinner(spinnerId));
  }

  const redirectToThemeFormPage = () => {
    props.history.push(`/${language}/${MINDALAY_ADMIN}/${CATEGORIES_KEY}/${THEME_FORM_KEY}`)
  }

  return (translationService ? <div className="container">
    <div className="row">
      <div className="col-12">
        <h2 className="content-title p-0">{translationService.translate("TR_THEMES")}</h2>
        <hr />
      </div>
    </div>
    {
      mainCategories && mainCategories.length ?
        <div className="content-body pt-0">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="">{`${translationService.translate("TR_CATEGORY")}`}</label>
                      <div className="info-title d-inline-block ml-1">
                        <div>
                          <InfoSvg />
                          <span style={{ display: "none" }}>
                            {translationService.translate("TR_SELECT_A_CATEGORY_INFO")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <ReactSelectOption
                      defaultValue={defaultValue}
                      value={mainCategoryId}
                      selectedValue={(() => {
                        const selectedValue = { ...mainCategories.find(data => data.id === mainCategoryId) };
                        if (selectedValue) {
                          selectedValue.label = selectedValue.name;
                          selectedValue.value = selectedValue.id;
                        }
                        return selectedValue;
                      })()}
                      items={mainCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                      onChange={(item) => getThemesByMainCategoryId(item)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div>
                    <label htmlFor="d-none d-sm-block ">{'\u00A0'}</label>
                    <div className="d-flex">
                      <button to="#" className="mindalay--btn-default" onClick={redirectToThemeFormPage}>
                        {translationService.translate("TR_CREATE_THEME")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            themes && themes.length ?
              themes.map((theme, index) => {
                return (showThemesCount > index) ? <div key={theme.id} className="accordion-wrapper course-pass-section">
                  <div className="card mindalay-card">
                    <ul className="dropdown-sub-menu">
                      <div
                        className="accordion-wrapper course-pass-section"
                      >
                        <div className={`card-header d-flex align-items-center`} >
                          <h5 className="mb-0 text-elipsis card-course-name">{theme.name}</h5>

                          <div className="course-wrapper--instructor">
                            <div className="course-button-group">
                              <div className="course-button-group d-flex mb-1">
                                <Link className="course-edit-button mr-1" to={`/${language}/${MINDALAY_ADMIN}/${CATEGORIES_KEY}/${THEME_FORM_KEY}/${theme.id}`}>
                                  <EditSvg />
                                </Link>
                                <Link className="course-delet-button ml-1" to="#" onClick={() => removeTheme(theme)}>
                                  <DeleteSvg />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div> : null
              })
              : null
          }
          {
            !themes.length && mainCategoryId ?
              <div className="no-data-container mt-3">
                <div className="no-data-wrapper">
                  <p>{translationService.translate("TR_NO_THEMES")}</p>
                  <img src={NoDataImage} alt="/" />
                </div>
              </div>
              : null
          }
          <div className="row">
            <div className="col-12 mt-3">
              {
                themes && (themes.length > showThemesCount)
                  ? <button type="button" className="mindalay--btn-dark w-100" onClick={() => setShowThemesCount(showThemesCount + 20)}>
                    {translationService.translate("TR_SHOW_MORE")}
                  </button> : null
              }
            </div>
          </div>
        </div>
        : null
    }
  </div> : null
  );
}

export default withRouter(Themes);
