import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import EditSvg from "../../Components/Svg/editSvg";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	INFO_KEY,
	SUCCESS_KEY,
	WARNING_KEY,
} from "../../Constants/mainKeys";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import successSvg from "../../assets/icons/alert-icons/check.svg";
import warningSvg from "../../assets/icons/warning.svg";
// import closeSvg from "../../assets/icons/alert-icons/close-white.svg";
import CloseSvg from "../../Components/Svg/closeSvg";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Input from "../../Components/Inputs/input";
import fileSvg from "../../assets/icons/file.png";
import UploadButton from "../../Components/UploadButton/uploadButton";
import MainService from "../../Services/mainService";

const useDebounce = (value, delay) => {
	const [debounceValue, setDebounceValue] = useState(value);
};

function Students(props) {
	const pageSize = 10;
	const dispatch = useDispatch();
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language } = useSelector(state => state.language);
	const [users, setUsers] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [grups, setGrups] = useState([]);
	const [grupId, setGrupId] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [user, setUser] = useState(null);
	const [values, setValues] = useState({
		personalDocumentNumber: "",
		userId: "",
		organizationId: "",
		creatorUserId: "",
		createDate: "",
	});
	const [isChange, setIsChange] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openIsUpdateModal, setIsOpenUpdateModal] = useState(false);
	const [isBulkModal, setIsBulkModal] = useState(false);
	const [file, setFile] = useState(null);
	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getUsers();
		// getGroups();
	}, []);

	const getUsers = (isLoaded = true) => {
		const spinnerId = uuid();
		isLoaded && dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getStudents()
			.then(response => {
				if (response && response.data) {
					setUsers(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				isLoaded && dispatch(removePartialViewSpinner(spinnerId));
			});
	};
	const getStudents = id => {
		if (!id) {
			return getUsers();
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getStudentByGrup(id)
			.then(response => {
				if (response && response.data && response.data.length) {
					setUsers(response.data);
				} else {
					AlertService.alert(INFO_KEY, "This group has no students");
					setUsers([]);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	// const getGroups = id => {
	// 	const spinnerId = uuid();
	// 	dispatch(addPartialViewSpinner(spinnerId));
	// 	ApiService.getGroups()
	// 		.then(response => {
	// 			if (response && response.data && response.data.length) {
	// 				const userTyps = [{ groupName: "All", id: null }, ...response.data];
	// 				setGrups(userTyps);
	// 			}
	// 		})
	// 		.catch(error =>
	// 			AlertService.alert(
	// 				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
	// 				error,
	// 			),
	// 		)
	// 		.finally(() => {
	// 			dispatch(removePartialViewSpinner(spinnerId));
	// 		});
	// };
	const removeImg = () => {
		setFile(null);
		setIsChange(false);
	};

	const onSelectOptionChange = item => {
		setGrupId(item.id);
		getStudents(item.id);
	};

	const handlePageClick = event => {
		getUsers(event.selected + 1, grupId);
		setActivePageNumber(event.selected);
	};

	const getStudent = user => {
		if (!user) return;
		setOpenModal(true);
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		ApiService.getStudentById(user.id)
			.then(response => {
				if (response.data) {
					setUser(response.data);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	const onClose = (close = true) => {
		setOpenModal(false);
		setUser(null);
		setValues({
			personalDocumentNumber: "",
			userId: "",
			organizationId: "",
			creatorUserId: "",
		});
		setIsInvalidSubmit(false);
		setIsChange(false);
		setIsOpenUpdateModal(false);
		setIsBulkModal(false);
		setFile(null);
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	const onSave = () => {
		const spinerID = uuid();
		if (!values.personalDocumentNumber.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			formData.append(key, value);
		});
		dispatch(addModalSpinner(spinerID));
		ApiService.updateStudent(formData)
			.then(response => {
				setUsers(
					users.map(item => {
						if (item.id === values.id) {
							return {
								...item,
								personalDocumentNumber: values.personalDocumentNumber,
							};
						}
						return item;
					}),
				);
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const studentBulkUpload = () => {
		const spinerID = uuid();
		const formData = new FormData();
		formData.append("file", file);
		dispatch(addModalSpinner(spinerID));
		ApiService.studentBulkUpload(formData)
			.then(response => {
				if (response && response.data && response.data.length) {
					setUsers([...users, ...response.data]);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);

				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const openUpdateModal = item => {
		if (!item) return false;
		setIsOpenUpdateModal(true);
		setValues({
			userId: item.userId,
			personalDocumentNumber: item.personalDocumentNumber,
			organizationId: item.organizationId,
			creatorUserId: item.creatorUserId,
			id: item.id,
		});
	};

	const uploadImage = event => {
		setIsInvalidSubmit(false);
		setIsChange(true);
		const file = event.target.files[0];
		mainService
			.readFile(file, ["csv"])
			.then(image => {
				if (!image) {
					return;
				}
				setFile(file);
			})
			.catch(error => {
				AlertService.alert(
					WARNING_KEY,
					"Only allowed format for upload .csv",
					// translationService.translate("TR_VALID_IMAGE_ERROR_MSG"),
				);
			});
	};

	return translationService ? (
		<div className="container mt-4">
			{isBulkModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						studentBulkUpload();
					}}
					addButtonIsDisabled={!isChange}
					isLarge={true}
					title={translationService.translate("TR_STUDENTS_BULK_UPLOAD")}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<div className="form-group position-relative mb-0">
								<div className="d-flex justify-content-center">
									<div
										className="course-media-wrapper"
										style={{
											width: 100,
										}}>
										{file && (
											<div className="upload-file-content">
												<div
													className="img"
													style={{
														backgroundImage: `url(${fileSvg})`,
														minHeight: 150,
													}}
													alt="course-image"></div>
												<div onClick={() => removeImg()}>
													<CloseSvg />
												</div>
											</div>
										)}
									</div>
								</div>

								<UploadButton
									textInputClasses="pl-100"
									text={translationService.translate("TR_UPLOAD_FILE")}
									clicked={uploadImage}
									accept={["csv"]}
								/>
								<small>
									{translationService.translate(
										"TR_ONLY_ALLOWED_FORMAT_FOR_UPLOAD_CSV",
									)}
								</small>
							</div>
						</div>
					</div>
				</ModalComponent>
			)}
			{openIsUpdateModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave(openModal);
					}}
					addButtonIsDisabled={!isChange}
					isLarge={true}
					title={translationService.translate(
						"TR_CHANGE_STUDENT_DOCUMENT_NUMBER",
					)}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<Input
								type="text"
								id="personalDocumentNumber"
								name="personalDocumentNumber"
								inputClassName="pr--5"
								value={values.personalDocumentNumber}
								fieldLength={64}
								labelValue={translationService.translate("TR_DOCUMENT_NUMBER")}
								onChange={event =>
									onChange(event, "personalDocumentNumber", 64)
								}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			{openModal && (
				<ModalComponent
					cancel={onClose}
					isLarge={true}
					title={user?.studentName}>
					{user ? (
						<div className="row">
							<div className="col-12">
								<div className="row d-flex justify-content-center">
									<div className="org-student-info-block">
										<img src={user.avatarFilePath} alt="/" />
									</div>
								</div>
								<hr className="mt-2" />
							</div>
							<div className="col-12">
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_NAME",
									)}:`}</b>
									<span>{user.organizationName}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_GROUP_NAME",
									)}:`}</b>
									<span>{user.groupName}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_DOCUMENT_NUMBER",
									)}:`}</b>
									<span>{user.personalDocumentNumber}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_EMAIL",
									)}:`}</b>
									<span>{user.email}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ENTITY_USER_NAME",
									)}:`}</b>
									<span>{user.username}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_FIRSTNAME",
									)}:`}</b>
									<span>{user.firstname}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_LASTNAME",
									)}:`}</b>
									<span>{user.lastname}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_MIDDLENAME",
									)}:`}</b>
									<span>{user.middlename}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_MOBILEPHONE",
									)}:`}</b>
									<span>{user.mobilePhone}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_PHONE",
									)}:`}</b>
									<span>{user.phone}</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_SEND_NOTIFICATION",
									)}:`}</b>
									<span>
										{user.sendNotification ? (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-check transaction-successful fs-30"></i>
											</div>
										) : (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-times transaction-unsuccessful fs-30"></i>
											</div>
										)}
									</span>
								</div>
								<hr className="mt-2" />
								<div className="d-flex justify-content-between mx-1">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_SEND_EMAIL",
									)}:`}</b>
									<span>
										{user.sendEmail ? (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-check transaction-successful fs-30"></i>
											</div>
										) : (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-times transaction-unsuccessful fs-30"></i>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_STUDENTS")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate(
							"TR_PAYMENT_PACKAGE_HISTORY",
						)}
						pageName="admin_students"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />

			<div className="row align-items-end">
				{grups && !!grups.length && (
					<div className="col-12  mb-2">
						<ReactSelectOption
							value={grupId}
							selectedValue={(() => {
								const selectedItem = {
									...grups.find(data => data.id === grupId),
								};
								if (Object.keys(selectedItem).length) {
									selectedItem.label = selectedItem.groupName;
									selectedItem.value = selectedItem.id;
									return selectedItem;
								} else {
									return {
										value: null,
										label: translationService.translate("Choose"),
									};
								}
							})()}
							items={grups
								.filter(item => item.id !== grupId)
								.map(data => ({
									...data,
									label: data.groupName,
									value: data.id,
								}))}
							onChange={item => onSelectOptionChange(item, "parentId")}
						/>
					</div>
				)}
				<div className="col-12 mb-2">
					<div className="d-flex justify-content-end">
						<Link
							to={`/${language}/student-form`}
							className="mindalay--btn-default mr-2">
							{translationService.translate("TR_CREATE_STUDENT")}
						</Link>
						<Link
							to={"#"}
							className="btn btn-primary"
							onClick={event => {
								event.stopPropagation();
								setIsBulkModal(true);
							}}>
							{translationService.translate("TR_STUDENTS_BULK_UPLOAD")}
						</Link>
					</div>
				</div>
			</div>
			{users && users.length ? (
				<div className="row mt-2">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_DOCUMENT_NUMBER")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_GROUP_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_USERNAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_FULLNAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_EMAIL")}
										</th>

										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{users.map((item, index) => {
										return (
											<tr
												key={index}
												onClick={() => {
													getStudent(item);
												}}>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.personalDocumentNumber}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.groupName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.username}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.studentName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.email}
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={`#`}
																title={translationService.translate("TR_EDIT")}
																onClick={event => {
																	event.stopPropagation();
																	openUpdateModal(item);
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{/* {pagination && pagination.TotalPages > 1 ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={true}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null} */}
		</div>
	) : null;
}

export default withRouter(Students);
