import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../Services/translationService";
import ArrowBackSvg from "../Components/Svg/arrowBackSvg";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../Constants/mainKeys";
import uuid from "react-uuid";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import Input from "../Components/Inputs/input";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import ActionButton from "../Components/ActionButton/actionButton";
import MainService from "../Services/mainService";
import moment from "moment";
import Textarea from "../Components/Inputs/textArea";

const buttonSpinnerId = uuid();

function PaymentPackageForm(props) {
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { paymentId } = props.match.params;
	const dispatch = useDispatch();
	const [values, setValues] = useState({
		allowSaveVideo: false,
		cost: "",
		courseService: false,
		currencyId: null,
		description: "",
		documentService: false,
		fileLibraryService: false,
		packageName: "",
		storageSize: "",
		userMaxCount: "",
		userTypeService: false,
		webinarService: false,
		webinarStudentsMaxCount: "",
		whiteLabelService: false,
		gamificationService: false,
		innerPaymentService: false,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [currencys, setCurrency] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getCurrency();
	}, []);

	useEffect(() => {
		if (paymentId) {
			getPaymentPackage(paymentId);
		}
	}, [paymentId]);

	const getPaymentPackage = id => {
		if (!id) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDeveloperPaymentPackage(id)
			.then(response => {
				if (response.data) {
					const newValues = {};
					Object.entries(values).forEach(([key, value]) => {
						if (response.data.hasOwnProperty(key) && response.data[key]) {
							newValues[key] = response.data[key];
						}
					});
					newValues.id = id;
					setValues({ ...values, ...newValues });
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getCurrency = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCurrency()
			.then(response => {
				if (response.data && response.data.length) {
					setCurrency(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		let regex = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/u;
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onNumberChange = (event, fieldname, maxValue = Infinity) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsInvalidSubmit(false);
		const phonePattern = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
		const mobilePhonePattern = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
		if (
			(fieldname === "phone" && !phonePattern.test()) ||
			(fieldname === "mobilePhone" && !mobilePhonePattern.test())
		) {
			return false;
		}
		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			setValues(values => ({ ...values, [fieldname]: event.target.value }));
		}
	};

	const onSelectOptionChange = (item, key) => {
		if (!item) return null;
		setIsInvalidSubmit(false);
		setValues(values => ({ ...values, [key]: item.value }));
	};

	const onCheckBoxChange = (event, name) => {
		setValues(values => ({
			...values,
			[name]: event.target.checked,
		}));
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	const onSubmit = e => {
		e.preventDefault();
		let clon = { ...values };
		if (
			!values.packageName.trim().length ||
			!values.storageSize ||
			!values.userMaxCount ||
			!values.webinarStudentsMaxCount ||
			!values.currencyId ||
			!values.cost
		) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		(!paymentId
			? ApiService.createDeveloperPaymentPackage(values)
			: ApiService.updateDeveloperPaymentPackage(values)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setIsInvalidSubmit(false);
				props.history.push(`/${language}/developer/payment-packages`);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	return (
		translationService && (
			<div className="container">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<Link
								to={`/${language}/developer/payment-packages`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{!paymentId
									? translationService.translate("TR_CREATE_PAYMENT_PACKAGE")
									: translationService.translate("TR_UPDATE_PAYMENT_PACKAGE")}
							</h2>
						</div>
					</div>
				</div>
				<hr />

				<form className="row" onSubmit={onSubmit}>
					<div className="col-12 col-md-6">
						<Input
							type="text"
							id="packageName"
							name="packageName"
							inputClassName="pr--5"
							value={values.packageName}
							isInvalidSubmit={
								isInvalidSubmit && !values.packageName.trim().length
							}
							labelValue={`${translationService.translate("TR_NAME")}*`}
							onChange={event => onChange(event, "packageName")}
						/>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>{translationService.translate("TR_CURRENCY")}*</label>
							<ReactSelectOption
								value={values.currencyId}
								isInvalidSubmit={isInvalidSubmit}
								selectedValue={(() => {
									const selectedItem = {
										...currencys.find(data => data.id === values.currencyId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.isocode;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={currencys
									.filter(item => item.id !== values.currencyId)
									.map(data => ({
										...data,
										label: data.isocode,
										value: data.id,
									}))}
								onChange={item => onSelectOptionChange(item, "currencyId")}
							/>
						</div>
					</div>
					<div className="col-12">
						<Textarea
							rows="3"
							id="description"
							name="description"
							textAreaClassName="pr--5"
							value={values.description}
							labelValue={translationService.translate("TR_DESCRIPTION")}
							onChange={event => onChange(event, "description")}
						/>
					</div>
					<div className={`col-12 col-md-6  my-1`}>
						<div className="position-relative">
							<Input
								type="text"
								id="cost"
								name="cost"
								inputClassName="pr--5"
								value={values.cost}
								// fieldLength={fieldsLength.nameMaxLength}
								isInvalidSubmit={isInvalidSubmit && !values.cost}
								labelValue={`${translationService.translate("TR_COST")}*`}
								onChange={event => onNumberChange(event, "cost")}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6 my-1">
						<Input
							type="text"
							id="storageSize"
							name="storageSize"
							inputClassName="pr--5"
							value={values.storageSize}
							isInvalidSubmit={isInvalidSubmit && !values.storageSize}
							labelValue={`${translationService.translate("TR_STORAGE_SIZE")}*`}
							onChange={event => onNumberChange(event, "storageSize", 100)}
						/>
					</div>
					<div className="col-12 col-md-6  my-1">
						<Input
							type="text"
							id="webinarStudentsMaxCount"
							name="webinarStudentsMaxCount"
							inputClassName="pr--5"
							value={values.webinarStudentsMaxCount}
							isInvalidSubmit={
								isInvalidSubmit && !values.webinarStudentsMaxCount
							}
							labelValue={`${translationService.translate(
								"TR_WEBINAR_STUDENTS_MAX_COUNT",
							)}*`}
							onChange={event =>
								onNumberChange(event, "webinarStudentsMaxCount", 100)
							}
						/>
					</div>
					<div className="col-12 col-md-6 my-1">
						<Input
							type="text"
							id="userMaxCount"
							name="userMaxCount"
							inputClassName="pr--5"
							value={values.userMaxCount}
							isInvalidSubmit={isInvalidSubmit && !values.userMaxCount}
							labelValue={`${translationService.translate(
								"TR_ORGANIZATION_USERS_MAX_COUNT",
							)}*`}
							onChange={event => onNumberChange(event, "userMaxCount", 100)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="allowSaveVideo">
							{translationService.translate("TR_ALLOW_VIDEO_SAVE")}
						</label>
						<InputCheckBox
							id={`allowSaveVideo`}
							name={`allowSaveVideo`}
							checked={values.allowSaveVideo}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "allowSaveVideo");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="documentService">
							{translationService.translate("TR_DOCUMENT_SERVICE")}
						</label>
						<InputCheckBox
							id={`documentService`}
							name={`documentService`}
							checked={values.documentService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "documentService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="userTypeService">
							{translationService.translate("TR_USER_TYPE_SERVICE")}
						</label>
						<InputCheckBox
							id={`userTypeService`}
							name={`userTypeService`}
							checked={values.userTypeService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "userTypeService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="courseService">
							{translationService.translate("TR_COURSE_SERVICE")}
						</label>
						<InputCheckBox
							id={`courseService`}
							name={`courseService`}
							checked={values.courseService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "courseService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="webinarService">
							{translationService.translate("TR_WEBINAR_SERVICE")}
						</label>
						<InputCheckBox
							id={`webinarService`}
							name={`webinarService`}
							checked={values.webinarService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "webinarService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="fileLibraryService">
							{translationService.translate("TR_FILE_LIBRARY_SERVICE")}
						</label>
						<InputCheckBox
							id={`fileLibraryService`}
							name={`fileLibraryService`}
							checked={values.fileLibraryService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "fileLibraryService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="innerPaymentService">Inner payment service</label>
						<InputCheckBox
							id={`innerPaymentService`}
							name={`innerPaymentService`}
							checked={values.innerPaymentService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "innerPaymentService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="gamificationService">Gamification service</label>
						<InputCheckBox
							id={`gamificationService`}
							name={`gamificationService`}
							checked={values.gamificationService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "gamificationService");
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="whiteLabelService">White label service</label>
						<InputCheckBox
							id={`whiteLabelService`}
							name={`whiteLabelService`}
							checked={values.whiteLabelService}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "whiteLabelService");
							}}
						/>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div>
							<ActionButton
								spinnerId={buttonSpinnerId}
								clicked={onSubmit}
								type="submit"
								className="mindalay--btn-default float-right mb-4 px-5"
								name={translationService.translate("TR_SAVE")}
							/>
						</div>
					</div>
				</form>
			</div>
		)
	);
}
export default withRouter(PaymentPackageForm);
