import React, { Component } from 'react';
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { connect } from 'react-redux';
import TranslationService from '../../Services/translationService';
import ApiService from '../../Services/apiService';
import uuid from 'react-uuid';
import AlertService from '../../Services/alertService';
import { ERROR_KEY } from '../../Constants/mainKeys';
import moment from 'moment';
import MainService from '../../Services/mainService';
import MessageSend from '../../Components/Svg/messageSend';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';

class Messages extends Component {
  _isMounted = false;

  state = {
    translationService: null,
    messagesData: [],
    senderId: null,
    receiverId: null,
    messages: [],
    message: '',
    isInvalidSubmit: false,
    partnerName: null,
    messageMaxLength: 2000
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this.getAllMessagesData();
    this.interval = setInterval(this.getChat, 10000);
  };

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevState.messages && this.state.messages && prevState.messages.length !== this.state.messages.length) {
      this.chatScrolToBottom();
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    this._isMounted = false;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    };
    return true;
  };

  chatScrolToBottom = () => {
    var element = document.getElementById("mindalay--consultation-chat");
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  }

  getAllMessagesData = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getAllMessagesData().then(response => {
      this.props.removePageSpinner(spinnerId);
      if (this._isMounted && response.data && response.data.length) {
        const data = [...response.data]
        this.setState({
          messagesData: data,
        }, () => {
          this.getCurrentUserChat(data[0], 0);
        });
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  getCurrentUserChat = (messageData, currentUserIndex) => {
    const { user } = this.props;
    if (!messageData || !user) { return; }
    const { messagesData } = this.state;
    const senderId = user.id;
    const receiverId = messageData.userId;
    const partnerName = messageData ? messageData.userName : ""
    this.setState({ senderId, receiverId, partnerName, currentUserIndex }, () => {
      this.getChat(messagesData, messageData);
    })
  }

  getChat = (messagesData, messageData) => {
    var { receiverId } = this.state;
    if (!receiverId) { return; }
    receiverId && ApiService.getChat(receiverId).then(response => {
      const data = { ...response.data };
      data && this.setState({ messages: data.chat });
      if (messagesData && messagesData.length && messageData) this.resetSenderMessageCount(messagesData, messageData);
    }).catch(error => this.getFail(error, null));
  }

  resetSenderMessageCount = (messagesData, currentSenderData) => {
    messagesData.forEach(element => {
      if (element.userId === currentSenderData.userId) {
        element.unreadMessagesCount = 0;
      }
    });
    this.setState({ messagesData })
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  sendMessage = (event) => {
    event && event.preventDefault();
    var { message, senderId } = this.state;
    const receiverId = [];
    if (!senderId || !this.state.receiverId || !message.trim()) { return; }
    if (!message) {
      this.setState({ isInvalidSubmit: true });
    } else {
      message = message.trim();
      receiverId.push(this.state.receiverId);
      // var data = {
      //   receiverId,
      //   senderId,
      //   message,
      // }
      // ApiService.sendMessage(data).then(response => {
      //   response && this.getChat();
      //   this.setState({ message: '' });
      // }).catch(error => this.getFail(error, spinnerId));
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {
    const { translationService, messagesData, messages, message, senderId, partnerName, isInvalidSubmit, currentUserIndex, messageMaxLength } = this.state;
    const { user } = this.props;
    return (
      translationService ? <div className={user && !user.isOrganizationUser ? "m-section" : ""}>
        <div className="container general-messages">
          <div className="row">
            <div className="col-12">
              <h2 className="section-title">{translationService.translate("TR_MESSAGES")}</h2>
              <hr />
            </div>
            <div className="col-12">
              {
                messagesData.length ?
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12 messages-users-container">
                      <div className="message-users-container-height">
                        {
                          messagesData.map((messageData, index) => {
                            return <div
                              key={index}
                              className={`messages-user-block ${index === currentUserIndex ? "active-message-container" : ""}`}
                              onClick={() => this.getCurrentUserChat(messageData, index)}
                            >
                              <div className="message-user-image" style={{ backgroundImage: `url(${messageData.avatarFilePath})` }} />
                              <h3 className="message-user-name">{messageData.userName}</h3>
                              {messageData.unreadMessagesCount ? <span className="message-unread-count">{messageData.unreadMessagesCount}</span> : ""}
                            </div>
                          })
                        }
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12 mindalay--consultation-tab">
                      <hr className="d-md-none" />
                      <div className="tab-pane " id="consultation-contract--messages-tab" aria-labelledby="consultation-contract--messages-tab">
                        {
                          partnerName ?
                            <strong className="consultation-chat-username">{`${partnerName}`}</strong>
                            : null
                        }
                        <div className="consultation-chat-content-wrapper">
                          <div id="mindalay--consultation-chat" className="consultation-chat-message-container">
                            {
                              messages && messages.length ?
                                messages.map((mess, index) => {
                                  return mess.receiver ?
                                    <div key={index} className="consultation-chat-message-wrapper consultation-chat-currnet-user">
                                      <div className="consultation-chat-message">
                                        <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                                        <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                                      </div>
                                    </div>
                                    :
                                    <div key={index} className="consultation-chat-message-wrapper consultant-chat-sender">
                                      <div className="consultation-chat-message">
                                        <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                                        <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                                      </div>
                                    </div>
                                })
                                : null
                            }
                          </div>
                          {
                            senderId ?
                              <form onSubmit={this.sendMessage}>
                                <div className="consultation-chat-input-wrapper">
                                  <div className="consultation-chat-send-button bg-transparent" id="send-message-button" onClick={this.sendMessage}>
                                    <button className="message-send-button" title={translationService.translate("TR_SEND")}>
                                      <MessageSend />
                                    </button>
                                  </div>
                                  <input
                                    type="text"
                                    className={`form-control ${isInvalidSubmit && (!message || !message.trim()) ? "is-invalid error-bordered" : ""}`}
                                    value={message}
                                    autoComplete="off"
                                    name="message"
                                    placeholder={translationService.translate("TR_TYPE_TEXT")}
                                    onChange={(event) => this.onChange(event, messageMaxLength)}
                                  />
                                </div>
                              </form>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="no-data-container">
                    <div className="no-data-wrapper">
                      <p>{translationService.translate("TR_NO_MESSAGES")}</p>
                      <img src={NoDataImage} alt="/" />
                    </div>
                  </div>
              }
            </div>
          </div>
        </div></div> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  hasUser: state.user.hasUser,
  user: state.user.user,
  language: state.language.language,
  pageSpinners: state.spinner.pageSpinners,
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)

