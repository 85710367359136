import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import OrganizationUserDropdown from "./SubComponents/organizationUserDropdown";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";

const ContentManagerHeader = () => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = () => {
		dispatch(showOrHideGeneralDashboardLeftMenu(!showOrHideGeneralLeftMenu));
	};

	return translationService ? (
		<Auxiliary>
			<header className="relative-layout admin-dashboard-header header instructor-header-zindex white-background">
				<nav className="navbar navbar-expand-md navbar-light navbar-shadow d-flex justify-content-md-end justify-content-between instructor-consultant-navbar">
					{!showOrHideGeneralLeftMenu ? (
						<Link
							to={`/${language}`}
							className="navbar-brand instructor-consultant-header-logo d-none"></Link>
					) : null}
					<Link
						to="#"
						className="burger-btn open-burger-menu nav-link"
						onClick={showOrHideLeftMenu}>
						<span></span>
					</Link>
					<div>
						<ul className="navbar-nav align-items-left align-items-lg-center">
							<li className="nav-item navbar-profile-container header-dropdown">
								<OrganizationUserDropdown />
							</li>
						</ul>
					</div>
				</nav>
			</header>
		</Auxiliary>
	) : null;
};

export default withRouter(ContentManagerHeader);
