// import React, { Component } from 'react';
// import Collapse from 'react-bootstrap/Collapse';
// import Topics from './topics';
// import { connect } from 'react-redux';
// import { compose } from 'redux';

// class Sections extends Component {

//   state = {
//     currentSectionId: this.props.currentSectionId || null,
//   }

//   openSectionData = (id) => {
//     if (!id) { return; }
//     if (id === this.state.currentSectionId) {
//       this.setState({ currentSectionId: null });
//     } else {
//       this.setState({ currentSectionId: id });
//     }
//   }

//   openSectionWithProps = (id) => {
//     if (!id) { return; }
//     this.setState({ currentSectionId: id });
//   }

//   render() {
//     const { sections, user } = this.props;
//     const { currentSectionId } = this.state;
//     return (
//       sections && sections.map(section => {
//         return <div key={section.id} className="accordion-wrapper course-pass-section">
//           <div className="card mindalay-card">
//             <div
//               className="card-header card-header-background-color d-flex justify-content-between align-items-center"
//               aria-controls={`section_${section.id}`}
//               aria-expanded={currentSectionId === section.id}
//               onClick={() => this.openSectionData(section.id)}
//             >
//               <h5 className="mb-0 text-elipsis">{section.name}</h5>
//               <i className="fas fa-angle-down"></i>
//             </div>
//             <Collapse in={currentSectionId === section.id}>
//               <div id={`section_${section.id}`} className="mindalay--collapse-body">
//                 <Topics
//                   topics={section.topics}
//                   showBtnView={this.props.showBtnView}
//                   changeTopicData={this.props.changeTopicData}
//                 />
//               </div>
//             </Collapse>
//           </div>
//         </div>
//       })
//     );
//   }
// }

// const mapStateToProps = state => ({
//   user: state.user,
// });

// const mapDispatchToProps = {};

// export default compose(connect(mapStateToProps, mapDispatchToProps))(Sections)

import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from "react";
import Collapse from "react-bootstrap/Collapse";
import Topics from "./topics";
import GameSvg from "../../../Components/Svg/gameSvg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	ERROR_KEY,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
	GAMIFY_SECTION_ENTITY_TYPE_ID,
} from "../../../Constants/mainKeys";
import GamificationItemsComponent from "../../../OrganizationPages/Gamification/Components/Modals/GamificationItemsComponent";
import GamifyApiService from "../../../Services/gamifyApiService";
import AlertService from "../../../Services/alertService";
import Auxiliary from "./../../../hoc/auxiliary/auxiliary";
import TranslationService from "../../../Services/translationService";

const Sections = forwardRef(
	(
		{
			sections,
			showBtnView,
			changeTopicData,
			topicId = null,
			currentSectionId = null,
			getQuizzesAssignment,
		},
		ref,
	) => {
		const { user } = useSelector(state => state.user);
		const [_currentSectionId, setCurrentSectionId] = useState(currentSectionId);
		const [isLoading, setIsLoading] = useState(null);
		const [selectedSection, setSelectedSection] = useState(null);
		const [gamifyData, setGamifyData] = useState(null);
		const { translations } = useSelector(state => state.translation);
		const [translationService, setTranslationService] = useState(null);

		useEffect(() => {
			setTranslationService(new TranslationService(translations));
		}, [translations]);

		const openSectionData = id => {
			if (!id) {
				return;
			}
			if (id === _currentSectionId) {
				setCurrentSectionId(null);
			} else {
				setCurrentSectionId(id);
			}
		};

		useImperativeHandle(ref, () => ({
			openSectionWithProps,
		}));

		const openSectionWithProps = id => {
			if (!id) {
				return;
			}
			setCurrentSectionId(id);
		};

		const getGamifyDataByEntity = (sectionData, entityType) => {
			if (!sectionData || !entityType || isLoading) {
				return false;
			}
			setIsLoading(sectionData.id);
			const data = {
				entityId: sectionData.id.toString(),
				entityTypeId: entityType,
				parentId: +sectionData.courseId,
				parentTypeId: GAMIFY_COURSE_ENTITY_TYPE_ID,
			};
			GamifyApiService.getGamifyDataByEntity(data)
				.then(response => {
					if (response && response.data) {
						setGamifyData(response.data);
						setSelectedSection(sectionData);
					}
					setIsLoading(null);
				})
				.catch(error => getFail(error));
		};

		const cancel = () => {
			setSelectedSection(null);
			setGamifyData(null);
			setIsLoading(null);
		};

		const getFail = error => {
			error &&
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			setIsLoading(null);
		};

		return (
			!!translationService && (
				<Auxiliary>
					<GamificationItemsComponent
						entityData={selectedSection}
						gamifyData={gamifyData}
						entityTypeId={GAMIFY_SECTION_ENTITY_TYPE_ID}
						cancel={cancel}
					/>
					{sections && sections.length
						? sections.map(section => {
								return (
									<div
										key={section.id}
										className="accordion-wrapper course-pass-section">
										<div className="card mindalay-card">
											<div
												className="card-header card-header-background-color d-flex justify-content-between align-items-center"
												aria-controls={`section_${section.id}`}
												aria-expanded={_currentSectionId === section.id}
												onClick={() => openSectionData(section.id)}>
												<h5 className="mb-0 text-elipsis">{section.name}</h5>
												{user && user.isGamifyAvailable && topicId ? (
													<Link
														className={`course-gamify-button border-none pass-course-gamify-button pass-course-gamify-section-button`}
														to="#"
														title={translationService.translate(
															"TR_GAMIFICATION_ICON",
														)}
														onClick={event => {
															event.stopPropagation();
															getGamifyDataByEntity(
																section,
																GAMIFY_SECTION_ENTITY_TYPE_ID,
															);
														}}>
														{isLoading && isLoading === section.id ? (
															<div
																className="spinner-border mr-2"
																style={{
																	width: "20px",
																	height: "20px",
																	borderWidth: "1px",
																	color: "var(--mindalay--brand-color)",
																}}
															/>
														) : (
															<GameSvg width="25" className="mr-2" />
														)}
													</Link>
												) : null}
												<i className="fas fa-angle-down"></i>
											</div>
											<Collapse in={_currentSectionId === section.id}>
												<div
													id={`section_${section.id}`}
													className="mindalay--collapse-body">
													<Topics
														topics={section.topics}
														showBtnView={showBtnView}
														changeTopicData={changeTopicData}
														getQuizzesAssignment={getQuizzesAssignment}
													/>
												</div>
											</Collapse>
										</div>
									</div>
								);
						  })
						: null}
				</Auxiliary>
			)
		);
	},
);

export default Sections;
