export const MINDALAY_KEY = "Mindalay";
export const LANGUAGE_KEY = "language";
export const DEFAULT_LANGUAGE_KEY = "en";
export const USER_KEY = "user";
export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const MENU_KEY = "menu";
export const CURRENT_COURSE = "currentCourse";
export const PURE_LAYOUT_BACK_KEY = "pureLayoutBack";
export const BACK_URL_KEY = "backUrl";
export const ACTIVE_TAB_KEY = "active-tab";

export const CONSULTATION_CONTRACT_BACK_KEY = "consultationContractBack";
export const CURICULUM_BREAD_CRUMB = "curiculumBreadCrumb";
export const FAQ_KEY = "FAQ";
export const TOPIC_PAGE_KEY = "topicPage";
export const TOPIC_PAGE_PROPERTY_ID_KEY = "topicPagePropertyId";
export const FAQ_PROPERTY_ID_KEY = "FAQPropertyId";
export const WEBINAR_DATA_KEY = "webinarData";
export const FILL_BALANCE_KEY = "fill-balance";
export const IP_KEY = "ip";
export const CURRENCY_KEY = "currency";
export const CURRENCY_SYMBOL_KEY = "currencySymbol";
export const DEFAULT_CURRENCY_SYMBOL_KEY = "$";
export const COUNTRY_KEY = "country";
export const DEFAULT_CURRENCY_KEY = "USD";
export const DEFAULT_IP_KEY = {
	currency: "USD",
};
export const ERROR_KEY = "error";
export const WARNING_KEY = "warning";
export const SUCCESS_KEY = "success";
export const INFO_KEY = "info";
export const PAGE_KEY = "page";
export const BUTTON_KEY = "button";
export const TRANSLATE_KEYS = "translatekeys";
export const WISH_LIST_PRODUCTS_IDS_KEYS = "wishListProductsByIds";
export const SHOPPING_CART_PRODUCTS_IDS_KEYS = "shoppingCartProductsByIds";
export const PAYPAL_SUCCESS_KEY = "paypalsuccess";
export const PAYPAL_CANCEL_KEY = "paypalcancel";
export const CRYLLEX_SUCCESS_KEY = "cryllexsuccess";
export const CRYLLEX_CANCEL_KEY = "cryllexcancel";
export const STRIPE_SUCCESS_KEY = "stripesuccess";
export const STRIPE_CANCEL_KEY = "stripecancel";
export const REMEMBER_ME_KEY = "remember_me";
export const REMEMBER_ME_ITEM_KEY = "remember_me_item";

export const ID_KEY = "id";
export const NUMBER_KEY = "number";
export const BOOLEAN_KEY = "boolean";
export const OBJECT_KEY = "object";
export const STRING_KEY = "string";

export const ADDED_KEY = "added";
export const REMOVED_KEY = "removed";

export const MOVE_KEY = "move";
export const REMOVE_KEY = "remove";

export const IMAGE_KEY = "image";
export const FILE_KEY = "file";
export const VIDEO_KEY = "video";
export const AUDIO_KEY = "audio";
export const QUIZ_KEY = "quiz";

export const SHOPPING_CART_KEY = "shopping-cart";
export const WISH_LIST_KEY = "wish-list";

export const MESSAGE_NOTIFICATION_KEY = "messageNotification";
export const SEND_CALENDAR_AND_WEBINAR_ID_KEY = "SendCalendarAndWebinarId";
export const UNREAD_NOTIFICATION_COUNT_KEY = "unreadNotificationCount";
export const UNREAD_MESSAGES_COUNT_KEY = "unreadMessagesCount";
export const SEND_PAYMENT_APPROVAL_KEY = "sendPaymentApproval";
export const NOTIFY_CONNECT_KEY = "NotifyConnect";
export const REDIRECT_URL_KEY = "redirect-url";

export const TR_CREATE_KEY = "TR_CREATE";
export const TR_UPDATE_KEY = "TR_UPDATE";
export const TR_SUBMIT_KEY = "TR_SUBMIT";
export const TR_SAVE_KEY = "TR_SAVE";

export const TR_MULTI_CHOICE_KEY = "TR_MULTI_CHOICE";
export const TR_MATCHING_QUESTION_KEY = "TR_MATCHING_QUESTION";
export const TR_SHORT_ANSWER_KEY = "TR_SHORT_ANSWER";
export const TR_TRUE_FALSE_KEY = "TR_TRUE_FALSE";
export const TR_NONE_KEY = "TR_NONE";
export const TR_UNIMPORTANT_CASE_KEY = "TR_UNIMPORTANT_CASE";
export const TR_CASE_MUST_MATCH_KEY = "TR_CASE_MUST_MATCH";
export const TR_FIRST_EXPIRE_TYPE_KEY = "TR_FIRST_EXPIRE_TYPE";
export const TR_SECOND_EXPIRE_TYPE_KEY = "TR_SECOND_EXPIRE_TYPE";
export const TR_THIRD_EXPIRE_TYPE_KEY = "TR_THIRD_EXPIRE_TYPE";
export const TR_SECONDS_KEY = "TR_SECONDS";
export const TR_MINUTES_KEY = "TR_MINUTES";
export const TR_HOURS_KEY = "TR_HOURS";
export const TR_DAYS_KEY = "TR_DAYS";
export const TR_WEEKS_KEY = "TR_WEEKS";
export const TR_UNLIMITED_KEY = "TR_UNLIMITED";
export const TR_HIGHEST_GRADE_KEY = "TR_HIGHEST_GRADE";
export const TR_AVERAGE_GRADE_KEY = "TR_AVERAGE_GRADE";
export const TR_FIRST_ATTEMPT_KEY = "TR_FIRST_ATTEMPT";
export const TR_LAST_ATTEMPT_KEY = "TR_LAST_ATTEMPT";
export const TR_ONE_ANSWER_ONLY_KEY = "TR_ONE_ANSWER_ONLY";
export const TR_MULTIPLE_ANSWERS_ALLOWED_KEY = "TR_MULTIPLE_ANSWERS_ALLOWED";
export const TR_NO = "TR_NO";
export const TR_YES = "TR_YES";
export const TR_VALID_IMAGE_MESSAGE_ERROR_KEY = "TR_VALID_IMAGE_MESSAGE_ERROR";
export const TR_VIDEO_VALIDATION_MSG_KEY = "TR_VIDEO_VALIDATION_MSG";
export const TR_DELETE_CONFIRM_MESSAGE_KEY = "TR_DELETE_CONFIRM_MESSAGE";
export const TR_DEFERRED_FEEDBACK = "TR_DEFERRED_FEEDBACK";
export const TR_ADAPTIVE_MODE = "TR_ADAPTIVE_MODE";
export const TR_INTER_MULTI_TRIES = "TR_INTER_MULTI_TRIES";
export const TR_IMMEDIATE_FEEDBACK = "TR_IMMEDIATE_FEEDBACK";

export const ADMIN_USER_TYPE = 2;
export const CURATOR_USER_TYPE = 3;
export const STUDENT_USER_TYPE = 4;
export const LECTURER_USER_TYPE = 5;
export const INSTRUCTOR_USER_TYPE = 6;
export const MET_DEPARTMENT_USER_TYPE = 7;
export const DEVELOPER_USER_TYPE = 9;
export const CONTENT_MANAGER_USER_TYPE = 10;
export const CONSULTANT_USER_TYPE = 11;

export const ALL_VALID_USER_TYPES = [2, 3, 4, 5, 6, 10, 11];

export const COURSE_ENTITY_TYPE = 0;
export const CONSULTATION_ENTITY_TYPE = 7;
export const PAYMENT_PACKAGE_ENTITY_TYPE = 14;
export const ORGANIZATION_ENTITY_TYPE = 16;

export const VALID_IMAGE_TYPES_KEY = ["jpeg", "png", "jpg"];
export const VALID_IMAGE_TYPES_FOR_WEBINAR_KEY = ["jpeg", "png", "jpg", "svg"];
export const VALID_VIDEO_TYPES_FOR_WEBINAR_KEY = ["mp4", "mpg", "avi"];
export const VALID_VIDEO_TYPES_KEY = ["mp4"];
export const VALID_AUDIO_TYPES_KEY = ["mp3"];
export const VALID_FILE_TYPES_KEY = [
	"pdf",
	"txt",
	"doc",
	"rtf",
	"xls",
	"xlsx",
	"docx",
	"pptx",
];
// export const ALL_VALID_FILE_TYPES = ['jpeg', 'png', 'jpg', 'mp4', 'mpg', 'avi', 'pdf', 'txt', 'doc', 'rtf', 'xls', 'xlsx'];
export const ALL_VALID_FILE_TYPES = [
	"jpeg",
	"png",
	"jpg",
	"mp3",
	"mp4",
	"mpg",
	"avi",
	"pdf",
	"txt",
	"doc",
	"rtf",
	"xls",
	"xlsx",
	"docx",
	"pptx",
];

export const ALPHABET_KEY = "abcdefghijklmnopqrstuvwxyz";
export const INVALID_USERNAME_OR_PASSWORD = "invalid_username_or_password";

export const QUESTION_TYPES_ARR_KEY = [
	TR_MULTI_CHOICE_KEY,
	TR_MATCHING_QUESTION_KEY,
	TR_SHORT_ANSWER_KEY,
	TR_TRUE_FALSE_KEY,
];

export const CHOISE_NUMBERING_TYPES_ARR_KEY = [
	TR_NONE_KEY,
	"a, b, c ...",
	"A, B, C ...",
	"1, 2, 3 ...",
];

// export const GRADES_ARR_KEY = [0, 100, 90, 83.33, 80, 75, 70, 66.66, 60, 50, 40, 33.33, 30, 25, 20, 16.66, 14.28, 12.5, 11.11, 10, 5,
//   -5, -10, -11.11, -12.5, -14.28, -16.66, -20, -25, -30, -33.33, -40, -50, -60, -66.66, -70, -75, -80, -83.33, -90, -100];

// export const POSITIVE_GRADES_ARR_KEY = [0, 100, 90, 83.33, 80, 75, 70, 66.66, 60, 50, 40, 33.33, 30, 25, 20, 16.66, 14.28, 12.5, 11.11, 10, 5];

export const MULTI_ANSWER_TYPES = [
	TR_ONE_ANSWER_ONLY_KEY,
	TR_MULTIPLE_ANSWERS_ALLOWED_KEY,
];

export const ANSWER_CASE_TYPES = [
	TR_UNIMPORTANT_CASE_KEY,
	TR_CASE_MUST_MATCH_KEY,
];

export const QUIZ_ATTEMPT_STATUS_TYPES = [
	"TR_INPROGRESS",
	"TR_OVERDUE",
	"TR_FINISHED",
	"TR_ABANDONED",
];

export const COURSE_LEVELS = [
	"TR_ALL_LEVELS",
	"TR_BEGINNERS",
	"TR_INTERMEDIATE",
	"TR_EXPERT",
];

export const CONSULTATION_LEVELS = [
	"TR_BEGINNERS",
	"TR_INTERMEDIATE",
	"TR_EXPERT",
];

export const RATINGS = [1, 2, 3, 4, 5];

export const ASC_DESC_FILTER_DATA = [
	"TR_A_Z",
	"TR_Z_A",
	"TR_PUBLISHED",
	"TR_UNPUBLISHED",
];
export const A_Z_Z_A_KEY = ["TR_A_Z", "TR_Z_A"];

export const EXPIRE_TYPES_ARR_KEY = [
	TR_FIRST_EXPIRE_TYPE_KEY,
	TR_SECOND_EXPIRE_TYPE_KEY,
	TR_THIRD_EXPIRE_TYPE_KEY,
];
export const TIME_TYPES_ARR_KEY = [
	TR_SECONDS_KEY,
	TR_MINUTES_KEY,
	TR_HOURS_KEY,
	TR_DAYS_KEY,
	TR_WEEKS_KEY,
];
export const ALLOWED_ATTEMPTS_ARR_KEY = [
	TR_UNLIMITED_KEY,
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
];
export const GRADING_METHODS_ARR_KEY = [
	TR_HIGHEST_GRADE_KEY,
	TR_AVERAGE_GRADE_KEY,
	TR_FIRST_ATTEMPT_KEY,
	TR_LAST_ATTEMPT_KEY,
];
// export const QUESTION_BEHAVIOUR = [TR_DEFERRED_FEEDBACK, TR_ADAPTIVE_MODE, TR_INTER_MULTI_TRIES, TR_IMMEDIATE_FEEDBACK];
export const QUESTION_BEHAVIOUR = [TR_DEFERRED_FEEDBACK, TR_INTER_MULTI_TRIES];

export const PAYMENT_TYPES = [
	{ value: 0, label: "TR_WITHDRAW" },
	{ value: 1, label: "TR_DEPOSIT" },
	{ value: 2, label: "TR_PURCHASE" },
	{ value: 3, label: "TR_SALE" },
	{ value: 5, label: "TR_COURSE_PERIOD_EXTENSION" },
	{ value: 6, label: "TR_REFUND" },
];

export const PAYMENT_HISTORY_TABLE_FIELDS = [
	{
		transactionType: 0,
		headerFields: [
			"TR_PRICE",
			"TR_TRANSACTION_DATE",
			"TR_PROVIDER",
			"TR_STATUS",
		],
	},
	{
		transactionType: 1,
		headerFields: [
			"TR_PRICE",
			"TR_TRANSACTION_DATE",
			"TR_PROVIDER",
			"TR_STATUS",
		],
	},
	{
		transactionType: 2,
		headerFields: [
			"TR_PRICE",
			"TR_SERVICE_TYPE",
			"TR_SERVICE_NAME",
			"TR_OTHER_USER_NAME",
			"TR_TRANSACTION_DATE",
		],
	},
	{
		transactionType: 3,
		headerFields: [
			"TR_PRICE",
			"TR_SERVICE_TYPE",
			"TR_SERVICE_NAME",
			"TR_OTHER_USER_NAME",
			"TR_TRANSACTION_DATE",
			"TR_UNFREEZE_DATE",
		],
	},
	{
		transactionType: 5,
		headerFields: [
			"TR_PRICE",
			"TR_SERVICE_TYPE",
			"TR_SERVICE_NAME",
			"TR_OTHER_USER_NAME",
			"TR_TRANSACTION_DATE",
		],
	},
	{
		transactionType: 6,
		headerFields: [
			"TR_PRICE",
			"TR_SERVICE_TYPE",
			"TR_SERVICE_NAME",
			"TR_TRANSACTION_DATE",
		],
	},
];

export const PAYMENT_TYPES_ORG_ADMIN = [
	{ value: 0, label: "TR_WITHDRAW" },
	{ value: 1, label: "TR_DEPOSIT" },
	{ value: 2, label: "TR_PURCHASE" },
	{ value: 8, label: "TR_DIRECT_PURCHASE" },
];
export const PAYMENT_TYPES_ORG_STUDENT = [
	// { value: 0, label: "TR_ALL" },
	{ value: 1, label: "TR_DEPOSIT" },
	{ value: 7, label: "Inner payment" },
];

export const ENTITY_TYPE = [
	{ value: 0, label: "TR_COURSE" },
	{ value: 7, label: "TR_CONSULTATION" },
];
export const PAYMENT_RECEIPT_TYPES = [
	{ value: 0, label: "TR_ALL" },
	{ value: 1, label: "TR_ACCEPTED" },
	{ value: 2, label: "TR_REJECTED" },
];
export const ENTITY_TYPES = [
	{ value: -1, label: "TR_ALL" },
	{ value: 0, label: "TR_COURSES" },
	{ value: 7, label: "TR_CONSULTATIONS" },
];
export const ENTITY_TYPES_WITH_PAYMENT_PACKAGES = [
	{ value: -1, label: "TR_ALL" },
	{ value: 0, label: "TR_COURSES" },
	{ value: 7, label: "TR_CONSULTATIONS" },
	{ value: 16, label: "TR_PAYMENT_PACKAGES" },
];

export const CONFIRM_ARRAY = [TR_NO, TR_YES];

export const QUIZ_REVIEW_OPTIONS_CATEGORY_TYPE = "0";
export const FILE_LIBRARY_CATEGORY_TYPE = "1";
export const COURSE_CATEGORIES_TYPE_KEY = "2";
export const FILE_LIBRARY_CATEGORY_TYPE_KEY = "1";

export const VISITIBILTY_DATA = [
	"TR_ALWAYS_VISIBLE",
	"TR_YOU_HAVE_TO_COMPLATE_TOPIC",
];
export const COMPLETION_DATA = [
	"TR_AUTOCOMPLETE",
	"TR_PASS_QUIZ",
	"TR_PASS_VIDEO",
	"TR_PASS_ASSIGNMENT",
];

export const TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY =
	"TR_NOT_SUBMITTED_FOR_VERIFICATION";
export const TR_SUBMITTED_FOR_VERIFICATION_KEY =
	"TR_SUBMITTED_FOR_VERIFICATION";
export const TR_DENIED_KEY = "TR_DENIED";
export const TR_VERIFIED_BUT_NOT_PUBLISHED_KEY =
	"TR_VERIFIED_BUT_NOT_PUBLISHED";
export const TR_PUBLISHED_KEY = "TR_PUBLISHED";

export const SHOPPING_CART_COUNT_KEY = "shoppingCartCount";
export const WISH_LIST_COUNT_KEY = "wishListCount";

export const RETURN_URL_KEY = "returnurl";
export const LANGUAGES_KEY = "languages";
export const PAGES_KEY = "pages";
export const ROLE_KEY = "role";
export const INVALID_DATE_KEY = "Invalid date";

export const INVALID_TIME_KEY = "Invalid time";
export const VALID_TIME_KEY = "Valid time";

export const TICKET_CHAT_TYPE_KEY = 3;
export const CONSULTATION_CHAT_TYPE_KEY = 4;

export const CATEGORY_TYPES_KEY = [
	{ value: 0, label: "TR_QUIZ_REVIEW_OPTIONS" },
	{ value: 1, label: "TR_FILE_LIBRARY" },
	{ value: 2, label: "TR_COURSE" },
	{ value: 3, label: "TR_PAGE" },
];

export const WEEK_DAYS_KEY = [
	"TR_SUNDAY",
	"TR_MONDAY",
	"TR_TUESDAY",
	"TR_WEDNESDAY",
	"TR_THURSDAY",
	"TR_FRIDAY",
	"TR_SATURDAY",
];

export const MONTH_ARR_KEY = [
	"TR_JANUARY",
	"TR_FEBRUARY",
	"TR_MARTH",
	"TR_APRIL",
	"TR_MAY",
	"TR_JUNE",
	"TR_JULY",
	"TR_AUGUST",
	"TR_SEPTEMBER",
	"TR_OCTOBER",
	"TR_NOVEMBER",
	"TR_DECEMBER",
];

export const TRANSLATION_TYPES_KEY = [
	"Default",
	"Admin",
	"Site",
	"Validation",
	"Exception",
	"Email",
	"Notification",
	"Page",
	"User type",
	"Category",
];

export const GAMIFY_COURSE_ENTITY_TYPE_ID = 1;
export const GAMIFY_SECTION_ENTITY_TYPE_ID = 6;
export const GAMIFY_TOPIC_ENTITY_TYPE_ID = 2;
export const GAMIFY_QUIZ_ENTITY_TYPE_ID = 3;
export const GAMIFY_GROUP_ENTITY_TYPE_ID = 4;
export const GAMIFY_ASSIGNMENT_ENTITY_TYPE_ID = 5;

export const PAYMENT_SISTEM_PENDING_STATUSES = [1];
export const PAYMENT_SISTEM_SUCCESS_STATUSES = [2];
export const PAYMENT_SISTEM_CANCELED_STATUSES = [3, 4, 5, 6, 7, 8, 9, 10, 11];
