export const COURSES_KEY = 'courses';
export const COURSE_KEY = 'course';
export const CREATE_COURSE_KEY = 'createcourse';
export const CURICULUMN_KEY = 'curiculumn';
export const GENERAL_INFO_KEY = 'general-info';
export const MESSAGES_KEY = 'messages';
export const ALL_MESSAGES_KEY = 'all-messages';
export const SEEALL_KEY = 'seeall';
export const TARGET_STUDENTS_KEY = 'target-students';
export const PRICING_KEY = 'pricing';
export const PROMOTIONS_KEY = 'promotions';
export const QUIZ_VIEW_KEY = 'quiz-view';
export const QUIZ_ATTEMPT_KEY = 'quiz-attempt';
export const QUESTION_BANK_KEY = 'question-bank';
export const FORM_KEY = 'form';
export const QUIZ_FORM_KEY = 'quiz-form';
export const TOPIC_FORM_KEY = 'topic-form';
export const MEDIA_FORM_KEY = 'media-form';
export const GROUP_FORM_KEY = 'group-form';
export const FILE_LIBRARY_CATEGORY_KEY = 'filelibrarycategory';
export const FILE_LIBRARY_KEY = 'file-library';
export const FILELIBRARY_KEY = 'filelibrary';
export const LOGIN_KEY = 'login';
export const REGISTRATION_KEY = 'registration';
export const FORGOT_PASSWORD_KEY = 'forgot-password';
export const CHANGE_PASSWORD_KEY = 'changePassword';
export const TERMS_KEY = 'terms';
export const DATA_POLICY_KEY = 'data-policy';
export const COOKIES_POLICY_KEY = 'cookies-policy';
export const SETTINGS_KEY = 'settings';
export const VIEW_KEY = 'view';
export const QUESTION_KEY = 'question';
export const PROFILE_KEY = 'profile';
export const PROFILE_PICTURE_KEY = 'profile-picture';
export const PROFILE_SETTINGS_KEY = 'profile-settings';
export const EDIT_PROFILE_KEY = 'edit-profile';
export const BECOME_INSTRUCTOR_KEY = 'become-instructor';
export const COMUNICATION_KEY = 'communication';
export const ASSIGNMENTS_KEY = 'assignments';
export const BECOME_CONSULTANT_KEY = 'become-consultant';
export const CONSULTANT_KEY = 'consultant';
export const CONSULTANTS_KEY = 'consultants';
export const CONSULTANT_INFORMATION_KEY = 'consultant-information';
export const CREATE_CONSULTATION_KEY = 'create-consultation';
export const CONSULTANT_DASHBOARD_KEY = 'consultant-dashboard';
export const STUDENT_CONSULTATION_DASHBOARD_KEY = 'student-consultation-dashboard';
export const CONSULTANT_CONSULTATION_CONTRACTS_KEY = 'consultant-consultation-contracts';
export const STUDENT_CONSULTATION_CONTRACTS_KEY = 'student-consultation-contracts';
export const ON_GOING_KEY = 'onGoing';
export const START_TIME_KEY = 'startTime';
export const STATE_KEY = 'state';
export const ATTEMPT_ID_KEY = 'attemptId';
export const GRACE_PERIOD_END_DATE_KEY = 'gracePeriodEndDate';
export const INSTRUCTOR_KEY = 'instructor';
export const STUDENT_KEY = 'student';
export const COURSE_DASHBOARD_KEY = 'coursedashboard';
export const PURE_KEY = 'pure';
export const PASS_COURSE_KEY = 'passcourse'
export const CATEGORY_KEY = 'category';
export const CATEGORIES_KEY = 'categories';
export const PASS_COURSE = 'pass-course';
export const PASS_COURSE_VIDEO_KEY = 'video';
export const OVERVIEW_KEY = 'overview';
export const FINANCES_KEY = 'finances';
export const ALL_CONSULTATIONS_KEY = 'all-consultations';
export const CONSULTANT_FEED_KEY = 'consultant-feed';
export const NOTIFICATIONS_KEY = "notifications";
export const TICKETS_KEY = "tickets";
export const TICKET_KEY = "ticket";
export const ADMIN_TICKET_KEY = "adminticket";
export const CHECKOUT_KEY = "checkout";
export const MINDALAY_ADMIN = 'mindalay-admin';
export const LOGS_KEY = 'logs';
export const PAYMENT_LOGS_KEY = 'payment-logs';
export const BALANCE_LOGS_KEY = 'balance-logs';
export const VERIFIED_KEY = 'verified';
export const UNVERIFIED_KEY = 'unverified';
export const FEEDBACKS_KEY = 'feedbacks';
export const FEEDBACK_KEY = 'feedback';
export const TOPIC_KEY = 'topic';
export const IN_PROCESS_KEY = 'in-process';
export const RESOLVED_KEY = 'resolved';
export const HOME_KEY = 'home';
export const KEY_WORD_KEY = 'key-word';
export const SPECIALITY_KEY = 'speciality';
export const SEARCH_KEY = 'search';
export const DISCOUNT_KEY = 'discount';
export const VIDEO_KEY = 'video';
export const INFO_KEY = 'info';
export const MINDALAY_FOR_BUSINESS_KEY = 'mindalay-for-business';
export const MINDALAY_FOR_INSTITUTIONS_KEY = 'mindalay-for-institutions';
export const LEARN_IN_MINDALAY_KEY = 'learn-in-mindalay';
export const TEACH_IN_MINDALAY_KEY = 'teach-in-mindalay';
export const FROM_INFO_PAGE_KEY = 'from-info-page';
export const HELP_KEY = "help";
export const FAQ_PATH_KEY = "FAQ";
export const ONE_TIME_WEBINAR_KEY = "onetimewebinar";
export const CREATE_ONE_TIME_WEBINAR_KEY = "video-conference";
export const VIDEO_CONFERENCE_KEY = "video-conference";
export const B2B_KEY = "b2b";
export const VIDEO_CONFERENCE_ROOM_KEY = "video-conference-room";
export const WEBINARS_KEY = "webinars";
export const WEBINAR_KEY = "webinar";
export const WEBINAR_CALENDAR_KEY = "webinar-calendar";
export const COMMUNICATION_KEY = "communication";
export const CHAT_KEY = "chat";
export const THEME_KEY = "theme";
export const THEME_FORM_KEY = "theme-form";
export const ORGANIZATION_KEY = "organization";
export const PAYMENT_KEY = "payment";
export const GROUP_KEY = "group";
export const ORGANIZATION_FORM_KEY = "organization-form";
export const ORGANIZATION_ADMIN_KEY = "organization-admin";
export const PAYMENT_PACKAGE_KEY = "payment-package";
export const PAYMENT_PACKAGES_KEY = "payment-packages";
export const HISTORY_KEY = "history";
export const NOR_FOUND_KEY = "not-found";
export const PAYMENT_RECEIPT_KEY = "payment-receipt";
export const PAYMENT_HISTORY_KEY = "payment-history";
export const DOCUMENT_KEY = "document";
export const DOCUMENTS_KEY = "documents";
export const DOCUMENT_VIEW_KEY = "document-view";
export const DOCUMENT_FORM_KEY = "document-form";
export const CUSTOM_PAYMENT_PACKAGE_KEY = "custom-payment-package";
export const SCHEDULE_KEY = "schedule";
export const SCHEDULES_KEY = "schedules";
export const PROGRAM_KEY = "program";









