import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'bootstrap';
import { Tabs } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import TranslationService from '../../../Services/translationService';
import InProcess from '../Components/inProcess';
import Completed from '../Components/completed';
import Proposal from '../Components/proposal';
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import ArrowBackSvg from '../../../Components/Svg/arrowBackSvg';
import ApiService from '../../../Services/apiService';
import AlertService from '../../../Services/alertService';
import uuid from 'react-uuid';
import { ASC_DESC_FILTER_DATA, CONSULTATION_CHAT_TYPE_KEY, ERROR_KEY, SUCCESS_KEY } from "../../../Constants/mainKeys";
import ModalComponent from '../../../Components/ModalComponent/modalComponent';
import Textarea from '../../../Components/Inputs/textArea';
import Interview from '../Components/interview';
import { compose } from 'redux';
import { ALL_CONSULTATIONS_KEY, STUDENT_CONSULTATION_CONTRACTS_KEY, STUDENT_CONSULTATION_DASHBOARD_KEY } from '../../../Constants/urlKeys';
import { setConsultationContractBackUrl } from './../../../Store/Actions/main';

class ForStudentView extends Component {
  _isMounted = false;
  ascDescFilterData = ASC_DESC_FILTER_DATA;
  consultationChatType = CONSULTATION_CHAT_TYPE_KEY;

  state = {
    key: this.props.match.params.contractState || 'proposal',
    consultationId: this.props.match.params.consultationId || null,
    translationService: null,
    defaultProposalContracts: [],
    proposalContracts: [],
    inProcessContracts: [],
    completedContracts: [],
    interviewContracts: [],
    showModal: false,
    coverLetter: '',
    coverLetterMaxLength: 2000,
    contractId: null,
    contract: null,
    proposalContractsConsultantAccepted: [],
    proposalContractsStudentAccepted: [],
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this.getStudentContracts();
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAllStudentContracts = () => {
    this.setState({ key: "proposal", consultationId: null }, () => {
      this.getStudentContracts();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  acceptContract = (contract) => {
    var { proposalContracts, contractId, consultationId, consultantId, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.acceptContractStudent(contract ? contract.consultationId : consultationId, contract ? contract.consultantId : consultantId).then(response => {
      if (response) {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_ACCEPTED"));
        this.props.removePageSpinner(spinnerId);
        this.getStudentContracts();
        if (contract) {
          proposalContracts = proposalContracts.filter(proposalContract => proposalContract.id !== contract.id);
        } else {
          proposalContracts = proposalContracts.filter(proposalContract => proposalContract.id !== contractId);
        }
        this.setState({ proposalContracts, showModal: false });
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  declineContract = (id) => {
    const { proposalContracts, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.declineContractStudent(id).then(() => {
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
      this.props.removePageSpinner(spinnerId);
      let proposalContractsConsultantAccepted = [];
      let filteredProposalContracts = proposalContracts.filter(contract => contract.id !== id);
      filteredProposalContracts.forEach(contract => {
        if (contract.consultantAccepted && !contract.studentAccepted) {
          proposalContractsConsultantAccepted.push(contract);
        }
      });
      this.setState({
        proposalContracts: filteredProposalContracts,
        proposalContractsFiltered: filteredProposalContracts,
        proposalContractsConsultantAccepted,
      });
    }).catch(error => this.getFail(error, spinnerId));
  }

  getStudentContracts = () => {
    const { consultationId } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getStudentContracts().then(response => {
      var defaultProposalContracts = [];
      var proposalContracts = [];
      var interviewContracts = [];
      var inProcessContracts = [];
      var completedContracts = [];
      if (response.data) {
        var data = [...response.data];
        if (consultationId) {
          data = data.filter(elem => +elem.consultationId === +consultationId)
        }
        data && data.forEach(contract => {
          contract.showMoreDesc = false;
          contract.isShowDropdown = false;
          if (!contract.studentAccepted || !contract.consultantAccepted) {
            proposalContracts.push(contract);
            defaultProposalContracts.push(contract);
          }
          if (contract.studentAccepted && contract.consultantAccepted && !contract.signed) {
            interviewContracts.push(contract);
          }
          if (contract.signed && !contract.isCompleted) {
            inProcessContracts.push(contract);
          }
          if (contract.isCompleted) {
            completedContracts.push(contract);
          }
        })

        proposalContracts = proposalContracts ? proposalContracts.reverse() : [];
        // interviewContracts = interviewContracts ? interviewContracts.reverse() : [];
        // inProcessContracts = inProcessContracts ? inProcessContracts.reverse() : [];
        // completedContracts = completedContracts ? completedContracts.reverse() : [];

        var proposalContractsConsultantAccepted = [];
        var proposalContractsStudentAccepted = [];

        proposalContracts.forEach(contract => {
          if (!contract.consultantAccepted && contract.studentAccepted) {
            proposalContractsStudentAccepted.unshift(contract);
          }
          if (contract.consultantAccepted && !contract.studentAccepted) {
            proposalContractsConsultantAccepted.unshift(contract);
          }
        })

        this._isMounted && this.setState({
          defaultProposalContracts,
          proposalContracts,
          proposalContractsFiltered: proposalContracts,
          inProcessContracts,
          inProcessContractsFiltered: inProcessContracts,
          interviewContracts,
          interviewContractsFiltered: interviewContracts,
          completedContracts,
          completedContractsFiltered: completedContracts,
          proposalContractsConsultantAccepted,
          proposalContractsStudentAccepted
        });
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  redirectToConsultationContracts = (id) => {
    this.props.setConsultationContractBackUrl(this.props.location.pathname);
    this.props.history.push(`/${this.props.language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${id}`);
  }

  onChangeShowMoreDesc = (index, array, event) => {
    const contractArray = [...this.state[array]];
    const contractElem = contractArray[index];
    contractElem.showMoreDesc = !contractElem.showMoreDesc
    contractArray[index] = contractElem;
    this.setState({ [array]: contractArray });
    event.stopPropagation();
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  openOrCloseModal = (contract) => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
      contractId: contract.id,
      consultantUserId: contract.consultantUserId,
      consultationId: contract.consultationId,
      consultantId: contract.consultantId,
      contract
    });
  }

  filterContract = (filteredArray, defaultArray, name) => {
    if (filteredArray !== null) {
      this.setState({ [name]: filteredArray });
    } else {
      this.setState({ [name]: this.state[defaultArray] });
    }
  }

  filterProposalContracts = (filteredArray) => {
    const { defaultProposalContracts } = this.state;
    var proposalContractsConsultantAccepted = [];
    var proposalContractsStudentAccepted = [];
    if (filteredArray !== null) {
      filteredArray.forEach(contract => {
        if (!contract.consultantAccepted && contract.studentAccepted) {
          proposalContractsStudentAccepted.unshift(contract);
        }
        if (contract.consultantAccepted && !contract.studentAccepted) {
          proposalContractsConsultantAccepted.unshift(contract);
        }
      })
      this.setState({ proposalContractsStudentAccepted, proposalContractsConsultantAccepted });
    } else {
      defaultProposalContracts.forEach(contract => {
        if (!contract.consultantAccepted && contract.studentAccepted) {
          proposalContractsStudentAccepted.unshift(contract);
        }
        if (contract.consultantAccepted && !contract.studentAccepted) {
          proposalContractsConsultantAccepted.unshift(contract);
        }
      })
      this.setState({ proposalContractsStudentAccepted, proposalContractsConsultantAccepted });
    }
  }

  onChangeIsShowDropdown = (index, array, event) => {
    const contractArray = [...this.state[array]];
    const contractElem = contractArray[index];
    contractElem.isShowDropdown = !contractElem.isShowDropdown
    contractArray[index] = contractElem;
    this.setState({ [array]: contractArray });
    event.stopPropagation();
  }

  goBack = () => {
    window.history.back();
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {
    const {
      translationService,
      key,
      showModal,
      coverLetterMaxLength,
      coverLetter,
      proposalContractsFiltered,
      interviewContractsFiltered,
      inProcessContractsFiltered,
      completedContractsFiltered,
      proposalContracts,
      inProcessContracts,
      interviewContracts,
      completedContracts,
      contract,
      proposalContractsConsultantAccepted,
      proposalContractsStudentAccepted,
    } = this.state;

    return (translationService ? <section className="m-section">
      {
        showModal ?
          <ModalComponent
            title={contract ? contract.consultation.name : ''}
            contentClassName="resource-modal"
            isLarge="xl"
            actionButtonTitle={translationService.translate("TR_CONFIRM")}
            cancel={() => this.openOrCloseModal(contract)}
            onSubmit={this.acceptContract}
          >
            <div className="d-flex resource-modal">
              <div className="tab-content scroll" role="tablist">
                <div id="pane-image" className="card tab-pane fade show active h-100 border-0" role="tabpanel"
                  aria-labelledby="tab-image">
                  <div id="collapse-image" className="collapse show" role="tabpanel" aria-labelledby="heading-image">
                    <div className="card-body">
                      {
                        contract.consultation?.description ?
                          <p className='word-break-break-word'>{contract.consultation.description}</p>
                          : null
                      }
                      <Textarea
                        rows="6"
                        id="coverLetter"
                        min="0"
                        max={coverLetterMaxLength}
                        name="coverLetter"
                        blockClassName="mt-4"
                        value={coverLetter}
                        textAreaClassName="pr--5"
                        labelValue={`${translationService.translate("TR_COVER_LETTER")}`}
                        onChange={(event) => this.onChange(event, coverLetterMaxLength)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalComponent>
          : null
      }
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-md-10 col-12">
            <h2 className="content-title p-0">
              <Link to={`/${this.props.language}/${ALL_CONSULTATIONS_KEY}`} title={translationService.translate("TR_BACK")}>
                <ArrowBackSvg />
              </Link>
              {translationService.translate("TR_CONSULTATION_CONTRACTS")}
            </h2>
            <hr />
            <div>
              <Link
                to={`/${this.props.language}/${STUDENT_CONSULTATION_DASHBOARD_KEY}`}
                className="mindalay--btn-link mindalay--btn-dark mt-md-0 mt-3 m--w-md-100 float-right ml-md-3"
                onClick={this.getAllStudentContracts}
              >
                {translationService.translate("TR_ALL_CONTRACTS")}
              </Link>
              <div className="tab-title-group tab-content">
                <Tabs
                  activeKey={key}
                  onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey="proposal" title={translationService.translate("TR_PROPOSAL")}>
                    <Proposal
                      proposalContracts={proposalContractsFiltered}
                      contracts={proposalContracts}
                      proposalContractsConsultantAccepted={proposalContractsConsultantAccepted}
                      proposalContractsStudentAccepted={proposalContractsStudentAccepted}
                      filterContract={this.filterProposalContracts}
                      acceptContract={this.acceptContract}
                      declineContract={this.declineContract}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                      openOrCloseModal={this.openOrCloseModal}
                      isStudent={true}
                    />
                  </Tab>
                  <Tab eventKey="interview" title={translationService.translate("TR_INTERVIEW")}>
                    <Interview
                      interviewContracts={interviewContractsFiltered}
                      contracts={interviewContracts}
                      filterContract={this.filterContract}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                      redirectToConsultationContracts={this.redirectToConsultationContracts}
                    />
                  </Tab>
                  <Tab eventKey="in-process" title={translationService.translate("TR_IN_PROCESS")}>
                    <InProcess
                      inProcessContracts={inProcessContractsFiltered}
                      contracts={inProcessContracts}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                      filterContract={this.filterContract}
                      redirectToConsultationContracts={this.redirectToConsultationContracts}
                      onChangeIsShowDropdown={this.onChangeIsShowDropdown}
                    />
                  </Tab>
                  <Tab eventKey="completed" title={translationService.translate("TR_COMPLETED")}>
                    <Completed
                      completedContracts={completedContractsFiltered}
                      contracts={completedContracts}
                      filterContract={this.filterContract}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                      redirectToConsultationContracts={this.redirectToConsultationContracts}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user,
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  setConsultationContractBackUrl
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ForStudentView);

