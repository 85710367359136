import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NUMBER_KEY } from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import SearchSvg from "../Svg/searchSvg";

const Search = ({ searchData, searchFieldName, emitSearchedData, triggerSearch, onChange, isDisabled }) => {
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    triggerSearch && search(searchValue);
  }, [triggerSearch]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const search = searchValue => {
    setSearchValue(searchValue);
    onChange && onChange(searchValue);
    if (searchData) {
      searchData[0]?.consultation
        ? emitSearchedData(searchValue ? searchData.filter(data => (data.consultation[searchFieldName].toLowerCase()).includes(searchValue.toLowerCase())) : null, searchValue)
        : emitSearchedData(searchValue ? searchData.filter(data => (data[searchFieldName].toLowerCase()).includes(searchValue.toLowerCase())) : null, searchValue)
    }
  }

  const onNumberChange = (event) => {
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) >= 0)) {
      search(event.target.value)
    }
  }

  return translationService ? <div className="mindalay--search-input-wrapper form-group mb-lg-0 mb-2">
    <input
      type="text"
      value={searchValue}
      placeholder={translationService.translate("TR_SEARCH")}
      className="form-control"
      disabled={isDisabled ? isDisabled : false}
      onChange={searchFieldName !== "ticketNumber" ? event => search(event.target.value) : event => onNumberChange(event)}
    />
    <div className="mindalay--search-input">
      <SearchSvg />
    </div>
  </div> : null;
}

export default Search;