import React from "react"
import { withRouter } from "react-router-dom"
import Auxiliary from "../../hoc/auxiliary/auxiliary"
import ContentManagerRoutes from "../../Routing/contentManagerRoutes"
import ContentManagerHeader from "../LayoutSections/Header/contentManagerHeader"
import ContentManagerFooter from "../LayoutSections/Footer/contentManagerFooter"
import ContentManagerLeftMenu from "../LayoutSections/LeftMenu/ContentManagerLeftMenu"

const ContentManagerLayout = (props) => {

  return (
    <Auxiliary>
      <ContentManagerHeader />
      <ContentManagerLeftMenu />
      <section className={`${props.location.pathname.includes("/help") ? "" : "instructior-dashboard-body--large"} `}>
        <div className="top-section wrapper pb-5">
          <ContentManagerRoutes />
        </div>
        <ContentManagerFooter />
      </section>
    </Auxiliary>
  );
}

export default withRouter(ContentManagerLayout);

