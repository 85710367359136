import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import TranslationService from "../../Services/translationService";
import ModalSpinner from "../Spinners/modalSpinner";

import {
  FacebookShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  // EmailShareButton,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TumblrShareButton,
  // VKShareButton,
  // WorkplaceShareButton,

  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  ViberIcon,
  // GooglePlusIcon,
  // PinterestIcon,
  // VKIcon,
  // OKIcon,
  // RedditIcon,
  // TumblrIcon,
  // LivejournalIcon,
  // MailruIcon,
  // WorkplaceIcon,
  // EmailIcon,
} from "react-share";
import { withRouter } from "react-router";

const ShareModalComponent = (props) => {
  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const modalSpinners = useSelector(state => state.spinner.modalSpinners);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);
  
  return (
    translationService &&
    <Modal
      size={props.isLarge ? "lg" : ""}
      show={true}
      onHide={props.cancel}
      contentClassName={props.contentClassName}
      aria-labelledby={props.ariaLabelledby}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalSpinner spinners={modalSpinners} />
        <div>
          <FacebookShareButton url={props.url} onClick={props.cancel}>
            <FacebookIcon size={35} round={true} />
          </FacebookShareButton>
          <TelegramShareButton url={props.url} onClick={props.cancel}>
            <TelegramIcon size={35} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton url={props.url} onClick={props.cancel}>
            <WhatsappIcon size={35} round={true} />
          </WhatsappShareButton>
          <TwitterShareButton url={props.url} onClick={props.cancel} >
            <TwitterIcon size={35} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={props.url} onClick={props.cancel}>
            <LinkedinIcon size={35} round={true} />
          </LinkedinShareButton>
          <ViberShareButton url={props.url} onClick={props.cancel}>
            <ViberIcon size={35} round={true} />
          </ViberShareButton>
        </div>
        {/* {props.children} */}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="mindalay--btn-default mindalay--btn-small" onClick={props.cancel}>
          {translationService.translate("TR_CANCEL")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default withRouter(ShareModalComponent);

