import React, { Component } from 'react';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import * as moment from "moment";
import MainService from '../../../Services/mainService';
import TranslationService from '../../../Services/translationService';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ShowMoreText from "react-show-more-text";

class StudentFeedback extends Component {

  state = {
    feedBackDataCount: 5,
    translationService: null,
  }

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  render() {
    const { translationService, feedBackDataCount, } = this.state;
    const { feedBackData } = this.props;

    return (
      translationService ? <Auxiliary>
        {feedBackData ?
          feedBackData.map((feedBack, index) => {
            return feedBackDataCount > index ? <div key={feedBack.id} className="course-feedback p-4">
              <div className="feedback-wrapper">
                <div className="d-flex">
                  <div style={{ backgroundImage: `url(${feedBack.reviewerAvatarFilePath})` }} className="image-wrapper" ></div>
                  <div className="feedback-body">
                    <h4 className="feedback-usernam">{feedBack.reviewerName}</h4>
                    <div className="d-flex align-items-center mt-1">
                      <div className="rating"><div style={{ width: `${feedBack.rating * 20}%` }}></div></div>
                      <p className="feedback-date-time">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBack.createdOn))).format("MMM DD, H:mm")}</p>
                    </div>
                    {
                      feedBack.reviewText ?
                        <div className=" mt-3">
                          <ShowMoreText
                            lines={2}
                            more={translationService.translate("TR_SHOW_MORE")}
                            less={translationService.translate("TR_LESS")}
                            className="content-css word-break-break-word"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            <p>{feedBack.reviewText}</p>
                          </ShowMoreText>
                        </div>
                        : null
                    }
                  </div>
                </div>
                {
                  feedBack.replyText ?
                    <div className="feedback-reply-body">
                      <div className="d-flex">
                        <div style={{ backgroundImage: `url(${feedBack.replierAvatarFilePath})` }} className="image-wrapper" ></div>
                        <div className="feedback-body">
                          <h4 className="feedback-usernam">{feedBack.replierName}</h4>
                          <div className="d-flex align-items-center mt-1">
                            <p className="feedback-date-time">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBack.createdOn))).format("MMM DD, H:mm")}</p>
                          </div>
                          <div className="mt-3">
                            <ShowMoreText
                              lines={2}
                              more={translationService.translate("TR_SHOW_MORE")}
                              less={translationService.translate("TR_LESS")}
                              className="content-css word-break-break-word"
                              anchorClass="my-anchor-css-class"
                              expanded={false}
                              truncatedEndingComponent={"... "}
                            >
                              <p>{feedBack.replyText}</p>
                            </ShowMoreText>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div> : null
          })
          : null}
        <div className="row">
          <div className="col-12 mt-3">
            {
              feedBackData && (feedBackData.length > feedBackDataCount)
                ? <button type="button" className="mindalay--btn-default w-100 mb-4" onClick={() => this.setState({ feedBackDataCount: feedBackDataCount + 5 })}>
                  {translationService.translate("TR_SHOW_MORE")}
                </button> : null
            }
          </div>
        </div>
      </Auxiliary> : null
    );
  }
}

const mapStateToProps = state => ({
  language: state.language.language,
  user: state.user.user,
  translations: state.translation.translations,
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(StudentFeedback);

