import React from 'react'

export default function ReportDocumentView(props) {

  const { documentData } = props;

  if (documentData.data && JSON.parse(documentData.data)) {
    var {
      a1,
      a2,
      a3,
      a4,
      a5,
      a6,
      a7,
      a8,
      a10,
      a12,
    } = JSON.parse(documentData.data);
  }

  return (documentData.signFilePath || documentData.filePath ?
    <div className='col-12'>
      <div id='document' className='my-5'>
        <img src={documentData.signFilePath ? documentData.signFilePath : documentData.filePath} alt="/" style={{ width: "100%", maxWidth: "100%", height: "auto" }} />
      </div>
    </div>
    : <div className='row'>
      <div className='col-12'>
        <div id='document' className='my-5 d-flex justify-content-center'>
          <div className='document-body'>
            <p className='text-center'>{a1}</p>
            <h1 className="template-title mt-1">ОТЧЕТ</h1>
            <h3 className="template-sub-title mt-1">о прохождении производственной практики</h3>

            <p className="flex-wrap">
              <span> Обучающегося группы</span>
              <span className='ml-3'>{a2}</span>
            </p>

            <h3 className="center selected-font mt-3">{a3}</h3>

            <p className="flex-wrap mt-4">Место прохождения практики<br />
              <span>{a4}</span>
            </p>
            <p className="flex-wrap">(полное наименование предприятия, учреждения, организации)</p>
            <p className="flex-wrap mt-3 mb-3">Руководитель практики
              <span className='ml-3'>{a5}</span>
            </p>
            <p className="d-flex mt-4">Период прохождения практики с
              {
                a6 ? <span className='mx-2'>{a6}</span> : <span className="empty-row d-block"></span>
              }
              <span className="mx-1">до</span>
              {
                a7 ? <span className='mx-2'>{a7}</span> : <span className="empty-row d-block"></span>
              }
            </p>
            <p className="flex-wrap mt-4">За время прохождения практики освоил следующие виды работ:</p>
            <p className="flex-wrap">
              <span className='mt-2'>
                {a8}
              </span>
            </p>
            <div className="flex-wrap mt-7 d-flex">
              <span>Практикант</span>
              <span className="empty-row d-block ml-2"></span>
              <span className='ml-2'>{a10}</span>
            </div>
            <p className="flex-wrap mt-3">Содержание и объем выполненных работ подтверждаю.</p>
            <div className="flex-wrap mt-3 mb-3 d-flex">
              <span>Руководитель практики</span>
              <span className="empty-row d-block ml-2"></span>
              <span className='ml-2'>{a12}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
