import React from 'react'
import CKEditor from 'ckeditor4-react';

export default function RichTextEditor(props) {

  var config = {
    toolbar: [
      // { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'] },
      // { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Undo', 'Redo'] },
      // { name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: ['Scayt'] },
      // { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      // { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
      { name: 'insert', items: ['Image', 'SpecialChar'] },

      // { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Source'] },
      { name: 'others', items: ['-'] },


      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
      { name: 'styles', items: ['Styles', 'Format'] },
      // { name: 'about', items: ['About'] }
      // { name: 'tools', items: ['Maximize'] },
    ],
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'forms' },
      { name: 'tools' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'others' },
      '/',
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
      { name: 'styles' },
      { name: 'colors' },
      { name: 'about' }
    ]
  }

  const getArticle = () => {
    return new Promise(resolve => {
      resolve(props.value);
    });
  };

  const handleInstanceReady = ({ editor }) => {
    getArticle().then(article => {
      editor.setData(article);
    });
  };

  return (
    <div className={props.blockClassName}>
      {
        props.labelValue ?
          <label>{props.labelValue}</label>
          : null
      }
      <div
        className={`${(props.isInvalidSubmit && !props.value.trim()) || props.isInvalidField ? "is-invalid error-bordered" : ""}`}
      >
        <CKEditor
          config={props.config ? props.config : config}
          onChange={props.onEditorChange}
          onInstanceReady={handleInstanceReady}
        />
      </div>
    </div>
  )
}
