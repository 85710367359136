import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import TranslationService from './../../../Services/translationService';
import PosterImage from "./../.././../assets/images/video-poster.png";

const FileLibraryVideos = (props) => {

    const translations = useSelector(state => state.translation.translations);
    const [translationService, setTranslationService] = useState(null);
    const [ongoingVideoId, setOngoingVideoId] = useState(null);
    const { videos } = props;

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    return translationService ? videos && videos.length ? <div className="mindalay-files-gallery-container">
        <div className="row">
            {
                videos && videos.map((video, index) => {
                    return <div key={index} className="col-lg-4 col-sm-6 col-12 my-2">
                        <div className="mindalay-file-wrapper mindalay-video">
                            <video
                                className="cursor-pointer"
                                poster={PosterImage}
                                autoPlay={true}
                                controls
                                onPointerDown={() => setOngoingVideoId(video.id)}
                            >
                                {
                                    ongoingVideoId === video.id ?
                                        <source src={video.filePath} type="video/mp4" />
                                        : null
                                }
                            </video>
                            <div className="card-body">
                                <p className="card-text">{video.name}</p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
        : <div className="no-data-container mt-3">
            <div className="no-data-wrapper">
                <p>{translationService.translate("TR_YOU_HAVE_NO_VIDEO")}</p>
                <img src={NoDataImage} alt="/" />
            </div>
        </div>
        : null
}

export default FileLibraryVideos;

