import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import MainService from "../../Services/mainService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import SelectOption from "../../Components/SelectOption/selectOption";
import Input from "../../Components/Inputs/input";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner,
  addPartialViewSpinner,
  removePartialViewSpinner
} from "../../Store/Actions/spinner";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import { ERROR_KEY, SUCCESS_KEY, FILE_LIBRARY_CATEGORY_TYPE_KEY, BOOLEAN_KEY, NUMBER_KEY } from "../../Constants/mainKeys";
import { TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from "../../Constants/translationKeys";
import { COURSES_KEY, FILELIBRARY_KEY, FILE_LIBRARY_KEY, INSTRUCTOR_KEY } from "../../Constants/urlKeys";
import SmallQuestionMark from './../../Components/SmallQuestionMark/smallQuestionMark';
import HelpService from './../../Services/helpService';

class FileLibraryCategoryForm extends Component {
  mainService = new MainService();
  state = {
    form: {
      name: '',
      parentId: '',
      categorytype: +FILE_LIBRARY_CATEGORY_TYPE_KEY,
      isPrivate: false
    },
    fileCategoryId: +this.props.match.params.id,
    isInvalidSubmit: false,
    spinnerId: uuid(),
    fileCatgories: [],
    translationService: null,
    failedFields: [],
    nameMaxLength: 100,
  };

  componentDidMount() {
    this.setTranslations();
    this.getFileCategories();
    this.state.fileCategoryId && this.getCategoryById(this.state.fileCategoryId);
  };

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getFileCategories = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getCategoriesByType(FILE_LIBRARY_CATEGORY_TYPE_KEY).then(response => {
      if (response.data) {
        const fileCatgories = this.getCategories(response.data);
        this.setState({ fileCatgories });
      }
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(error => this.getFail(spinnerId, error));
  }

  getCategories = (categories) => {
    let newCategories = [];
    categories.forEach(category => {
      if (this.state.fileCategoryId) {
        if (this.state.fileCategoryId !== category.id) {
          newCategories.push(category);
        }
      } else {
        newCategories.push(category);
      }
      if (category.children && category.children.length) {
        newCategories = [...newCategories, ...this.getCategories(category.children)];
      }
    });
    return newCategories;
  }

  getCategoryById = categoryId => {
    const { spinnerId } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getCategoryById(categoryId).then(response => {
      const categoryData = response.data && response.data[0];
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          name: categoryData.name,
          parentId: categoryData.parentId,
          categoryType: categoryData.filePath,
          isPrivate: categoryData.isPrivate
        }
      }));
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(e => this.getFail(null, e));
  }

  onChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({ ...prevState, failedFields, form: { ...prevState.form, [event.target.name]: event.target.value } }));
  };

  onCheckboxChange = event => {
    typeof event.target.checked === BOOLEAN_KEY && this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.checked
      }
    }));
  };

  onNumberChange = event => {
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0)) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          [event.target.name]: event.target.value
        }
      }));
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { translationService, fileCategoryId, spinnerId } = this.state;
    const form = { ...this.state.form };
    if (!form.name && !form.name.trim()) {
      this.setState({ isInvalidSubmit: true });
    } else {
      this.props.addButtonSpinner(spinnerId);
      form.parentId = +form.parentId;
      form.name = form.name.trim();

      if (fileCategoryId) {
        form.id = fileCategoryId;
      }

      for (let i in form) {
        !form[i] && delete form[i];
      }
      (fileCategoryId
        ? ApiService.categoryUpdate(form)
        : ApiService.categoryCreate(form)).then(() => {
          AlertService.alert(SUCCESS_KEY, translationService.translate(`${fileCategoryId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
          this.props.removeButtonSpinner(spinnerId);
          window.history.back();
        }).catch(e => this.submitFail(spinnerId, e));
    }
  };

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  submitFail = (spinnerId, error) => {
    this.props.removeButtonSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  };

  getFail = (spinnerId, error) => {
    spinnerId && this.props.removePageSpinner(spinnerId);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  };

  goBack = () => {
    const { history, language, user } = this.props;
    if (user.isOrganizationUser) {
      history.push(`/${language}/${FILELIBRARY_KEY}`);
    } else {
      history.push(`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_KEY}`);
    }
  }

  getQuestionMarkData = (key) => {
    if (!key) { return; }
    if (HelpService.setPageName(this.props.location.pathname)) {
      this.props.getCurrentQuestionMarkData(HelpService.setPageName(this.props.location.pathname), key)
    }
  }

  render() {
    const { isInvalidSubmit, spinnerId, fileCatgories, translationService, failedFields, fileCategoryId, nameMaxLength } = this.state;
    const { name, parentId, isPrivate } = this.state.form;
    const { user } = this.props;

    return translationService ? <div className={user.isOrganizationUser ? "m-section" : ""}>
      <div className="col-12">
        <h2 className="content-title p-0">
          <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}><ArrowBackSvg /></Link>
          {translationService.translate(fileCategoryId ? "TR_UPDATE_FOLDER" : "TR_CREATE_FOLDER")}
        </h2>
        <hr />
      </div>
      <div className="col-12">
        <form onSubmit={this.onSubmit}>
          <div className="row">
            {
              fileCatgories.length ?
                <div className="col-12">
                  <SelectOption
                    label={translationService.translate("TR_PARENT_FOLDER")}
                    name="parentId"
                    value={parentId}
                    defaultValue=" "
                    items={fileCatgories}
                    changed={this.onNumberChange}
                  />
                </div>
                : null
            }
            <div className="col-12">
              <Input
                type="text"
                id="name"
                name="name"
                inputClassName="pr--4"
                value={name}
                max={nameMaxLength}
                fieldLength={nameMaxLength}
                isInvalidSubmit={isInvalidSubmit}
                labelValue={`${translationService.translate("TR_NAME")} *`}
                onChange={(event) => this.onChange(event, nameMaxLength)}
                failedFields={failedFields}
              />
            </div>
            {
              user.isOrganizationUser ?
                <div className="col-12 d-flex">
                  <InputCheckBox
                    id="isPrivate"
                    name="isPrivate"
                    checked={isPrivate}
                    labelValue=" "
                    onChange={this.onCheckboxChange}
                    failedFields={failedFields}
                  />
                  <div>
                    <label htmlFor="isPrivate">{translationService.translate("TR_IS_PRIVATE")}</label>
                    <SmallQuestionMark
                      id="is_private"
                      onMouseEnter={() => this.getQuestionMarkData("is_private")}
                    />
                  </div>
                  
                </div>
                : null
            }
            <div className="col-12 mt-3">
              <SubmitButton
                id={fileCategoryId}
                spinnerId={spinnerId}
              />
            </div>
          </div>
        </form>
      </div>
    </div> : null;
  };
};

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user,
  currentQuestionMarkData: state.main.currentQuestionMarkData,
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner,
  addPartialViewSpinner,
  removePartialViewSpinner,
  getCurrentQuestionMarkData
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(FileLibraryCategoryForm);