import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { BACK_URL_KEY, ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import Textarea from "../../Components/Inputs/textArea";
import Input from "../../Components/Inputs/input";
import ActionButton from "./../../Components/ActionButton/actionButton";
import MainService from "../../Services/mainService";
import { addButtonSpinner } from "./../../Store/Actions/spinner";
import ApiService from "./../../Services/apiService";
import { Link, withRouter } from "react-router-dom";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import PageInfoBlock from "./../../Components/PageInfoBlock/PageInfoBlock";
import contactUs from "./../../assets/images/contactUs.svg";
const ContactUs = props => {
	const dispatch = useDispatch();
	const emailMaxLength = 120;
	const nameMaxLength = 100;
	const spinnerId = uuid();
	const descriptionMaxLength = 2000;
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);
	const [pageContent, setPageContent] = useState(null);
	const [description, setDescription] = useState("");
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
	const [isInvalidEmail, setIsInvalidEmail] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [failedFields, setFailedFields] = useState([]);
	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	useEffect(() => {
		getPageContent();
	}, []);
	const getPageContent = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getPagecontent(9)
			.then(response => {
				dispatch(removePageSpinner(spinnerId));
				response.data && setPageContent({ ...response.data });
			})
			.catch(error => getFail(error, spinnerId));
	};
	const removeFailedFields = name => {
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		setFailedFields(failedFields);
	};
	const checkFieldValidation = (field, fieldName) => {
		const isValid = MainService.isValidField(field, fieldName);
		switch (fieldName) {
			case "firstName":
				checkIsinvalidFieldName(isValid, "isInvalidFirstName");
				break;
			case "email":
				checkIsinvalidFieldName(isValid, "isInvalidEmail");
				break;
			default:
				break;
		}
	};
	const checkIsinvalidFieldName = (isValid, name) => {
		switch (name) {
			case "isInvalidFirstName":
				setIsInvalidFirstName(!isValid);
				break;
			case "isInvalidEmail":
				setIsInvalidEmail(!isValid);
				break;
			default:
				break;
		}
	};
	const onSubmit = event => {
		event && event.preventDefault();
		if (!description.trim() || !email.trim() || !name.trim()) {
			setIsInvalidSubmit(true);
			return;
		} else {
			if (!isInvalidEmail) {
				const data = {
					name: name ? name.trim() : "",
					email: email.trim(),
					description: description.trim(),
				};
				dispatch(addButtonSpinner(spinnerId));
				ApiService.contactUs(data)
					.then(() => {
						dispatch(addButtonSpinner(spinnerId));
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(
								"TR_CONTACT_US_MESSAGE_SUCCESSFULLY_SEND",
							),
						);
						setName("");
						setDescription("");
						setEmail("");
						setIsInvalidSubmit(false);
					})
					.catch(error => getFail(error, spinnerId));
			} else {
				return;
			}
		}
	};
	const goBack = () => {
		if (localStorage.getItem(BACK_URL_KEY)) {
			props.history.push(localStorage.getItem(BACK_URL_KEY));
		} else {
			props.history.push(`/${language}`);
		}
		localStorage.removeItem(BACK_URL_KEY);
	};
	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};
	return translationService ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="content-title p-0">
						<Link
							to="#"
							title={translationService.translate("TR_BACK")}
							onClick={goBack}>
							<ArrowBackSvg />
						</Link>
						{translationService.translate("TR_CONTACT_US")}
					</h2>
					<PageInfoBlock
						pageTitle="TR_CONTACT_US"
						pageName="contact_us"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row mt-2">
				<div className="col-12">
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-12 col-lg-6 d-lg-block d-none">
								<div>
									<img src={contactUs} style={{ maxWidth: "100%" }} />
								</div>
							</div>
							<div className="col-lg-6 col-12">
								<Input
									type="text"
									id="name"
									name="name"
									value={name}
									isInvalidField={isInvalidFirstName}
									fieldLength={nameMaxLength}
									isInvalidSubmit={isInvalidSubmit}
									inputClassName={`pr--4`}
									blockClassName="mb-0"
									labelValue={`${translationService.translate("TR_NAME")} *`}
									onBlur={() => checkFieldValidation(name, "firstName")}
									onChange={event => {
										if (event.target.value.length <= nameMaxLength) {
											setName(event.target.value);
										} else {
											return;
										}
										failedFields && removeFailedFields(event.target.name);
									}}
								/>
								{isInvalidFirstName ? (
									<small className="red-color">
										{translationService.translate("TR_INVALID_NAME")}
									</small>
								) : null}
								<Input
									type="email"
									id="email"
									name="email"
									value={email}
									isInvalidField={isInvalidEmail}
									isInvalidSubmit={isInvalidSubmit}
									blockClassName="mb-0 mt-3"
									labelValue={`${translationService.translate("TR_EMAIL")} *`}
									onBlur={() => checkFieldValidation(email, "email")}
									onChange={event => {
										if (event.target.value.length <= emailMaxLength) {
											setEmail(event.target.value);
										} else {
											return;
										}
										failedFields && removeFailedFields(event.target.name);
									}}
								/>
								{isInvalidEmail ? (
									<small className="red-color">
										{translationService.translate("TR_EMAIL_IS_INVALID")}
									</small>
								) : null}
								<Textarea
									rows="5"
									id="description"
									name="description"
									value={description}
									max={descriptionMaxLength}
									blockClassName="mb-0 mt-3"
									labelValue={`${translationService.translate(
										"TR_DESCRIPTION",
									)} *`}
									isInvalidField={
										isInvalidSubmit && (!description || !description.trim())
									}
									onChange={event => {
										if (event.target.value.length <= descriptionMaxLength) {
											setDescription(event.target.value);
										} else {
											return;
										}
										failedFields && removeFailedFields(event.target.name);
									}}
								/>
								<ActionButton
									type="submit"
									spinnerId={spinnerId}
									name={translationService.translate("TR_SEND")}
									className="mindalay--btn-default float-right mt-3"
									clicked={onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	) : null;
};
export default withRouter(ContactUs);
