import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import Dropdown from "react-bootstrap/Dropdown";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InstructorCourseBlock from "../../Components/Course/InstructorCourseBlock";
import Search from "../../Components/Search/search";
import { setPureLayoutBackUrl } from "../../Store/Actions/main";
import { addButtonSpinner, removeButtonSpinner, addPartialViewSpinner, removePartialViewSpinner } from "../../Store/Actions/spinner";
import { COURSES_KEY, COURSE_DASHBOARD_KEY, CREATE_COURSE_KEY, GENERAL_INFO_KEY, INSTRUCTOR_KEY, PURE_KEY, VIEW_KEY } from "../../Constants/urlKeys";
import {
  ERROR_KEY,
  SUCCESS_KEY,
  TR_NO,
  TR_YES,
  TR_DELETE_CONFIRM_MESSAGE_KEY,
  TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY,
  TR_SUBMITTED_FOR_VERIFICATION_KEY,
  TR_DENIED_KEY,
  TR_VERIFIED_BUT_NOT_PUBLISHED_KEY,
  TR_PUBLISHED_KEY
} from "../../Constants/mainKeys";

class Courses extends Component {
  _isMounted = false;

  state = {
    courses: [],
    filteredCourses: [],
    translationService: null,
    numberOfDisplayedCourses: 12,
    filterValue: '',
    searchValue: '',
    triggerSearch: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this._isMounted && this.getCourses();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getCourses = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getMyCoursesAsInstructor().then(response => {
      if (response.data && this._isMounted) {
        const courses = [...response.data];
        this.setState({ courses: courses, filteredCourses: courses });
      }
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  filterCourses = eventKey => {
    let coursesData = [];
    const { courses, searchValue } = this.state;
    coursesData = eventKey === null ? courses : this.switchCourses(courses, eventKey);
    if (searchValue) {
      coursesData = coursesData.filter(data => (data.name.toLowerCase()).includes(searchValue.toLowerCase()));
    }
    this.setState({ filteredCourses: coursesData, filterValue: eventKey });
  }

  setSearchData = (data, searchValue) => {
    const { filterValue, courses } = this.state;
    let coursesData = (!data && !searchValue) ? courses : data;
    if (filterValue) {
      coursesData = this.switchCourses(coursesData, filterValue);
    }
    this.setState({ filteredCourses: coursesData, searchValue });
  }

  switchCourses = (courses, key) => {
    switch (key) {
      case TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY:
        return courses.filter(data => !data.submitedForVerification && !data.verified);
      case TR_SUBMITTED_FOR_VERIFICATION_KEY:
        return courses.filter(data => data.submitedForVerification && !data.verified && !data.verificationDenied);
      case TR_DENIED_KEY:
        return courses.filter(data => data.verificationDenied);
      case TR_VERIFIED_BUT_NOT_PUBLISHED_KEY:
        return courses.filter(data => data.verified && !data.published);
      case TR_PUBLISHED_KEY:
        return courses.filter(data => data.published);
    }
  }

  delete = course => {
    const { translationService } = this.state;
    const courseId = course.id;
    const spinnerId = uuid();
    const { courses } = this.state;
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${course.name} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.addPartialViewSpinner(spinnerId);
      ApiService.courseDelete(courseId).then(() => {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_COURSE_DELET_MESSAGE"));
        var index = courses.findIndex(function (o) {
          return o.id === courseId;
        })
        if (index !== -1) courses.splice(index, 1);
        this.setState({ courses });
        this.props.removePartialViewSpinner(spinnerId);
      }).catch(error => this.deleteFail(error, spinnerId));
    }).catch(error => this.deleteFail(error, spinnerId));
  }

  deleteFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    this.props.removePartialViewSpinner(spinnerId);
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    this.props.removePartialViewSpinner(spinnerId);
  }

  goBack = () => {
    this.props.history.push(`/${this.props.language}`);
  }

  render() {

    const { translationService, courses, numberOfDisplayedCourses, filteredCourses, filterValue } = this.state;
    const { language } = this.props;

    var isEmptyMessage = null;
    if (!courses.length && translationService) {
      isEmptyMessage = translationService.translate("TR_NO_CREATED_COURSE");
    }
    if (filteredCourses && courses && !filteredCourses.length && courses.length && filterValue === TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY && translationService) {
      isEmptyMessage = translationService.translate("TR_COURSES_NOT_SUBMITTED");
    }
    if (filteredCourses && courses && !filteredCourses.length && courses.length && filterValue === TR_SUBMITTED_FOR_VERIFICATION_KEY) {
      isEmptyMessage = translationService.translate("TR_COURSES_SUBMITTED")
    }
    if (filteredCourses && courses && !filteredCourses.length && courses.length && filterValue === TR_DENIED_KEY) {
      isEmptyMessage = translationService.translate("TR_COURSES_DENIED")
    }
    if (filteredCourses && courses && !filteredCourses.length && courses.length && filterValue === TR_VERIFIED_BUT_NOT_PUBLISHED_KEY) {
      isEmptyMessage = translationService.translate("TR_COURSES_VERIFIED")
    }
    if (filteredCourses && courses && !filteredCourses.length && courses.length && filterValue === TR_PUBLISHED_KEY) {
      isEmptyMessage = translationService.translate("TR_COURSES_PUBLISHED")
    }

    return translationService ?
      <Auxiliary>
        <div className="col-12">
          <h2 className="section-title">{translationService.translate("TR_COURSES")}</h2>
          <hr />
          <div className="mb-3">
            <div className="d-lg-flex flex-wrap d-block align-items-center justify-content-between">
              <div className="d-lg-flex d-block mb-2 mr-lg-2">
                <Search
                  searchData={courses}
                  searchFieldName="name"
                  emitSearchedData={(data, searchContent) => this.setSearchData(data, searchContent)}
                />
              </div>
              <div className="d-flex flex-wrap mindalay--btn-link-wrapper--instructor">
                <Link
                  to={`/${language}/${PURE_KEY}/${CREATE_COURSE_KEY}`}
                  className="mindalay--btn-default mindalay--btn-link mr-sm-2 mb-2"
                  onClick={() => this.props.setPureLayoutBackUrl(`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`)}
                >
                  {translationService.translate("TR_CREATE_COURSE")}
                </Link>
                <div className="header-dropdown filter-dropdown mb-2">
                  <Dropdown onSelect={eventKey => this.filterCourses(eventKey)}>
                    <Dropdown.Toggle variant="transparent">
                      <Link to="#" className="mindalay--btn-default mindalay--btn-link">
                        {translationService.translate(filterValue || "TR_ALL_COURSES")}
                      </Link>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {translationService.translate("TR_ALL_COURSES")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY}>
                        {translationService.translate(TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY)}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={TR_SUBMITTED_FOR_VERIFICATION_KEY}>
                        {translationService.translate(TR_SUBMITTED_FOR_VERIFICATION_KEY)}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={TR_DENIED_KEY}>
                        {translationService.translate(TR_DENIED_KEY)}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={TR_VERIFIED_BUT_NOT_PUBLISHED_KEY}>
                        {translationService.translate(TR_VERIFIED_BUT_NOT_PUBLISHED_KEY)}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={TR_PUBLISHED_KEY}>
                        {translationService.translate(TR_PUBLISHED_KEY)}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            {
              filteredCourses && filteredCourses.length ? filteredCourses.map((course, courseIndex) => {
                return (numberOfDisplayedCourses > courseIndex)
                  ? <div key={course.id} className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
                    <InstructorCourseBlock
                      course={course}
                      courseDashboardLink={`/${language}/${COURSE_DASHBOARD_KEY}/${course.id}/${GENERAL_INFO_KEY}`}
                      delete={this.delete}
                    />
                  </div> : null;
              }) : isEmptyMessage ?
                <div className="no-data-container">
                  <div className="no-data-wrapper">
                    <p className="col-12">{isEmptyMessage}</p>
                    <img src={NoDataImage} alt="/" />
                  </div>
                </div>
                : null
            }
          </div>
        </div>
        <div className="col-12 mt-3">
          {
            filteredCourses && (filteredCourses.length > numberOfDisplayedCourses)
              ? <button type="button" className="mindalay--btn-dark w-100" onClick={() => this.setState({ numberOfDisplayedCourses: numberOfDisplayedCourses + 12 })}>
                {translationService.translate("TR_SHOW_MORE")}
              </button> : null
          }
        </div>
      </Auxiliary> : null
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPartialViewSpinner,
  removePartialViewSpinner,
  setPureLayoutBackUrl,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Courses);
