import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import * as moment from "moment";
import { Link, withRouter } from "react-router-dom";
import NotificationSvg from "../../../../Components/Svg/notificationSvg";
import MainService from "../../../../Services/mainService";
import TranslationService from "../../../../Services/translationService";
import ApiService from "../../../../Services/apiService";
import AlertService from "../../../../Services/alertService";
import uuid from "react-uuid";
import Auxiliary from "../../../../hoc/auxiliary/auxiliary";
import NotificationSpinner from "../../../../Components/Spinners/notificationSpinner";
import { ERROR_KEY, NUMBER_KEY } from "../../../../Constants/mainKeys";
import { NOTIFICATIONS_KEY, ORGANIZATION_ADMIN_KEY } from "../../../../Constants/urlKeys";
import { addPageSpinner, removePageSpinner } from "../../../../Store/Actions/spinner";
import { setCurrentCourse } from "../../../../Store/Actions/course";
import feedbackSvg from "../../../../assets/icons/notification-icons/feedback-icon.svg";
import consultationSvg from "../../../../assets/icons/notification-icons/consultation.svg";
import courseSvg from "../../../../assets/icons/notification-icons/course.svg";
import complaintSvg from "../../../../assets/icons/notification-icons/complaint.svg";
import financesSvg from "../../../../assets/icons/notification-icons/finances.svg";
import upcomingWebinarkSvg from "../../../../assets/icons/notification-icons/upcoming-webinar.svg";
import { setConsultationContractBackUrl } from "../../../../Store/Actions/main";
import { showOrHideGeneralDashboardLeftMenu } from './../../../../Store/Actions/generalLeftMenu';

const Notification = (props) => {

  const dispatch = useDispatch();

  const translations = useSelector(state => state.translation.translations);
  const notificationCount = useSelector(state => state.signalR.notificationCount);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user)
  const [spinnerId, setSpinnerId] = useState(null);
  const [translationService, setTranslationService] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getNotifications = isOpened => {
    const spinnerId = uuid();
    if (isOpened) {
      setSpinnerId(spinnerId);
      ApiService.getNotifications().then(response => {
        setSpinnerId(null);
        setNotifications(response.data);
      }).catch(error => error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error));
    } else {
      setNotifications([]);
    }
  }

  const redirectToNotificationLinkPage = (notification) => {
    if (!notification || !notification.link) { return; }
    if (notification.objectType === 0) {
      getCourseById(notification);
    } else {
      if (props.location.pathname.includes(notification.link)) {
        window.location.reload();
      } else {
        dispatch(setConsultationContractBackUrl(props.location.pathname));
        props.history.push(`/${language}/${notification.link}`);
      }
    }
  }

  const markAsReadNotification = (event, currentNotification) => {
    event.stopPropagation();
    if (!currentNotification) { return; }
    const notificationIds = [currentNotification.id];
    ApiService.markAsReadNotification(notificationIds).then(() => {
      var updatedNotifications = [...notifications];
      updatedNotifications.forEach(notification => {
        if (notification.id === currentNotification.id) {
          notification.isRead = true;
        }
      });
      setNotifications(updatedNotifications);
    }).catch(error => getFail(error))
  }

  const getCourseById = (notification) => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getCourseById(notification.objectId).then(response => {
      dispatch(removePageSpinner(spinnerId));
      if (response && response.data) {
        setCurrentCourseHandler(response.data);
        props.history.push(`/${language}/${notification.link}`);
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const setCurrentCourseHandler = useCallback((course) => {
    dispatch(setCurrentCourse(course));
  }, []);

  const redirectToNotificationsPage = () => {
    // if (user.isOrganizationUser && user.isAdmin) {
    //   props.history.push(`/${language}/${ORGANIZATION_ADMIN_KEY}/${NOTIFICATIONS_KEY}`);
    // } else {
    // }
    props.history.push(`/${language}/${NOTIFICATIONS_KEY}`);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
  }

  return translationService ?
    <Auxiliary>
      <Dropdown onToggle={getNotifications}>
        <Dropdown.Toggle variant="transparent">
          <Link
            to="#"
            className={`nav-link ${typeof notificationCount === NUMBER_KEY && notificationCount > 0 ? "has-number" : ""}`}
            rel={typeof notificationCount === NUMBER_KEY && notificationCount}
            onClick={() => dispatch(showOrHideGeneralDashboardLeftMenu(false))}
          >
            <NotificationSvg />
          </Link>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="header-popup">
            <strong className="navbar-popup-title">{translationService.translate("TR_NOTIFICATIONS")}</strong>
            <hr className="m-0" />
            <div className="position-relative">
              <Dropdown.Item className="p-0">
                <ul className="navbar-popup-wrapper">
                  {
                    notifications && notifications.length
                      ? notifications.map(notification =>
                        <div
                          className={`${notification.link ? "cursor-pointer" : "cursor-default"} ${notification.isNotified ? "" : ""}`}
                          onClick={() => redirectToNotificationLinkPage(notification)}
                          key={notification.id}
                        >
                          <li className={`nav-item p-relative notification-body ${notification.isNotified ? "" : "font-bold"} ${!notification.isRead ? "unread-notification" : "read-notification"}`}>
                            <div className="notification-title">
                              {
                                (() => {
                                  switch (notification.objectType) {
                                    case 0: // COURSE 
                                      return <img src={courseSvg} alt="/" />
                                    case 5: // WEBINARCALENDAR 
                                      return <img src={upcomingWebinarkSvg} alt="/" />
                                    case 7: // CONSULTATION 
                                      return <img src={consultationSvg} alt="/" />
                                    case 8: // COMPLAINT  
                                      return <img src={complaintSvg} alt="/" />
                                    case 9: // STUDENTCOURSE 
                                      return <img src={courseSvg} alt="/" />
                                    case 10:// PAYMENT HISTORY
                                      return <img src={financesSvg} alt="/" />
                                    case 12: // REVIEW 
                                      return <img src={feedbackSvg} alt="/" />
                                    default:
                                      break
                                  }
                                })()
                              }
                              <span className="ml-2 not-subject" title={notification.title}>{notification.title}</span>
                            </div>
                            <p className="not-subject" title={notification.text}>{notification.text}</p>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="consultation-date">
                                {moment(MainService.convertUTCDateToLocalDate(new Date(notification.time))).startOf('minute').fromNow()}
                              </span>
                              {
                                !notification.isRead ?
                                  <div className="notification-circle" onClick={(event) => markAsReadNotification(event, notification)} />
                                  : null
                              }
                            </div>
                          </li>
                          {/* <div className="mt-2"><hr /></div> */}
                        </div>)
                      // : <Dropdown.Item>
                      //   <li className="nav-item">
                      //     <p className="not-subject-empty">{translationService.translate("TR_EMPTY_NOTIFICATION_MSG")}</p>
                      //   </li>
                      // </Dropdown.Item>
                      : <li className="nav-item">
                        <p className="not-subject-empty">{translationService.translate("TR_EMPTY_NOTIFICATION_MSG")}</p>
                      </li>
                  }
                </ul>
              </Dropdown.Item>
              {
                notifications && notifications.length ?
                  <Dropdown.Item>
                    <div className="text-right">
                      <button
                        type="button"
                        className="mindalay--btn-dark w-100"
                        onClick={redirectToNotificationsPage}
                      >
                        {translationService.translate("TR_SEE_ALL")}
                      </button>
                    </div>
                  </Dropdown.Item>
                  : null
              }
              <NotificationSpinner spinnerId={spinnerId} />
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </Auxiliary>
    : null;
}

export default withRouter(Notification);