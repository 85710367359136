import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TRANSLATION_TYPES_KEY,
} from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "./../../Services/apiService";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import NoDataImage from "./../../assets/images/illustrations/nodata.svg";
import Input from "./../../Components/Inputs/input";
import ReactPaginate from "react-paginate";
import EditSvg from "./../../Components/Svg/editSvg";
import { addModalSpinner } from "./../../Store/Actions/spinner";
import ModalComponent from "./../../Components/ModalComponent/modalComponent";
import Textarea from "../../Components/Inputs/textArea";
import SelectOption from "../../Components/SelectOption/selectOption";

const Translations = () => {
	const dispatch = useDispatch();
	const serachTextMaxLength = 120;
	const WAIT_INTERVAL = 500;
	const pageSize = 10;
	const translations = useSelector(state => state.translation.translations);
	const languages = useSelector(state => state.language.languages);
	const [translationService, setTranslationService] = useState(null);
	const [systemTranslations, setSystemTranslations] = useState([]);
	const [tabKey, setTabKey] = useState("en");
	const [searchText, setSearchText] = useState("");
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [timer, setTimer] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [currentTranslation, setCurrentTranslation] = useState(null);
	const [currentLanguageId, setCurrentLanguageId] = useState(1);
	const [translation1, setTranslation1] = useState("");
	const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getSystemTranslations(1, pageSize, "en");
		return () => {
			setSystemTranslations([]);
		};
	}, [languages]);

	const getSystemTranslations = (pageNumber, pageSize, isocode) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getSystemTranslations(+pageNumber, +pageSize, isocode)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				const data = [...response.data];
				setSystemTranslations(data);
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const handlePageClick = event => {
		setPageNumber(event.selected + 1);
		setActivePageNumber(event.selected);
		getSystemTranslations(event.selected + 1, pageSize, tabKey);
	};

	const onTextChange = (event, cb) => {
		cb(event.target.value);
	};

	const onChange = (event, maxLength = null, cb) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		if (!event.target.value) {
			getSystemTranslations(pageNumber, pageSize, tabKey);
		}
		clearTimeout(timer);
		cb(event.target.value);
		setTimer(
			setTimeout(() => triggerChange(event.target.value.trim()), WAIT_INTERVAL),
		);
	};

	const triggerChange = searchText => {
		if (searchText.length > 2) {
			ApiService.searchTranslations(searchText, tabKey)
				.then(response => {
					if (response && response.data) {
						const data = [...response.data];
						setSystemTranslations(data);
					} else {
						setSystemTranslations([]);
					}
				})
				.catch(error => getFail(error));
		}
	};

	const onSubmit = () => {
		if (!currentTranslation) {
			return false;
		}
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		setAddButtonIsDisabled(true);
		const data = {
			id: currentTranslation.id,
			languageId: currentLanguageId,
			shortKey: currentTranslation.shortKey,
			translation1: translation1,
			type: currentTranslation.type,
		};
		ApiService.updateTransalation(data)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				dispatch(removeModalSpinner(spinnerId));
				addModalSpinner(spinnerId);
				cancel();
			})
			.catch(error => getFail(error, spinnerId));
	};

	const cancel = () => {
		setCurrentTranslation(null);
		setAddButtonIsDisabled(false);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		cancel();
	};

	return translationService && languages ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_TRANSLATIONS")}
					</h2>
					<hr />
				</div>
			</div>
			<div className="translation-search-block">
				<div className="col-12 col-lg-6">
					<Input
						type="text"
						id="searchText"
						name="searchText"
						value={searchText}
						placeholder={`${translationService.translate("TR_SEARCH")}`}
						labelClassName="mt-2"
						onChange={event =>
							onChange(event, serachTextMaxLength, setSearchText)
						}
					/>
				</div>
			</div>
			{currentTranslation ? (
				<ModalComponent
					title={currentTranslation.shortKey}
					isLarge={true}
					actionButtonTitle={translationService.translate("TR_UPDATE")}
					addButtonIsDisabled={addButtonIsDisabled}
					cancel={cancel}
					onSubmit={onSubmit}>
					<form>
						<div className="mb-4">
							<div className="row">
								<div className="col-12 col-md-6">
									<SelectOption
										name="isCaseSensitive"
										value={currentTranslation.type}
										label={`${translationService.translate("TR_TYPE")} *`}
										items={[...TRANSLATION_TYPES_KEY]}
										changed={event => {
											var currentTranslationCopy = { ...currentTranslation };
											currentTranslationCopy.type = +event.target.value;
											setCurrentTranslation(currentTranslationCopy);
										}}
									/>
								</div>
							</div>
						</div>
						<Textarea
							rows="5"
							id="translation1"
							name="translation1"
							value={translation1}
							labelValue={`${translationService.translate("TR_TEXT")} *`}
							placeholder={`${translationService.translate("TR_TEXT")} *`}
							onChange={event => onTextChange(event, setTranslation1)}
						/>
					</form>
				</ModalComponent>
			) : null}
			{languages && languages.length ? (
				<div className="row">
					<div className="col-12">
						<div className="tab-title-group tab-content">
							<Tabs
								activeKey={tabKey}
								onSelect={key => {
									if (key !== tabKey) {
										setTabKey(key);
										getSystemTranslations(pageNumber, pageSize, key);
										setCurrentLanguageId(
											languages.find(lang => lang.isocode2 === key).languageId,
										);
										setSearchText("");
									}
								}}>
								{languages.map(language => {
									return (
										<Tab
											key={language.languageId}
											eventKey={language.isocode2}
											title={language.name}>
											{systemTranslations && systemTranslations.length ? (
												<div className="mt-4">
													<div className="mindayal-admin-table table-responsive">
														<table className="table">
															<thead>
																<tr>
																	<th>
																		{translationService.translate("TR_KEY")}
																	</th>
																	<th>
																		{translationService.translate("TR_TEXT")}
																	</th>
																	<th>
																		{translationService.translate("TR_TYPE")}
																	</th>
																	<th>
																		{translationService.translate("TR_UPDATE")}
																	</th>
																</tr>
															</thead>
															<tbody>
																{systemTranslations.map(systemTranslation => {
																	return (
																		<tr
																			key={systemTranslation.id}
																			className="cursor-default">
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{systemTranslation.shortKey}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{systemTranslation.translation1}
																				</div>
																			</td>
																			<td>
																				<div className="mindalay-admin-table-td-item">
																					{TRANSLATION_TYPES_KEY.map(
																						(type, index) => {
																							if (
																								index === systemTranslation.type
																							) {
																								return type;
																							}
																						},
																					)}
																				</div>
																			</td>
																			<td>
																				<div className="d-flex justify-content-center">
																					<div className="edit-group">
																						<div
																							title={translationService.translate(
																								"TR_UPDATE",
																							)}
																							className="edit-group-item"
																							onClick={() => {
																								setTranslation1(
																									systemTranslation.translation1,
																								);
																								setCurrentTranslation(
																									systemTranslation,
																								);
																							}}>
																							<EditSvg />
																						</div>
																					</div>
																				</div>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													</div>
												</div>
											) : searchText ? (
												<div className="no-data-container mt-3">
													<div className="no-data-wrapper">
														<p>{translationService.translate("TR_NO_DATA")}</p>
														<img src={NoDataImage} alt="/" />
													</div>
												</div>
											) : null}
										</Tab>
									);
								})}
							</Tabs>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pagination &&
			systemTranslations &&
			systemTranslations.length &&
			!searchText.trim() ? (
				<div className="row my-4">
					<div className="col-12">
						<div>
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default Translations;
