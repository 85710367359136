import {
  ADD_PAGE_SPINNER,
  REMOVE_PAGE_SPINNER,
  ADD_BUTTON_SPINNER,
  REMOVE_BUTTON_SPINNER,
  ADD_PARTIAL_VIEW_SPINNER,
  REMOVE_PARTIAL_VIEW_SPINNER,
  ADD_MODAL_SPINNER,
  REMOVE_MODAL_SPINNER,
  ADD_TEXT_SPINNER,
  REMOVE_TEXT_SPINNER,
  ADD_TOPIC_SPINNER,
  REMOVE_TOPIC_SPINNER
} from '../../Constants/reduxKeys';

export function addPageSpinner(spinnerId) {
  return {
    type: ADD_PAGE_SPINNER,
    payload: spinnerId
  };
};

export function removePageSpinner(spinnerId) {
  return {
    type: REMOVE_PAGE_SPINNER,
    payload: spinnerId
  };
};

export function addTopicSpinner(spinnerId) {
  return {
    type: ADD_TOPIC_SPINNER,
    payload: spinnerId
  };
};

export function removeTopicSpinner(spinnerId) {
  return {
    type: REMOVE_TOPIC_SPINNER,
    payload: spinnerId
  };
};

export function addButtonSpinner(spinnerId) {
  return {
    type: ADD_BUTTON_SPINNER,
    payload: spinnerId
  };
};

export function removeButtonSpinner(spinnerId) {
  return {
    type: REMOVE_BUTTON_SPINNER,
    payload: spinnerId
  };
};

export function addTextSpinner(spinnerId) {
  return {
    type: ADD_TEXT_SPINNER,
    payload: spinnerId
  };
};

export function removeTextSpinner(spinnerId) {
  return {
    type: REMOVE_TEXT_SPINNER,
    payload: spinnerId
  };
};

export function addPartialViewSpinner(spinnerId) {
  return {
    type: ADD_PARTIAL_VIEW_SPINNER,
    payload: spinnerId
  };
};

export function removePartialViewSpinner(spinnerId) {
  return {
    type: REMOVE_PARTIAL_VIEW_SPINNER,
    payload: spinnerId
  };
};

export function addModalSpinner(spinnerId) {
  return {
    type: ADD_MODAL_SPINNER,
    payload: spinnerId
  };
};

export function removeModalSpinner(spinnerId) {
  return {
    type: REMOVE_MODAL_SPINNER,
    payload: spinnerId
  };
};


