import React from 'react';
import { Switch, Route } from "react-router-dom";
import ForConsultantView from '../Pages/ConsultationViewPages/ForConsultant/forConsultantView';
import ContractPageForConsultant from '../Pages/ConsultationContarctPages/ForConsultant/contractPageForConsultant';
import Finances from '../Pages/Overview/finances';
import Overview from '../Pages/Overview/overview';
import BecomeConsultantView from '../Pages/BecomeConsultant/becomeConsultantView';
import NotFoundPage from '../Pages/404/notFoundPage';
import UnderMaintenancePage from './../Pages/UnderMaintenancePage/underMaintenancePage';
import AuthenticationByRefreshToken from '../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken';

const ConsultantDashboardRoutes = () => {

  return <Switch>
    {/* Consultant */}
    <Route path="/:language/consultant/consultant-dashboard" exact component={ForConsultantView} />
    <Route path="/:language/consultant/finances" exact component={Finances} />
    <Route path="/:language/consultant/finances/paypalsuccess/returnurl" exact component={Finances} />
    <Route path="/:language/consultant/finances/paypalcancel/returnurl" exact component={Finances} />
    <Route path="/:language/consultant/finances/cryllexsuccess/returnurl" exact component={Finances} />
    <Route path="/:language/consultant/finances/cryllexcancel/returnurl" exact component={Finances} />
    <Route path="/:language/consultant/finances/stripesuccess/returnurl" exact component={Finances} />
    <Route path="/:language/consultant/finances/stripecancel/returnurl" exact component={Finances} />
    <Route path="/:language/consultant/finances/overview" exact component={Overview} />
    <Route path="/:language/consultant/consultant-consultation-contracts/:contractId" exact component={ContractPageForConsultant} />
    <Route path="/:language/consultant/consultant-consultation-contracts/:contractId/:contractState" exact component={ContractPageForConsultant} />
    <Route path="/:language/consultant/consultant-consultation-contracts/:contractId/returnurl" exact component={ContractPageForConsultant} />
    <Route path="/:language/consultant/consultant-information" exact component={BecomeConsultantView} />

    {/* AuthenticationByRefreshToken */}
    <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />

    {/* Server error page */}
    <Route path="/:language/under-maintenance" exact component={UnderMaintenancePage} />
    <Route component={NotFoundPage} />
  </Switch>;
}

export default ConsultantDashboardRoutes;