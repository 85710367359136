import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import AlertService from "../../Services/alertService";
import Sections from "./Components/sections";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { ERROR_KEY, NUMBER_KEY } from "../../Constants/mainKeys";
import {
	CREATE_CONSULTATION_KEY,
	PASS_COURSE_KEY,
	PASS_COURSE_VIDEO_KEY,
} from "../../Constants/urlKeys";
import QuizView from "./Components/quizView";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ShowMoreText from "react-show-more-text";
import PassMessage from "./Components/passMessage";
import Parser from "html-react-parser";
import { setConsultationContractBackUrl } from "./../../Store/Actions/main";
import { Helmet } from "react-helmet";
import PosterImage from "./../../assets/images/video-poster.png";
import ReactPlayer from "react-player";
import AssignmentsView from "./Components/assignmentsView";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

class PassCourseView extends Component {
	state = {
		courseId: +this.props.match.params?.courseId || null,
		studentCourseId: +this.props.match.params?.studentCourseId || null,
		currentSectionId: null,
		courseData: null,
		spinnerId: uuid(),
		translationService: null,
		currentTopicId: null,
		completionPercentage: null,
		courseQuizzes: [],
		couresAssignments: [],
		isShowPassMessages: false,
		ongoingVideoId: null,
	};

	componentDidMount() {
		const { courseId } = this.state;
		const { user } = this.props;
		this.setTranslations();
		this.getStudentCourseByStudentCourseId();
		courseId && this.getQuizzesByCourseId(courseId);
		user &&
			user.isOrganizationUser &&
			courseId &&
			this.getCouresAssignments(courseId);
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	getQuizzesByCourseId = () => {
		const { courseId, spinnerId } = this.state;
		const { user } = this.props;
		(user.isOrganizationUser
			? ApiService.getQuizzesByCourseIdByOrgStudent(courseId)
			: ApiService.getQuizzesByCourseId(courseId)
		)
			.then(response => {
				response.data && this.setState({ courseQuizzes: response.data });
			})
			.catch(error => this.getFail(error, spinnerId));
	};
	getCouresAssignments = () => {
		const { courseId, spinnerId } = this.state;
		const { user } = this.props;
		ApiService.getAssignmentByCourseId(courseId)
			.then(response => {
				response.data && this.setState({ couresAssignments: response.data });
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getStudentCourseByStudentCourseId = () => {
		const { studentCourseId, spinnerId } = this.state;
		this.props.addPageSpinner(spinnerId);
		ApiService.getStudentCourseByStudentCourseId(studentCourseId)
			.then(response => {
				const data = { ...response.data };
				if (data) {
					this.setState({
						courseData: data.course,
						currentTopicId: data.currentTopicId,
						completionPercentage: data.completionPercentage,
						isShowPassMessages:
							data.course?.welcomeMessage || data.course?.congratulationMessage
								? true
								: false,
					});
				}
				this.props.removePageSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	openSectionData = id => {
		if (id === this.state.currentSectionId) {
			this.setState({ currentSectionId: null });
		} else {
			this.setState({ currentSectionId: id });
		}
	};

	closePassMessage = () => {
		this.setState({ isShowPassMessages: false });
	};

	getFail = (error, spinnerId) => {
		spinnerId && this.props.removePageSpinner(spinnerId);
		if (error) {
			error &&
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			this.props.history.push(`/`);
		}
	};

	render() {
		const vidoe_link = null;
		const { user, language } = this.props;
		const {
			courseData,
			translationService,
			currentSectionId,
			currentTopicId,
			courseId,
			studentCourseId,
			completionPercentage,
			courseQuizzes,
			isShowPassMessages,
			ongoingVideoId,
			couresAssignments,
		} = this.state;

		return courseData && translationService ? (
			<div>
				<section className="top-section">
					{
						<Helmet>
							<title>{`${courseData.name} | Mindalay`}</title>
						</Helmet>
					}
					{isShowPassMessages &&
					(courseData.welcomeMessage || courseData.congratulationMessage) ? (
						<PassMessage
							messageType={
								courseData.welcomeMessage
									? "welcome"
									: courseData.congratulationMessage
									? "congratulation"
									: null
							}
							title={
								courseData.welcomeMessage
									? translationService.translate("TR_WELCOME")
									: courseData.congratulationMessage
									? translationService.translate("TR_CONGRATULATION")
									: ""
							}
							message={
								courseData.welcomeMessage
									? courseData.welcomeMessage
									: courseData.congratulationMessage
									? courseData.congratulationMessage
									: ""
							}
							cancel={this.closePassMessage}
						/>
					) : null}
					<div className="container-fluid course-pass-container mt-3">
						<div className="row">
							<div className="col-12 p-0">
								<div className="d-md-flex d-block">
									<aside className="coures-pass-left-content">
										<div className="course-complited-status">
											<h1>{courseData.name}</h1>
											<div className="course-media-wrapper d-flex m-0">
												{courseData.presentationFilePath ? (
													<div
														className="course-video-wrapper"
														style={{ width: 180 }}>
														<video
															controls
															controlsList="nodownload nopictonpicture"
															disablePictureInPicture={true}
															poster={PosterImage}
															autoPlay={true}
															className="cursor-pointer"
															width={180}
															onPointerDown={() =>
																this.setState({ ongoingVideoId: courseData.id })
															}>
															{ongoingVideoId === courseData.id ? (
																<source
																	src={courseData.presentationFilePath}
																	type="video/mp4"
																/>
															) : null}
														</video>
													</div>
												) : courseData?.videoLink ? (
													<div
														className="course-video-wrapper"
														style={{ width: 180 }}>
														<ReactPlayer
															className="course-video-link"
															url={courseData?.videoLink}
															config={{
																youtube: {
																	playerVars: { showinfo: 0, controls: 1 },
																},
																facebook: {
																	appId: "12345",
																},
															}}
														/>
													</div>
												) : (
													<div className="w-100">
														<div className="course-main-info ">
															<div
																className="course-image mb-2"
																style={{
																	backgroundImage: `url(${courseData.imagePath})`,
																}}></div>
														</div>
													</div>
												)}
											</div>
											{courseData.description ? (
												<ShowMoreText
													lines={3}
													more={translationService.translate("TR_SHOW_MORE")}
													less={translationService.translate("TR_LESS")}
													className="content-css mt-3"
													anchorClass="my-anchor-css-class"
													expanded={false}
													truncatedEndingComponent={"... "}>
													<span className="course-description-block">
														<ParserComponent text={courseData.description} />
													</span>
												</ShowMoreText>
											) : null}
											{completionPercentage ||
											typeof completionPercentage === NUMBER_KEY ? (
												<div className="progress my-3">
													<div
														className="progress-bar"
														style={{ width: `${completionPercentage}%` }}
														role="progressbar"
														aria-valuenow="75"
														aria-valuemin="0"
														aria-valuemax="100"></div>
												</div>
											) : null}
											<div className="course-pass-percent w-100 text-center">
												{completionPercentage ||
												typeof completionPercentage === NUMBER_KEY ? (
													<p>
														<span>{completionPercentage}</span>
														<span>%</span>
														{translationService.translate("TR_COMPLETED")}
													</p>
												) : null}
											</div>
											<div className="mt-3">
												{currentTopicId ||
												(courseData.sections.length &&
													courseData.sections[0].topics.length &&
													courseData.sections[0].topics[0].id) ? (
													<Link
														to={
															currentTopicId
																? `/${this.props.language}/${PASS_COURSE_KEY}/${PASS_COURSE_VIDEO_KEY}/${courseId}/${studentCourseId}/${currentTopicId}`
																: courseData.sections.length &&
																  courseData.sections[0].topics.length
																? `/${this.props.language}/${PASS_COURSE_KEY}/${PASS_COURSE_VIDEO_KEY}/${courseId}/${studentCourseId}/${courseData.sections[0].topics[0].id}`
																: "#"
														}
														className="mindalay--btn-secondary-outline btn mindalay--btn-link w-100 mt-2">
														{completionPercentage === 100
															? translationService.translate("TR_VIEW_COURSE")
															: translationService.translate(
																	"TR_CONTINUE_LEARNING",
															  )}
													</Link>
												) : null}
												{user && user.isOrganizationUser ? null : (
													<Link
														to={`/${language}/${CREATE_CONSULTATION_KEY}`}
														className="mindalay--btn-dark btn mindalay--btn-link w-100 mt-2"
														onClick={() =>
															this.props.setConsultationContractBackUrl(
																this.props.location.pathname,
															)
														}>
														{translationService.translate(
															"TR_ORDER_CONSULTATION",
														)}
													</Link>
												)}
											</div>
										</div>
										<hr />
									</aside>
									{/* <hr className="d-md-none d-block" /> */}
									<aside className="coures-pass-right-content px-3">
										<div className="row">
											<div className="col-12">
												<p className="section-title">
													{translationService.translate("TR_SECTIONS")}
												</p>
												{courseData && courseData.sections ? (
													<Sections
														sections={courseData.sections}
														currentSectionId={currentSectionId}
														showBtnView={true}
													/>
												) : null}
											</div>
										</div>
										{courseQuizzes && courseQuizzes.length ? (
											<Auxiliary>
												<hr />
												<div className="row mb-5">
													<div className="col-12">
														<p className="section-title">
															{translationService.translate(
																"TR_COURSE_CONTENT",
															)}
														</p>
													</div>
													<div className="col-12">
														<div className="course-pass-media-file-container">
															<div className="row">
																<QuizView
																	translationService={translationService}
																	quizData={courseQuizzes}
																/>
															</div>
														</div>
													</div>
												</div>
											</Auxiliary>
										) : null}
										{user &&
										user.isOrganizationUser &&
										couresAssignments &&
										couresAssignments.length ? (
											<Auxiliary>
												<hr />
												<div className="row mb-5">
													<div className="col-12">
														<p className="section-title">
															{translationService.translate("TR_ASSIGNMENTS")}
														</p>
													</div>
													<div className="col-12">
														<div className="course-pass-media-file-container">
															<div className="row">
																<AssignmentsView
																	translationService={translationService}
																	assignments={couresAssignments}
																/>
															</div>
														</div>
													</div>
												</div>
											</Auxiliary>
										) : null}
									</aside>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	language: state.language.language,
	translations: state.translation.translations,
	user: state.user.user,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
	setConsultationContractBackUrl,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(PassCourseView);
