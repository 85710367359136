import React from 'react';
import NotFoundSvg from '../../Components/Svg/notFoundSvg';

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <NotFoundSvg />
    </div>
  );
}

export default NotFoundPage;
