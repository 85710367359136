// import React, { useMemo } from "react";
// import ParserService from "../../Services/parserService";

// function ParserComponent({ text }) {
// 	const html = useMemo(() => {
// 		if (text) {
// 			return ParserService.processHTML(text);
// 		} else return null;
// 	}, [text]);
// 	return html;
// }

// export default ParserComponent;
import React, { useState, useEffect } from "react";
import ParserService from "../../Services/parserService";

function ParserComponent({ text }) {
	const [html, setHtml] = useState(null);

	useEffect(() => {
		if (text) {
			ParserService.processHTML(text).then(processedHtml => {
				setHtml(processedHtml);
			});
		} else {
			setHtml(null);
		}
	}, [text]);

	if (!html) {
		return null; // or you can return a loading indicator while waiting for the promise to resolve
	}

	return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

export default ParserComponent;
