import React, { Component } from 'react';
import { connect } from 'react-redux';
import Search from '../../../Components/Search/search';
import TranslationService from '../../../Services/translationService';
import * as moment from "moment";
import { addPartialViewSpinner, removePartialViewSpinner } from "../../../Store/Actions/spinner";
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import ShowMoreText from "react-show-more-text";
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import MainService from '../../../Services/mainService';

class Interview extends Component {

  state = {
    translationService: null,
    interviewContracts: this.props.interviewContracts || [],
    interviewContractsCount: 5
  }

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  render() {
    const { translationService, interviewContractsCount } = this.state;
    const { interviewContracts, contracts, user } = this.props;

    return (
      translationService ?
        <Auxiliary>
          <div className="row">
            <div className="col-12">
              <div className="my-3">
                <form onSubmit={event=> event.preventDefault()}>
                  <div className="d-lg-flex align-items-center justify-content-between">
                    <Search
                      searchData={contracts}
                      searchFieldName="categoryName"
                      emitSearchedData={data => this.props.filterContract(data, "interviewContracts", "interviewContractsFiltered")}
                    />
                    <div className="mindalay--btn-link-wrapper--instructor my-2">
                      <p className="text-right">{translationService.translate("TR_INTERVIEW")}: <strong>{interviewContracts ? interviewContracts.length : 0}</strong></p>
                    </div>
                  </div>
                </form>
              </div>
              <hr/>
            </div>
          </div>
          <div className="row">
            {
              interviewContracts && interviewContracts.length ?
                interviewContracts.map((contract, index) => {
                  return (interviewContractsCount > index) ? <div
                    key={contract.id}
                    className="col-lg-6 col-12 mt-3"
                  >
                    <div className="mindalay--consultation-card mindalay--consultation-container d-lg-flex d-block">
                      <div className="consultation-container-left-side mindalat--interview-container overflow-hidden">
                        <strong className="consultation-header-name">{contract.consultation.name}</strong>
                        <small
                          className="d-block"
                        >
                          {`${translationService.translate("TR_CREATED")}: ${moment(MainService.convertUTCDateToLocalDate(new Date(contract.consultation.creationDate))).format("ll (HH:mm)")}`}
                        </small>
                        {
                          //Student
                          contract.consultation.studentUserId === user.id ?
                            <div><small>{translationService.translate("TR_CONSULTANT")}</small><small className="secondary-color font-weight-bold">: {contract.consultantName}</small></div>
                            : null
                        }
                        {
                          //Consultant
                          contract.consultation.studentUserId !== user.id ?
                            <div><small>{translationService.translate("TR_STUDENT")}</small><small className="secondary-color font-weight-bold">: {contract.consultation.studentUserName}</small></div>
                            : null
                        }
                        <hr />
                        {
                          contract.consultation.description || contract.coverLetter ?
                            <div>
                              {
                                contract.coverLetter ?
                                  <span>
                                    <span className="d-block font-weight-bold my-2">{translationService.translate("TR_COVER_LETTER")}</span>
                                    <ShowMoreText
                                      lines={2}
                                      more={translationService.translate("TR_SHOW_MORE")}
                                      less={translationService.translate("TR_LESS")}
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      expanded={false}
                                      truncatedEndingComponent={"... "}
                                    >
                                      <p>{contract.coverLetter}</p>
                                    </ShowMoreText>
                                  </span>
                                  : null
                              }
                              {
                                contract.consultation.description ?
                                  <span className="flex-column d-block mt-3">
                                    <span className="d-block font-weight-bold my-2">{translationService.translate("TR_DESCRIPTION")}</span>
                                    <ShowMoreText
                                      lines={2}
                                      more={translationService.translate("TR_SHOW_MORE")}
                                      less={translationService.translate("TR_LESS")}
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      expanded={false}
                                      truncatedEndingComponent={"... "}
                                    >
                                      <p>{contract.consultation.description}</p>
                                    </ShowMoreText>
                                  </span>
                                  : null
                              }
                              <hr />
                              <button
                                type="button"
                                className="mindalay--btn mindalay--btn-default w-100"
                                onClick={this.props.redirectToConsultationContracts ? () => this.props.redirectToConsultationContracts(contract.id) : null}
                              >{translationService.translate("TR_DISCOVER_MORE")}</button>
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  </div> : null
                }) :
                <div className="no-data-container">
                  <div className="no-data-wrapper">
                    <p>{translationService.translate("TR_NO_INTERVIEW")}</p>
                    <img src={NoDataImage} alt="/"/>
                  </div>
                </div>
            }
            {
              interviewContracts && (interviewContractsCount < interviewContracts.length)
                ?
                <div className="col-12">
                  <button type="button" className="mindalay--btn-default w-100 mt-4" onClick={() => this.setState({ interviewContractsCount: interviewContractsCount + 5 })}>
                    {translationService.translate("TR_SHOW_MORE")}
                  </button>
                </div>
                : null
            }
          </div>
        </Auxiliary>
        : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Interview);
