import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import InputRadio from "../../Components/Inputs/inputRadio";
import Input from "../../Components/Inputs/input";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Textarea from "../../Components/Inputs/textArea";
import InfoSvg from "../../Components/Svg/infoSvg";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { COURSES_KEY, INSTRUCTOR_KEY, VIEW_KEY } from "../../Constants/urlKeys";
import { CONSULTANT_USER_TYPE, ERROR_KEY, INSTRUCTOR_USER_TYPE, NUMBER_KEY } from "../../Constants/mainKeys";
import { Link, withRouter } from 'react-router-dom';
import CloseSvg from './../../Components/Svg/closeSvg';
import { userUpdateSuccess } from "../../Store/Actions/user";
import { PURE_LAYOUT_BACK_KEY } from './../../Constants/mainKeys';

const BecomeInstructor = props => {
  const dispatch = useDispatch();

  const timeoutRef = useRef(null);
  const wrapperRef = useRef(null);

  const user = useSelector(state => state.user.user);
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [isVisible, setIsVisible] = useState(true);
  const [translationService, setTranslationService] = useState(null);
  const [instructorLibraryData, setInstructorLibraryData] = useState(null);
  const [specialty, setSpecialty] = useState("");
  const [secondSpecialty, setSecondSpecialty] = useState("");
  const [bio, setBio] = useState("");
  const [videoProfessionalism, setVideoProfessionalism] = useState(null);
  const [failedFields, setFailedFields] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  const [searchResult, setSearchResult] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [cursor, setCursor] = useState(0);
  var [activeSearchElementPosition, setActiveSearchElementPosition] = useState(0);
  const currentSpecialtyMaxLength = 100;
  const bioMinLength = 50;
  const specialtyMaxLength = 1000;
  const bioMaxLength = 2000;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    if (user.userTypeId === INSTRUCTOR_USER_TYPE || user.userTypeId === CONSULTANT_USER_TYPE) {
      props.history.push(`/${language}`);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
    };
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      if (specialty.trim().length > 1 && !secondSpecialty) getSpecialtyKeywords();
    }, 500);
  }, [specialty]);

  useEffect(() => {
    getLibraryData();
  }, []);

  const chanegeSpecialty = (value) => {
    if (!searchResult.length) {
      value.trim() && searchResult.unshift(value.trim());
      setSearchResult(searchResult);
    } else {
      searchResult[0] = value;
      setSearchResult(searchResult);
    }
    if (value.length <= 100) {
      setSpecialty(value);
    } else { return false; }
  }

  const handleKeyDown = (event, array) => {
    setIsVisible(true);
    var searchBlock = document.getElementById("scrollable");
    var searchBlockHeight = searchBlock?.offsetHeight;
    var currentElementHeight = event.target?.offsetHeight;
    var cursorNewIndex = 0;
    if (event.keyCode === 38 && cursor > 0) {
      cursorNewIndex = cursor - 1;
      changeCursorPosition(cursorNewIndex);
      if (activeSearchElementPosition -= currentElementHeight > 0) {
        setActiveSearchElementPosition(activeSearchElementPosition -= currentElementHeight)
      }
      if (activeSearchElementPosition + currentElementHeight < searchBlockHeight) {
        searchBlock.scrollBy({ top: -currentElementHeight, behavior: "smooth" });
      }
    }
    if (event.keyCode === 40 && cursor < array.length - 1) {
      cursorNewIndex = cursor + 1;
      changeCursorPosition(cursorNewIndex);
      setActiveSearchElementPosition(activeSearchElementPosition += currentElementHeight);

      if (activeSearchElementPosition + currentElementHeight > searchBlockHeight) {
        searchBlock.scrollBy({ top: currentElementHeight, behavior: "smooth" })
      }
    }
    if (event.keyCode === 13 && isVisible) {
      if (specialties.length) {
        const isExist = specialties.find(currentSpecialty => currentSpecialty === array[cursor]);
        if (!isExist && (array[cursor] || specialty.trim())) {
          addSpecialty(specialties, array[cursor] || specialty);
        }
      } else { addSpecialty(specialties, array[cursor]) }
    }
  }

  const changeCursorPosition = (cursorNewIndex) => {
    setCursor(cursorNewIndex);
  }

  const addSpecialty = (specialties, keyword) => {
    if (!keyword || (keyword && !keyword.trim())) { return; }
    if (JSON.stringify(specialties).length + keyword.length <= specialtyMaxLength) {
      specialties.push(keyword.trim());
      setSpecialties(specialties);
      setSpecialty("");
    } else { return false; }
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  const getSpecialtyKeywords = () => {
    ApiService.getSpecialtyKeywords(specialty).then(response => {
      if (response.data) {
        const data = [...response.data];
        data.unshift(specialty);
        setSearchResult(data);
        setIsVisible(true);
      } else {
        const data = [];
        data.unshift(specialty);
        setSearchResult(data);
      }
    }).catch(error => AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error))
  };

  const onSpecialtyFocus = () => {
    if (specialty.trim().length > 1) {
      getSpecialtyKeywords();
    }
  }

  const getLibraryData = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId))
    ApiService.getLibraryData().then(response => {
      setInstructorLibraryData(response.data);
      dispatch(removePageSpinner(spinnerId))
    }).catch(error => getFail(error, spinnerId));
  };

  const onSubmit = event => {
    const spinnerId = uuid();
    event.preventDefault();
    if ((!bio || !bio.trim() || (bio.trim() && bio.trim().length < bioMinLength)) || !(videoProfessionalism || videoProfessionalism === 0) || (!specialties.length && !specialty.trim())) {
      setIsInvalidSubmit(true);
    } else {
      dispatch(addPageSpinner(spinnerId));
      const form = {
        specialty: specialties && specialties.length ? JSON.stringify(specialties) : JSON.stringify([specialty]),
        bio: bio,
        videoProfessionalism: videoProfessionalism
      };
      ApiService.becomeInstructor(form).then(response => {
        const userData = { ...user };
        userData.userTypeId = response.data.userTypeId;
        userData.userTypeDisplayName = response.data.userTypeDisplayName;
        dispatch(userUpdateSuccess(userData))
        dispatch(removePageSpinner(spinnerId));
        props.history.push(`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`);
      }).catch(error => submitFail(spinnerId, error));
    }
  }

  const removeFailedFields = name => {
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    setFailedFields(failedFields);
  };

  const removeSpecialty = (currentSpecialty) => {
    var dublicateSpecialties = [...specialties];
    const index = dublicateSpecialties.indexOf(currentSpecialty);
    if (index > -1) {
      dublicateSpecialties.splice(index, 1);
    }
    setSpecialties(dublicateSpecialties);
  }

  const submitFail = (spinnerId, error) => {
    dispatch(removePageSpinner(spinnerId))
    setFailedFields(error);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  const setKeyword = (keyword) => {
    setSecondSpecialty(keyword);
    setIsVisible(false)
    setSpecialty(keyword);
    if (specialties.length) {
      const isExist = specialties.find(currentSpecialty => currentSpecialty === keyword);
      if (!isExist && (searchResult[cursor] || specialty.trim())) {
        addSpecialty(specialties, keyword || specialty);
      }
    } else { addSpecialty(specialties, keyword) }
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePageSpinner(spinnerId))
  }

  return translationService && (user.userTypeId !== INSTRUCTOR_USER_TYPE || user.userTypeId !== CONSULTANT_USER_TYPE) ?
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-12">
          <h2 className="section-title">{translationService.translate("TR_BECOME_INSTRUCTOR")}</h2>
          <hr />
          <form onSubmit={(event => event.preventDefault())}>
            <div className="position-relative" ref={wrapperRef}>
              <Input
                type="text"
                id="specialty"
                name="specialty"
                blockClassName="w-100"
                value={specialty}
                isInvalidField={isInvalidSubmit && (!specialties.length && !specialty.trim()) ? true : false}
                inputClassName="pr--5"
                infoText={translationService.translate("TR_TYPE_AND_CHOOSE_SPECIALTIES_INFO")}
                labelValue={`${translationService.translate("TR_SPECIALTY_PROFESSION")} *`}
                placeholder={`${translationService.translate("TR_TYPE")}...`}
                onFocus={onSpecialtyFocus}
                fieldLength={currentSpecialtyMaxLength}
                onChange={event => {
                  chanegeSpecialty(event.target.value);
                  failedFields && removeFailedFields(event.target.name);
                  setSecondSpecialty("");
                }}
                onKeyDown={(event) => handleKeyDown(event, searchResult)}
                failedFields={failedFields}
              />
              {
                searchResult && searchResult.length && specialty.trim().length > 1 && isVisible ?
                  <ul className="autocomplete-block top-80" id="scrollable">
                    {
                      searchResult.map((keyword, index) => {
                        return <li
                          key={index}
                          className={`autocomplete-item pr-4 ${cursor === index ? "active-search" : ""}`}
                          onClick={() => setKeyword(keyword)}
                        >
                          <span className={`${cursor === index ? "black-color" : ""}`}>
                            {
                              index ?
                                <i className="fas fa-search bottom-4" />
                                : null
                            }</span>
                          <Link to="#">{keyword}</Link >
                        </li>
                      })
                    }
                  </ul>
                  : null
              }
            </div>
            {
              specialties && specialties.length ?
                <div className="p-0">
                  <div className="selected-item-container my-2">
                    {
                      specialties.map((currentSpecialty, index) => {
                        return <div key={index} className="selected-item-wrapper">
                          <div className="selected-item word-break-break-word">
                            {currentSpecialty}
                          </div>
                          <button type="button" className="mindalay--btn-blue" onClick={() => removeSpecialty(currentSpecialty)}>
                            <CloseSvg />
                          </button>
                        </div>
                      })
                    }
                  </div>
                  <hr />
                </div>
                : null
            }
            <Textarea
              id="bio"
              name="bio"
              rows="3"
              labelClassName="big-sub-title mt-2"
              infoText={translationService.translate("TR_TELL_ABOUT_YOURSELF")}
              textAreaClassName="pr--5"
              value={bio}
              max={bioMaxLength}
              labelValue={`${translationService.translate("TR_BIOGRAPHY")} *`}
              isInvalidSubmit={isInvalidSubmit}
              isInvalidField={isInvalidSubmit && (!bio || !bio.trim() || (bio.trim() && bio.trim().length < bioMinLength)) ? true : false}
              onChange={event => {
                if (bioMaxLength >= event.target.value.length) {
                  setBio(event.target.value);
                } else { return false; }
                failedFields && removeFailedFields(event.target.name);
              }}
              failedFields={failedFields}
            />
            <small><i>{translationService.translate("TR_MIN_FIFTY_STYMOLS")}</i></small>
            <div className="form-group">
              <div className="d-flex align-items-start">
                <label>{`${translationService.translate("TR_HOW_MUCH_OF_VIDEO_PRO_ARE_YOU")} ? *`}</label>
                <div className="info-title">
                  <div>
                    <InfoSvg />
                    <span style={{ display: "none" }}>
                      {translationService.translate("TR_CHOOSE_TEACHING_SKILLS_INFO")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="become-an-instructor-step-1">
                {
                  instructorLibraryData && instructorLibraryData.libraryData.map(instructorLibrary => <InputRadio
                    key={instructorLibrary.id}
                    id={instructorLibrary.id}
                    value={instructorLibrary.id}
                    name="videoProfessionalism"
                    labelValue={instructorLibrary.displayName}
                    blockClassName={`form-check
                     ${isInvalidSubmit && (!videoProfessionalism || failedFields?.hasOwnProperty(`${"videoProfessionalism".charAt(0).toUpperCase() + "videoProfessionalism".slice(1)}`))
                        ? "is-invalid error-bordered" : ""}`}
                    labelClassName="form-check-label"
                    radioClassName="form-check-input"
                    onChange={event => {
                      typeof +event.target.value === NUMBER_KEY && setVideoProfessionalism(+event.target.value);
                      failedFields && removeFailedFields(event.target.name);
                    }}
                    failedFields={failedFields}
                  />)
                }
              </div>
            </div>
            <button
              type="button"
              className="mindalay--btn-default mt-3"
              onClick={onSubmit}
            >
              {translationService.translate("TR_CONTINUE")}
            </button>

          </form>
        </div>
      </div>
    </div>
    : null;
}
export default withRouter(BecomeInstructor);