import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Route, withRouter } from "react-router-dom";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import TranslationService from "../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import KeySvg from "../../../Components/Svg/keySvg";
import { ADMIN_USER_TYPE } from "../../../Constants/mainKeys";
import CoursesSubMenu from "./AdminSubMenus/coursesSubMenu";
import FeedBacksSubMenu from "./AdminSubMenus/feedBacksSubMenu";
import CategoriesSubMenu from "./AdminSubMenus/categoriesSubMenu";
import TicketSvg from "../../../Components/Svg/ticketSvg";
import FeedbackSvg from "../../../Components/Svg/feedbackSvg";
import CourseSvg from "../../../Components/Svg/courseSvg";
import TicketsSubMenu from "./AdminSubMenus/ticketsSubMenu";
import HintWordsSubMenu from "./AdminSubMenus/hintWordsSubMenu";
import Categorysvg from "../../../Components/Svg/categorySvg";
import LogSubMenu from "./AdminSubMenus/logSubMenu";
import LogSvg from "../../../Components/Svg/logSvg";
import PaymentPackageSubMneu from "./AdminSubMenus/paymentPackageSubMneu";
import {
	BALANCE_LOGS_KEY,
	CATEGORIES_KEY,
	CATEGORY_KEY,
	DISCOUNT_KEY,
	KEY_WORD_KEY,
	LOGS_KEY,
	NOR_FOUND_KEY,
	PAYMENT_HISTORY_KEY,
	PAYMENT_LOGS_KEY,
	PAYMENT_PACKAGES_KEY,
	PAYMENT_PACKAGE_KEY,
	PAYMENT_RECEIPT_KEY,
	UNVERIFIED_KEY,
} from "./../../../Constants/urlKeys";
import {
	COURSES_KEY,
	COURSE_KEY,
	FEEDBACK_KEY,
	HOME_KEY,
	IN_PROCESS_KEY,
	MINDALAY_ADMIN,
	RESOLVED_KEY,
	SPECIALITY_KEY,
	TICKET_KEY,
	VERIFIED_KEY,
} from "../../../Constants/urlKeys";
import PaymentPackageSvg from "../../../Components/Svg/paymentPackageSvg";

const AdminLeftMenu = props => {
	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const { settings } = useSelector(state => state.settings);

	const [translationService, setTranslationService] = useState(null);
	const [hover, setHover] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const toggleHover = isShow => {
		setHover(isShow);
	};

	const showOrHideLeftMenu = bool => {
		!bool && toggleHover(false);
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	const adminUserType = user.userTypeId === ADMIN_USER_TYPE ? true : false;

	return translationService && user && adminUserType ? (
		<section
			className={`side-menu-container ${
				showOrHideGeneralLeftMenu ? "show-general-left-menu" : ""
			}`}>
			<div
				className={showOrHideGeneralLeftMenu ? "layer" : ""}
				onClick={() => showOrHideLeftMenu(false)}
			/>
			<aside
				className={`side-left instructor-header-zindex ${
					hover ? "w-fix-left-menu" : ""
				}`}
				onMouseEnter={() => toggleHover(true)}
				onMouseLeave={() => toggleHover(false)}>
				<div className="side-left-container">
					<div className="side-left-inner-wrapper">
						<ul className="side-left-list-wrapper">
							<li
								title={translationService.translate("TR_HOME")}
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								} ${
									props.location.pathname.includes(HOME_KEY)
										? "active-side-left-menu-item"
										: ""
								}`}>
								<Link
									to={`/${language}`}
									className="p-0 d-flex justify-content-between"
									onClick={() => showOrHideLeftMenu(false)}>
									{settings && settings.mainLogoPath ? (
										<img src={settings.mainLogoPath} alt="/" style={{ width: "120px", maxWidth: "150px", maxHeight: "150px", display: "block", margin: "0 auto" }} />
									) : (
										<MindalaySvg />
									)}
									{/* <span
										className=" ml-2"
										style={{
											fontSize: 16,
											fontWeight: 400,
											transform: "none",
										}}>
										(Beta)
									</span> */}
								</Link>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_COURSES")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${VERIFIED_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(
											`${COURSES_KEY}/${VERIFIED_KEY}`,
										) ||
										props.location.pathname.includes(
											`${COURSES_KEY}/${UNVERIFIED_KEY}`,
										) ||
										props.location.pathname.includes(
											`${COURSES_KEY}/${DISCOUNT_KEY}`,
										) ||
										props.location.pathname.includes(`/${COURSE_KEY}/`)
											? "active-side-left-menu-item"
											: ""
									}>
									<CourseSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_COURSES")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_FEEDBACKS")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${FEEDBACK_KEY}/${COURSES_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(FEEDBACK_KEY)
											? "active-side-left-menu-item"
											: ""
									}>
									<FeedbackSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_FEEDBACKS")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_TICKETS")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${TICKET_KEY}/${IN_PROCESS_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(TICKET_KEY)
											? "active-side-left-menu-item"
											: ""
									}>
									<TicketSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_TICKETS")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_KEY_WORDS")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${KEY_WORD_KEY}/${SPECIALITY_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(KEY_WORD_KEY)
											? "active-side-left-menu-item"
											: ""
									}>
									<KeySvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_KEY_WORDS")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_CATEGORIES")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${CATEGORIES_KEY}/${CATEGORY_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(CATEGORIES_KEY)
											? "active-side-left-menu-item"
											: ""
									}>
									<Categorysvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_CATEGORIES")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_PAYMENT_RECEIPT")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${PAYMENT_PACKAGE_KEY}/${PAYMENT_RECEIPT_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes(PAYMENT_PACKAGE_KEY)
											? "active-side-left-menu-item"
											: ""
									}>
									<PaymentPackageSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_PAYMENT_PACKAGE")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}
								title={translationService.translate("TR_LOGS")}>
								<NavLink
									to={`/${language}/${MINDALAY_ADMIN}/${LOGS_KEY}`}
									onClick={() => toggleHover(false)}
									className={
										props.location.pathname.includes("logs")
											? "active-side-left-menu-item"
											: ""
									}>
									<LogSvg />
									{/* <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_LOGS")}</span> */}
									<span className={hover ? "tr-x-0" : ""}></span>
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</aside>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${COURSES_KEY}`}
				component={CoursesSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${FEEDBACK_KEY}`}
				component={FeedBacksSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${TICKET_KEY}/${IN_PROCESS_KEY}`}
				component={TicketsSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${TICKET_KEY}/${RESOLVED_KEY}`}
				component={TicketsSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${KEY_WORD_KEY}`}
				component={HintWordsSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${CATEGORIES_KEY}`}
				component={CategoriesSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${LOGS_KEY}`}
				component={LogSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${PAYMENT_LOGS_KEY}`}
				component={LogSubMenu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${BALANCE_LOGS_KEY}`}
				component={LogSubMenu}
			/>
			{/* <Route path={`/${language}/${MINDALAY_ADMIN}/${PAYMENT_PACKAGE_KEY}/${PAYMENT_PACKAGES_KEY}`} component={PaymentPackageSubMneu} /> */}
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${PAYMENT_PACKAGE_KEY}/${PAYMENT_RECEIPT_KEY}`}
				component={PaymentPackageSubMneu}
			/>
			<Route
				path={`/${language}/${MINDALAY_ADMIN}/${PAYMENT_PACKAGE_KEY}/${PAYMENT_HISTORY_KEY}`}
				component={PaymentPackageSubMneu}
			/>
			<Route
				path={`/${language}/${NOR_FOUND_KEY}/404`}
				component={LogSubMenu}
			/>
		</section>
	) : null;
};

export default withRouter(AdminLeftMenu);
