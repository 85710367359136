import React from "react";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InfoSvg from "../Svg/infoSvg";

const InputCheckBox = props => {
	return (
		<Auxiliary>
			<div
				className={`form-group custom-checkbox custom-control ${props.blockClassName ? props.blockClassName : ""
					}`}>
				<input
					type="checkbox"
					id={props.id}
					name={props.name}
					disabled={props.disabled}
					value={props.value}
					checked={props.checked}
					defaultChecked={props.defaultChecked}
					className={`custom-control-input
          ${props.checkBoxClassName ? props.checkBoxClassName : ""}
          ${(props.failedFields &&
							props.failedFields.hasOwnProperty(
								`${props.name.charAt(0).toUpperCase() + props.name.slice(1)}`,
							)) ||
							props.isInvalidField
							? "is-invalid error-bordered"
							: ""
						}`}
					onChange={props.onChange}
				/>
				{props.labelValue ? (
					<>
						<label
							className={`custom-control-label cursor-pointer d-flex ${props.labelClassName ? props.labelClassName : ""
								}`}
							style={{ color: props.textColor ? props.textColor : "var(--mindalay--gray-color)" }}
							htmlFor={props.id}>
							{props.image && (
								<div
									className="navbar-profile-image background-image-cover background-image;"
									style={{
										backgroundImage: `url(${props.image})`,
										cursor: "pointer",
										backgroundColor: "var(--mindalay--secondary-color)",
										border: "2px solid var(--mindalay--white-color)",
										boxSizing: "content-box",
										width: "20px",
										height: "20px",
										borderRadius: "50%",
										flex: "none",
									}}></div>
							)}
							{props.labelValue}
							{props.coursesCount || props.coursesCount === 0 ? (
								<span>
									(<span>{props.coursesCount}</span>)
								</span>
							) : null}
						</label>
					</>
				) : null}
				{props.infoText ? (
					<div className="info-title d-inline-block">
						<div>
							<InfoSvg />
							<span
								className={
									props.infoTextClassName ? props.infoTextClassName : ""
								}
								style={{ display: "none" }}>
								{props.infoText}
							</span>
						</div>
					</div>
				) : null}
			</div>
			{props.failedFields &&
				props.failedFields.hasOwnProperty(
					`${props.name.charAt(0).toUpperCase() + props.name.slice(1)}`,
				) ? (
				<small className="small-error-msg">
					{
						props.failedFields[
						props.name.charAt(0).toUpperCase() + props.name.slice(1)
						]
					}
				</small>
			) : null}
		</Auxiliary>
	);
};

export default InputCheckBox;
