import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { Link, withRouter } from "react-router-dom";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../../Services/alertService";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	SUCCESS_KEY,
	TR_YES,
	WARNING_KEY,
} from "../../../Constants/mainKeys";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import Input from "../../../Components/Inputs/input";
import ReactSelectOption from "../../../Components/SelectOption/reactSelectOption";
import ArrowBackSvg from "../../../Components/Svg/arrowBackSvg";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import Textarea from "../../../Components/Inputs/textArea";
import ActionButton from "../../../Components/ActionButton/actionButton";
import ApiService from "../../../Services/apiService";

const buttonSpinnerId = uuid();
const EducationalUnitForm = props => {
	const { educationalUnitId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [educationalUnits, setEducationalUnits] = useState([]);

	const fieldsLength = {
		nameMaxLength: 255,
		descMaxLength: 1000,
	};

	const [values, setValues] = useState({
		parentId: null,
		name: "",
		description: "",
		lecturerAttachmentOn: false,
		divisionAttachmentOn: false,
		// containsPractice: false,
	});

	useEffect(() => {
		if (localStorage.getItem("parentId") && educationalUnits.length) {
			setValues(values => ({
				...values,
				parentId: +localStorage.getItem("parentId"),
			}));
			setTimeout(() => {
				localStorage.removeItem("parentId");
			}, 1);
		}
	}, [educationalUnits]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (educationalUnitId) {
			getEducationalUnitsById(educationalUnitId);
		}
		getAllEducationalUnits();
	}, [educationalUnitId]);

	const getAllEducationalUnits = divisionId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAllEducationalUnits(divisionId)
			.then(response => {
				if (response && response.data) {
					setEducationalUnits(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getEducationalUnitsById = educationalUnitId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getEducationalUnitsById(educationalUnitId)
			.then(response => {
				if (response && response.data) {
					setValues(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldName]: item.value,
		}));
	};

	const onCheckboxChange = event => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			if (event.target.name === "lecturerAttachmentOn") {
				setValues(values => ({
					...values,
					// containsPractice: false,
					divisionAttachmentOn: false,
					[event.target.name]: event.target.checked,
				}));
				return;
			} else if (event.target.name === "divisionAttachmentOn") {
				setValues(values => ({
					...values,
					lecturerAttachmentOn: false,
					// containsPractice: !event.target.checked
					// 	? false
					// 	: values.containsPractice,
					[event.target.name]: event.target.checked,
				}));
				return;
			} else {
				setValues(values => ({
					...values,
					[event.target.name]: event.target.checked,
				}));
			}
			setIsInvalidSubmit(false);
		}
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		(educationalUnitId
			? ApiService.updateEducationalUnit(values)
			: ApiService.createEducationalUnit(values)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				props.history.push(`/${language}/educational-unit`);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center">
						<Link
							to={`/${language}/educational-unit`}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						<h2 className="title">
							{!educationalUnitId
								? translationService.translate("TR_CREATE_EDUACTIONAL_UNIT")
								: translationService.translate("TR_UPDATE_EDUCATIONAL_UNIT")}
						</h2>
					</div>
				</div>
			</div>
			<hr />
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-12 my-1">
						<Input
							type="text"
							id="name"
							name="name"
							inputClassName="pr--5"
							value={values.name}
							fieldLength={fieldsLength.nameMaxLength}
							isInvalidSubmit={isInvalidSubmit}
							labelValue={`${translationService.translate("TR_NAME")}*`}
							onChange={event =>
								onChange(event, "name", fieldsLength.nameMaxLength)
							}
						/>
					</div>

					<div className="col-md-6 col-12 mb-2">
						<label>
							{translationService.translate("TR_CHOOSEPARENTEDUCATIONALUNIT")}
						</label>
						<ReactSelectOption
							isDisabled={!!educationalUnitId}
							value={values.parentId}
							isSearchable={true}
							selectedValue={(() => {
								const selectedItem = {
									...educationalUnits.find(data => data.id === values.parentId),
								};
								if (Object.entries(selectedItem).length) {
									selectedItem.label = selectedItem.name;
									selectedItem.value = selectedItem.id;
									return selectedItem;
								} else {
									return {
										value: null,
										label: translationService.translate("TR_CHOOSE"),
									};
								}
							})()}
							items={educationalUnits.map(data => ({
								...data,
								label: data.name,
								value: data.id,
							}))}
							onChange={item => onSelectOptionChange(item, "parentId")}
						/>
					</div>

					<div className="col-12 my-1">
						<div className="row">
							<div className="col-md-4 col-12 my-1">
								<label
									htmlFor="lecturerAttachmentOn"
									className="cursor-pointer">
									{translationService.translate("TR_LECTURERATTACHMENT")}
								</label>
								<InputCheckBox
									id="lecturerAttachmentOn"
									name="lecturerAttachmentOn"
									checked={values.lecturerAttachmentOn}
									labelValue={translationService.translate(TR_YES)}
									onChange={onCheckboxChange}
								/>
							</div>
							<div className="col-md-4 col-12 my-1">
								<label
									htmlFor="divisionAttachmentOn"
									className="cursor-pointer">
									{translationService.translate("TR_DIVISIONATTACHMENT")}
								</label>
								<InputCheckBox
									id="divisionAttachmentOn"
									name="divisionAttachmentOn"
									checked={values.divisionAttachmentOn}
									labelValue={translationService.translate(TR_YES)}
									onChange={onCheckboxChange}
								/>
							</div>
							{/* {values.divisionAttachmentOn && (
								<div className="col-md-4 col-12 my-1">
									<label htmlFor="containsPractice" className="cursor-pointer">
										Contains practice
									</label>
									<InputCheckBox
										id="containsPractice"
										name="containsPractice"
										checked={values.containsPractice}
										labelValue={translationService.translate(TR_YES)}
										onChange={onCheckboxChange}
									/>
								</div>
							)} */}
						</div>
					</div>

					<div className="col-12">
						<Textarea
							rows="3"
							id="description"
							name="description"
							textAreaClassName="pr--5"
							value={values.description}
							max={fieldsLength.descMaxLength}
							labelValue={translationService.translate("TR_DESCRIPTION")}
							onChange={event =>
								onChange(event, "description", fieldsLength.descMaxLength)
							}
						/>
					</div>

					<div className="col-12">
						<div className="mt-3">
							<ActionButton
								spinnerId={buttonSpinnerId}
								type="submit"
								className="mindalay--btn-dark position-relative"
								name={translationService.translate(
									!educationalUnitId ? "TR_CREATE" : "TR_UPDATE",
								)}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	) : null;
};

export default withRouter(EducationalUnitForm);
