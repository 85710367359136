import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import TranslationService from "../../../../Services/translationService";
import { CATEGORY_KEY } from "../../../../Constants/urlKeys";
import Loader from 'react-loader-spinner';

const CategoryDropdowns = (props) => {
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const courseCategories = useSelector(state => state.course.courseCategories);

  const [firstLevelCategoryId, setFirstLevelCategoryId] = useState(null);
  const [secondLevelCategoryId, setSecondLevelCategoryId] = useState(null);
  const [translationService, setTranslationService] = useState(null);

  const onChangeFirstLevelCategoryId = (firstLevelCategory) => {
    setFirstLevelCategoryId(firstLevelCategory.id);
    setSecondLevelCategoryId(null);
  }

  const onChangeSecondLevelCategoryId = (secondLevelCategory) => {
    setSecondLevelCategoryId(secondLevelCategory.id)
  }

  const onChangeAllLevelCategoryIds = () => {
    setFirstLevelCategoryId(null);
    setSecondLevelCategoryId(null);
    props.clearSearchField();
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ?
    <div className="category-dropdown nav-item dropdown nav-dropdown d-none d-lg-block mx-4 category-filter-dropdown">
      {
        courseCategories && courseCategories.length ?
          <Dropdown>
            <Dropdown.Toggle variant="transparent">
              <div className="nav-link m-0">
                {translationService.translate("TR_CATEGORIES")}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ul className="ul-wrapper">
                {
                  courseCategories.map(category => {
                    const categoryChildren = category.children;
                    return <li key={category.id} className={`nav-item has-submenu ${!categoryChildren || !categoryChildren.length ? "hide-arrow" : ""}`}>
                      <Dropdown.Toggle variant="light">
                        <Link
                          to={`/${language}/${CATEGORY_KEY}/${category.id}`}
                          onMouseOver={() => onChangeFirstLevelCategoryId(category)}
                          className={`${category?.id === firstLevelCategoryId ? "active-category" : ""}`}
                          onClick={() => onChangeAllLevelCategoryIds()}
                        >
                          {category.name}
                        </Link>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul className="megasubmenu">
                          {
                            categoryChildren.map(categoryChild => {
                              const subCategoryChildren = categoryChild.children || categoryChild.themeChildren;
                              return <li key={categoryChild.id} className={`nav-item has-submenu ${!subCategoryChildren || !subCategoryChildren.length ? "hide-arrow" : ""}`}>
                                <Dropdown.Toggle variant="light">
                                  <Link
                                    to={`/${language}/${CATEGORY_KEY}/${category.id}/${categoryChild.id}`}
                                    onMouseOver={() => onChangeSecondLevelCategoryId(categoryChild)}
                                    className={`${categoryChild?.id === secondLevelCategoryId ? "active-category" : ""}`}
                                    onClick={() => onChangeAllLevelCategoryIds()}
                                  >
                                    {categoryChild.name}
                                  </Link>
                                </Dropdown.Toggle>
                                {
                                  subCategoryChildren && subCategoryChildren.length
                                    ? <Dropdown.Menu>
                                      <ul className="megasubmenu">
                                        {
                                          subCategoryChildren.map(subCategoryChild => {
                                            return <li key={subCategoryChild.id} className="nav-item has-submenu hide-arrow">
                                              <Dropdown.Toggle variant="light">
                                                <Link
                                                  to={`/${language}/${CATEGORY_KEY}/${category.id}/${categoryChild.id}/${subCategoryChild.id}`}
                                                  onClick={() => onChangeAllLevelCategoryIds()}
                                                >
                                                  {subCategoryChild.name}
                                                </Link>
                                              </Dropdown.Toggle>
                                            </li>;
                                          })
                                        }
                                      </ul>
                                    </Dropdown.Menu> : null
                                }
                              </li>;
                            })
                          }
                        </ul>
                      </Dropdown.Menu>
                    </li>
                  })
                }
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          : <Dropdown>
            <Dropdown.Toggle variant="transparent">
              <div className="nav-link m-0">
                {translationService.translate("TR_CATEGORIES")}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ul className="ul-wrapper">
                {
                  <li className={`nav-item has-submenu hide-arrow`}>
                    <Dropdown.Toggle variant="light">
                      <Link to="#" className="p-5">
                        <Loader type="ThreeDots" color="#32064A" height={60} width={60} />
                      </Link>
                    </Dropdown.Toggle>
                  </li>
                }
              </ul>
            </Dropdown.Menu>
          </Dropdown>
      }

    </div> : null;
}

export default CategoryDropdowns;