import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputCheckBox from '../../Components/Inputs/inputCheckBox';
import { NUMBER_KEY } from '../../Constants/mainKeys';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import TranslationService from '../../Services/translationService';

const ConsultantBlock = (props) => {

  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return (
    translationService ?  <div key={props.consultant.id} className="col-12">
      <div className="box-shadow-4 mindalay--consultation-container mindalay--consultant-container mindalay--hover-effect cursor-pointer d-flex mt-3">
        <div className="consultation-user-image" style={{ backgroundImage: `url(${props.consultant.imagePath})` }}></div>
        <div className="w-100">
          <div className="d-md-flex justify-content-between">
            <div className="consultation-container-left-side">
              <div className="d-sm-flex align-items-center">
                <strong className="consultation-header-name">{props.consultant.fullName}</strong>
                {
                  typeof props.consultant.rating === NUMBER_KEY ?
                    <div className=" ml-2 consultation-rating">
                      <div className="rating"><div style={{ width: `${props.consultant.rating * 20}%` }}></div></div>
                      <small>({props.consultant.totalReviews})</small>
                    </div>
                    : null
                }
              </div>
              <small className="gmt-time">
                {
                  props.convertUtcDayTimeToLocal(props.consultant.preferredStartTime.hours, props.consultant.preferredStartTime.minutes)
                }
                &nbsp;-&nbsp;
                {
                  props.convertUtcDayTimeToLocal(props.consultant.preferredEndTime.hours, props.consultant.preferredEndTime.minutes)
                }
              </small>
              <small className="consultation-houres">
                <span>{props.consultant.totalConsultationHours ? Math.round(props.consultant.totalConsultationHours) : 0}</span>
                {` ${translationService.translate("TR_HOURS_CONSULTATION")}`}
              </small>
            </div>
            <div className="ml-lg-4 consultation-price">
              {
                props.consultant.approximateCost ?
                  <Auxiliary >
                    <div className="text-md-right text-sm-left text-center">
                      <strong className="d-block brand-color">
                        {props.consultant.approximateCost}
                      </strong>
                    </div>
                    <div className="d-flex align-items-center justify-content-sm-start justify-content-md-end justify-content-center">
                      <small>{translationService.translate("TR_DURATION")}: </small>
                      <b className="mx-1">
                        {props.durationMax}
                      </b>
                      <small>{translationService.translate("TR_MINUTES")}</small>
                    </div>
                  </Auxiliary>
                  : null
              }
            </div>
          </div>
          <hr />
          <InputCheckBox
            id={props.consultant.id}
            labelValue={translationService.translate("TR_INVITE")}
            onChange={(event) => props.addConsultantToList(event, props.consultant.id)}
          />
        </div>
      </div>
    </div> : null
  );
}

export default ConsultantBlock;
