import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import AlertService from "../../../Services/alertService";
import ApiService from "../../../Services/apiService";
import LockSvg from "../../../Components/Svg/lockSvg";
import GameSvg from "../../../Components/Svg/gameSvg";
import TranslationService from "../../../Services/translationService";
import {
	PASS_COURSE_KEY,
	PASS_COURSE_VIDEO_KEY,
} from "../../../Constants/urlKeys";
import {
	ERROR_KEY,
	GAMIFY_SECTION_ENTITY_TYPE_ID,
	GAMIFY_TOPIC_ENTITY_TYPE_ID,
} from "../../../Constants/mainKeys";
import { Link } from "react-router-dom";
import GamifyApiService from "../../../Services/gamifyApiService";
import GamificationItemsComponent from "../../../OrganizationPages/Gamification/Components/Modals/GamificationItemsComponent";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";

const Topics = props => {
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const [translationService, setTranslationService] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const [gamifyData, setGamifyData] = useState(null);
	const [selectedTopic, setSelectedTopic] = useState(null);
	const { courseId, studentCourseId, topicId } = props.match.params;

	const history = useHistory();

	const openTopicVideoView = topicId => {
		(user && user.isOrganizationUser
			? ApiService.getStudentCourseAndTopicDataByOrgStudent(
					+studentCourseId,
					topicId,
			  )
			: ApiService.getStudentCourseAndTopicData(+studentCourseId, topicId)
		)
			.then(response => {
				response &&
					history.push(
						`/${language}/${PASS_COURSE_KEY}/${PASS_COURSE_VIDEO_KEY}/${courseId}/${studentCourseId}/${topicId}`,
					);
			})
			.catch(error => getFail(error));
	};

	const changeTopicData = async topic => {
		if (topic.isVisible && !isLoading) {
			setIsLoading(1);
			await onUnload();
			(user && user.isOrganizationUser
				? ApiService.getStudentCourseAndTopicDataByOrgStudent(
						+studentCourseId,
						topic.id,
				  )
				: ApiService.getStudentCourseAndTopicData(+studentCourseId, topic.id)
			)
				.then(response => {
					const data = { ...response.data };
					if (data) {
						props.changeTopicData(data);
						props.getQuizzesAssignment(topic.id);
						history.push(
							`/${language}/${PASS_COURSE_KEY}/${PASS_COURSE_VIDEO_KEY}/${courseId}/${studentCourseId}/${topic.id}`,
						);
					}
					setIsLoading(null);
				})
				.catch(error => getFail(error));
		} else {
			return false;
		}
	};

	const getGamifyDataByEntity = (topicData, entityType) => {
		if (!topicData || !entityType || isLoading) {
			return false;
		}
		setIsLoading(topicData.id);
		const data = {
			entityId: topicData.id,
			entityTypeId: entityType,
			parentId: +topicData.courseSectionId,
			parentTypeId: GAMIFY_SECTION_ENTITY_TYPE_ID, //GroupId
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					setGamifyData(response.data);
					setSelectedTopic(topicData);
				}
				setIsLoading(null);
			})
			.catch(error => getFail(error));
	};

	const onUnload = async () => {
		var video = document.getElementById("video");
		if (studentCourseId && topicId) {
			video &&
				(await ApiService.updateVideoTimestump(
					+studentCourseId,
					+topicId,
					Math.floor(video.currentTime),
				));
		}
	};

	const cancel = () => {
		setSelectedTopic(null);
		setGamifyData(null);
		setIsLoading(null);
	};

	const getFail = error => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		setIsLoading(null);
	};

	return translationService ? (
		<Auxiliary>
			<GamificationItemsComponent
				entityData={selectedTopic}
				gamifyData={gamifyData}
				entityTypeId={GAMIFY_TOPIC_ENTITY_TYPE_ID}
				cancel={cancel}
			/>
			{props.topics && props.topics.length
				? props.topics.map(topic => {
						return (
							<div
								key={topic.id}
								className={`card-body`}
								onClick={
									!props.showBtnView
										? () => {
												changeTopicData(topic);
										  }
										: null
								}
								title={
									topic.visiblilityCondition ? topic.visiblilityCondition : null
								}>
								<div
									className={`card-header d-flex align-items-center ${
										props.history.location.pathname.includes(topic.id)
											? "active-pass-course"
											: ""
									}`}>
									{topic.isPassed ? (
										<span className="light-green-background">
											<i className="fas fa-check-circle green-color"></i>
										</span>
									) : (
										<span className="light-blue-background">
											<i className="far fa-circle blue-color"></i>
										</span>
									)}
									<div className="d-flex w-100 justify-content-between">
										<h5 className="mb-0 text-elipsis card-course-name mr-2">
											{topic.name}
										</h5>
										<div className="d-flex align-items-center">
											{!topic.isVisible ? (
												<LockSvg className="mr-2" width="20" height="20" />
											) : null}
											{user && user.isGamifyAvailable && topicId ? (
												<Link
													className={`course-gamify-button border-none pass-course-gamify-button`}
													to="#"
													title={translationService.translate(
														"TR_GAMIFICATION_ICON",
													)}
													onClick={event => {
														event.stopPropagation();
														getGamifyDataByEntity(
															topic,
															GAMIFY_TOPIC_ENTITY_TYPE_ID,
														);
													}}>
													{isLoading && isLoading === topic.id ? (
														<div
															className="spinner-border text-success"
															style={{
																width: "15px",
																height: "15px",
																borderWidth: "1px",
															}}
														/>
													) : (
														<GameSvg width="20" />
													)}
												</Link>
											) : null}
										</div>
									</div>
									{!topic.isVisible
										? null
										: props.showBtnView && (
												<button
													type="button"
													className="mindalay--btn-default mindalay--btn-small"
													onClick={() => {
														openTopicVideoView(topic.id);
													}}>
													{translationService.translate("TR_VIEW")}
												</button>
										  )}
								</div>
							</div>
						);
				  })
				: null}
		</Auxiliary>
	) : null;
};

export default withRouter(Topics);
