import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import * as moment from "moment";
import TranslationService from "../../Services/translationService";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import MainService from "../../Services/mainService";
import SelectOption from "../../Components/SelectOption/selectOption";
import Input from "../../Components/Inputs/input";
import TextArea from "../../Components/Inputs/textArea";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import { compose } from "redux";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import { ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import {
	TR_CREATE_MESSAGE_KEY,
	TR_UPDATE_MESSAGE_KEY,
} from "../../Constants/translationKeys";
import {
	COURSE_DASHBOARD_KEY,
	CURICULUMN_KEY,
	QUIZ_VIEW_KEY,
} from "../../Constants/urlKeys";
import {
	TIME_TYPES_ARR_KEY,
	EXPIRE_TYPES_ARR_KEY,
	ALLOWED_ATTEMPTS_ARR_KEY,
	GRADING_METHODS_ARR_KEY,
	QUESTION_BEHAVIOUR,
	CONFIRM_ARRAY,
	BOOLEAN_KEY,
	NUMBER_KEY,
	QUIZ_REVIEW_OPTIONS_CATEGORY_TYPE,
} from "../../Constants/mainKeys";
import { ORGANIZATION_KEY } from "../../Constants/requestKeys";
import HelpService from "../../Services/helpService";
import SmallQuestionMark from "../../Components/SmallQuestionMark/smallQuestionMark";

class QuizForm extends Component {
	state = {
		form: {
			entityId:
				this.props.match.params.topicId || this.props.match.params.courseId,
			entityType: this.props.match.params.topicId ? 1 : 0,
			name: "",
			description: "",
			displayDescription: false,
			openDate: "",
			openDateEnabled: false,
			closeDate: "",
			closeDateEnabled: false,
			timeLimit: "",
			timeLimitEntity: 1,
			timeLimitEnabled: false,
			whenTimeExpires: 0,
			gracePeriod: 1,
			gracePeriodEntity: 1,
			gracePeriodEnabled: false,
			passGrade: "",
			maxgrade: "",
			attemptsAllowed: 0,
			gradingMethod: 0,
			shuffleEnabled: 1,
			reviewOptions: [],
			questionBehaviour: 0,
		},
		quizId: this.props.match.params.quizId,
		courseId: this.props.match.params.courseId,
		categories: [],
		categoryItems: [],
		spinnerId: uuid(),
		descriptionMaxLength: 2000,
		nameMaxLength: 200,
		translationService: null,
		failedFields: null,
		isInvalidSubmit: false,
		isInvalidStartDate: false,
		isInvalidEndDate: false,
	};

	componentDidMount() {
		this.setTranslations();
		this.getReviewOptionsCategories();
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	getReviewOptionsCategories = () => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getCategoriesByType(QUIZ_REVIEW_OPTIONS_CATEGORY_TYPE)
			.then(response => {
				let categories = response.data;
				this.getReviewOptionsCategoryItems().then(response => {
					const categoryItems = response;
					this.setState({ categories, categoryItems });
					if (this.state.quizId) {
						this.getQuizById(this.state.quizId);
					} else {
						categories = this.setReviewOptions(null, categories, categoryItems);
						const reviewOptions = [];
						categories.forEach(category => {
							categoryItems.forEach(categoryItem => {
								reviewOptions.push({
									categoryId: category.id,
									optionKey: categoryItem.name,
									optionValue: false,
								});
							});
						});
						this.setState(prevState => ({
							...prevState,
							categories,
							form: { ...prevState.form, reviewOptions },
						}));
					}
				});
				this.props.removePageSpinner(spinnerId);
			})
			.catch(e => this.getFail(spinnerId, e));
	};

	getQuizById = quizId => {
		const { spinnerId } = this.state;
		ApiService.getQuizById(quizId)
			.then(response => {
				const quizResponse = response.data;
				const responseReviewOptions = [...quizResponse.reviewOptions];
				let categories = [...this.state.categories];
				const categoryItems = [...this.state.categoryItems];
				categories = this.setReviewOptions(
					responseReviewOptions,
					categories,
					categoryItems,
				);
				const reviewOptions = [];
				categories.forEach(category => {
					categoryItems.forEach(categoryItem => {
						const selectedResponseReviewOption = responseReviewOptions.find(
							data =>
								data.categoryId === category.id &&
								categoryItem.name === data.optionKey,
						);
						reviewOptions.push({
							categoryId: category.id,
							optionKey: categoryItem.name,
							optionValue: selectedResponseReviewOption ? true : false,
						});
					});
				});
				this.setState(prevState => ({
					...prevState,
					categories,
					form: {
						...prevState.form,
						entityId: quizResponse.entityId,
						entityType: quizResponse.entityType,
						name: quizResponse.name,
						description: quizResponse.description,
						displayDescription: quizResponse.displayDescription,
						openDate: quizResponse.openDate
							? moment(
									MainService.convertUTCDateToLocalDate(
										new Date(quizResponse.openDate),
									),
							  ).format("YYYY-MM-DDTHH:mm:ss")
							: "",
						openDateEnabled: quizResponse.openDateEnabled,
						closeDate: quizResponse.closeDate
							? moment(
									MainService.convertUTCDateToLocalDate(
										new Date(quizResponse.closeDate),
									),
							  ).format("YYYY-MM-DDTHH:mm:ss")
							: "",
						closeDateEnabled: quizResponse.closeDateEnabled,
						timeLimit: quizResponse.timeLimit || "",
						timeLimitEntity: quizResponse.timeLimitEntity,
						timeLimitEnabled: quizResponse.timeLimitEnabled,
						whenTimeExpires: quizResponse.whenTimeExpires,
						gradingMethod: quizResponse.gradingMethod,
						gracePeriod: quizResponse.gracePeriod || "",
						gracePeriodEntity: quizResponse.gracePeriodEntity,
						gracePeriodEnabled: quizResponse.gracePeriodEnabled,
						passGrade: quizResponse.passGrade ? quizResponse.passGrade : "",
						attemptsAllowed: quizResponse.attemptsAllowed,
						shuffleEnabled: quizResponse.shuffleEnabled === true ? 1 : 0,
						questionBehaviour: quizResponse.questionBehaviour,
						maxgrade: quizResponse.maxGrade ? quizResponse.maxGrade : "",
						reviewOptions,
					},
				}));
			})
			.catch(e => this.getFail(spinnerId, e));
	};

	setReviewOptions = (reviewOptions, categories, categoryItems) => {
		if (
			categories &&
			categories.length &&
			categoryItems &&
			categoryItems.length
		) {
			categories.forEach((category, categoryIndex) => {
				const currentCategory = { ...category };
				currentCategory.categoryItems = [...categoryItems];
				currentCategory.categoryItems.forEach((item, itemIndex) => {
					const currentItem = { ...item };
					const selectedReviewOptions =
						reviewOptions &&
						reviewOptions.find(
							data =>
								data.categoryId === category.id && data.optionKey === item.name,
						);
					currentItem.optionValue = selectedReviewOptions ? true : false;
					currentCategory.categoryItems[itemIndex] = currentItem;
				});
				categories[categoryIndex] = currentCategory;
			});
		}
		categories.forEach(category => {
			if (!category.categoryItems[0].optionValue) {
				category.categoryItems.forEach(item => {
					item.disabled = true;
					category.categoryItems[0].disabled = false;
				});
			} else {
				category.categoryItems.forEach(item => {
					item.disabled = false;
					category.categoryItems[0].disabled = false;
				});
			}
		});
		return [...categories];
	};

	getReviewOptionsCategoryItems = () => {
		return new Promise((resolve, reject) =>
			ApiService.getReviewOptionsCategoryItems()
				.then(response => {
					let data = response.data.libraryData;
					data.map(library => (library.optionValue = false));
					resolve(data);
				})
				.catch(e => reject(e)),
		);
	};

	onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const failedFields = this.removeFailedFields(event.target.name);
		this.setState(prevState => ({
			...prevState,
			failedFields,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
			},
		}));
	};

	onTriggerCheckbox = (event, fieldName) => {
		const failedFields = this.removeFailedFields(event.target.name);
		if (fieldName === "openDate") this.setState({ isInvalidStartDate: null });
		if (fieldName === "closeDate") this.setState({ isInvalidEndDate: null });
		typeof event.target.checked === BOOLEAN_KEY &&
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: {
					...prevState.form,
					[event.target.name]: event.target.checked,
					[fieldName]: "",
				},
			}));
	};

	onCheckboxChange = event => {
		const failedFields = this.removeFailedFields(event.target.name);
		typeof event.target.checked === BOOLEAN_KEY &&
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: {
					...prevState.form,
					[event.target.name]: event.target.checked,
				},
			}));
	};

	onTriggerCheckboxes = (event, firstField, secondField) => {
		const failedFields = this.removeFailedFields(event.target.name);
		typeof event.target.checked === BOOLEAN_KEY &&
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: {
					...prevState.form,
					[event.target.name]: event.target.checked,
					[firstField]: "",
					[secondField]: 1,
				},
			}));
	};

	onDateChange = event => {
		const failedFields = this.removeFailedFields(event.target.name);
		this.setState(prevState => ({
			...prevState,
			failedFields,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
			},
		}));
	};

	checkDateFormatAndIsValid = (date, name, error) => {
		if (!date || !name) {
			return;
		}
		const { openDate, closeDate } = this.state.form;
		const { isInvalidStartDate, isInvalidEndDate } = this.state;
		const isValidDateFormat = MainService.isValidDateTime(date);
		const isValidDate = MainService.validateDate(date);
		if (!isValidDateFormat || !isValidDate) {
			this.setState({ [error]: true });
		} else this.setState({ [error]: false });
		if (openDate && closeDate && !isInvalidStartDate && !isInvalidEndDate) {
			const dateCompaire = MainService.checkDates(openDate, closeDate);
			if (dateCompaire && isValidDate && isValidDateFormat) {
				this.setState({ [error]: false });
			} else {
				this.setState({ [error]: true });
			}
		}
	};

	onNumberChange = event => {
		const { maxGrade, passGrade } = this.state.form;
		if (
			(event.target.name === "timeLimit" ||
				event.target.name === "gracePeriod" ||
				event.target.name === "passGrade" ||
				event.target.name === "maxgrade") &&
			+event.target.value.charAt(0) === 0
		) {
			event.target.value = event.target.value.substring(1);
		}
		if (event.target.name === "whenTimeExpires") {
			if (+event.target.value === 2) {
				this.setState(prevState => ({
					...prevState,
					form: { ...prevState.form, gracePeriodEnabled: true },
				}));
			} else {
				this.setState(prevState => ({
					...prevState,
					form: {
						...prevState.form,
						gracePeriodEnabled: false,
						gracePeriod: "",
						gracePeriodEntity: 0,
					},
				}));
			}
		}

		if (
			event.target.name === "shuffleEnabled" &&
			(event.target.value === 1 || event.target.value === "1")
		) {
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: { ...prevState.form, [event.target.name]: true },
			}));
		}

		const failedFields = this.removeFailedFields(event.target.name);
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				Number(event.target.value) >= 0)
		) {
			if (maxGrade < passGrade) this.setState({ isInvalidSubmit: false });
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: { ...prevState.form, [event.target.name]: event.target.value },
			}));
		}
	};

	changeCategoryItem = (event, categoryId, categoryItem, category) => {
		const form = { ...this.state.form };
		const reviewOptions = [...form.reviewOptions];
		if (typeof event.target.checked === BOOLEAN_KEY) {
			reviewOptions.forEach(reviewOption => {
				if (
					reviewOption.categoryId === categoryId &&
					reviewOption.optionKey === categoryItem.name
				) {
					reviewOption.optionValue = event.target.checked;
				}
			});
			form.reviewOptions = reviewOptions;
			this.setState({ form });
		}

		if (
			categoryItem.id === category.categoryItems[0].id &&
			category.categoryItems[0].optionValue === false
		) {
			category.categoryItems.forEach(item => {
				category.categoryItems[0].optionValue = true;
				category.categoryItems[0].disabled = false;
				item.disabled = false;
			});
		} else if (
			categoryItem.id === category.categoryItems[0].id &&
			category.categoryItems[0].optionValue === true
		) {
			category.categoryItems.forEach(item => {
				item.disabled = true;
				category.categoryItems[0].optionValue = false;
				category.categoryItems[0].disabled = false;
			});
		}
		if (
			categoryItem === category.categoryItems[0] &&
			!categoryItem.optionValue
		) {
			reviewOptions.forEach(reviewOption => {
				if (reviewOption.categoryId === category.id) {
					reviewOption.optionValue = false;
				}
			});
			form.reviewOptions = reviewOptions;
			this.setState({ form });
		}
	};

	scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	onSubmit = ev => {
		ev.preventDefault();
		const { language } = this.props;
		const {
			translationService,
			quizId,
			spinnerId,
			isInvalidStartDate,
			isInvalidEndDate,
			courseId,
		} = this.state;
		const form = { ...this.state.form };
		if (
			!form.name ||
			(parseInt(form.gracePeriodEntity) === 0 &&
				parseInt(form.gracePeriod) < 60) ||
			isInvalidStartDate ||
			isInvalidEndDate ||
			+form.maxgrade < +form.passGrade
		) {
			this.setState({ isInvalidSubmit: true });
			this.scrollToInvalidFieldPosition();
		} else {
			this.props.addButtonSpinner(spinnerId);
			const changeReviewOptions = [];
			form.reviewOptions.forEach(option => {
				if (option.optionValue) {
					changeReviewOptions.push({
						categoryId: option.categoryId,
						optionKey: option.optionKey,
					});
				}
			});
			form.entityId = form.entityId ? +form.entityId : null;
			form.entityType = +form.entityType;
			form.openDate = form.openDate ? new Date(form.openDate) : null;
			form.closeDate = form.closeDate ? new Date(form.closeDate) : null;
			form.attemptsAllowed = +form.attemptsAllowed;
			form.gradingMethod = +form.gradingMethod;
			form.passGrade = form.passGrade ? +form.passGrade : 0;
			form.maxgrade = form.maxgrade ? +form.maxgrade : 0;
			form.questionBehaviour = form.questionBehaviour
				? +form.questionBehaviour
				: 0;
			form.timeLimit = form.timeLimit ? +form.timeLimit : null;
			form.timeLimitEntity = +form.timeLimitEntity;
			form.whenTimeExpires = +form.whenTimeExpires;
			form.gracePeriodEnabled = form.whenTimeExpires === 2 ? true : false;
			form.gracePeriod = form.gracePeriodEnabled ? +form.gracePeriod : null;
			form.gracePeriodEntity = form.gracePeriodEnabled
				? +form.gracePeriodEntity
				: null;
			form.shuffleEnabled = +form.shuffleEnabled === 1 ? true : false;
			form.description = form.description ? form.description.trim() : "";
			form.name = form.name.trim();
			form.reviewOptions = changeReviewOptions;
			if (quizId) {
				form.id = +quizId;
			}
			(quizId ? ApiService.quizUpdate(form) : ApiService.quizCreate(form))
				.then(response => {
					if (response.data && response.data.id) {
						this.props.history.push(
							`/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_VIEW_KEY}/${response.data.id}`,
						);
					}
					// this.props.history.push(
					//   user.isOrganizationUser
					//     ? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${courseId}/curriculum`
					//     : `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${CURICULUMN_KEY}`
					// )
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate(
							quizId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY,
						),
					);
					this.props.removeButtonSpinner(spinnerId);
				})
				.catch(e => this.submitFail(spinnerId, e));
		}
	};

	getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(this.props.location.pathname)) {
			this.props.getCurrentQuestionMarkData(
				HelpService.setPageName(this.props.location.pathname),
				key,
			);
		}
	};

	submitFail = (spinnerId, errors) => {
		this.props.removeButtonSpinner(spinnerId);
		const failedFields = errors;
		this.setState({ failedFields });
		errors &&
			AlertService.alert(
				AlertService.checkMessageType(errors.respcode) || ERROR_KEY,
				errors,
			);
	};

	getFail = (spinnerId, error) => {
		this.props.removePageSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	goBack = () => {
		const { history, language, match, user } = this.props;
		history.push(
			user.isOrganizationUser
				? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/curriculum`
				: `/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${CURICULUMN_KEY}`,
		);
	};

	redirectToQuizView = quizId => {
		if (!quizId) {
			return;
		}
		const { history, language, match } = this.props;
		history.push(
			`/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${QUIZ_VIEW_KEY}/${quizId}`,
		);
	};

	render() {
		const { language, match, user } = this.props;
		const {
			translationService,
			spinnerId,
			quizId,
			failedFields,
			isInvalidSubmit,
			descriptionMaxLength,
			categories,
			isInvalidStartDate,
			isInvalidEndDate,
			nameMaxLength,
		} = this.state;
		const {
			name,
			displayDescription,
			openDateEnabled,
			closeDateEnabled,
			timeLimitEnabled,
			gracePeriodEnabled,
			openDate,
			closeDate,
			timeLimit,
			timeLimitEntity,
			description,
			gracePeriod,
			gracePeriodEntity,
			whenTimeExpires,
			gradingMethod,
			attemptsAllowed,
			shuffleEnabled,
			passGrade,
			maxgrade,
			questionBehaviour,
		} = this.state.form;

		return translationService ? (
			<div className="background content container">
				<div className="m-section">
					<h2 className="content-title p-0">
						<Link
							to={
								user.isOrganizationUser
									? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/curriculum`
									: `/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${CURICULUMN_KEY}`
							}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						{translationService.translate(
							quizId ? "TR_UPDATE_QUIZ" : "TR_CREATE_QUIZ",
						)}
					</h2>
					<hr />
					<div className="content-body p-0">
						<form onSubmit={this.onSubmit}>
							<div className="row">
								<div className="col-12 ">
									<div className="content-sub-title">
										<h3>{translationService.translate("TR_GENERAL")}</h3>
									</div>
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<Input
												type="text"
												id="name"
												name="name"
												inputClassName="pr--4"
												value={name}
												fieldLength={nameMaxLength}
												isInvalidSubmit={isInvalidSubmit}
												labelValue={`${translationService.translate(
													"TR_NAME",
												)} *`}
												onChange={event => this.onChange(event, nameMaxLength)}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<TextArea
												id="description"
												name="description"
												textAreaClassName="pr--5"
												min="0"
												rows="6"
												max={descriptionMaxLength}
												value={description}
												valueLength="input-lenght"
												labelValue={translationService.translate(
													"TR_DESCRIPTION",
												)}
												onChange={event =>
													this.onChange(event, descriptionMaxLength)
												}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<InputCheckBox
												id="displayDescription"
												name="displayDescription"
												checked={displayDescription}
												labelValue={translationService.translate(
													"TR_DISPLAY_DESCRIPTION_ON_COURSE_PAGE",
												)}
												onChange={this.onCheckboxChange}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6 ">
											<div>
												<label>
													{translationService.translate("TR_OPEN_QUIZ")}
												</label>
												<SmallQuestionMark
													id="open_the_quiz"
													onMouseEnter={() =>
														this.getQuestionMarkData("open_the_quiz")
													}
												/>
											</div>
											<Input
												type="datetime-local"
												id="openDate"
												name="openDate"
												value={openDate}
												inputClassName={
													isInvalidStartDate && openDateEnabled
														? "is-invalid error-bordered"
														: ""
												}
												disabled={!openDateEnabled}
												onChange={event =>
													openDateEnabled && this.onDateChange(event)
												}
												onBlur={() =>
													this.checkDateFormatAndIsValid(
														openDate,
														"openDate",
														"isInvalidStartDate",
													)
												}
												failedFields={failedFields}
											/>
											<InputCheckBox
												id="openDateEnabled"
												name="openDateEnabled"
												checked={openDateEnabled}
												labelValue={translationService.translate("TR_ENABLE")}
												onChange={event =>
													this.onTriggerCheckbox(event, "openDate")
												}
												failedFields={failedFields}
											/>
											{isInvalidStartDate && openDateEnabled ? (
												<small className="red-color">
													{translationService.translate("TR_INVALID_DATES")}
												</small>
											) : null}
										</div>
										<div className="col-12 col-lg-6">
											<div>
												<label>
													{translationService.translate("TR_CLOSE_QUIZ")}
												</label>
												<SmallQuestionMark
													id="close_the_quiz"
													onMouseEnter={() =>
														this.getQuestionMarkData("close_the_quiz")
													}
												/>
											</div>
											<Input
												id="closeDate"
												name="closeDate"
												type="datetime-local"
												value={closeDate}
												disabled={!closeDateEnabled}
												inputClassName={
													isInvalidEndDate && closeDateEnabled
														? "is-invalid error-bordered"
														: ""
												}
												onChange={event =>
													closeDateEnabled && this.onDateChange(event)
												}
												onBlur={() =>
													this.checkDateFormatAndIsValid(
														closeDate,
														"closeDate",
														"isInvalidEndDate",
													)
												}
												failedFields={failedFields}
											/>
											<InputCheckBox
												id="closeDateEnabled"
												name="closeDateEnabled"
												checked={closeDateEnabled}
												labelValue={translationService.translate("TR_ENABLE")}
												onChange={event =>
													this.onTriggerCheckbox(event, "closeDate")
												}
												failedFields={failedFields}
											/>
											{isInvalidEndDate && closeDateEnabled ? (
												<small className="red-color">
													{translationService.translate("TR_INVALID_DATES")}
												</small>
											) : null}
										</div>
										<div className="col-12"></div>
									</div>
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<div>
												<label>
													{translationService.translate("TR_TIME_LIMIT")}
												</label>
												<SmallQuestionMark
													id="time_limit"
													onMouseEnter={() =>
														this.getQuestionMarkData("time_limit")
													}
												/>
											</div>
											<Input
												type="number"
												id="timeLimit"
												name="timeLimit"
												min="0"
												value={timeLimit}
												disabled={!timeLimitEnabled}
												onChange={event =>
													timeLimitEnabled && this.onNumberChange(event)
												}
												failedFields={failedFields}
											/>
										</div>
										<div className="col-12 col-lg-6">
											<SelectOption
												name="timeLimitEntity"
												value={timeLimitEntity}
												selectClasses="label-less"
												items={TIME_TYPES_ARR_KEY}
												isDisabled={!timeLimitEnabled}
												changed={event =>
													timeLimitEnabled && this.onNumberChange(event)
												}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<InputCheckBox
												id="timeLimitEnabled"
												name="timeLimitEnabled"
												checked={timeLimitEnabled}
												labelValue={translationService.translate("TR_ENABLE")}
												onChange={event =>
													this.onTriggerCheckboxes(
														event,
														"timeLimit",
														"timeLimitEntity",
													)
												}
												failedFields={failedFields}
											/>
										</div>
									</div>
									{timeLimitEnabled && timeLimit ? (
										<div className="row">
											<div className="col-12">
												<SelectOption
													value={whenTimeExpires}
													name="whenTimeExpires"
													label={`${translationService.translate(
														"TR_WHEN_TIME_EXPIRES",
													)} *`}
													items={EXPIRE_TYPES_ARR_KEY}
													changed={this.onNumberChange}
													failedFields={failedFields}
												/>
											</div>
										</div>
									) : null}
									{gracePeriodEnabled ? (
										<div className="row">
											<div className="col-12">
												<hr />
											</div>
											<div className="col-12 col-lg-6">
												<Input
													type="number"
													id="gracePeriod"
													name="gracePeriod"
													min="1"
													value={gracePeriod}
													disabled={!gracePeriodEnabled}
													labelValue={translationService.translate(
														"TR_SUBMISSION_GRACE_PERIOD",
													)}
													placeholder={gracePeriod}
													onChange={event =>
														gracePeriodEnabled && this.onNumberChange(event)
													}
													failedFields={failedFields}
												/>
											</div>
											<div className="col-12 col-lg-6">
												<SelectOption
													name="gracePeriodEntity"
													value={gracePeriodEntity}
													isDisabled={!gracePeriodEnabled}
													label={translationService.translate(
														"TR_SUBMISSION_GRACE_PERIOD",
													)}
													items={TIME_TYPES_ARR_KEY}
													error={
														isInvalidSubmit &&
														+gracePeriodEntity === 0 &&
														+gracePeriod < 60
													}
													changed={event =>
														gracePeriodEnabled && this.onNumberChange(event)
													}
													failedFields={failedFields}
												/>
											</div>
										</div>
									) : null}
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="content-sub-title pt-0">
												<h3>{translationService.translate("TR_GRADE")}</h3>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<Input
												type="number"
												id="passGrade"
												name="passGrade"
												min="0"
												value={passGrade}
												inputClassName={
													maxgrade < passGrade && isInvalidSubmit
														? "is-invalid error-bordered"
														: ""
												}
												labelValue={`${translationService.translate(
													"TR_GRADE_TO_PASS",
												)}`}
												onChange={this.onNumberChange}
												failedFields={failedFields}
											/>
										</div>
										<div className="col-12 col-lg-6">
											<Input
												type="number"
												id="maxgrade"
												name="maxgrade"
												min="0"
												value={maxgrade}
												inputClassName={
													maxgrade < passGrade && isInvalidSubmit
														? "is-invalid error-bordered"
														: ""
												}
												labelValue={`${translationService.translate(
													"TR_MAX_GRADE",
												)}`}
												onChange={this.onNumberChange}
												failedFields={failedFields}
											/>
										</div>
										{maxgrade < passGrade && isInvalidSubmit ? (
											<div className="col-12">
												<small className="red-color">
													{translationService.translate("TR_MAX_GRADE_INFO")}
												</small>
											</div>
										) : null}
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<div>
												<label>
													{translationService.translate("TR_ATTEMPTS_ALLOWED")}{" "}
													*
												</label>
												<SmallQuestionMark
													id="attempts_allowed"
													onMouseEnter={() =>
														this.getQuestionMarkData("attempts_allowed")
													}
												/>
											</div>
											<SelectOption
												value={attemptsAllowed}
												name="attemptsAllowed"
												items={ALLOWED_ATTEMPTS_ARR_KEY}
												changed={this.onNumberChange}
												failedFields={failedFields}
											/>
										</div>
										<div className="col-12 col-lg-6">
											<SelectOption
												name="gradingMethod"
												value={gradingMethod}
												label={`${translationService.translate(
													"TR_GRADING_METHOD",
												)} *`}
												items={GRADING_METHODS_ARR_KEY}
												changed={this.onNumberChange}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="content-sub-title pt-0">
												<div className="d-flex align-items-center">
													<h3>
														{translationService.translate(
															"TR_QUESTION_BEHAVIOUR",
														)}
													</h3>
													<SmallQuestionMark
														id="question_behaviour"
														onMouseEnter={() =>
															this.getQuestionMarkData("question_behaviour")
														}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-lg-6">
											<SelectOption
												name="shuffleEnabled"
												value={shuffleEnabled}
												items={CONFIRM_ARRAY}
												label={translationService.translate(
													"TR_SHUFFLE_WITHIN_QUESTIONS",
												)}
												changed={this.onNumberChange}
												failedFields={failedFields}
											/>
										</div>
										<div className="col-12 col-lg-6">
											<SelectOption
												name="questionBehaviour"
												value={questionBehaviour}
												label={`${translationService.translate(
													"TR_QUESTION_BEHAVIOUR",
												)} *`}
												items={QUESTION_BEHAVIOUR}
												changed={this.onNumberChange}
												failedFields={failedFields}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<hr />
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="content-sub-title pt-0">
												<div className="d-flex align-items-center">
													<h3>
														{translationService.translate("TR_REVIEW_OPTIONS")}
													</h3>
													<SmallQuestionMark
														id="review_options"
														onMouseEnter={() =>
															this.getQuestionMarkData("review_options")
														}
													/>
												</div>
											</div>
										</div>
									</div>
									{categories && categories.length ? (
										<div className="row">
											{categories.map(category => (
												<div
													className="col-lg-3 col-md-3 col-sm-6 col-12 mt-2 m-md-0"
													key={category.id}>
													<div>
														<p className="title mb-1 h-25">
															{translationService.translate(category.name)
																? translationService.translate(category.name)
																: category.name}
														</p>
													</div>
													{category.categoryItems &&
														category.categoryItems.map(categoryItem => (
															<div key={categoryItem.id} className="d-block">
																<InputCheckBox
																	id={`${categoryItem.name}_${category.id}`}
																	name={`${categoryItem.name}_${category.id}`}
																	defaultChecked={categoryItem.optionValue}
																	labelValue={categoryItem.displayName}
																	labelClassName={
																		categoryItem.disabled
																			? "disabled-checkbox"
																			: ""
																	}
																	checkBoxClassName={
																		categoryItem.disabled
																			? "custom-control-input-disabled"
																			: ""
																	}
																	disabled={categoryItem.disabled}
																	onChange={event =>
																		this.changeCategoryItem(
																			event,
																			category.id,
																			categoryItem,
																			category,
																		)
																	}
																/>
															</div>
														))}
												</div>
											))}
										</div>
									) : null}
									<div className="row">
										<div className="col-12">
											{failedFields &&
											failedFields.hasOwnProperty("ReviewOptions") ? (
												<small className="small-error-msg">
													{failedFields.ReviewOptions}
												</small>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<SubmitButton
								id={quizId}
								spinnerId={spinnerId}
								className="mt-3"
							/>
						</form>
					</div>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user,
	currentQuestionMarkData: state.main.currentQuestionMarkData,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	getCurrentQuestionMarkData,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(QuizForm);
