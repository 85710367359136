import React from "react";
import UserRoutes from "../../Routing/userRoutes";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Header from "../LayoutSections/Header/header";
import Footer from "../LayoutSections/Footer/footer";
import { withRouter } from "react-router-dom";

const UserLayout = props => {
	return (
		<Auxiliary>
			<Header />
			<div className={`wrapper m-section additional-top-padding`}>
				<UserRoutes />
			</div>
			<Footer />
		</Auxiliary>
	);
};

export default withRouter(UserLayout);
