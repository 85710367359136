import React from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
// #32064A
const PartialViewSpinner = props => {
	const { settings } = useSelector(state => state.settings);

	return props.spinners?.length ? (
		<div className={props.customClasses ? props.customClasses : ""}>
			{props.spinners.map(spinner => (
				<div key={spinner} className="center page-loader">
					<Loader
						type="ThreeDots"
						color={
							settings && settings.mainColor ? settings.mainColor : "#32064A"
						}
						height={80}
						width={80}
					/>
				</div>
			))}
		</div>
	) : null;
};

export default PartialViewSpinner;
