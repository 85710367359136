import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import TranslationService from "../../Services/translationService";
import Input from "../../Components/Inputs/input";
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import AlertService from '../../Services/alertService';
import { SUCCESS_KEY } from '../../Constants/mainKeys';

const CoursePromotions = () => {
  const translations = useSelector(state => state.translation.translations);
  const currentCourse = useSelector(state => state.course.currentCourse);
  const [translationService, setTranslationService] = useState(null);
  const courseLink = currentCourse && currentCourse.promotionLink ? currentCourse.promotionLink : "";

  const copyLink = (link) => {
    if (!link) { return; }
    navigator.clipboard.writeText(link);
    AlertService.alert(SUCCESS_KEY, translationService.translate("TR_COPIED"));
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <Auxiliary>
    <div className="content-title d-block">
      <h2 className="section-title">{translationService.translate("TR_PROMOTIONS")}</h2>
    </div>
    <hr />
    <div className="content-body pt-0">
      <div className="row">
        <div className="col-12">
          <Input
            readOnly={true}
            type="text"
            id="course-link"
            name="course-link"
            value={courseLink}
          />
        </div>
      </div>
      <button
        type="button"
        className="mindalay--btn-default"
        onClick={() => copyLink(courseLink)}
      >
        {translationService.translate("TR_COPY_LINK")}
      </button>
    </div>
  </Auxiliary> : null;
}

export default withRouter(CoursePromotions);