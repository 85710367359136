import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import Collapse from "react-bootstrap/Collapse";
import TranslationService from "../../../Services/translationService";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import ImageSvg from "../../../Components/Svg/imageSvg";
import QuizSvg from "../../../Components/Svg/quizSvg";
import VideoSvg from "../../../Components/Svg/videoSvg";
import AudioSvg from "../../../Components/Svg/audioSvg";
import FileSvg from "../../../Components/Svg/fileSvg";
import CloseSvg from "../../../Components/Svg/closeSvg";
import {
	setPureLayoutBackUrl,
	setCuriculumBreadCrumb,
} from "../../../Store/Actions/main";
import { setCurrentCourse } from "../../../Store/Actions/course";
import {
	addTopicSpinner,
	removeTopicSpinner,
} from "../../../Store/Actions/spinner";
import {
	AUDIO_KEY,
	ERROR_KEY,
	FILE_KEY,
	IMAGE_KEY,
	VIDEO_KEY,
} from "../../../Constants/mainKeys";
import ApiService from "../../../Services/apiService";
import uuid from "react-uuid";
import AlertService from "../../../Services/alertService";
import TopicSpinner from "../../../Components/Spinners/topicSpinner";
import {
	MINDALAY_ADMIN,
	QUIZ_VIEW_KEY,
	TOPIC_KEY,
} from "../../../Constants/urlKeys";
import Parser from "html-react-parser";
import ParserService from "../../../Services/parserService";
import ParserComponent from "../../../Components/ParserComponent/ParserComponent";

class Topics extends Component {
	state = {
		sectionId: +this.props.sectionId,
		courseId: +this.props.match.params.courseId,
		// currentTopicId: this.props.curiculumBreadCrumb && this.props.curiculumBreadCrumb.currentTopicId,
		currentTopicId: null,
		topics: this.props.topics,
		quizzes: [],
		topicQuizzes: [],
		translationService: null,
		topicAttachedFiles: [],
		zoomImagePath: null,
		zoomAudioPath: null,
		zoomVideoPath: null,
	};

	componentDidMount() {
		this.setTranslations();
		window.addEventListener("keydown", this.zoomOut);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.zoomOut);
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	openTopicData = id => {
		if (id === this.state.currentTopicId) {
			this.setState({ currentTopicId: null }, () =>
				this.setCuriculumBreadCrumb(),
			);
		} else {
			this.setState({ currentTopicId: id }, () =>
				this.setCuriculumBreadCrumb(),
			);
			this.getCurrentTopicFiles(id);
			this.getCurrentTopicQuizzes(id);
		}
	};

	getCurrentTopicFiles = id => {
		const spinnerId = `file-${id}-${uuid()}`;
		this.props.addTopicSpinner(spinnerId);
		ApiService.getCurrentTopicFiles(id)
			.then(response => {
				this.props.removeTopicSpinner(spinnerId);
				if (response && response.data) {
					this.setState({ topicAttachedFiles: response.data });
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getCurrentTopicQuizzes = id => {
		const spinnerId = `file-${id}-${uuid()}`;
		this.props.addTopicSpinner(spinnerId);
		ApiService.getCurrentTopicQuizzes(id)
			.then(response => {
				this.props.removeTopicSpinner(spinnerId);
				if (response && response.data) {
					this.setState({ topicQuizzes: response.data });
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		this.setState({ zoomImagePath: imagePath });
	};

	zoomVideo = videoPath => {
		if (!videoPath) {
			return;
		}
		this.setState({ zoomVideoPath: videoPath });
	};

	zoomAudio = audioPath => {
		if (!audioPath) {
			return;
		}
		this.setState({ zoomAudioPath: audioPath });
	};

	zoomOut = event => {
		if (event.key === "Escape") {
			this.hideZoomImageVideo();
		}
	};

	hideZoomImageVideo = () => {
		this.setState({
			zoomImagePath: null,
			zoomVideoPath: null,
			zoomAudioPath: null,
		});
	};

	setCuriculumBreadCrumb = () => {
		const curiculumBreadCrumb = { ...this.props.curiculumBreadCrumb };
		curiculumBreadCrumb.currentTopicId = this.state.currentTopicId;
		this.props.setCuriculumBreadCrumb(curiculumBreadCrumb);
	};

	redirectToQuizViewPage = id => {
		const { language } = this.props;
		this.props.history.push(
			`/${language}/${MINDALAY_ADMIN}/${TOPIC_KEY}/${QUIZ_VIEW_KEY}/${id}`,
		);
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
	};

	render() {
		const {
			currentTopicId,
			topics,
			translationService,
			topicAttachedFiles,
			topicQuizzes,
			zoomImagePath,
			zoomVideoPath,
			zoomAudioPath,
		} = this.state;

		return (
			translationService &&
			topics && (
				<div className="card-body">
					{zoomImagePath ? (
						<div
							className="zoom-image-container"
							onClick={this.hideZoomImageVideo}>
							<div className="close-svg">
								<CloseSvg />
							</div>
							<img
								src={zoomImagePath}
								alt="/"
								onClick={event => event.stopPropagation()}
							/>
						</div>
					) : null}
					{zoomVideoPath ? (
						<div
							className="zoom-image-container"
							onClick={this.hideZoomImageVideo}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<video
									controls={true}
									onClick={event => event.stopPropagation()}>
									<source src={zoomVideoPath} />
								</video>
							</div>
						</div>
					) : null}
					{zoomAudioPath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<audio src={zoomAudioPath} controls />
							</div>
						</div>
					) : null}
					{topics.map((topic, topicIndex) => (
						<div
							key={topic.id}
							className="card mindalay-card transparent-background">
							<div
								className="card-header overflow-hidden"
								aria-controls={`topic_${topic.id}`}
								aria-expanded={currentTopicId === topic.id}
								onClick={() => this.openTopicData(topic.id)}>
								<h5 className="mb-0 text-elipsis">
									<span>{/* {topicIndex + 1}. */}</span>•&nbsp;&nbsp;
									{topic.name}
								</h5>
								{/* <p>{topic.description}</p> */}
								{topic.description ? (
									<span className="topic-description-block">
										<ParserComponent text={topic.description} />
									</span>
								) : null}
								{this.props.topicSpinners && this.props.topicSpinners.length
									? this.props.topicSpinners.map(spinnerid => {
											if (spinnerid.includes(topic.id)) {
												return (
													<TopicSpinner
														key={spinnerid}
														spinners={this.props.topicSpinners}
													/>
												);
											} else {
												return false;
											}
									  })
									: null}
							</div>
							{!this.props.topicSpinners.length ? (
								<Collapse in={currentTopicId === topic.id}>
									<div
										id={`topic_${topic.id}`}
										className="light-blue-background content-block">
										<div className="card-body ">
											<div className="section-media-wrapper">
												<div className="row mt-3">
													{topic.imagePath ? (
														<div className="col-md-6 col-12">
															<div
																className="topic-image"
																style={{
																	backgroundImage: `url(${topic.imagePath})`,
																}}></div>
														</div>
													) : null}
													{topic.presentationFilePath ? (
														<div className="col-md-6 col-12">
															<video
																src={topic.presentationFilePath}
																controls></video>
														</div>
													) : null}
												</div>
												{(topicAttachedFiles && topicAttachedFiles.length) ||
												(topicQuizzes && topicQuizzes.length) ? (
													<Auxiliary>
														<div className="section-content-wrapper">
															{topicAttachedFiles &&
															topicAttachedFiles.length ? (
																<Auxiliary>
																	<hr />
																	<div className="content-sub-title">
																		<h3>
																			{translationService.translate(
																				"TR_RESOURCES",
																			)}
																		</h3>
																	</div>
																</Auxiliary>
															) : null}
															{topicAttachedFiles.map(topicAttachedFile => {
																let fileType = "";
																if (
																	topicAttachedFile.mimeType &&
																	topicAttachedFile.mimeType.includes(IMAGE_KEY)
																) {
																	fileType = IMAGE_KEY;
																} else if (
																	topicAttachedFile.mimeType &&
																	topicAttachedFile.mimeType.includes(VIDEO_KEY)
																) {
																	fileType = VIDEO_KEY;
																} else if (
																	topicAttachedFile.mimeType &&
																	topicAttachedFile.mimeType.includes(AUDIO_KEY)
																) {
																	fileType = AUDIO_KEY;
																} else if (
																	topicAttachedFile.mimeType &&
																	(topicAttachedFile.mimeType.includes("pdf") || //pdf
																		topicAttachedFile.mimeType.includes(
																			"text",
																		) || //txt
																		topicAttachedFile.mimeType.includes(
																			"application",
																		))
																) {
																	fileType = FILE_KEY;
																}
																return (
																	<div
																		key={topicAttachedFile.id}
																		className="section-content">
																		{(() => {
																			switch (fileType) {
																				case IMAGE_KEY:
																					return (
																						<ImageSvg
																							onClick={() =>
																								this.zoomImage(
																									topicAttachedFile.filePath,
																								)
																							}
																						/>
																					);
																				case VIDEO_KEY:
																					return (
																						<VideoSvg
																							onClick={() =>
																								this.zoomVideo(
																									topicAttachedFile.filePath,
																								)
																							}
																						/>
																					);
																				case AUDIO_KEY:
																					return (
																						<AudioSvg
																							onClick={() =>
																								this.zoomAudio(
																									topicAttachedFile.filePath,
																								)
																							}
																						/>
																					);
																				case FILE_KEY:
																					return <FileSvg />;
																				default:
																					break;
																			}
																		})()}
																		{topicAttachedFile.mimeType.includes(
																			IMAGE_KEY,
																		) ? (
																			<p
																				className="text-elipsis cursor-pointer"
																				onClick={() =>
																					this.zoomImage(
																						topicAttachedFile.filePath,
																					)
																				}>
																				{topicAttachedFile.nameAsResource}
																			</p>
																		) : null}
																		{topicAttachedFile.mimeType.includes(
																			VIDEO_KEY,
																		) ? (
																			<p
																				className="text-elipsis cursor-pointer"
																				onClick={() =>
																					this.zoomVideo(
																						topicAttachedFile.filePath,
																					)
																				}>
																				{topicAttachedFile.nameAsResource}
																			</p>
																		) : null}
																		{topicAttachedFile.mimeType.includes(
																			"pdf",
																		) || //pdf
																		topicAttachedFile.mimeType.includes(
																			"text",
																		) || //txt
																		topicAttachedFile.mimeType.includes(
																			"application",
																		) ? (
																			<a
																				target="blank"
																				href={topicAttachedFile.filePath}
																				className="file-item text-secondary">
																				{topicAttachedFile.nameAsResource}
																			</a>
																		) : null}
																	</div>
																);
															})}
															{topicQuizzes.length ? (
																<Auxiliary>
																	<hr />
																	<div className="content-sub-title">
																		<h3>
																			{translationService.translate(
																				"TR_QUIZZES",
																			)}
																		</h3>
																	</div>
																	{topicQuizzes.map(topicQuiz => {
																		return (
																			<div
																				key={topicQuiz.id}
																				className="section-content">
																				<QuizSvg />
																				<Link
																					to="#"
																					className="text-elipsis full-width"
																					onClick={() =>
																						this.redirectToQuizViewPage(
																							topicQuiz.id,
																						)
																					}>
																					<p>{topicQuiz.name}</p>
																				</Link>
																			</div>
																		);
																	})}
																</Auxiliary>
															) : null}
														</div>
													</Auxiliary>
												) : null}
											</div>
										</div>
									</div>
								</Collapse>
							) : null}
						</div>
					))}
				</div>
			)
		);
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	language: state.language.language,
	translations: state.translation.translations,
	curiculumBreadCrumb: state.main.curiculumBreadCrumb,
	topicSpinners: state.spinner.topicSpinners,
});

const mapDispatchToProps = {
	setCurrentCourse,
	setPureLayoutBackUrl,
	setCuriculumBreadCrumb,
	addTopicSpinner,
	removeTopicSpinner,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(Topics);
