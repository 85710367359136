import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import ActionButton from "../../Components/ActionButton/actionButton";
import Input from "../../Components/Inputs/input";
import TextArea from "../../Components/Inputs/textArea";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import AddFromLibraryModal from "./Modals/addFromLibraryModal";
import AddFileLibraryModal from "./Modals/addFileLibraryModal";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import FileSvg from "../../Components/Svg/fileSvg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { TR_CREATE_MESSAGE_KEY } from "../../Constants/translationKeys";
import { COURSE_DASHBOARD_KEY, CURICULUMN_KEY } from "../../Constants/urlKeys";
import {
	AUDIO_KEY,
	BOOLEAN_KEY,
	ERROR_KEY,
	FILE_KEY,
	IMAGE_KEY,
	SUCCESS_KEY,
	TR_SUBMIT_KEY,
	VIDEO_KEY,
} from "../../Constants/mainKeys";
import { ORGANIZATION_KEY } from "../../Constants/requestKeys";

class TopicMediaForm extends Component {
	fileType = new URLSearchParams(this.props.location.search).get("type");

	state = {
		form: {
			courseTopicId: +this.props.match.params.topicId,
			name: "",
			description: "",
			fileLibraryId: null,
			create: true,
			displayDescription: false,
		},
		mediaId: +this.props.match.params.mediaId,
		showSelectFromFileLibraryModal: false,
		showAddNewFileLibraryModal: false,
		selectedFileLibrary: null,
		filePath: null,
		spinnerId: uuid(),
		descriptionMaxLength: 2000,
		nameMaxLength: 200,
		isInvalidSubmit: false,
		translationService: null,
		categories: [],
		failedFields: [],
		nameIsEmpty: false,
	};

	componentDidMount() {
		this.setTranslations();
		this.state.mediaId && this.getAttachedFileToTopicById(this.state.mediaId);
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getAttachedFileToTopicById = mediaId => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getAttachFileToTopicById(mediaId).then(response => {
			const mediaData = response.data;
			this.setState(prevState => ({
				...prevState,
				filePath: mediaData.filePath,
				form: {
					...prevState.form,
					name: mediaData.nameAsResource || "",
					description: mediaData.description || "",
					displayDescription: mediaData.displayDescription || false,
					fileLibraryId: mediaData.id,
				},
			}));
			this.props.removePageSpinner(spinnerId);
		});
	};

	onChange = (event, maxLength = null) => {
		const failedFields = this.removeFailedFields(event.target.name);
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			failedFields,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
			},
		}));
	};

	attachFileLibrary = file => {
		file &&
			this.setState(prevState => ({
				...prevState,
				showAddNewFileLibraryModal: false,
				showSelectFromFileLibraryModal: false,
				selectedFileLibrary: file,
				form: {
					...prevState.form,
					fileLibraryId: file.id,
				},
			}));
	};

	onCheckboxChange = event => {
		const failedFields = this.removeFailedFields(event.target.name);
		typeof event.target.checked === BOOLEAN_KEY &&
			this.setState(prevState => ({
				...prevState,
				failedFields,
				form: {
					...prevState.form,
					[event.target.name]: event.target.checked,
				},
			}));
	};

	onSubmit = event => {
		event.preventDefault();
		const { user } = this.props;
		const { translationService, spinnerId, mediaId } = this.state;
		const form = { ...this.state.form };
		form.name = form.name ? form.name.trim() : "";
		form.description = form.description ? form.description.trim() : "";
		if (!form.name || !form.fileLibraryId) {
			this.setState({ isInvalidSubmit: true });
		} else {
			form[this.fileType] = true;
			if (mediaId) {
				form.id = mediaId;
			}
			this.props.addButtonSpinner(spinnerId);
			if (user.isOrganizationUser) {
				(mediaId
					? ApiService.updateAttachedFileToTopicOrg(form)
					: ApiService.attachFileToTopicOrg(form)
				)
					.then(() => {
						this.props.removeButtonSpinner(spinnerId);
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_CREATE_MESSAGE_KEY),
						);
						this.goBack();
					})
					.catch(e => this.submitFail(e, spinnerId));
			} else {
				(mediaId
					? ApiService.updateAttachedFileToTopic(form)
					: ApiService.attachFileToTopic(form)
				)
					.then(() => {
						this.props.removeButtonSpinner(spinnerId);
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_CREATE_MESSAGE_KEY),
						);
						this.goBack();
					})
					.catch(e => this.submitFail(e, spinnerId));
			}
		}
	};

	closeModal = () => {
		this.setState({
			showAddNewFileLibraryModal: false,
			showSelectFromFileLibraryModal: false,
		});
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	submitFail = (message, spinnerId) => {
		message &&
			AlertService.alert(
				AlertService.checkMessageType(message.respcode) || ERROR_KEY,
				message,
			);
		this.props.removeButtonSpinner(spinnerId);
	};

	isShowAddNewFileLibraryModal = () => {
		this.setState({ showAddNewFileLibraryModal: true });
	};

	goBack = () => {
		const { language, history, match, user } = this.props;
		history.push(
			user.isOrganizationUser
				? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/curriculum`
				: `/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${CURICULUMN_KEY}`,
		);
	};
	render() {
		const { match, language, user } = this.props;
		const { name, description, displayDescription, fileLibraryId } =
			this.state.form;
		const {
			spinnerId,
			descriptionMaxLength,
			isInvalidSubmit,
			translationService,
			failedFields,
			showSelectFromFileLibraryModal,
			showAddNewFileLibraryModal,
			selectedFileLibrary,
			filePath,
			nameIsEmpty,
			nameMaxLength,
			mediaId,
		} = this.state;

		return translationService ? (
			<div className="background content container">
				<div className="m-section">
					<h2 className="content-title p-0">
						<Link
							to={
								user.isOrganizationUser
									? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/curriculum`
									: `/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${CURICULUMN_KEY}`
							}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						{translationService.translate("TR_ADD_MEDIA")}
					</h2>
					<hr />
					<div className="content-body p-0">
						<form onSubmit={this.onSubmit}>
							<div className="row">
								<div className="col-12">
									<div className="content-sub-title">
										<h3>{translationService.translate("TR_GENERAL")}</h3>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<Input
										type="text"
										id="name"
										name="name"
										inputClassName="pr--5"
										value={name}
										fieldLength={nameMaxLength}
										isInvalidSubmit={isInvalidSubmit || nameIsEmpty}
										labelValue={`${translationService.translate("TR_NAME")} *`}
										onChange={event => this.onChange(event, nameMaxLength)}
										failedFields={failedFields}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<TextArea
										id="description"
										name="description"
										rows="6"
										min="0"
										textAreaClassName="pr--5"
										max={descriptionMaxLength}
										value={description}
										labelValue={translationService.translate("TR_DESCRIPTION")}
										onChange={event =>
											this.onChange(event, descriptionMaxLength)
										}
										failedFields={failedFields}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<InputCheckBox
										id="displayDescription"
										name="displayDescription"
										checked={displayDescription}
										labelValue={translationService.translate(
											"TR_DISPLAY_DESCRIPTION_ON_COURSE_PAGE",
										)}
										onChange={this.onCheckboxChange}
										failedFields={failedFields}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<hr />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="content-sub-title">
										<h3>{translationService.translate("TR_FILES")} *</h3>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-12">
									<button
										type="button"
										className={`mindalay--btn-default w-100 mb-2 ${
											isInvalidSubmit && !fileLibraryId
												? "is-invalid error-bordered"
												: ""
										}`}
										onClick={() =>
											this.setState({ showSelectFromFileLibraryModal: true })
										}>
										{translationService.translate("TR_ADD_FROM_LIBRARY")}
									</button>
									{showSelectFromFileLibraryModal ? (
										<AddFromLibraryModal
											fileType={this.fileType}
											cancel={this.closeModal}
											added={this.attachFileLibrary}
											isShowAddNewFileLibraryModal={
												this.isShowAddNewFileLibraryModal
											}
										/>
									) : null}
								</div>
								<div className="col-lg-6 col-md-6 col-12">
									<button
										type="button"
										className={`mindalay--btn-default w-100 mb-2 ${
											isInvalidSubmit && !fileLibraryId
												? "is-invalid error-bordered"
												: ""
										}`}
										// onClick={() => this.setState({ showAddNewFileLibraryModal: true })}
										onClick={() => {
											!name
												? this.setState({ nameIsEmpty: true })
												: this.setState({ showAddNewFileLibraryModal: true });
										}}>
										{translationService.translate("TR_ADD_NEW_FILE")}
									</button>
									{showAddNewFileLibraryModal ? (
										<AddFileLibraryModal
											fileType={this.fileType}
											cancel={this.closeModal}
											added={this.attachFileLibrary}
											name={name}
										/>
									) : null}
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-12 mt-3">
									{(selectedFileLibrary || filePath) &&
									this.fileType === IMAGE_KEY ? (
										<div
											className="upload"
											style={{
												backgroundImage: `url(${
													(selectedFileLibrary &&
														selectedFileLibrary.filePath) ||
													filePath
												})`,
											}}></div>
									) : null}
									{(selectedFileLibrary || filePath) &&
									this.fileType === VIDEO_KEY ? (
										<video
											src={
												(selectedFileLibrary && selectedFileLibrary.filePath) ||
												filePath
											}
											controls={true}
											className="upload"></video>
									) : null}
									{(selectedFileLibrary || filePath) &&
									this.fileType === AUDIO_KEY ? (
										<audio
											src={
												(selectedFileLibrary && selectedFileLibrary.filePath) ||
												filePath
											}
											controls
										/>
									) : null}
									{(selectedFileLibrary || filePath) &&
									this.fileType === FILE_KEY ? (
										<Auxiliary>
											<div className="file-icon-wrapper">
												<FileSvg />
											</div>
											<p className="text-center mt-2">
												{selectedFileLibrary?.name}
											</p>
										</Auxiliary>
									) : null}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<hr />
									<ActionButton
										type="submit"
										spinnerId={spinnerId}
										className="mindalay--btn-default w-30 position-relative"
										name={
											mediaId
												? translationService.translate("TR_UPDATE")
												: translationService.translate("TR_SAVE")
										}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(TopicMediaForm);
