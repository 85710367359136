import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../Services/translationService";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import ArrowBackSvg from "./../Components/Svg/arrowBackSvg";
import MainService from "../Services/mainService";
import RolesService from "../Services/rolesService";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import { ERROR_KEY } from "../Constants/mainKeys";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import TemplateDocumentForm from "./component/TemplateDocumentForm";

function DocumentForm(props) {
	const { documentId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user, role } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [roleService, setRoleService] = useState(null);
	const [documentCategory, setDocumentCategory] = useState([]);
	const [documentCategoryId, setDocumentCategoryId] = useState(null);
	const [documentTemplate, setDocumentTemplate] = useState([]);
	const [templateData, setTemplateData] = useState(null);

	const [documentTemplateId, setDocumentTemplateId] = useState(null);
	const [divisions, setDivisions] = useState([]);

	useEffect(() => {
		if (documentId) {
		}
	}, [documentId]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		getDocumentCategory();
		getAllStudentDivisions();
	}, []);

	const getAllStudentDivisions = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAllStudentDivisions()
			.then(response => {
				if (response && response.data) {
					setDivisions(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getDocumentCategory = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentCategory()
			.then(response => {
				if (response && response.data) {
					setDocumentCategory(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getDocumentTemplate = id => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentTemplatesByCategoryId(id)
			.then(response => {
				if (response && response.data) {
					setDocumentTemplate(response.data);
					setDocumentCategoryId(id);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onSelectOptionChangeDocumentCategory = item => {
		if (!item) return false;
		getDocumentTemplate(item.id);
	};
	const onSelectOptionChangeDocumentTemplate = item => {
		if (!item) return false;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentTemplateInfoDataInTemplateId(item.id)
			.then(response => {
				if (response && response.data) {
					setTemplateData(response.data);
					setDocumentCategoryId(item.id);
					const documentData = JSON.parse(item.body);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const setTemplateInfoData = () => {};

	const onSubmit = () => {};

	return translationService ? (
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<Link
								to={`/${language}/document`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{!documentId
									? translationService.translate("TR_CREATE_DOCUMENT")
									: translationService.translate("TR_UPDATE_DOCUMENT")}
							</h2>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<form onSubmit={onSubmit}>
				<div className="row">
					{documentCategory && documentCategory.length ? (
						<div className="col-md-6 col-12 my-1">
							<label>Choos document</label>
							<ReactSelectOption
								value={documentCategoryId}
								isInvalidSubmit={isInvalidSubmit && !documentCategoryId}
								// isDisabled={(divisionId, documentCategoryId)}
								selectedValue={(() => {
									const selectedItem = {
										...documentCategory.find(
											data => data.id === documentCategoryId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentCategory.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item => onSelectOptionChangeDocumentCategory(item)}
							/>
						</div>
					) : null}
					{documentTemplate && documentTemplate.length ? (
						<div className="col-md-6 col-12 my-1">
							<label>Choos template</label>
							<ReactSelectOption
								value={documentTemplateId}
								isInvalidSubmit={isInvalidSubmit && !documentTemplateId}
								// isDisabled={divisionId}
								selectedValue={(() => {
									const selectedItem = {
										...documentCategory.find(
											data => data.id === documentTemplateId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentTemplate.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item => onSelectOptionChangeDocumentTemplate(item)}
							/>
						</div>
					) : null}
					<hr />
					<TemplateDocumentForm templateData={templateData} />
				</div>
			</form>
		</div>
	) : null;
}

export default withRouter(DocumentForm);
