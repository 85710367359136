import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import ArrowBackSvg from '../../../Components/Svg/arrowBackSvg';
import AlertService from '../../../Services/alertService';
import ApiService from '../../../Services/apiService';
import * as moment from "moment";
import TranslationService from '../../../Services/translationService';
import { addPageSpinner, removePageSpinner } from './../../../Store/Actions/spinner';
import MainService from '../../../Services/mainService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { ENTITY_TYPE, ERROR_KEY, NUMBER_KEY, TICKET_CHAT_TYPE_KEY } from '../../../Constants/mainKeys';
import MessageSend from '../../../Components/Svg/messageSend';
import { TICKETS_KEY } from '../../../Constants/urlKeys';

const Chat = (props) => {

  const entityType = [...ENTITY_TYPE];
  const ticketChatType = TICKET_CHAT_TYPE_KEY;
  const ticketId = parseInt(props.match.params.ticketId);
  const messageMaxLength = 2000;

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);

  const [translationService, setTranslationService] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [messages, setMessages] = useState([]);
  var [message, setMessage] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getTicketData(ticketId);
  }, [props.match.params.ticketId]);

  const getTicketData = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getTicketByIdFromStudent(ticketId).then(response => {
      response.data && setTicketData(response.data);
      dispatch(removePageSpinner(spinnerId));
      getTicketChatFromStudent(ticketChatType, ticketId);
    }).catch(error => getFail(error, spinnerId));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      ticketId && getTicketChatFromStudent(ticketChatType, ticketId);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const getTicketChatFromStudent = (ticketChatType, ticketId) => {
    ApiService.getTicketChatFromStudent(ticketChatType, ticketId).then(response => {
      response.data && setMessages(response.data.chat);
      chatScrolToBottom();
    }).catch(error => getFail(error));
  }

  const onMessageChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setMessage(event.target.value);
    setIsInvalidSubmit(false);
  }

  const sendMessage = (event) => {
    event && event.preventDefault();
    if (!message || !message.trim()) {
      setIsInvalidSubmit(true);
    } else {
      message = message.trim();
      var data = {
        senderId: user.id,
        message,
        entityId: ticketId,
        chatType: ticketChatType
      }
      ApiService.sendMessageFromStudent(data).then(response => {
        response && getTicketChatFromStudent(ticketChatType, ticketId);
        setMessage("");
      }).catch(error => getFail(error));
    }
  }

  const chatScrolToBottom = () => {
    var element = document.getElementById("mindalay--consultation-chat");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
    goBack();
  }

  const goBack = () => {
    window.history.back();
  }

  return translationService ? <div className="container">
    <div className="row">
      <div className="col-12 content-title">
        <div className="d-flex align-items-center">
          <Link to={`/${language}/${TICKETS_KEY}`} title={translationService.translate("TR_BACK")} onClick={goBack}><ArrowBackSvg /></Link>
          <h2 className="content-title p-0">
            {translationService.translate("TR_TICKET")}</h2>
        </div>
      </div>
    </div>
    <hr />
    {
      ticketData ?
        <div className="col-12">
          {
            ticketData.ticketNumber ?
              <span className="d-block my-1"><b>{`${translationService.translate("TR_TICKET_NUMBER")}: `}</b><span>{ticketData.ticketNumber}</span></span>
              : null
          }
          {
            ticketData.description ?
              <span className="d-block my-1 word-wrap-break-word"><b>{`${translationService.translate("TR_DESCRIPTION")}: `}</b><span>{ticketData.description}</span></span>
              : null
          }
          {
            ticketData.subject ?
              <span className="d-block my-1"><b>{`${translationService.translate("TR_SUBJECT")}: `}</b><span>{ticketData.subject}</span></span>
              : null
          }
          {
            typeof ticketData.entityType === NUMBER_KEY ?
              entityType.map((type, index) => {
                if (type.value === ticketData.entityType) {
                  return <span key={index} className="d-block my-1">
                    <b>{`${translationService.translate("TR_TYPE")}: `}</b>
                    <span>{translationService.translate(type.label)}</span>
                  </span>
                } else { return false; }
              })
              : null
          }
          {
            ticketData.createDate ?
              <span className="d-block my-1">
                <b>{`${translationService.translate("TR_CREATED")}: `}</b>
                <span>{moment(MainService.convertUTCDateToLocalDate(new Date(ticketData.createDate))).format("lll")}</span>
              </span>
              : null
          }
          <hr />
          <Auxiliary>
            <h2 className="section-title">{translationService.translate("TR_CHAT")}</h2>
            <div className="admin-message-container my-4">
              <div className="mindalay--consultation-tab">
                <hr className="d-md-none" />
                <div className="tab-pane " id="consultation-contract--messages-tab" aria-labelledby="consultation-contract--messages-tab">
                  <strong className="consultation-chat-username">{translationService.translate("TR_SUPPORT")}</strong>
                  <div className="consultation-chat-content-wrapper">
                    <div id="mindalay--consultation-chat" className="consultation-chat-message-container">
                      {
                        messages && messages.length ?
                          messages.map((mess, index) => {
                            return mess.receiver ?
                              <div key={index} className="consultation-chat-message-wrapper consultation-chat-currnet-user">
                                <div className="consultation-chat-message">
                                  <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                                  <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                                </div>
                              </div>
                              :
                              <div key={index} className="consultation-chat-message-wrapper consultant-chat-sender">
                                <div className="consultation-chat-sender-wrapper">
                                </div>
                                <div className="consultation-chat-message">
                                  <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                                  <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                                </div>
                              </div>
                          })
                          : null
                      }
                    </div>
                    {
                      !ticketData.processed ?
                        <form onSubmit={sendMessage}>
                          <div className="consultation-chat-input-wrapper">
                            <div className="consultation-chat-send-button bg-transparent" id="send-message-button" onClick={sendMessage}>
                              <button className="message-send-button" title={translationService.translate("TR_SEND")}>
                                <MessageSend />
                              </button>
                            </div>
                            <input
                              type="text"
                              className={`form-control pr--5 ${isInvalidSubmit && (!message || !message.trim()) ? "is-invalid error-bordered" : ""}`}
                              value={message}
                              autoComplete="off"
                              name="message"
                              placeholder={translationService.translate("TR_TYPE_TEXT")}
                              onChange={(event) => onMessageChange(event, messageMaxLength)}
                            />
                          </div>
                        </form>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </Auxiliary>
        </div>
        : null
    }
  </div> : null

}

export default withRouter(Chat);
