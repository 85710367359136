import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	BACK_URL_KEY,
	ERROR_KEY,
	LANGUAGE_KEY,
	TOKEN_KEY,
} from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import { Link, withRouter } from "react-router-dom";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import MainService from "../../Services/mainService";
import moment from "moment";
import Fiels from "./Components/files";
import NoDataImage from "./../../assets/images/illustrations/nodata.svg";
import PosterImage from "./../../assets/images/video-poster.png";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import axios from "axios";

const WebinarCalendarInfo = props => {
	// streamOn-2, streamOff-3 screenShareOn-4 screenShareOff-5
	const { webinarCalendarId } = props.match.params;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const [translationService, setTranslationService] = useState(null);
	const [files, setFiles] = useState([]);
	const [messages, setMessages] = useState([]);
	const [webinarName, setWebinarName] = useState("");
	const [ongoingVideoId, setOngoingVideoId] = useState(null);

	const [lecturerVideo, setLecturerVideo] = useState(null);
	const [studentVideos, setStudentVideos] = useState([]);
	const [screenShareVideos, setScreenShareVideos] = useState([]);
	const [webinarVideoLogs, setWebinarVideoLogs] = useState([
		// todo
	]);

	useEffect(() => {
		// getWebinarVideoLogs();
		getWebinarData(webinarCalendarId);
		getWebinarVideos(webinarCalendarId);
		return () => {
			localStorage.removeItem(BACK_URL_KEY);
		};
	}, [webinarCalendarId, user]);

	const getWebinarData = webinarCalendarId => {
		if (!webinarCalendarId) {
			return;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getWebinarData(+webinarCalendarId)
			.then(response => {
				if (response && response.data && response.data.fileResponseModels) {
					setFiles(response.data.fileResponseModels);
				}
				if (response && response.data && response.data.chatResponseModels) {
					setMessages(response.data.chatResponseModels);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getWebinarVideos = webinarCalendarId => {
		if (!webinarCalendarId || !user.serverName) {
			return;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getWebinarVideos(user.serverName, +webinarCalendarId, user.id)
			.then(response => {
				const data = [...response.data];

				var studentVideos = [];
				var screenShareVideos = [];
				if (data && data.length) {
					data.forEach(video => {
						if (
							video.isLecturerVideo &&
							video.isCurrentUserVideo &&
							!video.isLecturerScreenShare &&
							!video.isCurrentUserScreenShare
						) {
							setLecturerVideo(video);
						}
						if (
							video.isLecturerVideo &&
							!video.isCurrentUserVideo &&
							!video.isLecturerScreenShare &&
							!video.isCurrentUserScreenShare
						) {
							setLecturerVideo(video);
						}
						if (!video.isLecturerVideo && !video.isScreenShare) {
							studentVideos.push(video);
						}
						if (video.isScreenShare) {
							screenShareVideos.push(video);
						}
					});
					setStudentVideos(studentVideos);
					setScreenShareVideos(screenShareVideos);
				}

				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	// const getWebinarVideoLogs = () => {
	//   if (!webinarCalendarId) { return false; }
	//   try {
	//     axios.get(
	//       `http://localhost:4000/log/get/${webinarCalendarId}`,
	//       {
	//         headers: {
	//           'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
	//           'language': localStorage.getItem(LANGUAGE_KEY)
	//         },
	//       }
	//     ).then(response=>{
	//       console.log(response);
	//     })
	//   } catch (error) {
	//     console.log(error);
	//   }
	// }

	function videoPlay(vid) {
		if (!vid) {
			return false;
		}
		vid.play();
	}

	function videoPause(vid) {
		if (!vid) {
			return false;
		}
		vid.pause();
	}

	const playAllVideos = () => {
		if (!webinarVideoLogs.length) {
			return false;
		}
		let sortedWebinarVideoLogs = webinarVideoLogs.sort((a, b) => {
			return +a.time - +b.time;
		});
		let startTime = sortedWebinarVideoLogs[0].time;
		sortedWebinarVideoLogs.forEach((log, index) => {
			if (index > 0) {
				log.time = log.time - startTime;
			}
		});
		sortedWebinarVideoLogs[0].time = 1;

		sortedWebinarVideoLogs.forEach(log => {
			if (log.eventType == 2 || log.eventType == 3) {
				// video
				let video = document.getElementById(`video_${log.userId}`);
				if (video) {
					setTimeout(() => {
						if (log.eventType == 2) {
							videoPlay(video);
						}
						if (log.eventType == 3) {
							videoPause(video);
						}
					}, log.time);
				}
			}

			if (log.eventType == 4 || log.eventType == 5) {
				// screen share
				let screenShare = document.getElementById(`screen_share_${log.userId}`);
				if (screenShare) {
					setTimeout(() => {
						if (log.eventType == 4) {
							videoPlay(screenShare);
						}
						if (log.eventType == 5) {
							videoPause(screenShare);
						}
					}, log.time);
				}
			}
		});
	};

	const getFail = (error, spinnerId) => {
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const goBack = () => {
		if (localStorage.getItem(BACK_URL_KEY)) {
			props.history.push(localStorage.getItem(BACK_URL_KEY));
		} else {
			props.history.push(`/${language}`);
		}
		localStorage.removeItem(BACK_URL_KEY);
	};

	return translationService ? (
		<div className="container">
			<div className="row">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center justify-content-between w-100">
						<div className="d-flex align-items-center">
							<Link
								to="#"
								onClick={goBack}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="content-title p-0">{webinarName}</h2>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_VIDEOS")}
					</h2>
				</div>
				<div className="col-12">
					<div className="row">
						{lecturerVideo ? (
							<Auxiliary>
								<div className="col-12 col-sm-10 col-md-8 col-lg-4 p-1">
									<video
										id={`video_${lecturerVideo.userId}`}
										controls
										poster={PosterImage}
										autoPlay={true}
										className="w-100 cursor-pointer"
										onPointerDown={() =>
											setOngoingVideoId(lecturerVideo.userId)
										}>
										{ongoingVideoId === lecturerVideo.userId ? (
											<source src={lecturerVideo.rellPath} type="video/mp4" />
										) : null}
									</video>
								</div>
								<div className="col-12">
									<hr />
								</div>
							</Auxiliary>
						) : null}
						{studentVideos && studentVideos.length ? (
							<Auxiliary>
								{studentVideos.map((video, index) => {
									return (
										<div
											key={index}
											className="col-6 col-sm-4 col-md-4 col-lg-2 p-1">
											<video
												id={`video_${video.userId}`}
												controls
												poster={PosterImage}
												autoPlay={true}
												className="w-100 cursor-pointer"
												onPointerDown={() => setOngoingVideoId(video.userId)}>
												{ongoingVideoId === video.userId ? (
													<source src={video.rellPath} type="video/mp4" />
												) : null}
											</video>
										</div>
									);
								})}
								<div className="col-12">
									<hr />
								</div>
							</Auxiliary>
						) : null}

						{screenShareVideos && screenShareVideos.length ? (
							<Auxiliary>
								{screenShareVideos.map((video, index) => {
									return (
										<div
											key={index}
											className="col-6 col-sm-4 col-md-4 col-lg-2 p-1">
											<video
												id={`screen_share_${video.userId}`}
												controls
												poster={PosterImage}
												autoPlay={true}
												className="w-100 cursor-pointer"
												onPointerDown={() => setOngoingVideoId(video.userId)}>
												{ongoingVideoId === video.userId ? (
													<source src={video.rellPath} type="video/mp4" />
												) : null}
											</video>
										</div>
									);
								})}
							</Auxiliary>
						) : null}
						{!lecturerVideo &&
						!studentVideos.length &&
						!screenShareVideos.length ? (
							<div className="no-data-container mt-3">
								<div className="no-data-wrapper">
									<p>{translationService.translate("TR_NO_DATA")}</p>
									<img src={NoDataImage} alt="/" />
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
			<hr />
			<div className="row mb-5">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_CHAT")}
					</h2>
				</div>
				{messages && messages.length ? (
					<div className="col-lg-8 col-12">
						<div className=" mindalay--consultation-tab">
							<div className="consultation-chat-content-wrapper">
								<div
									id="mindalay--consultation-chat"
									className="consultation-chat-message-container box-shadow-4 py-5">
									{messages.map((mess, index) => {
										return mess.senderId === user.id ? (
											<div
												key={index}
												className="consultation-chat-message-wrapper consultation-chat-currnet-user">
												<div className="consultation-chat-message">
													<p className="consultation-chat-message-text word-wrap-break-word">
														{mess.message}
													</p>
													<span className="consultation-chat-message-date">
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(mess.time),
															),
														).format("MMM DD, H:mm")}
													</span>
												</div>
											</div>
										) : (
											<div
												key={index}
												className="consultation-chat-message-wrapper consultant-chat-sender">
												<div className="d-flex align-items-end">
													<div
														className="chat-image"
														style={{
															backgroundImage: `url(${mess.avatarFilePath})`,
														}}
													/>
													<div>
														<small>{mess.userName}</small>
														<div className="consultation-chat-message">
															<p className="consultation-chat-message-text word-wrap-break-word">
																{mess.message}
															</p>
															<span className="consultation-chat-message-date">
																{moment(
																	MainService.convertUTCDateToLocalDate(
																		new Date(mess.time),
																	),
																).format("MMM DD, H:mm")}
															</span>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="no-data-container mt-3">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
			<hr />
			{
				<div className="row mb-5">
					<div className="col-12">
						<h2 className="section-title">
							{translationService.translate("TR_FILES")}
						</h2>
					</div>
					{files.length ? (
						<div className="col-12">
							<Fiels files={files} />
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<p>{translationService.translate("TR_NO_DATA")}</p>
								<img src={NoDataImage} alt="/" />
							</div>
						</div>
					)}
				</div>
			}
		</div>
	) : null;
};

export default withRouter(WebinarCalendarInfo);
