import { GET_POTENTIAL_CONSULTATIONS_COUNT_SUCCESS } from "../../Constants/reduxKeys";

let initialState = {
  potentialconsultationsCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POTENTIAL_CONSULTATIONS_COUNT_SUCCESS:
      return {
        ...state,
        potentialconsultationsCount: action.payload.data,
      };
    default:
      break;
  }
  return state;
};

export default reducer;