import React from "react";
import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";
import NoDataImage from "../../../../assets/images/illustrations/nodata.svg";
import ResourceCount from "../../Resources/Components/ResourceCount";
import { useSelector } from "react-redux";
import { STUDENT_USER_TYPE } from "../../../../Constants/mainKeys";

export default function FragmenetsModal(props) {
	const { selectedResource, setSelectedResource, translationService } = props;
	const { user } = useSelector(state => state.user);
	return translationService && selectedResource ? (
		<Modal
			size="lg"
			show={true}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable
			onHide={() => setSelectedResource(null)}>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{selectedResource.name || selectedResource.resource?.name}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row align-self-stretch">
					{selectedResource &&
					selectedResource.fragments &&
					selectedResource.fragments.length ? (
						selectedResource.fragments.map((item, index) => {
							return (
								<div key={index} className="col-12 col-md-6 col-lg-3 p-2">
									<Card className="w-100 h-100">
										{item.fragmentAmount !== null ? (
											<ResourceCount count={item.fragmentAmount} size="sm" />
										) : null}
										<div
											className="w-100"
											style={{
												backgroundImage: `url(${
													item.imagePath || item.fragment?.imagePath
												})`,
												height: "200px",
												backgroundPosition: "center",
												backgroundSize: "contain",
												backgroundRepeat: "no-repeat",
											}}
										/>
										<Card.Body>
											<h3 className="title text-center">
												{item.name || item.fragment?.name}
											</h3>
										</Card.Body>
									</Card>
								</div>
							);
						})
					) : (
						<div className="no-data-container">
							<div className="no-data-wrapper">
								<p>{translationService.translate("TR_NO_DATA")}</p>
								<img src={NoDataImage} alt="/" />
							</div>
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn mindalay--btn-dark px-4"
					onClick={() => setSelectedResource(null)}>
					{translationService.translate("TR_CLOSE")}
				</button>
			</Modal.Footer>
		</Modal>
	) : null;
}
