import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TranslationService from '../../Services/translationService';
import { connect } from 'react-redux';
import { compose } from 'redux';
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import ApiService from '../../Services/apiService';
import AlertService from '../../Services/alertService';
import { ERROR_KEY, SUCCESS_KEY } from '../../Constants/mainKeys';
import ModalComponent from '../../Components/ModalComponent/modalComponent';
import Textarea from '../../Components/Inputs/textArea';
import MainService from '../../Services/mainService';
import moment from 'moment';
import ShowMoreText from "react-show-more-text";
import NoDataImage from '../../assets/images/illustrations/nodata.svg';

class ConsultantFeed extends Component {
  _isMounted = false;

  state = {
    translationService: null,
    consultations: [],
    showModal: false,
    coverLetter: '',
    isInvalidSubmit: false,
    coverLetterMaxLength: 2000,
    coverLetterMinLength: 50,
    consultationId: null,
    studentUserId: null,
    currentConsultation: null,
    pageNumber: 1,
    pageSize: 5,
    pagination: null
  }

  componentDidMount() {
    const { pageNumber, pageSize } = this.state;
    this._isMounted = true;
    this.setTranslations();
    this.getPotentialConsultations(pageNumber, pageSize);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getPotentialConsultations = (pageNumber, pageSize) => {
    const spinnerId = uuid();
    let { consultations } = this.state;
    this.props.addPageSpinner(spinnerId)
    ApiService.getPotentialConsultations(pageNumber, pageSize).then(response => {
      if (response && response.data && response.pagination && this._isMounted) {
        consultations = [...response.data];
        if (this.state.consultations.length) {
          consultations = [...this.state.consultations, ...response.data];
        }
        this.setState({ consultations, pagination: JSON.parse(response.pagination) });
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getNextPageConsultations = () => {
    let { pageNumber } = this.state;
    pageNumber += 1;
    this.setState({
      pageNumber,
    }, () => {
      this.getPotentialConsultations(this.state.pageNumber, this.state.pageSize);
    })
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  openOrCloseModal = (consultation) => {
    const { showModal } = this.state;
    if (consultation) {
      this.setState({
        showModal: !showModal,
        consultationId: consultation.id,
        studentUserId: consultation.studentUserId,
        currentConsultation: consultation,
      });
    } else {
      this.setState({ showModal: !showModal })
    }
  }

  applyConsultation = () => {
    var { coverLetter, consultations, coverLetterMinLength, consultationId, translationService } = this.state;
    if (!coverLetter || coverLetter.trim().length < coverLetterMinLength) {
      this.setState({ isInvalidSubmit: true });
    } else {
      const spinnerId = uuid();
      this.props.addPageSpinner(spinnerId);
      ApiService.applyConsultation(consultationId, coverLetter).then(() => {
        consultations = consultations.filter(consultations => consultations.id !== consultationId);
        this.setState({ consultations, coverLetter: '', showModal: false });
        this.props.removePageSpinner(spinnerId);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CONSULTATION_APPLIED_MESSAGE"))
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {
    const {
      translationService,
      consultations,
      showModal,
      coverLetter,
      isInvalidSubmit,
      coverLetterMaxLength,
      coverLetterMinLength,
      currentConsultation,
      pagination
    } = this.state;

    return (translationService ? <section className="m-section">
      <div className="container consultations-container">
        <div className="row">
          <div className="col-12">
            <h3 className="section-title">{translationService.translate("TR_CONSULTANT_FEED")}</h3>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {
              showModal ?
                <ModalComponent
                  title={currentConsultation ? currentConsultation.name : ""}
                  contentClassName="resource-modal"
                  isLarge="xl"
                  actionButtonTitle={translationService.translate("TR_APPLY")}
                  cancel={this.openOrCloseModal}
                  onSubmit={this.applyConsultation}
                >
                  <div className="d-flex resource-modal">
                    <div className="tab-content scroll" role="tablist">
                      <div id="pane-image" className="card tab-pane fade show active h-100 border-0" role="tabpanel"
                        aria-labelledby="tab-image">
                        <div id="collapse-image" className="collapse show" role="tabpanel" aria-labelledby="heading-image">
                          <div className="card-body">
                            {
                              currentConsultation.description ?
                                <p className='word-break-break-word'>{currentConsultation.description}</p>
                                : null
                            }
                            {/* {
                              currentConsultation.description ?
                                <Auxiliary>
                                  <div>
                                    <p className='d-flex'>{translationService.translate("TR_CREATOR")}: <span className='word-break-break-word ml-1'>{currentConsultation.studentUserName}</span></p>
                                    <p className='d-flex mt-1'>{translationService.translate("TR_DESCRIPTION")}: <span className='word-break-break-word ml-1'>{currentConsultation.description}</span></p>
                                  </div>
                                  <hr />
                                </Auxiliary>
                                : null
                            } */}
                            <label className="d-block">{`${translationService.translate("TR_COVER_LETTER")}*`}</label>
                            <small className="m-0 mb-2 d-block">{translationService.translate("TR_COVER_LETTER_INFO")}</small>
                            <Textarea
                              rows="6"
                              id="coverLetter"
                              min={coverLetterMinLength}
                              max={coverLetterMaxLength}
                              name="coverLetter"
                              textAreaClassName="pr--5"
                              value={coverLetter}
                              placeholder={translationService.translate("TR_TYPE_COVER_LATTER")}
                              isInvalidSubmit={isInvalidSubmit}
                              isInvalidField={isInvalidSubmit && coverLetter.trim().length < coverLetterMinLength}
                              onChange={(event) => this.onChange(event, coverLetterMaxLength)}
                            />
                            <small><i>{translationService.translate("TR_MIN_FIFTY_STYMOLS")}</i></small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalComponent>
                : null
            }
            <div className="row">
              {
                consultations.length ?
                  consultations.map(consultation => {
                    return <div key={consultation.id} className="col-12">
                      <div className="box-shadow-4 mindalay--consultation-container mindalay--consultant-container d-sm-flex mt-3">
                        <div className="consultation-user-image" style={{ backgroundImage: `url(${consultation.studentImagePath})` }}></div>
                        <div className="w-100">
                          <div className="d-sm-flex justify-content-between">
                            <div className="consultation-container-left-side">
                              <strong className="consultation-user-name">{consultation.studentUserName}</strong>
                              <small className="d-block gmt-time">{`${translationService.translate("TR_CREATED")}: 
                              ${moment(MainService.convertUTCDateToLocalDate(new Date(consultation.creationDate))).format("MMM DD, H:mm")}`}
                              </small>
                            </div>
                            <div className="ml-lg-4 consultation-price">
                              <button type="button" className="mindalay--btn-dark" onClick={() => this.openOrCloseModal(consultation)}>
                                {translationService.translate("TR_APPLY")}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="consultation-student-info-wrapper">
                            {
                              consultation.preferredDay ?
                                <div className="consultation-student-info">
                                  <small>{`${translationService.translate("TR_PREFERED_DAY")}`}</small>
                                  <strong>{moment(MainService.convertUTCDateToLocalDate(new Date(consultation.preferredDay))).format("MMM DD, H:mm")}</strong>
                                </div>
                                : null
                            }
                            {
                              consultation.durationMax ?
                                <div className="consultation-student-info">
                                  <small>{`${translationService.translate("TR_DURATION")}: `}</small>
                                  <strong><span>{consultation.durationMax}</span></strong>
                                </div>
                                : null
                            }
                            {
                              consultation.approximateCost ?
                                <div className="consultation-student-info">
                                  <small>{`${translationService.translate("TR_APPROXIMATE_COST")}: `}</small>
                                  <strong><span>{consultation.approximateCost}</span></strong>
                                </div>
                                : null
                            }
                          </div>
                          {
                            consultation.name ?
                              <div className="selected-item-container">
                                <div className="selected-item-wrapper">
                                  <div className="selected-item">
                                    <p>{consultation.name}</p>
                                  </div>
                                </div>
                              </div>
                              : null
                          }
                          {
                            consultation.description ?
                              <ShowMoreText
                                lines={2}
                                more={translationService.translate("TR_SHOW_MORE")}
                                less={translationService.translate("TR_LESS")}
                                className="content-css"
                                anchorClass="my-anchor-css-class"
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                <p> {consultation.description}</p>
                              </ShowMoreText>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  })
                  : <div className="no-data-container">
                    <div className="no-data-wrapper">
                      <p className="col-12">{translationService.translate("TR_THERE_IS_NO_CONSULTATION_TO_APPLY")}</p>
                      <img src={NoDataImage} alt="/" />
                    </div>
                  </div>
              }
            </div>
            {
              pagination && pagination.HasNext ?
                <button type="button" className="mindalay--btn-dark w-100 mt-3" onClick={() => this.getNextPageConsultations()}>
                  {translationService.translate("TR_SHOW_MORE")}
                </button>
                : null
            }
          </div>
        </div>
      </div>
    </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  user: state.user.user
});

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ConsultantFeed);
