import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from 'react-uuid';
import ApiService from '../../Services/apiService';
import TranslationService from '../../Services/translationService';
import { addPageSpinner, removePageSpinner } from '../../Store/Actions/spinner';
import AlertService from '../../Services/alertService';
import { BACK_URL_KEY, ERROR_KEY, FAQ_KEY, FAQ_PROPERTY_ID_KEY } from '../../Constants/mainKeys';
import { Link } from 'react-router-dom';
import { B2B_KEY, CONSULTANT_KEY, FAQ_PATH_KEY, HELP_KEY, STUDENT_KEY, TOPIC_KEY, VIDEO_CONFERENCE_KEY } from '../../Constants/urlKeys';
import { setFaqContent, setFaqPropertyId } from '../../Store/Actions/main';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import { INSTRUCTOR_KEY } from './../../Constants/urlKeys';
import ArrowBackSvg from './../../Components/Svg/arrowBackSvg';
import MainService from '../../Services/mainService';
import HelpLeftMenu from './Components/HelpLeftMenu';
import { setFaqPageContent, setPageContent, setTopicPageContent } from '../../Store/Actions/help';

const Help = (props) => {

  const dispatch = useDispatch();
  const pathName = props.location.pathname;
  const { translations } = useSelector(state => state.translation);
  const { language } = useSelector(state => state.language);
  const [translationService, setTranslationService] = useState(null);
  const { faqPageContent, topicPageContent, pageContent } = useSelector(state => state.help);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (
      !props.location.pathname.includes(STUDENT_KEY) &&
      !props.location.pathname.includes(INSTRUCTOR_KEY) &&
      !props.location.pathname.includes(CONSULTANT_KEY) &&
      !props.location.pathname.includes(VIDEO_CONFERENCE_KEY) &&
      !props.location.pathname.includes(B2B_KEY)
    ) {
      redirectToAnotherPage(STUDENT_KEY);
    }
    getHelpPageContent();
    return () => {
      localStorage.removeItem(BACK_URL_KEY);
    }
  }, []);

  useEffect(() => {
    // if (MainService.isShowOnlyB2B()) {
    //   getHelpPageContent();
    // }
    pageContent && getStudentFaqContent(pageContent);
  }, [props.match.params.pageName]);

  const getStudentFaqContent = (pageContent) => {
    dispatch(setFaqPageContent(null));
    dispatch(setTopicPageContent(null))
    if (!pageContent.children || (pageContent.children && !pageContent.children.length)) { return; }
    const currentChild = pageContent.children.find(pageContentChild => pageContentChild.uri === props.match.params.pageName);
    if (!currentChild) { return }
    if (currentChild && (!currentChild.children || (currentChild.children && !currentChild?.children.length)) && !currentChild?.pageContentResponses?.length) {
      dispatch(setFaqPageContent(null));
      dispatch(setTopicPageContent(null))
    } else {
      if (currentChild.children.length) {
        currentChild.children.forEach(currentChildItem => {

          if (currentChildItem.pageContentResponses && currentChildItem.pageContentResponses.length) {
            //FAQ
            dispatch(setFaqPageContent(currentChildItem))
          }

          if (currentChildItem.children && currentChildItem.children.length) {
            //TOPIC
            dispatch(setTopicPageContent(currentChildItem));
          }

        })
      }
    }
  }

  const getHelpPageContent = () => {
    const spinnerId = uuid();
    // dispatch(addPageSpinner(spinnerId));
    (MainService.isShowOnlyB2B() ? ApiService.getHelpPageContentBySpecialDomain() : ApiService.getHelpPageContent()).then(response => {
      dispatch(removePageSpinner(spinnerId));
      if (response.data) {
        dispatch(setPageContent({ ...response.data }))
        getStudentFaqContent(response.data);
        if (response.data.children.length === 1) {
          const data = { ...response.data }
          redirectToAnotherPage(data.children[0].uri);
          if (data.children[0].children && data.children[0].children.length) {
            //TOPIC
            dispatch(setTopicPageContent(data.children[0]));
          }
        }
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const redirectToAnotherPage = (uri) => {
    props.history.push(`/${language}/${HELP_KEY}/${uri}`);
  }

  const redirectToFaqPage = (id) => {
    if (!faqPageContent) { return; }
    dispatch(setFaqContent(faqPageContent));
    dispatch(setFaqPropertyId(faqPageContent));
    localStorage.setItem(FAQ_PROPERTY_ID_KEY, id);
    localStorage.setItem(FAQ_KEY, JSON.stringify(faqPageContent));
    props.history.push(`/${language}/${HELP_KEY}/${props.match.params.pageName}/${FAQ_PATH_KEY}`);
  }

  const goBack = () => {
    if (localStorage.getItem(BACK_URL_KEY)) {
      props.history.push(localStorage.getItem(BACK_URL_KEY));
    } else {
      props.history.push(`/${language}`);
    }
    localStorage.removeItem(BACK_URL_KEY);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
    props.history.push(`/${language}`)
  }

  return (translationService && pageContent ? <div className='help-container'>
    <div className="container mt-4">
      <div className="row">
        <div className="col-12 content-title">
          <div className="d-flex align-items-center">
            {/* <Link to={`#`} title={translationService.translate("TR_BACK")} onClick={goBack}><ArrowBackSvg /></Link> */}
            <h2>{translationService.translate(pageContent.pageTitle) ? translationService.translate(pageContent.pageTitle) : pageContent.pageTitle}</h2>
          </div>
        </div>
      </div>
      <div className="row"><div className="col-12"><hr /></div></div>
      {
        // Student, Instructor, Consultant, Video conference, For organizations 
        pageContent && pageContent.children && pageContent.children.length && pageContent.children.length > 1 ?
          <Auxiliary>
            <div className="row">
              <div className="col-12">
                <ul className="help-top-sections">
                  {
                    pageContent.children.map(child => {
                      return <li
                        key={child.id}
                        className={`cursor-pointer help-top-section ${pathName.includes(child.uri) ? "help-top-active-section" : ""}`}
                        onClick={() => redirectToAnotherPage(child.uri)}
                      >
                        <Link to="#">{translationService.translate(child.pageTitle) ? translationService.translate(child.pageTitle) : child.pageTitle}</Link>
                      </li>
                    })
                  }
                </ul>
              </div>
            </div>
            <div className="row"><div className="col-12"><hr /></div></div>
          </Auxiliary>
          : null
      }
      {
        // Topics part
        topicPageContent ?
          <Auxiliary>
            <div className="row">
              {
                topicPageContent.children && topicPageContent.children.length ?
                  topicPageContent.children.map(topicPageContentChild => {
                    return <div key={topicPageContentChild.id} className="col-lg-4 col-sm-6 my-2 cursor-pointer">
                      <Link to={`/${language}/${HELP_KEY}/${props.match.params.pageName}/${TOPIC_KEY}/${topicPageContentChild.id}`} className="help-topic-container">
                        <div className="card faq-wrapper">
                          <div className="card-body">
                            <p className="faq-description text-capitalize">
                              {translationService.translate(topicPageContentChild.pageTitle) ? translationService.translate(topicPageContentChild.pageTitle) : topicPageContentChild.pageTitle}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  }) : null
              }
            </div>
            <div className="row"><div className="col-12"><hr /></div></div>
          </Auxiliary>
          : null
      }
      {
        // FAQ part
        faqPageContent ?
          <div className="row">
            <div className="col-12">
              <div className="content-sub-title p-0 my-2"><h3>{translationService.translate(faqPageContent.pageTitle) ? translationService.translate(faqPageContent.pageTitle) : faqPageContent.pageTitle}</h3></div>
            </div>
            {
              faqPageContent.pageContentResponses && faqPageContent.pageContentResponses.length ?
                faqPageContent.pageContentResponses.map(faqPageContentResponse => {
                  return <div key={faqPageContentResponse.id} className="col-lg-4 col-sm-6 my-2" onClick={() => redirectToFaqPage(faqPageContentResponse.id)}>
                    <Link to="#" className="faq-topic-container">
                      <div className="card faq-wrapper">
                        <div className="card-body">
                          <p className="faq-description">{translationService.translate(faqPageContentResponse.name) ? translationService.translate(faqPageContentResponse.name) : faqPageContentResponse.name}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                })
                : null
            }
          </div>
          : null
      }
      {
        !faqPageContent && !topicPageContent ?
          <div className="no-data-container">
            <div className="no-data-wrapper">
              <p>{translationService.translate("TR_NO_DATA")}</p>
              <img src={NoDataImage} />
            </div>
          </div>
          : null
      }
    </div>
  </div>
    : null
  );
}

export default withRouter(Help);