import { WISH_LIST_PRODUCTS_IDS_KEYS } from "../Constants/mainKeys";

class ChangeStorageDataService {

  static addOrRemoveWishListProduct = (course) => {
    return new Promise((resolve, reject) => {
      if (!course) { reject(false) }
      if (!localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS)) {
        //// if wish list is empty
        let wishListProductsByIds = [course.id];
        const wishListCount = wishListProductsByIds.length;
        wishListProductsByIds = JSON.stringify(wishListProductsByIds);
        localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, wishListProductsByIds);
        const data = {
          course,
          status: "added",
          wishListCount
        }
        resolve(data);
      } else {
        let wishListProductsByIds = JSON.parse(localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS));
        const isExist = wishListProductsByIds.find(productId => productId === course.id);
        if (!isExist) {
          ////add product to wishlist
          wishListProductsByIds.push(course.id);
          const wishListCount = wishListProductsByIds.length;
          wishListProductsByIds = JSON.stringify(wishListProductsByIds);
          localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, wishListProductsByIds);
          const data = {
            course,
            status: "added",
            wishListCount
          }
          resolve(data);
        } else {
          //remove product from wishlist
          wishListProductsByIds = wishListProductsByIds.filter(productId => productId !== course.id);
          const wishListCount = wishListProductsByIds.length;
          wishListProductsByIds = JSON.stringify(wishListProductsByIds);
          localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, wishListProductsByIds);
          const data = {
            course,
            status: "removed",
            wishListCount,
          }
          resolve(data);
        }
      }
    })
  }
}

export default ChangeStorageDataService;