import React, { useEffect, useState } from "react";
import SubmitButton from "../../../Components/SubmitButton/submitButton";
import ActionButton from "../../../Components/ActionButton/actionButton";
import TranslationService from "../../../Services/translationService";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import Input from "../../../Components/Inputs/input";
import Textarea from "../../../Components/Inputs/textArea";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import ApiService from "../../../Services/apiService";
import { setSettings } from "../../../Store/Actions/settings";
import AlertService from "../../../Services/alertService";
import { ERROR_KEY, SUCCESS_KEY } from "../../../Constants/mainKeys";
import ReactSelectOption from "../../../Components/SelectOption/reactSelectOption";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import { getLanguages } from "../../../Store/Actions/language";

const spinnerId = uuid();

const MessagesForm = () => {
	const titleMaxLength = 120;
	const descriptionMaxLength = 2000;
	const footerTextMaxLength = 1000;
	const fieldMaxLength = 120;
	const placeholderFiledMaxLength = 50;

	const dispatch = useDispatch();
	const { language, languages } = useSelector(state => state.language);
	const { settings } = useSelector(state => state.settings);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [whitelabelLanguages, setWhitelabelLanguages] = useState([]);
	const [isChange, setIsChange] = useState(false);
	const [values, setValues] = useState({
		title: "",
		headerOrganizationName: "",
		description: "",
		footerText: "",
		loginPrompt: "",
		passwordPrompt: "",
		loginPlaceholder: "",
		passwordPlaceholder: "",
		languageId: null,
		organizationShortName: "",
		languageIds: [],
	});

	useEffect(() => {
		if (values && values.organizationShortName.trim()) {
			setIsInvalidSubmit(false);
		}
	}, [values.organizationShortName]);

	useEffect(() => {
		getWhitelabelLanguages();
	}, []);

	useEffect(() => {
		if (settings && whitelabelLanguages && whitelabelLanguages.length) {
			const newValues = {};
			Object.entries(settings).forEach(([key, value]) => {
				if (value) {
					if (key === "languageIds" && value.length) {
						newValues[key] = languages.filter(item =>
							value.find(el => el === item.languageId) ? true : false,
						);
					} else {
						newValues[key] = value;
					}
				}
			});
			newValues.id = settings.id;
			setValues(values => ({ ...values, ...newValues }));
		}
	}, [settings, whitelabelLanguages, languages]);

	useEffect(() => {
		if (
			values.languageIds &&
			values.languageIds.length === 1 &&
			values.languageIds[0].languageId !== values.languageId
		) {
			let langId = values.languageIds[0].languageId;
			setValues(values => ({
				...values,
				languageId: langId,
			}));
			getCurrentOrganizationSettingsAdmin(langId);
		}
	}, [values.languageIds]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getWhitelabelLanguages = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getWhitelabelLanguages()
			.then(response => {
				if (response && response.data && response.data.length) {
					let _languageId = response.data[0].languageId;
					setWhitelabelLanguages(response.data);
					setValues(values => ({
						...values,
						languageId: _languageId,
					}));
					getCurrentOrganizationSettingsAdmin(_languageId);
				} else {
					setWhitelabelLanguages([]);
				}
			})
			.catch(error => {
				getFail(error);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getCurrentOrganizationSettingsAdmin = languageId => {
		if (!languageId) {
			return false;
		}
		ApiService.getCurrentOrganizationSettingsAdmin(languageId)
			.then(res => {
				if (res.data) {
					const newValues = {};
					Object.entries(res.data).forEach(([key, value]) => {
						if (value) {
							if (key === "languageIds" && value.length) {
							} else {
								newValues[key] = value;
							}
						}
					});
					newValues.id = settings.id;
					setValues(values => ({ ...values, ...newValues }));
				}
			})
			.catch(error => {
				getFail(error);
			});
	};

	const onChangeShortName = (event, fieldName, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		if (event.target.value.includes(".") || event.target.value.includes("+")) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldName]: event.target.value.trim(),
		}));
		setIsInvalidSubmit(false);
	};

	const onChange = (event, fieldName, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) {
			return false;
		}
		if (fieldName === "languageId" && settings) {
			getCurrentOrganizationSettingsAdmin(item.value);
		}
		setIsInvalidSubmit(false);
		setValues(values => ({
			...values,
			[fieldName]: item.value,
		}));
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!values.organizationShortName.trim() || !values.languageId) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return;
		}
		values.languageIds = values.languageIds.map(el => el.languageId);
		dispatch(addButtonSpinner(spinnerId));
		(settings
			? ApiService.updateSettings(values)
			: ApiService.createSettings(values)
		)
			.then(res => {
				if (res.data) {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					dispatch(setSettings(res.data));
				}
				dispatch(getLanguages());
			})
			.catch(error => {
				getFail(error);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(spinnerId));
				setIsChange(false);
			});
	};

	const getFail = error => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	return translationService ? (
		<div className="mt-4">
			<form onSubmit={onSubmit} onChange={() => setIsChange(true)}>
				<div className="row mb-3">
					<div className="col-md-6">
						<Input
							type="text"
							id="name"
							name="name"
							disabled={settings && settings.organizationShortName}
							value={values.organizationShortName}
							isInvalidSubmit={isInvalidSubmit}
							labelValue={`${translationService.translate(
								"TR_ORGANIZATIONSHORTNAME",
							)}*"`}
							fieldLength={32}
							withoutSpace={translationService.translate(
								"TR_ORG_SHORT_NAME_MSG",
							)}
							onChange={event =>
								onChangeShortName(event, "organizationShortName", 32)
							}
						/>
					</div>

					<div className="col-md-6 col-12 mb-2">
						<label>{translationService.translate("TR_CHOOSELANGUAGES ")}</label>
						{whitelabelLanguages && whitelabelLanguages.length ? (
							<div className="d-flex flex-wrap">
								{whitelabelLanguages.map(item => (
									<div
										className="border rounded px-3 py-2 mr-2 mb-2"
										key={item.languageId}>
										<InputCheckBox
											id={`languages${item.languageId}`}
											name={`languages${item.languageId}`}
											checked={
												values.languageIds.filter(
													el => el.languageId === item.languageId,
												).length
											}
											blockClassName="mb-0 pb-0"
											labelValue={item.name}
											onChange={event => {
												if (event.target.checked) {
													setValues(values => ({
														...values,
														languageIds: [...values.languageIds, item],
														languageId: values.languageIds.length
															? values.languageId
															: item.languageId,
													}));
													if (settings && !values.languageIds.length) {
														getCurrentOrganizationSettingsAdmin(
															item.languageId,
														);
													}
												} else {
													const selectedValue = values.languageIds.filter(
														el => el.languageId !== item.languageId,
													);
													setValues(values => ({
														...values,
														languageIds: selectedValue,
														languageId:
															item.languageId === values.languageId
																? null
																: values.languageId,
													}));
												}
												setIsInvalidSubmit(false);
												setIsChange(true);
											}}
										/>
									</div>
								))}
							</div>
						) : null}
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-md-6 col-12 mb-2">
								<label>
									{translationService.translate("TR_CHOOSELANGUAGE")}
								</label>
								<ReactSelectOption
									value={values.languageId}
									isInvalidSubmit={isInvalidSubmit}
									selectedValue={(() => {
										const selectedItem = {
											...values.languageIds.find(
												data => data.languageId === values.languageId,
											),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label = selectedItem.name;
											selectedItem.value = selectedItem.languageId;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={values.languageIds.map(data => ({
										...data,
										label: data.name,
										value: data.languageId,
									}))}
									onChange={item => onSelectOptionChange(item, "languageId")}
								/>
								<small>
									{translationService.translate("TR_ORG_CHOOSE_LANGUAGE_MSG")}
								</small>
							</div>
							<div className="col-md-6">
								<Input
									type="text"
									id="headerOrganizationName"
									name="headerOrganizationName"
									className="pr--4"
									value={values.headerOrganizationName}
									labelValue={translationService.translate(
										"TR_HEADER_ORG_NAME",
									)}
									fieldLength={titleMaxLength}
									onChange={event =>
										onChange(event, "headerOrganizationName", titleMaxLength)
									}
								/>
							</div>
						</div>
					</div>
					{values.languageId ? (
						<Auxiliary>
							<div className="col-md-6">
								<Input
									type="text"
									id="title"
									name="title"
									className="pr--4"
									value={values.title}
									labelValue={translationService.translate("TR_MAINTITLE")}
									fieldLength={titleMaxLength}
									onChange={event => onChange(event, "title", titleMaxLength)}
								/>
							</div>

							<div className="col-12">
								<Textarea
									id="blockMessage"
									name="blockMessage"
									min="0"
									rows="3"
									textAreaClassName="pr--5"
									max={descriptionMaxLength}
									value={values.description}
									labelValue={translationService.translate("TR_DESCRIPTION")}
									onChange={event =>
										onChange(event, "description", descriptionMaxLength)
									}
								/>
							</div>
							<div className="col-12">
								<Textarea
									id="blockMessage"
									name="blockMessage"
									min="0"
									rows="2"
									textAreaClassName="pr--5"
									max={footerTextMaxLength}
									value={values.footerText}
									labelValue={translationService.translate("TR_FOOTERTEXT")}
									onChange={event =>
										onChange(event, "footerText", footerTextMaxLength)
									}
								/>
							</div>
							<div className="col-md-6">
								<Input
									type="text"
									id="name"
									name="name"
									className="pr--4"
									value={values.loginPrompt}
									labelValue={translationService.translate("TR_LOGINPROMPT")}
									fieldLength={fieldMaxLength}
									onChange={event =>
										onChange(event, "loginPrompt", fieldMaxLength)
									}
								/>
							</div>
							<div className="col-md-6">
								<Input
									type="text"
									id="name"
									name="name"
									className="pr--4"
									value={values.loginPlaceholder}
									labelValue={translationService.translate(
										"TR_LOGINPLACEHOLDER",
									)}
									fieldLength={placeholderFiledMaxLength}
									onChange={event =>
										onChange(
											event,
											"loginPlaceholder",
											placeholderFiledMaxLength,
										)
									}
								/>
							</div>
							<div className="col-md-6">
								<Input
									type="text"
									id="name"
									name="name"
									className="pr--4"
									value={values.passwordPrompt}
									labelValue={translationService.translate("TR_PASSWORDPROMPT")}
									fieldLength={fieldMaxLength}
									onChange={event =>
										onChange(event, "passwordPrompt", fieldMaxLength)
									}
								/>
							</div>
							<div className="col-md-6">
								<Input
									type="text"
									id="name"
									name="name"
									className="pr--4"
									value={values.passwordPlaceholder}
									labelValue={translationService.translate(
										"TR_PASSWORDPLACEHOLDER",
									)}
									fieldLength={placeholderFiledMaxLength}
									onChange={event =>
										onChange(
											event,
											"passwordPlaceholder",
											placeholderFiledMaxLength,
										)
									}
								/>
							</div>
							<div className="col-12">
								<hr />
								<ActionButton
									spinnerId={spinnerId}
									type="submit"
									disabled={isInvalidSubmit || !isChange}
									className="mindalay--btn-dark position-relative mb-3"
									name={translationService.translate("TR_SAVE")}
								/>
							</div>
						</Auxiliary>
					) : null}
				</div>
			</form>
		</div>
	) : null;
};

export default MessagesForm;
