import { SHOW_OR_HIDE_GENERAL_DASHBOARD_LEFT_MENU } from "../../Constants/reduxKeys";

let initialState = {
  showOrHideGeneralLeftMenu: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_OR_HIDE_GENERAL_DASHBOARD_LEFT_MENU:
      return {
        ...state,
        showOrHideGeneralLeftMenu: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;