import React, { Component } from 'react';
import { connect } from 'react-redux';
import TranslationService from '../../Services/translationService';
import { addPartialViewSpinner, removePartialViewSpinner } from "../../Store/Actions/spinner";
import uuid from 'react-uuid';
import ApiService from '../../Services/apiService';
import moment from 'moment';
import MainService from '../../Services/mainService';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { CONSULTANT_CONSULTATION_CONTRACTS_KEY, COURSES_KEY, CONSULTANT_KEY, STUDENT_CONSULTATION_CONTRACTS_KEY } from '../../Constants/urlKeys';
import { ERROR_KEY, PAYMENT_TYPES, ENTITY_TYPE, INVALID_DATE_KEY, PAYMENT_HISTORY_TABLE_FIELDS, NUMBER_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import { STUDENT_USER_TYPE } from './../../Constants/mainKeys';
import ReactPaginate from 'react-paginate';
import { setConsultationContractBackUrl } from './../../Store/Actions/main';

class Overview extends Component {

  paymentTypes = PAYMENT_TYPES;
  entityTypes = ENTITY_TYPE;
  paymentHistoryTableFields = PAYMENT_HISTORY_TABLE_FIELDS;

  state = {
    translationService: null,
    paymentHistory: [],
    defaultValue: { label: "", value: "" },
    paymentType: 1,
    entityType: 0,
    pagination: null,
    activePageNumber: 0,
    pageNumber: 1,
    pageSize: 10,
  };

  componentDidMount() {
    const { pageNumber, paymentType, pageSize } = this.state;
    this.setTranslations();
    this.getPaymentHistoryWithPagination(paymentType, -1, pageNumber, pageSize);
  };

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getPaymentHistoryWithPagination = (paymentType, entityType, pageNumber, pageSize) => {
    if (paymentType === undefined || entityType === undefined || pageNumber === undefined || pageSize === undefined) { return; }
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getPaymentHistoryWithPagination(paymentType, entityType, pageNumber, pageSize).then(response => {
      if (response && response.data && response.pagination) {
        const data = [...response.data];
        data && data.forEach(element => {
          if (element.createDate) {
            element.createDate = moment(MainService.convertUTCDateToLocalDate(new Date(element.createDate))).format("ll (HH:mm)");
          }
          if (element.freezInfo && (moment(MainService.convertUTCDateToLocalDate(new Date(element.freezInfo))).format("ll (HH:mm)") !== INVALID_DATE_KEY)) {
            element.freezInfo = moment(MainService.convertUTCDateToLocalDate(new Date(element.freezInfo))).format("ll (HH:mm)");
          }
        });
        this.setState({
          pagination: JSON.parse(response.pagination),
          paymentHistory: data,
        })
      }
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  filterPaymentHistory = (selectedCategory, name) => {
    this.setState({ [name]: selectedCategory.value, activePageNumber: 0, pageNumber: 1, }, () => {
      if (name === "paymentType") { //Transaction types
        if (selectedCategory.value === 2 || selectedCategory.value === 3 || selectedCategory.value === 6) {
          this.getPaymentHistoryWithPagination(selectedCategory.value, this.state.entityType, this.state.pageNumber, this.state.pageSize);
        } else {
          this.getPaymentHistoryWithPagination(selectedCategory.value, -1, this.state.pageNumber, this.state.pageSize);
        }
        this.setState({ paymentType: selectedCategory.value, entityType: 0 });
      }
      if (name === "entityType") {
        this.getPaymentHistoryWithPagination(this.state.paymentType, selectedCategory.value, this.state.pageNumber, this.state.pageSize);
        this.setState({ entityType: selectedCategory.value })
      }
    });
  }

  handlePageClick = (event) => {
    const { paymentType, pageSize } = this.state;
    this.setState({ activePageNumber: event.selected, pageNumber: event.selected + 1 }, () => {
      this.getPaymentHistoryWithPagination(paymentType, this.state.entityType, this.state.pageNumber, pageSize);
    });
  }

  getFail = (message, spinnerId) => {
    message && AlertService.alert((AlertService.checkMessageType(message.respcode) || ERROR_KEY), message);
    spinnerId && this.props.removePartialViewSpinner(spinnerId)
  }

  redirectToCourseView = (courseId) => {
    this.props.history.push(`/${this.props.language}/${COURSES_KEY}/${courseId}`)
  }

  redirectToContractPage = (contractId) => {
    const { paymentType } = this.state;
    if (paymentType === 2 && contractId) { //Purchase Student
      this.props.setConsultationContractBackUrl(this.props.location.pathname);
      this.props.history.push(`/${this.props.language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${contractId}`);
    }
    if (paymentType === 3 && contractId) { //Sale Consultant
      this.props.setConsultationContractBackUrl(this.props.location.pathname);
      this.props.history.push(`/${this.props.language}/${CONSULTANT_KEY}/${CONSULTANT_CONSULTATION_CONTRACTS_KEY}/${contractId}`)
    }
  }

  render() {

    const { user } = this.props;

    const {
      translationService,
      paymentHistory,
      paymentType,
      defaultValue,
      entityType,
      pagination,
      activePageNumber,
    } = this.state;


    if (paymentHistory.length) {
      paymentHistory.forEach(currentElement => {
        if (currentElement.entityTypeName && currentElement.entityTypeName.toLowerCase().includes("course")) {
          currentElement.entityName = (() => {
            return <div
              className="payment-name"
              onClick={() => currentElement.entityId && this.redirectToCourseView(currentElement.entityId)}
            >
              <span title={currentElement.entityName}>
                {currentElement.entityName}
              </span>
            </div>
          })();
        }
        if (currentElement.entityTypeName && currentElement.entityTypeName.toLowerCase().includes("consultation")) {
          currentElement.entityName = (() => {
            return <div
              className="payment-name"
              onClick={() => currentElement.contractId && this.redirectToContractPage(currentElement.contractId)}
            >
              <span title={currentElement.entityName}>
                {currentElement.entityName}
              </span>
            </div>
          })();
        }
        if (currentElement.statusName) {
          currentElement.status = (() => {
            return <div>
              <span title={currentElement.statusName} className={`payment-status ${+currentElement.paymentStatus === 0 ? "orange-border orange-color" :
                +currentElement.paymentStatus === 1 ? "green-border green-color" : +currentElement.paymentStatus === 2 ? "blue-border blue-color" :
                  +currentElement.paymentStatus === 3 ? "red-border red-color" : ""}`}>
                {currentElement.statusName}
              </span>
            </div>
          })();
        }
      })
    }

    return (translationService ?
      <div className="container">
        <div className={`${user.userTypeId === STUDENT_USER_TYPE ? 'm-section' : ''}`}>
          <div className="row">
            <div className="col-12">
              <div className="row align-items-end">
                <div className="col-lg-8 col-sm-6 col-12">
                  <h2 className="section-title">{translationService.translate("TR_OVERVIEW")}</h2>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group m-0">
                    <label htmlFor="#">{translationService.translate("TR_TRANSACTION_TYPES")}</label>
                    <ReactSelectOption
                      defaultValue={defaultValue}
                      value={paymentType}
                      selectedValue={(() => {
                        const selectedValue = { ...this.paymentTypes.find(data => data.value === paymentType) };
                        if (selectedValue) {
                          selectedValue.label = translationService.translate(selectedValue.label);
                        }
                        return selectedValue;
                      })()}
                      items={this.paymentTypes.map((data) => ({ label: translationService.translate(data.label), value: data.value }))}
                      onChange={(item) => this.filterPaymentHistory(item, "paymentType")}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div className="col-12">
              <div className="mindalay--overview-container m-0">
                <div className="mindalay--overview-header d-lg-flex align-items-lg-center justify-content-lg-between">
                  {
                    paymentType === 2 || paymentType === 3 || paymentType === 6 ?
                      <div className="col-lg-4 col-sm-6 col-6 pl-0">
                        <div className="form-group">
                          <label htmlFor="#">{translationService.translate("TR_SERVICE_TYPE")}</label>
                          <ReactSelectOption
                            defaultValue={defaultValue}
                            value={entityType}
                            selectedValue={(() => {
                              const selectedValue = { ...this.entityTypes.find(data => data.value === entityType) };
                              if (selectedValue) {
                                selectedValue.label = translationService.translate(selectedValue.label);
                              }
                              return selectedValue;
                            })()}
                            items={this.entityTypes.map(data => ({ label: translationService.translate(data.label), value: data.value }))}
                            onChange={(item) => this.filterPaymentHistory(item, "entityType")}
                          />
                        </div>
                      </div>
                      : null
                  }
                </div>
                {
                  <div className="row">
                    <div className="col-12">
                      {
                        paymentHistory && paymentHistory.length ?
                          <div className="mindayal-admin-table table-responsive">
                            <table className="table">
                              <thead>
                                <tr className="cursor-default">
                                  {
                                    this.paymentHistoryTableFields.map(paymentHistoryTableField => {
                                      if (paymentHistoryTableField.transactionType === paymentType) {
                                        return paymentHistoryTableField.headerFields.map(field => {
                                          return <th key={field} className="px-5">
                                            <div className="mindalay-admin-table-td-item">
                                              {translationService.translate(field)}
                                            </div>
                                          </th>
                                        })
                                      }
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  paymentHistory.map(historyItem => {
                                    if (paymentType === 0 || paymentType === 1) {
                                      //deposit, withdraw
                                      return <tr key={historyItem.id} className="cursor-default">
                                        <td className="px-5">{historyItem.amountWithCurrency}</td>
                                        <td className="px-5">{historyItem.createDate}</td>
                                        <td className="px-5">{historyItem.paymentProviderName}</td>
                                        <td className="px-5">{historyItem.status}</td>
                                      </tr>
                                    } else if (paymentType === 3) {
                                      //sale
                                      return <tr key={historyItem.id} className="cursor-default">
                                        <td className="px-5">{historyItem.amountWithCurrency}</td>
                                        <td className="px-5">{historyItem.entityTypeName}</td>
                                        <td className="px-5">{historyItem.entityName}</td>
                                        <td className="px-5">{historyItem.otherUserName}</td>
                                        <td className="px-5">{historyItem.createDate}</td>
                                        <td className="px-5">{historyItem.freezInfo}</td>
                                      </tr>
                                    } else if (paymentType === 6) {
                                      //refund
                                      return <tr key={historyItem.id} className="cursor-default">
                                        <td className="px-5">{historyItem.amountWithCurrency}</td>
                                        <td className="px-5">{historyItem.entityTypeName}</td>
                                        <td className="px-5">{historyItem.entityName}</td>
                                        <td className="px-5">{historyItem.createDate}</td>
                                      </tr>
                                    } else {
                                      //purchase, course period extention
                                      return <tr key={historyItem.id} className="cursor-default">
                                        <td className="px-5">{historyItem.amountWithCurrency}</td>
                                        <td className="px-5">{historyItem.entityTypeName}</td>
                                        <td className="px-5">{historyItem.entityName}</td>
                                        <td className="px-5">{historyItem.otherUserName}</td>
                                        <td className="px-5">{historyItem.createDate}</td>
                                      </tr>
                                    }
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                          : <div className="no-data-container">
                            <div className="no-data-wrapper">
                              <p>{translationService.translate("TR_THERE_IS_NO_TRANSACTIONS_OF_THIS_TYPE")}</p>
                              <img src={NoDataImage} alt="/" />
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                }
                {
                  pagination ?
                    <div className="row mt-4">
                      <div className="col-12">
                        <div>
                          <ReactPaginate
                            nextLabel={translationService.translate("TR_NEXT")}
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pagination.TotalPages}
                            previousLabel={translationService.translate("TR_PREVIOUS")}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            forcePage={activePageNumber}
                          />
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div> : null
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  translations: state.translation.translations,
  language: state.language.language
});

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  setConsultationContractBackUrl
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Overview);