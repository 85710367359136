class HelpService {
	static setPageName(url) {
		switch (true) {
			// b2b unauth
			case url.includes("/registration"):
				return "create_organization";
			//

			// b2b lecturer part
			case url.includes("/filelibrary"):
				return "file_library";

			case url.includes("/webinar") &&
				!url.includes("/webinar/webinar-calendar"):
				return "webinars";

			case url.includes("/webinar/webinar-calendar"):
				return "webinar_calendar";

			case url.includes("/course") &&
				!url.includes("/coursedashboard") &&
				!url.includes("/quiz-form"):
				return "students_courses";

			case url.includes("/organization/coursedashboard"):
				return "create_course";

			case url.includes("/quiz-form"):
				return "create_quiz";

			case url.includes("/message"):
				return "messages";
			//

			//b2b Curator part
			case url.includes("/webinar/form"):
				return "webinars";

			case url.includes("/group") && !url.includes("/admin/group"):
				return "faculties";

			case url.includes("/message"):
				return "messages";
			//

			//b2b Student part
			case url.includes("/webinar") &&
				!url.includes("/webinar/webinar-calendar"):
				return "webinars";

			case url.includes("/webinar/webinar-calendar"):
				return "webinar_calendar";

			case url.includes("/message"):
				return "messages";
			//

			//b2b admin part
			case url.includes("/student-form"):
				return "student_form";

			case url.includes("/user-form"):
				return "user_form";
			//

			//b2c
			case url.includes("/organization-admin/organization-form"):
				return "create_organization";
			//

			default:
				break;
		}
	}
}

export default HelpService;
