import React from 'react';

const PaymentPackageSvg = () => {
  return <svg id="SvgjsSvg1001" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.com/svgjs">
    <defs id="SvgjsDefs1002">
    </defs><g id="SvgjsG1008">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M510.56,340.86,353.72,8.6A15,15,0,0,0,340.15,0H245.62l109.5,54.75A44.77,44.77,0,0,1,380,95V419.08l123.4-58.25A15,15,0,0,0,510.56,340.86Z" fill="#ffffff" className="color000 svgShape">
        </path>
        <circle cx="175" cy="120" r="20" fill="#ffffff" className="color000 svgShape"></circle>
        <path d="M341.71,81.58l-160-80a15,15,0,0,0-13.42,0l-160,80A15,15,0,0,0,0,95V497a15,15,0,0,0,15,15H335a15,15,0,0,0,15-15V95A15,15,0,0,0,341.71,81.58ZM187.91,412.81v16.93a7.5,7.5,0,0,1-7.5,7.5h-13.9a7.5,7.5,0,0,1-7.5-7.5V414.46c-11.63-1.34-23.1-5.24-30.62-10.61a7.51,7.51,0,0,1-2.7-8.64L131,380.43a7.5,7.5,0,0,1,11.22-3.71A51.72,51.72,0,0,0,169.82,385c11.22,0,18.76-5.68,18.76-14.13,0-6.66-3.45-12.49-20.26-19.3-20.28-8-41.09-19.56-41.09-45.13,0-20.16,12.87-35.92,33.32-41.56V248.58a7.5,7.5,0,0,1,7.5-7.5h13.68a7.5,7.5,0,0,1,7.5,7.5V263a63.42,63.42,0,0,1,25,8.2,7.48,7.48,0,0,1,3.22,9.12l-5.51,14.57a7.5,7.5,0,0,1-10.74,3.85,46,46,0,0,0-24.08-6.51c-14.75,0-15.89,8.78-15.89,11.47,0,6.1,3.28,10.18,22.73,18.23,18.06,7.36,38.83,19.41,38.83,47.31C222.77,390.08,209,406.93,187.91,412.81ZM175,170a50,50,0,1,1,50-50A50.06,50.06,0,0,1,175,170Z" fill="#ffffff" className="color000 svgShape">
        </path>
      </svg>
    </g>
  </svg>

}

export default PaymentPackageSvg;
