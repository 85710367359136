import { CURRENT_USER_SUCCESS } from '../../Constants/reduxKeys';
import {
  USER_LOGIN_REQUEST,
  USER_REGISTRATION_REQUEST,
  CURRENT_USER_UPDATE_REQUEST,
  AUTH_LOGOUT_REQUEST,
  REMOVE_LOGIN_ERROR_MESSAGE_REQUEST,
  GET_PAGES_FROM_ORGANIZATION_USER_REQUEST,
} from '../../Constants/reduxRequestKeys';

export function userLogin(data, spinnerId, history) {
  return {
    type: USER_LOGIN_REQUEST,
    payload: { data, spinnerId, history },
  };
};

export function userRegistration(data, spinnerId) {
  return {
    type: USER_REGISTRATION_REQUEST,
    payload: { data, spinnerId },
  };
};

export function logout() {
  return {
    type: AUTH_LOGOUT_REQUEST,
    payload: {}
  }
};

export function userUpdate(data, translationSuccessMessage) {
  return {
    type: CURRENT_USER_UPDATE_REQUEST,
    payload: { data, translationSuccessMessage }
  };
};

export function userUpdateSuccess(data) {
  return {
    type: CURRENT_USER_SUCCESS,
    payload: data
  };
};

export function removeLoginErrorMessage() {
  return {
    type: REMOVE_LOGIN_ERROR_MESSAGE_REQUEST,
    payload: true
  };
};

export function setUserType(userTypeId) {
  return {
    type: GET_PAGES_FROM_ORGANIZATION_USER_REQUEST,
    payload: userTypeId
  };
};


