class ResponseService {
  static responseCheckIdentity(response) {
    return new Promise((resolve, reject) => {
      if(!response) { return; }
      if(!response.data.isError) {
        resolve(response.data);
      } else {
        reject(response.data.errorDescription || 'Fail');
      }
    });
  };
}

export default ResponseService;