import React, { Component } from "react";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import uuid from "react-uuid";
import { Link } from "react-router-dom";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import QuestionsGroupForm from "./questionsGroupForm";
import SelectQuestionTypeModal from "./Modals/selectQuestionTypeModal";
import Search from "../../Components/Search/search";
import SettingsSvg from "../../Components/Svg/settingsSvg";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { TR_DELETE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
	FORM_KEY,
	INSTRUCTOR_KEY,
	QUESTION_BANK_KEY,
	COURSES_KEY,
	QUESTION_KEY,
} from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import InfoSvg from "../../Components/Svg/infoSvg";
import Parser from "html-react-parser";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

class Questions extends Component {
	state = {
		deleteQuestionId: null,
		deleteQuestionsGroupId: null,
		questionsGroupId: null,
		updateQuestionsGroup: null,
		showQuestionsGroupForm: false,
		showQuestionTypeModal: false,
		questions: [],
		questionsGroups: [],
		searchedQuestionsGroup: null,
		translationService: null,
	};

	componentDidMount() {
		this.setTranslations();
		this.getQuestionsGroups();
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getQuestionsGroups = () => {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		ApiService.getQuestionsGroups()
			.then(response => {
				const questionsGroups = response.data;
				this.setState({ questionsGroups, showQuestionsGroupForm: false });
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(error => this.getFail(spinnerId, error));
	};

	getQuestionsByQuestionGroupId = groupId => {
		if (groupId === this.state.questionsGroupId) {
			this.setState({ questionsGroupId: null });
		} else {
			ApiService.getQuestionsByQuestionGroupId(groupId)
				.then(response => {
					const questions = [...response.data];
					this.setState({ questions, questionsGroupId: groupId });
				})
				.catch(error => this.getFail(null, error));
		}
	};

	createQuestionByQuestionType = () => {
		const { user } = this.props;
		this.setState({ showQuestionTypeModal: false });
		const { history, language } = this.props;
		const hash = history.location.hash.substring(1);
		if (hash) {
			const path = user.isOrganizationUser
				? `/${language}/question/form/${hash}`
				: `/${language}/instructor/courses/question-bank/form/${hash}`;
			history.push(path);
		}
	};

	toggleQuestionTypeModal = show => {
		const { user } = this.props;
		this.setState({ showQuestionTypeModal: show });
		const { language, location, history } = this.props;
		if (location.hash && !show) {
			const path = user.isOrganizationUser
				? `/${language}/${QUESTION_KEY}`
				: `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${QUESTION_BANK_KEY}`;
			history.push(path);
		}
	};

	updateQuestionGroup = updateQuestionsGroup => {
		this.setState({ showQuestionsGroupForm: true, updateQuestionsGroup });
		window.scrollTo(0, 0);
	};

	createQuestionGroup = () => {
		this.cancelUpdateQuestionGroup().then(() => {
			this.setState({ showQuestionsGroupForm: true });
		});
	};

	cancelUpdateQuestionGroup = () => {
		return new Promise((resolve, reject) => {
			resolve(
				this.setState({
					updateQuestionsGroup: null,
					showQuestionsGroupForm: false,
				}),
			);
		});
	};

	deleteQuestion = question => {
		const { translationService } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				question.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			ApiService.questionDelete(question.id)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate(TR_DELETE_MESSAGE_KEY),
					);
					const questions = this.state.questions.filter(
						data => data.id !== question.id,
					);
					this.setState({ questions });
				})
				.catch(error => this.deleteFail(null, error));
		});
	};

	deleteQuestionsGroup = questionGroup => {
		const { translationService } = this.state;
		const questionGroupId = questionGroup.id;
		this.setState({ deleteQuestionsGroupId: questionGroupId });
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				questionGroup.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			ApiService.questionsGroupDelete(questionGroupId)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate(TR_DELETE_MESSAGE_KEY),
					);
					const questionsGroups = this.state.questionsGroups.filter(
						data => data.id !== questionGroupId,
					);
					this.setState({ questionsGroups });
				})
				.catch(error => this.deleteFail(null, error));
		});
	};

	getFail = (spinnerId, error) => {
		spinnerId && this.props.removePageSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	deleteFail = (spinnerId, error) => {
		this.props.removeButtonSpinner(spinnerId);
		this.setState({ deleteQuestionsGroupId: null, deleteQuestionId: null });
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	render() {
		const { language, user } = this.props;
		const {
			translationService,
			questionsGroups,
			questions,
			questionsGroupId,
			updateQuestionsGroup,
			showQuestionTypeModal,
			showQuestionsGroupForm,
			searchedQuestionsGroup,
		} = this.state;

		return translationService ? (
			<div className={`${user.isOrganizationUser ? "m-section" : "col-12"}`}>
				<div className="container">
					<div className="row mt-4">
						<div className="col-12">
							<h2 className="section-title">
								{translationService.translate("TR_QUESTION_BANK")}
							</h2>
							<PageInfoBlock
								pageTitle={translationService.translate("TR_QUESTION_BANK")}
								pageName="question_bank_info"
								translationService={translationService}
							/>
						</div>
					</div>
					<hr />
					<div className="mb-3">
						<div className="d-lg-flex align-items-center justify-content-between">
							<div className="d-lg-flex d-block">
								<Search
									searchFieldName="name"
									searchData={questionsGroups}
									emitSearchedData={data =>
										this.setState({ searchedQuestionsGroup: data })
									}
								/>
							</div>
							<div className="mindalay--btn-link-wrapper--instructor">
								{(searchedQuestionsGroup && searchedQuestionsGroup.length) ||
								(questionsGroups && questionsGroups.length) ? (
									<button
										type="button"
										className="mindalay--btn-default w-sm-100 mx-1 mt-1"
										onClick={() => this.toggleQuestionTypeModal(true)}>
										{translationService.translate("TR_CREATE_QUESTION")}
									</button>
								) : null}
								{showQuestionTypeModal ? (
									<SelectQuestionTypeModal
										show={showQuestionTypeModal}
										added={this.createQuestionByQuestionType}
										closeModal={() => this.toggleQuestionTypeModal(false)}
									/>
								) : null}
								<button
									type="button"
									className="mindalay--btn-default w-sm-100 mx-1 mt-1"
									onClick={this.createQuestionGroup}>
									{translationService.translate("TR_CREATE_QUESTION_GROUP")}
								</button>
							</div>
						</div>
					</div>
					{showQuestionsGroupForm ? (
						<div className="p-3 light-gray-background">
							<QuestionsGroupForm
								questionsGroupData={updateQuestionsGroup}
								cancel={() => this.setState({ showQuestionsGroupForm: false })}
								added={this.getQuestionsGroups}
							/>
						</div>
					) : null}
					<hr />
					{(searchedQuestionsGroup && searchedQuestionsGroup.length) ||
					(questionsGroups && questionsGroups.length) ? (
						(searchedQuestionsGroup || questionsGroups).map(
							(questionsGroup, questionsGroupIndex) => (
								<div key={questionsGroup.id} className="accordion-wrapper mt-3">
									<div className="card mindalay-card">
										<div className="dropleft setting-dropdown">
											<div
												className="setting-dropdown-btn"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												<SettingsSvg />
											</div>
											<div className="dropdown-menu">
												<Link
													to="#"
													className="dropdown-item"
													onClick={() =>
														this.updateQuestionGroup(questionsGroup)
													}>
													{translationService.translate("TR_EDIT")}
												</Link>
												<Link
													to="#"
													className="dropdown-item"
													onClick={() =>
														this.deleteQuestionsGroup(questionsGroup)
													}>
													{translationService.translate("TR_DELETE")}
												</Link>
											</div>
										</div>
										<div
											aria-controls={`questions_group_${questionsGroup.id}`}
											aria-expanded={questionsGroupId === questionsGroup.id}
											className="card-header cursor-pointer"
											onClick={() =>
												this.getQuestionsByQuestionGroupId(questionsGroup.id)
											}>
											<h5 className="mb-0 word-break-break-word">
												<span>{questionsGroupIndex + 1}</span>
												{questionsGroup.name}
											</h5>
										</div>
										<Collapse in={questionsGroupId === questionsGroup.id}>
											<div
												id={`questions_group_${questionsGroup.id}`}
												className="light-blue-background">
												<div className="card-body">
													{questions.map((question, questionIndex) => {
														const editQuestionPath = `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${QUESTION_BANK_KEY}/${FORM_KEY}/${question.questionType}/${question.id}`;
														const editQuestionPathByOrgUser = `/${language}/${QUESTION_KEY}/${FORM_KEY}/${question.questionType}/${question.id}`;
														return (
															<div
																key={question.id}
																className="card-header mb-1 cursor-default z-index-auto">
																<div className="dropleft setting-dropdown">
																	<div
																		className="setting-dropdown-btn"
																		data-toggle="dropdown"
																		aria-haspopup="true"
																		aria-expanded="false">
																		<SettingsSvg />
																	</div>
																	<div className="dropdown-menu">
																		<Link
																			to={
																				user.isOrganizationUser
																					? editQuestionPathByOrgUser
																					: editQuestionPath
																			}
																			className="dropdown-item">
																			{translationService.translate("TR_EDIT")}
																		</Link>
																		<Link
																			to="#"
																			className="dropdown-item"
																			onClick={() =>
																				this.deleteQuestion(question)
																			}>
																			{translationService.translate(
																				"TR_DELETE",
																			)}
																		</Link>
																	</div>
																</div>

																{/* <h5 className="mb-0 text-elipsis"><span>{questionIndex + 1}</span>{question.name || question.text}</h5> */}
																{question.name ? (
																	<span className="question-dropdown-block">
																		<h5 className="mb-0 word-break-break-word">
																			<span>{questionIndex + 1}</span>
																			<ParserComponent
																				text={question.name || question.text}
																			/>
																		</h5>
																	</span>
																) : null}
																{question.text ? (
																	<span className="question-dropdown-block img-cursor-default word-break-break-word">
																		<ParserComponent text={question.text} />
																	</span>
																) : null}
															</div>
														);
													})}
												</div>
											</div>
										</Collapse>
									</div>
								</div>
							),
						)
					) : (
						<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
							<InfoSvg />
							{translationService.translate("TR_EMPTY_QUESTION_GROUPS_MSG")}
						</p>
					)}
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	removePageSpinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
