import React from "react";

const trueFalseSvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="38" height="16" viewBox="0 0 38 16">
    <g transform="translate(-527 -419)">
      <path d="M8,22a8,8,0,1,0,8,8A8.009,8.009,0,0,0,8,22ZM8,36a6,6,0,1,1,6-6A6.006,6.006,0,0,1,8,36Z" transform="translate(527 397)" fill="#2980b9"></path>
      <circle cx="8" cy="8" r="8" transform="translate(549 419)" fill="#2980b9"></circle>
    </g>
  </svg>;
}

export default trueFalseSvg;