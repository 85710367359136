import React from 'react';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import InfoSvg from '../Svg/infoSvg';

const InputRadio = props => {
  return (
    <Auxiliary>
      <div className={`custom-radio custom-control ${props.blockClassName ? props.blockClassName : ""}`}>

        <input
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          className={`mr-1 custom-control-input ${props.radioClassName ? props.radioClassName : ""}`}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <label htmlFor={props.id} className={`custom-control-label ${props.labelClassName ? props.labelClassName : ""}`}>
          {props.labelValue}
        </label>
        {
          props.infoText ?
            <div className="info-title d-inline-block">
              <div>
                <InfoSvg />
                <span style={{ display: "none" }}>
                  {props.infoText}
                </span>
              </div>
            </div>
            : null
        }
      </div>
      {
        props.failedFields?.hasOwnProperty(`${props.name.charAt(0).toUpperCase() + props.name.slice(1)}`)
          ? <small className="small-error-msg">{props.failedFields[props.name.charAt(0).toUpperCase() + props.name.slice(1)]}</small>
          : null
      }
    </Auxiliary>
  )
}

export default InputRadio;
