import React, { Component } from 'react';
import { Tab } from 'bootstrap';
import { Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import TranslationService from '../../../Services/translationService';
import Proposal from '../Components/proposal';
import Interview from '../Components/interview';
import InProcess from '../Components/inProcess';
import Completed from '../Components/completed';
import ApiService from '../../../Services/apiService';
import { addPartialViewSpinner, removePartialViewSpinner } from "../../../Store/Actions/spinner";
import uuid from 'react-uuid';
import AlertService from '../../../Services/alertService';
import ModalComponent from '../../../Components/ModalComponent/modalComponent';
import Textarea from '../../../Components/Inputs/textArea';
import { CONSULTATION_CHAT_TYPE_KEY, ERROR_KEY, SUCCESS_KEY } from '../../../Constants/mainKeys';
import { CONSULTANT_KEY } from '../../../Constants/requestKeys';
import { CONSULTANT_CONSULTATION_CONTRACTS_KEY } from '../../../Constants/urlKeys';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { setConsultationContractBackUrl } from './../../../Store/Actions/main';
import { CONSULTANT_USER_TYPE, INSTRUCTOR_USER_TYPE } from './../../../Constants/mainKeys';

class ForConsultantView extends Component {
  _isMounted = false;
  consultationChatType = CONSULTATION_CHAT_TYPE_KEY;

  state = {
    translationService: null,
    consultationId: null,
    key: "proposal",
    showModal: false,
    coverLetter: '',
    coverLetterMinLength: 50,
    coverLetterMaxLength: 2000,
    contractId: null,
    defaultProposalContracts: [],
    proposalContracts: [],
    inProcessContracts: [],
    interviewContracts: [],
    completedContracts: [],
    isInvalidSubmit: false,
    contract: null,
    proposalContractsConsultantAccepted: [],
    proposalContractsStudentAccepted: [],
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this.getConsultantContracts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getConsultantContracts = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getConsultantContracts().then(response => {
      var defaultProposalContracts = [];
      var proposalContracts = [];
      var interviewContracts = [];
      var inProcessContracts = [];
      var completedContracts = [];
      if (response && response.data) {
        const data = [...response.data];
        data && data.forEach(contract => {
          contract.showMoreDesc = false;
          contract.isShowDropdown = false;
          if (!contract.studentAccepted || !contract.consultantAccepted) {
            proposalContracts.push(contract);
            defaultProposalContracts.push(contract);
          }
          if (contract.studentAccepted && contract.consultantAccepted && !contract.signed) {
            interviewContracts.push(contract);
          }
          if (contract.signed && !contract.isCompleted) {
            inProcessContracts.push(contract);
          }
          if (contract.isCompleted) {
            completedContracts.push(contract);
          }
        })

        proposalContracts = proposalContracts ? proposalContracts.reverse() : [];
        // interviewContracts = interviewContracts ? interviewContracts.reverse() : [];
        // inProcessContracts = inProcessContracts ? inProcessContracts.reverse() : [];
        // completedContracts = completedContracts ? completedContracts.reverse() : [];

        var proposalContractsConsultantAccepted = [];
        var proposalContractsStudentAccepted = [];

        proposalContracts.forEach(contract => {
          if (!contract.consultantAccepted && contract.studentAccepted) {
            proposalContractsStudentAccepted.unshift(contract);
          }
          if (contract.consultantAccepted && !contract.studentAccepted) {
            proposalContractsConsultantAccepted.unshift(contract);
          }
        })

        this._isMounted && this.setState({
          defaultProposalContracts,
          proposalContracts,
          proposalContractsFiltered: proposalContracts,
          inProcessContracts,
          inProcessContractsFiltered: inProcessContracts,
          interviewContracts,
          interviewContractsFiltered: interviewContracts,
          completedContracts,
          completedContractsFiltered: completedContracts,
          proposalContractsConsultantAccepted,
          proposalContractsStudentAccepted
        });
      }
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  acceptContract = () => {
    var { proposalContracts, coverLetter, coverLetterMinLength, consultationId, translationService } = this.state;
    const id = this.state.contractId;
    if (!coverLetter || coverLetter.trim().length < coverLetterMinLength) {
      this.setState({ isInvalidSubmit: true });
    } else {
      const spinnerId = uuid();
      this.props.addPartialViewSpinner(spinnerId);

      ApiService.acceptContractConsultant(consultationId, coverLetter).then(() => {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_ACCEPTED"));
        this.props.removePartialViewSpinner(spinnerId);
        this.getConsultantContracts();
        proposalContracts = proposalContracts.filter(contract => contract.id !== id);
        this.setState({ proposalContracts, showModal: false });
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  declineContract = (id) => {
    const { proposalContracts, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.declineContractConsultant(id).then(response => {
      if (response) {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
        this.props.removePartialViewSpinner(spinnerId);
        let proposalContractsStudentAccepted = [];

        let filteredProposalContracts = proposalContracts.filter(contract => contract.id !== id);
        filteredProposalContracts.forEach(contract => {
          if (!contract.consultantAccepted && contract.studentAccepted) {
            proposalContractsStudentAccepted.push(contract);
          }
        });

        this.setState({
          proposalContracts: filteredProposalContracts,
          proposalContractsFiltered: filteredProposalContracts,
          proposalContractsStudentAccepted,
        });
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  openOrCloseModal = (contract) => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal, contractId: contract.id, consultationId: contract.consultationId, contract });
  }

  redirectToConsultationContracts = (id) => {
    this.props.setConsultationContractBackUrl(this.props.location.pathname);
    this.props.history.push(`/${this.props.language}/${CONSULTANT_KEY}/${CONSULTANT_CONSULTATION_CONTRACTS_KEY}/${id}`)
  }

  onChangeShowMoreDesc = (index, array, event) => {
    const contractArray = [...this.state[array]];
    const contractElem = contractArray[index];
    contractElem.showMoreDesc = !contractElem.showMoreDesc
    contractArray[index] = contractElem;
    this.setState({ [array]: contractArray });
    event.stopPropagation();
  }

  filterContract = (filteredArray, defaultArray, name) => {
    if (filteredArray !== null) {
      this.setState({ [name]: filteredArray });
    } else {
      this.setState({ [name]: this.state[defaultArray] });
    }
  }

  filterProposalContracts = (filteredArray) => {
    const { defaultProposalContracts } = this.state;
    var proposalContractsConsultantAccepted = [];
    var proposalContractsStudentAccepted = [];
    if (filteredArray !== null) {
      filteredArray.forEach(contract => {
        if (!contract.consultantAccepted && contract.studentAccepted) {
          proposalContractsStudentAccepted.unshift(contract);
        }
        if (contract.consultantAccepted && !contract.studentAccepted) {
          proposalContractsConsultantAccepted.unshift(contract);
        }
      })
      this.setState({ proposalContractsStudentAccepted, proposalContractsConsultantAccepted });
    } else {
      defaultProposalContracts.forEach(contract => {
        if (!contract.consultantAccepted && contract.studentAccepted) {
          proposalContractsStudentAccepted.unshift(contract);
        }
        if (contract.consultantAccepted && !contract.studentAccepted) {
          proposalContractsConsultantAccepted.unshift(contract);
        }
      })
      this.setState({ proposalContractsStudentAccepted, proposalContractsConsultantAccepted });
    }
  }

  onChangeIsShowDropdown = (index, array, event) => {
    const contractArray = [...this.state[array]];
    const contractElem = contractArray[index];
    contractElem.isShowDropdown = !contractElem.isShowDropdown
    contractArray[index] = contractElem;
    this.setState({ [array]: contractArray });
    event.stopPropagation();
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
  }

  render() {
    const {
      translationService,
      key,
      showModal,
      coverLetterMinLength,
      coverLetterMaxLength,
      coverLetter,
      isInvalidSubmit,
      proposalContractsFiltered,
      interviewContractsFiltered,
      inProcessContractsFiltered,
      completedContractsFiltered,
      proposalContracts,
      inProcessContracts,
      interviewContracts,
      completedContracts,
      contract,
      proposalContractsConsultantAccepted,
      proposalContractsStudentAccepted
    } = this.state;

    const { user } = this.props;

    return (
      translationService ? <Auxiliary>
        {
          showModal ?
            <ModalComponent
              title={contract ? contract.consultation.name : ''}
              contentClassName="resource-modal"
              isLarge="xl"
              actionButtonTitle={translationService.translate("TR_CONFIRM")}
              cancel={() => this.openOrCloseModal(contract)}
              onSubmit={this.acceptContract}
            >
              <div className="d-flex resource-modal">
                <div className="tab-content scroll" role="tablist">
                  <div id="pane-image" className="card tab-pane fade show active h-100 border-0" role="tabpanel"
                    aria-labelledby="tab-image">
                    <div id="collapse-image" className="collapse show" role="tabpanel" aria-labelledby="heading-image">
                      <div className="card-body">
                        {
                          contract.consultation?.description ?
                            <p className='word-break-break-word'>{contract.consultation.description}</p>
                            : null
                        }
                        {/* {
                          contract.consultation?.description ?
                            <Auxiliary>
                              <div>
                                <div className='d-flex align-items-baseline'>
                                  <label className="mt-1 d-block">{translationService.translate("TR_CREATOR")}:</label>
                                  <p className='word-break-break-word'>&nbsp; {contract.consultation.studentUserName}</p>
                                </div>
                                <div className='d-flex align-items-baseline'>
                                  <label className="mt-1 d-block">{translationService.translate("TR_DESCRIPTION")}:</label>
                                  <p className='word-break-break-word'>&nbsp; {contract.consultation.description}</p>
                                </div>
                              </div>
                              <hr />
                            </Auxiliary>
                            : null
                        } */}
                        <label className="mt-4 d-block">{`${translationService.translate("TR_COVER_LETTER")}*`}</label>
                        <small className="m-0 d-block">{translationService.translate("TR_COVER_LETTER_INFO")}</small>
                        <Textarea
                          rows="6"
                          id="coverLetter"
                          min={coverLetterMinLength}
                          max={coverLetterMaxLength}
                          isInvalidField={isInvalidSubmit && coverLetter.trim().length <= coverLetterMinLength}
                          name="coverLetter"
                          blockClassName="mt-2 mb-1"
                          textAreaClassName="pr--5"
                          value={coverLetter}
                          onChange={(event) => this.onChange(event, coverLetterMaxLength)}
                          isInvalidSubmit={isInvalidSubmit}
                        />
                        <small className="m-0"><i>{translationService.translate("TR_MIN_FIFTY_STYMOLS")}</i></small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalComponent>
            : null
        }
        <div className="container container-dashboard--instructor">
          <div className="row">
            <div className="col-12">
              {
                user && (user.userTypeId === CONSULTANT_USER_TYPE || user.userTypeId === INSTRUCTOR_USER_TYPE) ?
                  <Auxiliary>
                    <strong className="section-title">{translationService.translate("TR_CONSULTANT_INFORMATION")}</strong>
                    <hr />
                  </Auxiliary>
                  : null
              }
              <div className="tab-title-group p-0">
                <Tabs
                  activeKey={key}
                  onSelect={key => this.setState({ key })}
                >
                  <Tab eventKey="proposal" title={translationService.translate("TR_PROPOSAL")}>
                    <Proposal
                      proposalContracts={proposalContractsFiltered}
                      proposalContractsConsultantAccepted={proposalContractsConsultantAccepted}
                      proposalContractsStudentAccepted={proposalContractsStudentAccepted}
                      contracts={proposalContracts}
                      filterContract={this.filterProposalContracts}
                      openOrCloseModal={this.openOrCloseModal}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                      declineContract={this.declineContract}
                      isStudent={false}
                    />
                  </Tab>
                  <Tab eventKey="interview" title={translationService.translate("TR_INTERVIEW")}>
                    <Interview
                      interviewContracts={interviewContractsFiltered}
                      contracts={interviewContracts}
                      filterContract={this.filterContract}
                      redirectToConsultationContracts={this.redirectToConsultationContracts}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                    />
                  </Tab>
                  <Tab eventKey="in-process" title={translationService.translate("TR_IN_PROCESS")}>
                    <InProcess
                      inProcessContracts={inProcessContractsFiltered}
                      contracts={inProcessContracts}
                      filterContract={this.filterContract}
                      redirectToConsultationContracts={this.redirectToConsultationContracts}
                      onChangeIsShowDropdown={this.onChangeIsShowDropdown}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                    />
                  </Tab>
                  <Tab eventKey="completed" title={translationService.translate("TR_COMPLETED")}>
                    <Completed
                      completedContracts={completedContractsFiltered}
                      contracts={completedContracts}
                      filterContract={this.filterContract}
                      onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                      redirectToConsultationContracts={this.redirectToConsultationContracts}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Auxiliary> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user,
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  setConsultationContractBackUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(ForConsultantView);

