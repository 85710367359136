import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "./../../Services/translationService";
import ApiService from "./../../Services/apiService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "./../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import { ERROR_KEY, NUMBER_KEY } from "../../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import MainService from "../../Services/mainService";
import moment from "moment";
import {
	ORGANIZATION_KEY,
	PAYMENT_KEY,
	SCHEDULES_KEY,
} from "../../Constants/urlKeys";
import ArrowBackSvg from "./../../Components/Svg/arrowBackSvg";

const PaymentSchedule = props => {
	const { innerPaymentId } = props.match.params;
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);
	const [scheduleData, setScheduleData] = useState(null);

	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPaymentSchedule();
	}, [innerPaymentId]);

	const handlePageClick = event => {
		setActivePageNumber(event.selected);
	};

	const getPaymentSchedule = () => {
		if (!innerPaymentId) {
			return false;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentSchedule(innerPaymentId)
			.then(resposne => {
				if (resposne && resposne.data) {
					setScheduleData(resposne.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		props.history.push(
			`/${language}/${ORGANIZATION_KEY}/${PAYMENT_KEY}/${SCHEDULES_KEY}`,
		);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center">
						<Link
							to={`/${language}/${ORGANIZATION_KEY}/${PAYMENT_KEY}/${SCHEDULES_KEY}`}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						<h2>{translationService.translate("TR_PAYMENT_SCHEDULE")}</h2>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<hr />
				</div>
			</div>
			<div className="row">
				{scheduleData ? (
					<>
						<div className="col-12">
							{typeof scheduleData.dueAmount === NUMBER_KEY ? (
								<div className="d-flex justify-content-between mb-2">
									<strong>
										{translationService.translate("TR_DUE_AMOUNT")}
									</strong>
									<span>{scheduleData.dueAmountAsString}</span>
								</div>
							) : null}

							{scheduleData.leftToPay ? (
								<div className="d-flex justify-content-between mb-2">
									<strong className="">
										{translationService.translate("TR_LEFT_TO_PAY")}{" "}
									</strong>
									<span>{scheduleData.leftToPayAsString}</span>
								</div>
							) : null}

							{scheduleData.paidAmount ? (
								<div className="d-flex justify-content-between mb-2">
									<strong className="">
										{translationService.translate("TR_PAID_AMOUNT")}{" "}
									</strong>
									<span>{scheduleData.paidAmountAsString}</span>
								</div>
							) : null}

							{scheduleData.lastPaymentDate ? (
								<div className="d-flex justify-content-between mb-2">
									<strong>
										{translationService.translate("TR_LAST_PAYMENT_DATE")}
									</strong>
									<span>
										{moment(
											MainService.convertUTCDateToLocalDate(
												new Date(scheduleData.lastPaymentDate),
											),
										).format("ll (HH:mm)")}
									</span>
								</div>
							) : null}

							{scheduleData.futurePaymentDate ? (
								<div className="d-flex justify-content-between mb-2">
									<strong>
										{translationService.translate("TR_NEXT_PAYMENT_DATE")}
									</strong>
									<span>
										{moment(
											MainService.convertUTCDateToLocalDate(
												new Date(scheduleData.futurePaymentDate),
											),
										).format("ll (HH:mm)")}
									</span>
								</div>
							) : null}
						</div>
						{scheduleData.payments && scheduleData.payments.length ? (
							<div className="col-12 mb-5 mt-3 mindayal-admin-table">
								<table className="table">
									<thead>
										<tr>
											<th>{translationService.translate("TR_AMOUNT")}</th>
											<th>{translationService.translate("TR_PAYMENT_DATE")}</th>
										</tr>
									</thead>
									<tbody>
										{scheduleData.payments.map((payment, index) => {
											return (
												<tr key={index}>
													<td className="cursor-default">
														{payment.amountAsString}
													</td>
													<td className="cursor-default">
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(payment.paymentDate),
															),
														).format("ll HH:mm")}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : null}
					</>
				) : (
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p className="col-12">
								{translationService.translate("TR_NO_DATA")}
							</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
			{pagination ? (
				<div className="my-4">
					<div className="row">
						<div className="col-12">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(PaymentSchedule);
