import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../Services/translationService";
import { COURSE_KEY } from "../../Constants/urlKeys";
import ViewSvg from "../Svg/viewSvg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import GameSvg from "../../Components/Svg/gameSvg";
import GamificationItemsComponent from "../../OrganizationPages/Gamification/Components/Modals/GamificationItemsComponent";
import {
	ERROR_KEY,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
} from "../../Constants/mainKeys";
import GamifyApiService from "../../Services/gamifyApiService";
import AlertService from "../../Services/alertService";
import ReactPlayer from "react-player";

const OrganizationStudentCourseBlock = props => {
	const courseData = props.course;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);

	const [translationService, setTranslationService] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [gamifyData, setGamifyData] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getGamifyDataByEntity = (courseData, entityType) => {
		if (!courseData || !entityType || isLoading) {
			return false;
		}
		setIsLoading(true);
		const data = {
			entityId: courseData.id.toString(),
			entityTypeId: entityType,
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					setGamifyData(response.data);
				}
				setIsLoading(false);
			})
			.catch(error => getFail(error));
	};

	const cancel = () => {
		setGamifyData(null);
		setIsLoading(false);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		setIsLoading(false);
	};

	return translationService && courseData ? (
		<div
			className={`course-wrapper--instructor d-flex flex-column justify-content-between`}>
			<GamificationItemsComponent
				entityData={courseData}
				gamifyData={gamifyData}
				entityTypeId={GAMIFY_COURSE_ENTITY_TYPE_ID}
				cancel={cancel}
			/>

			<div className="course-image-wrapper--instructor d-flex flex-column justify-content-between">
				<div className="course-button-group" style={{ top: 0 }}>
					{user && user.isGamifyAvailable ? (
						<Link
							className="course-gamify-button mt-0 mb-1"
							to="#"
							title={translationService.translate("TR_GAMIFICATION_ICON")}
							onClick={() =>
								getGamifyDataByEntity(courseData, GAMIFY_COURSE_ENTITY_TYPE_ID)
							}>
							{isLoading ? (
								<div
									className="spinner-border text-success"
									style={{ width: "15px", height: "15px", borderWidth: "1px" }}
								/>
							) : (
								<GameSvg />
							)}
						</Link>
					) : null}
					{/* <Link
          className="course-view-button mt-0"
          to={`/${language}/${COURSE_KEY}/${courseData.id}`}
          title={translationService.translate("TR_VIEW")}
        >
          <ViewSvg />
        </Link> */}
				</div>
				{courseData.videoLink ? (
					<div
						className="course-media-wrapper w-100"
						style={{ height: "180px" }}>
						<ReactPlayer
							className="course-video-link"
							url={courseData.videoLink}
							config={{
								youtube: {
									playerVars: { showinfo: 0, controls: 1 },
								},
								facebook: {
									appId: "12345",
								},
							}}
						/>
					</div>
				) : (
					<div
						style={{ backgroundImage: `url(${courseData.imagePath})` }}
						className="course-image"
					/>
				)}
			</div>
			<div className="course-body-wrapper--instructor border-top">
				<div className="p-2 ">
					<h6 className="course-name--instructor" title={courseData.name}>
						{courseData.name}
					</h6>
					<Auxiliary>
						<hr />
						<div className="progress mt-3 mb-2">
							<div
								className="progress-bar"
								style={{ width: `${courseData.completionPercentage}%` }}
								role="progressbar"
								aria-valuenow="75"
								aria-valuemin="0"
								aria-valuemax="100"></div>
						</div>
						<div className="text-right">
							<p className="red-color font-default">
								{translationService.translate("TR_COMPLETED")}:{" "}
								<span className="font-bold">
									{courseData.completionPercentage} %
								</span>
							</p>
						</div>
					</Auxiliary>
					<button
						type="button"
						className={`mindalay--btn-primary-outline mt-3 w-100 mindalay--btn-small`}
						onClick={() =>
							props.history.push(`/${language}/course/${courseData.id}`)
						}>
						{translationService.translate("TR_VIEW")}
					</button>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(OrganizationStudentCourseBlock);
