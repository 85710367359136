import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'react-uuid';
import { CONSULTATION_CHAT_TYPE_KEY, ERROR_KEY, TICKET_CHAT_TYPE_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import MainService from '../../Services/mainService';
import TranslationService from '../../Services/translationService';
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import consultationSvg from "../../assets/icons/notification-icons/consultation.svg";
import complaintSvg from "../../assets/icons/notification-icons/complaint.svg";
import NoDataImage from '../../assets/images/illustrations/nodata.svg'
import { CHAT_KEY, CONSULTANT_CONSULTATION_CONTRACTS_KEY, CONSULTANT_KEY, MESSAGES_KEY, STUDENT_CONSULTATION_CONTRACTS_KEY, TICKET_KEY } from '../../Constants/urlKeys';

const AllMessages = (props) => {

  var ticketChatType = TICKET_CHAT_TYPE_KEY;
  var consultationChatType = CONSULTATION_CHAT_TYPE_KEY;

  const dispatch = useDispatch();

  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);

  const [messages, setMessages] = useState([]);
  const [translationService, setTranslationService] = useState(null);
  const [messagesCount, setMessagesCount] = useState(10);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getAllMessagesData();
  }, []);

  const getAllMessagesData = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId))
    ApiService.getAllMessagesData().then(response => {
      dispatch(removePageSpinner(spinnerId));
      setMessages(response.data);
    }).catch(error => getFail(error, spinnerId));
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
  }

  const redirectToCurrentMessagePage = (message) => {
    if (!message) { return; }
    if (message.chatType === ticketChatType) {
      props.history.push(`/${language}/${TICKET_KEY}/${CHAT_KEY}/${message.entityId}`);
    }
    if (message.chatType === consultationChatType) {
      if (message.isStudent) {
        props.history.push(`/${language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${message.entityId}/${MESSAGES_KEY}`)
      }
      if (!message.isStudent) props.history.push(`/${language}/${CONSULTANT_KEY}/${CONSULTANT_CONSULTATION_CONTRACTS_KEY}/${message.entityId}/${MESSAGES_KEY}`)
    }
  }

  return translationService && messages ?
    <section className="m-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <strong className="section-title">{translationService.translate("TR_MESSAGES")}</strong>
            <hr />
          </div>
          {
            messages && messages.length ?
              messages.map((message, index) => {
                return (messagesCount > index) ? <div
                  key={index}
                  className={`col-12 my-2 cursor-pointer`}
                  onClick={() => redirectToCurrentMessagePage(message)}
                >
                  <div className="card mindalay--card">
                    <div className="card-body">
                      <div className="notification-title">
                        {
                          (() => {
                            switch (message.chatType) {
                              case ticketChatType: // ticket 
                                return <img src={complaintSvg} alt="/" />
                              case consultationChatType: // consultation 
                                return <img src={consultationSvg} alt="/" />
                              default:
                                break
                            }
                          })()
                        }
                        <span className="ml-2">{message.title}</span>
                      </div>
                      <p>
                        {message.message}
                      </p>
                      <span className="consultation-date">{moment(MainService.convertUTCDateToLocalDate(new Date(message.time))).startOf('minute').fromNow()}</span>
                    </div>
                  </div>
                </div> : null
              })
              : <div className="no-data-container">
                <div className="no-data-wrapper">
                  <p>{translationService.translate("TR_NO_MESSAGES")}</p>
                  <img src={NoDataImage} alt="/" />
                </div>
              </div>
          }
          <div className="col-12">
            <div className="row">
              <div className="col-12 mt-3">
                {
                  messages && (messages.length > messagesCount)
                    ? <button type="button" className="mindalay--btn-dark w-100" onClick={() => setMessagesCount(messagesCount + 10)}>
                      {translationService.translate("TR_SHOW_MORE")}
                    </button> : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    : null
}

export default withRouter(AllMessages);
