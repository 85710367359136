import { GET_POTENTIAL_CONSULTATIONS_COUNT_REQUEST } from "../../Constants/reduxRequestKeys";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../Services/apiService";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from "../Actions/spinner";
import AlertService from "../../Services/alertService";
import { GET_POTENTIAL_CONSULTATIONS_COUNT_SUCCESS } from "../../Constants/reduxKeys";
import { ERROR_KEY } from "../../Constants/mainKeys";

export default function* watcher() {
  yield takeLatest(GET_POTENTIAL_CONSULTATIONS_COUNT_REQUEST, getPotentialconsultationsCount);
};

function* getPotentialconsultationsCount() {
  const spinnerId = uuid();
  yield put(addPageSpinner(spinnerId));
  try {
    const potentialconsultationsCount = yield call(ApiService.getPotentialconsultationsCount)
    yield put({
      type: GET_POTENTIAL_CONSULTATIONS_COUNT_SUCCESS,
      payload: potentialconsultationsCount
    });
    yield put(removePageSpinner(spinnerId));
  } catch (error) {
    yield put(removePageSpinner(spinnerId));
    AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }
}