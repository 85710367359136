import React, { Component } from 'react';
import { Tab } from 'bootstrap';
import { Tabs } from 'react-bootstrap';
import BecomeConsultant from './becomeConsultant';
import Textarea from '../../Components/Inputs/textArea';
import ActionButton from '../../Components/ActionButton/actionButton';
import { connect } from 'react-redux';
import TranslationService from '../../Services/translationService';
import Input from '../../Components/Inputs/input';
import { CONSULTANT_USER_TYPE, INSTRUCTOR_USER_TYPE } from '../../Constants/mainKeys';
import ApiService from '../../Services/apiService';
import { Link } from 'react-router-dom';
import CloseSvg from '../../Components/Svg/closeSvg';
import { PURE_LAYOUT_BACK_KEY } from './../../Constants/mainKeys';

const WAIT_INTERVAL = 500;

class BecomeConsultantView extends Component {
  _isMounted = false;
  userTypeId = this.props.user.userTypeId;

  constructor(props) {
    super(props);
    this.state = {
      key: "instructor",
      translationService: null,
      specialty: "",
      bio: "",
      failedFields: null,
      isInvalidSubmit: false,
      showBecomeConsultantForm: false,
      searchResult: [],
      isVisible: false,
      cursor: 0,
      activeSearchElementPosition: 0,
      specialties: [],
      bioMinLength: 50,
      currentSpecialtyMaxLength: 100,
      specialtyMaxLength: 1000,
      bioMaxLength: 2000
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this._isMounted = false;
    localStorage.removeItem(PURE_LAYOUT_BACK_KEY)
  }

  shouldComponentUpdate(nextProps,) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    const failedFields = this.removeFailedFields(event.target.name);
    this.setState({ ...this.state, [event.target.name]: event.target.value, failedFields }, () => {
      if (!this.state.bio && !this.state.specialty) {
        this.setState({ showBecomeConsultantForm: false });
      }
    });
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { isVisible } = this.state;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && isVisible) {
      this.setState({ isVisible: false });
    }
  }

  onFocus = () => {
    this.getSpecialtyKeywords();
  }

  getSpecialtyKeywords = () => {
    var { specialty } = this.state;
    specialty = specialty.trim();
    if (specialty.length > 1) {
      ApiService.getSpecialtyKeywords(specialty).then(response => {
        if (response.data) {
          const data = [...response.data];
          data.unshift(specialty);
          this.setState({ searchResult: data, isVisible: true })
        } else {
          const data = [];
          data.unshift(specialty);
          this.setState({ searchResult: data });
        }
      })
    }
  }

  changeSpecialty = (event, maxLength = null) => {
    var { searchResult } = this.state;
    if (maxLength && maxLength < event.target.value.length) { return; }
    const failedFields = this.removeFailedFields(event.target.name);
    if (!event.target.value) {
      this.setState({ isVisible: false });
    }
    if (!searchResult.length) {
      searchResult.unshift(event.target.value);
      this.setState({ searchResult });
    } else {
      searchResult[0] = event.target.value;
      this.setState({ searchResult });
    }
    clearTimeout(this.timer);
    this.setState({
      failedFields,
      [event.target.name]: event.target.value,
    })
    this.timer = setTimeout(this.getSpecialtyKeywords, WAIT_INTERVAL)
  }

  handleKeyDown = (event, array) => {
    var { cursor, activeSearchElementPosition, isVisible, specialties, specialty } = this.state;
    this.setState({ isVisible: true });
    var searchBlock = document.getElementById("scrollable");
    var searchBlockHeight = searchBlock?.offsetHeight;
    var currentElementHeight = event.target?.offsetHeight;
    var cursorNewIndex = 0;
    if (event.keyCode === 38 && cursor > 0) {
      cursorNewIndex = cursor - 1;
      this.changeCursorPosition(cursorNewIndex);
      if (activeSearchElementPosition -= currentElementHeight > 0) {
        this.setState({ activeSearchElementPosition: activeSearchElementPosition -= currentElementHeight })
      }
      if (activeSearchElementPosition + currentElementHeight < searchBlockHeight) {
        searchBlock.scrollBy({ top: -currentElementHeight, behavior: "smooth" });
      }
    }
    if (event.keyCode === 40 && cursor < array.length - 1) {
      cursorNewIndex = cursor + 1;
      this.changeCursorPosition(cursorNewIndex);
      this.setState({ activeSearchElementPosition: activeSearchElementPosition += currentElementHeight })

      if (activeSearchElementPosition + currentElementHeight > searchBlockHeight) {
        searchBlock.scrollBy({ top: currentElementHeight, behavior: "smooth" })
      }
    }
    if (event.keyCode === 13 && isVisible) {
      if (specialties.length) {
        const isExist = specialties.find(currentSpecialty => currentSpecialty === array[cursor]);
        if (!isExist && (array[cursor] || specialty.trim())) {
          this.addSpecialty(specialties, array[cursor] || specialty);
        }
      } else { this.addSpecialty(specialties, array[cursor]) }
    }
  }

  addSpecialty = (specialties, keyword) => {
    const { specialtyMaxLength } = this.state;
    if (!keyword || (keyword && !keyword.trim())) { return; }
    if (JSON.stringify(specialties).length + keyword.length <= specialtyMaxLength) {
      specialties.push(keyword.trim());
      this.setState({ specialties, specialty: "" });
    } else { return false; }
  }

  changeCursorPosition = (cursorNewIndex) => {
    this.setState({ cursor: cursorNewIndex })
  }

  setKeyword = (keyword) => {
    const { specialty, specialties } = this.state;
    if (specialties.length) {
      const isExist = specialties.find(currentSpecialty => currentSpecialty === keyword);
      if (!isExist && (keyword || specialty.trim())) {
        this.addSpecialty(specialties, keyword || specialty);
      }
    } else { this.addSpecialty(specialties, keyword) }
    this.setState({ specialty: "", isVisible: false })
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  removeSpecialty = (currentSpecialty) => {
    const { specialties, specialty } = this.state;
    var dublicateSpecialties = [...specialties];
    const index = dublicateSpecialties.indexOf(currentSpecialty);
    if (index > -1) {
      dublicateSpecialties.splice(index, 1);
    }
    if (!specialty || (specialty || !specialty.trim()) || !dublicateSpecialties || (dublicateSpecialties && !dublicateSpecialties.length)) {
      this.setState({ isInvalidSubmit: true, showBecomeConsultantForm: false })
    }
    this.setState({ specialties: dublicateSpecialties })
  }

  onSpecialtyFocus = () => {
    const { specialty } = this.state;
    if (specialty.trim().length > 1) {
      this.getSpecialtyKeywords();
    }
  }

  continue = () => {
    const { specialty, bio, specialties, bioMinLength } = this.state;
    if ((!specialties.length && !specialty.trim().length) || (!bio.trim() || bio.trim() && bio.trim().length < bioMinLength)) {
      this.setState({ isInvalidSubmit: true })
    } else {
      this.setState({
        showBecomeConsultantForm: true,
        key: "consultant",
        isInvalidSubmit: false,
      });
      if (!specialties.length && specialty.trim().length) {
        specialties.push(specialty);
        this.setState({ specialties, specialty: "" });
      }
    }
  }

  render() {
    const {
      translationService,
      key,
      isInvalidSubmit,
      specialty,
      bio,
      showBecomeConsultantForm,
      failedFields,
      searchResult,
      isVisible,
      cursor,
      specialties,
      bioMinLength,
      bioMaxLength,
      currentSpecialtyMaxLength
    } = this.state;

    return (
      translationService ? <div className="container">
        {
          !(this.userTypeId === INSTRUCTOR_USER_TYPE || this.userTypeId === CONSULTANT_USER_TYPE) ?
            <div className="tab-content tab-title-group">
              <Tabs
                activeKey={key}
                onSelect={key => this.setState({ key })}
              >
                <Tab eventKey="instructor" title={translationService.translate("TR_BIO")}>
                  <div className="container">
                    <div className="row mt-5" >
                      <div className="col-12">
                        <div className="position-relative" ref={this.setWrapperRef}>
                          <Input
                            type="text"
                            id="specialty"
                            name="specialty"
                            blockClassName="w-100"
                            value={specialty}
                            isInvalidField={isInvalidSubmit && (!specialties.length && !specialty.trim()) ? true : false}
                            inputClassName="pr--5"
                            infoText={translationService.translate("TR_TYPE_AND_CHOOSE_SPECIALTIES_INFO")}
                            labelValue={`${translationService.translate("TR_SPECIALTY_PROFESSION")} *`}
                            placeholder={`${translationService.translate("TR_TYPE")}...`}
                            onFocus={this.onSpecialtyFocus}
                            fieldLength={currentSpecialtyMaxLength}
                            onChange={event => this.changeSpecialty(event, currentSpecialtyMaxLength)}
                            onKeyDown={(event) => this.handleKeyDown(event, searchResult)}
                            failedFields={failedFields}
                          />
                          {
                            searchResult && searchResult.length && specialty.trim().length > 1 && isVisible ?
                              <ul className="autocomplete-block top-80" id="scrollable">
                                {
                                  searchResult.map((keyword, index) => {
                                    return <li
                                      key={index}
                                      className={`autocomplete-item pr-4 ${cursor === index ? "active-search" : ""}`}
                                      onClick={() => this.setKeyword(keyword)}
                                    >
                                      <span className={`${cursor === index ? "black-color" : ""}`}>
                                        {
                                          index ?
                                            <i className="fas fa-search bottom-4" />
                                            : null
                                        }</span>
                                      <Link to="#">{keyword}</Link >
                                    </li>
                                  })
                                }
                              </ul>
                              : null
                          }
                          {
                            specialties && specialties.length ?
                              <div>
                                <div className="selected-item-container my-2">
                                  {
                                    specialties.map((currentSpecialty, index) => {
                                      return <div key={index} className="selected-item-wrapper">
                                        <div className="selected-item word-break-break-word">
                                          {currentSpecialty}
                                        </div>
                                        <button type="button" className="mindalay--btn-blue" onClick={() => this.removeSpecialty(currentSpecialty)}>
                                          <CloseSvg />
                                        </button>
                                      </div>
                                    })
                                  }
                                </div>
                                <hr />
                              </div>
                              : null
                          }
                        </div>
                        <Textarea
                          id="bio"
                          name="bio"
                          rows="3"
                          labelClassName="big-sub-title mt-2"
                          infoText={translationService.translate("TR_TELL_ABOUT_YOURSELF")}
                          textAreaClassName="pr--5"
                          value={bio}
                          max={bioMaxLength}
                          labelValue={`${translationService.translate("TR_BIOGRAPHY")} *`}
                          isInvalidSubmit={isInvalidSubmit}
                          isInvalidField={isInvalidSubmit && (!bio || !bio.trim() || (bio.trim() && bio.trim().length < bioMinLength)) ? true : false}
                          onChange={event => this.onChange(event, bioMaxLength)}
                          failedFields={failedFields}
                        />
                        <small><i>{translationService.translate("TR_MIN_FIFTY_STYMOLS")}</i></small>
                        <ActionButton
                          type="submit"
                          name={translationService.translate("TR_CONTINUE")}
                          className="mindalay--btn-default mt-3"
                          clicked={this.continue}
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
                {
                  showBecomeConsultantForm ?
                    <Tab eventKey="consultant" title={translationService.translate("TR_CONSULTANT_INFORMATION")}>
                      <BecomeConsultant
                        specialty={specialties && specialties.length ? JSON.stringify(specialties) : JSON.stringify([specialty])}
                        bio={bio}
                        showBecomeConsultantForm={showBecomeConsultantForm}
                      />
                    </Tab>
                    : null
                }
              </Tabs>
            </div>
            : <BecomeConsultant />
        }
      </div> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  user: state.user.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BecomeConsultantView)

