import React from 'react';
import Loader from 'react-loader-spinner';

const TextSpinner = props => {
  return (
    props.spinner ? <div className={`center spinner ${props.className ? props.className : ""}`}>
      <Loader type="Oval" color={props.color ? props.color : "#32064A"} height={props.height ? props.height : 20} width={props.width ? props.width : 20} />
    </div>
      : null
  );
}

export default TextSpinner;