import { takeLatest, call, put } from "redux-saga/effects";
import ApiService from "../../Services/apiService";
import ResponseService from "../../Services/responseService";
import AlertService from "../../Services/alertService";
import { IdentityService } from "../../Services/identityService";
import { addButtonSpinner, removeButtonSpinner } from "../Actions/spinner";
import {
	ERROR_KEY,
	INVALID_USERNAME_OR_PASSWORD,
	REFRESH_TOKEN_KEY,
	SHOPPING_CART_PRODUCTS_IDS_KEYS,
	SUCCESS_KEY,
	TOKEN_KEY,
	WISH_LIST_PRODUCTS_IDS_KEYS,
	LANGUAGE_KEY,
	CURRENCY_KEY,
	DEFAULT_CURRENCY_KEY,
	CURRENCY_SYMBOL_KEY,
	DEFAULT_CURRENCY_SYMBOL_KEY,
	ADMIN_USER_TYPE,
} from "../../Constants/mainKeys";
import {
	USER_LOGIN_REQUEST,
	USER_REGISTRATION_REQUEST,
	CURRENT_USER_UPDATE_REQUEST,
	GET_PAGES_FROM_ORGANIZATION_USER_REQUEST,
	AUTH_LOGOUT_REQUEST,
} from "../../Constants/reduxRequestKeys";
import {
	AUTH_SUCCESS,
	CURRENT_USER_SUCCESS,
	AUTH_FAIL,
	CURRENT_USER_UPDATE_SUCCESS,
	CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT,
	REGISTRATION_FAIL,
	GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS,
	ADD_SETTINGS,
} from "../../Constants/reduxKeys";
import { DEFAULT_LANGUAGE_KEY } from "./../../Constants/mainKeys";

export default function* watcher() {
	yield takeLatest(USER_LOGIN_REQUEST, userLogin);
	yield takeLatest(USER_REGISTRATION_REQUEST, userRegistration);
	yield takeLatest(CURRENT_USER_UPDATE_REQUEST, userUpdate);
	yield takeLatest(GET_PAGES_FROM_ORGANIZATION_USER_REQUEST, getOrgUserPages);
}

function* getOrgUserPages(action) {
	const userTypeId = action.payload;
	try {
		const availablePages = yield call(
			ApiService.getPagesFromOrgUserByUserTypeId,
			userTypeId,
		);
		if (availablePages && availablePages.data && availablePages.data.length) {
			yield put({
				type: GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS,
				payload: availablePages.data,
			});
			window.location.href = "/";
		} else {
			yield put({
				type: AUTH_LOGOUT_REQUEST,
				payload: {},
			});
		}
	} catch (error) {
		AlertService.alert(
			AlertService.checkMessageType(error.respcode) || ERROR_KEY,
			error,
		);
	}
}

function* userLogin(action) {
	const { spinnerId, history } = action.payload;
	try {
		const formData = { ...action.payload.data };
		yield put(addButtonSpinner(spinnerId));
		const token = yield call(IdentityService.login, formData);
		const response = yield call(ResponseService.responseCheckIdentity, token);
		yield put({
			type: AUTH_SUCCESS,
			payload: {
				token,
				refreshToken: response.refreshToken,
			},
		});
		const user = yield call(ApiService.currentUser);
		if (user && user.data) {
			if (user.data.isoCode) {
				localStorage.setItem(CURRENCY_KEY, user.data.isoCode);
			} else {
				localStorage.setItem(CURRENCY_KEY, DEFAULT_CURRENCY_KEY);
			}
			if (user.data.currencySymbol) {
				localStorage.setItem(CURRENCY_SYMBOL_KEY, user.data.currencySymbol);
			} else {
				localStorage.setItem(CURRENCY_SYMBOL_KEY, DEFAULT_CURRENCY_SYMBOL_KEY);
			}
		}
		if (user && user.data && user.data.isOrganizationUser) {
			if (!user.data.defaultUserTypeIds) {
				const availablePages = yield call(ApiService.getPagesFromOrgUser);
				let { data } = availablePages;
				yield put({
					type: GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS,
					payload: data,
				});
			}
		}
		const wishListProductsByIds = localStorage.getItem(
			WISH_LIST_PRODUCTS_IDS_KEYS,
		);
		const shoppingCartProductsByIds = localStorage.getItem(
			SHOPPING_CART_PRODUCTS_IDS_KEYS,
		);
		if (
			user &&
			user.data &&
			!user.data.isOrganizationUser &&
			!user.data.isAdmin &&
			(wishListProductsByIds || shoppingCartProductsByIds)
		) {
			const wishListCourseIds = wishListProductsByIds
				? {
						isShoppingCart: false,
						courseIds: JSON.parse(wishListProductsByIds),
				  }
				: null;
			const shoppingCartCourseIds = shoppingCartProductsByIds
				? {
						isShoppingCart: true,
						courseIds: JSON.parse(shoppingCartProductsByIds),
				  }
				: null;
			const data = [wishListCourseIds, shoppingCartCourseIds];
			const wishListAndShoppingCartCounts = yield call(
				ApiService.wishListOrCartBulkcreate,
				data,
			);
			yield put({
				type: CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT,
				payload: {
					wishListCount: wishListAndShoppingCartCounts.data.wishListCount,
					shoppingCartCount:
						wishListAndShoppingCartCounts.data.shoppingCartCount,
				},
			});
		} else {
			yield put({
				type: CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT,
				payload: {
					wishListCount: user.data.wishListCount,
					shoppingCartCount: user.data.shoppingCartCount,
				},
			});
		}

		yield put({
			type: ADD_SETTINGS,
			payload: null,
		});

		yield put({
			type: CURRENT_USER_SUCCESS,
			payload: user.data,
		});

		history &&
			history.push(
				`/${
					localStorage.getItem(LANGUAGE_KEY)
						? localStorage.getItem(LANGUAGE_KEY)
						: DEFAULT_LANGUAGE_KEY
				}`,
			);

		yield put(removeButtonSpinner(spinnerId));
	} catch (error) {
		localStorage.removeItem(TOKEN_KEY);
		localStorage.removeItem(REFRESH_TOKEN_KEY);
		if (error && error === INVALID_USERNAME_OR_PASSWORD) {
			yield put({
				type: AUTH_FAIL,
				payload: error,
			});
		} else {
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		}
		yield put(removeButtonSpinner(spinnerId));
	}
}

function* userRegistration(action) {
	const { spinnerId } = action.payload;
	try {
		yield put(addButtonSpinner(spinnerId));
		const data = { ...action.payload.data };
		data.password = btoa(data.password);
		data.confirmPassword = btoa(data.confirmPassword);

		// const userRegistered = yield call(ApiService.registration, data);
		// yield put({
		//   type: USER_REGISTRATION_SUCCESS,
		//   payload: userRegistered
		// });

		yield call(ApiService.registration, data);
		yield userLogin({
			type: USER_LOGIN_REQUEST,
			payload: {
				spinnerId,
				data: {
					username: data.email,
					password: atob(data.password),
				},
			},
		});
	} catch (error) {
		yield put(removeButtonSpinner(spinnerId));
		// AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
		yield put({
			type: REGISTRATION_FAIL,
			payload: error,
		});
	}
}

function* userUpdate(action) {
	try {
		const user = { ...action.payload.data };
		const savedUserData = { ...user };
		delete user.avatarFilePath;
		const formData = new FormData();
		for (let i in user) {
			if (!user[i]) {
				delete user[i];
			} else {
				formData.append(`${i}`, user[i]);
			}
		}
		yield call(ApiService.userUpdate, formData);
		AlertService.alert(SUCCESS_KEY, action.payload.translationSuccessMessage);
		yield put({
			type: CURRENT_USER_UPDATE_SUCCESS,
			payload: savedUserData,
		});
	} catch (error) {
		AlertService.alert(
			AlertService.checkMessageType(error.respcode) || ERROR_KEY,
			error,
		);
	}
}
