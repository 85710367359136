import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import CategoriesCollapse from './Component/categoriesCollapse';
import uuid from 'react-uuid';
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import { TR_DELETE_MESSAGE_KEY } from "../../Constants/translationKeys";
import { COURSES_KEY, FILE_LIBRARY_CATEGORY_KEY, FORM_KEY, INSTRUCTOR_KEY } from '../../Constants/urlKeys';
import { ACTIVE_TAB_KEY, ERROR_KEY, SUCCESS_KEY, TR_DELETE_CONFIRM_MESSAGE_KEY, TR_NO, TR_YES } from "../../Constants/mainKeys";
import SmallQuestionMark from './../../Components/SmallQuestionMark/smallQuestionMark';
import HelpService from './../../Services/helpService';
import { compose } from 'redux';

class FileLibraryCategoryView extends Component {

  state = {
    fileCategories: [...this.props.fileCategories],
    translationService: null,
    spinnerId: uuid()
  };

  componentDidMount() {
    this.setTranslations();
  };

  componentDidUpdate() {
    this.setTranslations();
    if (this.props.fileCategories !== this.state.fileCategories) {
      this.setState({ fileCategories: this.props.fileCategories })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  delete = category => {
    var { translationService, spinnerId } = this.state;
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${category.name} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.addButtonSpinner(spinnerId);
      ApiService.categoryDelete(category.id).then(() => {
        AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DELETE_MESSAGE_KEY));
        this.props.getFileCategories();
      }).catch(error => this.deleteFail(null, error));
    }).catch(error => this.deleteFail(null, error));
  };

  getQuestionMarkData = (key) => {
    if (!key) { return; }
    if (HelpService.setPageName(this.props.location.pathname)) {
      this.props.getCurrentQuestionMarkData(HelpService.setPageName(this.props.location.pathname), key)
    }
  }

  deleteFail = (spinnerId, message) => {
    spinnerId && this.props.removeButtonSpinner(spinnerId);
    message && AlertService.alert((AlertService.checkMessageType(message.respcode) || ERROR_KEY), message);
  };

  render() {
    const { language } = this.props;
    const { translationService, fileCategories } = this.state;

    return translationService
      ? <div className="row mt-3">
        <div className="col-12">
          <p>{translationService.translate("TR_FILE_LIBRARY_INFO")}</p>
          <hr />
          <div className="d-flex align-items-center">
            <Link
              to={
                this.props.user.isOrganizationUser
                  ? `/${language}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}`
                  : `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}`
              }
              className="mindalay--btn-default"
              onClick={() => {
                localStorage.setItem(ACTIVE_TAB_KEY, "file-library-folder");
              }}
            >
              {translationService.translate("TR_CREATE_FOLDER")}
            </Link>
            <SmallQuestionMark
              id="folder_create"
              blockClassName="ml-2"
              onMouseEnter={() => this.getQuestionMarkData("folder_create")}
            />
          </div>
          <hr />
        </div>
        <div className="col-12">
          {
            fileCategories && fileCategories.length
              ? <CategoriesCollapse
                items={fileCategories}
                deleteCategory={this.delete}
              /> : null
          }
        </div>
      </div> : null;
  };
};

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user,
  currentQuestionMarkData: state.main.currentQuestionMarkData,
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  getCurrentQuestionMarkData
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(FileLibraryCategoryView);
