import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import uuid from "react-uuid";
import Input from "../../Components/Inputs/input";
import InputRadio from "../../Components/Inputs/inputRadio";
import Textarea from "../../Components/Inputs/textArea";
import AlertService from "../../Services/alertService";
import MainService from "../../Services/mainService";
import ApiService from "../../Services/apiService";
import UploadButton from "../../Components/UploadButton/uploadButton";
import InfoSvg from "../../Components/Svg/infoSvg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import CloseSvg from "../../Components/Svg/closeSvg";
import { Link } from "react-router-dom";
import { userUpdateSuccess } from "../../Store/Actions/user";
import { addPageSpinner, removePageSpinner, addButtonSpinner, removeButtonSpinner, } from "../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from "../../Constants/translationKeys";
import {
  CONSULTANT_USER_TYPE,
  ERROR_KEY,
  INSTRUCTOR_USER_TYPE,
  NUMBER_KEY,
  STRING_KEY,
  STUDENT_USER_TYPE,
  SUCCESS_KEY,
  VALID_IMAGE_TYPES_KEY
} from "../../Constants/mainKeys";
import AvatarEditor from 'react-avatar-editor';


const Profile = () => {

  const mainService = new MainService();
  const dispatch = useDispatch();

  const timeoutRef = useRef(null);
  const wrapperRef = useRef(null);

  const translations = useSelector(state => state.translation.translations);
  const user = useSelector(state => state.user.user);
  var editor = "";
  const [translationService, setTranslationService] = useState(null);
  const [instructorLibraryData, setInstructorLibraryData] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [instructorId, setInstructorId] = useState(null);
  const [specialty, setSpecialty] = useState('');
  const [secondSpecialty, setSecondSpecialty] = useState("");
  const [bio, setBio] = useState('');
  const [isInvalidSettitngSubmit, setiSInvalidSettitngSubmit] = useState(false);
  const [videoProfessionalism, setVideoProfessionalism] = useState(null);
  const [failedFields, setFailedFields] = useState(false);
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidconfirmPassword, setIsInvalidconfirmPassword] = useState(false);
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchKeywords, setShowSearchKeywords] = useState(false);
  const [specialties, setSpecialties] = useState([]);
  const [changes, setChanges] = useState(false);
  const [showChangesErrorMsg, setShowChangesErrorMsg] = useState(false);
  const [invalidFileFormatMsg, setInvalidFileFormatMsg] = useState("");
  // const [currencies, setCurrencies] = useState([]);
  // const [currencyId, setCurrencId] = useState(null);
  const nameMaxLength = 99;
  const passwordMinLength = 6;
  const passwordMaxLength = 100;
  const specialtyMaxLength = 1000;
  const bioMaxLength = 2000;
  const bioMinLength = 50;

  const [cursor, setCursor] = useState(0);
  const [allowOnsubmit, setAllowOnsubmit] = useState(true);
  const spinnerId = uuid();

  var [activeSearchElementPosition, setActiveSearchElementPosition] = useState(0);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    // getCurrencies();
    if (user && (user.userTypeId === INSTRUCTOR_USER_TYPE || user.userTypeId === CONSULTANT_USER_TYPE)) {
      getLibraryData();
      getInstructorOrConsultantData();
    }
    if (user && (user.userTypeId !== INSTRUCTOR_USER_TYPE || user.userTypeId !== CONSULTANT_USER_TYPE)) {
      getStudent();
    }
  }, [user]);

  useEffect(() => {
    if (allowOnsubmit) {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        if (specialty.trim().length > 1 && !secondSpecialty && showSearchKeywords) getSpecialtyKeywords();
      }, 500);
    }
  }, [specialty]);

  // const getCurrencies = () => {
  //   const spinnerId = uuid();
  //   dispatch(addPageSpinner(spinnerId));
  //   ApiService.getCurrency().then(response => {
  //     if (response.data) {
  //       const data = [...response.data];
  //       setCurrencies(data);
  //       const currentCurrency = localStorage.getItem(CURRENCY_KEY) ? localStorage.getItem(CURRENCY_KEY) : DEFAULT_CURRENCY_KEY;
  //       if (data && data.length) {
  //         data.forEach(currency => {
  //           if (currentCurrency === currency.isocode) {
  //             setCurrencId(currency.id);
  //           }
  //         })
  //       }
  //     }
  //     dispatch(removePageSpinner(spinnerId));
  //   }).catch(error => getFail(error, spinnerId))
  // }

  const chanegeSpecialty = (value) => {
    if (!searchResult.length) {
      searchResult.unshift(value);
      setSearchResult(searchResult);
    } else {
      searchResult[0] = value;
      setSearchResult(searchResult);
    }
    if (value.length <= 100) {
      setSpecialty(value);
      setShowSearchKeywords(true);
      setAllowOnsubmit(true);
    } else { return false; }
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
      setCursor(0);
      setActiveSearchElementPosition(0);
    }
  };

  const getSpecialtyKeywords = () => {
    ApiService.getSpecialtyKeywords(specialty).then(response => {
      if (response.data) {
        const data = [...response.data];
        data.unshift(specialty);
        setSearchResult(data);
        setIsVisible(true);
      } else {
        const data = [];
        data.unshift(specialty);
        setSearchResult(data);
      }
    }).catch(error => AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error))
  };

  const onSpecialtyFocus = () => {
    if (specialty.trim().length > 1) {
      getSpecialtyKeywords();
    }
  }

  const getInstructorOrConsultantData = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getCurrentInstructorData().then(response => {
      dispatch(removePageSpinner(spinnerId));
      if (response && response.data) {
        const instructorData = { ...response.data };
        setFirstName(user.firstname);
        setLastName(user.lastname);
        setEmail(user.email);
        setSpecialties(instructorData.specialty && MainService.isJson(instructorData.specialty) ? JSON.parse(instructorData.specialty) : []);
        setBio(instructorData.bio);
        setVideoProfessionalism(instructorData.videoProfessionalism);
        setInstructorId(instructorData.id);
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const getStudent = () => {
    setFirstName(user.firstname);
    setLastName(user.lastname);
    setEmail(user.email);
  }

  const getLibraryData = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getLibraryData().then(response => {
      dispatch(removePageSpinner(spinnerId));
      setInstructorLibraryData(response.data);
    }).catch(error => getFail(error, spinnerId));
  };

  const updateUserStorageData = useCallback(userData => {
    dispatch(userUpdateSuccess(userData));
  })

  const checkFieldValidation = (field, fieldName) => {
    const isValid = MainService.isValidField(field, fieldName);
    switch (fieldName) {
      case "firstName":
        checkIsinvalidFieldName(isValid, "isInvalidFirstName")
        break;
      case "lastName":
        checkIsinvalidFieldName(isValid, "isInvalidLastName")
        break;
      case "email":
        checkIsinvalidFieldName(isValid, "isInvalidEmail")
        break;
      default:
        break;
    }
  }

  const checkIsinvalidFieldName = (isValid, name) => {
    switch (name) {
      case "isInvalidFirstName":
        setIsInvalidFirstName(!isValid);
        break;
      case "isInvalidLastName":
        setIsInvalidLastName(!isValid);
        break;
      case "isInvalidEmail":
        setIsInvalidEmail(!isValid);
        break;
      default:
        break;
    }
  }

  const uploadFile = event => {
    const file = event.target.files[0];
    if (file) {
      setInvalidFileFormatMsg("");
      const fileName = file.name;
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        setUploadedFile(uploadedImage);
        setFile(file);
        setFileName(fileName);
        setShowChangesErrorMsg(false);
      }).catch(error => error && setInvalidFileFormatMsg(translationService.translate("TR_INVALID_FILE_FORMAT_EXC")));
    };
  }

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = () => {
    if (editor && file) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      var arr = croppedImg.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return {
        file: new File([u8arr], file.name, { type: mime }),
        base64: croppedImg,
      }
    } else { return false; }
  }

  const checkFieldValidationForPasswords = (fieldValue, fieldName) => {
    var isValid = MainService.isValidField(fieldValue, fieldName);
    isValid = fieldValue.length >= passwordMinLength && fieldValue.length <= passwordMaxLength;
    if ((password && confirmPassword) && (password !== confirmPassword)) {
      setMismatchPasswords(true);
      checkIsinvalidFieldNameForPasswords(false, "mismatchPasswords");
    } else setMismatchPasswords(false);
    switch (fieldName) {
      case "password":
        if (!password.length) {
          setIsInvalidPassword(false)
        } else {
          checkIsinvalidFieldNameForPasswords(isValid, "isInvalidPassword");
        }
        break;
      case "confirmPassword":
        if (!confirmPassword.length) {
          setIsInvalidconfirmPassword(false)
        } else {
          checkIsinvalidFieldNameForPasswords(isValid, "isInvalidconfirmPassword");
        }
        break;
      default:
        break;
    }
  }

  const checkIsinvalidFieldNameForPasswords = (isValid, name) => {
    switch (name) {
      case "isInvalidPassword":
        setIsInvalidPassword(!isValid);
        break;
      case "isInvalidconfirmPassword":
        setIsInvalidconfirmPassword(!isValid);
        break;
      default:
        break;
    }
  }

  const handleKeyDown = (event, array) => {
    setIsVisible(true);
    var searchBlock = document.getElementById("scrollable");
    var searchBlockHeight = searchBlock?.offsetHeight;
    var currentElementHeight = event.target?.offsetHeight;
    var cursorNewIndex = 0;
    if (event.keyCode === 38 && cursor > 0) {
      cursorNewIndex = cursor - 1;
      changeCursorPosition(cursorNewIndex, array);
      if (activeSearchElementPosition -= currentElementHeight > 0) {
        setActiveSearchElementPosition(activeSearchElementPosition -= currentElementHeight)
      }
      if (activeSearchElementPosition + currentElementHeight < searchBlockHeight) {
        searchBlock.scrollBy({ top: -currentElementHeight, behavior: "smooth" });
      }
    }
    if (event.keyCode === 40 && cursor < array.length - 1) {
      cursorNewIndex = cursor + 1;
      changeCursorPosition(cursorNewIndex, array);
      setActiveSearchElementPosition(activeSearchElementPosition += currentElementHeight);

      if (activeSearchElementPosition + currentElementHeight > searchBlockHeight) {
        searchBlock.scrollBy({ top: currentElementHeight, behavior: "smooth" })
      }
    }
    if (event.keyCode === 13 && isVisible) {
      if (specialties && specialties.length) {
        const isExist = specialties.find(currentSpecialty => currentSpecialty === array[cursor]);
        if (!isExist && (array[cursor] || specialty.trim())) {
          if ((array[cursor] && array[cursor].trim()) || (specialty && specialty.trim())) {
            addSpecialty(specialties, array[cursor] || specialty);
          }
        }
      } else { array[cursor].trim() && addSpecialty(specialties, array[cursor]) }
    }
  }

  const setKeyword = (keyword) => {
    if (specialties.length) {
      const isExist = specialties.find(currentSpecialty => currentSpecialty === keyword);
      if (!isExist) addSpecialty(specialties, keyword);
    } else { addSpecialty(specialties, keyword) }
  }

  const addSpecialty = (specialties, keyword) => {
    if (JSON.stringify(specialties).length + keyword.length <= specialtyMaxLength) {
      specialties?.push(keyword);
      setSpecialties(specialties);
      setSpecialty("");
    } else { return false; }
  }

  const removeSpecialty = (currentSpecialty) => {
    var dublicateSpecialties = [...specialties];
    const index = dublicateSpecialties.indexOf(currentSpecialty);
    if (index > -1) {
      dublicateSpecialties.splice(index, 1);
    }
    setSpecialties(dublicateSpecialties);
    setShowChangesErrorMsg(false);
    setChanges(true);
  }

  const changeCursorPosition = (cursorNewIndex, array) => {
    setCursor(cursorNewIndex);
  }

  const updateCurrentUserData = (event) => {
    event.preventDefault();
    if (!changes) {
      setShowChangesErrorMsg(true);
      return;
    }
    if (user && (user.userTypeId === INSTRUCTOR_USER_TYPE || user.userTypeId === CONSULTANT_USER_TYPE)) {
      if (!bio.trim() || (bio.trim() && bio.trim().length < bioMinLength) || (!specialties.length && (!specialty || !specialty.trim()))) {
        setiSInvalidSettitngSubmit(true);
        return false;
      } else {
        setiSInvalidSettitngSubmit(false);
      }
    }
    const form = {
      // image: file,
      image: handleSave()?.file ? handleSave()?.file : null,
      bio: bio.trim(),
      email,
      firstName,
      id: user.userTypeId !== STUDENT_USER_TYPE ? instructorId : 0,
      lastName,
      specialty: specialties && specialties.length ? JSON.stringify(specialties) : specialty && specialty.trim() ? JSON.stringify([specialty]) : null,
      videoProfessionalism: user.userTypeId === STUDENT_USER_TYPE ? null : videoProfessionalism,
      password: password ? btoa(password) : null,
      confirmPassword: confirmPassword ? btoa(confirmPassword) : null,
    };
    for (let i in form) {
      if (!form[i] && typeof form[i] !== NUMBER_KEY) { delete form[i] }
    }
    if (!isInvalidFirstName && !isInvalidLastName && !isInvalidEmail && !isInvalidPassword && !isInvalidconfirmPassword && changes) {
      if (!user.isOrganizationUser) {
        dispatch(addButtonSpinner(spinnerId));
        let formData = new FormData();
        for (let i in form) {
          formData.append(`${i}`, typeof form[i] === STRING_KEY ? form[i].trim() : form[i]);
        }
        ApiService.updateCurrentUserData(formData).then(response => {
          const userData = { ...user };
          if (response.data && handleSave()?.file && handleSave()?.base64) {
            userData.image = handleSave()?.file ? handleSave()?.file : null;
            userData.avatarFilePath = handleSave()?.base64 ? handleSave()?.base64 : null;
          }
          setFirstName(firstName?.trim());
          setLastName(lastName?.trim());
          setEmail(email?.trim());
          setBio(bio?.trim());
          userData.firstname = firstName?.trim();
          userData.lastname = lastName?.trim();
          userData.email = email?.trim();
          updateUserStorageData(userData);
          setChanges(false);
          setUploadedFile(null);
          setFile(null);
          setUploadedFile(null);
          setPassword("");
          setConfirmPassword("");
          AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
        }).catch(error => submitFail(spinnerId, error));
      } else {
        if (handleSave()?.file) {
          const data = {
            id: user.id,
            email: email.trim(),
            firstname: firstName.trim(),
            lastname: lastName.trim(),
            image: handleSave()?.file,
            // isActive: user.isActive,
            userTypeId: user.userTypeId,
          }
          let formData = new FormData();
          for (let i in data) {
            formData.append(`${i}`, typeof data[i] === STRING_KEY ? data[i].trim() : data[i]);
          }
          dispatch(addButtonSpinner(spinnerId));
          ApiService.updateOrgUserAvatar(formData).then((res) => {
            const userData = { ...user };
            if (res && res.data) {
              userData.avatarFilePath = res.data?.avatarFilePath;
            }
            // if (handleSave()?.file && handleSave()?.base64) {
            //   userData.image = handleSave()?.file ? handleSave()?.file : null;
            //   userData.avatarFilePath = handleSave()?.base64 ? handleSave()?.base64 : null;
            // }
            updateUserStorageData(userData);
            setChanges(false);
            setUploadedFile(null);
            setFile(null);
            setUploadedFile(null);
            dispatch(removeButtonSpinner(spinnerId));
            if (!password && !confirmPassword) AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
          }).catch(error => submitFail(spinnerId, error));
        }
        if (password && confirmPassword) {
          dispatch(addButtonSpinner(spinnerId));
          const dublicateForm = { ...form };
          for (let i in dublicateForm) {
            if (i !== "password" && i !== "confirmPassword") { delete dublicateForm[i] }
          }
          ApiService.updateOrgUserPassword(form).then(() => {
            setPassword("");
            setConfirmPassword("");
            dispatch(removeButtonSpinner(spinnerId));
            if (!handleSave()?.file) AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
          }).catch(error => submitFail(spinnerId, error));
        }
      }
    }
  }

  // const changeCurrency = (selectedItem) => {
  //   if (!selectedItem || !selectedItem.id) { return; }
  //   const spinnerId = uuid();
  //   ApiService.changeCurrency(selectedItem.id).then(() => {
  //     setCurrencId(selectedItem.id);
  //     localStorage.setItem(CURRENCY_KEY, selectedItem.isocode);
  //     AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CURRENCY_SUCCESSFULLY_UPDATED"));
  //   }).catch(error => getFail(error, spinnerId));
  // }

  const removeFailedFields = name => {
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    setFailedFields(failedFields);
  };

  const submitFail = (spinnerId, error) => {
    spinnerId && dispatch(removePageSpinner(spinnerId));
    spinnerId && dispatch(removeButtonSpinner(spinnerId));
    setFailedFields(error);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
  }

  const cannelUploadFile = () => {
    setFile(null);
    setUploadedFile(null);
  }

  const isInstructor = user && user.userTypeId === INSTRUCTOR_USER_TYPE ? true : false;
  const isConsultant = user && user.userTypeId === CONSULTANT_USER_TYPE ? true : false;

  return translationService ?
    <div className="container mt-4 ">
      <div className="profile-tabs tabs-wrapper">
        <div className="col-12 p-0">
          <h2 className="section-title">{translationService.translate("TR_EDIT_PROFILE")}</h2>
          <hr />
        </div>
        {/* Global form */}
        <form onChange={() => setChanges(true)}>
          <div className="mt-4">
            <h2 className="content-title p-0">{translationService.translate("TR_PROFILE_PICTURE")}</h2>
            {/* <form> */}
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                {
                  uploadedFile ?
                    <div className="user-profile-image-container">
                      <AvatarEditor
                        ref={setEditorRef}
                        image={uploadedFile}
                        width={300}
                        height={250}
                        border={30}
                        color={[0, 0, 0, 0.2]} // RGBA
                        scale={1.2}
                        rotate={0}
                      />
                    </div>
                    : null
                }
                <div className="user-profile-image-wrapper">
                  <p>{translationService.translate("TR_USER_AVATAR_IMAGE")}</p>
                  {
                    !uploadedFile ?
                      <div className="user-profile-image-container">
                        <div className="user-profile-image" style={{ backgroundImage: `url(${uploadedFile || user.avatarFilePath})` }}></div>
                      </div>
                      : null
                  }
                  <div className="form-group position-relative">
                    <UploadButton
                      textInputClasses="pl-120"
                      placeholder={fileName}
                      text={translationService.translate("TR_UPLOADAVATAR")}
                      clicked={uploadFile}
                    />
                    {
                      file && user.avatarFilePath ?
                        <button
                          type="button"
                          className="mindalay--btn-default mt-2"
                          onClick={cannelUploadFile}
                        >{translationService.translate("TR_CANCEL")}</button>
                        : null
                    }
                  </div>
                  {
                    invalidFileFormatMsg ?
                      <small className="red-color">{invalidFileFormatMsg}</small>
                      : null
                  }
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="mt-4">
            <h2 className="content-title p-0">{translationService.translate("TR_PROFILE_SETTINGS")}</h2>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <Input
                      type="text"
                      id="firstName"
                      name="firstName"
                      inputClassName={`pr--4 ${user.isOrganizationUser ? "disabled-input" : ""}`}
                      value={firstName}
                      fieldLength={user.isOrganizationUser ? null : nameMaxLength}
                      labelValue={translationService.translate("TR_FIRSTNAME")}
                      placeholder={`${translationService.translate("TR_TYPE")}...`}
                      isInvalidField={isInvalidFirstName}
                      onChange={event => {
                        if (event.target.value.length <= nameMaxLength) {
                          setFirstName(event.target.value);
                        } else { return; }
                        failedFields && removeFailedFields(event.target.name);
                        setShowChangesErrorMsg(false);
                      }}
                      onBlur={() => checkFieldValidation(firstName, "firstName")}
                      failedFields={failedFields}
                    />
                    {
                      isInvalidFirstName ?
                        <small className="red-color">{translationService.translate("TR_INVALID_FIRST_NAME")}</small>
                        : null
                    }
                  </div>
                  <div className="col-sm-6 col-12">
                    <Input
                      type="text"
                      id="lastName"
                      name="lastName"
                      inputClassName={`pr--4 ${user.isOrganizationUser ? "disabled-input" : ""}`}
                      value={lastName}
                      fieldLength={user.isOrganizationUser ? null : nameMaxLength}
                      labelValue={translationService.translate("TR_LASTNAME")}
                      placeholder={`${translationService.translate("TR_TYPE")}...`}
                      isInvalidField={isInvalidLastName}
                      onChange={event => {
                        if (event.target.value.length <= nameMaxLength) {
                          setLastName(event.target.value);
                        } else { return; }
                        failedFields && removeFailedFields(event.target.name);
                        setShowChangesErrorMsg(false);
                      }}
                      onBlur={() => checkFieldValidation(lastName, "lastName")}
                      failedFields={failedFields}
                    />
                    {
                      isInvalidLastName ?
                        <small className="red-color">{translationService.translate("TR_INVALID_LAST_NAME")}</small>
                        : null
                    }
                  </div>
                  <div className="col-12">
                    <Input
                      type="text"
                      id="email"
                      name="email"
                      inputClassName="disabled-input"
                      value={email}
                      labelValue={translationService.translate("TR_EMAIL")}
                      placeholder={`${translationService.translate("TR_TYPE")}...`}
                      onChange={event => {
                        setEmail(event.target.value);
                        failedFields && removeFailedFields(event.target.name);
                        setShowChangesErrorMsg(false);
                      }}
                      isInvalidField={isInvalidEmail}
                      onBlur={() => checkFieldValidation(email, "email")}
                      failedFields={failedFields}
                    />
                    {
                      isInvalidEmail ?
                        <small className="red-color">{translationService.translate("TR_EMAIL_IS_INVALID")}</small>
                        : null
                    }
                  </div>
                  {
                    user && (isInstructor || isConsultant) ?
                      <Auxiliary>
                        <div className="col-12">
                          <div className="d-flex align-items-center position-relative" ref={wrapperRef}>
                            <Input
                              type="text"
                              id="specialty"
                              name="specialty"
                              blockClassName="w-100"
                              value={specialty}
                              isInvalidField={isInvalidSettitngSubmit && (!specialties.length && !specialty.trim()) ? true : false}
                              inputClassName="pr--4"
                              infoText={translationService.translate("TR_TYPE_AND_CHOOSE_SPECIALTIES_INFO")}
                              labelValue={`${translationService.translate("TR_SPECIALTY_PROFESSION")} *`}
                              placeholder={`${translationService.translate("TR_TYPE")}...`}
                              onFocus={onSpecialtyFocus}
                              fieldLength={100}
                              onChange={event => {
                                chanegeSpecialty(event.target.value);
                                failedFields && removeFailedFields(event.target.name);
                                setSecondSpecialty("");
                                setShowChangesErrorMsg(false);
                              }}
                              onKeyDown={(event) => handleKeyDown(event, searchResult)}
                              failedFields={failedFields}
                            />
                            {
                              searchResult && searchResult.length && specialty.trim().length > 1 && isVisible ?
                                <ul className="autocomplete-block top-80" id="scrollable">
                                  {
                                    searchResult.map((keyword, index) => {
                                      return <li
                                        key={index}
                                        className={`autocomplete-item pr-4 ${cursor === index ? "active-search" : ""}`}
                                        onClick={() => setKeyword(keyword)}
                                      >
                                        <span className={`${cursor === index ? "black-color" : ""}`}>
                                          {
                                            index ?
                                              <i className="fas fa-search bottom-4" />
                                              : null
                                          }
                                        </span>
                                        <Link to="#">{keyword}</Link >
                                      </li>
                                    })
                                  }
                                </ul>
                                : null
                            }
                          </div>
                        </div>
                        {
                          specialties && specialties.length ?
                            <div className="col-12 ">
                              <div className="selected-item-container my-2">
                                {
                                  specialties.map((currentSpecialty, index) => {
                                    return <div key={index} className="selected-item-wrapper">
                                      <div className="selected-item word-break-break-word">
                                        {currentSpecialty}
                                      </div>
                                      <button type="button" className="mindalay--btn-blue" onClick={() => removeSpecialty(currentSpecialty)}>
                                        <CloseSvg />
                                      </button>
                                    </div>
                                  })
                                }
                              </div>
                              <hr />
                            </div>
                            : null
                        }
                        <div className="col-12">
                          <div className="d-flex align-items-center">
                            <Textarea
                              id="bio"
                              name="bio"
                              rows="4"
                              infoText={translationService.translate("TR_TELL_ABOUT_YOURSELF")}
                              blockClassName="w-100"
                              textAreaClassName="pr--5"
                              placeholder={`${translationService.translate("TR_TYPE")}...`}
                              value={bio}
                              max={bioMaxLength}
                              labelValue={`${translationService.translate("TR_BIOGRAPHY")} *`}
                              isInvalidField={isInvalidSettitngSubmit && (!bio.trim() || (bio.trim() && bio.trim().length < bioMinLength)) ? true : false}
                              onChange={event => {
                                if (bioMaxLength >= event.target.value.length) {
                                  setBio(event.target.value);
                                } else { return false; }
                                failedFields && removeFailedFields(event.target.name);
                              }}
                              failedFields={failedFields}
                            />
                          </div>
                          <small><i>{translationService.translate("TR_MIN_FIFTY_STYMOLS")}</i></small>
                        </div>
                      </Auxiliary>
                      : null
                  }
                </div>
              </div>
              {
                user && (isInstructor || isConsultant) ?
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <div className="d-flex align-items-start">
                            <label>{`${translationService.translate("TR_HOW_MUCH_OF_VIDEO_PRO_ARE_YOU")} ?`}</label>
                            <div className="info-title">
                              <div>
                                <InfoSvg />
                                <span style={{ display: "none" }}>
                                  {translationService.translate("TR_CHOOSE_TEACHING_SKILLS_INFO")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="become-an-instructor-step-1">
                            {
                              instructorLibraryData && typeof videoProfessionalism === NUMBER_KEY && instructorLibraryData.libraryData.map(instructorLibrary => {
                                return <InputRadio
                                  key={instructorLibrary.id}
                                  id={instructorLibrary.id}
                                  value={instructorLibrary.id}
                                  defaultChecked={videoProfessionalism === instructorLibrary.id ? true : false}
                                  name="videoProfessionalism"
                                  labelValue={instructorLibrary.displayName}
                                  blockClassName="form-check mt-0"
                                  labelClassName="form-check-label"
                                  radioClassName="form-check-input"
                                  onChange={event => {
                                    typeof +event.target.value === NUMBER_KEY && setVideoProfessionalism(+event.target.value);
                                    failedFields && removeFailedFields(event.target.name);
                                    setShowChangesErrorMsg(false);
                                  }}
                                  failedFields={failedFields}
                                />
                              }
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>
            <hr />
          </div>
          {/* {
            currencies && currencies.length && user.isAdmin ?
              <Auxiliary>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <div>
                        <label>{translationService.translate("TR_CHANGE_CURRENCY")}</label>
                        <div className="info-title d-inline-block">
                          <div>
                            <InfoSvg />
                            <span style={{ display: "none" }}>
                              {translationService.translate("TR_CHANGE_CURRENCY_INFO")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ReactSelectOption
                        value={currencyId}
                        selectedValue={(() => {
                          const selectedItem = { ...currencies.find(data => data.id === currencyId) };
                          if (selectedItem) {
                            selectedItem.value = selectedItem.id;
                            selectedItem.label = selectedItem.isocode;
                          }
                          return selectedItem;
                        })()}
                        items={currencies.map(data => ({ ...data, label: data.isocode, value: data.id }))}
                        onChange={item => changeCurrency(item)}
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </Auxiliary>
              : null
          } */}
          <div className="mt-4">
            <h2 className="content-title p-0">{translationService.translate("TR_CHANGE_PASSWORD")}</h2>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <Input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  value={password}
                  labelValue={translationService.translate("TR_PASSWORD")}
                  infoText={translationService.translate("TR_PASSWORD_INFO")}
                  isInvalidField={isInvalidPassword || mismatchPasswords}
                  onChange={event => {
                    setPassword(event.target.value);
                    event.target.value && setErrorMessage(null);
                    failedFields && removeFailedFields(event.target.name);
                    setShowChangesErrorMsg(false);
                  }}
                  onBlur={() => checkFieldValidationForPasswords(password, "password")}
                  failedFields={failedFields}
                />
                {
                  isInvalidPassword ?
                    <small className="red-color">{translationService.translate("TR_PASSWORD_INFO")}</small>
                    : null
                }
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  labelValue={translationService.translate("TR_CONFIRM_PASSWORD")}
                  infoText={translationService.translate("TR_PASSWORD_INFO")}
                  isInvalidField={isInvalidconfirmPassword || mismatchPasswords}
                  onChange={event => {
                    setConfirmPassword(event.target.value);
                    event.target.value && setErrorMessage(null);
                    failedFields && removeFailedFields(event.target.name);
                    setShowChangesErrorMsg(false);
                  }}
                  onBlur={() => checkFieldValidationForPasswords(confirmPassword, "confirmPassword")}
                  failedFields={failedFields}
                />
                {
                  isInvalidconfirmPassword ?
                    <small className="red-color">{translationService.translate("TR_PASSWORD_INFO")}</small>
                    : null
                }
              </div>
              {
                errorMessage ? <div className="col-12">
                  <small className="fail">{errorMessage}</small>
                </div> : null
              }
              {
                mismatchPasswords ?
                  <div className="col-12"><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                  : null
              }
              {
                showChangesErrorMsg ?
                  <div className="col-12 my-2"><small className="red-color">{translationService.translate("TR_DID_NOT_MAKE_CHANGES")}</small></div>
                  : null
              }
              <div className="col-12">
                <SubmitButton type="button" id={true} spinnerId={spinnerId} className="col-12 col-md-3 mb-3" clicked={updateCurrentUserData} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div> : null;
}

export default Profile;