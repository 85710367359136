import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Link } from "react-router-dom";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "../../../Services/gamifyApiService";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import AlertService from "../../../Services/alertService";
import { ERROR_KEY } from "../../../Constants/mainKeys";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";

const Badges = props => {
	const dispatch = useDispatch();
	const { studentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);

	const [badges, setBadges] = useState([]);

	useEffect(() => {
		getBadges();
		return () => {
			setBadges([]);
		};
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getBadges = (id = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			id,
			userId: studentId ? studentId : user.studentId,
			badgeImageSize: 3,
			questImageSize: 1,
		};

		GamifyApiService.getBadgesTree(data)
			.then(response => {
				if (response && response.data) {
					let data = [...response.data];
					data = data.sort((a, b) => {
						const prerequisiteBadgeA = a.prerequisiteBadge
							? a.prerequisiteBadge.name
							: "";
						const prerequisiteBadgeB = b.prerequisiteBadge
							? b.prerequisiteBadge.name
							: "";
						if (prerequisiteBadgeA > prerequisiteBadgeB) {
							return -1;
						}
						if (prerequisiteBadgeA < prerequisiteBadgeB) {
							return 1;
						}
						return 0;
					});
					setBadges(data);
				}
			})
			.catch(error => getFail(error))
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getFail = error => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	const BadgeView = ({ badge }) => {
		if (!badge) {
			return false;
		}
		let withArrow = badge.prerequisiteBadge;
		return (
			<div className="badge-container d-flex">
				<div className="d-flex flex-column align-items-center">
					<div
						className={`badge-block mb-2 ${
							withArrow ? "badge-block-arrow" : "badge-block-without-arrow"
						}`}
						onClick={() =>
							window.open(
								`/${language}/gamification/badge/${badge.id}`,
								"_blank",
							)
						}>
						<div
							className="badge-image-block position-relative overflow-hidden"
							style={{ backgroundImage: `url(${badge.imagePath})` }}>
							{!badge.isBadgeAwarded ? (
								<div className="not-awarded">
									<i className="fa-solid fa-lock"></i>
								</div>
							) : null}
						</div>
						<h6 className="text-center mt-2 max-line-2" title={badge.name}>
							{badge.name}
						</h6>
					</div>
					{badge.badgeQuests && badge.badgeQuests.length ? (
						<div className="quest-container d-flex flex-column">
							{badge.badgeQuests.map((item, index) => {
								if (item.isRequired) {
									return (
										<div
											key={index}
											className={`quest-block `}
											onClick={() =>
												window.open(
													`/${language}/gamification/quest/${item.quest.id}`,
													"_blank",
												)
											}>
											<div
												className="quest-image-block position-relative overflow-hidden"
												style={{
													backgroundImage: `url(${item.quest?.imagePath})`,
												}}
												title={item.quest?.name}>
												{!item?.quest?.isQuestCompleted ? (
													<div className="not-awarded" />
												) : null}
											</div>
										</div>
									);
								} else {
									return false;
								}
							})}
						</div>
					) : null}
				</div>
				{badge && badge.prerequisiteBadge ? (
					<BadgeView badge={badge.prerequisiteBadge} />
				) : null}
			</div>
		);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="content-title p-0">
						<div className="d-flex align-items-center">
							<Link
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							<h2 className="content-title p-0">
								{translationService.translate("TR_BADGES")}
							</h2>
						</div>
					</div>

					<PageInfoBlock
						pageTitle="Gamification badges"
						pageName={"student_badges"}
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row student-badge px-3" style={{ overflowX: "auto" }}>
				{badges && badges.length ? (
					badges.map((item, index) => {
						return (
							<div
								key={index}
								className={`badge-container d-flex mb-3 justify-content-end ${
									item.prerequisiteBadge ? "col-12 pl-0" : ""
								} `}>
								<BadgeView badge={item} />
							</div>
						);
					})
				) : (
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
		</div>
	) : null;
};

export default withRouter(Badges);
