import React from "react";
import { withRouter } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Header from "../LayoutSections/Header/header";
import Footer from "../LayoutSections/Footer/footer";
import BaseRoutes from "../../Routing/baseRoutes";

const BaseLayout = props => {
  return (
    <Auxiliary>
      <Header />
      <div className="wrapper additional-top-padding m-section">
        {props.children}
        <BaseRoutes />
      </div>
      <Footer />
    </Auxiliary>
  );
}

export default withRouter(BaseLayout);

