import React from "react";
import { Route, Switch } from "react-router";
import PassCourseView from "../Pages/PassCourse/passCourseView";
import PassCourseVideoView from "../Pages/PassCourse/passCourseVideoView";
import NotFoundPage from "../Pages/404/notFoundPage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import StudentAssignment from "../Pages/Assignment/StudentAssignment";

const PassCourseRoutes = () => {
	return (
		<Switch>
			{/* Pass Course */}
			<Route
				path="/:language/passcourse/:courseId/:studentCourseId"
				exact
				component={PassCourseView}
			/>

			<Route
				path="/:language/passcourse/video/:courseId/:studentCourseId/:topicId"
				exact
				component={PassCourseVideoView}
			/>

			<Route
				path="/:language/passcourse/:courseId/:studentCourseId/assignment/:assignmentId"
				exact
				component={StudentAssignment}
			/>

			<Route
				path="/:language/passcourse/video/:courseId/:studentCourseId/:topicId/assignment/:assignmentId"
				exact
				component={StudentAssignment}
			/>

			<Route component={NotFoundPage} />
		</Switch>
	);
};

export default PassCourseRoutes;
