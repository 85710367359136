import React, { Component } from 'react';
import { connect } from 'react-redux';
import Carousel from '../../Components/Carousel/carousel';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import ApiService from '../../Services/apiService';
import AlertService from '../../Services/alertService';
import CourseBlock from '../../Components/Course/courseBlock';
import ShoppingCartList from './shoppingCartList';
import WishListItems from '../WishList/wishListItems';
import TranslationService from '../../Services/translationService';
import ButtonSpinner from '../../Components/Spinners/buttonSpinner';
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner, addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { changeShoppingCartCount } from "../../Store/Actions/wishListOrShoppingCart";
import { ERROR_KEY, OBJECT_KEY, SHOPPING_CART_PRODUCTS_IDS_KEYS, WISH_LIST_PRODUCTS_IDS_KEYS } from '../../Constants/mainKeys';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { CHECKOUT_KEY } from '../../Constants/urlKeys';
import { LOGIN_KEY } from './../../Constants/urlKeys';
import ReurnUrl from '../../Services/returnUrl';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 4,
    slidesToSlide: 4
  },
  miniTablet: {
    breakpoint: { max: 768, min: 425 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 425, min: 375 },
    items: 2,
    slidesToSlide: 2
  },
  miniMobile: {
    breakpoint: { max: 375, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

class ShoppingCart extends Component {
  _isMounted = false;
  state = {
    courses: [],
    shoppingCartCourses: [],
    translationService: null,
    wishListCourses: [],
    shoppingCartData: null,
    checkoutSpinnerId: uuid()
  }

  componentDidMount() {
    const { user } = this.props;
    this._isMounted = true;
    this.setTranslations();
    if (user) this.getShoppingCartDataForAuthUser();
    else this.getShoppingCartDataForUnAuthUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getShoppingCartDataForAuthUser = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getShoppingCartCourses().then(response => {
      const data = { ...response.data };
      this._isMounted && this.setState({
        shoppingCartData: data.shoppingCart ? { ...data.shoppingCart } : null,
        shoppingCartCourses: data.shoppingCart && data.shoppingCart.courses ? [...data.shoppingCart.courses] : [],
        wishListCourses: data.wishlist ? [...data.wishlist] : [],
        courses: data.recommended ? [...data.recommended] : []
      });
      data.shoppingCart && data.shoppingCart.courses && this.props.changeShoppingCartCount(response.data.shoppingCart.courses.length);
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getShoppingCartDataForUnAuthUser = () => {
    const spinnerId = uuid();
    var shoppingCartProductsByIds = null;
    var wishListProductsByIds = null;
    if (localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS)) {
      shoppingCartProductsByIds = JSON.parse(localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS))
    }
    if (localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS)) {
      wishListProductsByIds = JSON.parse(localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS))
    }
    if (shoppingCartProductsByIds && typeof shoppingCartProductsByIds === OBJECT_KEY) {
      this.props.changeShoppingCartCount(shoppingCartProductsByIds.length);
    }
    const data = {
      shoppingCartCourseIds: shoppingCartProductsByIds && shoppingCartProductsByIds.length ? shoppingCartProductsByIds : null,
      wishListCourseIds: wishListProductsByIds && wishListProductsByIds.length ? wishListProductsByIds : null
    }
    this.props.addPageSpinner(spinnerId);
    ApiService.getShoppingCartCoursesForUnauthUsers(data).then(response => {
      const data = { ...response.data };
      this.setState({
        shoppingCartData: data.shoppingCart ? { ...data.shoppingCart } : null,
        shoppingCartCourses: data.shoppingCart && data.shoppingCart.courses ? [...data.shoppingCart.courses] : [],
        wishListCourses: data.wishlist ? [...data.wishlist] : [],
        courses: data.top ? [...data.top] : [],
      })
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getShoppingCartCourses = () => {
    const { user } = this.props;
    var shoppingCartProductsByIds = null;
    if (localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS)) {
      shoppingCartProductsByIds = JSON.parse(localStorage.getItem(SHOPPING_CART_PRODUCTS_IDS_KEYS))
    }
    if (user) {
      ApiService.getShoppingCartCourses().then(response => {
        if (response.data) {
          const data = { ...response.data };
          this._isMounted && this.setState({
            shoppingCartData: data.shoppingCart ? { ...data.shoppingCart } : null,
            shoppingCartCourses: data.shoppingCart && data.shoppingCart.courses ? [...data.shoppingCart.courses] : []
          });
          data.shoppingCart && data.shoppingCart.courses && this.props.changeShoppingCartCount(data.shoppingCart.courses.length);
        }
      }).catch(error => this.getFail(error));
    } else {
      const data = {
        shoppingCartCourseIds: shoppingCartProductsByIds && shoppingCartProductsByIds.length ? shoppingCartProductsByIds : null,
      }
      ApiService.getShoppingCartCoursesForUnauthUsers(data).then(response => {
        const data = { ...response.data };
        this.setState({
          shoppingCartData: data.shoppingCart ? { ...data.shoppingCart } : null,
          shoppingCartCourses: data.shoppingCart && data.shoppingCart.courses ? [...data.shoppingCart.courses] : [],
        })
      }).catch(error => this.getFail(error));
    }
  };

  updateCoursesData = (name, id, currentCourse) => {
    var coursesArray = [...this.state[name]];
    var wishListCourses = [...this.state.wishListCourses];
    var courses = [...this.state.courses];
    var shoppingCartCourses = [...this.state.shoppingCartCourses];

    if (name === "courses") {
      coursesArray.forEach(course => {
        if (course.id === id) {
          if (!course.isInWishlist) {
            course.isInWishlist = true;
            wishListCourses.unshift(currentCourse);
            this.setState({ wishListCourses })
          } else {
            course.isInWishlist = false;
            var filteredCourseArray = wishListCourses.filter(courseId => courseId.id !== id);
            this.setState({ wishListCourses: filteredCourseArray })
          }
        }
      })
      this.setState({ [name]: coursesArray });
    }

    if (name === "shoppingCartCourses") {
      currentCourse && wishListCourses.push(currentCourse);
      const filteredCourseArray = coursesArray.filter(courseId => courseId.id !== id);
      currentCourse && courses.forEach(course => {
        if (course.id === id) course.isInWishlist = true;
      })
      this.setState({ [name]: filteredCourseArray, wishListCourses, courses }, () => {
        this.getShoppingCartCourses();
      });
    }

    if (name === "wishListCourses") {
      currentCourse && shoppingCartCourses.push(currentCourse);
      const filteredCourseArray = coursesArray.filter(courseId => courseId.id !== id);
      courses.forEach(course => {
        if (course.id === id) {
          course.isInWishlist = false
        }
      })
      this.setState({ [name]: filteredCourseArray, shoppingCartCourses, courses }, () => {
        this.getShoppingCartCourses();
      });
    }
  }

  checkPrices = () => {
    const { user, language } = this.props;
    if (user) {
      this.props.history.push(`/${this.props.language}/${CHECKOUT_KEY}`);
    } else {
      var url = `/${language}/${CHECKOUT_KEY}`;
      ReurnUrl.setUrl(url).then(() => {
        this.props.history.push(`/${language}/${LOGIN_KEY}`);
      })
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    spinnerId && this.props.removeButtonSpinner(spinnerId);
  };

  render() {

    const { courses, translationService, wishListCourses, shoppingCartData, checkoutSpinnerId, shoppingCartCourses } = this.state;
    const currentSpinner = this.props.buttonSpinners.find(spinner => spinner === checkoutSpinnerId);
    const { user } = this.props;

    return (translationService ?
      <Auxiliary>
        <section className="section m-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-12 order-md-0 order-12">
                <ShoppingCartList
                  courses={shoppingCartCourses}
                  updateCoursesData={this.updateCoursesData}
                  getShoppingCartCourses={this.getShoppingCartCourses}
                />
                <WishListItems
                  courses={wishListCourses}
                  updateCoursesData={this.updateCoursesData}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-12 order-md-12 order-0">
                <div className="cart-total-info-wrapper">
                  {
                    shoppingCartData && shoppingCartCourses && shoppingCartCourses.length ?
                      <Auxiliary>
                        <div className="total-price-info">
                          <span className="total-text">{`${translationService.translate('TR_TOTAL')}:`} </span>
                          {
                            !shoppingCartData.currency.symbolPosition ?
                              <p className="total-price"><span>{shoppingCartData.totalCost}</span><span>{shoppingCartData.currency.symbol}</span></p>
                              : <p className="total-price"><span>{shoppingCartData.currency.symbol}</span><span>{shoppingCartData.totalCost}</span></p>
                          }
                          <div className="d-flex align-items-center">
                            {
                              shoppingCartData.discountPercent ?
                                <p className="total-discount"><span>{shoppingCartData.discountPercent}</span>% off</p>
                                : null
                            }
                            <p className="total-old-price">
                              {
                                shoppingCartData.totalOldCost ?
                                  <del>
                                    <span>{shoppingCartData.currency.symbol}</span>
                                    <span>{shoppingCartData.totalOldCost}
                                    </span>
                                  </del>
                                  : null
                              }
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="d-flex justify-content-center mindalay--btn-default w-100 text-capitalize"
                          onClick={() => this.checkPrices(shoppingCartData.currency.id)}
                        >
                          {translationService.translate('TR_CHECKOUT')}
                          {currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
                        </button>
                      </Auxiliary>
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          courses && courses.length ?
            <section className="section m-section">
              <div className="container">
                <hr />
                <h3 className="section-title">
                  {user ? translationService.translate('TR_RECOMMENDED_COURSES') : translationService.translate("TR_TOP_COURSES")}
                </h3>
                <div className="course-carousel">
                  <Carousel
                    responsive={responsive}
                    arrows={true}
                  >
                    {
                      courses.map(course =>
                        <CourseBlock
                          key={course.id}
                          course={course}
                          creatorId={course.creatorUserId}
                          updateCoursesData={this.updateCoursesData}
                        />)
                    }
                  </Carousel>
                </div>
              </div>
            </section>

            : null
        }
      </Auxiliary>
      : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  buttonSpinners: state.spinner.buttonSpinners,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  addButtonSpinner,
  removeButtonSpinner,
  changeShoppingCartCount,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShoppingCart);
