import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { compose } from "redux";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import {
	BACK_URL_KEY,
	ERROR_KEY,
	NUMBER_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { Link } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Sections from "./Components/sections";
import QuizSvg from "../../Components/Svg/quizSvg";
import {
	COURSES_KEY,
	COURSE_KEY,
	MINDALAY_ADMIN,
	QUIZ_VIEW_KEY,
} from "../../Constants/urlKeys";
import { UNVERIFIED_KEY } from "../../Constants/urlKeys";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Textarea from "../../Components/Inputs/textArea";
import Parser from "html-react-parser";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

class CurrentCourse extends Component {
	state = {
		translationService: null,
		courseId: parseInt(this.props.match.params.courseId),
		courseData: null,
		courseQuizzes: [],
		showMessageModal: false,
		verificationdenialmessage: "",
		failedFields: [],
		isInvalidSubmit: false,
	};

	componentDidMount() {
		const { courseId } = this.state;
		this.setTranslations();
		if (courseId) {
			this.getCourseByIdForAdmin(courseId);
		} else {
			this.goBack();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getCourseByIdForAdmin = courseId => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getCourseByIdForAdmin(courseId)
			.then(response => {
				this.props.removePageSpinner(spinnerId);
				if (response && response.data) {
					this.setState({ courseData: response.data }, () => {
						this.getCourseQuizzes(courseId);
					});
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getCourseQuizzes = courseId => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getCourseQuizzes(courseId)
			.then(response => {
				this.props.removePageSpinner(spinnerId);
				if (response && response.data) {
					this.setState({ courseQuizzes: response.data });
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	convertKeywordsToString = string => {
		const objects = JSON.parse(string);
		return objects && Object.values(objects).join(" ");
	};

	convertStrToArray = str => {
		const array = [];
		str = JSON.parse(str);
		const keys = Object.keys(str);
		const values = Object.values(str);
		keys.forEach((key, index) => {
			array.push({
				[key]: values[index],
			});
		});
		return [...array];
	};

	goBack = () => {
		if (localStorage.getItem(BACK_URL_KEY)) {
			this.props.history.push(localStorage.getItem(BACK_URL_KEY));
		} else {
			window.history.back();
		}
		localStorage.removeItem(BACK_URL_KEY);
	};

	redirectToQuizViewPage = quizId => {
		const { language } = this.props;
		this.props.history.push(
			`/${language}/${MINDALAY_ADMIN}/${COURSE_KEY}/${QUIZ_VIEW_KEY}/${quizId}`,
		);
	};

	onTextChange = (event, maxLength = null) => {
		const failedFields = this.removeFailedFields(event.target.name);
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.setState({
			failedFields,
			[event.target.name]: event.target.value,
		});
	};

	verifyCourse = () => {
		const { courseId, courseData, translationService } = this.state;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_VERIFY_COURSE_COMFIRM_MSG")} "${
				courseData.name
			}"`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			this.props.addPageSpinner(spinnerId);
			ApiService.courseVerify(courseId)
				.then(() => {
					this.props.removePageSpinner(spinnerId);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					this.props.history.push(
						`/${this.props.language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${UNVERIFIED_KEY}`,
					);
				})
				.catch(error => this.getFail(error, spinnerId));
		});
	};

	showMessageModal = () => {
		this.setState({
			showMessageModal: true,
		});
	};

	cancel = () => {
		this.setState({
			showMessageModal: false,
			verificationdenialmessage: "",
		});
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
		this.goBack();
	};

	onSubmit = () => {
		const { verificationdenialmessage, courseId, translationService } =
			this.state;
		if (
			!courseId ||
			!verificationdenialmessage ||
			!verificationdenialmessage.trim()
		) {
			this.setState({ isInvalidSubmit: true });
		} else {
			const spinnerId = uuid();
			this.props.addPageSpinner(spinnerId);
			const data = {
				id: courseId,
				verificationdenialmessage,
			};
			ApiService.denyCourse(data)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					this.props.removePageSpinner(spinnerId);
					this.cancel();
					this.props.history.push(
						`/${this.props.language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${UNVERIFIED_KEY}`,
					);
				})
				.catch(error => this.getFail(error, spinnerId));
		}
	};

	render() {
		const { languages } = this.props;
		const {
			translationService,
			courseData,
			courseQuizzes,
			showMessageModal,
			verificationdenialmessage,
			failedFields,
			isInvalidSubmit,
		} = this.state;

		return translationService ? (
			courseData ? (
				<div className="container">
					<div className="row">
						<div className="col-12 content-title">
							<div className="d-flex align-items-center justify-content-between w-100">
								<div className="d-flex align-items-center">
									<Link
										to="#"
										title={translationService.translate("TR_BACK")}
										onClick={this.goBack}>
										<ArrowBackSvg />
									</Link>
									<h2 className="content-title word-wrap-break-word">
										{translationService.translate("TR_COURSE_VIEW")}
									</h2>
								</div>
								{courseData.published ? (
									<p className="d-block">
										{translationService.translate(
											"TR_COURSE_ALREADY_PUBLISHED",
										)}
									</p>
								) : null}
							</div>
						</div>
					</div>
					<hr />
					{showMessageModal ? (
						<ModalComponent
							title={`${translationService.translate("TR_DENY_COURSE")} - "${
								courseData.name
							}"`}
							isLarge={true}
							actionButtonTitle={translationService.translate("TR_DENY")}
							cancel={this.cancel}
							onSubmit={this.onSubmit}>
							<form>
								<Textarea
									rows="4"
									id="verificationdenialmessage"
									name="verificationdenialmessage"
									value={verificationdenialmessage}
									isInvalidSubmit={isInvalidSubmit}
									isInvalidField={
										isInvalidSubmit && !verificationdenialmessage.trim()
									}
									placeholder={`${translationService.translate(
										"TR_REASON",
									)}...`}
									onChange={this.onTextChange}
									failedFields={failedFields}
								/>
							</form>
						</ModalComponent>
					) : null}
					{courseData.description ? (
						<div className="row">
							<div className="col-12">
								<h4 className="mb-2">{courseData.name}</h4>
								{/* <p>{courseData.description}</p> */}
								<span className="course-description-block">
									<ParserComponent text={courseData.description} />
								</span>
							</div>
						</div>
					) : null}
					<div className="mt-5">
						{courseData.creatorUserName ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate("TR_CREATOR")}: `}</b>
								<span>{courseData.creatorUserName}</span>
							</span>
						) : null}
						{(courseData.languageId ||
							typeof courseData.languageId === NUMBER_KEY) &&
						languages ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate("TR_LANGUAGE")}: `}</b>
								{languages.map((lang, index) => {
									if (lang.languageId === courseData.languageId) {
										return <span key={index}>{lang.name}</span>;
									} else {
										return false;
									}
								})}
							</span>
						) : null}
						{courseData.cost ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate("TR_PRICE")}: `}</b>
								<span>
									{courseData.discount === 100
										? `${translationService.translate("TR_FREE")}`
										: courseData.cost}
								</span>
							</span>
						) : null}
						{courseData.discount ||
						typeof courseData.discount === NUMBER_KEY ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_DISCOUNT_PERCENTAGE",
								)}: `}</b>
								<span>{courseData.discount}</span>
							</span>
						) : null}
						{courseData.period ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate("TR_DURATION_DAYS")}: `}</b>
								<span>{courseData.period}</span>
							</span>
						) : null}
						{courseData.categories ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_COURSE_CATEGORIES",
								)}: `}</b>
								{courseData.categories && courseData.categories.length ? (
									<span>
										{courseData.categories.map((category, index) => {
											if (index + 1 === courseData.categories.length) {
												return <span key={index}>{category.name}</span>;
											} else {
												return <span key={index}>{category.name},</span>;
											}
										})}
									</span>
								) : null}
							</span>
						) : null}
						{courseData.keywords ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_COURSE_KEY_WORDS",
								)}: `}</b>
								<span>{this.convertKeywordsToString(courseData.keywords)}</span>
							</span>
						) : null}
						{courseData.creatorUserName ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_EXPERIENCE_LEVEL",
								)}: `}</b>
								<span>{courseData.creatorUserName}</span>
							</span>
						) : null}
						{courseData.whatYouWillLearn ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_WHAT_YOU_WILL_LEARN_DESC",
								)}: `}</b>
								<span>
									{this.convertStrToArray(courseData.whatYouWillLearn).map(
										(desc, index) => {
											return <span key={index}>{Object.values(desc)}</span>;
										},
									)}
								</span>
							</span>
						) : null}
						{courseData.requirements ? (
							<span className="d-block">
								<b>{`${translationService.translate("TR_REQUIREMENTS")}: `}</b>
								<span>
									{this.convertStrToArray(courseData.requirements).map(
										(desc, index) => {
											return <span key={index}>{Object.values(desc)}</span>;
										},
									)}
								</span>
							</span>
						) : null}
						{courseData.targetStudents ? (
							<span className="d-block">
								<b>{`${translationService.translate(
									"TR_TARGETED_STUDENTS",
								)}: `}</b>
								<span>
									{this.convertStrToArray(courseData.targetStudents).map(
										(desc, index) => {
											return <span key={index}>{Object.values(desc)}</span>;
										},
									)}
								</span>
							</span>
						) : null}
						{courseData.welcomeMessage ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_WELCOME_MESSAGE",
								)}: `}</b>
								<span>{courseData.welcomeMessage}</span>
							</span>
						) : null}
						{courseData.congratulationMessage ? (
							<span className="d-block my-1">
								<b>{`${translationService.translate(
									"TR_CONGRETULATION_MESSAGE",
								)}: `}</b>
								<span>{courseData.congratulationMessage}</span>
							</span>
						) : null}
					</div>
					{courseData.sections?.length ? (
						<Auxiliary>
							<hr />
							<div className="course-content">
								<h3 className="title">
									{translationService.translate("TR_COURSE_CONTENT")}
								</h3>
								{<Sections sections={courseData.sections} isEditMode={false} />}
							</div>
						</Auxiliary>
					) : null}
					{courseQuizzes && courseQuizzes.length ? (
						<Auxiliary>
							<div className="section-content-wrapper">
								<div className="col-12">
									<hr />
									<div className="content-sub-title">
										<h3>{translationService.translate("TR_COURSE_QUIZZES")}</h3>
									</div>
									{courseQuizzes.map(courseQuiz => {
										return (
											<div
												key={courseQuiz.id}
												className="section-content course-quiz-svg d-flex align-items-center p-3 cursor-pointer my-2"
												onClick={() =>
													this.redirectToQuizViewPage(courseQuiz.id)
												}>
												<QuizSvg />
												<Link to="#" className="text-elipsis full-width">
													<p>{courseQuiz.name}</p>
												</Link>
											</div>
										);
									})}
								</div>
							</div>
						</Auxiliary>
					) : null}
					{!courseData.published ? (
						<div className="mt-4">
							{!courseData.verified ? (
								<button
									type="button"
									className="mindalay--btn-default m-1"
									onClick={this.verifyCourse}>
									{translationService.translate("TR_VERIFY")}
								</button>
							) : null}
							<button
								type="button"
								className="mindalay--btn-default m-1"
								onClick={this.showMessageModal}>
								{translationService.translate("TR_DENY")}
							</button>
						</div>
					) : null}
				</div>
			) : null
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	languages: state.language.languages,
	language: state.language.language,
	user: state.user.user,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(CurrentCourse);
