import {
  CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT,
  CHANGE_SHOPPING_CART_COUNT,
  CHANGE_WISH_LIST_COUNT,
} from "../../Constants/reduxKeys";

export function changeShoppingCartOrWishListCount(wishListCount, shoppingCartCount) {
  return {
    type: CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT,
    payload: { wishListCount, shoppingCartCount }
  };
};

export function changeWishListCount(wishListCount) {
  return {
    type: CHANGE_WISH_LIST_COUNT,
    payload: { wishListCount }
  };
};

export function changeShoppingCartCount(shoppingCartCount) {
  return {
    type: CHANGE_SHOPPING_CART_COUNT,
    payload: { shoppingCartCount }
  };
};

