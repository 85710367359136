import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ButtonSpinner from "../Spinners/buttonSpinner";
import TranslationService from "../../Services/translationService";

const ActionButton = React.memo(props => {
	const { translations } = useSelector(state => state.translation);
	const { buttonSpinners } = useSelector(state => state.spinner);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const currentSpinner = buttonSpinners.find(
		spinner => spinner === props.spinnerId,
	);
	return translationService ? (
		<div className="d-flex justify-content-end">
			<button
				type={props.type}
				className={`action-button d-flex align-items-center ${props.className
					} ${currentSpinner || props.disabled ? "pointer-events-none" : ""} `}
				onClick={props.clicked}
				disabled={currentSpinner}
			>
				{currentSpinner
					? translationService.translate("TR_LOADING")
					: props.name}
				{currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
			</button>
		</div>
	) : null;
});

export default ActionButton;
