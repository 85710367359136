import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SettingsSvg from '../../../Components/Svg/settingsSvg';
import TranslationService from '../../../Services/translationService';
import { COURSES_KEY, FILE_LIBRARY_CATEGORY_KEY, FORM_KEY, INSTRUCTOR_KEY } from '../../../Constants/urlKeys';
import { ACTIVE_TAB_KEY } from '../../../Constants/mainKeys';

const CategoriesCollapse = ({ items, deleteCategory }) => {
  const [displayChildren, setDisplayChildren] = useState({});
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <div className="accordion-wrapper mt-3">
    {items && items.map((item, itemIndex) => {
      return (
        <div key={item.id} className="card mindalay-card">
          <div className="dropleft setting-dropdown">
            <div className="setting-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <SettingsSvg />
            </div>
            <div className="dropdown-menu">
              <Link
                to={
                  user.isOrganizationUser ?
                    `/${language}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}/${item.id}`
                    :
                    `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}/${item.id}`
                }
                className="dropdown-item"
                onClick={() => {
                  localStorage.setItem(ACTIVE_TAB_KEY, "file-library-folder");
                }}
              >
                {translationService.translate("TR_EDIT")}
              </Link>
              <Link to="#" className="dropdown-item" onClick={() => deleteCategory(item)}>
                {translationService.translate("TR_DELETE")}
              </Link>
            </div>
          </div>
          <div
            className="card-header"
            aria-controls={`file_category_${item.id}`}
            aria-expanded={displayChildren[item.id] && item.children && item.children.length}
            onClick={() => setDisplayChildren({ ...displayChildren, [item.id]: !displayChildren[item.id] })}
          >
            <h5 className="mb-0 text-elipsis"><span>{itemIndex + 1}</span>{item.name}</h5>
          </div>
          <Collapse in={displayChildren[item.id] && item.children && item.children.length ? true : false}>
            <div
              id={`file-category_${item.id}`}
              className="light-blue-background"
            >
              <div className="card-body">
                {
                  displayChildren[item.id] && item.children && item.children.length
                    ? <CategoriesCollapse
                      items={item.children}
                      deleteCategory={deleteCategory}
                    /> : null
                }
              </div>
            </div>
          </Collapse>
        </div>
      );
    })}
  </div> : null;
}

export default CategoriesCollapse;