import React, { Component, createRef } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import {
	addPageSpinner,
	removePageSpinner,
} from "../../../Store/Actions/spinner";
import { setBackUrl } from "../../../Store/Actions/main";
import logo from "../../../assets/logo/logo_214x214.png";
import { setLanguage } from "./../../../Store/Actions/language";
import Dropdown from "./../../../Components/Dropdown/dropdown";
import {
	B2B_KEY,
	HELP_KEY,
	LOGIN_KEY,
	REGISTRATION_KEY,
} from "../../../Constants/urlKeys";
import { LANGUAGE_KEY } from "../../../Constants/mainKeys";

class SpecialDomainHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMobileBurgerMenu: false,
			translationService: null,
			isShowMobileCategoriesMenu: false,
		};
		this.box = createRef();
	}

	componentDidMount() {
		this.setTranslations();
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	toggleMobileBurgerMenu = show => {
		this.setState({ showMobileBurgerMenu: show });
	};

	onLogoClick = () => {
		const { settings, language } = this.props;
		if (settings) {
			this.props.history.push(`/${language}/login`);
		} else {
			this.props.history.push(`/${language}`);
		}
	};

	changeLanguage = isocode => {
		if (isocode) {
			localStorage.setItem(LANGUAGE_KEY, isocode);
			this.props.setLanguage(isocode);
			const newPath = this.props.location.pathname
				.toString()
				.replace(this.props.match.path.toString(), `/${isocode}`);
			this.props.history.push(newPath.toString());
		}
	};

	render() {
		const { language, languages, settings } = this.props;
		const {
			translationService,
			showMobileBurgerMenu,
			isShowMobileCategoriesMenu,
		} = this.state;

		return translationService ? (
			<header className={`relative-layout header header-zindex`}>
				<div
					className={`burger-menu-container ${isShowMobileCategoriesMenu ? "toggle-burger" : ""
						}`}>
					<aside
						id="navbar_main"
						className={`mobile-offcanvas white-background mobile-categories-block ${isShowMobileCategoriesMenu ? "show" : ""
							}`}
						ref={this.box}>
						<div className="offcanvas-header">
							<div
								className="side-menu-close-btn left-close-btn"
								onClick={() =>
									this.setState({ isShowMobileCategoriesMenu: false })
								}>
								<span></span>
							</div>
							<h5 className="p-3 text-white box-shadow-4 gray-color">
								{translationService.translate("TR_CATEGORIES")}
							</h5>
						</div>
					</aside>
				</div>
				<nav className="navbar navbar-expand-lg brand-background">
					{/* <div className="nav-item d-lg-none d-block mr-2">
						<span
							style={{
								fontSize: 16,
								fontWeight: 400,
								transform: "none",
								color: "#ffffff",
							}}>
							(Beta)
						</span>
					</div> */}
					<div className="nav-left-side d-flex align-items-center flex-1">
						<Link to="#" className="navbar-brand" onClick={this.onLogoClick}>
							{/* {settings?.mainLogoPath ? (
								<img src={settings?.mainLogoPath} alt="/" />
							) : (
								<>
									<img src={logo} alt="/" />
									<h1 className="d-lg-block d-none">
											mindalay
									</h1>
								</>
							)} */}
							<img src={logo} alt="/" />
							<h1 className="d-lg-block d-none">
								mindalay
							</h1>
						</Link>
						<div className="nav-item d-lg-flex d-none align-items-center ">
							{/* <span
								style={{
									fontSize: 16,
									fontWeight: 400,
									transform: "none",
									color: "#ffffff",
									marginRight: "10px",
								}}>
								(Beta)
							</span> */}
							<Link
								to={
									this.props.location.pathname.includes("/help")
										? "#"
										: `/${language}/${HELP_KEY}`
								}
								className="nav-link">
								{translationService.translate("TR_HELP")}
							</Link>
						</div>
					</div>

					<div className="mobil-navbar-wrapper d-lg-none d-block">
						<ul className="navbar-nav">
							<li
								className="nav-item"
								onClick={() => this.toggleMobileBurgerMenu(true)}>
								<Link to="#" className="burger-btn open-burger-menu nav-link">
									<span></span>
								</Link>
							</li>
						</ul>
					</div>
					<Auxiliary>
						<div className="d-lg-block d-none ml-2">
							<ul className="navbar-nav align-items-left align-items-lg-center">
								<li className="mx-1">
									<Dropdown
										className="language-dropdown-light"
										items={languages}
										title={language.toUpperCase()}
										selected={this.changeLanguage}>
										{/* <i className="fas fa-language mr-1"></i> */}
									</Dropdown>
								</li>
								<Link
									to={`/${language}/${LOGIN_KEY}`}
									className="login-btn mx-1">
									{translationService.translate("TR_SIGN_IN")}
								</Link>
								<Link
									to={`/${language}/${REGISTRATION_KEY}`}
									className="mindalay--btn mindalay--btn-outline mx-1">
									{translationService.translate("TR_SIGN_UP")}
								</Link>
							</ul>
						</div>
						<div
							className={`burger-menu-container ${showMobileBurgerMenu ? "toggle-burger" : ""
								}`}>
							<div
								className={`burger-menu-body ${showMobileBurgerMenu ? "toggle-burger-menu" : ""
									}`}>
								<ul className="burger-profile-container">
									<li id="close-burger-menu" className="nav-item">
										<div
											className={`right-close-btn side-menu-close-btn ${showMobileBurgerMenu ? "burger-close-button-scala" : ""
												}`}
											onClick={() => this.toggleMobileBurgerMenu(false)}>
											<span></span>
										</div>
									</li>
									<li className="nav--item">
										<Link
											to={`/${language}`}
											className="profile-nav-link"
											onClick={() =>
												this.setState({ showMobileBurgerMenu: false })
											}>
											{translationService.translate("TR_HOME")}
										</Link>
									</li>
									<li className="nav--item">
										<Link
											to={`/${language}/${LOGIN_KEY}`}
											className="profile-nav-link"
											onClick={() =>
												this.setState({ showMobileBurgerMenu: false })
											}>
											{translationService.translate("TR_SIGN_IN")}
										</Link>
									</li>
									<li className="nav--item">
										<Link
											to={`/${language}/${REGISTRATION_KEY}`}
											className="profile-nav-link"
											onClick={() =>
												this.setState({ showMobileBurgerMenu: false })
											}>
											{translationService.translate("TR_SIGN_UP")}
										</Link>
									</li>

									<li className="nav--item">
										<Link
											to={`/${language}/${HELP_KEY}`}
											className="profile-nav-link"
											onClick={() => {
												this.setState({ showMobileBurgerMenu: false });
												this.props.setBackUrl(this.props.location.pathname);
											}}>
											{translationService.translate("TR_HELP")}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</Auxiliary>
				</nav>
			</header>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	languages: state.language.languages,
	settings: state.settings.settings,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
	setBackUrl,
	setLanguage,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(SpecialDomainHeader);
