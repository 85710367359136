import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { compose } from 'redux';
import Paypal from '../../Components/Svg/PaymentSvg/paypal';
import Skrill from '../../Components/Svg/PaymentSvg/skrill';
import WebMoney from '../../Components/Svg/PaymentSvg/webMoney';
import StripeIcon from '../../Components/Svg/PaymentSvg/stripe';
import YandexMoney from '../../Components/Svg/PaymentSvg/yandexMoney';
import CreditCardSvg from '../../Components/Svg/PaymentSvg/creditCard';
import ApiService from '../../Services/apiService';
import TranslationService from '../../Services/translationService';
import CreditCard from './creditCard';
import { CHECKOUT_KEY, COURSES_KEY, PROFILE_KEY } from '../../Constants/urlKeys';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import ButtonSpinner from '../../Components/Spinners/buttonSpinner';
import AlertService from '../../Services/alertService';
import Input from './../../Components/Inputs/input';
import { changeShoppingCartOrWishListCount } from './../../Store/Actions/wishListOrShoppingCart';
import TextSpinner from '../../Components/Spinners/textSpinner';
import { addTextSpinner, removeTextSpinner } from './../../Store/Actions/spinner';
import {
  addPageSpinner,
  removePageSpinner,
  addButtonSpinner,
  removeButtonSpinner
} from "../../Store/Actions/spinner";
import {
  ERROR_KEY, MOVE_KEY,
  NUMBER_KEY,
  PAYPAL_SUCCESS_KEY,
  REMOVE_KEY,
  RETURN_URL_KEY,
  SHOPPING_CART_COUNT_KEY,
  SUCCESS_KEY,
  TR_YES,
  TR_NO,
  WARNING_KEY,
  WISH_LIST_COUNT_KEY,
  PAYPAL_CANCEL_KEY,
  STRIPE_SUCCESS_KEY,
  STRIPE_CANCEL_KEY,
  CRYLLEX_SUCCESS_KEY,
  CRYLLEX_CANCEL_KEY,
} from '../../Constants/mainKeys';
import { CANCEL_KEY } from '../../Constants/requestKeys';


class Checkout extends Component {
  _isMounted = false;

  state = {
    fillAmount: '',
    translationService: null,
    paymentSystems: [],
    paymentFillSystemId: 0,
    isInvalidSubmit: false,
    shoppingCartCourses: [],
    cartData: {},
    checkoutSpinnerId: uuid(),
    courseId: +this.props.match.params.courseId || null,
    courseById: null,
    totalBalance: 0,
    totalCost: 0,
    minAmount: null,
    deleteFileId: null,
    operation: null,
    showShoppingCartCoursesCount: 5,
    balanceAsString: null
  }

  componentDidMount() {
    const { courseId } = this.state;
    const { user } = this.props;
    this._isMounted = true;
    this.setTranslations();
    this.getPaymentSystems();
    this.getBalance();
    if (courseId) {
      this.getBuyNowCourse();
    } else {
      this.getShoppingCartCourses();
    }

    if (this.props.location.pathname.includes(PAYPAL_SUCCESS_KEY) && this.props.location.pathname.includes(RETURN_URL_KEY) && user) {
      this.getPayPalResponse(SUCCESS_KEY);
    }
    if (this.props.location.pathname.includes(PAYPAL_CANCEL_KEY) && this.props.location.pathname.includes(RETURN_URL_KEY) && user) {
      this.getPayPalResponse(CANCEL_KEY);
    }
    if (this.props.location.pathname.includes(STRIPE_SUCCESS_KEY) && this.props.location.pathname.includes(RETURN_URL_KEY) && user) {
      this.getStripeResponse(SUCCESS_KEY);
    }
    if (this.props.location.pathname.includes(STRIPE_CANCEL_KEY) && this.props.location.pathname.includes(RETURN_URL_KEY) && user) {
      this.getStripeResponse(CANCEL_KEY);
    }
    if (this.props.location.pathname.includes(CRYLLEX_SUCCESS_KEY) && this.props.location.pathname.includes(RETURN_URL_KEY) && user) {
      this.getCryllexResponse(SUCCESS_KEY);
    }
    if (this.props.location.pathname.includes(CRYLLEX_CANCEL_KEY) && this.props.location.pathname.includes(RETURN_URL_KEY) && user) {
      this.getCryllexResponse(CANCEL_KEY);
    }
    localStorage.removeItem(RETURN_URL_KEY);
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getBalance = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getBalance().then(response => {
      if (response.data) {
        this._isMounted && this.setState({ totalBalance: response.data?.balance1, balanceAsString: response.data?.balanceAsString })
      }
      this.props.removePageSpinner(spinnerId)
    }).catch(error => this.getFail(error, spinnerId))
  }

  getShoppingCartCourses = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getShoppingCartCourses().then(response => {
      if (response && response.data) {
        this._isMounted && this.setState({
          cartData: response.data,
          paymentFillSystemId: 0,
          shoppingCartCourses: response.data?.shoppingCart ? response.data?.shoppingCart?.courses : [],
          totalCost: response.data?.shoppingCart && response.data?.shoppingCart.totalCost ? response.data.shoppingCart.totalCost : 0,
        });
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getBuyNowCourse = () => {
    const { courseId } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getBuyNowCourse(courseId).then(response => {
      if (response && response.data) {
        this._isMounted && this.setState({
          cartData: response.data,
          paymentFillSystemId: 0,
          shoppingCartCourses: response.data?.courses,
          totalCost: response.data?.totalCost ? response.data.totalCost : 0,
          totalCostAsString: response.data?.totalCostAsString,
        })
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  onNumberChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    const { minAmount } = this.state;
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0 && !minAmount)) {
      this.setState({ [event.target.name]: +event.target.value, failedFields, })
    }
    if (event.target.value === '' || (minAmount && typeof +event.target.value === NUMBER_KEY && Number(event.target.value) >= minAmount)) {
      this.setState({ [event.target.name]: +event.target.value, failedFields, })
    }
  };

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  getPaymentSystems = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getPaymentSystems().then(response => {
      this.props.removePageSpinner(spinnerId);
      this._isMounted && response.data && this.setState({ paymentSystems: response.data });
    }).catch(error => this.getFail(error, spinnerId));
  }

  redirectToCouresView = (courseId) => {
    const { language } = this.props;
    this.props.history.push(`/${language}/${COURSES_KEY}/${courseId}`)
  }

  getPayPalResponse = (status) => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getPayPalResponse(status, this.props.location.search).then(response => {
      if (response && response.data) {
        if (status === SUCCESS_KEY) {
          AlertService.alert(SUCCESS_KEY, "Purchase completed");
        } else {
          AlertService.alert(ERROR_KEY, "Purchase not completed");
        }
        this.setState({ totalBalance: response.data?.balance1, balanceAsString: response.data?.balanceAsString });
      }
      this.changeUrl();
      this.props.removePageSpinner(spinnerId)
    }).catch(error => this.getFail(error, spinnerId));
  }

  getStripeResponse = (status) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paymentId = params.get("paymentId") || null;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getStripeResponse(status, paymentId).then(response => {
      if (response && response.data) {
        if (status === SUCCESS_KEY) {
          AlertService.alert(SUCCESS_KEY, "Purchase completed");
        } else {
          AlertService.alert(ERROR_KEY, "Purchase not completed");
        }
        this.setState({ totalBalance: response.data?.balance1, balanceAsString: response.data?.balanceAsString });
      }
      this.changeUrl();
      this.props.removePageSpinner(spinnerId)
    }).catch(error => this.getFail(error, spinnerId));
  }

  getCryllexResponse = (status) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paymentId = params.get("paymentId") || null;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getCryllexResponse(status, paymentId).then(response => {
      if (response && response.data) {
        if (status === SUCCESS_KEY) {
          AlertService.alert(SUCCESS_KEY, "Purchase completed");
        } else {
          AlertService.alert(ERROR_KEY, "Purchase not completed");
        }
        this.setState({ totalBalance: response.data?.balance1, balanceAsString: response.data?.balanceAsString });
      }
      this.changeUrl();
      this.props.removePageSpinner(spinnerId)
    }).catch(error => this.getFail(error, spinnerId));
  }

  changeUrl = () => {
    this.props.history.push(`/${this.props.language}/${CHECKOUT_KEY}`)
  }

  removeAllCoursesFromShoppingCart = (courseIds) => {
    const { translationService } = this.state;
    const { language } = this.props
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.removeAllCoursesFromShoppingCart(courseIds).then(response => {
      this.props.removePageSpinner(spinnerId);
      this.props.changeShoppingCartOrWishListCount(response.data?.wishListCount, response.data?.shoppingCartCount);
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_PURCHASE_COMPLETED"));
      this.props.history.push(`/${language}/${PROFILE_KEY}`);
    }).catch(error => this.getFail(error, spinnerId))
  }

  confirmPayment = () => {
    const { translationService } = this.state;
    AlertService.alertConfirm(
      `${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.checkout();
    })
  }

  paymentAndPurchase = (event) => {
    //checkout if your balance < totalbalance
    event.preventDefault();
    const firstSpinnerId = uuid();
    const { paymentFillSystemId, fillAmount, cartData, translationService, courseId } = this.state;
    if (!paymentFillSystemId || !fillAmount) {
      this.setState({ isInvalidSubmit: true });
    } else {
      AlertService.alertConfirm(
        `${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
        "",
        translationService.translate(TR_YES),
        translationService.translate(TR_NO)
      ).then(() => {
        this.props.addPageSpinner(firstSpinnerId);
        (
          courseId ?
            ApiService.checkPriceBuyNow(
              cartData.totalCost,
              courseId
            ) :
            ApiService.checkPrices(
              cartData.shoppingCart ?
                cartData.shoppingCart.totalCost :
                cartData.totalCost,
              this.state.cartData.shoppingCart ?
                this.state.cartData.shoppingCart.currency.id :
                this.state.cartData.currency.id
            )).then(response => {
              this.props.removePageSpinner(firstSpinnerId);
              const secondSpinnerId = uuid();
              const courseIds = [];
              if (cartData.shoppingCart && cartData.shoppingCart.courses) {
                cartData.shoppingCart.courses.forEach(course => { courseIds.push(course.id) })
              } else {
                if (cartData.courses) {
                  cartData.courses.forEach(course => { courseIds.push(course.id) })
                }
              }
              const data = {
                amount: fillAmount,
                courseIds: JSON.stringify(courseIds),
                paymentSystemId: paymentFillSystemId,
              }
              if (response.data) {
                this.props.addPageSpinner(secondSpinnerId);
                ApiService.coursePurchase(data).then(response => {
                  if (response.data && response.data.returnUrl) {
                    window.location.href = response.data.returnUrl;
                  }
                  this.props.removePageSpinner(secondSpinnerId);
                }).catch(error => this.getFail(error, secondSpinnerId));
              } else {
                if (courseId) {
                  this.getBuyNowCourse();
                } else {
                  this.getShoppingCartCourses();
                }
                AlertService.alert(WARNING_KEY, translationService.translate("TR_CHANGES_IN_CHECKOUT_PRICES"));
                this.props.removePageSpinner(secondSpinnerId);
              }
            }).catch(error => this.getFail(error, firstSpinnerId))
      })
    }
  }

  checkout = () => {
    //if your balance > total price
    const { cartData } = this.state;
    const firstSpinnerId = uuid();
    const courseIds = [];
    cartData.courses && cartData.courses.forEach(course => { courseIds.push(course.id) });
    cartData.shoppingCart && cartData.shoppingCart.courses && cartData.shoppingCart.courses.forEach(course => { courseIds.push(course.id) });
    if (!courseIds || !courseIds.length) { return; }
    this.props.addPageSpinner(firstSpinnerId);
    ApiService.buyCoursesFromShoppingCart(courseIds).then(response => {
      this.props.removePageSpinner(firstSpinnerId);
      response && this.removeAllCoursesFromShoppingCart(courseIds);
    }).catch(error => this.getFail(error, firstSpinnerId));
  }

  // checkPaymentFillResponse = (id, data) => {
  //   const spinnerId = uuid();
  //   switch (id) {
  //     case 1:
  //       //PayPal
  //       this.props.addPageSpinner(spinnerId);
  //       window.location.href = data.paypalReturnUrl;
  //       break;
  //     case 2:
  //       break;
  //     case 3:
  //       //Stripe
  //       if (data.location) {
  //         window.location.href = data.location;
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }

  removeCourseFromShoppingCart = (event, course) => {
    event.stopPropagation();
    const { translationService } = this.state;
    this.setState({ operation: REMOVE_KEY, deleteFileId: course.id })
    const spinnerId = `file-${course.id}`;
    this.props.addTextSpinner(spinnerId);
    ApiService.removeCourseFromShoppingCart(course.id).then(response => {
      this.props.removeTextSpinner(spinnerId)
      if (response) {
        if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
          localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
          localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
          this.props.changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount);
          this.getShoppingCartCourses();
        }
        AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate('TR_REMOVE_COURSE_CHECKOUT_MSG')}`);
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  moveCourseToWishList = (event, course) => {
    event.stopPropagation();
    const { translationService } = this.state;
    this.setState({ operation: MOVE_KEY, deleteFileId: course.id });
    const spinnerId = `file-${course.id}`;
    this.props.addTextSpinner(spinnerId);
    ApiService.moveCourseToWishListOrCart(course.id, false).then(response => {
      this.props.removeTextSpinner(spinnerId);
      if (response) {
        response && AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate('TR_ADDED_WISH_LIST_MSG')}`);
        if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
          localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
          localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
          this.props.changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount);
          this.getShoppingCartCourses();
        }
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  onChangePaymentSystem = (field, id, totalBalance, totalCost) => {
    if (!id) return;
    this.setState({ [field]: id });
    if (typeof totalBalance === NUMBER_KEY && typeof totalCost === NUMBER_KEY && totalBalance < totalCost) {
      this.setState({ minAmount: totalCost - totalBalance, fillAmount: Math.ceil(totalCost - totalBalance), })
    }
  }

  getFail = (error, spinnerId) => {
    spinnerId && this.props.removeButtonSpinner(spinnerId);
    spinnerId && this.props.removePageSpinner(spinnerId);
    spinnerId && this.props.removeTextSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  render() {

    const paymentSystemsComponents = [
      {
        id: 1,
        name: "PayPal",
        value: <Paypal />
      },
      {
        id: 2,
        name: "Skrill",
        value: <Skrill />
      },
      {
        id: 3,
        name: "Stripe",
        value: <StripeIcon />
      },
      {
        id: 4,
        value: <WebMoney />
      },
      {
        id: 5,
        value: <YandexMoney />
      },
      {
        id: 6,
        value: <CreditCardSvg />
      },
    ];

    const {
      translationService,
      isInvalidSubmit,
      paymentFillSystemId,
      shoppingCartCourses,
      checkoutSpinnerId,
      totalBalance,
      totalCost,
      failedFields,
      fillAmount,
      deleteFileId,
      operation,
      showShoppingCartCoursesCount,
      courseId,
      balanceAsString,
      totalCostAsString,
      paymentSystems
    } = this.state;

    const currentSpinner = this.props.buttonSpinners.find(spinner => spinner === checkoutSpinnerId);
    const currentButtonSpinner = this.props.textSpinners.find(spinner => spinner === `file-${deleteFileId}`);

    return (
      translationService ? <section className="my-4"><div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title">{translationService.translate("TR_CHECKOUT")}</h2>
                <hr />
              </div>
            </div>
            {
              balanceAsString ?
                <div className="d-flex align-items-center total-balance">
                  <b>{`${translationService.translate("TR_YOUR_BALANCE")}: `}</b><h3 className="ml-2">{balanceAsString}</h3>
                </div>
                : null
            }
            {
              totalCostAsString ?
                <div className="d-flex align-items-center mt-3">
                  <b>{`${translationService.translate("TR_TOTAL_PRICE")}: `}</b><h3 className="ml-2 secondary-color">{totalCostAsString}</h3>
                </div>
                : null
            }
            <hr />
            {
              typeof totalBalance === NUMBER_KEY && typeof totalCost === NUMBER_KEY && totalBalance < totalCost ?
                <div className="col-12 pl-0">
                  {
                    !paymentFillSystemId ? <p>{translationService.translate("TR_CHOOSE_PAYMENT_METHOD")}</p> : null
                  }
                  <div className="payments-logos-container d-flex flex-wrap">
                    {/* {
                      paymentSystemsComponents.map(paymentSystemsComponent => {
                        return <div
                          key={paymentSystemsComponent.id}
                          onClick={() => this.onChangePaymentSystem("paymentFillSystemId", paymentSystemsComponent.id, totalBalance, totalCost,)}
                          className={`payment-logo-block ${paymentSystemsComponent.id === paymentFillSystemId ? "active-payment-system" : ""}
                                  ${!paymentFillSystemId && isInvalidSubmit ? "is-invalid error-bordered error-payment-system" : ""}`}>
                          {paymentSystemsComponent.value}
                        </div>
                      })
                    } */}
                    {
                      paymentSystemsComponents && paymentSystems.length ?
                        paymentSystemsComponents.map(paymentSystemsComponent => {
                          return paymentSystems.map(paymentSystem => {
                            if ((paymentSystem.name === paymentSystemsComponent.name && paymentSystem.type === 0) ||
                              (paymentSystem.name === paymentSystemsComponent.name && paymentSystem.type === 2)) {
                              return <div
                                key={paymentSystemsComponent.id}
                                onClick={() => this.onChangePaymentSystem("paymentFillSystemId", paymentSystemsComponent.id, totalBalance, totalCost)}
                                className={`payment-logo-block ${paymentSystemsComponent.id === paymentFillSystemId ? "active-payment-system" : ""}
                                ${!paymentFillSystemId && isInvalidSubmit ? "is-invalid error-bordered error-payment-system" : ""}`}>
                                {paymentSystemsComponent.value}
                              </div>
                            } else { return false; }
                          })
                        })
                        : null
                    }
                  </div>
                </div>
                : null
            }
            {
              paymentFillSystemId === 1 || paymentFillSystemId === 3 ?
                <form onSubmit={this.paymentAndPurchase}>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <Input
                        type="number"
                        id="fillAmount"
                        name="fillAmount"
                        min="0"
                        value={fillAmount}
                        labelValue={`${translationService.translate("TR_AMOUNT")} *`}
                        onChange={this.onNumberChange}
                        failedFields={failedFields}
                        isInvalidSubmit={isInvalidSubmit}
                      />
                    </div>
                  </div>
                  {/* if your balance < total balance */}
                  <div className="text-right">
                    <button type="button" className="mindalay--btn-secondary-outline text-capitaliz" onClick={this.paymentAndPurchase}>
                      {translationService.translate('TR_CHECKOUT')}
                      {currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
                    </button>
                  </div>
                </form>
                : null
            }
            {
              paymentFillSystemId === 6 ?
                <div className="mb-2">
                  <CreditCard translationService={translationService} />
                  <button type="button" className="mindalay--btn-secondary-outline text-capitaliz">
                    {translationService.translate('TR_CHECKOUT')}
                    {currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
                  </button>
                </div>
                : null
            }
            {
              shoppingCartCourses && shoppingCartCourses.length ?
                // if your balance > total balance
                <Auxiliary>
                  {
                    (typeof totalBalance === NUMBER_KEY && typeof totalCost === NUMBER_KEY && totalBalance >= totalCost) || !totalCost ?
                      <button type="button" className="mindalay--btn-secondary-outline text-capitaliz" onClick={this.confirmPayment}>
                        {translationService.translate('TR_CHECKOUT')}
                        {currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
                      </button>
                      : null
                  }
                </Auxiliary>
                : null
            }
            <div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title">{translationService.translate("TR_ORDER_DETAILS")}</h2>
                <hr />
              </div>
            </div>
            {
              shoppingCartCourses && shoppingCartCourses.length ?
                shoppingCartCourses.map((course, index) => {
                  return (showShoppingCartCoursesCount > index) ? <div key={course.id} className="cart-wrapper d-sm-flex my-3 ">
                    <div className="cart-body-wrapper d-flex w-100">
                      <div
                        className="cart-image d-flex cursor-pointer"
                        style={{ backgroundImage: `url(${course.imagePath})` }}
                        onClick={() => this.redirectToCouresView(course.id)}
                      />
                      <div className="cart-info-wrapper d-sm-flex d-block w-100">
                        <div className="cart-item-info">
                          <h3 className="cursor-pointer" onClick={() => this.redirectToCouresView(course.id)}>{course.name}</h3>
                          <p>{course.creatorUserName}</p>
                          {
                            !courseId ?
                              <div className="cart-item-ruls d-sm-flex d-block remove-or-move-course">
                                <div className="mr-2">
                                  {
                                    currentButtonSpinner && course.id === deleteFileId && operation === REMOVE_KEY
                                      ? <TextSpinner spinner={currentButtonSpinner} className="ml-2" />
                                      : <Link
                                        className={`text-button ${currentButtonSpinner ? "pointer-events-none" : ""}`}
                                        to="#"
                                        onClick={(event) => this.removeCourseFromShoppingCart(event, course)}
                                      >
                                        {`${translationService.translate('TR_REMOVE')}`}
                                      </Link>
                                  }
                                </div>
                                <div className="mr-2">
                                  {
                                    currentButtonSpinner && course.id === deleteFileId && operation === MOVE_KEY
                                      ? <TextSpinner spinner={currentButtonSpinner} className="ml-2" />
                                      : <Link
                                        className={`text-button ${currentButtonSpinner ? "pointer-events-none" : ""}`}
                                        to="#"
                                        onClick={(event) => this.moveCourseToWishList(event, course)}
                                      >
                                        {translationService.translate('TR_MOVE_TO_WISH_LIST')}
                                      </Link>
                                  }
                                </div>
                              </div>
                              : null
                          }
                        </div>
                        <div className="cart-price-wrapper d-sm-block d-flex flex-1 text-sm-right">
                          {
                            course.cost ?
                              <Auxiliary>
                                <div className="cart-item-new-price m-sm-0 mr-2">{course.discount === 100 ? `${translationService.translate("TR_FREE")}` : course.cost}</div>
                                <div className="cart-item-old-price">
                                  {course.oldCost ? <span>{course.oldCost}</span> : null}
                                </div>
                              </Auxiliary>
                              : <div className="cart-item-new-price m-sm-0 mr-2 h-100 d-flex align-items-center justify-content-end">
                                {translationService.translate('TR_FREE')}
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div> : null
                })
                : null
            }
            <div className="row mb-4">
              <div className="col-12 mt-3">
                {
                  shoppingCartCourses && (shoppingCartCourses.length > showShoppingCartCoursesCount)
                    ? <button type="button" className="mindalay--btn-dark w-100" onClick={() => this.setState({ showShoppingCartCoursesCount: showShoppingCartCoursesCount + 5 })}>
                      {translationService.translate("TR_SHOW_MORE")}
                    </button> : null
                }
              </div>
            </div>
          </div>
        </div>
      </div></section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  buttonSpinners: state.spinner.buttonSpinners,
  textSpinners: state.spinner.textSpinners,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  addButtonSpinner,
  removeButtonSpinner,
  changeShoppingCartOrWishListCount,
  addTextSpinner,
  removeTextSpinner
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout);
