import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import { NUMBER_KEY } from '../../Constants/mainKeys';

class CreditCard extends Component {

  state = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    isInvalidName: false,
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }

  onNumberChange = (event, fieldLength) => {
    const isValid = /[0-9]+$/;
    if (event.target.value) {
      if ((event.target.value === '' || typeof +event.target.value === NUMBER_KEY) && event.target.value.length <= fieldLength && isValid.test(event.target.value)) {
        this.setState({ [event.target.name]: +event.target.value });
      }
    } else {
      this.setState({ [event.target.name]: '' })
    }
  };

  onNameChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onNameValidation = () => {
    const isValid = /^[a-z,A-Z]*\s{1}[a-z,A-Z]*$/;
    if (isValid.test(this.state.name)) {
      this.setState({ isInvalidName: false });
    } else {
      this.setState({ isInvalidName: true });
    }
  }

  render() {
    const { cvc, expiry, focus, name, number, isInvalidName } = this.state;
    const { translationService } = this.props;

    return translationService ?
      <div id="PaymentForm" className="my-3">
        <Cards
          cvc={cvc}
          expiry={expiry}
          focused={focus}
          name={name}
          number={number}
        />
        <form className="my-3">
          <div className="d-flex flex-column form-group">
            <input
              type="tel"
              name="number"
              className="my-1"
              placeholder={translationService.translate("TR_CARD_NUMBER")}
              autoComplete="off"
              value={number}
              onChange={(event) => this.onNumberChange(event, 16)}
              onFocus={this.handleInputFocus}
            />
            <input
              type="text"
              name="name"
              className={`my-1 ${isInvalidName ? "is-invalid error-bordered" : ""}`}
              placeholder={translationService.translate("TR_YOUR_NAME")}
              autoComplete="off"
              value={name}
              onChange={(event) => this.onNameChange(event)}
              onBlur={(name) => this.onNameValidation(name)}
              onFocus={this.handleInputFocus}
            />
            <input
              type="tel"
              name="expiry"
              className="my-1"
              placeholder={translationService.translate("TR_EXPIRY")}
              autoComplete="off"
              value={expiry}
              onChange={(event) => this.onNumberChange(event, 4)}
              onFocus={this.handleInputFocus}
            />
            <input
              type="tel"
              name="cvc"
              className="my-1"
              placeholder={translationService.translate("TR_CVC")}
              autoComplete="off"
              value={cvc}
              onChange={(event) => this.onNumberChange(event, 3)}
              onFocus={this.handleInputFocus}
            />
          </div>
        </form>
      </div>
      : null
  }
}

export default (CreditCard)
