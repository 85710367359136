import React from "react";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import { withRouter } from "react-router-dom";
import SpecialDomainHeader from "../LayoutSections/Header/specialDomainHeader";
import SpecialDomainRoutes from "../../Routing/specialDomainRoutes";
import SpecialDomainFooter from "../LayoutSections/Footer/specialDomainFooter";
import { useSelector } from "react-redux";

const SpecialDomainLayout = props => {
	const { settings } = useSelector(state => state.settings);

	return (
		<Auxiliary>
			<SpecialDomainHeader />
			<div
				className={`wrapper additional-top-padding  
        ${
					props.location.pathname.includes("/help") ||
					props.location.pathname.includes("/login")
						? ""
						: "m-section "
				}
        ${
					props.location.pathname.includes("/login")
						? "d-flex justify-content-center align-items-center"
						: ""
				}
        `}
				style={
					settings &&
					settings.backgroundImagePath &&
					props.location.pathname.includes("/login")
						? {
								backgroundImage: `url(${settings.backgroundImagePath})`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
								backgroundSize: "cover",
						  }
						: {}
				}>
				<SpecialDomainRoutes />
			</div>
			<SpecialDomainFooter />
		</Auxiliary>
	);
};

export default withRouter(SpecialDomainLayout);
