import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import uuid from "react-uuid";
import ApiService from "../../../Services/apiService";
import AlertService from "../../../Services/alertService";
import ModalComponent from "../../../Components/ModalComponent/modalComponent";
import {
	AUDIO_KEY,
	ERROR_KEY,
	FILE_KEY,
	IMAGE_KEY,
	VIDEO_KEY,
} from "../../../Constants/mainKeys";
import FileSvg from "../../../Components/Svg/fileSvg";
import {
	addModalSpinner,
	removeModalSpinner,
} from "./../../../Store/Actions/spinner";
import PosterImage from "./../../../assets/images/video-poster.png";

const AddFromLibraryModal = props => {
	const { isMultiple = false } = props;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);

	const [translationService, setTranslationService] = useState(null);
	const [categories, setCategories] = useState([]);
	const [currentCategories, setCurrentCategories] = useState([]);
	const [files, setFiles] = useState([]);
	const [breadCrumbData, setBreadCrumbData] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [ongoingVideoId, setOngoingVideoId] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		const getFileCategories = () => {
			const spinnerId = uuid();
			dispatch(addModalSpinner(spinnerId));
			ApiService.getCategoriesByType(1)
				.then(response => {
					dispatch(removeModalSpinner(spinnerId));
					if (response && response.data && response.data.length) {
						setCategories(response.data);
						setCurrentCategories(response.data);
						getFiles(response.data[0]);
						setFiles([]);
					}
				})
				.catch(error => getFail(error, spinnerId));
		};
		getFileCategories();
	}, []);

	const getFiles = category => {
		if (category) {
			(props.match.params.topicId
				? ApiService.getFileLibraryByTopicIdFileTypeAndCategoryId(
						props.match.params.topicId,
						category.id,
						props.fileType,
						true,
				  )
				: ApiService.getFileLibraryByFileTypeAndCategoryId(
						category.id,
						props.fileType,
				  )
			)
				.then(response => {
					const breadCrumbDataCopy = [...breadCrumbData];
					const breadCrumbCategoryIndex = breadCrumbDataCopy.findIndex(
						data => data.id === category.id,
					);
					if (breadCrumbCategoryIndex === -1) {
						breadCrumbDataCopy.push({
							id: category.id,
							name: category.name,
							children: category.children,
						});
					} else {
						breadCrumbDataCopy.splice(breadCrumbCategoryIndex + 1);
					}
					setFiles(response.data);
					setCurrentCategories(category.children);
					setBreadCrumbData(breadCrumbDataCopy);
				})
				.catch(
					error =>
						error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						),
				);
		} else {
			setFiles([]);
		}
	};

	const selectFile = file => {
		if (!file) {
			return false;
		}
		if (isMultiple) {
			if (selectedFiles.find(item => item.id === file.id)) {
				setSelectedFiles(selectedFiles.filter(item => item.id !== file.id));
			} else {
				setSelectedFiles([...selectedFiles, file]);
			}
		} else {
			setSelectedFile(file);
		}
	};

	const clearFilter = () => {
		setCategories(categories);
		setCurrentCategories(categories);
		setFiles([]);
		setBreadCrumbData([]);
		setSelectedFile(null);
	};

	const closeFromLibraryModalAndOpenFileLibraryModal = () => {
		props.cancel();
		props.isShowAddNewFileLibraryModal();
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeModalSpinner(spinnerId));
	};

	return translationService ? (
		<ModalComponent
			title={translationService.translate("TR_CHOOSE_FILE")}
			isLarge={true}
			cancel={props.cancel}
			contentClassName="library-modal"
			aria-labelledby="example-modal-sizes-title-lg"
			actionButtonTitle={translationService.translate("TR_ADD")}
			addButtonIsDisabled={!selectedFile && !selectedFiles.length}
			onSubmit={() => {
				(selectedFile || selectedFiles) &&
					props.added(selectedFile || selectedFiles);
			}}>
			<div className="container mt-4 mb-4">
				<div className="row">
					{breadCrumbData && breadCrumbData.length ? (
						<div className="col-12 mb-3">
							<ul className="bread-crump d-flex flex-wrap file-category">
								<li className="bread-crump-item pointer" onClick={clearFilter}>
									{translationService.translate("TR_HOME")}
									<i className="fas fa-chevron-right mr-3 ml-3"></i>
								</li>
								{breadCrumbData.map(item => (
									<li
										key={item.id}
										className="bread-crump-item pointer word-break-break-word"
										onClick={() => getFiles(item)}>
										{item.name}
										<i className="fas fa-chevron-right mr-3 ml-3"></i>
									</li>
								))}
							</ul>
						</div>
					) : null}
					<div className="col-12">
						{currentCategories && currentCategories.length ? (
							currentCategories.map(category => {
								if (!category.isEmpty) {
									return (
										<button
											key={category.id}
											type="button"
											className="mindalay--btn-default mr-2 mindalay--btn-small folder-button"
											title={category.name}
											onClick={() => getFiles(category)}>
											{category.name}
										</button>
									);
								}
							})
						) : (
							<div className="col-12">
								<span>
									{translationService.translate("TR_THERE_IS_NO_FOLDER_INFO")}
									<Link
										to="#"
										onClick={
											closeFromLibraryModalAndOpenFileLibraryModal
										}>{`"${translationService.translate(
										"TR_ADD_NEW_FILE",
									)}"`}</Link>
									.
								</span>
							</div>
						)}
					</div>
					{files && files.length ? (
						<div className="col-12 mt-4">
							<div className="tab-content scroll">
								<div className="card tab-pane fade show active">
									<div className="collapse show">
										<div className="card-body">
											<div className="row">
												{files.map(file => (
													<div
														key={file.id}
														className={`col-lg-4 col-md-6 col-12 mb-2 pointer ${
															(selectedFile && file.id === selectedFile.id) ||
															selectedFiles.find(item => item.id === file.id)
																? "selected-border p-2"
																: ""
														}`}
														onClick={() => file && selectFile(file)}>
														{file && props.fileType === IMAGE_KEY ? (
															<div
																style={{
																	backgroundImage: `url(${file.filePath})`,
																}}
																className="w-100 file-image"></div>
														) : null}
														{file && props.fileType === VIDEO_KEY ? (
															<video
																controls
																poster={PosterImage}
																autoPlay={false}
																className="upload choose-video"
																onPointerDown={() =>
																	setOngoingVideoId(file.id)
																}>
																{ongoingVideoId === file.id ? (
																	<source
																		src={file.filePath}
																		type="video/mp4"
																	/>
																) : null}
															</video>
														) : null}
														{file && props.fileType === AUDIO_KEY ? (
															<div className="file-icon-wrapper mb-2">
																<audio
																	className="w-100 cursor-default"
																	src={file.filePath}
																	controls
																/>
															</div>
														) : null}
														{file && props.fileType === FILE_KEY ? (
															<div className="file-icon-wrapper mb-2">
																<FileSvg />
															</div>
														) : null}
														<p className="text-center">{file.name}</p>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</ModalComponent>
	) : null;
};

export default withRouter(AddFromLibraryModal);
