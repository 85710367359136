import React from "react";
import { withRouter } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import AdminRoutes from "../../Routing/adminRoutes";
import AdminFooter from "../LayoutSections/Footer/adminFooter";
import AdminHeader from "../LayoutSections/Header/adminHeader";
import AdminLeftMenu from '../LayoutSections/LeftMenu/AdminLeftMenu';
import { COURSE_KEY, HOME_KEY, IN_PROCESS_KEY, RESOLVED_KEY, TICKET_KEY, TOPIC_KEY } from "../../Constants/urlKeys";

const AdminLayout = props => {
  return (
    <Auxiliary>
      <AdminHeader />
      <div className="wrapper">
        <AdminLeftMenu />
        <div className={
          props.location.pathname.includes(`/${COURSE_KEY}/`) ||
            props.location.pathname.includes(`/${TOPIC_KEY}/`) ||
            (
              props.location.pathname.includes(`/${TICKET_KEY}/`)
              && !props.location.pathname.includes(`/${IN_PROCESS_KEY}`)
              && !props.location.pathname.includes(`/${RESOLVED_KEY}`)
            )
            || props.location.pathname.includes(`/${HOME_KEY}`)
            ? "admin-dashboard-body--large"
            : "instructior-dashboard-body--large"
        }>
          <section className="top-section m-section wrapper">
            <AdminRoutes />
          </section>
          <AdminFooter />
        </div>
      </div>
    </Auxiliary>
  );
}

export default withRouter(AdminLayout);

