import React, { useEffect, useState } from "react";
import ActionButton from "../../../Components/ActionButton/actionButton";
import TranslationService from "../../../Services/translationService";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";

import {
	addButtonSpinner,
	removeButtonSpinner,
} from "../../../Store/Actions/spinner";
import MainService from "../../../Services/mainService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
} from "../../../Constants/mainKeys";
import AlertService from "../../../Services/alertService";
import UploadButton from "../../../Components/UploadButton/uploadButton";
import CloseSvg from "./../../../Components/Svg/closeSvg";
import AvatarEditor from "react-avatar-editor";
import ApiService from "../../../Services/apiService";
import { setSettings } from "../../../Store/Actions/settings";

const spinnerId = uuid();

const ImagesForm = () => {
	const mainService = new MainService();
	var editor = "";
	const dispatch = useDispatch();
	const { language, languages } = useSelector(state => state.language);
	const { settings } = useSelector(state => state.settings);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [isChange, setIsChange] = useState(false);

	const [scale, setScale] = useState(1);

	const handleZoomChange = newScale => {
		// You can add validation or constraints on the newScale if needed
		setScale(newScale);
	};

	const [values, setValues] = useState({
		mainLogo: null,
		footerLogo: null,
		backgroundImage: null,
		removeMainLogo: false,
		removeFooterLogo: false,
		removeBackgroundImage: false,
	});

	useEffect(() => {
		if (settings) {
			setValues(values => ({
				...values,
				removeMainLogo: false,
				removeFooterLogo: false,
				removeBackgroundImage: false,
				mainLogo: { uploadedFile: settings.mainLogoPath },
				footerLogo: { uploadedFile: settings.footerLogoPath },
				backgroundImage: { uploadedFile: settings.backgroundImagePath },
			}));
		}
	}, [settings]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const uploadFile = (event, fieldName) => {
		const file = event.target.files[0];
		if (file && fieldName) {
			const fileName = file.name;
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedFile => {
					const currentFile = {
						file,
						fileName,
						uploadedFile,
					};
					setValues(value => ({
						...value,
						[fieldName]: currentFile,
					}));
				})
				.catch(error => {
					AlertService.alert(
						ERROR_KEY,
						translationService.translate("TR_VALID_IMAGE_ERROR_MSG"),
					);
				});
		}
		setIsChange(true);
	};

	const setEditorRef = ed => {
		editor = ed;
	};

	const removeImg = (fieldName, removedFieldName) => {
		setValues(value => ({
			...value,
			[fieldName]: null,
			[removedFieldName]: true,
		}));
		setIsChange(true);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		dispatch(addButtonSpinner(spinnerId));
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			if (typeof value === "boolean") {
				formData.append(key, value);
			} else {
				if (value && value.file) {
					formData.append(key, value.file);
				} else {
					formData.append(key, null);
				}
			}
		});
		let languageId;
		if (languages && language) {
			let _language = languages.find(el => el.isocode2 === language);
			languageId = _language ? _language.languageId : 1;
		}
		formData.append("languageId", languageId);
		formData.append("organizationId", settings.organizationId);
		formData.append("id", settings.id);
		ApiService.updateSettingsWhiteLabelMediaSettings(formData)
			.then(res => {
				if (res.data) {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					const newValues = { ...values };
					Object.keys(newValues).forEach(key => {
						if (typeof newValues[key] === "boolean" && newValues[key]) {
							newValues[key] = false;
						}
					});
					setValues(newValues);
					dispatch(setSettings(res.data));
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(spinnerId));
				setIsChange(false);
			});
	};

	return translationService ? (
		<div className="mt-4">
			<form onSubmit={onSubmit}>
				<div className="row mb-3 align-items-lg-stretch">
					<div className="col-lg-4 col-md-6 col-12 ">
						<div className="course-media-wrapper d-flex flex-column justify-content-between h-100 bg-light p-2 rounded border">
							<label>{translationService.translate("TR_MAIN_LOGO")}</label>
							{values.mainLogo?.uploadedFile ? (
								<div className="upload-file-content">
									<div onClick={() => removeImg("mainLogo", "removeMainLogo")}>
										<CloseSvg />
									</div>
									<div
										className="img"
										style={{
											backgroundImage: `url(${values.mainLogo.uploadedFile})`,
										}}
										alt="course-image"></div>
								</div>
							) : null}
							<div className="form-group position-relative mb-0">
								<UploadButton
									textInputClasses="pl-100"
									labelValue=""
									text={`${translationService.translate("TR_UPLOAD_FILE")} *`}
									clicked={event => uploadFile(event, "mainLogo")}
								/>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-12 d-flex mt-3 mt-lg-0 flex-column justify-content-between">
						<div className="course-media-wrapper d-flex flex-column justify-content-between h-100 bg-light p-2 rounded border">
							<label>{translationService.translate("TR_FOOTER_LOGO")}</label>
							{values.footerLogo?.uploadedFile ? (
								<div className="upload-file-content">
									<div
										onClick={() => removeImg("footerLogo", "removeFooterLogo")}>
										<CloseSvg />
									</div>
									<div
										className="img"
										style={{
											backgroundImage: `url(${values.footerLogo.uploadedFile})`,
										}}
										alt="course-image"></div>
								</div>
							) : null}
							<div className="form-group position-relative mb-0">
								<UploadButton
									textInputClasses="pl-100"
									labelValue=""
									text={`${translationService.translate("TR_UPLOAD_FILE")} *`}
									clicked={event => uploadFile(event, "footerLogo")}
								/>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-12 mt-3 mt-lg-0">
						<div className="course-media-wrapper d-flex flex-column justify-content-between h-100 bg-light p-2 rounded border">
							<label>
								{translationService.translate("TR_BACKGROUND_IMAGE")}
							</label>
							{values.backgroundImage?.uploadedFile ? (
								<div className="upload-file-content d-flex flex-column align-items-center">
									<div
										onClick={() =>
											removeImg("backgroundImage", "removeBackgroundImage")
										}>
										<CloseSvg />
									</div>
									<div className="user-profile-image-container">
										<AvatarEditor
											ref={setEditorRef}
											image={values.backgroundImage?.uploadedFile}
											width={250}
											height={200}
											border={30}
											color={[0, 0, 0, 0.6]} // RGBA
											scale={scale}
											rotate={0}
										/>
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="mb-0 mr-2">
												{translationService.translate(
													"TR_ZOOM_BACKGROUND_IMAGE",
												)}
											</label>
											<input
												type="range"
												min="0.1"
												className="w-100 input-range"
												max="2"
												step="0.1"
												value={scale}
												onChange={e =>
													handleZoomChange(parseFloat(e.target.value))
												}
											/>
										</div>
									</div>
								</div>
							) : null}

							<div className="form-group position-relative mb-0">
								<UploadButton
									textInputClasses="pl-100"
									labelValue=""
									text={`${translationService.translate("TR_UPLOAD_FILE")} *`}
									clicked={event => uploadFile(event, "backgroundImage")}
								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<hr />
						<ActionButton
							spinnerId={spinnerId}
							type="submit"
							disabled={!isChange}
							className="mindalay--btn-dark position-relative mb-3"
							name={translationService.translate("TR_SAVE")}
						/>
					</div>
				</div>
			</form>
		</div>
	) : null;
};

export default ImagesForm;
