import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ActionButton from "./../../../../Components/ActionButton/actionButton";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import {
	addButtonSpinner,
	removeButtonSpinner,
} from "../../../../Store/Actions/spinner";
import GamifyApiService from "../../../../Services/gamifyApiService";
import AlertService from "../../../../Services/alertService";
import { ERROR_KEY } from "../../../../Constants/mainKeys";
import TranslationService from "../../../../Services/translationService";

const buttonSpinnerId = uuid();

export default function ResourceBlock({ item, setSelectedResource }) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const generateGamifyToken = (scopeType, resourceId) => {
		if (!scopeType) {
			return false;
		}
		dispatch(addButtonSpinner(`${buttonSpinnerId}_${resourceId}`));

		GamifyApiService.generateGamifyToken({ scopeType })
			.then(response => {
				if (response && response.data) {
					let redirectUrl = `${process.env.REACT_APP_GAMIFICATION_URL}/resources/${response.data}/form/${resourceId}`;
					window.open(redirectUrl, "_blank");
				}
				dispatch(removeButtonSpinner(`${buttonSpinnerId}_${resourceId}`));
			})
			.catch(error => getFail(error, `${buttonSpinnerId}_${resourceId}`));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	return (
		!!translationService && (
			<Card className="w-100 h-100">
				<div
					className="w-100"
					style={{
						backgroundImage: `url(${item.imagePath})`,
						height: "200px",
						backgroundPosition: "center",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
					}}
				/>

				<Card.Body className="d-flex flex-column justify-content-between p-2">
					<div className="d-flex justify-content-between flex-column h-100">
						<div>
							<h3 className="title">{item.name}</h3>
							<p className="max-line-3">{item.description}</p>
							<hr />
						</div>
						<div>
							{item.rate ? (
								<p className="d-flex justify-content-between mb-2">
									<b>{translationService.translate("TR_RATE")}</b>
									<b>{item.rate}</b>
								</p>
							) : null}
							<p className="d-flex justify-content-between mb-2">
								<b>{translationService.translate("TR_IS_FRAGMENTAL")}</b>
								{item.isFragmental ? (
									<i
										className="fa-regular fa-circle-check text-success"
										style={{ fontSize: "20px" }}></i>
								) : (
									<i
										className="fa-regular fa-circle-xmark text-danger"
										style={{ fontSize: "20px" }}></i>
								)}
							</p>
							<p className="d-flex justify-content-between mb-2">
								<b>{translationService.translate("TR_IS_FRAGMENTAL")}</b>
								{item.isDefault ? (
									<i
										className="fa-regular fa-circle-check text-success"
										style={{ fontSize: "20px" }}></i>
								) : (
									<i
										className="fa-regular fa-circle-xmark text-danger"
										style={{ fontSize: "20px" }}></i>
								)}
							</p>
						</div>
					</div>
					<div>
						<ActionButton
							type="button"
							spinnerId={`${buttonSpinnerId}_${item.id}`}
							className="btn mindalay--btn-secondary w-100 py-2 mt-2"
							name={translationService.translate("TR_MANAGE")}
							clicked={() => generateGamifyToken(1, item.id)}
						/>
						{item.isFragmental ? (
							<button
								className="btn mindalay--btn-dark w-100 py-2 mt-2"
								to={`#`}
								onClick={() => setSelectedResource(item)}>
								{translationService.translate("TR_SHOW_FRAGMENTS")}
							</button>
						) : null}
					</div>
				</Card.Body>
			</Card>
		)
	);
}
