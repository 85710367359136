import React, { useState, useEffect } from 'react';
import pdfIcon from "../../../assets/icons/pdf.png";
import txtIcon from "../../../assets/icons/txt.png";
import xlsIcon from "../../../assets/icons/xls.png";
import PosterImage from "../../../assets/images/video-poster.png";
import { AUDIO_KEY, IMAGE_KEY, OBJECT_KEY, STRING_KEY, VALID_FILE_TYPES_KEY, VIDEO_KEY } from '../../../Constants/mainKeys';
import CloseSvg from './../../../Components/Svg/closeSvg';
import AudioSvg from './../../../Components/Svg/audioSvg';
import MainService from '../../../Services/mainService';

const includesData = (file, type) => {
  if (typeof type === STRING_KEY && file.mimeType.includes(type)) {
    return file.mimeType
  }
  if (typeof type === OBJECT_KEY) {
    var validType = type.find(element => file.mimeType.includes(element))
    if (validType) return file.mimeType
  }
}

const Fiels = (props) => {

  useEffect(() => {
    window.addEventListener('keydown', zoomOut);
    return () => {
      window.removeEventListener('keydown', zoomOut);
    }
  }, [])

  const [zoomVideoPath, setZoomVideoPath] = useState(null);
  const [zoomAudioPath, setZoomAudioPath] = useState(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  const { topicFileData } = props;

  const zoomOut = (event) => {
    if (event.key === "Escape") {
      hideZoomImageVideoAudio();
    }
  }

  const hideZoomImageVideoAudio = () => {
    setZoomVideoPath(null);
    setZoomAudioPath(null);
    setZoomImagePath(null);
  }

  const openFile = (filePath) => {
    window.open(filePath, '_blank').focus();
  }

  return (
    <div className="row">
      {
        zoomVideoPath ?
          <div className="zoom-image-container" onClick={hideZoomImageVideoAudio}>
            <div className="zoom-image-block">
              <div className="close-svg"><CloseSvg /></div>
              <video controls={true} onClick={(event) => event.stopPropagation()}>
                <source src={zoomVideoPath} />
              </video>
            </div>
          </div>
          : null
      }
      {
        zoomAudioPath ?
          <div className="zoom-image-container" onClick={hideZoomImageVideoAudio}>
            <div className="zoom-image-block">
              <div className="close-svg"><CloseSvg /></div>
              <audio src={zoomAudioPath} controls />
            </div>
          </div>
          : null
      }
      {
        zoomImagePath ?
          <div className="zoom-image-container" onClick={hideZoomImageVideoAudio}>
            <div className="close-svg"><CloseSvg /></div>
            <img src={zoomImagePath} alt="/" onClick={(event) => event.stopPropagation()} />
          </div>
          : null
      }
      <div className="col-12">
        <div className="mt-4">
          <div className="course-pass-media-file-container">
            <div className="row">
              {
                topicFileData ? topicFileData.map(file => <div key={file.id} className="col-lg-6 col-md-12 col-12 mt-3">
                  <div className="course-pass-media-file-wrapper box-shadow-4 cursor-default">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={`d-flex align-items-center cursor-pointer w-100`}>
                        {
                          (() => {
                            switch (file.mimeType) {
                              case includesData(file, IMAGE_KEY):
                                return <img src={file.filePath} alt={file.filePath} onClick={() => setZoomImagePath(file.filePath)} />
                              case includesData(file, AUDIO_KEY):
                                return <AudioSvg onClick={() => setZoomAudioPath(file.filePath)} width={50} />
                              case includesData(file, VIDEO_KEY):
                                return <video
                                  className="file-video cursor-pointer"
                                  autoPlay={true}
                                  poster={PosterImage}
                                  onPointerDown={() => setZoomVideoPath(file.filePath)}
                                />

                              case includesData(file, VALID_FILE_TYPES_KEY):
                                switch (file.mimeType) {
                                  case includesData(file, "pdf"):
                                    return <img src={pdfIcon} alt="/" onClick={() => openFile(file.filePath)} />;
                                  case includesData(file, "txt"):
                                    return <img src={txtIcon} alt="/" onClick={() => openFile(file.filePath)} />;
                                  case includesData(file, "doc"):
                                    return <img src={''} alt="/" onClick={() => openFile(file.filePath)} />;
                                  case includesData(file, "rtf"):
                                    return <img src={''} alt="/" onClick={() => openFile(file.filePath)} />;
                                  case includesData(file, "xls"):
                                    return <img src={xlsIcon} alt="/" onClick={() => openFile(file.filePath)} />;
                                  case includesData(file, "xlsx"):
                                    return <img src={''} alt="/" onClick={() => openFile(file.filePath)} />;
                                  default:
                                    break
                                }
                              default:
                                break;
                            }
                          })()
                        }
                        {/* <p className="ml-3 cursor-default" title={file.nameAsResource}>{file.nameAsResource}</p> */}
                        {
                          file.mimeType.includes(IMAGE_KEY) ?
                            <p
                              title={file.nameAsResource}
                              className="text-elipsis cursor-pointer ml-2"
                              onClick={() => setZoomImagePath(file.filePath)}
                            >
                              {file.nameAsResource}
                            </p>
                            : null
                        }
                        {
                          file.mimeType.includes(VIDEO_KEY) ?
                            <p
                              title={file.nameAsResource}
                              className="text-elipsis cursor-pointer ml-2"
                              onClick={() => setZoomVideoPath(file.filePath)}
                            >
                              {file.nameAsResource}
                            </p>
                            : null
                        }
                        {
                          file.mimeType.includes(AUDIO_KEY) ?
                            <p
                              title={file.nameAsResource}
                              className="text-elipsis cursor-pointer ml-2"
                              onClick={() => setZoomAudioPath(file.filePath)}
                            >
                              {file.nameAsResource}
                            </p>
                            : null
                        }
                        {
                          file.mimeType.includes("pdf") || //pdf
                            file.mimeType.includes("text") || //txt
                            file.mimeType.includes("application") ?
                            <a
                              title={file.nameAsResource}
                              target="blank"
                              href={file.filePath}
                              className="file-item text-secondary ml-2"
                            >
                              {file.nameAsResource}
                            </a>
                            : null
                        }
                      </div>
                      <a href={MainService.getDownloadUrl(file.downloadPath ? file.downloadPath : file.filePath)} target="blank" className="float-right brand-color">
                        <i className="fas fa-download" />
                      </a>
                    </div>
                  </div>
                </div>) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fiels;
