import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import TranslationService from "../../../Services/translationService";
import { PURE_LAYOUT_BACK_KEY } from "./../../../Constants/mainKeys";

const PureHeader = props => {
	const { language } = useSelector(state => state.language);
	const { translations } = useSelector(state => state.translation);
	const { pureLayoutBackUrl } = useSelector(state => state.main);
	const [translationService, setTranslationService] = useState(null);
	const { settings } = useSelector(state => state.settings);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const goBack = () => {
		if (pureLayoutBackUrl) {
			props.history.push(pureLayoutBackUrl);
			localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
		} else {
			window.history.back();
		}
	};

	return translationService ? (
		<header className="header relative-layout">
			<nav className="navbar site-navbar-heigth navbar-shadow justify-content-between brand-background">
				<Link to={`/${language}`} className="navbar-brand">
					{settings && settings.mainLogo ? (
						<img src={settings.mainLogo} alt="/" />
					) : (
						<MindalaySvg />
					)}
				</Link>
				<div className="nav-item navbar-nav align-items-center">
					<Link to="#" className="nav-link" onClick={() => goBack()}>
						{translationService.translate("TR_BACK")}
					</Link>
				</div>
			</nav>
		</header>
	) : null;
};

export default withRouter(PureHeader);
