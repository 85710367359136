import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackSvg from "../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "../../../Services/gamifyApiService";
import AlertService from "../../../Services/alertService";
import {
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import { ERROR_KEY } from "../../../Constants/mainKeys";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";

const buttonSpinnerId = uuid();
const LeaderBoard = props => {
	const dispatch = useDispatch();
	const { leaderboardId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [leaderBoard, setLeaderBoard] = useState(null);
	const [showMore, setShowMore] = useState(10);

	const elemenetposition = useMemo(() => {
		let position = 0;
		if (leaderBoard && leaderBoard.leaderbordInformation) {
			leaderBoard.leaderbordInformation.forEach((el, i) => {
				if (user?.studentId === el.id) {
					position = i;
				}
			});
		}
		return position;
	}, [leaderBoard]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getLeaderBoardById();
		return () => {
			setLeaderBoard([]);
		};
	}, []);

	const getLeaderBoardById = () => {
		if (!leaderboardId) {
			return false;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		GamifyApiService.getLeaderBoardById(leaderboardId)
			.then(response => {
				if (response && response.data) {
					let data = { ...response.data };
					setLeaderBoard(data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="content-title p-0">
						<div className="d-flex align-items-center">
							<Link
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{leaderBoard
									? leaderBoard.name
									: translationService.translate("TR_BACK")}
							</h2>
						</div>
					</div>
					<PageInfoBlock
						pageTitle="Leader Board"
						pageName="student_leaderboard_position"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{leaderBoard ? (
				<div className="row mt-3">
					<div className="col-12 col-lg-4 px-3">
						{leaderBoard.resource ? (
							<div className="my-2">
								<Card className="w-100 border-0">
									<div
										className="w-100"
										style={{
											backgroundImage: `url(${leaderBoard.resource?.imagePath})`,
											height: "250px",
											backgroundPosition: "center",
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
										}}
									/>
									<Card.Body>
										<h4 className="text-center mb-2">
											{leaderBoard.resource?.name}
										</h4>
										<p className="word-break-break-word text-center">
											{leaderBoard.resource?.description}
										</p>
									</Card.Body>
								</Card>
							</div>
						) : null}
					</div>
					<div className="col-12 col-lg-8 px-3">
						<h4>{leaderBoard.name}</h4>
						<p>{leaderBoard.description}</p>
						{leaderBoard &&
						leaderBoard.leaderbordInformation &&
						leaderBoard.leaderbordInformation.length ? (
							<div className="mindayal-admin-table table-responsive mt-4">
								<table className="table">
									<thead>
										<tr>
											<th>#</th>
											<th>{translationService.translate("TR_NAME")}</th>
											<th>{translationService.translate("TR_VALUE")}</th>
										</tr>
									</thead>
									<tbody>
										{leaderBoard.leaderbordInformation.map(
											(item, index, arr) => {
												return (
													<Auxiliary key={index}>
														<tr
															key={index}
															className={`cursor-default ${
																showMore >= index || user?.studentId === item.id
																	? ""
																	: "d-none"
															}`}>
															<td style={{ width: "40px" }}>{index + 1}</td>
															<td className="word-break-break-word d-flex align-items-center">
																{user?.studentId === item.id && (
																	<span
																		style={{
																			marginRight: "8px",
																			width: "10px",
																			height: "10px",
																			borderRadius: "50%",
																			backgroundColor:
																				"var(--mindalay--green-color)",
																		}}
																	/>
																)}
																{item.displayName ? item.displayName : " "}
															</td>
															<td className="word-break-break-word">
																{item.collectedPoints}
															</td>
														</tr>
														{user?.studentId === item.id &&
															elemenetposition > showMore &&
															elemenetposition !== showMore + 1 && (
																<tr
																	className="border-none"
																	onClick={() => {
																		if (
																			user?.studentId === item.id &&
																			leaderBoard.leaderbordInformation
																				.length !== showMore
																		) {
																			setShowMore(
																				leaderBoard.leaderbordInformation
																					.length,
																			);
																		} else if (index === showMore) {
																			setShowMore(elemenetposition);
																		}
																	}}>
																	<td
																		colSpan={3}
																		className="border-none text-center">
																		<span
																			className="tex"
																			style={{ textDecoration: "unde" }}>
																			{translationService.translate(
																				"TR_SHOW_MORE",
																			)}
																		</span>
																	</td>
																</tr>
															)}
														{index === showMore && showMore !== arr.length && (
															<tr
																className="border-none"
																onClick={() => {
																	if (
																		user?.studentId === item.id &&
																		leaderBoard.leaderbordInformation.length !==
																			showMore
																	) {
																		setShowMore(
																			leaderBoard.leaderbordInformation.length,
																		);
																	} else if (index === showMore) {
																		setShowMore(elemenetposition);
																	}
																}}>
																<td
																	colSpan={3}
																	className="border-none text-center">
																	<span
																		className="tex"
																		style={{ textDecoration: "unde" }}>
																		{translationService.translate(
																			"TR_SHOW_MORE",
																		)}
																	</span>
																</td>
															</tr>
														)}
														{/* {((index === showMore &&
														elemenetposition > showMore &&
														elemenetposition !== showMore + 1) ||
														(user?.studentId === item.id &&
															leaderBoard.leaderbordInformation.length >
																elemenetposition &&
															elemenetposition > showMore &&
															leaderBoard.leaderbordInformation.length !==
																showMore)) && (
														<tr
															className="border-none"
															onClick={() => {
																if (
																	user?.studentId === item.id &&
																	leaderBoard.leaderbordInformation.length !==
																		showMore
																) {
																	setShowMore(
																		leaderBoard.leaderbordInformation.length,
																	);
																} else if (index === showMore) {
																	setShowMore(elemenetposition);
																}
															}}>
															<td
																colSpan={3}
																className="border-none text-center">
																<span
																	className="tex"
																	style={{ textDecoration: "unde" }}>
																	{translationService.translate("TR_SHOW_MORE")}
																</span>
															</td>
														</tr>
													)} */}
													</Auxiliary>
												);
											},
										)}
									</tbody>
								</table>
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(LeaderBoard);
