import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import uuid from "react-uuid";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import { ERROR_KEY } from "../Constants/mainKeys";
import TranslationService from "../Services/translationService";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import MainService from "../Services/mainService";
import RolesService from "../Services/rolesService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import ModalComponentWithoutActionButton from "../Components/ModalComponent/modalComponentWithoutActionButton";

const setDate = (dateStr, monthArr = []) => {
	if (!dateStr) {
		return "";
	}
	const date = new Date(dateStr);
	let monthName = [
		"Январь",
		"Февраль",
		"Март",
		"Апрель",
		"Май",
		"Июнь",
		"Июль",
		"Август",
		"Сентябрь",
		"Октябрь",
		"Ноябрь",
		"Декабрь",
	][date.getMonth()];
	// this.translationService.translate(monthName).pipe(
	//   map((data: string) => monthName = data)
	// ).subscribe();
	return `${date.getDate()} ${monthName} ${date.getFullYear()}`;
};

function Documents(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { role, user } = useSelector(state => state.user);

	const [translationService, setTranslationService] = useState(null);
	const [roleService, setRoleService] = useState(null);
	const [documentCategory, setDocumentCategory] = useState([]);
	const [documentCategoryId, setDocumentCategoryId] = useState(null);
	const [organizationDocument, setOrganizationDocument] = useState([]);
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [documentTemplate, setDocumentTemplate] = useState(null);
	const [templateInfo, setTemplateInfo] = useState(null);
	const [body, setBody] = useState("");

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		getDocumentCategory();
	}, []);

	const cancel = () => {
		setSelectedDocument(null);
	};

	const getOrganizationDocument = categoryId => {
		if (!categoryId) return false;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrganizationDocument(categoryId)
			.then(response => {
				if (response && response.data) {
					setOrganizationDocument(response.data);
					setDocumentCategoryId(categoryId);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const setTemplateBody = (templateInfo, templateBody, templateData) => {
		let tableRowCount = 1,
			tableTds = [],
			monthArr = [];
		if (templateInfo.length) {
			templateInfo.forEach(info => {
				let dataValue = templateData[`a${info.position}`];
				if (dataValue === "Другое (вписать вручную)") {
					dataValue = "";
				}
				if (
					info.type === "organization-name" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					templateBody = templateBody.replace(
						`<p>{a${info.position}}</p>`,
						`<div class="center"><span class="organization-name-title">${dataValue}</span></div>`,
					);
				} else if (
					info.type === "input-number" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					templateBody = templateBody.replace(
						`<p>№ {a${info.position}}</p>`,
						`<div class="direction-right"><span class="template-data-field direction-right">№ ${dataValue}</span></div>`,
					);
					templateBody = templateBody.replace(
						`{a${info.position}}`,
						`${dataValue}`,
					);
				} else if (
					info.type === "input-create-date" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					if (dataValue) {
						dataValue = setDate(dataValue, monthArr);
						templateBody = templateBody.replace(
							`<p>{a${info.position}}</p>`,
							`<span class="template-data-field">${dataValue}</span>`,
						);
					} else {
						templateBody = templateBody.replace(
							`<p>{a${info.position}}</p>`,
							``,
						);
					}
				} else if (
					info.type === "input-date" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					if (dataValue) {
						dataValue = setDate(dataValue, monthArr);
						templateBody = templateBody.replace(
							`{a${info.position}}`,
							`${dataValue}`,
						);
					} else {
						templateBody = templateBody.replace(`{a${info.position}}`, ``);
					}
				} else if (
					info.type === "sign-row-arr" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					const usersList = dataValue;
					if (usersList.length) {
						templateBody = templateBody.replace(
							`{a${info.position}}`,
							`<div class="input-list"><div class="flexable sign-row-mrg"><span class="empty-row"></span><span class="template-data-field">${usersList[0]}</span></div></div>`,
						);
						for (let i = 1; i < usersList.length; i++) {
							if (i === 1) {
								templateBody = templateBody.replace(
									`${usersList[0]}</span></div></div>`,
									`${usersList[0]}</span></div><div class="flexable sign-row-mrg"><span class="empty-row"></span><span class="template-data-field">${usersList[i]}</span></div></div>`,
								);
							} else {
								const prevIndex = i - 1;
								templateBody = templateBody.replace(
									`${usersList[prevIndex]}</span></div></div>`,
									`${usersList[prevIndex]}</span></div><div class="flexable sign-row-mrg"><span class="empty-row"></span><span class="template-data-field">${usersList[i]}</span></div></div>`,
								);
							}
						}
					} else {
						templateBody = templateBody.replace(`{a${info.position}}`, ``);
					}
				} else if (
					typeof dataValue === "object" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					const usersList = dataValue;
					if (usersList.length) {
						templateBody = templateBody.replace(
							`<p>{a${info.position}}</p>`,
							`<div class="input-list"><span class="template-data-field">1. ${usersList[0]}</span></div>`,
						);
						templateBody = templateBody.replace(
							`{a${info.position}}`,
							`<div class="input-list"><span class="template-data-field">1. ${usersList[0]}</span></div>`,
						);
						for (let i = 1; i < usersList.length; i++) {
							if (i === 1) {
								templateBody = templateBody.replace(
									`${usersList[0]}</span></div>`,
									`${usersList[0]}</span><span class="template-data-field">2. ${usersList[i]}</span></div>`,
								);
							} else {
								const prevIndex = i - 1;
								const nextIndex = i + 1;
								templateBody = templateBody.replace(
									`${usersList[prevIndex]}</span></div>`,
									`${usersList[prevIndex]}</span><span class="template-data-field">${nextIndex}. ${usersList[i]}</span></div>`,
								);
							}
						}
					} else {
						templateBody = templateBody.replace(`{a${info.position}}`, ``);
					}
				} else if (
					info.type === "sign-row" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					templateBody = templateBody.replace(
						`{a${info.position}}`,
						'<span class="empty-row"></span>',
					);
				} else if (
					info.type === "table-input-string" ||
					info.type === "table-input-date" ||
					info.type === "table-input-number" ||
					info.type === "table-library" ||
					info.type === "table-list" ||
					info.type === "table-sign-part" ||
					info.type === "table-number-and-date" ||
					info.type === "table-input-start-end-time"
				) {
					tableTds.push(`<td>{a${info.position}}</td>`);
					if (templateData["tableData"].length) {
						templateBody = templateBody.replace(
							`<td>{a${info.position}}</td>`,
							`<td class="col${info.position}-${templateData.tableData[0].number}">{a${info.position}}</td>`,
						);
						templateBody = templateBody.replace(
							`<td>№ {a${info.position}}</td>`,
							`<td class="col${info.position}-${templateData.tableData[0].number}">№ {a${info.position}}</td>`,
						);
					} else {
						templateBody = templateBody.replace(
							`<td>№ {a${info.position}}</td>`,
							`<td></td>`,
						);
						templateBody = templateBody.replace(
							`<td>{a${info.position}}</td>`,
							`<td></td>`,
						);
					}
				} else if (
					!templateData[`a${info.position}`] &&
					info.type !== "sign-row"
				) {
					templateBody = templateBody.replace(`{a${info.position}}`, "");
				} else if (
					info.type === "input-date-to-right" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					templateBody = templateBody.replace(
						`<p>{a${info.position}}</p>`,
						`<div class="direction-right">${dataValue}</div>`,
					);
				} else if (
					info.type === "input-short-string" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					templateBody = templateBody.replace(
						`<p>{a${info.position}}</p>`,
						`${dataValue}`,
					);
				} else if (
					info.type === "drop-down-group-not-draw" &&
					templateBody.includes(`{a${info.position}}`)
				) {
					templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, ``);
				} else {
					templateBody = templateBody.replace(
						`{a${info.position}}`,
						`${dataValue}`,
					);
				}

				if (!dataValue) {
					templateBody = templateBody.replace(`<p>{a${info.position}}</p>`, ``);
				}
			});

			let tableData = templateData["tableData"];
			if (tableData && tableData.length) {
				templateBody = templateBody.replace("<tr>", `<tr class="row-th">`);
				templateBody = templateBody.replace(
					"<tr>",
					`<tr class="row-${tableData[0].number}">`,
				);

				tableData.forEach(data => {
					if (!templateBody.includes(`<tr class="row-${data.number}">`)) {
						let row = `<tr class="row-${
							data.number
						}"><td>${++tableRowCount}</td></tr>`;
						tableTds.forEach(tdItem => {
							const startIndexOfColumn = tdItem.indexOf("{a") + 2;
							const endIndexOfColumn = tdItem.indexOf("}");
							const column = tdItem.substring(
								startIndexOfColumn,
								endIndexOfColumn,
							);
							tdItem = tdItem.replace(
								"<td>",
								`<td class="col${column}-${data.number}">`,
							);
							row = row.replace("</tr>", `${tdItem}</tr>`);
						});
						const lastIndexTR = templateBody.lastIndexOf("</tr>");
						const lastIndexTBody = templateBody.lastIndexOf("</tbody>") + 8;
						const subStr = templateBody.substring(lastIndexTR, lastIndexTBody);
						templateBody = templateBody.replace(subStr, `</tr>${row}</tbody>`);
					}
					const dateData = templateInfo.find(
						info => info.position.toString() === data.columnNumber,
					);
					if (
						typeof data.value === "object" &&
						dateData.type === "table-number-and-date"
					) {
						if (data.value[0]) {
							if (
								templateBody.includes(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								)
							) {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${data.number}">№ ${data.value[0]}</td>`,
								);
								if (data.value[1]) {
									templateBody = templateBody.replace(
										`<td class="col${data.columnNumber}-${data.number}">№ ${data.value[0]}</td>`,
										`<td class="col${data.columnNumber}-${data.number}">№ ${
											data.value[0]
										} от ${setDate(data.value[1], monthArr)}</td>`,
									);
								}
							} else {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${tableRowCount}">№ ${data.value[0]}</td>`,
								);
								if (data.value[1]) {
									templateBody = templateBody.replace(
										`<td class="col${data.columnNumber}-${tableRowCount}">№ ${data.value[0]}</td>`,
										`<td class="col${data.columnNumber}-${data.number}">№ ${
											data.value[0]
										} от ${setDate(data.value[1], monthArr)}</td>`,
									);
								}
							}
						} else if (data.value[1]) {
							if (
								templateBody.includes(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								)
							) {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${
										data.number
									}">от ${setDate(data.value[1], monthArr)}</td>`,
								);
							} else {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${
										data.number
									}">от ${setDate(data.value[1])}</td>`,
								);
							}
						}
					} else if (dateData && dateData.type === "table-input-number") {
						if (
							templateBody.includes(
								`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
							)
						) {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`,
							);
						} else {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`,
							);
						}
						if (
							templateBody.includes(
								`<td class="col${data.columnNumber}-${data.number}">№ {a${data.columnNumber}}</td>`,
							)
						) {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${data.number}">№ {a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`,
							);
						} else {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${tableRowCount}">№ {a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">№ ${data.value}</td>`,
							);
						}
					} else if (dateData && dateData.type === "table-input-date") {
						if (
							templateBody.includes(
								`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
							)
						) {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">${setDate(
									data.value,
									monthArr,
								)}</td>`,
							);
						} else {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">${setDate(
									data.value,
									monthArr,
								)}</td>`,
							);
						}
					} else if (data.timeType && data.timeType === "start") {
						const timeData = tableData.find(
							time =>
								time.columnNumber == data.columnNumber &&
								time.number == data.number &&
								time.timeType === "end",
						);
						if (timeData) {
							if (
								templateBody.includes(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								)
							) {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${data.number}">${data.value} - ${timeData.value}</td>`,
								);
							} else {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${data.number}">${data.value} - ${timeData.value}</td>`,
								);
							}
						} else {
							if (
								templateBody.includes(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								)
							) {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${data.number}">${data.value} - </td>`,
								);
							} else {
								templateBody = templateBody.replace(
									`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
									`<td class="col${data.columnNumber}-${data.number}">${data.value} - </td>`,
								);
							}
						}
					} else if (!data.timeType) {
						if (
							templateBody.includes(
								`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
							)
						) {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${data.number}">{a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">${data.value}</td>`,
							);
						} else {
							templateBody = templateBody.replace(
								`<td class="col${data.columnNumber}-${tableRowCount}">{a${data.columnNumber}}</td>`,
								`<td class="col${data.columnNumber}-${data.number}">${data.value}</td>`,
							);
						}
					}
				});

				templateInfo.forEach(info => {
					for (let i = 0; i < tableData[tableData.length - 1].number + 1; i++) {
						templateBody = templateBody.replace(
							`<td class="col${info.position}-${i}">{a${info.position}}</td>`,
							`<td class="col${info.position}-${i}"></td>`,
						);
						templateBody = templateBody.replace(
							`<td class="col${info.position}-${i}">№ {a${info.position}}</td>`,
							`<td class="col${info.position}-${i}"></td>`,
						);
					}
				});
			} else {
				templateBody = templateBody.replace("<tr>", `<tr class="row-th">`);
			}

			templateBody = templateBody.replace("<td></td>", "");
			templateBody = templateBody.replace("<td>Действия</td>", "");
			templateBody = templateBody.replace(
				"<table",
				'<div class="draw-template-table-container"><table class="draw-template-table"',
			);
			templateBody = templateBody.replace("</table>", "</table></div>");
			templateBody = templateBody.replace(
				"<h1>",
				'<h1 class="center template-title">',
			);
			templateBody = templateBody.replace("<h2>", '<h2 class="center">');
			templateBody = templateBody.replace(
				"<h3>",
				'<h3 class="center template-title margin-h3">',
			);
			templateBody = templateBody.replace("<h3>", '<h3 class="doc-sub-title">');
			templateBody = templateBody
				.split("<p>")
				.join(`<p class="draw-flex-wrap">`);
			setBody(templateBody);
			return templateBody;
		}
	};

	const openModal = item => {
		setSelectedDocument(item);
		getDocumentTemplateInfoInTemplateId(item);
	};

	const getDocumentTemplateInfoInTemplateId = item => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getDocumentTemplateInfoInTemplateId(item.documentTemplateId)
			.then(response => {
				const templateInfo = response.data;
				getDocumentTemplate(
					item.documentTemplateId,
					templateInfo,
					JSON.parse(item.data),
				);

				// setTemplateInfo(response.data);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const getDocumentTemplate = (id, templateInfo, templateData) => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getDocumentTemplateById(id)
			.then(response => {
				if (response.data && response.data.body) {
					const templateBody = response.data.body;
					setTemplateBody(templateInfo, templateBody, templateData);
				}

				// setDocumentTemplate(response.data);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const getDocumentCategory = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentCategory()
			.then(response => {
				if (response && response.data) {
					setDocumentCategory(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	// const getDocumentTemplate()

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_EDUCATIONAL_MATERIALS")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{selectedDocument && (
				<ModalComponentWithoutActionButton
					title={selectedDocument.name}
					size={"xl"}
					cancel={cancel}>
					{selectedDocument.signFilePath && (
						<div>
							<div
								className="signed-image"
								style={{
									backgroundImage: `url(${selectedDocument.signFilePath})`,
								}}
							/>
						</div>
					)}
					{!selectedDocument.signFilePath && selectedDocument.filePath && (
						<div className="row  justify-content-center align-items-center">
							<div className="col-12">
								<div
									className="signed-image"
									style={{
										backgroundImage: `url(${selectedDocument.filePath})`,
									}}
								/>
							</div>
							<div className="col-12 col-lg-4 mt-4 d-flex  justify-content-center align-items-center">
								<a
									href={selectedDocument.filePath}
									download
									className="mindalay--btn-default">
									{translationService.translate("TR_DOWNLOAD")}
								</a>
							</div>
						</div>
					)}
					{body && (
						<div
							className="template-container"
							dangerouslySetInnerHTML={{ __html: body }}></div>
					)}
				</ModalComponentWithoutActionButton>
			)}

			{roleService &&
				roleService.checkRole &&
				roleService.checkRole("organizationdocument_create") && (
					<div className="row align-items-end justify-content-end">
						<div className="col-12 col-md-6  d-flex justify-content-end">
							<Link
								to={"#"}
								className="mindalay--btn-default mt-3"
								onClick={() => {
									// getselectedDocument();
								}}>
								Create{" "}
								{translationService.translate("TR_EDUCATIONAL_MATERIALS")}
							</Link>
						</div>
					</div>
				)}
			<div className="row mt-3">
				{documentCategory && !!documentCategory.length && (
					<div className="col-lg-5 col-md-12 col-12">
						<div className="row">
							<div className="col-12">
								<div className="mindayal-admin-table table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th className="text-nowrap">
													{translationService.translate("TR_DOCUMENT_TYPE")}
												</th>
												<th className="text-nowrap">
													{translationService.translate(
														"TR_DESCRIPTION_OR_NUMBER",
													)}
												</th>
											</tr>
										</thead>
										<tbody>
											{documentCategory.map((item, index) => {
												return (
													<tr
														key={index}
														onClick={() => {
															getOrganizationDocument(item.id);
														}}>
														<td>
															<div className="mindalay-admin-table-td-item ">
																{item.name}
															</div>
														</td>
														<td>
															<div className="mindalay-admin-table-td-item ">
																{item.description}
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
				{organizationDocument && !!organizationDocument.length && (
					<div className="col-lg-7 col-md-12 col-12">
						<div className="row">
							<div className="col-12">
								<div className="mindayal-admin-table table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th className="text-nowrap">
													{translationService.translate("TR_DOCUMENT_NAME")}
												</th>
												<th className="text-nowrap">
													{translationService.translate(
														"TR_DESCRIPTION_OR_NUMBER",
													)}
												</th>
												<th className="text-nowrap">
													{translationService.translate("TR_ACTIONS")}
												</th>
											</tr>
										</thead>
										<tbody>
											{organizationDocument.map((item, index) => {
												return (
													<tr key={index}>
														<td style={{ maxWidth: "100px" }}>
															<div
																className="mindalay-admin-table-td-item max-line-1"
																title={item.name}>
																{item.name}
															</div>
														</td>
														<td style={{ maxWidth: "100px" }}>
															<div
																className="mindalay-admin-table-td-item max-line-3"
																title={item.description}>
																{item.description}
															</div>
														</td>
														<td>
															<div className="table-action-btn-group d-flex align-items-center">
																<>
																	<Link
																		to={"#"}
																		onClick={() => {
																			openModal(item);
																		}}
																		className="table-action-btn add-btn">
																		<i
																			className="fa fa-download"
																			aria-hidden="true"
																		/>
																	</Link>
																	{roleService &&
																		roleService.checkRole &&
																		roleService.checkRole(
																			"organizationdocument_update",
																		) && (
																			<Link
																				to={"#"}
																				title={translationService.translate(
																					"TR_EDIT",
																				)}
																				onClick={e => {
																					e.stopPropagation();
																				}}
																				className="table-action-btn edit-btn">
																				<EditSvg />
																			</Link>
																		)}
																	{roleService &&
																		roleService.checkRole &&
																		roleService.checkRole(
																			"organizationdocument_delete",
																		) && (
																			<Link
																				to="#"
																				title={translationService.translate(
																					"TR_DELETE",
																				)}
																				className="table-action-btn delete-btn"
																				onClick={e => {
																					e.stopPropagation();
																					// deletedocumentTemplat(item);
																				}}>
																				<DeleteSvg />
																			</Link>
																		)}
																</>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	) : null;
}

export default withRouter(Documents);
