import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const carousel = (props) => {
  return (
    <Carousel
      arrows={props.arrows}
      responsive={props.responsive}
      customButtonGroup={props.customButtonGroup}
      infinite={props.infinite}
    >
      {props.children}
    </Carousel>
  );
};

export default carousel;
