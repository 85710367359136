import React from 'react';
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Footer from '../LayoutSections/Footer/footer';
import InstructorHeader from '../LayoutSections/Header/instructorHeader';
import ConsultantDashboardRoutes from '../../Routing/consultantDashboardRoutes';
import GeneralDashboardLeftMenu from '../LayoutSections/LeftMenu/GeneralDashboardLeftMenu';
import { withRouter } from 'react-router';

const ConsultantDashboardLayout = props => {
  const isFinancesPage = props.location.pathname.includes("finances") ? true : false;
  const isConsultantContractsPage = props.location.pathname.includes("/consultant/consultant-dashboard") ? true : false;
  const isConsultantInformationPage = props.location.pathname.includes("/consultant/consultant-information") ? true : false;
  return <Auxiliary>
    <InstructorHeader />
    <GeneralDashboardLeftMenu />
    <section className={
      isFinancesPage
        || isConsultantContractsPage
        || isConsultantInformationPage
        ? "instructior-dashboard-body--large"
        : "consultant-dashboard-body--large"}>
      <div className="top-section m-section wrapper">
        <ConsultantDashboardRoutes />
      </div>
      <Footer />
    </section>
  </Auxiliary>;
}

export default withRouter(ConsultantDashboardLayout);
