import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import MainService from "../../Services/mainService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Input from "../../Components/Inputs/input";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import CloseSvg from "../../Components/Svg/closeSvg";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import InfoSvg from "../../Components/Svg/infoSvg";
import { setCurrentCourse } from "../../Store/Actions/course";
import { addButtonSpinner, removeButtonSpinner, addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from "../../Constants/translationKeys";
import { COURSE_DASHBOARD_KEY, MESSAGES_KEY } from "../../Constants/urlKeys";
import AddFromLibraryModal from "./../../Pages/CourseTopics/Modals/addFromLibraryModal";
import AddFileLibraryModal from "./../../Pages/CourseTopics/Modals/addFileLibraryModal";
import {
  BOOLEAN_KEY,
  ERROR_KEY,
  NUMBER_KEY,
  SUCCESS_KEY,
  COURSE_LEVELS,
  COMPLETION_DATA,
  RETURN_URL_KEY,
} from "../../Constants/mainKeys";
import RichTextEditor from "../Questions/Components/RichTextEditor";

class CourseForm extends Component {
  _isMounted = false;
  mainService = new MainService();
  levels = COURSE_LEVELS;

  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: this.props.courseData && this.props.courseData.id,
        name: '',
        keywords: '',
        description: null,
        image: null,
        presentationFile: null,
        period: '',
        isLifeTime: '',
        languageId: null,
        removedThemeIds: [],
        newThemes: [],
        experienceLevel: 0,
        passRule: this.props.courseData.passRule ? this.props.courseData.passRule : 0,
        toBeCompletedQuizId: this.props.courseData && this.props.courseData.toBeCompletedQuizId,
        videoDuration: null
      },
      mainCategoryid: null,
      courseId: +this.props.match.params.courseId || null,
      initialSelectedCategories: [],
      selectedCategories: [],
      firstLevelCategoryId: null,
      secondLevelCategoryId: null,
      secondLevelCategories: [],
      thirdLevelCategoryId: null,
      thirdLevelCategories: [],
      courseData: this.props.courseData,
      isInvalidSubmit: false,
      uploadedImage: null,
      uploadedVideo: null,
      spinnerId: uuid(),
      nameLength: 200,
      courseKeyWordsLength: 200,
      descriptionMaxLength: 20000,
      translationService: null,
      failedFields: [],
      languages: [],
      courseCategories: [],
      courseQuizzes: [],
      currentUploadedImage: null,
      currentUploadedVideo: null,
      receivedImage: null,
      receivedVideo: null,
      isInvalidStartDate: false,
      isInvalidEndDate: false,
      isShowInfoAlert: false,
      changes: false,
      isInvalidCompletion: false,
      keywordsArray: [],
      contentLanguages: [],
      editor: '',
      descriptionMaxLengthError: false,
      showSelectFromFileLibraryModal: false,
      showAddNewFileLibraryModal: false,
      fileType: null,
      selectedImageFromLibrary: null,
      selectedVideoFromLibrary: null,
      courseVideo: null,
      courseImage: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.location.pathname.includes(RETURN_URL_KEY)) {
      this.props.history.push(this.props.location.pathname.slice(0, this.props.location.pathname.length - 10));
    }
    this.setTranslations();
    this.getContentLanguages();
    this.getQuizzesByCourseId();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevProps.languages !== this.state.languages) {
      this.setState({ languages: prevProps.languages })
    }
    if (prevProps.courseCategories !== this.state.courseCategories) {
      this.setState({ courseCategories: prevProps.courseCategories }, () => this.state.courseData && this.setCourseData());
    }
  }

  componentWillUnmount() {
    const { changes, translationService } = this.state
    if (changes && translationService && window.confirm(`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`)) {
      this.onSubmit();
    }
    this._isMounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getContentLanguages = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getContentLanguages().then(response => {
      this.props.removePageSpinner(spinnerId);
      if (response && response.data && response.data.libraryData) {
        this.setState({ contentLanguages: response.data.libraryData });
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  getQuizzesByCourseId = () => {
    const { courseId } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getQuizzesByCourseId(courseId).then(response => {
      this._isMounted && this.setState({ courseQuizzes: response.data });
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  };

  setCourseData = () => {
    const courseData = { ...this.state.courseData };
    const newThemes = [];
    const keywordsArray = courseData.keywords ? this.convertKeywordsToArray(courseData.keywords) : [];
    const selectedCategories = [];
    courseData.categories.forEach(data => selectedCategories.push({ categoryId: data.id, categoryName: data.name }));
    courseData.categories.forEach(data => newThemes.push({ categoryId: data.categoryId, themeId: data.id }));
    const selectedMainCategory = this.state.courseCategories.find(data => data.id === courseData.mainCategoryid);
    this.setState(prevState => ({
      ...prevState,

      courseImage: courseData.imagePath,
      courseVideo: courseData.presentationFilePath,

      initialSelectedCategories: courseData.categories,
      mainCategoryid: courseData.mainCategoryid,
      secondLevelCategories: selectedMainCategory?.children || [],
      selectedCategories,
      keywordsArray,
      form: {
        ...prevState.form,
        name: courseData.name || "",
        description: courseData.description ? courseData.description : "",
        period: courseData.period || "",
        isLifeTime: courseData.isLifeTime || false,
        languageId: courseData.languageId,
        experienceLevel: courseData.experienceLevel,
        newThemes,
        removedThemeIds: [],
        keywords: "",
      }
    }));
  }

  convertKeywordsToArray = (keywordsStr) => {
    const keywordsObj = JSON.parse(keywordsStr);
    const keywordsArray = [];
    for (const i in keywordsObj) {
      keywordsArray.push(keywordsObj[i])
    }
    return keywordsArray;
  }

  attachFileLibrary = file => {
    const { fileType } = this.state;
    if (!fileType || !file) { return false; }
    if (fileType === "image") {
      this.setState(prevState => ({
        ...prevState,
        showAddNewFileLibraryModal: false,
        showSelectFromFileLibraryModal: false,
        selectedImageFromLibrary: file,
        isInvalidSubmit: false,
        form: {
          ...prevState.form,
          imageFileId: file.id
        }
      }));
    }
    if (fileType === "video") {
      this.setState(prevState => ({
        ...prevState,
        showAddNewFileLibraryModal: false,
        showSelectFromFileLibraryModal: false,
        selectedVideoFromLibrary: file,
        currentUploadedVideo: file,
        form: {
          ...prevState.form,
          videoFileId: file.id,
        }
      }), () => {
        this.getVideoDuration();
      });
      if (this.state.form.passRule === 2) {
        this.setState({ isInvalidCompletion: false, isDisabledAddButton: false })
      }
    }
  };

  getVideoDuration = () => {
    var video = document.getElementById("course-video");
    video.onloadedmetadata = function () {
      getDuration(Math.floor(this.duration))
    };
    var getDuration = (duration) => {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          videoDuration: duration
        }
      }));
    }
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    const failedFields = this.removeFailedFields(event.target.name);
    this.setState(prevState => ({ ...prevState, failedFields, form: { ...prevState.form, [event.target.name]: event.target.value } }));
  };

  onEditorChange = (event) => {
    const { descriptionMaxLength } = this.state;
    const newRichText = event.editor.getData();
    if (newRichText.length > descriptionMaxLength) {
      this.setState({ descriptionMaxLengthError: true })
    } else this.setState({ descriptionMaxLengthError: false })
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        description: newRichText
      }
    }))
  }

  onDateChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value
      }
    }));
  };

  onNumberChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) >= 0)) {
      this.setState(prevState => ({ ...prevState, failedFields, form: { ...prevState.form, [event.target.name]: +event.target.value } }));
    }
  };

  onSelectChange = (selectedCategory, name) => {
    if (selectedCategory && name) {
      if (name === "languageId") {
        this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, [name]: selectedCategory.id } }))
      }
      if (name === "experienceLevel") {
        this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, [name]: selectedCategory.value } }))
      }
    } else { return; }
  }

  onReactSelectChange = (selectedItem, fieldName) => {
    const { courseQuizzes } = this.state;
    if (selectedItem) {
      const failedFields = this.removeFailedFields(fieldName);
      this.setState(
        prevState =>
        ({
          ...prevState,
          changes: true,
          failedFields,
          isInvalidCompletion: false,
          form:
          {
            ...prevState.form,
            [fieldName]: selectedItem.value
          }
        }));
    }

    if (fieldName === "passRule" && selectedItem.value === 1 && (!courseQuizzes || !courseQuizzes.length)) {
      this.setState({ isInvalidCompletion: true });
    }

    if (fieldName === "passRule" && +selectedItem.value === 2 && !this.state.selectedVideoFromLibrary && !this.state.courseImage) {
      //Pass video
      this.setState({ isInvalidCompletion: true, isDisabledAddButton: true })
    }
    if (fieldName === "passRule" && +selectedItem.value === 2 && (this.state.selectedVideoFromLibrary || this.state.courseVideo)) {
      //Pass video
      this.setState({ isInvalidCompletion: false, isDisabledAddButton: false })
    }

    if (courseQuizzes && courseQuizzes.length && fieldName === "passRule" && selectedItem.value === 1) {
      this.setState(
        prevState =>
        ({
          ...prevState,
          form:
          {
            ...prevState.form,
            toBeCompletedQuizId: courseQuizzes[0]?.id
          }
        }));
    }
  }

  onCheckboxChange = event => {
    if (typeof event.target.checked === BOOLEAN_KEY) {
      const failedFields = this.removeFailedFields(event.target.name);
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        isInvalidSubmit: false,
        form: {
          ...prevState.form,
          period: (event.target.name === 'isLifeTime' && event.target.checked) ? '' : this.state.period,
          [event.target.name]: event.target.checked
        }
      }));
    }
  }

  onFirstLevelCategoryChange = selectedCategory => {
    if (selectedCategory) {
      this.setState({
        changes: true,
        mainCategoryid: selectedCategory.id,
        secondLevelCategories: selectedCategory.children ? selectedCategory.children : selectedCategory.themeChildren,
        thirdLevelCategories: []
      });
    }
  }

  onSecondLevelCategoryChange = selectedCategory => {
    if (selectedCategory) {
      this.setState({
        changes: true,
        secondLevelCategoryId: selectedCategory.id,
        thirdLevelCategories: selectedCategory.children
          ? selectedCategory.children
          : selectedCategory.themeChildren
      });
    }
  }

  onThirdLevelCategoryChange = selectedCategory => {
    const { secondLevelCategoryId } = this.state;
    if (selectedCategory) {
      const selectedCategories = [...this.state.selectedCategories];
      const newThemes = [...this.state.form.newThemes];
      const removedThemeIds = [...this.state.form.removedThemeIds];

      const existInInitialCategories = this.state.initialSelectedCategories.find(data => data.id === selectedCategory.id);

      const existingRemoveCategoryIdIndex = removedThemeIds.indexOf(selectedCategory.id);
      existingRemoveCategoryIdIndex !== -1 && removedThemeIds.splice(existingRemoveCategoryIdIndex, 1);

      if (existInInitialCategories) {
        const existInSelectedCategories = selectedCategories.find(data => data.categoryId === selectedCategory.id);
        !existInSelectedCategories && selectedCategories.push({ categoryId: selectedCategory.id, categoryName: selectedCategory.name });
      } else {
        if (!newThemes.find(data => data.themeId === selectedCategory.id)) {
          newThemes.push({ categoryId: secondLevelCategoryId, themeId: selectedCategory.id });
          selectedCategories.push({ categoryId: selectedCategory.id, categoryName: selectedCategory.name });
        }
      }

      this.setState(prevState => ({
        ...prevState,
        changes: true,
        selectedCategories,
        thirdLevelCategoryId: selectedCategory.id,
        form: {
          ...prevState.form,
          newThemes,
          removedThemeIds,
        }
      }));
    }
  }

  removeCategory = categoryId => {
    if (categoryId) {
      let selectedCategories = [...this.state.selectedCategories];
      let newThemes = [...this.state.form.newThemes];
      const removedThemeIds = [...this.state.form.removedThemeIds];

      const existInInitialCategories = this.state.initialSelectedCategories.find(data => +data.id === +categoryId);

      const existingNewCategoryIdIndex = newThemes.findIndex(data => +data.categoryId === +categoryId);
      existingNewCategoryIdIndex !== -1 && newThemes.splice(existingNewCategoryIdIndex, 1);

      if (existInInitialCategories) {
        selectedCategories = selectedCategories.filter(data => +data.categoryId !== +categoryId);
        newThemes = newThemes.filter(data => +data.themeId !== +categoryId);
        removedThemeIds.push(categoryId);
      } else {
        const selectedCategoryIndex = selectedCategories.findIndex(data => +data.categoryId === +categoryId);
        selectedCategoryIndex !== -1 && selectedCategories.splice(selectedCategoryIndex, 1);
      }
      this.setState(prevState => ({
        ...prevState,
        selectedCategories,
        form: {
          ...prevState.form,
          newThemes,
          removedThemeIds,
        }
      }));
    }
  }

  addKeyword = () => {
    const { keywordsArray } = this.state;
    const { keywords } = this.state.form;
    if (keywords.trim()) {
      keywordsArray.push(keywords.trim());
    }
    this.setState(prevState => ({
      ...prevState,
      keywordsArray,
      form: {
        ...prevState.form,
        keywords: ""
      }
    }));
  }

  removeKeyword = (keyword) => {
    const keywordsArray = [...this.state.keywordsArray];
    const index = keywordsArray.indexOf(keyword);
    if (index > -1) {
      keywordsArray.splice(index, 1);
    }
    this.setState({ keywordsArray });
  }

  convertKeywordsToObject = (keywordsArray) => {
    const arr = [];
    var filteredArray = keywordsArray;
    filteredArray.forEach((str, index) => arr.push({ "key": index + 1, "value": str }));
    const object = arr.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
    return JSON.stringify(object);
  }

  convertArrayToString = (array) => {
    if (!Object.values(array[array.length - 1])[0]) {
      array = array.splice(0, array.length - 1);
    }
    return JSON.stringify(array.reduce((obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }), {}));
  };

  convertKeywordsToString = (string) => {
    const objects = JSON.parse(string);
    return objects && Object.values(objects).join(' ');
  }


  submitFail = (spinnerId, errors) => {
    this.props.removeButtonSpinner(spinnerId);
    const failedFields = errors;
    this.setState({ failedFields });
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  }

  closeModal = () => {
    this.setState({ showAddNewFileLibraryModal: false, showSelectFromFileLibraryModal: false });
  };

  removeImg = () => {
    this.setState(prevState => ({
      ...prevState,
      selectedImageFromLibrary: null,
      form: {
        ...prevState.form,
        imageFileId: null,
      }
    }));
  }

  removeVideo = () => {
    this.setState(prevState => ({
      ...prevState,
      selectedVideoFromLibrary: null,
      form: {
        ...prevState.form,
        videoFileId: null,
      }
    }));
  }

  setEditorRef = (ed) => {
    this.setState({ editor: ed });
  };

  scrollToInvalidFieldPosition = () => {
    setTimeout(() => {
      let firstInvalidControl =
        document.querySelector(".select-fail") ||
        document.querySelector(".is-invalid") ||
        document.querySelector(".error-bordered") ||
        document.querySelector(".error-bordered-2") ||
        document.querySelector(".fail");
      firstInvalidControl && window.scrollTo({
        top: firstInvalidControl?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
        behavior: "smooth"
      });
    }, 200);
  }

  onSubmit = event => {
    event && event.preventDefault();
    const {
      translationService,
      spinnerId,
      selectedCategories,
      isInvalidStartDate,
      isInvalidEndDate,
      isInvalidCompletion,
      keywordsArray,
      descriptionMaxLengthError
    } = this.state;
    const form = { ...this.state.form };
    if (descriptionMaxLengthError) { return; }
    this.setState({ changes: false });
    if (
      !selectedCategories.length ||
      form.name.trim().length < 3 ||
      !(form.period || form.isLifeTime) ||
      // !(form.image || this.state.uploadedImage) ||
      (!form.imageFileId && !this.state.courseImage) ||
      !form.languageId ||
      isInvalidStartDate ||
      isInvalidEndDate ||
      isInvalidCompletion
      // || (form.passRule === 1 && !form.toBeCompletedQuizId) ||
      // (form.passRule === 2 && !this.state.selectedVideoFromLibrary && !this.state.courseVideo)
    ) {
      this.setState({ isInvalidSubmit: true });
      this.scrollToInvalidFieldPosition();
    } else {
      form.experienceLevel = form.experienceLevel ? +form.experienceLevel : 0;
      form.keywords = keywordsArray ? this.convertKeywordsToObject(keywordsArray) : '';
      form.name = form.name.trim();
      form.description = form.description ? form.description.trim() : "";
      !form.toBeCompletedQuizId && delete form.toBeCompletedQuizId;
      !form.presentationFile && delete form.presentationFile;
      form.image = this.handleSave() ? this.handleSave() : delete form.image;
      form.videoDuration = form.videoDuration ? form.videoDuration : null;
      form.newThemes = form.newThemes ? JSON.stringify(form.newThemes) : null;

      if (form.isLifeTime) delete form.period;
      if (!form.videoDuration) delete form.videoDuration;
      const formData = new FormData();
      for (let i in form) {
        if (i !== "removedThemeIds") {
          formData.append(`${i}`, form[i]);
        }
      }

      for (let i in form.removedThemeIds) {
        formData.append("removedThemeIds", form.removedThemeIds[i]);
      }
      this.props.addButtonSpinner(spinnerId);
      ApiService.courseUpdate(formData).then(response => {
        AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
        this.props.setCurrentCourse(response.data);
        this.props.removeButtonSpinner(spinnerId);
        this.setState({ courseData: response.data }, () => this.setCourseData());
        this.props.history.push(`/${this.props.language}/${COURSE_DASHBOARD_KEY}/${this.props.courseData.id}/${MESSAGES_KEY}`);
      }).catch(errors => this.submitFail(spinnerId, errors));
    }
  }

  handleSave = () => {
    if (this.state.editor && this.state.form.image) {
      const canvasScaled = this.state.editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      var arr = croppedImg.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], this.state.form.image.name, { type: mime }) || null;
    }
  };


  render() {

    const {
      isInvalidSubmit,
      spinnerId,
      nameLength,
      translationService,
      failedFields,
      courseData,
      courseKeyWordsLength,
      courseCategories,
      selectedCategories,
      secondLevelCategories,
      thirdLevelCategories,
      courseQuizzes,
      mainCategoryid,
      secondLevelCategoryId,
      thirdLevelCategoryId,
      isInvalidCompletion,
      courseId,
      keywordsArray,
      contentLanguages,
      descriptionMaxLengthError,
      showSelectFromFileLibraryModal,
      showAddNewFileLibraryModal,
      fileType,
      selectedImageFromLibrary,
      selectedVideoFromLibrary,
      courseVideo,
      courseImage,
    } = this.state;

    const {
      id,
      name,
      keywords,
      description,
      period,
      isLifeTime,
      languageId,
      experienceLevel,
      passRule,
      toBeCompletedQuizId,
    } = this.state.form;

    return translationService && courseData && contentLanguages.length ? <Auxiliary>
      <div className="content-title d-block">
        <h2 className="section-title">{translationService.translate("TR_COURSE_GENERAL_INFO")}</h2>
      </div>
      <hr />
      <div className="content-body pt-0">
        <form onSubmit={this.onSubmit} onChange={() => this.setState({ changes: true })}>
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="form-group">
                <label>{`${translationService.translate("TR_CATEGORY")} *`}</label>
                <ReactSelectOption
                  placeholder=" "
                  isInvalidSubmit={!selectedCategories.length ? isInvalidSubmit : null}
                  isDisabled={(selectedCategories && selectedCategories.length) || !courseCategories.length}
                  selectedValue={(() => {
                    const selectedItem = { ...courseCategories.find(data => +data.id === +mainCategoryid) };
                    if (selectedItem) {
                      selectedItem.label = selectedItem.name;
                      selectedItem.value = selectedItem.id;
                    }
                    return selectedItem;
                  })()}
                  items={courseCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                  onChange={this.onFirstLevelCategoryChange}
                  failedFields={failedFields}
                />
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="form-group">
                <label>{`${translationService.translate("TR_SUBCATEGORY")} *`}</label>
                <ReactSelectOption
                  placeholder=" "
                  isInvalidSubmit={!selectedCategories.length ? isInvalidSubmit : null}
                  isDisabled={!secondLevelCategories.length}
                  items={secondLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                  selectedValue={(() => {
                    const selectedValue = { ...secondLevelCategories.find(data => data.id === secondLevelCategoryId) };
                    if (selectedValue) {
                      selectedValue.label = selectedValue.name;
                      selectedValue.value = selectedValue.id;
                    }
                    return selectedValue;
                  })()}
                  onChange={this.onSecondLevelCategoryChange}
                  failedFields={failedFields}
                />
              </div>
            </div>
            {
              thirdLevelCategories ?
                <div className="col-lg-4 col-12">
                  <div className="form-group">
                    <label>{`${translationService.translate("TR_THEMES")} *`}</label>
                    <ReactSelectOption
                      placeholder=" "
                      isInvalidSubmit={!selectedCategories.length ? isInvalidSubmit : null}
                      isDisabled={!thirdLevelCategories?.length}
                      items={thirdLevelCategories?.map(data => ({ ...data, label: data.name, value: data.id }))}
                      onChange={this.onThirdLevelCategoryChange}
                      selectedValue={(() => {
                        const selectedValue = { ...thirdLevelCategories?.find(data => data.id === thirdLevelCategoryId) };
                        if (selectedValue) {
                          selectedValue.label = selectedValue.name;
                          selectedValue.value = selectedValue.id;
                        }
                        return selectedValue;
                      })()}
                      failedFields={failedFields}
                    />
                  </div>
                </div>
                : null
            }
            <div className="col-12 mb-2">
              {
                isInvalidSubmit && !selectedCategories.length
                  ? <small className="red-color">{translationService.translate("TR_EMPTY_CATEGORIES_WARNING_MSG")}</small> : null
              }
              <div className="categories-wrapper">
                {
                  selectedCategories && selectedCategories.map(category => <div key={category.categoryId} className="selected-category">
                    {category.categoryName}
                    <CloseSvg onClick={() => this.removeCategory(category.categoryId)} />
                  </div>)
                }
              </div>
            </div>
            <div className="col-12">
              {
                failedFields && failedFields.hasOwnProperty("CategoryIds")
                  ? <small className="small-error-msg">{failedFields["CategoryIds"]}</small>
                  : null
              }
              <small>{translationService.translate("TR_YOU_CAN_SELECT_A_LOT_OF_CATEGORIES")}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-12"><hr /></div>
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                type="text"
                id="name"
                name="name"
                value={name}
                inputClassName="pr--5"
                isInvalidSubmit={isInvalidSubmit}
                isInvalidField={isInvalidSubmit && name.trim().length < 3 ? true : false}
                labelValue={`${translationService.translate("TR_COURSE_TITLE")} *`}
                fieldLength={nameLength}
                onChange={event => this.onChange(event, nameLength)}
                failedFields={failedFields}
              />
              {
                name.length < 3 && isInvalidSubmit ?
                  <small className="red-color">{translationService.translate("TR_AT_LASTED_THREE_CHARACTERS")}</small>
                  : null
              }
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Input
                  type="text"
                  id="keywords"
                  name="keywords"
                  blockClassName="w-100"
                  infoText={translationService.translate("TR_COURSE_KEYWORDS_INFO")}
                  value={keywords}
                  showPlusSvg={true}
                  onPlusSvgClick={this.addKeyword}
                  inputClassName="pr--5"
                  labelValue={translationService.translate("TR_COURSE_KEY_WORDS")}
                  fieldLength={courseKeyWordsLength}
                  onChange={event => this.onChange(event, courseKeyWordsLength)}
                  failedFields={failedFields}
                />
              </div>
            </div>
            {
              keywordsArray && keywordsArray.length ?
                <div className="col-12">
                  <div className="d-flex flex-wrap mb-3">
                    {
                      keywordsArray.map((keyword, index) => {
                        return <div key={index} className="selected-item-container">
                          <div className="selected-item-wrapper">
                            <div className="selected-item" title={keyword}>
                              <p className="keyword-description">{keyword}</p>
                            </div>
                            <button type="button" className="mindalay--btn-blue" onClick={() => this.removeKeyword(keyword)}>
                              <CloseSvg />
                            </button>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
                : null
            }
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label>{`${translationService.translate("TR_COURSE_LANGUAGE")} *`}</label>
                <ReactSelectOption
                  selectedValue={(() => {
                    const selectedItem = { ...contentLanguages.find(data => +data.id === +languageId) };
                    if (selectedItem) {
                      selectedItem.label = selectedItem.displayName;
                      selectedItem.value = selectedItem.languageId;
                    }
                    return selectedItem;
                  })()}
                  value={languageId}
                  isInvalidSubmit={isInvalidSubmit}
                  items={contentLanguages.map(data => ({ ...data, label: data.displayName, value: data.id }))}
                  onChange={(item) => this.onSelectChange(item, "languageId")}
                  failedFields={failedFields}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label>{`${translationService.translate("TR_LEVEL")} *`}</label>
                <ReactSelectOption
                  selectedValue={{ label: translationService.translate(this.levels[experienceLevel]), value: experienceLevel }}
                  items={this.levels.map((data, index) => ({ label: translationService.translate(data), value: index }))}
                  onChange={(item) => this.onSelectChange(item, "experienceLevel")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {
                (courseId && description) || description !== null ?
                  <Auxiliary>
                    <label>{translationService.translate("TR_DESCRIPTION")}</label>
                    <div className="info-title d-inline-block">
                      <div>
                        <InfoSvg />
                        <span style={{ display: "none" }}>
                          {translationService.translate("TR_CREATE_COURSE_DESC_INFO")}
                        </span>
                      </div>
                    </div>
                    <div className={descriptionMaxLengthError ? "course-description-error" : ""}>
                      <RichTextEditor
                        value={description}
                        config={{
                          toolbar: [
                            ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'],
                            ['Undo', 'Redo'],
                            ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
                            ['Styles', 'Format'],
                            // ['Maximize']
                          ],
                        }}
                        onEditorChange={(event) => this.onEditorChange(event)}
                      />
                    </div>
                    {
                      descriptionMaxLengthError ? <small className="red-color mt-2">{translationService.translate("TR_LONG_DESCRIPTION_MESSAGE")}</small> : null
                    }
                  </Auxiliary>
                  : null
              }
            </div>
          </div>
          <div className="row"><div className="col-12"><hr /></div></div>
          <div className="row">
            {
              !isLifeTime ? <div className="col-md-6 col-12">
                <Input
                  type="number"
                  id="period"
                  name="period"
                  min="0"
                  infoText={translationService.translate("TR_DURATION_DAYS_INFO")}
                  value={period}
                  labelValue={`${translationService.translate("TR_DURATION_DAYS")}*`}
                  onChange={this.onNumberChange}
                  failedFields={failedFields}
                  isInvalidSubmit={isInvalidSubmit}
                />
              </div> : null
            }
            <div className="col-12 d-flex align-items-center">
              <InputCheckBox
                id="isLifeTime"
                name="isLifeTime"
                checked={isLifeTime}
                labelValue={translationService.translate("TR_IS_LIFE_TIME")}
                onChange={this.onCheckboxChange}
                failedFields={failedFields}
              />
            </div>
          </div>
          {
            // courseQuizzes?.length ?
            <Auxiliary>
              <div className="row"><div className="col-12"><hr /></div></div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>{translationService.translate("TR_COMPLETION")}</label>
                    <div className="info-title d-inline-block">
                      <div>
                        <InfoSvg />
                        <span style={{ display: "none" }}>
                          {translationService.translate("TR_COMPLETION_INFO")}
                        </span>
                      </div>
                    </div>
                    <ReactSelectOption
                      value={passRule}
                      selectedValue={{ label: translationService.translate(COMPLETION_DATA[passRule]), value: passRule }}
                      items={COMPLETION_DATA.map((data, index) => ({ label: translationService.translate(data), value: index }))}
                      isInvalidField={
                        (isInvalidCompletion && passRule === 1 && !courseQuizzes?.length ? true : false) ||
                        (isInvalidCompletion && passRule === 2 && !this.state.selectedVideoFromLibrary && !this.state.courseVideo ? true : false)
                      }
                      onChange={item => this.onReactSelectChange(item, "passRule")}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {
                    passRule === 1 && courseQuizzes?.length ?
                      <div className="form-group">
                        <label>{`${translationService.translate("TR_CHOOSE_QUIZ")} *`}</label>
                        <ReactSelectOption
                          value={toBeCompletedQuizId}
                          isInvalidSubmit={isInvalidSubmit}
                          selectedValue={(() => {
                            const selectedItem = { ...courseQuizzes.find(data => data.id === toBeCompletedQuizId) };
                            if (selectedItem) {
                              selectedItem.value = selectedItem.id;
                              selectedItem.label = selectedItem.name;
                            }
                            return selectedItem;
                          })()}
                          items={courseQuizzes.map(data => ({ ...data, label: data.name, value: data.id }))}
                          onChange={item => this.onReactSelectChange(item, "toBeCompletedQuizId")}
                        />
                      </div>
                      : null
                  }
                </div>
              </div>
            </Auxiliary>
            //  : null
          }
          {
            passRule === 1 && !courseQuizzes?.length ?
              <small className="red-color">{translationService.translate("TR_YOU_DONT_HAVE_ANY_CREATED_QUIZ")}</small>
              : null
          }
          {
            passRule === 2 && !this.state.selectedVideoFromLibrary && !this.state.courseVideo ?
              <small className="red-color">{translationService.translate("TR_YOU_DONT_HAVE_UPLOADED_VIDEO")}</small>
              : null
          }
          <div className="row mt-3">
            <div className="col-lg-6 col-md-6 col-12">
              <button
                type="button"
                className={
                  `mindalay--btn-secondary w-100 mb-2 
                  ${fileType === "image" ? "active-upload-button" : "border-2-transparent"}
                  ${isInvalidSubmit && !selectedImageFromLibrary && !this.state.courseImage ? "error-bordered-2" : ""}
                  `
                }
                onClick={() => this.setState({ fileType: "image" })}
              >
                {translationService.translate("TR_ATTACH_IMAGE")} *
              </button>
              {
                isInvalidSubmit && !selectedImageFromLibrary && !this.state.courseImage ?
                  <small className="red-color">{translationService.translate("TR_REQUIRED_FIELD")}</small>
                  : null
              }
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <button
                type="button"
                className={`mindalay--btn-secondary w-100 mb-2 ${fileType === "video" ? "active-upload-button" : "border-2-transparent"}`}
                onClick={() => this.setState({ fileType: "video" })}
              >
                {translationService.translate("TR_ATTACH_VIDEO")}
              </button>
            </div>
          </div>
          <Auxiliary>
            <hr />
            {
              fileType ?
                <div className={`row ${fileType === "image" ? "justify-content-start" : "justify-content-end"}`}>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div>
                      <button
                        type="button"
                        className={`mindalay--btn-default w-100 mb-2`}
                        onClick={() => this.setState({ showSelectFromFileLibraryModal: true })}
                      >
                        {translationService.translate("TR_ADD_FROM_LIBRARY")}
                      </button>
                      {
                        showSelectFromFileLibraryModal
                          ? <AddFromLibraryModal
                            fileType={fileType}
                            cancel={this.closeModal}
                            added={this.attachFileLibrary}
                            isShowAddNewFileLibraryModal={this.isShowAddNewFileLibraryModal}
                          /> : null
                      }

                    </div>
                    <div>
                      <button
                        type="button"
                        className={`mindalay--btn-default w-100 mb-2`}
                        onClick={() => this.setState({ showAddNewFileLibraryModal: true })}
                      >
                        {translationService.translate("TR_ADD_NEW_FILE")}
                      </button>
                      {
                        showAddNewFileLibraryModal
                          ? <AddFileLibraryModal
                            fileType={fileType}
                            cancel={this.closeModal}
                            added={this.attachFileLibrary}
                            name={name}
                          /> : null
                      }
                    </div>
                  </div>
                </div>
                : null
            }


            {/* Selected image-video block */}
            <div className="row">

              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                {
                  selectedImageFromLibrary || courseImage ?
                    <div className="course-media-wrapper">
                      <div className="upload-file-content">
                        {
                          selectedImageFromLibrary ? <div onClick={() => this.removeImg()}><CloseSvg /></div> : null
                        }
                        <div
                          className="img"
                          style={{ backgroundImage: `url(${selectedImageFromLibrary && selectedImageFromLibrary.filePath ? selectedImageFromLibrary.filePath : courseImage ? courseImage : null})` }}
                          alt="course-image"
                        />
                      </div>
                    </div>
                    : null
                }
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                {
                  selectedVideoFromLibrary || courseVideo ?
                    <div className="course-media-wrapper">
                      <div className="upload-file-content">
                        {
                          selectedVideoFromLibrary ? <div onClick={() => this.removeVideo()}><CloseSvg controls={true} /></div> : null
                        }
                        <video
                          id="course-video"
                          src={
                            selectedVideoFromLibrary && selectedVideoFromLibrary.filePath ?
                              selectedVideoFromLibrary.filePath :
                              courseVideo ?
                                courseVideo : null
                          }
                          alt="course-image"
                          controls
                        />
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
          </Auxiliary>

          <div className="text-right mt-4">
            <SubmitButton id={id} spinnerId={spinnerId} className="col-12 col-sm-8 col-md-4" />
          </div>
        </form>
      </div>
    </Auxiliary> : null
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  languages: state.language.languages,
  courseCategories: state.course.courseCategories,
  courseData: state.course.currentCourse,
  user: state.user.user
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner,
  setCurrentCourse,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CourseForm);