import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import TranslationService from "./../../Services/translationService";
import { Helmet } from "react-helmet";
import { MINDALAY_KEY } from "./../../Constants/mainKeys";

const ReactHelmet = props => {
	const { translations } = useSelector(state => state.translation);
	const { settings } = useSelector(state => state.settings);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const currentUrl = props.history.location.pathname;

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const checkTabTitle = () => {
		if (!translationService) {
			return;
		}
		switch (true) {
			case currentUrl.includes("/login"):
				return `${translationService.translate(
					`TR_SIGN_IN`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/forgot-password"):
				return `${translationService.translate(
					`TR_FORGOT_PASSWORD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/user/changecredentials"):
				return `${translationService.translate(
					`TR_RESET_PASSWORD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/registration"):
				return `${translationService.translate(
					`TR_REGISTRATION`,
				)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/10"):
			//   return `${translationService.translate(`TR_FINANCE_ACCOUNTING_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/11"):
			//   return `${translationService.translate(`TR_DEVELOPMENT_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/12"):
			//   return `${translationService.translate(`TR_BUSINESS_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/20"):
			//   return `${translationService.translate(`TR_IT_SOFTWARE_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/21"):
			//   return `${translationService.translate(`TR_OFFICE_PRODUCTIVITY_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/22"):
			//   return `${translationService.translate(`TR_PERSONAL_DEVELOPMENT_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/23"):
			//   return `${translationService.translate(`TR_DESIGN_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/24"):
			//   return `${translationService.translate(`TR_MARKETING_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/25"):
			//   return `${translationService.translate(`TR_LIFESTYLE_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/26"):
			//   return `${translationService.translate(`TR_PHOTOGRAPHY_VIDEO_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/27"):
			//   return `${translationService.translate(`TR_HEALTH_FITNESS_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/28"):
			//   return `${translationService.translate(`TR_MUSIC_COURSES`)} | ${MINDALAY_KEY}`;
			// case currentUrl.includes("/category/150"):
			//   return `${translationService.translate(`TR_TEACHING_ACADEMICS_COURSES`)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/notifications"):
				return `${translationService.translate(
					`TR_NOTIFICATIONS`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/wish-list"):
				return `${translationService.translate(
					`TR_WISH_LIST`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/shopping-cart"):
				return `${translationService.translate(
					`TR_SHOPPING_CART`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/all-messages"):
				return `${translationService.translate(
					`TR_MESSAGES`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/tickets"):
				return `${translationService.translate(
					`TR_TICKETS`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/edit-profile"):
				return `${translationService.translate(
					`TR_EDIT_PROFILE`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/all-consultations"):
				return `${translationService.translate(
					`TR_CONSULTATIONS`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/consultant-feed"):
				return `${translationService.translate(
					`TR_CONSULTANT_FEED`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/consultant/consultant-information"):
				return `${translationService.translate(
					`TR_CONSULTANT_DASHBOARD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/instructor/courses/view"):
				return `${translationService.translate(
					`TR_INSTRUCTOR_DASHBOARD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/instructor/courses/question-bank"):
				return `${translationService.translate(
					`TR_INSTRUCTOR_DASHBOARD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/courses/file-library"):
				return `${translationService.translate(
					`TR_INSTRUCTOR_DASHBOARD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/consultant/finances"):
				return `${translationService.translate(
					`TR_FINANCES`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/consultant/finances/overview"):
				return `${translationService.translate(
					`TR_PAYMENT_HISTORY`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/instructor/finances"):
				return `${translationService.translate(
					`TR_FINANCES`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/instructor/finances/overview"):
				return `${translationService.translate(
					`TR_PAYMENT_HISTORY`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/student/finances"):
				return `${translationService.translate(
					`TR_FINANCES`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/student/overview"):
				return `${translationService.translate(
					`TR_PAYMENT_HISTORY`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/help"):
				return `${translationService.translate(
					`TR_HELP_CENTER`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/teach-in-mindalay/info"):
				return `${translationService.translate(
					`TR_BECOME_INSTRUCTOR`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/pure/become-instructor"):
				return `${translationService.translate(
					`TR_BECOME_INSTRUCTOR`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/instructor/courses/filelibrarycategory/form"):
				return `${translationService.translate(
					`TR_INSTRUCTOR_DASHBOARD`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/become-consultant/info"):
				return `${translationService.translate(
					`TR_BECOME_A_CONSULTANT`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/pure/become-consultant"):
				return `${translationService.translate(
					`TR_BECOME_A_CONSULTANT`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/mindalay-for-institutions/info"):
				return `${translationService.translate(
					`TR_MINDALAY_FOR_INSTITUTIONS`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/learn-in-mindalay/info"):
				return `${translationService.translate(
					`TR_ONLINE_LEARNING_PLATFORM`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/create-consultation"):
				return `${translationService.translate(
					`TR_CREATE_CONSULTATION`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/student-consultation-dashboard"):
				return `${translationService.translate(
					`TR_CONSULTATION_CONTRACTS`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/consultant/consultant-dashboard"):
				return `${translationService.translate(
					`TR_CONSULTATION_CONTRACTS`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/create-onetime-webinar"):
				return `${translationService.translate(
					`TR_CREATE_VIDEO_CONVERANCE`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/video-conference") &&
				!currentUrl.includes("/video-conference-room"):
				return `${translationService.translate(
					`TR_CREATE_VIDEO_CONVERANCE`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/video-conference-room"):
				return `${translationService.translate(
					`TR_JOIN_VIDEO_CONFERENCE`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/pure/createcourse"):
				return `${translationService.translate(
					`TR_CREATE_COURSE`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/coursedashboard/"):
				return `${translationService.translate(
					`TR_UPDATE_COURSE`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/checkout"):
				return `${translationService.translate(
					`TR_CHECKOUT`,
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/contact-us"):
				return `${translationService.translate(
					`TR_CONTACT_US`,
				)} | ${MINDALAY_KEY}`;
			//org users
			case user && user.isOrganizationUser && currentUrl.includes("/course"):
				return `${translationService.translate(
					`TR_COURSES`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/organization/coursedashboard"):
				return `${translationService.translate(
					`TR_CREATE_COURSE`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/filelibrary"):
				return `${translationService.translate(
					`TR_FILE_LIBRARY`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/message"):
				return `${translationService.translate(
					`TR_MESSAGES`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/question"):
				return `${translationService.translate(
					`TR_QUESTIONS`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/webinar"):
				return `${translationService.translate(
					`TR_WEBINARS`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/ongoing-webinars"):
				return `${translationService.translate(
					`TR_ONGOING_WEBINARS_PAGE`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/student"):
				return `${translationService.translate(
					`TR_STUDENTS`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/document"):
				return `${translationService.translate(
					`TR_DOCUMENTS`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/group"):
				return `${translationService.translate(`TR_GROUPS`)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/payment-package") &&
				!currentUrl.includes("/history"):
				return `${translationService.translate(
					`TR_PAYMENT_PACKAGES`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/organization-admin/history"):
				return `${translationService.translate(
					`TR_PAYMENT_PACKAGE_HISTORY`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/organization-admin/info"):
				return `${translationService.translate(
					`TR_INFO_PAGE`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/organization-admin/settings"):
				return `${translationService.translate(
					`TR_SETTINGS`,
				)} | ${MINDALAY_KEY}`;
			case user && user.isOrganizationUser && currentUrl.includes("/overview"):
				return `${translationService.translate(
					`TR_OVERVIEW`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/educational-unit"):
				return `${translationService.translate(
					`TR_EDUCATIONAL_UNIT_PAGE`,
				)} | ${MINDALAY_KEY}`;
			// case user && user.isOrganizationUser && currentUrl.includes("/division"):
			// 	return `${translationService.translate(
			// 		`TR_DIVISIONS`,
			// 	)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				currentUrl.includes("/user-types"):
				return `${translationService.translate(
					`TR_USERTYPES`,
				)} | ${MINDALAY_KEY}`;
			case user &&
				user.isOrganizationUser &&
				(currentUrl.includes("/users") || currentUrl.includes("/user")):
				return `${translationService.translate(`TR_USERS`)} | ${MINDALAY_KEY}`;

			case currentUrl.includes("/gamification"):
				return `${translationService.translate(
					"TR_GAMIFICATION_ICON",
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/organization/payment/schedules"):
				return `${translationService.translate(
					"TR_PAYMENT_SCHEDULES",
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("/organization-admin/finances"):
				return `${translationService.translate(
					"TR_FINANCES",
				)} | ${MINDALAY_KEY}`;
			case currentUrl.includes("division"):
				return `${translationService.translate("TR_GROUPS")} | ${MINDALAY_KEY}`;
			default:
				if (settings && settings.title) {
					return `${settings.title} | ${MINDALAY_KEY}`;
				} else {
					return MINDALAY_KEY;
				}
		}
	};

	const getTitle = () => {
		let title = checkTabTitle();
		if (title && title.length) {
			title = title[0].toUpperCase() + title.substring(1);
		}
		return title;
	};
	return translationService ? (
		<div>
			<Helmet>
				<title>{getTitle()}</title>
			</Helmet>
		</div>
	) : null;
};

export default withRouter(ReactHelmet);
