import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import RatingRadioInput from "../../../Components/Inputs/ratingRadioInput";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import AlertService from "../../../Services/alertService";
import ApiService from "../../../Services/apiService";
import TranslationService from "../../../Services/translationService";
import InputRadio from "../../../Components/Inputs/inputRadio";
import {
	addPageSpinner,
	addPartialViewSpinner,
	removePageSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import { COURSES_KEY } from "../../../Constants/urlKeys";
import {
	COURSE_LEVELS,
	ERROR_KEY,
	MINDALAY_KEY,
	OBJECT_KEY,
} from "../../../Constants/mainKeys";
import Parser from "html-react-parser";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import ParserService from "../../../Services/parserService";
import ParserComponent from "../../../Components/ParserComponent/ParserComponent";

const CoursesByCategoryView = props => {
	// const location = useLocation();
	const pageSize = 10;
	const history = useHistory();
	const dispatch = useDispatch();
	const levelsData = COURSE_LEVELS;

	const { mainCategoryId, categoryId, subcategoryId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { serachText } = useSelector(state => state.search);
	const { courseCategories } = useSelector(state => state.course);

	const [translationService, setTranslationService] = useState(null);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [courseCount, setCourseCount] = useState(false);
	const [mainCategoryName, setMainCategoryName] = useState(false);

	// submit filter form params
	// const [mainCategoryId, setMainCategoryId] = useState(null);
	const [categoryIds, setCategoryIds] = useState(null);
	const [subCategoryIds, setSubCategoryIds] = useState(null);
	const [languageIds, setLanguageIds] = useState(null);
	const [experienceLevels, setExperienceLevels] = useState(null);
	const [price, setPrice] = useState(0);
	const [rating, setRating] = useState(null);
	const [ordering, setOrdering] = useState(1);

	// getted filter data from the get request
	const [categories, setCategories] = useState([]);
	const [showMoreCategories, setShowMoreCategories] = useState(false);
	const [subCategories, setSubCategories] = useState([]);
	const [showMoreSubCategories, setShowMoreSubCategories] = useState(false);
	const [showMoreLanguages, setShowMoreLanguages] = useState(false);
	const [levels, setLevels] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [ratings, setRatings] = useState([]);
	const [courses, setCourses] = useState([]);

	// toggle in filter bar
	const [showRatings, setShowRatings] = useState(true);
	const [showCategories, setShowCategories] = useState(true);
	const [showSubCategories, setShowSubCategories] = useState(true);
	const [showLevels, setShowLevels] = useState(true);
	const [showLanguages, setShowLanguages] = useState(true);
	const [showPrices, setShowPrices] = useState(true);

	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const [filteredData, setFilteredData] = useState(null);
	var isShowSpinner = true;

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		isShowSpinner = true;
		getPageContent(isShowSpinner);
	}, [history.location.pathname]);

	useEffect(() => {
		isShowSpinner = false;
		if (serachText !== null && filteredData) getPageContent(isShowSpinner);
	}, [serachText]);

	const getPageContent = isShowSpinner => {
		const defaultParams = props.match.params;
		let subCategoryData = null;
		let categoryData = null;
		if (
			(subCategoryIds !== null
				? !subCategoryIds.includes(+defaultParams?.subcategoryId)
				: true) &&
			defaultParams?.subcategoryId
		) {
			subCategoryData = [+defaultParams.subcategoryId];
			setSubCategoryIds([...subCategoryData]);
		}
		if (
			(categoryIds !== null
				? !categoryIds.includes(+defaultParams.categoryId)
				: true) &&
			defaultParams?.categoryId
		) {
			categoryData = [+defaultParams.categoryId];
			if (defaultParams.subcategoryId) {
				subCategoryData = [+defaultParams.subcategoryId];
			}
			setCategoryIds([...categoryData]);
		}
		// +mainCategoryId !== +defaultParams.mainCategoryId && defaultParams.mainCategoryId && setMainCategoryId(+defaultParams?.mainCategoryId);
		getFilterData(
			defaultParams.mainCategoryId ? defaultParams.mainCategoryId : null,
			categoryData,
			subCategoryData,
			null,
			null,
			null,
			0,
			1,
			uuid(),
			isShowSpinner,
			pageNumber,
		);
	};

	const getPageTitle = () => {
		let pageTitle = null;
		if (
			!mainCategoryId ||
			!courseCategories ||
			(courseCategories && !courseCategories.length)
		) {
			pageTitle = mainCategoryName;
		}
		let mainCategory = courseCategories.find(
			mainCategory => mainCategory.id === +mainCategoryId,
		);
		if (mainCategoryId && !categoryId && !subcategoryId) {
			pageTitle = mainCategory?.name;
		}
		if (mainCategoryId && categoryId && !subcategoryId) {
			if (mainCategory && mainCategory.children) {
				pageTitle = mainCategory.children.find(
					category => category.id === +categoryId,
				)?.name;
			}
		}
		if (mainCategoryId && categoryId && subcategoryId) {
			if (mainCategory && mainCategory.children) {
				let currentCategory = mainCategory.children.find(
					category => category.id === +categoryId,
				);
				if (currentCategory && currentCategory.themeChildren) {
					pageTitle = currentCategory.themeChildren.find(
						theme => theme.id === +subcategoryId,
					)?.name;
				}
			}
		}
		if (pageTitle) {
			return `${pageTitle} | ${MINDALAY_KEY}`;
		} else {
			return MINDALAY_KEY;
		}
	};

	const getFilterData = (
		mainCategoryIdData = +mainCategoryId,
		categoryIdsData = categoryIds,
		subCategoryIdsData = subCategoryIds,
		ratingData = +rating,
		languageIdsData = languageIds,
		experienceLevelsData = experienceLevels,
		priceData = price,
		orderingData = ordering,
		pageSpinnerId = null,
		isShowSpinner,
		pageNumber,
	) => {
		const partialSpinnerId = uuid();
		const form = {
			mainCategoryId: mainCategoryIdData ? +mainCategoryIdData : null,
			categoryIds: categoryIdsData,
			subCategoryIds: subCategoryIdsData,
			rating: ratingData !== null ? +ratingData : null,
			languageIds: languageIdsData,
			experienceLevels: experienceLevelsData,
			price: priceData,
			ordering: orderingData,
			keyWord: serachText ? serachText : null,
			pageNumber,
			pageSize,
		};
		for (let i in form) {
			(form[i] === null ||
				(typeof form[i] === OBJECT_KEY && !form[i].length)) &&
				delete form[i];
		}
		if (isShowSpinner) {
			pageSpinnerId
				? setPageSpinner(pageSpinnerId)
				: setPartialViewSpinner(partialSpinnerId);
		}
		ApiService.courseFilter(form)
			.then(response => {
				response.pagination && setPagination(JSON.parse(response.pagination));
				const filterData = JSON.parse(JSON.stringify(response.data));
				setFilteredData(filterData);
				setCourseCount(filterData.courseCount);
				setMainCategoryName(filterData.mainCategoryName);
				setCategories(filterData.categories);
				setSubCategories(filterData.subCategories);
				setLanguages(filterData.languages);
				setLevels(filterData.experienceLevels);
				setRatings(filterData.ratings);
				setCourses(filterData.courses);
				setPrice(priceData);
				dispatch(removePageSpinner(pageSpinnerId));
				dispatch(removePartialViewSpinner(partialSpinnerId));
			})
			.catch(error => getFail(error, pageSpinnerId, partialSpinnerId));
	};

	const handlePageClick = event => {
		setActivePageNumber(event.selected);
		setPageNumber(event.selected + 1);
		getFilterData(
			mainCategoryId,
			categoryIds,
			subCategoryIds,
			rating,
			languageIds,
			experienceLevels,
			price,
			ordering,
			uuid(),
			true,
			event.selected + 1,
		);
		window.scrollTo(0, 0);
	};

	const onCategoryChange = event => {
		setPageNumber(1);
		setActivePageNumber(0);
		const targetValues = event.target.value.split(",");
		let categoryIdsData = [...(categoryIds || [])];
		targetValues &&
			targetValues.forEach(value => {
				if (
					categoryIds === null ||
					(categoryIds && !categoryIds.includes(+value))
				) {
					categoryIdsData.push(+value);
				} else {
					categoryIdsData = categoryIdsData.filter(data => data !== +value);
				}
			});
		setCategoryIds(categoryIdsData);
		getFilterData(
			mainCategoryId,
			categoryIdsData,
			subCategoryIds,
			rating,
			languageIds,
			experienceLevels,
			price,
			ordering,
			uuid(),
			true,
			1,
		);
	};

	const onSubCategoryChange = event => {
		setPageNumber(1);
		setActivePageNumber(0);
		const targetValues = event.target.value.split(",");
		let subCategoryIdsData = [...(subCategoryIds || [])];
		targetValues &&
			targetValues.forEach(value => {
				if (
					subCategoryIds === null ||
					(subCategoryIds && !subCategoryIds.includes(+value))
				) {
					subCategoryIdsData.push(+value);
				} else {
					subCategoryIdsData = subCategoryIdsData.filter(
						data => data !== +value,
					);
				}
			});
		setSubCategoryIds(subCategoryIdsData);
		// getFilterData(mainCategoryId, categoryIds, subCategoryIdsData);
		getFilterData(
			mainCategoryId,
			categoryIds,
			subCategoryIdsData,
			rating,
			languageIds,
			experienceLevels,
			price,
			ordering,
			uuid(),
			true,
			1,
		);
	};

	const onLevelChange = event => {
		setPageNumber(1);
		setActivePageNumber(0);
		const targetValue = event.target.value;
		const experienceLevelsData = [...(experienceLevels || [])];
		const levelIndex =
			experienceLevels && experienceLevels.indexOf(+targetValue);
		if ((levelIndex || levelIndex === 0) && levelIndex !== -1) {
			experienceLevelsData.splice(levelIndex, 1);
		} else {
			experienceLevelsData.push(+targetValue);
		}
		setExperienceLevels(experienceLevelsData);
		// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, languageIds, experienceLevelsData);
		getFilterData(
			mainCategoryId,
			categoryIds,
			subCategoryIds,
			rating,
			languageIds,
			experienceLevelsData,
			price,
			ordering,
			uuid(),
			true,
			1,
		);
	};

	const onLanguageChange = event => {
		setPageNumber(1);
		setActivePageNumber(0);
		const targetValue = event.target.value;
		const languageIdsData = [...(languageIds || [])];
		const languageIndex = languageIds && languageIds.indexOf(+targetValue);
		if ((languageIndex || languageIndex === 0) && languageIndex !== -1) {
			languageIdsData.splice(languageIndex, 1);
		} else {
			languageIdsData.push(+targetValue);
		}
		setLanguageIds(languageIdsData);
		// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, languageIdsData);
		getFilterData(
			mainCategoryId,
			categoryIds,
			subCategoryIds,
			rating,
			languageIdsData,
			null,
			price,
			ordering,
			uuid(),
			true,
			1,
		);
	};

	const clearFilter = () => {
		setCategoryIds(null);
		setSubCategoryIds(null);
		setLanguageIds(null);
		setExperienceLevels(null);
		setRating(null);
		setPrice(0);
		setPageNumber(1);
		setActivePageNumber(0);
		getFilterData(
			mainCategoryId,
			null,
			null,
			null,
			null,
			null,
			0,
			ordering,
			null,
			false,
			1,
		);
		// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, languageIdsData, null, price, ordering, uuid(), true, 1);
	};

	const getFail = (error, pageSpinnerId, partialSpinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		pageSpinnerId && dispatch(removePageSpinner(pageSpinnerId));
		partialSpinnerId && dispatch(removePartialViewSpinner(partialSpinnerId));
		props.history.push(`/${language}`);
	};

	const setPartialViewSpinner = useCallback(spinnerId => {
		dispatch(addPartialViewSpinner(spinnerId));
	});

	const setPageSpinner = useCallback(spinnerId => {
		dispatch(addPageSpinner(spinnerId));
	});

	return translationService ? (
		<>
			<Helmet>
				<title>{getPageTitle()}</title>
			</Helmet>
			<section className="section m-section course-category">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-4 col-12 filter-container">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-6 col-12 category-conteny-zindex">
									<div className="form-group">
										<div className="filter-wrapper">
											<p className="filter-name pl-0 my-2 cursor-default">
												{translationService.translate("SORT_BY")}
											</p>
										</div>
										<select
											className="form-control"
											defaultValue={1}
											onChange={event => {
												setPageNumber(1);
												getFilterData(
													mainCategoryId,
													categoryIds,
													subCategoryIds,
													rating,
													experienceLevels,
													languageIds,
													price,
													+event.target.value,
													uuid(),
													true,
													1,
												);
											}}>
											<option value="0">
												{translationService.translate("TR_HIGHEST_RATED")}
											</option>
											<option value="1">
												{translationService.translate("TR_NEWEST")}
											</option>
										</select>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-12 d-md-none d-block category-conteny-zindex">
									<div className="filter-wrapper show-filter-popup-button">
										<p className="filter-name pl-0 my-2">{"\u00A0"}</p>
									</div>
									<button
										type="button"
										className="mindalay--btn-default w-100 category-conteny-zindex"
										onClick={() => setShowMobileMenu(true)}>
										{translationService.translate("TR_FILTER")}
									</button>
								</div>
								{/* Mobile filter */}
								<div className="col-12">
									<hr className="d-md-none" />
								</div>
								<div
									className={`burger-menu-container burger-filter-container ${
										showMobileMenu ? "toggle-burger" : ""
									}`}>
									<div
										className={`burger-menu-body filter ${
											showMobileMenu ? "toggle-burger-menu" : ""
										}`}>
										<div
											className="right-close-btn side-menu-close-btn burger-close-button-scala"
											onClick={() => setShowMobileMenu(false)}>
											<span></span>
										</div>
										<div className="d-flex justify-content-between align-items-center burger-filter-header">
											<p>
												{translationService.translate("TR_RESULT")}:{" "}
												<span>{courseCount}</span>
											</p>
											<button
												type="button"
												className="mindalay--btn-secondary mindalay--btn-small clear-filter"
												onClick={clearFilter}>
												{translationService.translate("TR_CLEAR_FILTER")}
											</button>
										</div>
										<div className="burger-filter-body">
											<form>
												<div className="row">
													<div className="col-12">
														<hr />
														<div className="filter-wrapper">
															<p className="filter-name">
																{translationService.translate("TR_RATINGS")}
																<i
																	className={`${
																		showRatings ? "filter-toggle-icon" : ""
																	} fas fa-chevron-up`}></i>
															</p>
															<div
																className={`${
																	showRatings ? "" : "filter-toggle"
																} filter`}>
																{ratings.map(ratingData => (
																	<RatingRadioInput
																		key={`mobile_rating_${ratingData.rating}`}
																		id={`mobile_rating_${ratingData.rating}`}
																		name="mobile_filter_rating"
																		value={ratingData.rating}
																		courseCount={ratingData.courseCount}
																		checked={+rating === +ratingData.rating}
																		onChange={event => {
																			setRating(event.target.value);
																			// getFilterData(mainCategoryId, categoryIds, subCategoryIds, +event.target.value);
																			getFilterData(
																				mainCategoryId,
																				categoryIds,
																				subCategoryIds,
																				+event.target.value,
																				experienceLevels,
																				languageIds,
																				price,
																				ordering,
																				uuid(),
																				true,
																				1,
																			);
																		}}
																	/>
																))}
															</div>
														</div>
													</div>
													<div className="col-12">
														<hr />
														<div className="filter-wrapper filter-collapse">
															<p
																className="filter-name"
																onClick={() =>
																	setShowCategories(!showCategories)
																}>
																{translationService.translate("TR_CATEGORY")}
																<i
																	className={`${
																		showCategories ? "filter-toggle-icon" : ""
																	} fas fa-chevron-up`}></i>
															</p>
															<div
																className={`${
																	showCategories ? "" : "filter-toggle"
																} ${
																	categories.length > 6
																		? showMoreCategories && showCategories
																			? "filter-full-width"
																			: "collapsed"
																		: ""
																} filter`}>
																{categories.map(category => (
																	<InputCheckBox
																		key={`mobile_${category.categoryId}`}
																		id={`mobile_filter_category_${category.categoryId}`}
																		name="mobile_categoryId"
																		blockClassName="filter-item mb-0"
																		value={category.categoryId}
																		// checked={category.categoryIds.find(data => categoryIds && categoryIds.includes(+data)) || false}
																		checked={
																			(categoryIds &&
																				categoryIds.includes(
																					+category.categoryId,
																				)) ||
																			false
																		}
																		coursesCount={category.courseCount}
																		labelValue={category.categoryName}
																		onChange={onCategoryChange}
																	/>
																))}
															</div>
															{showCategories && categories.length > 6 ? (
																<div
																	className="align-items-center collapse-btn d-flex justify-content-end"
																	onClick={() =>
																		setShowMoreCategories(!showMoreCategories)
																	}>
																	<span>
																		{translationService.translate(
																			showMoreCategories
																				? "TR_LESS"
																				: "TR_SHOW_MORE",
																		)}
																	</span>
																	<i
																		className={`fas fa-angle-${
																			showMoreCategories ? "up" : "down"
																		} mr-2 ml-1`}
																		aria-hidden="true"></i>
																</div>
															) : null}
														</div>
													</div>
													<div className="col-12">
														<hr />
														<div className="filter-wrapper filter-collapse">
															<p
																className="filter-name"
																onClick={() =>
																	setShowSubCategories(!showSubCategories)
																}>
																{translationService.translate("TR_SUBCATEGORY")}
																<i
																	className={`${
																		showSubCategories
																			? "filter-toggle-icon"
																			: ""
																	} fas fa-chevron-up`}></i>
															</p>
															<div
																className={`${
																	showSubCategories ? "" : "filter-toggle"
																} ${
																	subCategories.length > 6
																		? showMoreSubCategories && showSubCategories
																			? "filter-full-width"
																			: "collapsed"
																		: ""
																} filter`}>
																{subCategories.map(subCategory => (
																	<InputCheckBox
																		key={`mobile_sub_${subCategory.categoryId}`}
																		id={`mobile_filter_subcategory_${subCategory.categoryId}`}
																		name="mobile_subCategoryId"
																		blockClassName="filter-item mb-0"
																		value={subCategory.categoryId}
																		// checked={subCategory.categoryIds?.find(data => subCategoryIds && subCategoryIds.includes(+data)) || false}
																		checked={
																			(subCategoryIds &&
																				subCategoryIds.includes(
																					+subCategory.categoryId,
																				)) ||
																			false
																		}
																		coursesCount={subCategory.courseCount}
																		labelValue={subCategory.categoryName}
																		onChange={onSubCategoryChange}
																	/>
																))}
															</div>
															{showSubCategories && subCategories.length > 6 ? (
																<div
																	className="align-items-center collapse-btn d-flex justify-content-end"
																	onClick={() =>
																		setShowMoreSubCategories(
																			!showMoreSubCategories,
																		)
																	}>
																	<span>
																		{translationService.translate(
																			showMoreSubCategories
																				? "TR_LESS"
																				: "TR_SHOW_MORE",
																		)}
																	</span>
																	<i
																		className={`fas fa-angle-${
																			showMoreSubCategories ? "up" : "down"
																		} mr-2 ml-1`}
																		aria-hidden="true"></i>
																</div>
															) : null}
														</div>
													</div>
													<div className="col-12">
														<hr />
														<div className="filter-wrapper">
															<p
																className="filter-name"
																onClick={() => setShowLevels(!showLevels)}>
																{translationService.translate("TR_LEVEL")}
																<i
																	className={`${
																		showLevels ? "filter-toggle-icon" : ""
																	} fas fa-chevron-up`}></i>
															</p>
															<div
																className={`${
																	showLevels ? "" : "filter-toggle"
																} filter`}>
																{levels.map(level => (
																	<InputCheckBox
																		key={`mobile_${level.experienceLevel}`}
																		id={`mobile_filter_level_${level.experienceLevel}`}
																		name="mobile_experienceLevel"
																		blockClassName="filter-item mb-0"
																		value={level.experienceLevel}
																		checked={
																			(experienceLevels &&
																				experienceLevels.includes(
																					level.experienceLevel,
																				)) ||
																			false
																		}
																		coursesCount={level.courseCount}
																		labelValue={translationService.translate(
																			levelsData[level.experienceLevel],
																		)}
																		onChange={onLevelChange}
																	/>
																))}
															</div>
														</div>
													</div>
													<div className="col-12">
														<hr />
														<div className="filter-wrapper filter-collapse">
															<p
																className="filter-name"
																onClick={() =>
																	setShowLanguages(!showLanguages)
																}>
																{translationService.translate("TR_LANGUAGE")}
																<i
																	className={`${
																		showLanguages ? "filter-toggle-icon" : ""
																	} fas fa-chevron-up`}></i>
															</p>
															<div
																className={`${
																	showLanguages ? "" : "filter-toggle"
																} ${
																	languages.length > 6
																		? showMoreLanguages && showLanguages
																			? "filter-full-width"
																			: "collapsed"
																		: ""
																} filter`}>
																{languages.map(language => (
																	<InputCheckBox
																		key={`mobile_${language.languageId}`}
																		id={`mobile_filter_language_${language.languageId}`}
																		name="mobile_languageId"
																		blockClassName="filter-item mb-0"
																		value={language.languageId}
																		checked={
																			(languageIds &&
																				languageIds.includes(
																					language.languageId,
																				)) ||
																			false
																		}
																		coursesCount={language.courseCount}
																		labelValue={language.languageName}
																		onChange={onLanguageChange}
																	/>
																))}
															</div>
															{showLanguages && languages.length > 6 ? (
																<div
																	className="align-items-center collapse-btn d-flex justify-content-end"
																	onClick={() =>
																		setShowMoreLanguages(!showMoreLanguages)
																	}>
																	<span>
																		{translationService.translate(
																			showMoreLanguages
																				? "TR_LESS"
																				: "TR_SHOW_MORE",
																		)}
																	</span>
																	<i
																		className={`fas fa-angle-${
																			showMoreLanguages ? "up" : "down"
																		} mr-2 ml-1`}
																		aria-hidden="true"></i>
																</div>
															) : null}
														</div>
													</div>
													<div className="col-12">
														<hr />
														<div className="filter-wrapper">
															<p
																className="filter-name"
																onClick={() => setShowPrices(!showPrices)}>
																{translationService.translate("TR_PRICE")}
																<i
																	className={`${
																		showPrices ? "filter-toggle-icon" : ""
																	} fas fa-chevron-up`}></i>
															</p>
															<div
																className={`${
																	showPrices ? "" : "filter-toggle"
																} filter`}>
																<InputRadio
																	id={`mobile_price_default`}
																	name="mobile_price"
																	blockClassName="filter-item mb-0"
																	checked={price === 0}
																	labelValue={translationService.translate(
																		"TR_ALL",
																	)}
																	onChange={() => {
																		setRating(0);
																		// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, experienceLevels, languageIds, 0);
																		getFilterData(
																			mainCategoryId,
																			categoryIds,
																			subCategoryIds,
																			rating,
																			experienceLevels,
																			languageIds,
																			0,
																			ordering,
																			uuid(),
																			true,
																			1,
																		);
																	}}
																/>
																<InputRadio
																	id={`mobile_price_free`}
																	name="mobile_price"
																	blockClassName="filter-item mb-0"
																	checked={price === 1}
																	labelValue={translationService.translate(
																		"TR_FREE",
																	)}
																	onChange={() => {
																		setPrice(1);
																		// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, experienceLevels, languageIds, 1);
																		getFilterData(
																			mainCategoryId,
																			categoryIds,
																			subCategoryIds,
																			rating,
																			experienceLevels,
																			languageIds,
																			1,
																			ordering,
																			uuid(),
																			true,
																			1,
																		);
																	}}
																/>
																<InputRadio
																	id={`mobile_price_paid`}
																	name="mobile_price"
																	blockClassName="filter-item mb-0"
																	checked={price === 2}
																	labelValue={translationService.translate(
																		"TR_PAID",
																	)}
																	onChange={() => {
																		setRating(2);
																		// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, experienceLevels, languageIds, 2);
																		getFilterData(
																			mainCategoryId,
																			categoryIds,
																			subCategoryIds,
																			rating,
																			experienceLevels,
																			languageIds,
																			2,
																			ordering,
																			uuid(),
																			true,
																			1,
																		);
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-12 d-md-block d-none filter">
									<div className="d-flex justify-content-between align-items-center">
										<p>
											{translationService.translate("TR_RESULT")}: {courseCount}
										</p>
										<button
											type="button"
											className="mindalay--btn-default mindalay--btn-small clear-filter"
											onClick={clearFilter}>
											{translationService.translate("TR_CLEAR_FILTER")}
										</button>
									</div>
									<form>
										<div className="row">
											<div className="col-12">
												<hr />
												<div className="filter-wrapper">
													<p
														className="filter-name"
														onClick={() => setShowRatings(!showRatings)}>
														{translationService.translate("TR_RATINGS")}
														<i
															className={`${
																showRatings ? "filter-toggle-icon" : ""
															} fas fa-chevron-up`}></i>
													</p>
													<div
														className={`${
															showRatings ? "" : "filter-toggle"
														} filter`}>
														{ratings.map(ratingData => (
															<RatingRadioInput
																key={`rating__${ratingData.rating}`}
																id={`rating_${ratingData.rating}`}
																name="filter-rating"
																value={ratingData.rating}
																courseCount={ratingData.courseCount}
																checked={+rating === +ratingData.rating}
																onChange={event => {
																	setRating(event.target.value);
																	// getFilterData(mainCategoryId, categoryIds, subCategoryIds, +event.target.value);
																	getFilterData(
																		mainCategoryId,
																		categoryIds,
																		subCategoryIds,
																		+event.target.value,
																		experienceLevels,
																		languageIds,
																		price,
																		ordering,
																		uuid(),
																		true,
																		1,
																	);
																}}
															/>
														))}
													</div>
												</div>
											</div>
											<div className="col-12">
												<hr />
												<div className="filter-wrapper filter-collapse">
													<p
														className="filter-name"
														onClick={() => setShowCategories(!showCategories)}>
														{translationService.translate("TR_CATEGORY")}
														<i
															className={`${
																showCategories ? "filter-toggle-icon" : ""
															} fas fa-chevron-up`}></i>
													</p>
													<div
														className={`${
															showCategories ? "" : "filter-toggle"
														} ${
															categories.length > 6
																? showMoreCategories && showCategories
																	? "filter-full-width"
																	: "collapsed"
																: ""
														} filter`}>
														{categories.map(category => (
															<InputCheckBox
																key={category.categoryId}
																id={`filter_category_${category.categoryId}`}
																name="categoryId"
																blockClassName="filter-item mb-0"
																value={category.categoryId}
																// checked={category.categoryIds?.find(data => categoryIds && categoryIds.includes(+data)) || false}
																checked={
																	(categoryIds &&
																		categoryIds.includes(
																			+category.categoryId,
																		)) ||
																	false
																}
																coursesCount={category.courseCount}
																labelValue={category.categoryName}
																onChange={onCategoryChange}
															/>
														))}
													</div>
													{showCategories && categories.length > 6 ? (
														<div
															className="align-items-center collapse-btn d-flex justify-content-end"
															onClick={() =>
																setShowMoreCategories(!showMoreCategories)
															}>
															<span>
																{translationService.translate(
																	showMoreCategories
																		? "TR_LESS"
																		: "TR_SHOW_MORE",
																)}
															</span>
															<i
																className={`fas fa-angle-${
																	showMoreCategories ? "up" : "down"
																} mr-2 ml-1`}
																aria-hidden="true"></i>
														</div>
													) : null}
												</div>
											</div>
											<div className="col-12">
												<hr />
												<div className="filter-wrapper filter-collapse">
													<p
														className="filter-name"
														onClick={() =>
															setShowSubCategories(!showSubCategories)
														}>
														{translationService.translate("TR_SUBCATEGORY")}
														<i
															className={`${
																showSubCategories ? "filter-toggle-icon" : ""
															} fas fa-chevron-up`}></i>
													</p>
													<div
														className={`${
															showSubCategories ? "" : "filter-toggle"
														} ${
															subCategories.length > 6
																? showMoreSubCategories && showSubCategories
																	? "filter-full-width"
																	: "collapsed"
																: ""
														} filter`}>
														{subCategories.map(subCategory => (
															<InputCheckBox
																key={subCategory.categoryId}
																id={`filter_subcategory_${subCategory.categoryId}`}
																name="subCategoryId"
																blockClassName="filter-item mb-0"
																value={subCategory.categoryId}
																checked={
																	(subCategoryIds &&
																		subCategoryIds.includes(
																			+subCategory.categoryId,
																		)) ||
																	false
																}
																coursesCount={subCategory.courseCount}
																labelValue={subCategory.categoryName}
																onChange={onSubCategoryChange}
															/>
														))}
													</div>
													{showSubCategories && subCategories.length > 6 ? (
														<div
															className="align-items-center collapse-btn d-flex justify-content-end"
															onClick={() =>
																setShowMoreSubCategories(!showMoreSubCategories)
															}>
															<span>
																{translationService.translate(
																	showMoreSubCategories
																		? "TR_LESS"
																		: "TR_SHOW_MORE",
																)}
															</span>
															<i
																className={`fas fa-angle-${
																	showMoreSubCategories ? "up" : "down"
																} mr-2 ml-1`}
																aria-hidden="true"></i>
														</div>
													) : null}
												</div>
											</div>
											<div className="col-12">
												<hr />
												<div className="filter-wrapper">
													<p
														className="filter-name"
														onClick={() => setShowLevels(!showLevels)}>
														{translationService.translate("TR_LEVEL")}
														<i
															className={`${
																showLevels ? "filter-toggle-icon" : ""
															} fas fa-chevron-up`}></i>
													</p>
													<div
														className={`${
															showLevels ? "" : "filter-toggle"
														} filter`}>
														{levels.map(level => (
															<InputCheckBox
																key={level.experienceLevel}
																id={`filter_level_${level.experienceLevel}`}
																name="experienceLevel"
																blockClassName="filter-item mb-0"
																value={level.experienceLevel}
																checked={
																	(experienceLevels &&
																		experienceLevels.includes(
																			+level.experienceLevel,
																		)) ||
																	false
																}
																coursesCount={level.courseCount}
																labelValue={translationService.translate(
																	levelsData[level.experienceLevel],
																)}
																onChange={onLevelChange}
															/>
														))}
													</div>
												</div>
											</div>
											<div className="col-12">
												<hr />
												<div className="filter-wrapper filter-collapse">
													<p
														className="filter-name"
														onClick={() => setShowLanguages(!showLanguages)}>
														{translationService.translate("TR_LANGUAGE")}
														<i
															className={`${
																showLanguages ? "filter-toggle-icon" : ""
															} fas fa-chevron-up`}></i>
													</p>
													<div
														className={`${
															showLanguages ? "" : "filter-toggle"
														} ${
															languages.length > 6
																? showMoreLanguages && showLanguages
																	? "filter-full-width"
																	: "collapsed"
																: ""
														} filter`}>
														{languages.map(language => (
															<InputCheckBox
																key={`mobile_${language.languageId}`}
																id={`mobile_filter_language_${language.languageId}`}
																name="mobile_languageId"
																blockClassName="filter-item mb-0"
																value={language.languageId}
																checked={
																	(languageIds &&
																		languageIds.includes(
																			language.languageId,
																		)) ||
																	false
																}
																coursesCount={language.courseCount}
																labelValue={language.languageName}
																onChange={onLanguageChange}
															/>
														))}
													</div>
													{showLanguages && languages.length > 6 ? (
														<div
															className="align-items-center collapse-btn d-flex justify-content-end"
															onClick={() =>
																setShowMoreLanguages(!showMoreLanguages)
															}>
															<span>
																{translationService.translate(
																	showMoreLanguages
																		? "TR_LESS"
																		: "TR_SHOW_MORE",
																)}
															</span>
															<i
																className={`fas fa-angle-${
																	showMoreLanguages ? "up" : "down"
																} mr-2 ml-1`}
																aria-hidden="true"></i>
														</div>
													) : null}
												</div>
											</div>
											<div className="col-12">
												<hr />
												<div className="filter-wrapper">
													<p
														className="filter-name"
														onClick={() => setShowPrices(!showPrices)}>
														{translationService.translate("TR_PRICE")}
														<i
															className={`${
																showPrices ? "filter-toggle-icon" : ""
															} fas fa-chevron-up`}></i>
													</p>
													<div
														className={`${
															showPrices ? "" : "filter-toggle"
														} filter`}>
														<InputRadio
															id="price_default"
															name="price"
															blockClassName="filter-item mb-0"
															checked={price === 0}
															labelValue={translationService.translate(
																"TR_ALL",
															)}
															onChange={() => {
																setPrice(0);
																// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, experienceLevels, languageIds, 0);
																getFilterData(
																	mainCategoryId,
																	categoryIds,
																	subCategoryIds,
																	rating,
																	experienceLevels,
																	languageIds,
																	0,
																	ordering,
																	uuid(),
																	true,
																	1,
																);
															}}
														/>
														<InputRadio
															id="price_free"
															name="price"
															blockClassName="filter-item mb-0"
															checked={price === 1}
															labelValue={translationService.translate(
																"TR_FREE",
															)}
															onChange={() => {
																setPrice(1);
																// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, experienceLevels, languageIds, 1);
																getFilterData(
																	mainCategoryId,
																	categoryIds,
																	subCategoryIds,
																	rating,
																	experienceLevels,
																	languageIds,
																	1,
																	ordering,
																	uuid(),
																	true,
																	1,
																);
															}}
														/>
														<InputRadio
															id="price_paid"
															name="price"
															blockClassName="filter-item mb-0"
															checked={price === 2}
															labelValue={translationService.translate(
																"TR_PAID",
															)}
															onChange={() => {
																setPrice(2);
																// getFilterData(mainCategoryId, categoryIds, subCategoryIds, rating, experienceLevels, languageIds, 2);
																getFilterData(
																	mainCategoryId,
																	categoryIds,
																	subCategoryIds,
																	rating,
																	experienceLevels,
																	languageIds,
																	2,
																	ordering,
																	uuid(),
																	true,
																	1,
																);
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-9 col-md-8 col-12">
							<div className="row">
								{mainCategoryName ? (
									<div className="col-12 category-conteny-zindex">
										<h1 className="section-title">{getPageTitle()}</h1>
										<hr />
									</div>
								) : null}
								<div className="col-12">
									<div className="course-wrapper-horizontal">
										{/* <PartialViewSpinner spinners={partialViewSpinners} customClasses="partial-view-spinner-wrapper" /> */}
										{courses && courses.length ? (
											courses.map((course, index) => {
												const ratingPercent = Math.round(
													(course.rating * 100) / 5,
												);
												// if (index >= viewCoursesCount) { return false; }
												return (
													<Auxiliary key={course.id}>
														<div
															className="course-wrapper"
															onClick={() =>
																props.history.push(
																	`/${language}/${COURSES_KEY}/${course.id}`,
																)
															}>
															<div className="course-image-wrapper">
																<Link to="#" className="w-100 h-100">
																	<div
																		className="course-image w-100"
																		style={{
																			backgroundImage: `url(${course.imagePath})`,
																		}}></div>
																</Link>
															</div>
															<div className="course-body-wrapper filtered-courses overflow-hidden">
																<div className="flex-1">
																	<Link
																		to={`/${language}/${COURSES_KEY}/${course.id}`}>
																		<h6 className="course-name">
																			{course.name}
																		</h6>
																	</Link>
																	{course.description ? (
																		<span className="course-description text-two-row max-height-50">
																			<span className="course-description-block">
																				<ParserComponent
																					text={course.description}
																				/>
																			</span>
																		</span>
																	) : null}
																	<div className="course-review-wrapper d-flex align-items-center">
																		<span className="course-review-number text-orange mr-1">
																			{course.rating}
																		</span>
																		<div className="rating">
																			<div
																				style={{
																					width: `${ratingPercent}%`,
																				}}></div>
																		</div>
																		<span className="course-review-count">
																			<span>
																				(
																				<span>
																					{course.approvedTotalReviews}
																				</span>
																				)
																			</span>
																		</span>
																	</div>
																	{course.isBestSeller ? (
																		<span className="course-status course-bestseller">
																			{translationService.translate(
																				"TR_BESTSELLER",
																			)}
																		</span>
																	) : null}
																	{course.isNew ? (
																		<span className="course-status course-new">
																			{translationService.translate("TR_NEW")}
																		</span>
																	) : null}
																</div>
																<div className="course-price-wrapper">
																	<p className="course-new-price">
																		{!course.cost || course.discount === 100
																			? `${translationService.translate(
																					"TR_FREE",
																			  )}`
																			: course.cost}
																	</p>
																	{course.oldCost ? (
																		<p className="course-old-price">
																			{course.oldCost}
																		</p>
																	) : null}
																</div>
															</div>
														</div>
														<hr />
													</Auxiliary>
												);
											})
										) : serachText ? (
											<div className="no-data-container">
												<div className="no-data-wrapper">
													<p className="word-break-break-word">
														{" "}
														{`${translationService.translate(
															"TR_SORRY_WE_COULD_NOT_FIND_RESULTS_FOR",
														)} "${serachText}"`}
													</p>
													<img src={NoDataImage} alt="/" />
												</div>
											</div>
										) : (
											<div className="no-data-container">
												<div className="no-data-wrapper">
													<p>
														{translationService.translate(
															"TR_FILTER_NO_COURSES",
														)}
													</p>
													<img src={NoDataImage} alt="/" />
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="col-12">
									{pagination ? (
										<div className="row mt-4">
											<div className="col-12">
												<div>
													<ReactPaginate
														nextLabel={translationService.translate("TR_NEXT")}
														onPageChange={handlePageClick}
														pageRangeDisplayed={3}
														marginPagesDisplayed={2}
														pageCount={pagination.TotalPages}
														previousLabel={translationService.translate(
															"TR_PREVIOUS",
														)}
														pageClassName="page-item"
														pageLinkClassName="page-link"
														previousClassName="page-item"
														previousLinkClassName="page-link"
														nextClassName="page-item"
														nextLinkClassName="page-link"
														breakLabel="..."
														breakClassName="page-item"
														breakLinkClassName="page-link"
														containerClassName="pagination"
														activeClassName="active"
														renderOnZeroPageCount={null}
														forcePage={activePageNumber}
													/>
												</div>
											</div>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	) : null;
};

export default withRouter(CoursesByCategoryView);
