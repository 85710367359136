import { SET_NOTIFICATION_COUNT, SET_UNREAD_MESSAGES_COUNT, SHOW_WEBINAR_SCREEN_NOTIFICATION, SET_GLOBAL_CONNECTION } from "../../Constants/reduxKeys";

let initialState = {
  notificationCount: 0,
  unreadMessgesCount: 0,
  showWebinarScreenNotification: null,
  globalConnection: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case SET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessgesCount: action.payload,
      };
    case SHOW_WEBINAR_SCREEN_NOTIFICATION:
      return {
        ...state,
        showWebinarScreenNotification: action.payload,
      };
    case SET_GLOBAL_CONNECTION:
      return {
        ...state,
        globalConnection: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;