import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import TranslationService from "../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";

const ContentManagerLeftMenu = props => {
	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const [translationService, setTranslationService] = useState(null);
	const { settings } = useSelector(state => state.settings);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = bool => {
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	return translationService && user ? (
		<section
			className={`side-menu-container ${
				showOrHideGeneralLeftMenu ? "show-general-left-menu" : ""
			}`}>
			<div
				className={showOrHideGeneralLeftMenu ? "layer" : ""}
				onClick={() => showOrHideLeftMenu(false)}
			/>
			<aside
				className={`side-left instructor-header-zindex w-fix-left-menu-organization side-left-organization`}>
				<div className="side-left-container">
					<div className="side-left-inner-wrapper">
						<ul className="side-left-list-wrapper">
							<li className={`side-left-menu-item has-submenu d-inline-flex`}>
								<Link
									to={`/${language}`}
									title={translationService.translate("TR_HOME")}
									className="p-0"
									onClick={() => showOrHideLeftMenu(false)}>
									{settings && settings.mainLogoPath ? (
										<img src={settings.mainLogoPath} alt="/" style={{ width: "120px", maxWidth: "150px", maxHeight: "150px", display: "block", margin: "0 auto" }} />
									) : (
										<MindalaySvg />
									)}
								</Link>
							</li>
							<br />
							<ul>
								<li
									className={`
                  side-left-menu-item d-block has-submenu w-100 pl-0 py-2
                  ${
										props.location.pathname.includes(
											"content-manager/translations",
										)
											? "active-side-left-menu-item"
											: ""
									}
                  `}>
									<Link
										to={`/${language}/content-manager/translations`}
										className={`nav-link ml-1 d-block`}
										onClick={() => showOrHideLeftMenu(false)}>
										Translations
									</Link>
								</li>
							</ul>
						</ul>
					</div>
				</div>
			</aside>
		</section>
	) : null;
};

export default withRouter(ContentManagerLeftMenu);
