import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from "react-redux";
import ApiService from '../../Services/apiService';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { withRouter } from 'react-router';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import { addModalSpinner, addPartialViewSpinner, removeModalSpinner, removePartialViewSpinner } from '../../Store/Actions/spinner';
import { CURATOR_USER_TYPE, ERROR_KEY, LECTURER_USER_TYPE, STUDENT_USER_TYPE, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY } from '../../Constants/mainKeys';
import { Link } from 'react-router-dom';
import { DOCUMENT_KEY, DOCUMENT_VIEW_KEY, STUDENT_KEY } from '../../Constants/urlKeys';
import ArrowBackSvg from '../../Components/Svg/arrowBackSvg';
import FileSvg from './../../Components/Svg/fileSvg';
import ViewSvg from './../../Components/Svg/viewSvg';
import RolesService from '../../Services/rolesService';
import ModalComponent from '../../Components/ModalComponent/modalComponent';
import MainService from '../../Services/mainService';
import UploadButton from '../../Components/UploadButton/uploadButton';
import CloseSvg from './../../Components/Svg/closeSvg';
import { setBackUrl } from '../../Store/Actions/main';
import _InfoSvg from './../../Components/Svg/_infoSvg';
import PageInfoBlock from '../../Components/PageInfoBlock/PageInfoBlock';

const StudentDocuments = (props) => {

  const dispatch = useDispatch();
  const mainService = new MainService();
  const studentId = props.match.params.studentId || null;
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const role = useSelector(state => state.user.role);

  const [translationService, setTranslationService] = useState(null);
  const [studentDocuments, setStudentDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(false);
  const [roleService, setRoleService] = useState(null);

  useEffect(() => {
    MainService.isJson(role) && setRoleService(new RolesService(JSON.parse(role)))
  }, [role]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    studentId && getStudentDocuments(studentId);
  }, []);

  const getStudentDocuments = (studentId) => {
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    (user.userTypeId === LECTURER_USER_TYPE ? ApiService.getStudentDocumentsByLecturer(studentId) : ApiService.getStudentDocumentsByCurator(studentId)).then(response => {
      if (response.data) {
        const data = [...response.data];
        setStudentDocuments(data);
      }
      dispatch(removePartialViewSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  };

  const documentSign = (currentDocument) => {
    const spinnerId = uuid()
    if (!file) {
      setIsInvalidSubmit(true);
      return;
    }
    dispatch(addModalSpinner(spinnerId));
    setAddButtonIsDisabled(true);
    const formData = new FormData();
    formData.append("documentId", currentDocument.id);
    formData.append("DocumentSignedFile", file);
    ApiService.documentSign(formData).then(() => {
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
      cancel();
    }).catch(error => getFail(error, spinnerId))
  }

  const uploadFile = event => {
    const file = event.target.files[0];
    if (file) {
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedFile => {
        setUploadedFile(uploadedFile);
        setFile(file);
        setIsInvalidSubmit(false);
      }).catch(error => error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), translationService.translate("TR_INVALID_FILE_FORMAT_EXC")));
    };
  }

  const cancel = () => {
    setCurrentDocument(null);
    setAddButtonIsDisabled(false);
    setFile(null);
    setUploadedFile(null);
  }

  const removeImg = () => {
    setUploadedFile(null);
    setFile(null);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
    spinnerId && dispatch(removeModalSpinner(spinnerId));
  }

  return translationService ?
    <Auxiliary>
      {
        currentDocument ?
          <ModalComponent
            title={currentDocument.name}
            isLarge={true}
            actionButtonTitle={translationService.translate("TR_SAVE")}
            addButtonIsDisabled={addButtonIsDisabled}
            cancel={cancel}
            onSubmit={() => documentSign(currentDocument)}
          >
            <form>
              <div className="row">
                <div className="col-12 col-lg-4 col-md-6">
                  <div className="course-media-wrapper">
                    <p className="my-2">{translationService.translate("TR_UPLOAD_FILE")} *</p>
                    {
                      uploadedFile ?
                        <div className="upload-file-content">
                          <div onClick={removeImg}><CloseSvg /></div>
                          <div className="img" style={{ backgroundImage: `url(${uploadedFile})` }} alt="course-image" ></div>
                        </div>
                        : null
                    }

                  </div>
                  <div className="course-media-wrapper">
                    <div className="form-group position-relative">
                      <UploadButton textInputClasses="pl-100" isInvalidSubmit={isInvalidSubmit} text={`${translationService.translate('TR_UPLOAD_FILE')} *`} clicked={uploadFile} />
                    </div>
                    <small>
                      {translationService.translate("TR_DOCUMENT_SIGN_VALID_IMAGE_TYPES").replace('{0}', "'jpg'").replace('{1}', "'jpeg'").replace('{2}', "'png'")}
                    </small>
                  </div>
                </div>
              </div>
            </form>

          </ModalComponent>
          : null
      }
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className='content-title p-0'>
              <div className="d-flex align-items-center">
                <Link to={`/${language}/${STUDENT_KEY}`} title={translationService.translate("TR_BACK")}><ArrowBackSvg /></Link>
                <h2 className="content-title p-0">
                  {translationService.translate("TR_STUDENT_DOCUMENTS")}
                </h2>
              </div>
            </div>
            <PageInfoBlock
              pageTitle={translationService.translate("TR_STUDENT_DOCUMENTS")}
              pageName={user.userTypeId === STUDENT_USER_TYPE ? "student_documents" :
                user.userTypeId === LECTURER_USER_TYPE || user.userTypeId === CURATOR_USER_TYPE ?
                  "curator_lecturer_selection_committee_student_documents" : ""
              }
              translationService={translationService}
            />
          </div>
        </div>
        <hr />

        <div className='row mt-3'>
          {
            studentDocuments && studentDocuments.length ?
              studentDocuments.map(document => {
                return <div key={document.id} className='col-xl-3 col-lg-4 col-sm-6 col-12 mb-4'>
                  <div className={`course-wrapper--instructor`} >
                    <div className="course-document-wrapper--instructor">
                      <div className="course-document-icon" >
                        <FileSvg />
                      </div>
                      <div className="document-button-group">
                        <Auxiliary>
                          <Link
                            className={`course-view-button`}
                            title={translationService.translate("TR_VIEW")}
                            to={`/${language}/${DOCUMENT_KEY}/${DOCUMENT_VIEW_KEY}/${document.id}`}
                            onClick={() => {
                              dispatch(setBackUrl(props.location.pathname));
                            }}
                          >
                            <ViewSvg />
                          </Link>
                          {
                            roleService.checkRole("organizationdocument_sign") ?
                              <Link
                                className={`course-edit-button ${document.signed ? "disabled" : ""}`}
                                to='#'
                                title={translationService.translate("TR_SIGN")}
                                onClick={() => {
                                  if (document.signed) {
                                    return false;
                                  } else {
                                    setCurrentDocument(document)
                                  }
                                }}
                              >
                                <FileSvg />
                              </Link>
                              : null
                          }
                        </Auxiliary>
                      </div>
                    </div>

                    <div className="p-3 border-bottom">
                      <h6
                        className="course-name--instructor cursor-pointer"
                        title={document.name}
                        onClick={() => {
                          dispatch(setBackUrl(props.location.pathname));
                          props.history.push(`/${language}/${DOCUMENT_KEY}/${DOCUMENT_VIEW_KEY}/${document.id}`)
                        }}
                      >
                        {document.name ? document.name : ""}
                      </h6>
                    </div>
                  </div>
                </div>
              })
              : <div className="no-data-container">
                <div className="no-data-wrapper">
                  <p>{translationService.translate("TR_NO_DOCUMENTS_INFO")}</p>
                  <img src={NoDataImage} alt="/" />
                </div>
              </div>
          }
        </div>


      </div>
    </Auxiliary>

    : null
}

export default withRouter(StudentDocuments);

