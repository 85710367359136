import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";

const Configs = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const { language } = useSelector(state => state.language);

	const [configs, setConfigs] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	useEffect(() => {
		getGonfigs();
	}, []);

	const filterconfigs = useMemo(() => {
		if (search.trim().length && configs.length) {
			return configs.filter(el =>
				el.name.toLowerCase().includes(search.toLowerCase()),
			);
		} else {
			return configs;
		}
	}, [configs, search]);

	const getGonfigs = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getConfig()
			.then(response => {
				if (response && response.data) {
					setConfigs(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteConfig = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteConfig(item.id)
				.then(() => {
					setConfigs(configs.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_CONFIGS")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="d-flex justify-content-between align-items-center">
				<div className="position-relative">
					<Input
						type="text"
						id="search"
						name="search"
						inputClassName="pr--5"
						blockClassName="mb-0"
						value={search}
						onChange={event => {
							if (event.target.value.trim().length) {
								setSearch(event.target.value);
							} else {
								setSearch("");
							}
						}}
					/>
					<div
						style={{
							position: "absolute",
							bottom: "50%",
							transform: "translateY(50%)",
							right: "15px",
							pointerEvents: "none",
						}}>
						<i
							className="fa fa-search"
							style={{ color: "var(--mindalay--gray-color-lightened-75)" }}
							aria-hidden="true"></i>
					</div>
				</div>
				<Link
					to={`/${language}/developer/config-form`}
					className="mindalay--btn-default">
					Create configs
				</Link>
			</div>

			{filterconfigs && filterconfigs.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_USER_TYPES_PAGE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_VALUE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_WL_DESCRIPTION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_DEFAULT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_READONLY")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{filterconfigs.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item  w-100">
														{item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item w-100 max-line-3 word-break-break-word">
														{item.value}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item w-100 word-break-break-word  max-line-3">
														{item.description}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item w-100">
														<span>
															{item.isDefault ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize w-100">
														<span>
															{item.isReadonly ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>

												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={`/${language}/developer/config-form/${item.id}`}
																title={translationService.translate("TR_EDIT")}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															<Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deleteConfig(item);
																}}>
																<DeleteSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Configs);
