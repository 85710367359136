import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "./../../Services/apiService";
import NodataImage from "../../assets/images/illustrations/nodata.svg";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import { addButtonSpinner } from "./../../Store/Actions/spinner";
import ActionButton from "../../Components/ActionButton/actionButton";
import Paypal from "./../../Components/Svg/PaymentSvg/paypal";
import Skrill from "../../Components/Svg/PaymentSvg/skrill";
import StripeIcon from "../../Components/Svg/PaymentSvg/stripe";
import WebMoney from "../../Components/Svg/PaymentSvg/webMoney";
import YandexMoney from "../../Components/Svg/PaymentSvg/yandexMoney";
import {
	CRYLLEX_CANCEL_KEY,
	CRYLLEX_SUCCESS_KEY,
	CURRENCY_SYMBOL_KEY,
	DEFAULT_CURRENCY_KEY,
	DEFAULT_CURRENCY_SYMBOL_KEY,
	TR_NO,
	WARNING_KEY,
} from "./../../Constants/mainKeys";
import { Link, withRouter } from "react-router-dom";
import Auxiliary from "./../../hoc/auxiliary/auxiliary";
import Input from "../../Components/Inputs/input";
import { CANCEL_KEY } from "../../Constants/requestKeys";
import {
	HISTORY_KEY,
	ORGANIZATION_ADMIN_KEY,
	PAYMENT_PACKAGE_KEY,
} from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	NUMBER_KEY,
	PAYPAL_CANCEL_KEY,
	PAYPAL_SUCCESS_KEY,
	RETURN_URL_KEY,
	STRIPE_CANCEL_KEY,
	STRIPE_SUCCESS_KEY,
	SUCCESS_KEY,
	TR_YES,
} from "../../Constants/mainKeys";
import Finance from "../../Components/Svg/financeSvg";
import InfoSvg from "../../Components/Svg/infoSvg";
import GuestApiService from "../../Services/guestApiService";
import RolesService from "../../Services/rolesService";
import Modal from "react-bootstrap/Modal";
import ModalSpinner from "../../Components/Spinners/modalSpinner";
import warningSvg from "./../../assets/icons/alert-icons/warning.svg";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import MainService from "../../Services/mainService";

const paymentSystemsComponents = [
	// {
	// 	id: 1,
	// 	name: "PayPal",
	// 	value: <Paypal />,
	// },
	{
		id: 2,
		name: "Skrill",
		value: <Skrill />,
	},
	{
		id: 3,
		name: "Stripe",
		value: <StripeIcon />,
	},
	{
		id: 4,
		value: <WebMoney />,
	},
	{
		id: 5,
		value: <YandexMoney />,
	},
];

const spinnerId = uuid();
const PaymentPackage = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const { role, user } = useSelector(state => state.user);
	const currencySymbol = localStorage.getItem(CURRENCY_SYMBOL_KEY)
		? localStorage.getItem(CURRENCY_SYMBOL_KEY)
		: DEFAULT_CURRENCY_SYMBOL_KEY;
	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [paymentPackageId, setPaymentPackageId] = useState(null);
	const [monthCount, setMonthCount] = useState(null);
	const [paymentPackages, setPaymentPackages] = useState([]);
	const [recurringPayment, setRecurringPayment] = useState(false);
	const [paymentSystems, setPaymentSystems] = useState([]);
	const [paymentFillSystemId, setPaymentFillSystemId] = useState(6);
	const [paymentFillSystem, setPaymentFillSystem] = useState(null);
	const [paymentPackageItems, setPaymentPackageItems] = useState([]);
	const [totalBalance, setTotalBalance] = useState(null);
	// const [balanceAsString, setBalanceAsString] = useState(null);
	const [isShowPaymentSystems, setIsShowPaymentSystems] = useState(false);
	const [paymentPackageItemPrice, setPaymentPackageItemPrice] = useState(null);
	const [fillAmount, setFillAmount] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [services, setServices] = useState([]);
	const [currentUserCountry, setCurrentUserCountry] = useState(null);
	const [roleService, setRoleService] = useState(null);
	const [
		isShowOrganizationNotActiveModal,
		setIsShowOrganizationNotActiveModal,
	] = useState(false);
	const [userBalance, setUserBalance] = useState(null);
	const [paymentPackageInPrice, setPaymentPackageInPrice] = useState(0);
	const [isInvalidBalance, setIsinvalidBalance] = useState(false);
	const [paymentSystemPackages, setPaymentSystemPackages] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		if (user.isAdmin && user.isOrganizationActive === false) {
			setIsShowOrganizationNotActiveModal(true);
		}
	}, [user]);

	useEffect(() => {
		getPaymentPackages();
		getCurrentUserCountry();
	}, [language]);

	useEffect(() => {
		getPaymentSystemPackages();
	}, []);

	useEffect(() => {
		if (
			props.location.pathname.includes(PAYPAL_SUCCESS_KEY) &&
			props.location.pathname.includes(RETURN_URL_KEY)
		) {
			getPayPalResponse(SUCCESS_KEY);
		}
		if (
			props.location.pathname.includes(PAYPAL_CANCEL_KEY) &&
			props.location.pathname.includes(RETURN_URL_KEY)
		) {
			getPayPalResponse(CANCEL_KEY);
		}
		if (
			props.location.pathname.includes(STRIPE_SUCCESS_KEY) &&
			props.location.pathname.includes(RETURN_URL_KEY)
		) {
			getStripeResponse(SUCCESS_KEY);
		}
		if (
			props.location.pathname.includes(STRIPE_CANCEL_KEY) &&
			props.location.pathname.includes(RETURN_URL_KEY)
		) {
			getStripeResponse(CANCEL_KEY);
		}
		if (
			props.location.pathname.includes(CRYLLEX_SUCCESS_KEY) &&
			props.location.pathname.includes(RETURN_URL_KEY)
		) {
			getCryllexResponse(SUCCESS_KEY);
		}
		if (
			props.location.pathname.includes(CRYLLEX_CANCEL_KEY) &&
			props.location.pathname.includes(RETURN_URL_KEY)
		) {
			getCryllexResponse(CANCEL_KEY);
		}
		// getBalanceAsOrgUser();
	}, []);

	const getPaymentSystemPackages = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentSystemPackages()
			.then(response => {
				if (response && response.data && response.data.length) {
					setPaymentSystemPackages(response.data);
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getCurrentUserCountry = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		GuestApiService.getIpLocationData()
			.then(response => {
				if (response && response.data && response.data.country) {
					setCurrentUserCountry(response.data.country);
					// if (response.data.country !== "RU") {
					//   getBalanceAsOrgUser();
					// }
				}
			})
			.catch(error => {})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getPaymentPackages = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentPackages()
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.services) setServices(data.services);
					if (data.packages) setPaymentPackages(data.packages);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getBalanceAsOrgUser = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getBalanceAsOrgUser()
			.then(response => {
				if (response && response.data) {
					setUserBalance(response.data);
					// setTotalBalance(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getStripeResponse = status => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const paymentId = params.get("paymentId") || null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getStripeResponse(status, paymentId)
			.then(response => {
				if (status === SUCCESS_KEY) {
					AlertService.alert(SUCCESS_KEY, "Transaction successful");
				} else {
					AlertService.alert(ERROR_KEY, "Transaction unsuccessful");
				}
				changeUrl();
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCryllexResponse = status => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const paymentId = params.get("paymentId") || null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCryllexResponse(status, paymentId)
			.then(response => {
				if (status === SUCCESS_KEY) {
					AlertService.alert(SUCCESS_KEY, "Transaction successful");
				} else {
					AlertService.alert(ERROR_KEY, "Transaction unsuccessful");
				}
				changeUrl();
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getPayPalResponse = status => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPayPalResponse(status, props.location.search)
			.then(response => {
				if (status === SUCCESS_KEY) {
					AlertService.alert(SUCCESS_KEY, "Transaction successful");
				} else {
					AlertService.alert(ERROR_KEY, "Transaction unsuccessful");
				}
				changeUrl();
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const changeUrl = () => {
		props.history.push(
			`/${language}/organization-admin/payment-package/history`,
		);
	};

	const onChangePaymentSystem = item => {
		if (!item) return false;
		setPaymentFillSystemId(item.id);
		setPaymentFillSystem(item);
	};

	const getPaymentPackageItems = paymentPackageId => {
		if (!paymentPackageId) {
			return;
		}
		setPaymentPackageItems([]);
		setPaymentPackageId(paymentPackageId);
		setMonthCount(null);
		setIsShowPaymentSystems(null);
		const spinnerId = uuid();
		ApiService.getPaymentPackageItems(paymentPackageId)
			.then(response => {
				response.data?.prices && setPaymentPackageItems(response.data.prices);
				if (typeof window === "object")
					window.scrollTo(0, document.body.scrollHeight);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getPaymentSystems = () => {
		dispatch(addButtonSpinner(spinnerId));
		ApiService.getPaymentSystems()
			.then(response => {
				response.data && setPaymentSystems(response.data);
				dispatch(removeButtonSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const paymentAndPurchase = event => {
		// Deposit
		//checkout if your balance < totalbalance
		event.preventDefault();
		if (!paymentFillSystemId || (!fillAmount && paymentFillSystemId !== 6)) {
			setIsInvalidSubmit(true);
		} else {
			AlertService.alertConfirm(
				`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
				"",
				translationService.translate(TR_YES),
				translationService.translate(TR_NO),
			).then(() => {
				let data = {
					amount: fillAmount,
					paymentPackageId: paymentPackageId,
					monthCount: monthCount,
					recurringPayment: recurringPayment,
					paymentSystemId: paymentFillSystemId,
					returnUrl: `${window.location.origin}/${language}/organization-admin/history`,
					currencyIsoCode: localStorage.getItem("currency")
						? localStorage.getItem("currency")
						: DEFAULT_CURRENCY_KEY,
				};
				dispatch(addButtonSpinner(spinnerId));
				ApiService.paymentAndPurchaseBankTransaction(data)
					.then(response => {
						// AlertService.alert(
						// 	SUCCESS_KEY,
						// 	translationService.translate("TR_DATA_SAVED"),
						// );
						if (response && response.data && response.data.paymentSystemUrl) {
							window.location.href = response.data.paymentSystemUrl;
						}
						// `/${language}/organization-admin/payment-package/history`,
						// props.history.push(`/${language}/organization-admin/history`);
						removeButtonSpinner(spinnerId);
					})
					.catch(error => getFail(error))
					.finally(() => {
						removeButtonSpinner(spinnerId);
					});
				// ApiService.paymentAndPurchaseBankTransaction(data).then(response => {
				//   AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"))
				//   props.history.push(`/${language}/${ORGANIZATION_ADMIN_KEY}/${PAYMENT_PACKAGE_KEY}/${HISTORY_KEY}`);
				//   removeButtonSpinner(spinnerId);
				//   if (response.data && response.data.returnUrl) {
				//     window.location.href = response.data.returnUrl;
				//     cancel();
				//   }
				//   if (response.data) {
				//     checkPaymentFillResponse(paymentFillSystemId, response.data);
				//     cancel();
				//   }
				//   dispatch(removeButtonSpinner(spinnerId));
				// }).catch(error => getFail(error, spinnerId));
				// if (paymentFillSystemId === 1) {
				// 	//Paypal
				// 	dispatch(addButtonSpinner(spinnerId));
				// 	ApiService.paymentAndPurchasePaypalPackage(data)
				// 		.then(response => {
				// 			if (response.data) {
				// 				checkPaymentFillResponse(paymentFillSystemId, response.data);
				// 				cancel();
				// 			}
				// 			removeButtonSpinner(spinnerId);
				// 		})
				// 		.catch(error => getFail(error, spinnerId));
				// } else if (paymentFillSystemId === 3) {
				// 	// Stripe
				// 	dispatch(addButtonSpinner(spinnerId));
				// 	ApiService.paymentAndPurchaseStripePackage(data)
				// 		.then(response => {
				// 			if (response.data) {
				// 				checkPaymentFillResponse(paymentFillSystemId, response.data);
				// 				cancel();
				// 			}
				// 			removeButtonSpinner(spinnerId);
				// 		})
				// 		.catch(error => getFail(error, spinnerId));
				// } else if (paymentFillSystemId === 6) {
				// 	// Bank transaction
				// 	dispatch(addButtonSpinner(spinnerId));
				// 	ApiService.paymentAndPurchaseBankTransaction(data)
				// 		.then(() => {
				// 			AlertService.alert(
				// 				SUCCESS_KEY,
				// 				translationService.translate("TR_DATA_SAVED"),
				// 			);
				// 			// `/${language}/organization-admin/payment-package/history`,
				// 			props.history.push(`/${language}/organization-admin/history`);
				// 			removeButtonSpinner(spinnerId);
				// 		})
				// 		.catch(error => getFail(error, spinnerId));
				// } else {
				// 	// Others
				// }
			});
		}
	};

	const cancel = () => {
		removeButtonSpinner(spinnerId);
		setFillAmount("");
		setPaymentPackageId(null);
		setPaymentFillSystemId(null);
		setPaymentFillSystem(null);
		setMonthCount(null);
		setRecurringPayment(false);
		setIsShowPaymentSystems(false);
		setPaymentPackageItems([]);
	};
	const confirmPayment = () => {
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			// if (userBalance && userBalance.balance1 < paymentPackageInPrice) {
			// 	AlertService.alert(WARNING_KEY, "You don't have enough money to buy");
			// 	setIsinvalidBalance(true);
			// 	const stripe = paymentSystemsComponents.find(
			// 		item => item.name === "Stripe",
			// 	);
			// 	onChangePaymentSystem(stripe.id);
			// 	setFillAmount(paymentPackageInPrice - userBalance.balance1);
			// } else {
			// 	makeAdepositPaymentPackage();
			// }
			makeAdepositPaymentPackage();
		});
	};

	const makeAdepositPaymentPackage = () => {
		// if your balance > paymentPackageItemPrice
		const data = {
			paymentPackageId,
			monthCount,
		};
		if (data.paymentPackageId && data.monthCount) {
			dispatch(addButtonSpinner(spinnerId));
			ApiService.makeAdepositPaymentPackage(data)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate(
							"TR_PAYMENT_PACKAGE_SUCCESSFULLY_BOUGHT",
						),
					);
					dispatch(removeButtonSpinner(spinnerId));
				})
				.catch(error => getFail(error, spinnerId));
		} else {
			return;
		}
	};

	const onNumberChange = event => {
		if (+event.target.value.charAt(0) === 0) {
			event.target.value = event.target.value.substring(1);
		}
		if (event.target.value.includes("e")) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				Number(event.target.value) > 0 &&
				!minAmount)
		) {
			setFillAmount(+event.target.value);
		}
		if (
			event.target.value === "" ||
			(minAmount &&
				typeof +event.target.value === NUMBER_KEY &&
				Number(event.target.value) >= minAmount)
		) {
			setFillAmount(+event.target.value);
		}
	};

	const compareCurrentBalanceAndPaymentPackageItemPrice = (
		totalBalance,
		paymentPackageItemPrice,
	) => {
		if (!paymentPackageItemPrice) {
			return;
		}
		setPaymentPackageInPrice(paymentPackageItemPrice);
		if (
			(totalBalance && totalBalance < paymentPackageItemPrice) ||
			!totalBalance ||
			+totalBalance === 0
		) {
			if (!paymentSystems || !paymentSystems.length) {
				getPaymentSystems();
			}
			setIsShowPaymentSystems(true);
		}
		if (totalBalance && totalBalance > paymentPackageItemPrice) {
			setIsShowPaymentSystems(false);
		}
	};

	const checkPaymentFillResponse = (id, data) => {
		switch (id) {
			case 1:
				window.location.href = data.paypalReturnUrl;
				break;
			case 3:
				if (data.returnUrl) {
					window.location.href = data.returnUrl;
				}
				break;
			default:
				break;
		}
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		paymentPackages && paymentPackages.length ? (
			<div className="container mt-4">
				<div className="row">
					<div className="col-12">
						<h2 className="section-title">
							{translationService.translate("TR_PAYMENT_PACKAGE")}
						</h2>
						<PageInfoBlock
							pageTitle={translationService.translate("TR_PAYMENT_PACKAGE")}
							pageName="admin_payment_package"
							translationService={translationService}
						/>
					</div>
				</div>
				<hr />
				{/* <div className='row'>
          <div className='col-12'>
            <div className="d-flex flex-wrap mindalay--btn-link-wrapper--instructor">
              <Link
                to={`/${language}/${ORGANIZATION_ADMIN_KEY}/${CUSTOM_PAYMENT_PACKAGE_KEY}/${FORM_KEY}`}
                className="mindalay--btn-default mindalay--btn-link mr-sm-2 mb-2"
              >
                {translationService.translate("TR_CREATE_CUSTOM_PACKAGE")}
              </Link>
            </div>
            <hr />
          </div>
        </div> */}
				{/* {
          balanceAsString ?
            <div>
              <div className="d-flex align-items-center total-balance justify-content-start">
                <b>{`${translationService.translate("TR_YOUR_BALANCE")}: `}</b><h3 className="ml-2">{balanceAsString}</h3>
              </div>
              <hr />
            </div>
            : null
        } */}

				{isShowOrganizationNotActiveModal ? (
					<Modal
						size="lg"
						show={true}
						onHide={() => setIsShowOrganizationNotActiveModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>
								<div className="d-flex align-items-start">
									<img className="mr-2" src={warningSvg} alt="/" width="30" />
									{translationService.translate("TR_ATTENTION")}
								</div>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{translationService.translate(
								"TR_ORGANIZATION_NOT_ACTIVE_MESSAGE",
							)}
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								className="mindalay--btn-default"
								onClick={() => setIsShowOrganizationNotActiveModal(false)}>
								{translationService.translate("TR_CLOSE")}
							</button>
						</Modal.Footer>
					</Modal>
				) : null}

				<section className="pricing pt-3">
					<div className="row justify-content-center">
						{paymentPackages && paymentPackages.length
							? paymentPackages.map((paymentPackage, index) => {
									return (
										<div key={index} className="col-lg-3 col-sm-6 col-12 my-2">
											<div
												className={`card payment-card ${
													isInvalidSubmit && !paymentPackageId
														? "error-bordered"
														: ""
												}  ${
													paymentPackageId === paymentPackage.id
														? "active-card"
														: ""
												}`}>
												<div className="card-body">
													<h5 className="card-title text-muted text-uppercase text-center">
														{paymentPackage.name}
													</h5>
													<h6 className="card-price text-center">
														{paymentPackage.cost}
														<span className="period">{` /${translationService.translate(
															"TR_MONTH_PER_USER",
														)}`}</span>
													</h6>
													<hr />
													<ul className="fa-ul">
														{paymentPackage.properties &&
														paymentPackage.properties.length
															? paymentPackage.properties.map((property, i) => {
																	if (
																		property.value &&
																		property.value !== "false" &&
																		property.value !== "0" &&
																		property.value !== 0
																	) {
																		return (
																			<li key={i}>
																				<span className="fa-li">
																					<i className="fas fa-check"></i>
																				</span>
																				<strong>
																					{property.value === "true"
																						? null
																						: property.value}{" "}
																					{property.key}
																				</strong>
																			</li>
																		);
																	} else {
																		return false;
																	}
															  })
															: null}
														{paymentPackage.properties &&
														paymentPackage.properties.length
															? paymentPackage.properties.map((property, i) => {
																	if (
																		!property.value ||
																		property.value === "false" ||
																		property.value === "0" ||
																		property.value === 0
																	) {
																		return (
																			<li key={i} className="text-muted">
																				<span className="fa-li">
																					<i className="fas fa-times"></i>
																				</span>
																				{property.key}
																			</li>
																		);
																	} else {
																		return false;
																	}
															  })
															: null}
													</ul>
													<button
														className="btn mindalay--btn-default w-100 mt-auto"
														onClick={() =>
															getPaymentPackageItems(paymentPackage.id)
														}>
														{translationService.translate("TR_SELECT")}
													</button>
												</div>
											</div>
										</div>
									);
							  })
							: null}
					</div>
					{services && services.length ? (
						<Auxiliary>
							{/* <hr className='m-0'/> */}
							<div className="course-requirements">
								{/* <h3 className="title">{translationService.translate("TR_SERVICES")}</h3> */}
								<div className="row">
									<div className="col-12">
										<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
											<InfoSvg />
											{services[services.length - 1]}
										</p>
									</div>
								</div>
								<div className="course-what-we-learn p-3">
									<div className="row">
										<div className="col-12">
											<div className="row">
												{services
													.slice(0, services.length - 1)
													.map((description, index) => {
														return description ? (
															<div
																key={index}
																className="col-12 col-lg-6 col-md-12">
																<div className="d-flex chech-icon-list">
																	<i className="fas fa-check"></i>
																	<p>{description}</p>
																</div>
															</div>
														) : null;
													})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Auxiliary>
					) : null}
					{paymentPackageItems && paymentPackageItems.length ? (
						<div className="row">
							<div className="col-12">
								<hr />
							</div>
							{paymentPackageItems.map((paymentPackageItem, index) => {
								return (
									<div key={index} className="col-sm-6 col-12 my-2">
										<div
											className={`card cursor-pointer brand-background payment-card-item ${
												paymentPackageItem.monthCount === monthCount
													? "active-card-item"
													: ""
											}
                     ${
												isInvalidSubmit && !paymentPackageItem
													? "error-bordered"
													: ""
											}`}
											onClick={() => {
												setMonthCount(paymentPackageItem.monthCount);
												setPaymentPackageItemPrice(paymentPackageItem.price);
												compareCurrentBalanceAndPaymentPackageItemPrice(
													totalBalance,
													paymentPackageItem.price,
												);
												// if (typeof paymentPackageItem.price === NUMBER_KEY && typeof totalBalance === NUMBER_KEY && paymentPackageItem.price > totalBalance) {
												//   setFillAmount(Math.ceil(paymentPackageItem.price - totalBalance));
												//   setMinAmount(Math.round(paymentPackageItem.price - totalBalance));
												// }
												if (typeof paymentPackageItem.price === NUMBER_KEY) {
													setFillAmount(Math.ceil(paymentPackageItem.price));
													setMinAmount(Math.round(paymentPackageItem.price));
												}
											}}>
											<div className="card-body p-0 pt-2">
												<h6 className="card-price text-center secondary-color">
													{paymentPackageItem.priceAsString}
													<span className="period text-white">
														{`/${translationService.translate("TR_FOR")}`}
														{"\u00A0"}
														{"\u00A0"}
														{paymentPackageItem.monthCount}
														{"\u00A0"}
														{"\u00A0"}
														{translationService.translate("TR_MONTH")}
													</span>
												</h6>
												<h5 className="card-title text-uppercase text-center text-white">
													{`${translationService.translate("TR_DISCOUNT")} - `}
													{paymentPackageItem.discount}
												</h5>
											</div>
										</div>
									</div>
								);
							})}
							<div className="col-12">
								<hr />
							</div>
						</div>
					) : null}
				</section>
				{monthCount &&
				(!totalBalance ||
					(totalBalance && +totalBalance < +paymentPackageItemPrice)) &&
				monthCount &&
				currentUserCountry === "RU" ? (
					<div className="row">
						<div className="col-12">
							<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
								<InfoSvg />
								{translationService.translate("TR_PAYMENT_PACKAGE_INFO_ADMIN")}
							</p>
						</div>
						<div className="col-12">
							<hr />
						</div>
					</div>
				) : null}
				{monthCount &&
				(!totalBalance ||
					(totalBalance && +totalBalance < +paymentPackageItemPrice)) &&
				monthCount &&
				currentUserCountry === "RU" ? (
					<div className="row">
						<div className="col-12">
							<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
								<InfoSvg />
								{translationService.translate("TR_PAYMENT_PACKAGE_INFO_ADMIN")}
							</p>
						</div>
						<div className="col-12">
							<hr />
						</div>
					</div>
				) : null}
				{isShowPaymentSystems && currentUserCountry !== "RU" ? (
					<div className="row">
						<div className="col-12">
							<div className="payments-logos-container d-flex flex-wrap my-2">
								{paymentSystemPackages && paymentSystemPackages.length
									? paymentSystemPackages.map((item, index) => (
											<div
												key={item.id}
												onClick={() => onChangePaymentSystem(item)}
												className={`payment-logo-block ${
													item.id === paymentFillSystemId
														? "active-payment-system"
														: ""
												}
                              ${
																!paymentFillSystemId && isInvalidSubmit
																	? "is-invalid error-bordered error-payment-system"
																	: ""
															}`}>
												<img src={item.logo} alt="/" />
											</div>
									  ))
									: null}
							</div>
						</div>
					</div>
				) : null}

				<div className="row">
					<div className="d-flex align-items-center mb-4">
						<div className="col-12">
							<div className="mr-3">
								{/* {
                  monthCount && (+totalBalance >= +paymentPackageItemPrice || paymentFillSystemId) ?
                    <div className="org-payment-pay-repetitve">
                      <InputCheckBox
                        id="recurringPayment"
                        name="recurringPayment"
                        checked={recurringPayment}
                        infoText={translationService.translate("TR_REPETITIVE_INFO")}
                        labelValue={translationService.translate("TR_REPETITIVE")}
                        blockClassName="d-flex align-items-center my-3"
                        onChange={(event) => setRecurringPayment(event.target.checked)}
                      />
                    </div>
                    : null
                } */}

								{monthCount &&
								(!totalBalance ||
									(totalBalance && +totalBalance < +paymentPackageItemPrice)) &&
								monthCount ? (
									//your balance < payment price
									<Auxiliary>
										{paymentFillSystemId ? (
											<form onSubmit={paymentAndPurchase}>
												{paymentFillSystem &&
												paymentFillSystem.name !== "Bank" ? (
													<div className="icon-input">
														<Input
															type="number"
															id="fillAmount"
															name="fillAmount"
															min="0"
															value={fillAmount}
															labelValue={`${translationService.translate(
																"TR_AMOUNT",
															)} *`}
															// onChange={onNumberChange}
															disabled={true}
															onChange={() => {}}
															isInvalidSubmit={isInvalidSubmit}
														/>
														{/* {
                                    currencySymbol ? <span>{currencySymbol}</span> : null
                                  } */}
														<span>$</span>
													</div>
												) : null}
												<ActionButton
													type="submit"
													spinnerId={spinnerId}
													name={
														paymentFillSystem &&
														paymentFillSystem.name !== "Bank"
															? translationService.translate("TR_SELECT")
															: translationService.translate("TR_BUY")
													}
													className="btn mindalay--btn-secondary capitalize"
													clicked={paymentAndPurchase}
												/>
											</form>
										) : null}
									</Auxiliary>
								) : null}
								{/* {paymentFillSystemId === 7 ? (
									//your balance > payment price
									<ActionButton
										type="button"
										spinnerId={spinnerId}
										name={translationService.translate("TR_BUY")}
										className="btn mindalay--btn-secondary"
										clicked={confirmPayment}
									/>
								) : null} */}
								{monthCount &&
								totalBalance &&
								paymentPackageItemPrice &&
								+totalBalance >= +paymentPackageItemPrice ? (
									//your balance > payment price
									<ActionButton
										type="button"
										spinnerId={spinnerId}
										name={translationService.translate("TR_BUY")}
										className="btn mindalay--btn-secondary"
										clicked={confirmPayment}
									/>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div className="no-data-container mt-3">
				<div className="no-data-wrapper">
					<p>{translationService.translate("TR_NO_DATA")}</p>
					<img src={NodataImage} alt="/" />
				</div>
			</div>
		)
	) : null;
};

export default withRouter(PaymentPackage);

// {
// 	/* {paymentSystemsComponents && paymentSystems.length ? (
// 								<div className="payments-logos-container d-flex flex-wrap my-2">
// 									{paymentSystemsComponents.map(paymentSystemsComponent => {
// 										return paymentSystems.map(paymentSystem => {
// 											if (
// 												(paymentSystem.name === paymentSystemsComponent.name &&
// 													paymentSystem.type === 0) ||
// 												(paymentSystem.name === paymentSystemsComponent.name &&
// 													paymentSystem.type === 2)
// 											) {
// 												return (
// 													<div
// 														key={paymentSystemsComponent.id}
// 														onClick={() =>
// 															onChangePaymentSystem(paymentSystemsComponent.id)
// 														}
// 														className={`payment-logo-block ${
// 															paymentSystemsComponent.id === paymentFillSystemId
// 																? "active-payment-system"
// 																: ""
// 														}
//                               ${
// 																!paymentFillSystemId && isInvalidSubmit
// 																	? "is-invalid error-bordered error-payment-system"
// 																	: ""
// 															}`}>
// 														{paymentSystemsComponent.value}
// 													</div>
// 												);
// 											} else {
// 												return false;
// 											}
// 										});
// 									})}
// 									<div
// 										onClick={() => onChangePaymentSystem(6)}
// 										className={`payment-logo-block ${
// 											paymentFillSystemId === 6 ? "active-payment-system" : ""
// 										}
//                               ${
// 																!paymentFillSystemId && isInvalidSubmit
// 																	? "is-invalid error-bordered error-payment-system"
// 																	: ""
// 															}`}>
// 										<div className="payment-system-icon-block">
// 											<Finance />
// 											<div className="info-title d-inline-block">
// 												<div>
// 													<InfoSvg />
// 													<span style={{ display: "none" }}>
// 														{translationService.translate(
// 															"TR_CONFIRM_LATER_BY_BANK_TRANSACTION",
// 														)}
// 													</span>
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 									{!!userBalance && (
// 										<div
// 											onClick={() => onChangePaymentSystem(7)}
// 											className={`payment-logo-block ${
// 												paymentFillSystemId === 7 ? "active-payment-system" : ""
// 											}
//                               ${
// 																(!paymentFillSystemId && isInvalidSubmit) ||
// 																isInvalidBalance
// 																	? "is-invalid error-bordered error-payment-system"
// 																	: ""
// 															}`}>
// 											<div className="payment-system-icon-block">
// 												<h6>{translationService.translate("TR_BALANCE")}</h6>
// 												<div className="info-title d-inline-block">
// 													<span>{userBalance.balanceAsString}</span>
// 												</div>
// 											</div>
// 										</div>
// 									)}
// 								</div>
// 							) : null} */
// }

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import uuid from "react-uuid";
// import AlertService from "../../Services/alertService";
// import TranslationService from "../../Services/translationService";
// import {
// 	addPartialViewSpinner,
// 	removeButtonSpinner,
// 	removePartialViewSpinner,
// } from "../../Store/Actions/spinner";
// import ApiService from "./../../Services/apiService";
// import NodataImage from "../../assets/images/illustrations/nodata.svg";
// import InputCheckBox from "../../Components/Inputs/inputCheckBox";
// import { addButtonSpinner } from "./../../Store/Actions/spinner";
// import ActionButton from "../../Components/ActionButton/actionButton";
// import Paypal from "./../../Components/Svg/PaymentSvg/paypal";
// import Skrill from "../../Components/Svg/PaymentSvg/skrill";
// import StripeIcon from "../../Components/Svg/PaymentSvg/stripe";
// import WebMoney from "../../Components/Svg/PaymentSvg/webMoney";
// import YandexMoney from "../../Components/Svg/PaymentSvg/yandexMoney";
// import {
// 	CRYLLEX_CANCEL_KEY,
// 	CRYLLEX_SUCCESS_KEY,
// 	CURRENCY_SYMBOL_KEY,
// 	DEFAULT_CURRENCY_SYMBOL_KEY,
// 	TR_NO,
// 	WARNING_KEY,
// } from "./../../Constants/mainKeys";
// import { Link, withRouter } from "react-router-dom";
// import Auxiliary from "./../../hoc/auxiliary/auxiliary";
// import Input from "../../Components/Inputs/input";
// import { CANCEL_KEY } from "../../Constants/requestKeys";
// import {
// 	HISTORY_KEY,
// 	ORGANIZATION_ADMIN_KEY,
// 	PAYMENT_PACKAGE_KEY,
// } from "../../Constants/urlKeys";
// import {
// 	ERROR_KEY,
// 	NUMBER_KEY,
// 	PAYPAL_CANCEL_KEY,
// 	PAYPAL_SUCCESS_KEY,
// 	RETURN_URL_KEY,
// 	STRIPE_CANCEL_KEY,
// 	STRIPE_SUCCESS_KEY,
// 	SUCCESS_KEY,
// 	TR_YES,
// } from "../../Constants/mainKeys";
// import Finance from "../../Components/Svg/financeSvg";
// import InfoSvg from "../../Components/Svg/infoSvg";
// import GuestApiService from "../../Services/guestApiService";
// import RolesService from "../../Services/rolesService";
// import Modal from "react-bootstrap/Modal";
// import ModalSpinner from "../../Components/Spinners/modalSpinner";
// import warningSvg from "./../../assets/icons/alert-icons/warning.svg";
// import _InfoSvg from "./../../Components/Svg/_infoSvg";
// import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
// import MainService from "../../Services/mainService";

// const paymentSystemsComponents = [
// 	// {
// 	//   id: 1,
// 	//   name: "PayPal",
// 	//   value: <Paypal />
// 	// },
// 	{
// 		id: 2,
// 		name: "Skrill",
// 		value: <Skrill />,
// 	},
// 	{
// 		id: 3,
// 		name: "Stripe",
// 		value: <StripeIcon />,
// 	},
// 	{
// 		id: 4,
// 		value: <WebMoney />,
// 	},
// 	{
// 		id: 5,
// 		value: <YandexMoney />,
// 	},
// ];

// const spinnerId = uuid();
// const PaymentPackage = props => {
// 	const dispatch = useDispatch();
// 	const translations = useSelector(state => state.translation.translations);
// 	const language = useSelector(state => state.language.language);
// 	const { role, user } = useSelector(state => state.user);
// 	const currencySymbol = localStorage.getItem(CURRENCY_SYMBOL_KEY)
// 		? localStorage.getItem(CURRENCY_SYMBOL_KEY)
// 		: DEFAULT_CURRENCY_SYMBOL_KEY;
// 	const [translationService, setTranslationService] = useState(null);
// 	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
// 	const [paymentPackageId, setPaymentPackageId] = useState(null);
// 	const [monthCount, setMonthCount] = useState(null);
// 	const [paymentPackages, setPaymentPackages] = useState([]);
// 	const [recurringPayment, setRecurringPayment] = useState(false);
// 	const [paymentSystems, setPaymentSystems] = useState([]);
// 	const [paymentFillSystemId, setPaymentFillSystemId] = useState(6);
// 	const [paymentPackageItems, setPaymentPackageItems] = useState([]);
// 	const [totalBalance, setTotalBalance] = useState(null);
// 	// const [balanceAsString, setBalanceAsString] = useState(null);
// 	const [isShowPaymentSystems, setIsShowPaymentSystems] = useState(false);
// 	const [paymentPackageItemPrice, setPaymentPackageItemPrice] = useState(null);
// 	const [fillAmount, setFillAmount] = useState("");
// 	const [minAmount, setMinAmount] = useState("");
// 	const [services, setServices] = useState([]);
// 	const [currentUserCountry, setCurrentUserCountry] = useState(null);
// 	const [roleService, setRoleService] = useState(null);
// 	const [
// 		isShowOrganizationNotActiveModal,
// 		setIsShowOrganizationNotActiveModal,
// 	] = useState(false);
// 	const [userBalance, setUserBalance] = useState(null);
// 	const [paymentPackageInPrice, setPaymentPackageInPrice] = useState(0);
// 	const [isInvalidBalance, setIsinvalidBalance] = useState(false);

// 	useEffect(() => {
// 		setTranslationService(new TranslationService(translations));
// 	}, [translations]);

// 	useEffect(() => {
// 		MainService.isJson(role) &&
// 			setRoleService(new RolesService(JSON.parse(role)));
// 	}, [role]);

// 	useEffect(() => {
// 		if (user.isAdmin && user.isOrganizationActive === false) {
// 			setIsShowOrganizationNotActiveModal(true);
// 		}
// 	}, [user]);

// 	useEffect(() => {
// 		getPaymentPackages();
// 		getCurrentUserCountry();
// 	}, [language]);

// 	useEffect(() => {
// 		if (
// 			props.location.pathname.includes(PAYPAL_SUCCESS_KEY) &&
// 			props.location.pathname.includes(RETURN_URL_KEY)
// 		) {
// 			getPayPalResponse(SUCCESS_KEY);
// 		}
// 		if (
// 			props.location.pathname.includes(PAYPAL_CANCEL_KEY) &&
// 			props.location.pathname.includes(RETURN_URL_KEY)
// 		) {
// 			getPayPalResponse(CANCEL_KEY);
// 		}
// 		if (
// 			props.location.pathname.includes(STRIPE_SUCCESS_KEY) &&
// 			props.location.pathname.includes(RETURN_URL_KEY)
// 		) {
// 			getStripeResponse(SUCCESS_KEY);
// 		}
// 		if (
// 			props.location.pathname.includes(STRIPE_CANCEL_KEY) &&
// 			props.location.pathname.includes(RETURN_URL_KEY)
// 		) {
// 			getStripeResponse(CANCEL_KEY);
// 		}
// 		if (
// 			props.location.pathname.includes(CRYLLEX_SUCCESS_KEY) &&
// 			props.location.pathname.includes(RETURN_URL_KEY)
// 		) {
// 			getCryllexResponse(SUCCESS_KEY);
// 		}
// 		if (
// 			props.location.pathname.includes(CRYLLEX_CANCEL_KEY) &&
// 			props.location.pathname.includes(RETURN_URL_KEY)
// 		) {
// 			getCryllexResponse(CANCEL_KEY);
// 		}
// 		// getBalanceAsOrgUser();
// 	}, []);

// 	const getCurrentUserCountry = () => {
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		GuestApiService.getIpLocationData()
// 			.then(response => {
// 				if (response && response.data && response.data.country) {
// 					setCurrentUserCountry(response.data.country);
// 					// if (response.data.country !== "RU") {
// 					//   getBalanceAsOrgUser();
// 					// }
// 				}
// 			})
// 			.catch(error => {})
// 			.finally(() => {
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			});
// 	};

// 	const getPaymentPackages = () => {
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getPaymentPackages()
// 			.then(response => {
// 				if (response && response.data) {
// 					const data = { ...response.data };
// 					if (data.services) setServices(data.services);
// 					if (data.packages) setPaymentPackages(data.packages);
// 				}
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const getBalanceAsOrgUser = () => {
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getBalanceAsOrgUser()
// 			.then(response => {
// 				if (response && response.data) {
// 					setUserBalance(response.data);
// 					// setTotalBalance(response.data);
// 				}
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const getStripeResponse = status => {
// 		const search = window.location.search;
// 		const params = new URLSearchParams(search);
// 		const paymentId = params.get("paymentId") || null;
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getStripeResponse(status, paymentId)
// 			.then(response => {
// 				if (status === SUCCESS_KEY) {
// 					AlertService.alert(SUCCESS_KEY, "Transaction successful");
// 				} else {
// 					AlertService.alert(ERROR_KEY, "Transaction unsuccessful");
// 				}
// 				changeUrl();
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const getCryllexResponse = status => {
// 		const search = window.location.search;
// 		const params = new URLSearchParams(search);
// 		const paymentId = params.get("paymentId") || null;
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getCryllexResponse(status, paymentId)
// 			.then(response => {
// 				if (status === SUCCESS_KEY) {
// 					AlertService.alert(SUCCESS_KEY, "Transaction successful");
// 				} else {
// 					AlertService.alert(ERROR_KEY, "Transaction unsuccessful");
// 				}
// 				changeUrl();
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const getPayPalResponse = status => {
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getPayPalResponse(status, props.location.search)
// 			.then(response => {
// 				if (status === SUCCESS_KEY) {
// 					AlertService.alert(SUCCESS_KEY, "Transaction successful");
// 				} else {
// 					AlertService.alert(ERROR_KEY, "Transaction unsuccessful");
// 				}
// 				changeUrl();
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const changeUrl = () => {
// 		props.history.push(
// 			`/${language}/${ORGANIZATION_ADMIN_KEY}/${PAYMENT_PACKAGE_KEY}/${HISTORY_KEY}`,
// 		);
// 	};

// 	const onChangePaymentSystem = id => {
// 		if (!id) return;
// 		setPaymentFillSystemId(id);
// 	};

// 	const getPaymentPackageItems = paymentPackageId => {
// 		if (!paymentPackageId) {
// 			return;
// 		}
// 		setPaymentPackageItems([]);
// 		setPaymentPackageId(paymentPackageId);
// 		setMonthCount(null);
// 		setIsShowPaymentSystems(null);
// 		const spinnerId = uuid();
// 		ApiService.getPaymentPackageItems(paymentPackageId)
// 			.then(response => {
// 				response.data?.prices && setPaymentPackageItems(response.data.prices);
// 				if (typeof window === "object")
// 					window.scrollTo(0, document.body.scrollHeight);
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const getPaymentSystems = () => {
// 		dispatch(addButtonSpinner(spinnerId));
// 		ApiService.getPaymentSystems()
// 			.then(response => {
// 				response.data && setPaymentSystems(response.data);
// 				dispatch(removeButtonSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const paymentAndPurchase = event => {
// 		// Deposit
// 		//checkout if your balance < totalbalance
// 		event.preventDefault();
// 		if (!paymentFillSystemId || (!fillAmount && paymentFillSystemId !== 6)) {
// 			setIsInvalidSubmit(true);
// 		} else {
// 			AlertService.alertConfirm(
// 				`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
// 				"",
// 				translationService.translate(TR_YES),
// 				translationService.translate(TR_NO),
// 			).then(() => {
// 				let data = {
// 					amount: fillAmount,
// 					paymentPackageId: paymentPackageId,
// 					monthCount: monthCount,
// 					recurringPayment: recurringPayment,
// 					paymentSystemId: paymentFillSystemId,
// 				};
// 				dispatch(addButtonSpinner(spinnerId));
// 				// ApiService.paymentAndPurchaseBankTransaction(data).then(response => {
// 				//   AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"))
// 				//   props.history.push(`/${language}/${ORGANIZATION_ADMIN_KEY}/${PAYMENT_PACKAGE_KEY}/${HISTORY_KEY}`);
// 				//   removeButtonSpinner(spinnerId);
// 				//   if (response.data && response.data.returnUrl) {
// 				//     window.location.href = response.data.returnUrl;
// 				//     cancel();
// 				//   }
// 				//   if (response.data) {
// 				//     checkPaymentFillResponse(paymentFillSystemId, response.data);
// 				//     cancel();
// 				//   }
// 				//   dispatch(removeButtonSpinner(spinnerId));
// 				// }).catch(error => getFail(error, spinnerId));
// 				if (paymentFillSystemId === 1) {
// 					//Paypal
// 					dispatch(addButtonSpinner(spinnerId));
// 					ApiService.paymentAndPurchasePaypalPackage(data)
// 						.then(response => {
// 							if (response.data) {
// 								checkPaymentFillResponse(paymentFillSystemId, response.data);
// 								cancel();
// 							}
// 							removeButtonSpinner(spinnerId);
// 						})
// 						.catch(error => getFail(error, spinnerId));
// 				} else if (paymentFillSystemId === 3) {
// 					// Stripe
// 					dispatch(addButtonSpinner(spinnerId));
// 					ApiService.paymentAndPurchaseStripePackage(data)
// 						.then(response => {
// 							if (response.data) {
// 								checkPaymentFillResponse(paymentFillSystemId, response.data);
// 								cancel();
// 							}
// 							removeButtonSpinner(spinnerId);
// 						})
// 						.catch(error => getFail(error, spinnerId));
// 				} else if (paymentFillSystemId === 6) {
// 					// Bank transaction
// 					dispatch(addButtonSpinner(spinnerId));
// 					ApiService.paymentAndPurchaseBankTransaction(data)
// 						.then(() => {
// 							AlertService.alert(
// 								SUCCESS_KEY,
// 								translationService.translate("TR_DATA_SAVED"),
// 							);
// 							props.history.push(
// 								`/${language}/${ORGANIZATION_ADMIN_KEY}/${PAYMENT_PACKAGE_KEY}/${HISTORY_KEY}`,
// 							);
// 							removeButtonSpinner(spinnerId);
// 						})
// 						.catch(error => getFail(error, spinnerId));
// 				} else {
// 					// Others
// 				}
// 			});
// 		}
// 	};

// 	const cancel = () => {
// 		removeButtonSpinner(spinnerId);
// 		setFillAmount("");
// 		setPaymentPackageId(null);
// 		setPaymentFillSystemId(null);
// 		setMonthCount(null);
// 		setRecurringPayment(false);
// 		setIsShowPaymentSystems(false);
// 		setPaymentPackageItems([]);
// 	};
// 	const confirmPayment = () => {
// 		AlertService.alertConfirm(
// 			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
// 			"",
// 			translationService.translate(TR_YES),
// 			translationService.translate(TR_NO),
// 		).then(() => {
// 			// if (userBalance && userBalance.balance1 < paymentPackageInPrice) {
// 			// 	AlertService.alert(WARNING_KEY, "You don't have enough money to buy");
// 			// 	setIsinvalidBalance(true);
// 			// 	const stripe = paymentSystemsComponents.find(
// 			// 		item => item.name === "Stripe",
// 			// 	);
// 			// 	onChangePaymentSystem(stripe.id);
// 			// 	setFillAmount(paymentPackageInPrice - userBalance.balance1);
// 			// } else {
// 			// 	makeAdepositPaymentPackage();
// 			// }
// 			makeAdepositPaymentPackage();
// 		});
// 	};

// 	const makeAdepositPaymentPackage = () => {
// 		// if your balance > paymentPackageItemPrice
// 		const data = {
// 			paymentPackageId,
// 			monthCount,
// 		};
// 		if (data.paymentPackageId && data.monthCount) {
// 			dispatch(addButtonSpinner(spinnerId));
// 			ApiService.makeAdepositPaymentPackage(data)
// 				.then(() => {
// 					AlertService.alert(
// 						SUCCESS_KEY,
// 						translationService.translate(
// 							"TR_PAYMENT_PACKAGE_SUCCESSFULLY_BOUGHT",
// 						),
// 					);
// 					dispatch(removeButtonSpinner(spinnerId));
// 				})
// 				.catch(error => getFail(error, spinnerId));
// 		} else {
// 			return;
// 		}
// 	};

// 	const onNumberChange = event => {
// 		if (+event.target.value.charAt(0) === 0) {
// 			event.target.value = event.target.value.substring(1);
// 		}
// 		if (event.target.value.includes("e")) {
// 			return false;
// 		}
// 		if (
// 			event.target.value === "" ||
// 			(typeof +event.target.value === NUMBER_KEY &&
// 				Number(event.target.value) > 0 &&
// 				!minAmount)
// 		) {
// 			setFillAmount(+event.target.value);
// 		}
// 		if (
// 			event.target.value === "" ||
// 			(minAmount &&
// 				typeof +event.target.value === NUMBER_KEY &&
// 				Number(event.target.value) >= minAmount)
// 		) {
// 			setFillAmount(+event.target.value);
// 		}
// 	};

// 	const compareCurrentBalanceAndPaymentPackageItemPrice = (
// 		totalBalance,
// 		paymentPackageItemPrice,
// 	) => {
// 		if (!paymentPackageItemPrice) {
// 			return;
// 		}
// 		setPaymentPackageInPrice(paymentPackageItemPrice);
// 		if (
// 			(totalBalance && totalBalance < paymentPackageItemPrice) ||
// 			!totalBalance ||
// 			+totalBalance === 0
// 		) {
// 			if (!paymentSystems || !paymentSystems.length) {
// 				getPaymentSystems();
// 			}
// 			setIsShowPaymentSystems(true);
// 		}
// 		if (totalBalance && totalBalance > paymentPackageItemPrice) {
// 			setIsShowPaymentSystems(false);
// 		}
// 	};

// 	const checkPaymentFillResponse = (id, data) => {
// 		switch (id) {
// 			case 1:
// 				window.location.href = data.paypalReturnUrl;
// 				break;
// 			case 3:
// 				if (data.returnUrl) {
// 					window.location.href = data.returnUrl;
// 				}
// 				break;
// 			default:
// 				break;
// 		}
// 	};

// 	const getFail = (error, spinnerId) => {
// 		error &&
// 			AlertService.alert(
// 				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 				error,
// 			);
// 		spinnerId && dispatch(removeButtonSpinner(spinnerId));
// 		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
// 	};

// 	return translationService ? (
// 		paymentPackages && paymentPackages.length ? (
// 			<div className="container mt-4">
// 				<div className="row">
// 					<div className="col-12">
// 						<h2 className="section-title">
// 							{translationService.translate("TR_PAYMENT_PACKAGE")}
// 						</h2>
// 						<PageInfoBlock
// 							pageTitle={translationService.translate("TR_PAYMENT_PACKAGE")}
// 							pageName="admin_payment_package"
// 							translationService={translationService}
// 						/>
// 					</div>
// 				</div>
// 				<hr />
// 				{/* <div className='row'>
//           <div className='col-12'>
//             <div className="d-flex flex-wrap mindalay--btn-link-wrapper--instructor">
//               <Link
//                 to={`/${language}/${ORGANIZATION_ADMIN_KEY}/${CUSTOM_PAYMENT_PACKAGE_KEY}/${FORM_KEY}`}
//                 className="mindalay--btn-default mindalay--btn-link mr-sm-2 mb-2"
//               >
//                 {translationService.translate("TR_CREATE_CUSTOM_PACKAGE")}
//               </Link>
//             </div>
//             <hr />
//           </div>
//         </div> */}
// 				{/* {
//           balanceAsString ?
//             <div>
//               <div className="d-flex align-items-center total-balance justify-content-start">
//                 <b>{`${translationService.translate("TR_YOUR_BALANCE")}: `}</b><h3 className="ml-2">{balanceAsString}</h3>
//               </div>
//               <hr />
//             </div>
//             : null
//         } */}

// 				{isShowOrganizationNotActiveModal ? (
// 					<Modal
// 						size="lg"
// 						show={true}
// 						onHide={() => setIsShowOrganizationNotActiveModal(false)}>
// 						<Modal.Header closeButton>
// 							<Modal.Title>
// 								<div className="d-flex align-items-start">
// 									<img className="mr-2" src={warningSvg} alt="/" width="30" />
// 									{translationService.translate("TR_ATTENTION")}
// 								</div>
// 							</Modal.Title>
// 						</Modal.Header>
// 						<Modal.Body>
// 							{translationService.translate(
// 								"TR_ORGANIZATION_NOT_ACTIVE_MESSAGE",
// 							)}
// 						</Modal.Body>
// 						<Modal.Footer>
// 							<button
// 								type="button"
// 								className="mindalay--btn-default"
// 								onClick={() => setIsShowOrganizationNotActiveModal(false)}>
// 								{translationService.translate("TR_CLOSE")}
// 							</button>
// 						</Modal.Footer>
// 					</Modal>
// 				) : null}

// 				<section className="pricing pt-3">
// 					<div className="row justify-content-center">
// 						{paymentPackages && paymentPackages.length
// 							? paymentPackages.map((paymentPackage, index) => {
// 									return (
// 										<div key={index} className="col-lg-3 col-sm-6 col-12 my-2">
// 											<div
// 												className={`card payment-card ${
// 													isInvalidSubmit && !paymentPackageId
// 														? "error-bordered"
// 														: ""
// 												}  ${
// 													paymentPackageId === paymentPackage.id
// 														? "active-card"
// 														: ""
// 												}`}>
// 												<div className="card-body">
// 													<h5 className="card-title text-muted text-uppercase text-center">
// 														{paymentPackage.name}
// 													</h5>
// 													<h6 className="card-price text-center">
// 														{paymentPackage.cost}
// 														<span className="period">{` /${translationService.translate(
// 															"TR_MONTH_PER_USER",
// 														)}`}</span>
// 													</h6>
// 													<hr />
// 													<ul className="fa-ul">
// 														{paymentPackage.properties &&
// 														paymentPackage.properties.length
// 															? paymentPackage.properties.map((property, i) => {
// 																	if (
// 																		property.value &&
// 																		property.value !== "false" &&
// 																		property.value !== "0" &&
// 																		property.value !== 0
// 																	) {
// 																		return (
// 																			<li key={i}>
// 																				<span className="fa-li">
// 																					<i className="fas fa-check"></i>
// 																				</span>
// 																				<strong>
// 																					{property.value === "true"
// 																						? null
// 																						: property.value}{" "}
// 																					{property.key}
// 																				</strong>
// 																			</li>
// 																		);
// 																	} else {
// 																		return false;
// 																	}
// 															  })
// 															: null}
// 														{paymentPackage.properties &&
// 														paymentPackage.properties.length
// 															? paymentPackage.properties.map((property, i) => {
// 																	if (
// 																		!property.value ||
// 																		property.value === "false" ||
// 																		property.value === "0" ||
// 																		property.value === 0
// 																	) {
// 																		return (
// 																			<li key={i} className="text-muted">
// 																				<span className="fa-li">
// 																					<i className="fas fa-times"></i>
// 																				</span>
// 																				{property.key}
// 																			</li>
// 																		);
// 																	} else {
// 																		return false;
// 																	}
// 															  })
// 															: null}
// 													</ul>
// 													<button
// 														className="btn mindalay--btn-default w-100 mt-auto"
// 														onClick={() =>
// 															getPaymentPackageItems(paymentPackage.id)
// 														}>
// 														{translationService.translate("TR_SELECT")}
// 													</button>
// 												</div>
// 											</div>
// 										</div>
// 									);
// 							  })
// 							: null}
// 					</div>
// 					{services && services.length ? (
// 						<Auxiliary>
// 							{/* <hr className='m-0'/> */}
// 							<div className="course-requirements">
// 								{/* <h3 className="title">{translationService.translate("TR_SERVICES")}</h3> */}
// 								<div className="row">
// 									<div className="col-12">
// 										<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
// 											<InfoSvg />
// 											{services[services.length - 1]}
// 										</p>
// 									</div>
// 								</div>
// 								<div className="course-what-we-learn p-3">
// 									<div className="row">
// 										<div className="col-12">
// 											<div className="row">
// 												{services
// 													.slice(0, services.length - 1)
// 													.map((description, index) => {
// 														return description ? (
// 															<div
// 																key={index}
// 																className="col-12 col-lg-6 col-md-12">
// 																<div className="d-flex chech-icon-list">
// 																	<i className="fas fa-check"></i>
// 																	<p>{description}</p>
// 																</div>
// 															</div>
// 														) : null;
// 													})}
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</Auxiliary>
// 					) : null}
// 					{paymentPackageItems && paymentPackageItems.length ? (
// 						<div className="row">
// 							<div className="col-12">
// 								<hr />
// 							</div>
// 							{paymentPackageItems.map((paymentPackageItem, index) => {
// 								return (
// 									<div key={index} className="col-sm-6 col-12 my-2">
// 										<div
// 											className={`card cursor-pointer brand-background payment-card-item ${
// 												paymentPackageItem.monthCount === monthCount
// 													? "active-card-item"
// 													: ""
// 											}
//                      ${
// 												isInvalidSubmit && !paymentPackageItem
// 													? "error-bordered"
// 													: ""
// 											}`}
// 											onClick={() => {
// 												setMonthCount(paymentPackageItem.monthCount);
// 												setPaymentPackageItemPrice(paymentPackageItem.price);
// 												compareCurrentBalanceAndPaymentPackageItemPrice(
// 													totalBalance,
// 													paymentPackageItem.price,
// 												);
// 												// if (typeof paymentPackageItem.price === NUMBER_KEY && typeof totalBalance === NUMBER_KEY && paymentPackageItem.price > totalBalance) {
// 												//   setFillAmount(Math.ceil(paymentPackageItem.price - totalBalance));
// 												//   setMinAmount(Math.round(paymentPackageItem.price - totalBalance));
// 												// }
// 												if (typeof paymentPackageItem.price === NUMBER_KEY) {
// 													setFillAmount(Math.ceil(paymentPackageItem.price));
// 													setMinAmount(Math.round(paymentPackageItem.price));
// 												}
// 											}}>
// 											<div className="card-body p-0 pt-2">
// 												<h6 className="card-price text-center secondary-color">
// 													{paymentPackageItem.priceAsString}
// 													<span className="period text-white">
// 														{`/${translationService.translate("TR_FOR")}`}
// 														{"\u00A0"}
// 														{"\u00A0"}
// 														{paymentPackageItem.monthCount}
// 														{"\u00A0"}
// 														{"\u00A0"}
// 														{translationService.translate("TR_MONTH")}
// 													</span>
// 												</h6>
// 												<h5 className="card-title text-uppercase text-center text-white">
// 													{`${translationService.translate("TR_DISCOUNT")} - `}
// 													{paymentPackageItem.discount}
// 												</h5>
// 											</div>
// 										</div>
// 									</div>
// 								);
// 							})}
// 							<div className="col-12">
// 								<hr />
// 							</div>
// 						</div>
// 					) : null}
// 				</section>
// 				{monthCount &&
// 				(!totalBalance ||
// 					(totalBalance && +totalBalance < +paymentPackageItemPrice)) &&
// 				monthCount &&
// 				currentUserCountry === "RU" ? (
// 					<div className="row">
// 						<div className="col-12">
// 							<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
// 								<InfoSvg />
// 								{translationService.translate("TR_PAYMENT_PACKAGE_INFO_ADMIN")}
// 							</p>
// 						</div>
// 						<div className="col-12">
// 							<hr />
// 						</div>
// 					</div>
// 				) : null}
// 				{monthCount &&
// 				(!totalBalance ||
// 					(totalBalance && +totalBalance < +paymentPackageItemPrice)) &&
// 				monthCount &&
// 				currentUserCountry === "RU" ? (
// 					<div className="row">
// 						<div className="col-12">
// 							<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
// 								<InfoSvg />
// 								{translationService.translate("TR_PAYMENT_PACKAGE_INFO_ADMIN")}
// 							</p>
// 						</div>
// 						<div className="col-12">
// 							<hr />
// 						</div>
// 					</div>
// 				) : null}
// 				{isShowPaymentSystems && currentUserCountry !== "RU" ? (
// 					<div className="row">
// 						<div className="col-12">
// 							{paymentSystemsComponents && paymentSystems.length ? (
// 								<div className="payments-logos-container d-flex flex-wrap my-2">
// 									{paymentSystemsComponents.map(paymentSystemsComponent => {
// 										return paymentSystems.map(paymentSystem => {
// 											if (
// 												(paymentSystem.name === paymentSystemsComponent.name &&
// 													paymentSystem.type === 0) ||
// 												(paymentSystem.name === paymentSystemsComponent.name &&
// 													paymentSystem.type === 2)
// 											) {
// 												return (
// 													<div
// 														key={paymentSystemsComponent.id}
// 														onClick={() =>
// 															onChangePaymentSystem(paymentSystemsComponent.id)
// 														}
// 														className={`payment-logo-block ${
// 															paymentSystemsComponent.id === paymentFillSystemId
// 																? "active-payment-system"
// 																: ""
// 														}
//                               ${
// 																!paymentFillSystemId && isInvalidSubmit
// 																	? "is-invalid error-bordered error-payment-system"
// 																	: ""
// 															}`}>
// 														{paymentSystemsComponent.value}
// 													</div>
// 												);
// 											} else {
// 												return false;
// 											}
// 										});
// 									})}
// 									<div
// 										onClick={() => onChangePaymentSystem(6)}
// 										className={`payment-logo-block ${
// 											paymentFillSystemId === 6 ? "active-payment-system" : ""
// 										}
//                               ${
// 																!paymentFillSystemId && isInvalidSubmit
// 																	? "is-invalid error-bordered error-payment-system"
// 																	: ""
// 															}`}>
// 										<div className="payment-system-icon-block">
// 											<Finance />
// 											<div className="info-title d-inline-block">
// 												<div>
// 													<InfoSvg />
// 													<span style={{ display: "none" }}>
// 														{translationService.translate(
// 															"TR_CONFIRM_LATER_BY_BANK_TRANSACTION",
// 														)}
// 													</span>
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 									{/* {!!userBalance && (
// 										<div
// 											onClick={() => onChangePaymentSystem(7)}
// 											className={`payment-logo-block ${
// 												paymentFillSystemId === 7 ? "active-payment-system" : ""
// 											}
//                               ${
// 																(!paymentFillSystemId && isInvalidSubmit) ||
// 																isInvalidBalance
// 																	? "is-invalid error-bordered error-payment-system"
// 																	: ""
// 															}`}>
// 											<div className="payment-system-icon-block">
// 												<h6>{translationService.translate("TR_BALANCE")}</h6>
// 												<div className="info-title d-inline-block">
// 													<span>{userBalance.balanceAsString}</span>
// 												</div>
// 											</div>
// 										</div>
// 									)} */}
// 								</div>
// 							) : null}
// 						</div>
// 					</div>
// 				) : null}

// 				<div className="row">
// 					<div className="d-flex align-items-center mb-4">
// 						<div className="col-12">
// 							<div className="mr-3">
// 								{/* {
//                   monthCount && (+totalBalance >= +paymentPackageItemPrice || paymentFillSystemId) ?
//                     <div className="org-payment-pay-repetitve">
//                       <InputCheckBox
//                         id="recurringPayment"
//                         name="recurringPayment"
//                         checked={recurringPayment}
//                         infoText={translationService.translate("TR_REPETITIVE_INFO")}
//                         labelValue={translationService.translate("TR_REPETITIVE")}
//                         blockClassName="d-flex align-items-center my-3"
//                         onChange={(event) => setRecurringPayment(event.target.checked)}
//                       />
//                     </div>
//                     : null
//                 } */}

// 								{monthCount &&
// 								(!totalBalance ||
// 									(totalBalance && +totalBalance < +paymentPackageItemPrice)) &&
// 								monthCount ? (
// 									//your balance < payment price
// 									<Auxiliary>
// 										{paymentFillSystemId === 1 ||
// 										paymentFillSystemId === 3 ||
// 										paymentFillSystemId === 6 ? (
// 											<form onSubmit={paymentAndPurchase}>
// 												{paymentFillSystemId !== 6 ? (
// 													<div className="icon-input">
// 														<Input
// 															type="number"
// 															id="fillAmount"
// 															name="fillAmount"
// 															min="0"
// 															value={fillAmount}
// 															labelValue={`${translationService.translate(
// 																"TR_AMOUNT",
// 															)} *`}
// 															// onChange={onNumberChange}
// 															disabled={true}
// 															onChange={() => {}}
// 															isInvalidSubmit={isInvalidSubmit}
// 														/>
// 														{/* {
//                                     currencySymbol ? <span>{currencySymbol}</span> : null
//                                   } */}
// 														<span>$</span>
// 													</div>
// 												) : null}
// 												<ActionButton
// 													type="submit"
// 													spinnerId={spinnerId}
// 													name={
// 														paymentFillSystemId === 6
// 															? translationService.translate("TR_SELECT")
// 															: translationService.translate("TR_BUY")
// 													}
// 													className="btn mindalay--btn-secondary capitalize"
// 													clicked={paymentAndPurchase}
// 												/>
// 											</form>
// 										) : null}
// 									</Auxiliary>
// 								) : null}
// 								{/* {paymentFillSystemId === 7 ? (
// 									//your balance > payment price
// 									<ActionButton
// 										type="button"
// 										spinnerId={spinnerId}
// 										name={translationService.translate("TR_BUY")}
// 										className="btn mindalay--btn-secondary"
// 										clicked={confirmPayment}
// 									/>
// 								) : null} */}
// 								{monthCount &&
// 								totalBalance &&
// 								paymentPackageItemPrice &&
// 								+totalBalance >= +paymentPackageItemPrice ? (
// 									//your balance > payment price
// 									<ActionButton
// 										type="button"
// 										spinnerId={spinnerId}
// 										name={translationService.translate("TR_BUY")}
// 										className="btn mindalay--btn-secondary"
// 										clicked={confirmPayment}
// 									/>
// 								) : null}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		) : (
// 			<div className="no-data-container mt-3">
// 				<div className="no-data-wrapper">
// 					<p>{translationService.translate("TR_NO_DATA")}</p>
// 					<img src={NodataImage} alt="/" />
// 				</div>
// 			</div>
// 		)
// 	) : null;
// };

// export default withRouter(PaymentPackage);
