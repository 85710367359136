import { ADD_SETTINGS } from "../../Constants/reduxKeys";

let initialState = {
	settings: localStorage.getItem("settings")
		? JSON.parse(localStorage.getItem("settings"))
		: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_SETTINGS:
			if (action.payload) {
				localStorage.setItem("settings", JSON.stringify(action.payload));
			} else {
				localStorage.removeItem("settings");
			}
			return {
				...state,
				settings: action.payload,
			};
		default:
			break;
	}
	return state;
};

export default reducer;
