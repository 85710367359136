export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_UPDATE_SUCCESS = "CURRENT_USER_UPDATE_SUCCESS";
export const GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS =
	"GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS";
export const LANGUAGE_SUCCESS = "LANGUAGE_SUCCESS";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const CHANGE_CURRENT_LANGUAGE_SUCCESS =
	"CHANGE_CURRENT_LANGUAGE_SUCCESS";
export const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS";
export const ADD_PAGE_SPINNER = "ADD_PAGE_SPINNER";
export const REMOVE_PAGE_SPINNER = "REMOVE_PAGE_SPINNER";
export const ADD_TOPIC_SPINNER = "ADD_TOPIC_SPINNER";
export const REMOVE_TOPIC_SPINNER = "REMOVE_TOPIC_SPINNER";
export const ADD_BUTTON_SPINNER = "ADD_BUTTON_SPINNER";
export const REMOVE_BUTTON_SPINNER = "REMOVE_BUTTON_SPINNER";
export const ADD_TEXT_SPINNER = "ADD_TEXT_SPINNER";
export const REMOVE_TEXT_SPINNER = "REMOVE_TEXT_SPINNER";
export const ADD_PARTIAL_VIEW_SPINNER = "ADD_PARTIAL_VIEW_SPINNER";
export const REMOVE_PARTIAL_VIEW_SPINNER = "REMOVE_PARTIAL_VIEW_SPINNER";
export const ADD_MODAL_SPINNER = "ADD_MODAL_SPINNER";
export const REMOVE_MODAL_SPINNER = "REMOVE_MODAL_SPINNER";
export const GET_UNAUTH_COURSES_SUCCESS = "GET_UNAUTH_COURSES_SUCCESS";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const SHOW_ALERT_COFIRM = "SHOW_ALERT_COFIRM";
export const HIDE_ALERT_COFIRM = "HIDE_ALERT_COFIRM";
export const GET_CATEGORIES_BY_COURSE_SUCCESS =
	"GET_CATEGORIES_BY_COURSE_SUCCESS";
export const GET_POTENTIAL_CONSULTATIONS_COUNT_SUCCESS =
	"GET_POTENTIAL_CONSULTATIONS_COUNT_SUCCESS";
export const SET_CURRENT_COURSE = "SET_CURRENT_COURSE";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const SET_UNREAD_MESSAGES_COUNT = "SET_UNREAD_MESSAGES_COUNT";
export const SET_PURE_LAYOUT_BACK_URL = "SET_PURE_LAYOUT_BACK_URL";
export const SET_CONSULTATION_CONTRACT_BACK_URL =
	"SET_CONSULTATION_CONTRACT_BACK_URL";
export const SET_CURICULUMN_BREAD_CRUMB = "SET_CURICULUMN_BREAD_CRUMB";
export const GET_HELP_DATA = "GET_HELP_DATA";
export const SET_HELP_DATA = "SET_HELP_DATA";
export const GET_HELP_DATA_SUCCESS = "GET_HELP_DATA_SUCCESS";
export const SET_FAQ_PROPERTY_ID = "SET_FAQ_PROPERTY_ID";
export const CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT =
	"CHANGE_SHOPPINGCART_OR_WISHLIST_PRODUCTS_COUNT";
export const CHANGE_SHOPPING_CART_COUNT = "CHANGE_SHOPPING_CART_COUNT";
export const CHANGE_WISH_LIST_COUNT = "CHANGE_WISH_LIST_COUNT";
export const SHOW_WEBINAR_SCREEN_NOTIFICATION =
	"SHOW_WEBINAR_SCREEN_NOTIFICATION";
export const SET_SERACH_TEXT = "SET_SERACH_TEXT";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const SHOW_OR_HIDE_GENERAL_DASHBOARD_LEFT_MENU =
	"showOrHideGeneralDashboardLeftMenu";
export const SET_IS_SHOW_HELP_LEFT_MENU = "SET_IS_SHOW_HELP_LEFT_MENU";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const SET_BACK_URL = "SET_BACK_URL";
export const SET_GLOBAL_CONNECTION = "SET_GLOBAL_CONNECTION";
export const SHOW_HELP_ALERT = "SHOW_HELP_ALERT";
export const GET_CURRENT_QUESTION_MARK_DATA = "GET_CURRENT_QUESTION_MARK_DATA";
export const GET_CURRENT_QUESTION_MARK_DATA_SUCCESS =
	"GET_CURRENT_QUESTION_MARK_DATA_SUCCESS";
export const SET_QUESTION_MARK_SPINNER = "SET_QUESTION_MARK_SPINNER";
export const SET_URL_EXTENSION = "SET_URL_EXTENSION";
export const SET_WEBINAR_DATA = "SET_WEBINAR_DATA";
export const SET_B2B_WEBINAR_DATA = "SET_B2B_WEBINAR_DATA";
export const REMOVE_WEBINAR_DATA = "REMOVE_WEBINAR_DATA";
export const SET_CURRENT_FAQ_CONTENT = "SET_CURRENT_FAQ_CONTENT";
export const SET_FAQ_PAGE_CONTENT = "SET_FAQ_PAGE_CONTENT";
export const SET_TOPIC_PAGE_CONTENT = "SET_TOPIC_PAGE_CONTENT";
export const SET_PAGE_CONTENT = "SET_PAGE_CONTENT";
export const ADD_SETTINGS = "SET_SETTINGS";
