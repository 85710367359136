import React from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";

const ModalSpinner = props => {
	const { settings } = useSelector(state => state.settings);

	return props.spinners && props.spinners.length ? (
		<div className="page-spinner-wrapper modal-spinner">
			{props.spinners.map(spinner => (
				<div key={spinner} className="center page-loader">
					<Loader
						type="ThreeDots"
						color={
							settings && settings.mainColor ? settings.mainColor : "#32064A"
						}
						height={60}
						width={60}
					/>
				</div>
			))}
		</div>
	) : null;
};

export default ModalSpinner;
