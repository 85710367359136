import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../UserPages/Home/home";
import Login from "../UserPages/Login/login";
import Registration from "../UserPages/Registration/registration";
import Terms from "../UserPages/Policy/terms";
import DataPolicy from "../UserPages/Policy/dataPolicy";
import CookiesPocicy from "../UserPages/Policy/cookiesPolicy";
import ConfirmEmail from "../UserPages/ConfirmEmail/confirmEmail";
import ResetPassword from "../UserPages/ResetPassword/resetPassword";
import CourseView from "../Pages/Courses/courseView";
import CoursesByCategoryView from "../Pages/Courses/Category/CoursesByCategoryView";
import JoinMeeting from "../UserPages/JoinMeeting/joinMeeting";
import JoinOneTimeWebinar from "../Pages/Webinars/joinOneTimeWebinar";
import PublicProfile from "../Pages/Profile/publicProfile";
import WishList from "../Pages/WishList/wishList";
import ShoppingCart from "../Pages/ShoppingCart/shoppingCart";
import BecomeInstructor from "../Pages/BecomeInstructor/becomeInstructor";
import Tickets from "../Pages/Tickets/tickets";
import Info from "../UserPages/Info/info";
import FooterMenu from "../UserPages/FooterMenu/footerMenu";
import NotFoundPage from "../Pages/404/notFoundPage";
import { LANGUAGE_KEY } from "./../Constants/mainKeys";
import Help from "../Pages/Help/help";
import Faq from "../Pages/Help/faq";
import Topic from "../Pages/Help/topic";
import OrganizationForm from "../UserPages/Organization/organizationForm";
import ContactUs from "../UserPages/ContactUs/contactUs";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import CustomPaymentPackage from "./../UserPages/CustomPaymentPackage/customPaymentPackage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import _Help from "../Pages/Help/_help";
import About from "../UserPages/About/about";
import TermsAndCondition from "../UserPages/TermsAndCondition/termsAndCondition";
import PrivacyPolicy from "../UserPages/PrivacyPolicy/privacyPolicy";

const UserRoutes = () => {
	return (
		<Switch>
			{/* Webinars */}
			<Route
				path="/:language/onetimewebinar/:webinarId/:webinarCalendarId/:lecturerUserId"
				exact
				component={JoinOneTimeWebinar}
			/>
			<Route
				path="/:language/video-conference-room/:webinarId/:webinarCalendarId/:lecturerUserId"
				exact
				component={JoinOneTimeWebinar}
			/>

			{/* Home */}
			<Route path="/:language" exact component={Home} />
			<Route
				path="/:language/emailactivation/:username/:password"
				exact
				component={Home}
			/>

			{/* Profile */}
			<Route
				path="/:language/profile/:userId"
				exact
				component={PublicProfile}
			/>

			{/* Course view */}
			<Route path="/:language/courses/:courseId" exact component={CourseView} />
			<Route
				path="/:language/courses/:courseId/returnurl"
				exact
				component={CourseView}
			/>

			{/* Courses by category */}
			<Route
				path="/:language/category"
				exact
				component={CoursesByCategoryView}
			/>
			<Route
				path="/:language/category/:mainCategoryId"
				exact
				component={CoursesByCategoryView}
			/>
			<Route
				path="/:language/category/:mainCategoryId/:categoryId"
				exact
				component={CoursesByCategoryView}
			/>
			<Route
				path="/:language/category/:mainCategoryId/:categoryId/:subcategoryId"
				exact
				component={CoursesByCategoryView}
			/>

			{/* Go to meeting(one time webinar) */}
			<Route path="/:language/joinmeeting" exact component={JoinMeeting} />

			{/* Login, Registration */}
			<Route path="/:language/login" exact component={Login} />
			<Route path="/:language/registration" exact component={Registration} />

			{/* Help */}
			{/* <Route path="/:language/help" exact component={Help} />
    <Route path="/:language/help/:pageName" exact component={Help} />
    <Route path="/:language/help/:pageName/FAQ" exact component={Faq} />
    <Route path="/:language/help/:pageName/topic/:topicId" exact component={Topic} /> */}
			<Route path="/:language/help" exact component={_Help} />
			<Route path="/:language/help/topic/:topicId" exact component={_Help} />
			<Route path="/:language/help/faq/:faqId" exact component={_Help} />

			{/* Change password */}
			<Route path="/:language/forgot-password" exact component={ConfirmEmail} />
			<Route
				path="/:language/user/changecredentials/:guid"
				exact
				component={ResetPassword}
			/>

			{/* Terms and Policy */}
			<Route path="/:language/terms" exact component={Terms} />
			<Route path="/:language/data-policy" exact component={DataPolicy} />

			{/* Wish List */}
			<Route path="/:language/wish-list" exact component={WishList} />

			{/* ShopingCart */}
			<Route path="/:language/shopping-cart" exact component={ShoppingCart} />

			{/* Become Instructor */}
			<Route
				path="/:language/pure/become-instructor"
				exact
				component={BecomeInstructor}
			/>

			{/* Tickets */}
			<Route path="/:language/tickets" exact component={Tickets} />

			{/* Info page */}
			<Route
				path="/:language/mindalay-for-institutions/info"
				exact
				component={Info}
			/>
			<Route path="/:language/learn-in-mindalay/info" exact component={Info} />
			<Route path="/:language/teach-in-mindalay/info" exact component={Info} />
			<Route path="/:language/become-consultant/info" exact component={Info} />

			{/* Create organization */}
			<Route
				path="/:language/organization-admin/organization-form"
				exact
				component={OrganizationForm}
			/>

			{/* 404 */}
			<Route path="/:language/not-found/404" exact component={NotFoundPage} />

			{/* Contact us */}
			<Route path="/:language/contact-us" exact component={ContactUs} />
			<Route path="/:language/about/:pageId" exact component={About} />
			<Route
				path="/:language/privacy-policy/:pageId"
				exact
				component={PrivacyPolicy}
			/>
			<Route
				path="/:language/cookies-policy/:pageId"
				exact
				component={CookiesPocicy}
			/>
			<Route
				path="/:language/terms-and-condition/:pageId"
				exact
				component={TermsAndCondition}
			/>

			{/* Custom payment package */}
			<Route
				path="/:language/custom-payment-package"
				exact
				component={CustomPaymentPackage}
			/>

			{/* About-us info */}
			<Route path="/:language/tariffs/:pageId" exact component={FooterMenu} />
			<Route
				path="/:language/platform-instructions/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route
				path="/:language/company-policy/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route
				path="/:language/personal-data-processing-policy/:pageId"
				exact
				component={FooterMenu}
			/>

			{/* AuthenticationByRefreshToken */}
			<Route
				path="/:language/authentication/:refreshToken"
				exact
				component={AuthenticationByRefreshToken}
			/>

			{/* Server error page */}
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>

			<Redirect to={`/${localStorage.getItem(LANGUAGE_KEY)}`} />
			<Route component={NotFoundPage} />
		</Switch>
	);
};
export default UserRoutes;
