import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import TranslationService from "../../Services/translationService";
import ActionButton from "../../Components/ActionButton/actionButton";
import uuid from 'react-uuid';
import AuthInput from '../../Components/Inputs/authInput';
import MainService from "../../Services/mainService";
import { userRegistration } from "../../Store/Actions/user";
import { TERMS_KEY, DATA_POLICY_KEY, COOKIES_POLICY_KEY } from '../../Constants/urlKeys';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import ApiService from '../../Services/apiService';
import { addModalSpinner, removeModalSpinner, addButtonSpinner, removeButtonSpinner } from './../../Store/Actions/spinner';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
import { CURRENCY_KEY, ERROR_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';


class Registration extends Component {
  state = {
    form: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      currencyId: null,
    },
    spinnerId: uuid(),
    isInvalidSubmit: false,
    errorMessages: [],
    translationService: null,
    nameMaxLength: 100,
    emailMaxLength: 120,
    passwordMinLength: 6,
    passwordMaxLength: 100,
  };

  componentDidMount() {
    this.setTranslations();
    this.getCurrencies();
  }

  componentWillUnmount() {
    localStorage.removeItem(CURRENCY_KEY);
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      errorMessages: [],
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  };

  checkFieldValidation = (field, fieldName) => {
    const { password, confirmPassword } = this.state.form;
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      this.changeIsinvalidFieldName(isValid, "isInvalidPassword");
    }
    if (fieldName === "confirmPassword") {
      this.changeIsinvalidFieldName(isValid, "isInvalidconfirmPassword");
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (password && confirmPassword && (password !== confirmPassword)) {
        this.changeIsinvalidFieldName(false, "mismatchPasswords");
      } else {
        this.changeIsinvalidFieldName(true, "mismatchPasswords");
      }
    }
    switch (fieldName) {
      case "firstName":
        this.changeIsinvalidFieldName(isValid, "isInvalidFirstName")
        break;
      case "lastName":
        this.changeIsinvalidFieldName(isValid, "isInvalidLastName")
        break;
      case "email":
        this.changeIsinvalidFieldName(isValid, "isInvalidEmail")
        break;
      default:
        break;
    }
  }

  changeCurrency = (selectedItem) => {
    if (!selectedItem || !selectedItem.id) { return; }
    this.setState(prevState => ({
      ...prevState,
      errorMessages: [],
      form: {
        ...prevState.form,
        currencyId: selectedItem.id,
      }
    }));
    localStorage.setItem(CURRENCY_KEY, selectedItem.isocode);
  }

  changeIsinvalidFieldName = (isValid, fieldName) => {
    if (!isValid) {
      this.setState({ [fieldName]: true });
    } else {
      this.setState({ [fieldName]: false });
    }
  }

  getCurrencies = () => {
    const { spinnerId } = this.state;
    this.props.addButtonSpinner(spinnerId);
    ApiService.getCurrency().then(response => {
      if (response.data && response.data.length) {
        const data = [...response.data];
        this.setState(prevState => ({
          ...prevState,
          currencies: data,
          form: {
            ...prevState.form,
            currencyId: data[0].id
          }
        }), () => {
          localStorage.setItem(CURRENCY_KEY, response.data[0].isocode)
        });
      }
      this.props.removeButtonSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId))
  }

  asyncLocalStorageSet = (key, value) => {
    if (!key || !value) { return Promise.reject() }
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { spinnerId } = this.state;
    const form = { ...this.state.form };
    const {
      isInvalidFirstName,
      isInvalidLastName,
      isInvalidEmail,
      isInvalidPassword,
      mismatchPasswords,
      isInvalidconfirmPassword } = this.state;
    if (
      (form.firstName &&
        form.lastName &&
        form.email &&
        form.password &&
        form.confirmPassword) &&
      (!isInvalidFirstName &&
        !isInvalidLastName &&
        !isInvalidEmail &&
        !isInvalidPassword &&
        !mismatchPasswords &&
        !isInvalidconfirmPassword)
    ) {
      form.firstName = form.firstName.trim();
      form.lastName = form.lastName.trim();
      form.email = form.email.trim();
      if (form.password !== form.confirmPassword) {
        this.addErrorMessage('TR_CHECK_PASSWORD');
      } else {
        this.props.userRegistration({ ...form }, spinnerId);
      }
    } else {
      this.addErrorMessage('TR_COMPLETE_FIELDS_MESSAGE_ERROR');
    };
  };

  addErrorMessage = (message) => {
    const { translationService } = this.state;
    this.setState(prevState => ({
      isInvalidSubmit: true,
      errorMessages: [
        ...prevState.errorMessages,
        translationService.translate(message)
      ]
    }));
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removeButtonSpinner(spinnerId);
    spinnerId && this.props.removeModalSpinner(spinnerId);
  }

  render() {
    const {
      spinnerId,
      isInvalidSubmit,
      errorMessages,
      translationService,
      isInvalidFirstName,
      isInvalidLastName,
      isInvalidEmail,
      isInvalidPassword,
      mismatchPasswords,
      isInvalidconfirmPassword,
      nameMaxLength,
      passwordMaxLength,
      emailMaxLength,
      currencies
    } = this.state;

    const { firstName, lastName, email, password, confirmPassword, currencyId } = this.state.form;
    const { errorMessage, language, userRegistered, registrationErrorMessage } = this.props;

    return (
      translationService ? <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="authorization-form content-background" style={{ maxWidth: "500px" }}>
                {!userRegistered ?
                  <Auxiliary>
                    <p className="authorization-form-title">{translationService.translate('TR_SIGN_UP')}</p>
                    <form onSubmit={this.onSubmit}>
                      <AuthInput
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        isInvalidField={isInvalidFirstName}
                        isInvalidSubmit={isInvalidSubmit}
                        placeholder={`${translationService.translate('TR_FIRSTNAME')} *`}
                        onChange={(event) => this.onChange(event, nameMaxLength)}
                        onBlur={() => this.checkFieldValidation(firstName, "firstName")}
                      />
                      {
                        isInvalidFirstName ?
                          <small className="red-color">{translationService.translate("TR_INVALID_FIRST_NAME")}</small>
                          : null
                      }
                      <AuthInput
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        isInvalidField={isInvalidLastName}
                        isInvalidSubmit={isInvalidSubmit}
                        placeholder={`${translationService.translate('TR_LASTNAME')} *`}
                        onChange={(event) => this.onChange(event, nameMaxLength)}
                        onBlur={() => this.checkFieldValidation(lastName, "lastName")}
                      />
                      {
                        isInvalidLastName ?
                          <small className="red-color">{translationService.translate("TR_INVALID_LAST_NAME")}</small>
                          : null
                      }
                      <AuthInput
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        isInvalidField={isInvalidEmail}
                        isInvalidSubmit={isInvalidSubmit}
                        placeholder={`${translationService.translate('TR_EMAIL')} *`}
                        onChange={(event) => this.onChange(event, emailMaxLength)}
                        onBlur={() => this.checkFieldValidation(email, "email")}
                      />
                      <div className="d-flex flex-column">
                        {
                          isInvalidEmail ?
                            <small className="red-color">{translationService.translate("TR_EMAIL_IS_INVALID")}</small>
                            : null
                        }
                        {
                          registrationErrorMessage && !registrationErrorMessage.includes("TR_SYSTEM_EXCEPTION_EXC") ?
                            <small className="red-color">{registrationErrorMessage}</small>
                            : null
                        }
                      </div>
                      <AuthInput
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        isInvalidField={isInvalidPassword || mismatchPasswords}
                        isInvalidSubmit={isInvalidSubmit}
                        inputClassName={`${(isInvalidSubmit && (!password || password !== confirmPassword)) ? "error-border" : null}`}
                        placeholder={`${translationService.translate('TR_PASSWORD')} *`}
                        onChange={(event) => this.onChange(event, passwordMaxLength)}
                        onBlur={() => this.checkFieldValidation(password, "password")}
                      />
                      {
                        isInvalidPassword ?
                          <small className="red-color">{translationService.translate("TR_PASSWORD_INFO")}</small>
                          : null
                      }
                      <AuthInput
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        isInvalidField={isInvalidconfirmPassword || mismatchPasswords}
                        isInvalidSubmit={isInvalidSubmit}
                        inputClassName={`${(isInvalidSubmit && (!confirmPassword || password !== confirmPassword)) ? "error-border" : null}`}
                        placeholder={`${translationService.translate('TR_CONFIRM_PASSWORD')} *`}
                        onChange={(event) => this.onChange(event, passwordMaxLength)}
                        onBlur={() => this.checkFieldValidation(confirmPassword, "confirmPassword")}
                      />
                      {
                        isInvalidconfirmPassword ?
                          <small className="red-color">{translationService.translate("TR_PASSWORD_INFO")}</small>
                          : null
                      }
                      {
                        mismatchPasswords ?
                          <div><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                          : null
                      }
                      <div className="fail-block">
                        {
                          isInvalidSubmit && errorMessages
                            ? errorMessages.map((error, index) => {
                              return <small key={index} className="fail mb-2 d-block">{error}</small>
                            }) : null
                        }
                        {
                          errorMessage
                            ? <small className="fail mb-2 d-block">{errorMessage}</small>
                            : null
                        }
                      </div>
                      {
                        currencies && currencies.length ?
                          <div className='my-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <label className='text-muted'>{translationService.translate("TR_CURRENCY")}</label>
                              <ReactSelectOption
                                value={currencyId}
                                className="currency-select"
                                isInvalidField={isInvalidSubmit && !currencyId}
                                selectedValue={(() => {
                                  const selectedItem = { ...currencies.find(data => data.id === currencyId) };
                                  if (selectedItem) {
                                    selectedItem.value = selectedItem.id;
                                    selectedItem.label = selectedItem.isocode;
                                  }
                                  return selectedItem;
                                })()}
                                items={currencies.map(data => ({ ...data, label: data.isocode, value: data.id }))}
                                onChange={item => this.changeCurrency(item)}
                              />
                            </div>
                          </div>
                          : null
                      }
                      <div className="mb-3">
                        <small>
                          {translationService.translate("TR_SIGN_UP_ACCEPT_CONDITIONS")}
                          <Link to={`/${language}/${TERMS_KEY}`} target="_blank">{translationService.translate("TR_TERMS")}</Link>,&nbsp;
                          <Link to={`/${language}/${DATA_POLICY_KEY}`} target="_blank">{translationService.translate("TR_DATA_POLICY")}</Link>
                          {` ${translationService.translate("TR_AND")}`}
                          &nbsp; <Link to={`/${language}/${COOKIES_POLICY_KEY}`} target="_blank">{translationService.translate("TR_COOKIES_POLICY")}</Link>
                        </small>
                      </div>
                      <div className="mb-3">
                        <ActionButton
                          type="submit"
                          spinnerId={spinnerId}
                          className="mindalay--btn-dark w-100 position-relative"
                          name={translationService.translate('TR_SIGN_UP')}
                        />
                      </div>
                    </form>
                  </Auxiliary>
                  :
                  <Auxiliary>
                    <i className="far fa-check-circle registration-success" />
                    <p>{`${translationService.translate("TR_AFTER_REGISTRATION_INFO")}`}</p>
                  </Auxiliary>
                }
              </div>
            </div>
          </div>
        </div>
      </section> : null
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.language.language,
    translations: state.translation.translations,
    errorMessage: state.user.errorMessage,
    userRegistered: state.user.userRegistered,
    registrationErrorMessage: state.user.registrationErrorMessage
  };
};
const mapDispatchToProps = {
  userRegistration,
  addModalSpinner,
  removeModalSpinner,
  addButtonSpinner,
  removeButtonSpinner
};
export default connect(mapStateToProps, mapDispatchToProps)(Registration);

