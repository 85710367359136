import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter, } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import { Accordion, Card } from 'react-bootstrap';

const HelpLeftMenu = (props) => {

  const { hideMenu, topicId } = props;
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const { pageContent } = useSelector(state => state.help);
  const [activeAccordionKey, setActiveAccordionKey] = useState('0');

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (pageContent &&
      pageContent.children &&
      pageContent.children.length && !topicId) {
      let firstChild = pageContent.children[0];
      if (firstChild.children && firstChild.children.length) {
        props.history.push(`/${language}/help/topic/${firstChild.children[0].id}`)
      }
    }
  }, [pageContent]);

  return translationService ?
    // Student, Instructor, Consultant, Video conference, For organizations 
    <Accordion defaultActiveKey={activeAccordionKey} onSelect={(key) => setActiveAccordionKey(key)}>
      {pageContent &&
        pageContent.children.length > 1 ?
        pageContent.children.map((child, mainIndex) => {
          let activeAccordion = child.children && child.children.length && child.children.find(childItem => childItem.id === +topicId)
          return (
            <Card className='border-none' key={mainIndex}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={mainIndex.toString()}
                className={`
                    border-none
                    bg-white
                    p-2 d-flex
                    justify-content-between
                    align-items-center 
                    text-inherit
                    word-break-break-word 
                    max-line-2 
                    cursor-pointer
                    header-left-menu-item
                    ${activeAccordion ? 'active-header-left-menu-item' : ''}
                  `}
                title={
                  translationService.translate(child.pageTitle)
                    ? translationService.translate(child.pageTitle)
                    : child.pageTitle
                }
              >
                {translationService.translate(child.pageTitle) || child.pageTitle}
                <i className='fas fa-angle-down'></i>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={mainIndex.toString()}>
                <Card.Body className='pt-1 pl-3'>
                  {child.children && child.children.length ?
                    child.children.map((childItem, childIndex) => {
                      return (
                        <Accordion key={`child-${childIndex}`} defaultActiveKey='0'>
                          <Card className='border-none'>
                            <Accordion.Toggle
                              as={Card.Header}
                              variant='link'
                              eventKey={`child-${childIndex}`}
                              className={`
                                    border-none 
                                    bg-white 
                                    p-2 
                                    d-flex 
                                    justify-content-between
                                    align-items-center
                                    text-inherit 
                                    word-break-break-word
                                    max-line-2
                                    cursor-pointer 
                                    header-left-menu-item
                                    ${topicId && +topicId === childItem.id ? 'active-header-left-menu-item' : ''}
                                `}
                              title={
                                translationService.translate(childItem.pageTitle)
                                  ? translationService.translate(childItem.pageTitle)
                                  : childItem.pageTitle
                              }
                              onClick={() => {
                                props.history.push(`/${language}/help/topic/${childItem.id}`)
                                hideMenu && hideMenu()
                              }}
                            >
                              {
                                translationService.translate(childItem.pageTitle)
                                  ? translationService.translate(childItem.pageTitle)
                                  : childItem.pageTitle
                              }
                            </Accordion.Toggle>
                          </Card>
                        </Accordion>
                      );
                    })
                    : null}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        }) : pageContent.children[0].children && pageContent.children[0].children.length ?
          pageContent.children[0].children.map((childItem, childIndex) => {
            return (
              <Accordion key={`child-${childIndex}`} defaultActiveKey='0'>
                <Card className='border-none'>
                  <Accordion.Toggle
                    as={Card.Header}
                    variant='link'
                    eventKey={`child-${childIndex}`}
                    className={`
                          border-none 
                          bg-white 
                          p-2 
                          d-flex 
                          justify-content-between
                          align-items-center
                          text-inherit 
                          word-break-break-word
                          max-line-2
                          cursor-pointer 
                          header-left-menu-item
                          ${topicId && +topicId === childItem.id ? 'active-header-left-menu-item' : ''}
                      `}
                    title={
                      translationService.translate(childItem.pageTitle)
                        ? translationService.translate(childItem.pageTitle)
                        : childItem.pageTitle
                    }
                    onClick={() => {
                      props.history.push(`/${language}/help/topic/${childItem.id}`)
                      hideMenu && hideMenu()
                    }}
                  >
                    {
                      translationService.translate(childItem.pageTitle)
                        ? translationService.translate(childItem.pageTitle)
                        : childItem.pageTitle
                    }
                  </Accordion.Toggle>
                </Card>
              </Accordion>
            );
          })
          : null
      }
    </Accordion>
    : null
}

export default withRouter(HelpLeftMenu);
