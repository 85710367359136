import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from './../../../../Store/Actions/generalLeftMenu';
import { MINDALAY_ADMIN, CATEGORIES_KEY, CATEGORY_KEY, THEME_KEY } from "../../../../Constants/urlKeys";

const CategoriesSubMenu = (props) => {
  
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${CATEGORIES_KEY}/${CATEGORY_KEY}`}
            className={props.location.pathname.includes(`/${CATEGORY_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_CATEGORIES")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${CATEGORIES_KEY}/${THEME_KEY}`}
            className={props.location.pathname.includes(`/${THEME_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_THEMES")}
          </NavLink>
        </li>
      </ul>
    </div>
  </aside> : null;
}

export default withRouter(CategoriesSubMenu);