import React from 'react';

const CourseSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    ><g><g xmlns="http://www.w3.org/2000/svg"><path d="m146.386 180.5 109.614 43.843 109.614-43.843-109.614-43.843z" fill="#b53471" data-original="#000000" /><path d="m181 226.655v34.616l75 30 75-30v-34.619l-75 30.005z" fill="#b53471" data-original="#000000" /><path d="m0 15.5v390h512v-390zm421 300h-30v-112.852l-30 12.002v66.923l-105 42.012-105-42.012v-66.918l-85.386-34.155 190.386-76.157 165 66.006z" fill="#b53471" data-original="#000000" /><path d="m316.813 436.5h-121.626l-10.001 30h-64.186v30h270v-30h-64.186z" fill="#b53471" data-original="#000000" /></g></g></svg>
  );
}

export default CourseSvg;
