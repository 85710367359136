import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const DataPolicy = () => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);

	const [translationService, setTranslationService] = useState(null);
	const [pageContent, setPageContent] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPageContent();
	}, []);

	const getPageContent = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getPagecontent(10)
			.then(response => {
				dispatch(removePageSpinner(spinnerId));
				response.data && setPageContent({ ...response.data });
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			{pageContent && pageContent.fullContent ? (
				<Auxiliary>
					<div className="row mt-4">
						<div className="col-12">
							<h2 className="section-title">{pageContent.name}</h2>
							<hr />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<ParserComponent text={pageContent.fullContent} />
						</div>
					</div>
				</Auxiliary>
			) : (
				<div className="mt-4">
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default DataPolicy;
