import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	CURATOR_USER_TYPE,
	DEVELOPER_USER_TYPE,
	ERROR_KEY,
	LECTURER_USER_TYPE,
	STUDENT_USER_TYPE,
	TR_NO,
	TR_YES,
	WEBINAR_DATA_KEY,
} from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import { withRouter } from "react-router";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { WEBINAR_KEY, WEBINAR_CALENDAR_KEY } from "../../Constants/urlKeys";
import ReactPaginate from "react-paginate";
import HelpService from "../../Services/helpService";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import _InfoSvg from "../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import MainService from "../../Services/mainService";
import moment from "moment";
import ActionButton from "../../Components/ActionButton/actionButton";
import { WEBINAR_SCREEN_URL_KEY } from "../../Constants/apiKeys";

const buttonSpinnerId = uuid();
const Webinars = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const currentQuestionMarkData = useSelector(
		state => state.main.currentQuestionMarkData,
	);
	const { user } = useSelector(state => state.user);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);
	const [webinars, setWebinars] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getOnGoingWebinars(1);
	}, []);

	const getOnGoingWebinars = currentPage => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOnGoingWebinars(currentPage, pageSize)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				response.data && setWebinars(response.data);
				dispatch(removePartialViewSpinner(spinnerId));
				window.scrollTo({ top: 0, behavior: "smooth" });
			})
			.catch(error => getFail(error, spinnerId));
	};

	const joinToWebinar = currentWebinarData => {
		if (!currentWebinarData) {
			return false;
		}
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			localStorage.setItem(
				WEBINAR_DATA_KEY,
				JSON.stringify(currentWebinarData),
			);
			setTimeout(() => {
				goToWebinar();
			}, 1000);
		});
	};

	const goToWebinar = () => {
		window.open(WEBINAR_SCREEN_URL_KEY, "_blank");
	};

	const handlePageClick = event => {
		getOnGoingWebinars(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_ONGOING_WEBINARS_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle="Ongoing webinars"
						pageName="ongoing_webinars"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{webinars && webinars.length ? (
				<div className="row">
					{webinars.map((item, index) => (
						<div key={index} className="col-12 my-2">
							<div className="card mindalay--card">
								<div
									className={`card-header d-sm-flex   justify-content-${
										item.inProcess || item.isFinished ? "between" : "end"
									} align-items-center`}>
									{item.inProcess && (
										<p className="mindalay--some-process-status text-success">
											{translationService.translate("TR_IN_PROCESS")}
										</p>
									)}
									{item.isFinished && (
										<p className="mindalay--some-process-status text-danger">
											{translationService.translate("TR_FINISHED")}
										</p>
									)}
									{item.inProcess ? (
										<button
											type="button"
											className="mindalay--btn-small mindalay--btn-dark ml-sm-1 my-sm-0 my-1"
											onClick={() => {
												joinToWebinar({
													webinarCalendarId: item.webinarCalendarId,
													webinarId: item.webinarId,
													webinarType: 0,
													lecturerUserId: item.lecturerUserId,
												});
											}}>
											{translationService.translate("TR_JOIN_THE_WEBINAR")}
										</button>
									) : null}
									{user.userTypeId !== STUDENT_USER_TYPE && !item.inProcess ? (
										<button
											type="button"
											className="mindalay--btn-small mindalay--btn-dark ml-sm-1 my-sm-0 my-1"
											onClick={() => {
												joinToWebinar({
													webinarCalendarId: item.webinarCalendarId,
													webinarId: item.webinarId,
													webinarType: 0,
													lecturerUserId: item.lecturerUserId,
												});
											}}>
											{translationService.translate("TR_START_THE_WEBINAR")}
										</button>
									) : null}
								</div>
								<div className="card-body">
									<h5 className="card-title">{item.name}</h5>
									<p className="webinar-creator-name d-block my-1">
										<span>{`${translationService.translate(
											"TR_LECTURER",
										)}: `}</span>
										<b>{item.lecturerName}</b>
									</p>
									<hr />
									<div className="mindalay--card-span-group m-0">
										<div className="webinar-start-time">
											<fieldset>
												<legend className="d-block">
													{translationService.translate("TR_START_TIME")}
												</legend>
												<span>
													{moment(
														MainService.convertUTCDateToLocalDate(
															new Date(item.startTime),
														),
													).format("YYYY-MM-DD HH:mm")}
												</span>
											</fieldset>
										</div>
										<div className="webinar-end-time">
											<fieldset>
												<legend className="d-block">
													{translationService.translate("TR_END_TIME")}
												</legend>
												<span>
													{moment(
														MainService.convertUTCDateToLocalDate(
															new Date(item.endTime),
														),
													).format("YYYY-MM-DD HH:mm")}
												</span>
											</fieldset>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}

			{/* <div className='row mt-3'>
        <div className='col-12'>
          {
            pagination && webinars && webinars.length ?
              <div className='d-flex justify-content-end'>
                <ReactPaginate
                  nextLabel={translationService.translate("TR_NEXT")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pagination.TotalPages}
                  previousLabel={translationService.translate("TR_PREVIOUS")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={activePageNumber}
                />
              </div>
              : null
          }
        </div>
      </div> */}
		</div>
	) : null;
};

export default withRouter(Webinars);
