import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { CONSULTATION_ENTITY_TYPE, COURSE_ENTITY_TYPE, ENTITY_TYPES, ERROR_KEY } from "../../Constants/mainKeys";
import Search from "../../Components/Search/search";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import { withRouter } from "react-router-dom";
import ReurnUrl from "../../Services/returnUrl";
import { LOGIN_KEY } from './../../Constants/urlKeys';
import Ticket from "./Components/ticket";
import NoDataImage from '../../assets/images/illustrations/nodata.svg';

const Tickets = (props) => {
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const user = useSelector(state => state.user.user);
  const language = useSelector(state => state.language.language);

  const [translationService, setTranslationService] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [resolvedTickets, setResolvedTickets] = useState([]);
  const [inProcessTickets, setInProcessTickets] = useState([]);

  const [defaultTickets, setDefaultTickets] = useState([]);
  const [defaultAllTickets, setDefaultAllTickets] = useState([]);
  const [defaultCourseTickets, setDefaultCourseTickets] = useState([]);
  const [defaultConsultationTickets, setDefaultConsultationTickets] = useState([]);

  const [entityType, setEntityType] = useState(-1);

  const [showInProcessTicketsCount, setShowInProcessTicketsCount] = useState(4);
  const [showResolvedTicketsCount, setShowResolvedTicketsCount] = useState(4);


  const [searchData, setSearchData] = useState('');

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (user) {
      const spinnerId = uuid();
      dispatch(addPageSpinner(spinnerId));
      ApiService.getTicketsData().then(response => {
        if (response.data) {
          const data = [...response.data]
          data.forEach(element => {
            element.showMoreDesc = false
          });
          var tickets = [];
          if (entityType > -1) {
            tickets = data.filter(ticket => ticket.entityType === entityType);
          } else {
            tickets = data;
          }

          const resolvedTickets = [];
          const inProcessTickets = [];

          const defaultCourseTickets = [];
          const defaultConsultationTickets = [];
          const defaultAllTickets = [];

          data.forEach(data => {
            defaultAllTickets.push({ ...data });
            if (data.entityType === COURSE_ENTITY_TYPE) defaultCourseTickets.push(data);
            if (data.entityType === CONSULTATION_ENTITY_TYPE) defaultConsultationTickets.push(data);
          })
          setDefaultCourseTickets(defaultCourseTickets);
          setDefaultConsultationTickets(defaultConsultationTickets);
          setDefaultAllTickets(defaultAllTickets);

          setTickets(tickets);

          tickets && tickets.forEach(ticket => {
            if (ticket.processed) { resolvedTickets.push(ticket) }
            if (!ticket.processed) { inProcessTickets.push(ticket) }
          });
          setResolvedTickets(resolvedTickets);
          setInProcessTickets(inProcessTickets);

          setDefaultTickets(data);
        }
        dispatch(removePageSpinner(spinnerId));
      }).catch(error => getFail(error, spinnerId));
    } else {
      ReurnUrl.setUrl(props.location.pathname).then(() => {
        props.history.push(`/${language}/${LOGIN_KEY}`)
      })
    }
  }, []);

  const onChangeShowMoreDesc = (id) => {
    if (!id) { return; }
    const allTickets = [...tickets];
    allTickets.forEach(ticket => {
      if (ticket.id === id) {
        ticket.showMoreDesc = !ticket.showMoreDesc;
      }
      setTickets(allTickets);
    })
  }

  const filterConsultations = (filteredArray, defaultArray) => {

    if (filteredArray !== null) {
      const resolvedTickets = [];
      const inProcessTickets = [];
      filteredArray && filteredArray.forEach(ticket => {
        if (ticket.processed) { resolvedTickets.push(ticket) }
        if (!ticket.processed) { inProcessTickets.push(ticket) }
      });
      setResolvedTickets(resolvedTickets);
      setInProcessTickets(inProcessTickets);
    } else {
      const resolvedTickets = [];
      const inProcessTickets = [];
      defaultArray && defaultArray.forEach(ticket => {
        if (ticket.processed) { resolvedTickets.push(ticket) }
        if (!ticket.processed) { inProcessTickets.push(ticket) }
      });
      setResolvedTickets(resolvedTickets);
      setInProcessTickets(inProcessTickets);
    }
  }

  const filterByEntityType = (selectedCategory) => {
    if (!selectedCategory) { return; }
    setEntityType(selectedCategory.value);
    var allTickets = [];
    if (selectedCategory.value > -1) {
      allTickets = [...defaultTickets].filter(ticket => ticket.entityType === selectedCategory.value);
    } else {
      allTickets = [...defaultTickets]
    }

    setTickets(allTickets)

    if (selectedCategory.entityType === COURSE_ENTITY_TYPE) setDefaultCourseTickets(allTickets)

    if (selectedCategory.entityType === CONSULTATION_ENTITY_TYPE) setDefaultConsultationTickets(allTickets)

    const resolvedTickets = [];
    const inProcessTickets = [];

    allTickets && allTickets.forEach(ticket => {
      if (ticket.processed) { resolvedTickets.push(ticket) }
      if (!ticket.processed) { inProcessTickets.push(ticket) }
    });
    setResolvedTickets(resolvedTickets);
    setInProcessTickets(inProcessTickets);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
  }

  return translationService ? <section className="my-4"><div className="container">
    <div className="row">
      <div className="col-12">
        <h2 className="section-title">{translationService.translate("TR_TICKETS")}</h2>
        <hr />
      </div>
      {
        defaultTickets && defaultTickets.length ?
          <div className="col-12">
            <form>
              <div className="row">
                <div className="col-md-6 col-12">
                  <label>{translationService.translate("TR_SEARCH_BY_TICKET_NUMBER")}</label>
                  {
                    (defaultCourseTickets && defaultCourseTickets.length) || (defaultConsultationTickets && defaultConsultationTickets.length) ?
                      <Search
                        searchData={entityType === 0 ? defaultCourseTickets : entityType === CONSULTATION_ENTITY_TYPE ? defaultConsultationTickets : entityType === -1 ? defaultAllTickets : []}
                        searchFieldName="ticketNumber"
                        emitSearchedData={data => filterConsultations(data, defaultTickets)}
                        onChange={(data) => setSearchData(data)}
                        isDisabled={
                          !(
                            (entityType === COURSE_ENTITY_TYPE && defaultCourseTickets && defaultCourseTickets.length)
                            || (entityType === CONSULTATION_ENTITY_TYPE && defaultConsultationTickets && defaultConsultationTickets.length)
                            || (entityType === -1 && defaultAllTickets && defaultAllTickets.length)
                          )
                        }
                      />
                      : null
                  }
                </div>
                <div className="col-md-6 col-12">
                  <label>{translationService.translate("TR_FILTER_BY_ENTITY_TYPE")}</label>
                  <ReactSelectOption
                    value={entityType}
                    selectedValue={(() => {
                      const selectedValue = { ...ENTITY_TYPES.find(data => data.value === entityType) };
                      if (selectedValue) {
                        selectedValue.label = translationService.translate(selectedValue.label);
                        selectedValue.value = selectedValue.value;
                      }
                      return selectedValue;
                    })()}
                    items={ENTITY_TYPES.map(data => ({ label: translationService.translate(data.label), value: data.value }))}
                    onChange={(item) => filterByEntityType(item)}
                  />
                </div>
                <div className="col-12"><div className="mindalay--btn-link-wrapper--instructor mt-2">
                  <p className="text-right">
                    {`${translationService.translate("TR_TICKETS")}:`}<strong>{inProcessTickets.length + resolvedTickets.length}</strong>
                  </p>
                </div></div>
              </div>
            </form>
          </div> : null
      }
      {
        resolvedTickets && resolvedTickets.length ?
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="content-sub-title p-0"><h3>{translationService.translate("TR_RESOLVED")}</h3></div>
              </div>
              <div className="col-12">
                <div className="row">
                  <Ticket
                    tickets={resolvedTickets}
                    showRefundedField={true}
                    ticketsCount={showResolvedTicketsCount}
                    onChangeShowMoreDesc={onChangeShowMoreDesc}
                  />
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    {
                      resolvedTickets && (resolvedTickets.length > showResolvedTicketsCount)
                        ? <button type="button" className="mindalay--btn-default w-100 mb-4" onClick={() => setShowResolvedTicketsCount(showResolvedTicketsCount + 4)}>
                          {translationService.translate("TR_SHOW_MORE")}
                        </button> : null
                    }
                  </div>
                </div>
              </div>
              {inProcessTickets && inProcessTickets.length ? <div className="col-12 mt-3"><hr /></div> : null}
            </div>
          </div> : null
      }
      {
        inProcessTickets && inProcessTickets.length ?
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="content-sub-title p-0"><h3>{translationService.translate("TR_IN_PROCESS")}</h3></div>
              </div>
              <div className="col-12">
                <div className="row">
                  <Ticket
                    tickets={inProcessTickets}
                    ticketsCount={showInProcessTicketsCount}
                    onChangeShowMoreDesc={onChangeShowMoreDesc}
                  />
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    {
                      inProcessTickets && (inProcessTickets.length > showInProcessTicketsCount)
                        ? <button type="button" className="mindalay--btn-default w-100 mb-4" onClick={() => setShowInProcessTicketsCount(showInProcessTicketsCount + 4)}>
                          {translationService.translate("TR_SHOW_MORE")}
                        </button> : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div> : null
      }
      {
        (!inProcessTickets || !inProcessTickets.length) && (!resolvedTickets || !resolvedTickets.length) && !searchData
          ?
          <div className="no-data-container">
            <div className="no-data-wrapper">
              <p>{translationService.translate("TR_THERE_IS_NO_TICKETS")}</p>
              <img src={NoDataImage} alt="/"/>
            </div>
          </div>
          : null
      }
    </div>
  </div>
  </section>
    : null
}

export default withRouter(Tickets);
