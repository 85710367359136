import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import Notification from "./SubComponents/notification";
import ProfileDropdown from "./SubComponents/profileDropdown";
import OrganizationUserDropdown from "./SubComponents/organizationUserDropdown";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import Messages from "./SubComponents/messages";

const InstructorHeader = () => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = () => {
		dispatch(showOrHideGeneralDashboardLeftMenu(!showOrHideGeneralLeftMenu));
	};

	return translationService ? (
		<header className="relative-layout admin-dashboard-header header instructor-header-zindex white-background">
			<nav className="navbar navbar-expand-md navbar-light navbar-shadow d-flex justify-content-md-end justify-content-between instructor-consultant-navbar">
				{!showOrHideGeneralLeftMenu ? (
					<Link
						to={`/${language}`}
						className="navbar-brand instructor-consultant-header-logo d-none"></Link>
				) : null}
				<Link
					to="#"
					className="burger-btn open-burger-menu nav-link"
					onClick={showOrHideLeftMenu}>
					<span></span>
				</Link>
				<div className="">
					<ul className="navbar-nav align-items-left align-items-lg-center">
						<li
							title={translationService.translate("TR_NOTIFICATIONS")}
							className="nav-item not-hover position-relative dropdown header-dropdown">
							<Notification />
						</li>
						<li
							title={translationService.translate("TR_MESSAGES")}
							className="nav-item not-hover d-md-block d-none position-relative dropdown header-dropdown">
							<Messages />
						</li>
						<li className="nav-item navbar-profile-container header-dropdown">
							{user && user.isOrganizationUser ? (
								<OrganizationUserDropdown />
							) : (
								<ProfileDropdown />
							)}
						</li>
					</ul>
				</div>
			</nav>
		</header>
	) : null;
};

export default withRouter(InstructorHeader);
