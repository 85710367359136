import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { addPartialViewSpinner, removePartialViewSpinner } from '../../Store/Actions/spinner';
import AlertService from '../../Services/alertService';
import { BACK_URL_KEY, CURATOR_USER_TYPE, ERROR_KEY, LECTURER_USER_TYPE } from '../../Constants/mainKeys';
import uuid from 'react-uuid';
import ApiService from '../../Services/apiService';
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import DiaryDocumentView from '../../Components/Documents/DiaryDocumentView';
import ReportDocumentView from '../../Components/Documents/ReportDocumentView.js.js';
import ActionButton from '../../Components/ActionButton/actionButton';
import TranslationService from '../../Services/translationService';
import { DOCUMENT_KEY } from '../../Constants/urlKeys';
import MainService from '../../Services/mainService';

const buttonSpinnerId = uuid();
const DocumentView = (props) => {

  const dispatch = useDispatch();
  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const [documentData, setDocumentData] = useState(null);
  const language = useSelector(state => state.language.language)
  const user = useSelector(state => state.user.user)

  useEffect(() => {
    if (props.match.params.documentId) {
      getDocumentById(props.match.params.documentId);
    }
    return () => {
      localStorage.removeItem(BACK_URL_KEY);
    }
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getDocumentById = (documentId) => {
    const spinnerId = uuid();
    if (!documentId) { return; }
    dispatch(addPartialViewSpinner(spinnerId));
    (
      user.userTypeId === LECTURER_USER_TYPE ?
        ApiService.getDocumentByIdAsLecturer(documentId) :
        user.userTypeId === CURATOR_USER_TYPE ?
          ApiService.getDocumentByIdAsCurator(documentId) :
          ApiService.getDocumentByIdAsStudent(documentId)
    ).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        data && setDocumentData(data);
        dispatch(removePartialViewSpinner(spinnerId));
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
    props.history.push(`/${language}/${DOCUMENT_KEY}`);
  }

  const onPrintDocument = (documentData) => {
    if (documentData.signFilePath || documentData.filePath) {
      printImage(documentData)
    } else {
      window.print();
      // var newTab = window.open('', '', 'height=800, width=800');
      // var headstr = `<html><head><title> ${documentData.name ? documentData.name : "Mindalay"} </title></head><body>`;
      // var divContents = document.getElementById("document").innerHTML;
      // var footstr = "</body>";
      // newTab.document.body.innerHTML = headstr + divContents + footstr;
      // newTab.print();
      // newTab.close();
    }
  }

  const printImage = (documentData) => {
    var win = window.open('', '', 'height=800, width=800');
    win.document.open();
    win.document.write([
      '<html>',
      '   <head>',
      `<title> ${documentData.name ? documentData.name : "Mindalay"} </title>`,
      '   </head>',
      '   <body onload="window.print()" onafterprint="window.close()">',
      ` <img src="${documentData.signFilePath ? documentData.signFilePath : documentData.filePath}"/>`,
      '   </body>',
      '</html>'
    ].join(''));
    win.document.close();
  }

  const goBack = () => {
    if (localStorage.getItem(BACK_URL_KEY)) {
      props.history.push(localStorage.getItem(BACK_URL_KEY));
    } else {
      props.history.push(`/${language}`);
    }
    localStorage.removeItem(BACK_URL_KEY);
  }

  return documentData && translationService ?
    <div className='m-section'>
      <div className='container'>
        <div className='row content-title-block'>
          <div className="col-12">
            <h2 className="content-title p-0">
              <Link to="#" onClick={goBack} title={translationService.translate("TR_BACK")}><ArrowBackSvg /></Link>
              {
                documentData.name ? documentData.name : ""
              }
            </h2>
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            {
              documentData.documentCategoryId === 2 ?
                <Auxiliary>
                  <ReportDocumentView documentData={documentData} />
                </Auxiliary>
                : null
            }
            {
              documentData.documentCategoryId === 4 ?
                <Auxiliary>
                  <DiaryDocumentView documentData={documentData} />
                </Auxiliary>
                : null
            }
          </div>
        </div>
        <div className='row print-button-block'>
          <div className='col-12'>
            <div className='d-flex justify-content-end'>
              <ActionButton
                type="button"
                spinnerId={buttonSpinnerId}
                className="mindalay--btn-dark m-1"
                name={translationService.translate("TR_PRINT_OUT")}
                clicked={() => onPrintDocument(documentData)}
              />
              {
                documentData.signFilePath || documentData.filePath ?
                  <a className="mindalay--btn-dark mindalay--btn-link m-1" href={MainService.getDownloadUrl(documentData.signFilePath ? documentData.signFilePath : documentData.filePath)} target="blank">
                    {translationService.translate("TR_DOWNLOAD")}
                  </a>
                  : null
              }
            </div>
          </div>
        </div>

      </div>

    </div>
    : null

}

export default withRouter(DocumentView)
