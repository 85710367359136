import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from "react-uuid";
import * as moment from "moment";
import SubmitButton from '../../Components/SubmitButton/submitButton';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import TranslationService from '../../Services/translationService';
import { setCurrentCourse } from "../../Store/Actions/course";
import { addButtonSpinner, removeButtonSpinner, addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from '../../Constants/translationKeys';
import { CURRENCY_SYMBOL_KEY, DEFAULT_CURRENCY_SYMBOL_KEY, ERROR_KEY, NUMBER_KEY, SUCCESS_KEY } from '../../Constants/mainKeys';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { COURSE_DASHBOARD_KEY, CURICULUMN_KEY } from '../../Constants/urlKeys';
import InputRadio from './../../Components/Inputs/inputRadio';
import { BOOLEAN_KEY } from './../../Constants/mainKeys';
import Input from '../../Components/Inputs/input';
import MainService from '../../Services/mainService';

class CoursePricing extends Component {

  state = {
    form: {
      // currencyId: 1,
      price: 0,
      hasDiscount: false,
      allowMindalayToDiscount: false,
      discount: '',
      discountStartDate: '',
      discountEndDate: '',
    },
    changes: false,
    costsData: [],
    prices: [],
    currencyData: [],
    spinnerId: uuid(),
    courseData: this.props.courseData,
    translationService: null,
    failedFields: null,
    isInvalidSubmit: false,
    defaultValue: { label: "", value: "" },
    isInvalidStartDate: false,
    isInvalidEndDate: false,
    costDisplayName: "",
    currencySymbol: localStorage.getItem(CURRENCY_SYMBOL_KEY) ? localStorage.getItem(CURRENCY_SYMBOL_KEY) : DEFAULT_CURRENCY_SYMBOL_KEY,
  };

  componentDidMount() {
    this.setTranslations();
    this.setCourseData();
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  componentWillUnmount() {
    const { changes, translationService } = this.state
    if (changes && translationService && window.confirm(`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`)) {
      this.onSubmit();
    }
  }

  setCourseData = () => {
    const { courseData } = this.state;
    if (!courseData) { this.props.history.push(`/${this.props.language}/${COURSE_DASHBOARD_KEY}/${this.props.courseData.id}/${CURICULUMN_KEY}`); }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        currencyId: courseData.currencyId,
        price: courseData.costByMainCurrency,
        hasDiscount: courseData.discountStartDate && courseData.discountEndDate ? true : false,
        allowMindalayToDiscount: courseData.allowMindalayToDiscount,
        discount: courseData.discount ? courseData.discount : "",
        discountStartDate: courseData.discountStartDate ? moment(MainService.convertUTCDateToLocalDate(new Date(courseData.discountStartDate))).format("YYYY-MM-DDTHH:mm:ss") : "",
        discountEndDate: courseData.discountEndDate ? moment(MainService.convertUTCDateToLocalDate(new Date(courseData.discountEndDate))).format("YYYY-MM-DDTHH:mm:ss") : "",
      }
    }));
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  // getCurrency = () => {
  //   const { currencyId } = this.state.form;
  //   const spinnerId = uuid();
  //   this.props.addPageSpinner(spinnerId);
  //   ApiService.getCurrency().then(response => {
  //     response.data && this.setState({
  //       currencyData: response.data
  //     }, () => {
  //       this.getCoursePricesByCurrencyId(typeof currencyId === NUMBER_KEY ? currencyId : 1);
  //     })
  //     this.props.removePageSpinner(spinnerId);
  //   }).catch(error => this.getFail(error, spinnerId));
  // }

  // onCurrencyChange = (item) => {
  //   if (!item) { return; }
  //   this.setState(prevState => ({
  //     ...prevState,
  //     changes: true,
  //     decimalCount: item.decimalCount,
  //     prices: [],
  //     form: {
  //       ...prevState.form,
  //       currencyId: +item.id,
  //     }
  //   }), () => {
  //     this.getCoursePricesByCurrencyId(+item.id)
  //   });
  // }

  // getCoursePricesByCurrencyId = (currencyId) => {
  //   const spinnerId = uuid();
  //   this.props.addPageSpinner(spinnerId);
  //   ApiService.getPricesByCurrencyId(currencyId).then(response => {
  //     if (response.data) {
  //       this.setState({
  //         prices: this.sortPriceArray(response.data)
  //       })
  //     }
  //     this.props.removePageSpinner(spinnerId);
  //   }).catch(error => this.getFail(error, spinnerId))
  // }

  onPriceChange = (item) => {
    if (!item) { return; }
    this.setState(prevState => ({
      ...prevState,
      changes: true,
      form: {
        ...prevState.form,
        price: +item.price
      }
    }));
  }

  onNumberChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e") || event.target.value.includes(".") || +event.target.value < 0) { return false };
    if (event.target.name === "discount" && typeof +event.target.value === NUMBER_KEY && event.target.value <= 99) {
      this.setState(prevState => ({ ...prevState, failedFields, changes: true, form: { ...prevState.form, [event.target.name]: +event.target.value } }));
    } else if (event.target.name === "price" && typeof +event.target.value === NUMBER_KEY && event.target.value <= maxLength) {
      this.setState(
        prevState =>
        (
          {
            ...prevState, failedFields,
            changes: true,
            isInvalidSubmit: false,
            isInvalidStartDate: false,
            isInvalidEndDate: false,
            form: {
              ...prevState.form,
              [event.target.name]: +event.target.value
            }
          }
        )
      );
      if (+event.target.value === 0) {
        this.setState(
          prevState =>
          (
            {
              ...prevState, failedFields,
              form: {
                ...prevState.form,
                hasDiscount: false
              }
            }
          )
        );
      }
    }
  };

  onHasDiscountChange = event => {
    if (typeof event.target.checked === BOOLEAN_KEY) {
      const failedFields = this.removeFailedFields(event.target.id);
      switch (event.target.id) {
        case 'noDiscount':
          this.setState(prevState => ({
            ...prevState,
            failedFields,
            isInvalidStartDate: null,
            isInvalidEndDate: null,
            isInvalidSubmit: false,
            changes: true,
            form: {
              ...prevState.form,
              hasDiscount: false,
              allowMindalayToDiscount: false,
              discountStartDate: '',
              discountEndDate: '',
            }
          }));
          break;
        case 'hasDiscount':
          this.setState(prevState => ({
            ...prevState,
            failedFields,
            changes: true,
            form: {
              ...prevState.form,
              [event.target.id]: event.target.checked,
              allowMindalayToDiscount: false
            }
          }));
          break;
        case 'allowMindalayToDiscount':
          this.setState(prevState => ({
            ...prevState,
            failedFields,
            isInvalidStartDate: null,
            isInvalidEndDate: null,
            isInvalidSubmit: false,
            changes: true,
            form: {
              ...prevState.form,
              [event.target.id]: event.target.checked,
              hasDiscount: false,
              discountStartDate: '',
              discountEndDate: '',
            }
          }));
          break;
        default:
          break
      }
    }
  }

  checkDateFormatAndIsValid = (date, name, error) => {
    if (!date || !name) { return; }
    const { discountStartDate, discountEndDate } = this.state.form;
    const { isInvalidStartDate, isInvalidEndDate } = this.state;
    const isValidDateFormat = MainService.isValidDateTime(date);
    const isValidDate = MainService.validateDate(date);
    if (!isValidDateFormat || !isValidDate) {
      this.setState({ [error]: true });
    } else this.setState({ [error]: false });

    if (discountStartDate && discountEndDate && !isInvalidStartDate && !isInvalidEndDate) {
      const dateCompaire = MainService.checkDates(discountStartDate, discountEndDate);
      if (dateCompaire && isValidDate && isValidDateFormat) {
        this.setState({ [error]: false });
      } else {
        this.setState({ [error]: true });
      }
    }
  }

  onDateChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      changes: true,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value
      }
    }));
  };

  onSubmit = (event) => {
    event && event.preventDefault();
    const { spinnerId, translationService, courseData, isInvalidStartDate, isInvalidEndDate, changes } = this.state;
    const form = { ...this.state.form };
    this.setState({ changes: false });
    if (!form.price || ((form.price || typeof form.price === NUMBER_KEY) && !isInvalidStartDate && !isInvalidEndDate && changes)) {
      form.id = courseData.id;
      form.discountEndDate = form.discountEndDate ? moment.utc(new Date(form.discountEndDate)).format("YYYY-MM-DDTHH:mm:ss") : "";
      form.discountStartDate = form.discountStartDate ? moment.utc(new Date(form.discountStartDate)).format("YYYY-MM-DDTHH:mm:ss") : "";

      if (!form.hasDiscount) {
        form.discount = '';
        form.discountStartDate = '';
        form.discountEndDate = '';
      } else if (!form.discount || !form.discountStartDate || !form.discountEndDate) {
        this.setState({ isInvalidSubmit: true });
        return;
      }
      const formData = new FormData();
      for (let i in form) {
        !form[i] && typeof form[i] !== NUMBER_KEY ? delete form[i] : formData.append(`${i}`, form[i]);
      }
      delete form.hasDiscount;
      this.props.addButtonSpinner(spinnerId);
      ApiService.courseCostUpdate(formData).then(response => {
        AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
        this.props.removeButtonSpinner(spinnerId);
        this.props.setCurrentCourse(response.data);
        this.props.history.push(`/${this.props.language}/${COURSE_DASHBOARD_KEY}/${this.props.courseData.id}/${CURICULUMN_KEY}`);
      }).catch(errors => this.submitFail(spinnerId, errors));
    } else {
      this.setState({ isInvalidSubmit: true });
    }
  };

  submitFail = (spinnerId, errors) => {
    this.props.removeButtonSpinner(spinnerId);
    const failedFields = errors;
    this.setState({ failedFields });
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  sortPriceArray = (array) => {
    if (!array || !array.length) {
      return;
    } else {
      return array.sort((a, b) => +a.price > +b.price ? 1 : -1);
    }
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  render() {
    const {
      spinnerId,
      translationService,
      failedFields,
      courseData,
      isInvalidSubmit,
      isInvalidStartDate,
      isInvalidEndDate,
      currencySymbol,
    } = this.state;

    const {
      hasDiscount,
      allowMindalayToDiscount,
      discount,
      discountStartDate,
      discountEndDate,
      price
    } = this.state.form;

    return translationService ? <Auxiliary>
      <div className="content-title d-block">
        <h2 className="section-title">{translationService.translate("TR_COURSE_PRICE")}</h2>
      </div>
      <hr />
      <div className="content-body pt-0">
        <p className="mb-3 mt-3">{translationService.translate("TR_COURSE_PRICE_INFO")}</p>
        <form onSubmit={this.onSubmit} >
          {/* <div className="row">
            <div className="col-md-6 col-12">
              <label>{`${translationService.translate("TR_CURRENCY")} *`}</label>
              <ReactSelectOption
                defaultValue={defaultValue}
                value={currencyId ? true : false}
                selectedValue={(() => {
                  const selectedValue = { ...currencyData.find(data => data.id === currencyId) };
                  if (selectedValue) {
                    selectedValue.label = selectedValue.isocode;
                    selectedValue.value = selectedValue.id;
                  }
                  return selectedValue;
                })()}
                isInvalidSubmit={isInvalidSubmit}
                items={[...currencyData].map(data => ({ ...data, value: data.id, label: data.isocode }))}
                onChange={item => this.onCurrencyChange(item)}
              />
              <ReactSelectOption
                defaultValue={{
                  label: localStorage.getItem(CURRENCY_KEY) ? localStorage.getItem(CURRENCY_KEY) : DEFAULT_CURRENCY_KEY,
                }}
                isDisabled={true}
              />
            </div>
            {
              prices && prices.length ?
                <div className="col-md-6 col-12">
                  <label>{`${translationService.translate("TR_COST")} *`}</label>
                  <ReactSelectOption
                    defaultValue={defaultValue}
                    value={price || typeof price === NUMBER_KEY ? true : false}
                    selectedValue={(() => {
                      const selectedValue = { ...prices.find(data => data.price === price) };
                      if (selectedValue) {
                        selectedValue.label = selectedValue.priceAsString;
                        selectedValue.value = selectedValue.id;
                      }
                      return selectedValue;
                    })()}
                    isInvalidSubmit={isInvalidSubmit}
                    items={[...prices].map(data => ({ ...data, value: data.id, label: data.priceAsString }))}
                    onChange={item => this.onPriceChange(item)}
                  />
                </div>
                : null
            }
          </div> */}
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-8">
              <div className='icon-input'>
                <Input
                  type="number"
                  id="price"
                  name="price"
                  value={price}
                  isInvalidSubmit={isInvalidSubmit}
                  labelValue={`${translationService.translate("TR_COST")} *`}
                  onChange={event => this.onNumberChange(event, 1000000)}
                />
                {
                  currencySymbol ? <span>{currencySymbol}</span> : null
                }
              </div>
            </div>
          </div>
          {
            price ?
              <Auxiliary>
                <div className="row"><div className="col-12"><hr /></div></div>
                <div className="row">
                  <div className="col-12">
                    <label>{translationService.translate("TR_CHOOSE_DISCOUNT")}</label>
                    <InputRadio
                      id="hasDiscount"
                      name="discount"
                      infoText={translationService.translate("TR_MY_OWN_DISCOUNT_INFO")}
                      checked={hasDiscount}
                      labelValue={translationService.translate("TR_MY_OWN_DISCOUNT")}
                      onChange={this.onHasDiscountChange}
                      failedFields={failedFields}
                    />
                    <InputRadio
                      id="allowMindalayToDiscount"
                      name="discount"
                      infoText={translationService.translate("TR_ALLOW_MINDALAY_DISCOUNT_INFO")}
                      checked={allowMindalayToDiscount}
                      labelValue={translationService.translate("TR_ALLOW_MINDALAY")}
                      onChange={this.onHasDiscountChange}
                      failedFields={failedFields}
                    />
                    <InputRadio
                      id="noDiscount"
                      name="discount"
                      checked={!allowMindalayToDiscount && !hasDiscount}
                      labelValue={translationService.translate("TR_WITHOUT_DISCOUNTS")}
                      onChange={this.onHasDiscountChange}
                      failedFields={failedFields}
                    />
                  </div>
                </div>
                <div className="row">
                  {
                    hasDiscount ? <Auxiliary>
                      <div className="col-12"><hr /></div>
                      <div className="col-lg-4 col-12">
                        <Input
                          type="number"
                          id="discount"
                          name="discount"
                          min="0"
                          maxLength="100"
                          value={discount}
                          isInvalidSubmit={isInvalidSubmit}
                          labelValue={`${translationService.translate("TR_DISCOUNT_PERCENTAGE")} *`}
                          onChange={this.onNumberChange}
                          failedFields={failedFields}
                        />
                      </div>
                      <div className="col-lg-4 col-12">
                        <Input
                          type="datetime-local"
                          id="discountStartDate"
                          name="discountStartDate"
                          min="0"
                          value={discountStartDate}
                          isInvalidSubmit={isInvalidSubmit}
                          inputClassName={isInvalidStartDate ? "is-invalid error-bordered" : ""}
                          labelValue={`${translationService.translate("TR_DISCOUNT_START_DATE")} *`}
                          onChange={this.onDateChange}
                          onBlur={() => this.checkDateFormatAndIsValid(discountStartDate, "discountStartDate", "isInvalidStartDate")}
                          failedFields={failedFields}
                        />
                        {
                          isInvalidStartDate ? <small className="red-color">{translationService.translate("TR_INVALID_DATES")}</small> : null
                        }
                      </div>
                      <div className="col-lg-4 col-12">
                        <Input
                          type="datetime-local"
                          id="discountEndDate"
                          name="discountEndDate"
                          min="0"
                          value={discountEndDate}
                          isInvalidSubmit={isInvalidSubmit}
                          inputClassName={isInvalidEndDate ? "is-invalid error-bordered" : ""}
                          labelValue={`${translationService.translate("TR_DISCOUNT_END_DATE")} *`}
                          onChange={this.onDateChange}
                          onBlur={() => this.checkDateFormatAndIsValid(discountEndDate, "discountEndDate", "isInvalidEndDate")}
                          failedFields={failedFields}
                        />
                        {
                          isInvalidEndDate ? <small className="red-color">{translationService.translate("TR_INVALID_DATES")}</small> : null
                        }
                      </div>
                    </Auxiliary> : null
                  }
                </div>
              </Auxiliary>
              : null
          }
          <div className="text-right mt-4">
            <SubmitButton
              id={courseData.id}
              spinnerId={spinnerId}
              className="col-12 col-sm-8 col-md-4"
            />
          </div>
        </form>
      </div>
    </Auxiliary> : null;
  }
}

const mapStateToProps = state => ({
  language: state.language.language,
  translations: state.translation.translations,
  courseData: state.course.currentCourse
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner,
  setCurrentCourse
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CoursePricing);
