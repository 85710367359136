import React, { memo } from 'react';

const RatingRadioInput = props => {
  const ratingPercent = Math.round((props.value * 100) / 5);
  return <div className={`custom-radio custom-control filter-item ${props.blockClassName ? props.blockClassName : ""}`}>
    <input
      type="radio"
      id={props.id}
      name={props.name}
      value={props.value}
      checked={props.checked}
      defaultChecked={props.defaultChecked}
      className={`mr-1 custom-control-input ${props.radioClassName ? props.radioClassName : ""}`}
      onChange={props.onChange}
      disabled={props.disabled}
    />
    <label htmlFor={props.id} className={`custom-control-label ${props.labelClassName ? props.labelClassName : ""}`}>
      <div className="rating-big-stars">
        <div style={{ width: `${ratingPercent}%`}}></div>
      </div>
      <small>{props.value} & ups</small>
      <span>(<span>{props.courseCount}</span>)</span>
    </label>
  </div>;
}

export default memo(RatingRadioInput);
