import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import TranslationService from "../../Services/translationService";
import ModalSpinner from "../Spinners/modalSpinner";

const ModalComponentWithoutActionButton = props => {
	const [translationService, setTranslationService] = useState(null);
	const { translations } = useSelector(state => state.translation);
	const { modalSpinners } = useSelector(state => state.spinner);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return (
		translationService && (
			<Modal
				scrollable
				size={
					props.modalSize
						? props.modalSize
						: props.size
						? props.size
						: props.isLarge
						? "lg"
						: ""
				}
				show={true}
				className="pr-0"
				onHide={props.cancel}
				contentClassName={props.contentClassName}
				aria-labelledby={props.ariaLabelledby}>
				<Modal.Header closeButton>
					<Modal.Title
						className={props.titleClassName || ""}
						title={props.title}>
						{props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						overflowY: modalSpinners && modalSpinners.length ? "clip" : "auto",
					}}>
					<ModalSpinner spinners={modalSpinners} />
					{props.children}
				</Modal.Body>
				<Modal.Footer>
					<button
						type="button"
						className="mindalay--btn-default"
						onClick={props.cancel}>
						{translationService.translate("TR_CLOSE")}
					</button>
				</Modal.Footer>
			</Modal>
		)
	);
};

export default ModalComponentWithoutActionButton;
