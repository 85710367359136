import { useState, useEffect } from 'react';

function useScroll() {
    const [scrollY, setScrollY] = useState(window.scrollY);
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };
    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return scrollY;
}

export default useScroll;