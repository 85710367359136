import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import MainService from "../../Services/mainService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ButtonSpinner from "../../Components/Spinners/buttonSpinner";
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { BOOLEAN_KEY, ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import { TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from "../../Constants/translationKeys";
import Textarea from "../../Components/Inputs/textArea";

class QuestionsGroupForm extends Component {
  mainService = new MainService();
  state = {
    form: {
      name: '',
      isPrivate: false,
    },
    questionsGroupData: this.props.questionsGroupData || null,
    isInvalidSubmit: false,
    spinnerId: null,
    translationService: null,
    failedFields: null,
    nameLength: 100,
    isDisabledSaveButton: false
  };

  componentDidMount() {
    this.setTranslations();
    this.state.questionsGroupData && this.setQuestionsGroupData(this.state.questionsGroupData);
  }

  componentDidUpdate() {
    this.setTranslations();

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    if (this.props.questionsGroupData && nextProps.questionsGroupData) {
      if (this.props.questionsGroupData.id !== nextProps.questionsGroupData.id) {
        this.setQuestionsGroupData(nextProps.questionsGroupData);
        this.setState({ questionsGroupData: nextProps.questionsGroupData });
      }
    } else if (this.props.questionsGroupData !== nextProps.questionsGroupData) {
      if (!this.props.questionsGroupData && nextProps.questionsGroupData) {
        this.setQuestionsGroupData(nextProps.questionsGroupData);
        this.setState({ questionsGroupData: nextProps.questionsGroupData });
      }
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  setQuestionsGroupData = questionsGroupData => {
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        name: questionsGroupData.name,
        isPrivate: questionsGroupData.isPrivate,
      }
    }));
  }

  onChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  };

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  onCheckboxChange = (event) => {
    const failedFields = this.removeFailedFields(event.target.name);
    typeof event.target.checked === BOOLEAN_KEY && this.setState(prevState =>
    ({
      ...prevState,
      failedFields,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.checked,
      }
    }));
  };

  cancelHandler = () => {
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        name: "",
        isPrivate: false,
      }
    }));
    this.props.cancel();
  }

  onSubmit = event => {
    event.preventDefault();
    const { translationService, questionsGroupData } = this.state;
    const form = { ...this.state.form };
    const spinnerId = uuid();
    this.setState({ spinnerId });
    if (!form.name || !form.name.trim()) {
      this.setState({ isInvalidSubmit: true });
    } else {
      this.props.addButtonSpinner(spinnerId);
      this.setState({ isDisabledSaveButton: true });
      if (questionsGroupData) {
        form.id = questionsGroupData.id;
      }
      (questionsGroupData
        ? ApiService.questionsGroupUpdate(form)
        : ApiService.questionsGroupCreate(form)).then(() => {
          this.props.removeButtonSpinner(spinnerId);
          this.setState(prevState => ({ ...prevState, isDisabledSaveButton: false, form: { ...prevState.form, name: "", isPrivate: false } }));
          AlertService.alert(SUCCESS_KEY, translationService.translate(`${questionsGroupData ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
          this.props.removeButtonSpinner(spinnerId);
          this.props.added();
        }).catch(e => this.submitFail(spinnerId, e));
    }
  }

  submitFail = (spinnerId, error) => {
    this.props.removeButtonSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    this.setState({ isDisabledSaveButton: false });
  }

  render() {
    const { isInvalidSubmit, spinnerId, translationService, failedFields, questionsGroupData, nameLength, isDisabledSaveButton } = this.state;
    const { name, isPrivate } = this.state.form;
    const { user } = this.props;
    const selectedButtonSpinner = this.props.buttonSpinners.find(spinner => spinner === spinnerId);


    return translationService ? <form onSubmit={this.onSubmit}>
      <Textarea
        name="name"
        textAreaClassName="pr--4"
        blockClassName="m-0 flex-1 mt-2 mb-3"
        valueLength="input-lenght"
        min="0"
        rows="3"
        max={nameLength}
        labelValue={`${translationService.translate("TR_NAME")} *`}
        value={name}
        autoFocus={true}
        isInvalidSubmit={isInvalidSubmit}
        isInvalidField={isInvalidSubmit && !name.trim()}
        onChange={event => this.onChange(event, nameLength)}
        failedFields={failedFields}
      />
      <InputCheckBox
        id="isPrivate"
        name="isPrivate"
        blockClassName={!user.isOrganizationUser ? "d-none" : ""}
        checked={isPrivate}
        labelValue={translationService.translate("TR_IS_PRIVATE")}
        onChange={this.onCheckboxChange}
        failedFields={failedFields}
      />
      <div className="mb-3 question-group-btn-group d-flex justify-content-end">
        <button type="submit" className="mindalay--btn-secondary mr-2 mt-1" disabled={isDisabledSaveButton} >
          {translationService.translate(!questionsGroupData ? "TR_CREATE" : "TR_SAVE")}
          {selectedButtonSpinner ? <ButtonSpinner buttonClassName="float-right" spinner={spinnerId} /> : null}
        </button>
        <button type="button" className="mindalay--btn-secondary-outline mt-1" onClick={this.cancelHandler}>
          {translationService.translate("TR_CANCEL")}
        </button>
      </div>
    </form> : null;
  }
}

const mapStateToProps = state => ({
  pageSpinners: state.spinner.pageSpinners,
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  languages: state.language.languages,
  user: state.user.user
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(QuestionsGroupForm);