// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from "react-redux"
// import TranslationService from '../../../Services/translationService'
// import { withRouter } from 'react-router'
// import _InfoSvg from '../../../Components/Svg/_infoSvg'
// import PageInfoBlock from '../../../Components/PageInfoBlock/PageInfoBlock'
// import AlertService from '../../../Services/alertService'
// import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from '../../../Constants/mainKeys'
// import { addButtonSpinner, addPartialViewSpinner, removeButtonSpinner, removePartialViewSpinner } from '../../../Store/Actions/spinner'
// import { Link } from "react-router-dom"
// import { Card } from 'react-bootstrap'
// import GamifyApiService from '../../../Services/gamifyApiService'
// import uuid from 'react-uuid'
// import NukaCarousel from 'nuka-carousel'
// import ActionButton from '../../../Components/ActionButton/actionButton'
// import Auxiliary from '../../../hoc/auxiliary/auxiliary'
// import NoDataImage from "./../../../assets/images/illustrations/nodata.svg"
// import CardSvg from '../../../Components/Svg/cardSvg';
// import StarSvg from '../../../Components/Svg/starSvg';

// const buttonSpinnerId = uuid();
// const buttonSpinnerId1 = uuid();

// const ShoppingCart = (props) => {

//   const dispatch = useDispatch();
//   const { translations } = useSelector(state => state.translation);
//   const { user } = useSelector(state => state.user);
//   const { textSpinners } = useSelector(state => state.spinner);
//   const { language } = useSelector(state => state.language);
//   const [translationService, setTranslationService] = useState(null);
//   const [shopItems, setShopItems] = useState([]);
//   const [loaderId, setLoaderId] = useState(null);
//   const [isDisabledFields, setIsDisabledFields] = useState(false);
//   const [totalPriceData, setTotalPriceData] = useState(null);
//   const [isClosedInfoBlock, setIsClosedInfoBlock] = useState(false);

//   useEffect(() => {
//     setTranslationService(new TranslationService(translations));
//   }, [translations]);

//   useEffect(() => {
//     getShoppingCartItems();
//   }, []);

//   useEffect(() => {
//     if (shopItems && shopItems.length) {
//       getTotalPrice()
//     }
//   }, [shopItems])

//   const getTotalPrice = () => {
//     GamifyApiService.getTotalPrice(user.studentId ? user.studentId.toString() : null).then(response => {
//       if (response && response.data) {
//         setTotalPriceData(response.data)
//       }
//     }).catch(error => getFail(error))
//   }

//   const getShoppingCartItems = () => {
//     const spinnerId = uuid();
//     dispatch(addPartialViewSpinner(spinnerId));
//     const data = {
//       userId: user.studentId ? user.studentId.toString() : null,
//       shopItemImageSize: 3,
//       resourceImageSize: 1,
//     }
//     GamifyApiService.getShoppingCartItems(data).then(response => {
//       if (response && response.data && response.data.length) {
//         let data = [...response.data];
//         setShopItems(data);
//       }
//       dispatch(removePartialViewSpinner(spinnerId))
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const rmeoveItemFromShoppingCart = (item, spinnerId) => {
//     if (!item || loaderId) { return false; }
//     dispatch(addButtonSpinner(spinnerId));
//     let shopItemsCopy = [...shopItems];
//     GamifyApiService.removeShopItemFromShoppingCart(item.id).then(response => {
//       AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
//       dispatch(removeButtonSpinner(spinnerId))
//       setShopItems(shopItemsCopy.filter(_item => _item.id !== item.id));
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const moveAllProductsToFavorites = (spinnerId) => {
//     AlertService.alertConfirm(
//       `${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
//       "You want to move all products to favorites.",
//       translationService.translate(TR_YES),
//       translationService.translate(TR_NO)
//     ).then(() => {
//       dispatch(addButtonSpinner(spinnerId))
//       GamifyApiService.moveAllProductsToFavorites(user.studentId ? user.studentId.toString() : null).then(response => {
//         dispatch(removeButtonSpinner(spinnerId));
//         AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
//         setShopItems([]);
//       }).catch(error => getFail(error, spinnerId))
//     })
//   }

//   const moveToFaviorites = (item, spinnerId) => {
//     if (!item || loaderId) { return false; }
//     dispatch(addButtonSpinner(spinnerId));
//     let shopItemsCopy = [...shopItems];
//     GamifyApiService.moveToFavorite(item.id).then(response => {
//       AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
//       dispatch(removeButtonSpinner(spinnerId))
//       setShopItems(shopItemsCopy.filter(_item => _item.id !== item.id));
//     }).catch(error => getFail(error, spinnerId))
//   }
//   const buyToFaviorites = (spinnerId) => {
//     if (!user.studentId) return false;
//     AlertService.alertConfirm(
//       `${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
//       "",
//       translationService.translate(TR_YES),
//       translationService.translate(TR_NO),
//     ).then(() => {
//       dispatch(addButtonSpinner(spinnerId));
//       GamifyApiService.buyShopItemOll(user.studentId).then(() => {
//         dispatch(removeButtonSpinner(spinnerId));
//         setShopItems([]);
//         AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
//         props.history.push(`/${language}/gamification/shop-items`);
//       }).catch(error => getFail(error, spinnerId))
//     });
//   }

//   const changeShopItemCount = (item, num) => {
//     if (num < 1 || item.shopItem.availability < num) { return false; }
//     setIsDisabledFields(true);
//     let data = {
//       id: item.id,
//       quantity: num,
//       userId: user.id.toString(),
//       shopItemId: item.shopItem?.id
//     }
//     GamifyApiService.updateShoppingCartItem(data).then(response => {
//       let shopItemsCopy = [...shopItems];
//       shopItemsCopy.forEach(_item => {
//         if (_item.id === item.id) {
//           _item.isDisabled = false;
//           _item.quantity = num;
//         }
//       });
//       setShopItems(shopItemsCopy);
//       setIsDisabledFields(false);
//     }).catch(error => getFail(error));

//   }

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
//     spinnerId && dispatch(removePartialViewSpinner(spinnerId));
//     spinnerId && dispatch(removeButtonSpinner(spinnerId));
//     setIsDisabledFields(false)
//   }

//   return translationService ?
//     <div className="container">
//       <div className="row mt-4">
//         <div className="col-12">
//           <div className='p-0 instructor-consultant-navbar'>
//             <div className='d-flex justify-content-between flex-wrap align-items-center'>
//               <h2 className="section-title">
//                 {translationService.translate("TR_SHOPPING_CART")}
//               </h2>
//               <div className='navbar'>
//                 <ul className="navbar-nav">
//                   <li className={`nav-item position-relative dropdown ${isClosedInfoBlock ? "mr-4" : ""}`}>
//                     <Link to={`/${language}/gamification/favorites`} className="nav-link p-1" title="Favorites">
//                       <StarSvg />
//                     </Link>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//           <PageInfoBlock
//             pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
//             infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
//             translationService={translationService}
//             closeCb={() => setIsClosedInfoBlock(!isClosedInfoBlock)}
//           />
//         </div>
//       </div>
//       <hr />
//       {
//         shopItems && shopItems.length ?
//           <div className='row'>
//             <div className={`col-12 col-lg-8 px-3 border rounded`}>
//               <div className="row" style={{ minHeight: "500px" }}>
//                 {
//                   shopItems.map((item, index) => {
//                     return <div key={index} className="col-12 col-sm-6 col-lg-4 p-2">
//                       <Card className='w-100 h-100'>
//                         {
//                           item.shopItem.imagePaths && item.shopItem.imagePaths.length ?
//                             item.shopItem.imagePaths.length > 1 ?
//                               <NukaCarousel
//                                 autoplay={item.shopItem.imagePaths.length > 1 ? true : false}
//                                 wrapAround={true}
//                                 autoplayInterval={80000}
//                                 speed={1000}
//                                 height='200px'
//                                 className='small-carousel'
//                                 renderCenterLeftControls={({ previousSlide }) => (
//                                   <button onClick={previousSlide} className='arrow left-arrow'>
//                                     <i className="fa fa-arrow-left" />
//                                   </button>
//                                 )}
//                                 renderCenterRightControls={({ nextSlide }) => (
//                                   <button onClick={nextSlide} className='arrow right-arrow'>
//                                     <i className="fa fa-arrow-right" />
//                                   </button>
//                                 )}
//                               >
//                                 {
//                                   item.shopItem.imagePaths.map((item, index) => {
//                                     return <div
//                                       key={index}
//                                       className='w-100 pt-3'
//                                       style={{
//                                         backgroundImage: `url(${item})`,
//                                         backgroundPosition: "center",
//                                         backgroundSize: "contain",
//                                         backgroundRepeat: "no-repeat",
//                                         height: "200px"
//                                       }}
//                                     />
//                                   })
//                                 }
//                               </NukaCarousel>
//                               : <div
//                                 className='w-100 pt-3'
//                                 style={{
//                                   backgroundImage: `url(${item.shopItem.imagePaths[0]})`,
//                                   backgroundPosition: "center",
//                                   backgroundSize: "contain",
//                                   backgroundRepeat: "no-repeat",
//                                   height: "200px"
//                                 }}
//                               />
//                             : null
//                         }
//                         <Card.Body className='position-relative pt-0 d-flex flex-column justify-content-between p-2'>
//                           <div>
//                             <h3 className='title text-center'>{item.shopItem.name}</h3>
//                             <hr />
//                           </div>
//                           <div>
//                             <p className="max-line-3">
//                               {item.shopItem.description}
//                             </p>
//                             <p className='d-flex justify-content-between mt-2'>
//                               <b>Availability </b>
//                               <b>{item.shopItem.availability}</b>
//                             </p>

//                             <p className='d-flex justify-content-between mt-2 align-items-center'>
//                               <b>{translationService.translate("TR_PRICE")} </b>
//                               <span className='d-flex align-items-center word-break-break-word max-line-1'>
//                                 <small className='mx-2 ' >{item.shopItem.resourceAmount}</small>
//                                 <img
//                                   src={item?.shopItem.resource.imagePath}
//                                   alt={item?.shopItem.resource.name}
//                                   title={item?.shopItem.resource.name}
//                                   style={{ width: "30px", height: "30px" }}
//                                 />
//                               </span>
//                             </p>
//                           </div>
//                           <div>
//                             <Auxiliary>
//                               <hr />
//                               <div className='d-flex justify-content-around align-items-center'>
//                                 <button
//                                   disabled={isDisabledFields}
//                                   className={`mindalay--btn-blue mindalay--btn d-flex justify-content-center align-items-center p-3 ${isDisabledFields ? "disabled" : ""}`}
//                                   style={{ fontSize: "25px", height: "20px", width: "20px", borderRadius: "50%" }}
//                                   onClick={() => changeShopItemCount(item, (item.quantity || 0) - 1)}
//                                 >
//                                   <span className='mb-1'>-</span>
//                                 </button>
//                                 <b>{item.quantity}</b>
//                                 <button
//                                   disabled={isDisabledFields}
//                                   className={`mindalay--btn-blue mindalay--btn d-flex justify-content-center align-items-center p-3 ${isDisabledFields ? " disabled" : ""}`}
//                                   style={{ fontSize: "25px", height: "20px", width: "20px", borderRadius: "50%" }}
//                                   onClick={() => changeShopItemCount(item, (item.quantity || 0) + 1)}
//                                 >
//                                   <span className='mb-1'>+</span>
//                                 </button>
//                               </div>
//                             </Auxiliary>
//                             <div className='mt-3'>
//                               <ActionButton
//                                 type="button"
//                                 spinnerId={`${buttonSpinnerId}_move_${item.id}`}
//                                 className="mindalay--btn-secondary mindalay--btn outline-none w-100 max-line-1 my-1"
//                                 name={"Move to favorite"}
//                                 clicked={() => { moveToFaviorites(item, `${buttonSpinnerId}_move_${item.id}`) }}
//                               />
//                               <ActionButton
//                                 type="button"
//                                 spinnerId={`${buttonSpinnerId}_${item.id}`}
//                                 clicked={() => { rmeoveItemFromShoppingCart(item, `${buttonSpinnerId}_${item.id}`) }}
//                                 className="mindalay--btn-dark w-100 max-line-1 my-1"
//                                 name={"Remove from Cart"}
//                               />
//                             </div>
//                           </div>
//                         </Card.Body>
//                       </Card>
//                     </div>
//                   })
//                 }
//               </div>
//             </div>
//             {
//               totalPriceData && Object.keys(totalPriceData).length ?
//                 <div className='col-12 col-lg-4 mt-3 mt-lg-0'>
//                   <div className='row px-2'>
//                     <div className='border rounded w-100'>
//                       <Card className='border-none p-3'>
//                         <div className="cart-total-info-wrapper">
//                           <Auxiliary>
//                             <div className="total-price-info">
//                               <span className="total-text d-block mb-3" style={{ fontSize: "22px" }} >{`${translationService.translate('TR_TOTAL')}`} </span>
//                               {
//                                 Object.keys(totalPriceData).map((item, index) => {
//                                   return <div key={index} className='d-flex justify-content-between align-items-center'>
//                                     <h5 className="word-break-break-word max-line-2 mr-3" title={item}>{item}: </h5>
//                                     <h5 className="total-price" ><span style={{ fontSize: "22px" }}>{totalPriceData[item]}</span></h5>
//                                   </div>
//                                 })
//                               }
//                             </div>
//                             <ActionButton
//                               type="button"
//                               spinnerId={`${buttonSpinnerId}_move_all`}
//                               clicked={() => moveAllProductsToFavorites(`${buttonSpinnerId}_move_all`)}
//                               className="mindalay--btn-secondary-outline w-100 mt-3 mb-2"
//                               name={"Move All Products to Favorites"}
//                             />
//                             <ActionButton
//                               type="button"
//                               spinnerId={`${buttonSpinnerId}_buy_all`}
//                               clicked={() => { buyToFaviorites(`${buttonSpinnerId}_buy_all`) }}
//                               className="mindalay--btn-dark w-100"
//                               name={"Buy All"}
//                             />
//                           </Auxiliary>
//                         </div>
//                       </Card>
//                     </div>
//                   </div>
//                 </div>
//                 : null
//             }
//           </div>
//           : <div className="mt-4 w-100">
//             <div className="no-data-container">
//               <div className="no-data-wrapper">
//                 <p>{translationService.translate("TR_NO_DATA")}</p>
//                 <img src={NoDataImage} alt="/" height="200px" />
//               </div>
//             </div>
//           </div>
//       }

//     </div>
//     : null
// }

// export default withRouter(ShoppingCart);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import AlertService from "../../../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../../Constants/mainKeys";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import GamifyApiService from "../../../Services/gamifyApiService";
import uuid from "react-uuid";
import NukaCarousel from "nuka-carousel";
import ActionButton from "../../../Components/ActionButton/actionButton";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import NoDataImage from "./../../../assets/images/illustrations/nodata.svg";
import CardSvg from "../../../Components/Svg/cardSvg";
import StarSvg from "../../../Components/Svg/starSvg";

const buttonSpinnerId = uuid();
const buttonSpinnerId1 = uuid();

const ShoppingCart = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { user } = useSelector(state => state.user);
	const { textSpinners } = useSelector(state => state.spinner);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [shopItems, setShopItems] = useState([]);
	const [loaderId, setLoaderId] = useState(null);
	const [isDisabledFields, setIsDisabledFields] = useState(false);
	const [totalPriceData, setTotalPriceData] = useState(null);
	const [isClosedInfoBlock, setIsClosedInfoBlock] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getShoppingCartItems();
	}, []);

	useEffect(() => {
		if (shopItems && shopItems.length) {
			getTotalPrice();
		}
	}, [shopItems]);

	const getTotalPrice = () => {
		GamifyApiService.getTotalPrice(
			user.studentId ? user.studentId.toString() : null,
		)
			.then(response => {
				if (response && response.data) {
					setTotalPriceData(response.data);
				}
			})
			.catch(error => getFail(error));
	};

	const getShoppingCartItems = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			userId: user.studentId ? user.studentId.toString() : null,
			shopItemImageSize: 3,
			resourceImageSize: 1,
		};
		GamifyApiService.getShoppingCartItems(data)
			.then(response => {
				if (response && response.data && response.data.length) {
					let data = [...response.data];
					setShopItems(data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const rmeoveItemFromShoppingCart = (item, spinnerId) => {
		if (!item || loaderId) {
			return false;
		}
		dispatch(addButtonSpinner(spinnerId));
		let shopItemsCopy = [...shopItems];
		GamifyApiService.removeShopItemFromShoppingCart(item.id)
			.then(response => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				dispatch(removeButtonSpinner(spinnerId));
				setShopItems(shopItemsCopy.filter(_item => _item.id !== item.id));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const moveAllProductsToFavorites = spinnerId => {
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			translationService.translate("TR_MOVE_TO_FAVORITES"),
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addButtonSpinner(spinnerId));
			GamifyApiService.moveAllProductsToFavorites(
				user.studentId ? user.studentId.toString() : null,
			)
				.then(response => {
					dispatch(removeButtonSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					setShopItems([]);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const moveToFaviorites = (item, spinnerId) => {
		if (!item || loaderId) {
			return false;
		}
		dispatch(addButtonSpinner(spinnerId));
		let shopItemsCopy = [...shopItems];
		GamifyApiService.moveToFavorite(item.id)
			.then(response => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				dispatch(removeButtonSpinner(spinnerId));
				setShopItems(shopItemsCopy.filter(_item => _item.id !== item.id));
			})
			.catch(error => getFail(error, spinnerId));
	};
	const buyToFaviorites = spinnerId => {
		if (!user.studentId) return false;
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addButtonSpinner(spinnerId));
			GamifyApiService.buyShopItemOll(user.studentId)
				.then(() => {
					dispatch(removeButtonSpinner(spinnerId));
					setShopItems([]);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					props.history.push(`/${language}/gamification/shop-items`);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const changeShopItemCount = (item, num) => {
		if (num < 1 || item.shopItem.availability < num) {
			return false;
		}
		setIsDisabledFields(true);
		let data = {
			id: item.id,
			quantity: num,
			userId: user.id.toString(),
			shopItemId: item.shopItem?.id,
		};
		GamifyApiService.updateShoppingCartItem(data)
			.then(response => {
				let shopItemsCopy = [...shopItems];
				shopItemsCopy.forEach(_item => {
					if (_item.id === item.id) {
						_item.isDisabled = false;
						_item.quantity = num;
					}
				});
				setShopItems(shopItemsCopy);
				setIsDisabledFields(false);
			})
			.catch(error => getFail(error));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		setIsDisabledFields(false);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="p-0 instructor-consultant-navbar">
						<div className="d-flex justify-content-between flex-wrap align-items-center">
							<h2 className="section-title">
								{translationService.translate("TR_SHOPPING_CART")}
							</h2>
							<div className="navbar">
								<ul className="navbar-nav">
									<li
										className={`nav-item position-relative dropdown ${
											isClosedInfoBlock ? "mr-4" : ""
										}`}>
										<Link
											to={`/${language}/gamification/favorites`}
											className="nav-link p-1"
											title="Favorites">
											<StarSvg />
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<PageInfoBlock
						pageTitle="Student shoping cart"
						pageName="student_shoping_cart"
						translationService={translationService}
						closeCb={() => setIsClosedInfoBlock(!isClosedInfoBlock)}
					/>
				</div>
			</div>
			<hr />
			{shopItems && shopItems.length ? (
				<div className="row">
					<div className={`col-12 col-lg-8 px-3 border rounded`}>
						<div className="row" style={{ minHeight: "500px" }}>
							{shopItems.map((item, index) => {
								return (
									<div key={index} className="col-12 col-sm-6 col-lg-4 p-2">
										<Card className="w-100 h-100">
											{item.shopItem.imagePaths &&
											item.shopItem.imagePaths.length ? (
												item.shopItem.imagePaths.length > 1 ? (
													<NukaCarousel
														autoplay={
															item.shopItem.imagePaths.length > 1 ? true : false
														}
														wrapAround={true}
														autoplayInterval={80000}
														speed={1000}
														height="200px"
														className="small-carousel"
														renderCenterLeftControls={({ previousSlide }) => (
															<button
																onClick={previousSlide}
																className="arrow left-arrow">
																<i className="fa fa-arrow-left" />
															</button>
														)}
														renderCenterRightControls={({ nextSlide }) => (
															<button
																onClick={nextSlide}
																className="arrow right-arrow">
																<i className="fa fa-arrow-right" />
															</button>
														)}>
														{item.shopItem.imagePaths.map((item, index) => {
															return (
																<div
																	key={index}
																	className="w-100 pt-3"
																	style={{
																		backgroundImage: `url(${item})`,
																		backgroundPosition: "center",
																		backgroundSize: "contain",
																		backgroundRepeat: "no-repeat",
																		height: "200px",
																	}}
																/>
															);
														})}
													</NukaCarousel>
												) : (
													<div
														className="w-100 pt-3"
														style={{
															backgroundImage: `url(${item.shopItem.imagePaths[0]})`,
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
															height: "200px",
														}}
													/>
												)
											) : null}
											<Card.Body className="position-relative pt-0 d-flex flex-column justify-content-between p-2">
												<div>
													<h3 className="title text-center">
														{item.shopItem.name}
													</h3>
													<hr />
												</div>
												<div>
													<p className="max-line-3">
														{item.shopItem.description}
													</p>
													<p className="d-flex justify-content-between mt-2">
														<b>
															{translationService.translate("TR_AVAILABILITY")}
														</b>
														<b>{item.shopItem.availability}</b>
													</p>

													<p className="d-flex justify-content-between mt-2 align-items-center">
														<b>{translationService.translate("TR_PRICE")} </b>
														<span className="d-flex align-items-center word-break-break-word max-line-1">
															<small className="mx-2 ">
																{item.shopItem.resourceAmount}
															</small>
															<img
																src={item?.shopItem.resource.imagePath}
																alt={item?.shopItem.resource.name}
																title={item?.shopItem.resource.name}
																style={{ width: "30px", height: "30px" }}
															/>
														</span>
													</p>
												</div>
												<div>
													<Auxiliary>
														<hr />
														<div className="d-flex justify-content-around align-items-center">
															<button
																disabled={isDisabledFields}
																className={`mindalay--btn-blue mindalay--btn d-flex justify-content-center align-items-center p-3 ${
																	isDisabledFields ? "disabled" : ""
																}`}
																style={{
																	fontSize: "25px",
																	height: "20px",
																	width: "20px",
																	borderRadius: "50%",
																}}
																onClick={() =>
																	changeShopItemCount(
																		item,
																		(item.quantity || 0) - 1,
																	)
																}>
																<span className="mb-1">-</span>
															</button>
															<b>{item.quantity}</b>
															<button
																disabled={isDisabledFields}
																className={`mindalay--btn-blue mindalay--btn d-flex justify-content-center align-items-center p-3 ${
																	isDisabledFields ? " disabled" : ""
																}`}
																style={{
																	fontSize: "25px",
																	height: "20px",
																	width: "20px",
																	borderRadius: "50%",
																}}
																onClick={() =>
																	changeShopItemCount(
																		item,
																		(item.quantity || 0) + 1,
																	)
																}>
																<span className="mb-1">+</span>
															</button>
														</div>
													</Auxiliary>
													<div className="mt-3">
														<ActionButton
															type="button"
															spinnerId={`${buttonSpinnerId}_move_${item.id}`}
															className="mindalay--btn-secondary mindalay--btn outline-none w-100 max-line-1 my-1"
															name={translationService.translate(
																"TR_MOVE_TO_FAVORITE",
															)}
															clicked={() => {
																moveToFaviorites(
																	item,
																	`${buttonSpinnerId}_move_${item.id}`,
																);
															}}
														/>
														<ActionButton
															type="button"
															spinnerId={`${buttonSpinnerId}_${item.id}`}
															clicked={() => {
																rmeoveItemFromShoppingCart(
																	item,
																	`${buttonSpinnerId}_${item.id}`,
																);
															}}
															className="mindalay--btn-dark w-100 max-line-1 my-1"
															name={translationService.translate(
																"TR_REMOVE_FROM_CART",
															)}
														/>
													</div>
												</div>
											</Card.Body>
										</Card>
									</div>
								);
							})}
						</div>
					</div>
					{totalPriceData && Object.keys(totalPriceData).length ? (
						<div className="col-12 col-lg-4 mt-3 mt-lg-0">
							<div className="row px-2">
								<div className="border rounded w-100">
									<Card className="border-none p-3">
										<div className="cart-total-info-wrapper">
											<Auxiliary>
												<div className="total-price-info">
													<span
														className="total-text d-block mb-3"
														style={{ fontSize: "22px" }}>
														{`${translationService.translate("TR_TOTAL")}`}{" "}
													</span>
													{Object.keys(totalPriceData).map((item, index) => {
														return (
															<div
																key={index}
																className="d-flex justify-content-between align-items-center">
																<h5
																	className="word-break-break-word max-line-2 mr-3"
																	title={item}>
																	{item}:{" "}
																</h5>
																<h5 className="total-price">
																	<span style={{ fontSize: "22px" }}>
																		{totalPriceData[item]}
																	</span>
																</h5>
															</div>
														);
													})}
												</div>
												<ActionButton
													type="button"
													spinnerId={`${buttonSpinnerId}_move_all`}
													clicked={() =>
														moveAllProductsToFavorites(
															`${buttonSpinnerId}_move_all`,
														)
													}
													className="mindalay--btn-secondary-outline w-100 mt-3 mb-2"
													name={translationService.translate(
														"TR_MOVE_ALL_PRODUCTS_TO_FAVORITE",
													)}
												/>
												<ActionButton
													type="button"
													spinnerId={`${buttonSpinnerId}_buy_all`}
													clicked={() => {
														buyToFaviorites(`${buttonSpinnerId}_buy_all`);
													}}
													className="mindalay--btn-dark w-100"
													name={translationService.translate("TR_BUY_ALL")}
												/>
											</Auxiliary>
										</div>
									</Card>
								</div>
							</div>
						</div>
					) : null}
				</div>
			) : (
				<div className="mt-4 w-100">
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" height="200px" />
						</div>
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(ShoppingCart);
