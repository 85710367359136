import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showOrHideHelpAlert } from "../../Store/Actions/main";
import { withRouter } from "react-router-dom";
import Parser from "html-react-parser";
import ParserService from "../../Services/parserService";
import ParserComponent from "../ParserComponent/ParserComponent";

const HelpAlert = props => {
	const { helpKey } = props;
	const dispatch = useDispatch();
	const [currentHelpAlert, setCurrentHelpAlert] = useState(null);
	const [alertBlockClassList, setAlertBlockClassList] = useState(null);
	const [alertPositions, setAlertPositions] = useState(null);
	const [foundElementPosition, setFoundElementPosition] = useState(null);
	const [isShowNextButton, setIsShowNextButton] = useState(false);
	const [isShowPrevButton, setIsShowPrevButton] = useState(false);

	const helpAlerts = useSelector(state => state.main.helpAlerts);

	useEffect(() => {
		if (helpAlerts?.length) {
			var currentData = null;
			var activeTab = document.querySelector(".tab-pane.active");

			if (activeTab) {
				currentData = helpAlerts.find(
					data =>
						data.name.trim() === activeTab.querySelector(".info-title").id,
				);
			} else {
				currentData = helpAlerts.find(data =>
					document.getElementById(data.name.trim()),
				);
			}

			if (currentData) {
				setCurrentHelpAlert(currentData);
				setFoundElementPosition(getElementTopLeft(currentData.name.trim()));
			}
		}
	}, [helpAlerts]);

	const getElementTopLeft = id => {
		var element = document.getElementById(id);
		var top = 0;
		var left = 0;
		while (element && element.tagName != "BODY") {
			top += element.offsetTop;
			left += element.offsetLeft;
			element = element.offsetParent;
		}
		if (!element) {
			dispatch(showOrHideHelpAlert(false));
		}
		return { offsetTop: top, offsetLeft: left };
	};

	useEffect(() => {
		if (currentHelpAlert && foundElementPosition) {
			var alertComponentWidth =
				document.getElementById("alert-wrapper").offsetWidth;
			var alertComponentHeight =
				document.getElementById("alert-wrapper").offsetHeight;

			let alertPositions = null;
			if (
				alertComponentWidth + foundElementPosition.offsetLeft >
				document.body.offsetWidth
			) {
				// LEFT ALERT
				if (foundElementPosition.offsetTop - alertComponentHeight < 10) {
					if (alertComponentWidth > foundElementPosition.offsetLeft) {
						document
							.querySelector(".help-top-center")
							?.style.setProperty("--left", "-18%");
						alertPositions = {
							top: `${foundElementPosition.offsetTop + 30}px`,
							left: `${
								foundElementPosition.offsetLeft - alertComponentWidth + 190
							}px`,
						};
						setAlertBlockClassList("help-top-center");
						if (
							foundElementPosition.offsetTop + alertComponentHeight <
							window.scrollY
						) {
							window.scrollTo(
								foundElementPosition.offsetLeft,
								foundElementPosition.offsetTop - (alertComponentHeight + 200),
							);
						}
					} else {
						// LEFT BOTTOM ALERT //arrow position- right top
						alertPositions = {
							top: `${foundElementPosition.offsetTop - 6}px`,
							left: `${
								foundElementPosition.offsetLeft - alertComponentWidth - 4
							}px`,
						};
						setAlertBlockClassList("help-right");
						if (
							foundElementPosition.offsetTop + alertComponentHeight <
							window.scrollY
						) {
							window.scrollTo(
								foundElementPosition.offsetLeft,
								foundElementPosition.offsetTop - (alertComponentHeight + 200),
							);
						}
					}
				} else {
					if (alertComponentWidth > foundElementPosition.offsetLeft) {
						// CENTER TOP ALERT  // arrow position- bottom center
						alertPositions = {
							top: `${
								foundElementPosition.offsetTop - alertComponentHeight - 10
							}px`,
							left: `${
								foundElementPosition.offsetLeft - alertComponentWidth + 162
							}px`,
						};
						setAlertBlockClassList("help-bottom-center");
						if (
							foundElementPosition.offsetTop - window.scrollY <
								alertComponentHeight ||
							window.scrollY + window.innerHeight <
								foundElementPosition.offsetTop
						) {
							window.scrollTo(
								foundElementPosition.offsetLeft,
								foundElementPosition.offsetTop - (alertComponentHeight + 200),
							);
						}
					} else {
						// LEFT TOP ALERT  // arrow position- bottom right
						alertPositions = {
							top: `${
								foundElementPosition.offsetTop - alertComponentHeight - 10
							}px`,
							left: `${
								foundElementPosition.offsetLeft - alertComponentWidth + 36
							}px`,
						};
						setAlertBlockClassList("help-bottom-right");
						if (
							foundElementPosition.offsetTop - window.scrollY <
								alertComponentHeight ||
							window.scrollY + window.innerHeight <
								foundElementPosition.offsetTop
						) {
							window.scrollTo(
								foundElementPosition.offsetLeft,
								foundElementPosition.offsetTop - (alertComponentHeight + 200),
							);
						}
					}
				}
			}
			// else if (foundElementPosition.offsetTop - alertComponentHeight < 0) {
			else if (foundElementPosition.offsetTop - alertComponentHeight < 10) {
				// BOTTOM ALERT  // arrow position- top left
				alertPositions = {
					top: `${foundElementPosition.offsetTop + 28}px`,
					left: `${foundElementPosition.offsetLeft - 13}px`,
				};
				setAlertBlockClassList("help-top");
				window.scrollTo(
					foundElementPosition.offsetLeft,
					foundElementPosition.offsetTop - (alertComponentHeight + 200),
				);
			} else {
				// TOP ALERT // arrow position- bottom left
				alertPositions = {
					top: `${
						foundElementPosition.offsetTop - alertComponentHeight - 10
					}px`,
					left: `${foundElementPosition.offsetLeft - 13}px`,
				};
				setAlertBlockClassList("help-bottom");
				if (
					foundElementPosition.offsetTop - window.scrollY <
						alertComponentHeight ||
					window.scrollY + window.innerHeight < foundElementPosition.offsetTop
				) {
					window.scrollTo(
						foundElementPosition.offsetLeft,
						foundElementPosition.offsetTop - (alertComponentHeight + 200),
					);
				}
			}
			setAlertPositions(alertPositions);
		}

		if (currentHelpAlert && helpAlerts && helpAlerts.length) {
			setIsShowPrevButton(
				helpAlerts.find(
					data =>
						data.name !== currentHelpAlert.name &&
						data.displayOrder < currentHelpAlert.displayOrder,
				),
			);
			var nextHelpData = helpAlerts.find(
				data =>
					data.name !== currentHelpAlert.name &&
					data.displayOrder > currentHelpAlert.displayOrder,
			);
			if (nextHelpData) {
				var nextElement = document.getElementById(nextHelpData.name);
				if (nextElement) setIsShowNextButton(true);
				else setIsShowNextButton(false);
			} else setIsShowNextButton(false);
		}

		const interval = setInterval(
			() => {
				currentHelpAlert && next();
			},
			currentHelpAlert
				? readingMilliseconds(currentHelpAlert.description)
				: 5000,
		);
		return () => clearInterval(interval);
	}, [currentHelpAlert, foundElementPosition]);

	const close = event => {
		event.preventDefault();
		dispatch(showOrHideHelpAlert(false));
	};

	const readingMilliseconds = text => {
		// const wpm = 200-250;
		const wpm = 100;
		const words = text.trim().split(/\s+/).length;
		const time = Math.ceil((words / wpm) * 60 * 1000);
		return time;
	};

	const next = event => {
		event && event.preventDefault();
		const content = helpAlerts.filter(
			data =>
				data &&
				data.name !== currentHelpAlert.name &&
				data.displayOrder > currentHelpAlert.displayOrder,
		);
		content.sort((first, second) => first.displayOrder - second.displayOrder);
		if (content && content.length) {
			setCurrentHelpAlert(content[0]);
			setFoundElementPosition(getElementTopLeft(content[0]?.name));
		} else {
			dispatch(showOrHideHelpAlert(false));
		}
	};

	const prev = event => {
		event && event.preventDefault();
		const content = helpAlerts.filter(
			data =>
				data &&
				data.name !== currentHelpAlert.name &&
				data.displayOrder < currentHelpAlert.displayOrder,
		);
		content.sort((first, second) => first.displayOrder - second.displayOrder);
		if (content && content.length) {
			setCurrentHelpAlert(content[content.length - 1]);
			setFoundElementPosition(
				getElementTopLeft(content[content.length - 1]?.name),
			);
		} else {
			dispatch(showOrHideHelpAlert(false));
		}
	};

	return currentHelpAlert ? (
		<div id="alert-wrapper" className="alert-wrapper" style={alertPositions}>
			<div className={`alert-block h-100 ${alertBlockClassList}`}>
				<div className="d-flex mb-1">
					<div className="flex-1 text-left">
						{/* {currentHelpAlert.displayOrder} / {helpAlertsInitialLength} */}
					</div>
					{isShowPrevButton ? (
						<button
							type="button"
							className="btn-transition btn btn-outline-light mr-1"
							title="prev"
							onClick={prev}>
							<i className="fas fa-backward m-0"></i>
						</button>
					) : null}
					{isShowNextButton ? (
						<button
							type="button"
							className="btn-transition btn btn-outline-light mr-1"
							title="next"
							onClick={next}>
							<i className="fas fa-forward m-0"></i>
						</button>
					) : null}
					<button
						type="button"
						className="btn-transition btn btn-outline-light"
						title="close"
						onClick={close}>
						<i className="fas fa-times m-0"></i>
					</button>
				</div>
				<hr />
				<span>
					<ParserComponent text={currentHelpAlert.description} />
				</span>
			</div>
		</div>
	) : null;
};

export default withRouter(HelpAlert);
