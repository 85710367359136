import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { CHAT_KEY, COURSES_KEY, STUDENT_CONSULTATION_CONTRACTS_KEY, TICKET_KEY } from '../../../Constants/urlKeys';
import TranslationService from '../../../Services/translationService';
import moment from "moment";
import Auxiliary from './../../../hoc/auxiliary/auxiliary';
import ShowMoreText from "react-show-more-text";

const Ticket = (props) => {

  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);


  const redirectToCourseOrConsultationPage = (ticket) => {
    if (!ticket) { return; }
    if (ticket.entityType === 0) {
      props.history.push(`/${language}/${COURSES_KEY}/${ticket.entityId}`)
    }
    if (ticket.entityType === 7) {
      ticket.contractId && props.history.push(`/${language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${ticket.contractId}`)
    }
  }

  const redirectToTicketChatPage = (ticketId) => {
    props.history.push(`/${language}/${TICKET_KEY}/${CHAT_KEY}/${ticketId}`)
  }

  const tickets = [...props.tickets];

  return (translationService ? <Auxiliary>
    {
      tickets && tickets.map((ticket, index) => {
        return (props.ticketsCount > index) ? <div key={ticket.id} className="col-md-6 col-12 mt-4">
          <div className="mindalay--consultation-card mindalay--consultation-container cursor-pointer" onClick={() => redirectToTicketChatPage(ticket.id)}>
            <div className="consultation-container-left-side overflow-hidden">
              <div className="ticket-card-wrapper">
                {
                  ticket.entityName ?
                    <strong className="consultation-header-name cursor-pointer" onClick={() => redirectToCourseOrConsultationPage(ticket)}>{ticket.entityName}</strong>
                    : null
                }
                {
                  !ticket.processed ?
                    <p className="mindalay--some-process-status text-info">{translationService.translate("TR_IN_PROCESS")}</p>
                    : <p className="mindalay--some-process-status text-success">{translationService.translate("TR_RESOLVED")}</p>
                }
                <hr />
              </div>
              <div className="d-flex flex-wrap">
                {
                  ticket.ticketNumber ?
                    <div className="ticket-subject">
                      <small className="d-block">
                        {`${translationService.translate("TR_TICKET_NUMBER")}:`}
                      </small>
                      <small>{`${ticket.ticketNumber}`}</small>
                    </div>
                    : null
                }
                {
                  ticket.createDate ?
                    <div className="ticket-subject">
                      <small className="d-block">
                        {`${translationService.translate("TR_CREATED")}:`}
                      </small>
                      <small> {`${moment(ticket.createDate).format("LL")}`}</small>
                    </div>
                    : null
                }
                <div className="ticket-subject">
                  <small className="d-block">{`${translationService.translate("TR_ENTITY_TYPE")}:`}
                  </small>
                  <small>{`${ticket.entityType === 0
                    ? translationService.translate("TR_COURSE")
                    : translationService.translate("TR_CONSULTATION")}`}</small>
                </div>
                {
                  ticket.subject ?
                    <div className="ticket-subject">
                      <small className="d-block">
                        {`${translationService.translate("TR_SUBJECT")}:`}
                      </small>
                      <small> {`${ticket.subject}`}</small>
                    </div>
                    : null
                }
                {
                  props.showRefundedField ?
                    ticket.withRefund ?
                      <div className="ticket-subject">
                        <small className="d-block">
                          {`${translationService.translate("TR_REFUND")}:`}
                        </small>
                        <small>
                          {
                            ticket.refunded ? `${translationService.translate("TR_APPROVED")}` : `${translationService.translate("TR_DENIED")}`
                          }
                        </small>
                      </div>
                      : null
                    : null
                }
              </div>
              <hr />
              {
                ticket.description ?
                  <ShowMoreText
                    lines={2}
                    more={translationService.translate("TR_SHOW_MORE")}
                    less={translationService.translate("TR_LESS")}
                    className="content-css mt-3"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    <p>{ticket.description}</p>
                  </ShowMoreText>
                  : null
              }
            </div>
          </div>
        </div> : null
      })

    }
  </Auxiliary> : null
  );
}

export default withRouter(Ticket);