import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import { Link, withRouter } from "react-router-dom";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import MainService from "../../Services/mainService";
import moment from "moment";
import { INFO_KEY, WEBINAR_KEY } from "../../Constants/urlKeys";
import ShowMoreText from "react-show-more-text";
import Auxiliary from "./../../hoc/auxiliary/auxiliary";
import { WEBINAR_CALENDAR_KEY } from "./../../Constants/urlKeys";
import {
	getCurrentQuestionMarkData,
	setBackUrl,
} from "./../../Store/Actions/main";
import NoDataImage from "./../../assets/images/illustrations/nodata.svg";
import HelpService from "./../../Services/helpService";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const WebinarCalendar = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [currentWebinar, setCurrentWebinar] = useState(null);
	const { webinarId } = props.match.params;

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		webinarId && getWebinarById(webinarId);
	}, []);

	const getWebinarById = webinarId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getWebinarbyIdFromOrgUser(+webinarId, user)
			.then(response => {
				response.data && setCurrentWebinar({ ...response.data });
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	const setUrl = () => {
		dispatch(setBackUrl(props.location.pathname));
	};

	const getFail = (error, spinnerId) => {
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	return translationService ? (
		currentWebinar ? (
			<div className="container">
				<div className="row">
					<div className="col-12 mt-3">
						<div className="content-title p-0">
							<div className="d-flex align-items-center">
								<Link
									to={`/${language}/${WEBINAR_KEY}`}
									title={translationService.translate("TR_BACK")}>
									<ArrowBackSvg />
								</Link>
								<h2 className="content-title p-0">{currentWebinar.name}</h2>
							</div>
						</div>
						<PageInfoBlock
							pageTitle={currentWebinar.name}
							pageName="lecturer_student_webinars"
							translationService={translationService}
						/>
					</div>
				</div>
				<hr />
				{currentWebinar.description ? (
					<Auxiliary>
						<ShowMoreText
							lines={2}
							more={translationService.translate("TR_SHOW_MORE")}
							less={translationService.translate("TR_LESS")}
							className="content-css"
							anchorClass="my-anchor-css-class"
							expanded={false}
							truncatedEndingComponent={"... "}>
							<p className="word-break-break-word">
								{currentWebinar.description}
							</p>
						</ShowMoreText>
						<hr />
					</Auxiliary>
				) : null}
				<div className="row">
					{currentWebinar.days &&
						currentWebinar.days.map(currentWebinarDay => {
							return (
								<div
									key={currentWebinarDay.id}
									className="col-lg-6 col-12 my-2">
									<Link
										onClick={setUrl}
										to={`/${language}/${WEBINAR_KEY}/${WEBINAR_CALENDAR_KEY}/${currentWebinarDay.id}/${INFO_KEY}`}
										className="card mindalay--card">
										<div className="card-header">
											{new Date(
												moment(
													MainService.convertUTCDateToLocalDate(
														new Date(currentWebinarDay.startTime),
													),
												).format("YYYY-MM-DD HH:mm"),
											).getTime() > new Date().getTime() ? (
												<p className="mindalay--some-process-status text-info">
													{translationService.translate("TR_UPCOMING")}
												</p>
											) : null}
											{currentWebinarDay.inProcess ? (
												<p className="mindalay--some-process-status green-color">
													{translationService.translate("TR_IN_PROCESS")}
												</p>
											) : null}
											{currentWebinarDay.isFinished ||
											new Date(
												moment(
													MainService.convertUTCDateToLocalDate(
														new Date(currentWebinarDay.endTime),
													),
												).format("YYYY-MM-DD HH:mm"),
											).getTime() < new Date().getTime() ? (
												<p className="mindalay--some-process-status red-color">
													{translationService.translate("TR_FINISHED")}
												</p>
											) : null}
										</div>
										<div className="card-body">
											<div className="mindalay--card-span-group m-0">
												<div className="webinar-start-time">
													<fieldset>
														<legend className="d-block">
															{translationService.translate("TR_START_TIME")}
														</legend>
														<span>
															{moment(
																MainService.convertUTCDateToLocalDate(
																	new Date(currentWebinarDay.startTime),
																),
															).format("YYYY-MM-DD HH:mm")}
														</span>
													</fieldset>
												</div>
												<div className="webinar-end-time">
													<fieldset>
														<legend className="d-block">
															{translationService.translate("TR_END_TIME")}
														</legend>
														<span>
															{moment(
																MainService.convertUTCDateToLocalDate(
																	new Date(currentWebinarDay.endTime),
																),
															).format("YYYY-MM-DD HH:mm")}
														</span>
													</fieldset>
												</div>
											</div>
											{/* <div className="w-100 mt-2">
                                            <Link to="#" className="mindalay--btn-small mindalay--btn-dark w-100 text-center" type="button">
                                                {translationService.translate("TR_VIEW_DETAILS")}
                                            </Link>
                                        </div> */}
										</div>
									</Link>
								</div>
							);
						})}
				</div>
			</div>
		) : (
			<div className="no-data-container mt-5">
				<div className="no-data-wrapper">
					<p className="col-12">{translationService.translate("TR_NO_DATA")}</p>
					<img src={NoDataImage} alt="/" />
				</div>
			</div>
		)
	) : null;
};

export default withRouter(WebinarCalendar);
