import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Collapse from "react-bootstrap/Collapse";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import MultiChoiseSvg from "../../Components/Svg/multiChoiseSvg";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import ShortAnswerSvg from "../../Components/Svg/shortAnswerSvg";
import MatchingSvg from "../../Components/Svg/matchingSvg";
import TrueFalseSvg from "../../Components/Svg/trueFalseSvg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from "../../Constants/translationKeys";
import { ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import {
	COURSES_KEY,
	COURSE_DASHBOARD_KEY,
	CURICULUMN_KEY,
	INSTRUCTOR_KEY,
	ORGANIZATION_KEY,
	QUESTION_BANK_KEY,
	QUESTION_KEY,
} from "../../Constants/urlKeys";
import Parser from "html-react-parser";
import ActionButton from "../../Components/ActionButton/actionButton";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const buttonSpinnerId = uuid();

class QuizView extends Component {
	state = {
		questionsGroups: [],
		questions: [],
		quizData: null,
		currentQuestionsGroupId: null,
		quizId: +this.props.match.params.quizId,
		attachedQuestions: [],
		translationService: null,
		attachQuestionState: false,
		ids: [],
		isChange: false,
		isLoading: false,
	};

	componentDidMount() {
		this.setTranslations();
		this.state.quizId && this.getQuizById(this.state.quizId);
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getQuizById = (quizId, showSpinner = true) => {
		const spinnerId = uuid();
		showSpinner && this.props.addPageSpinner(spinnerId);
		ApiService.getQuizById(quizId)
			.then(response => {
				showSpinner && this.props.removePageSpinner(spinnerId);
				const quizData = { ...response.data };
				this.setState(
					{
						quizData: quizData,
						attachedQuestions: quizData.questions,
						ids: quizData.questions
							? quizData.questions.map(item => item.id)
							: [],
					},
					() => {
						this.getQuestionsGroups();
					},
				);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getQuestionsGroups = () => {
		// if (this.state.attachQuestionState) {
		//   this.setState({ attachQuestionState: false });
		// } else {
		//   ApiService.getQuestionsGroups().then(response => {
		//     const questionsGroups = response.data;
		//     this.setState({ questionsGroups, attachQuestionState: true });
		//   });
		// }
		ApiService.getQuestionsGroups()
			.then(response => {
				const questionsGroups = response.data;
				this.setState({
					questionsGroups,
					attachQuestionState: true,
				});
				if (questionsGroups.length === 1) {
					this.getQuestionsByGroupId(questionsGroups[0].id);
				}
			})
			.catch(error => this.getFail(error));
	};

	getQuestionsByGroupId = (groupId, iscurrent = true) => {
		if (this.state.currentQuestionsGroupId === groupId) {
			this.setState({ currentQuestionsGroupId: null });
		} else {
			ApiService.getQuizQuestionsGroup(this.state.quizId, groupId)
				.then(response => {
					this.setState({
						questions: response.data,
						currentQuestionsGroupId: groupId,
					});
				})
				.catch(error => this.getFail(error));
		}
	};

	toggleQuestionBelongValue = (event, question, isBelong) => {
		const { translationService, quizId, ids } = this.state;

		if (event.target.checked) {
			this.setState(prevState => ({
				...prevState,
				ids: [...prevState.ids, question.id],
				isChange: true,
			}));
		} else {
			this.setState(prevState => ({
				...prevState,
				ids: prevState.ids.filter(item => item !== question.id),
				isChange: true,
			}));
		}
	};

	onSubmit = () => {
		const { translationService, ids, quizId } = this.state;
		const form = {
			courseQuizId: quizId,
			questionIds: ids,
		};
		this.setState({ isChange: false });
		this.props.addButtonSpinner(buttonSpinnerId);
		ApiService.createQuizQuestion(form)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(TR_DATA_SAVED_KEY),
				);
				this.getQuizById(quizId, false);
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			)
			.finally(() => {
				this.props.removeButtonSpinner(buttonSpinnerId);
			});
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
	};

	goBack = () => {
		const { language, history, match } = this.props;
		history.push(
			`/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${CURICULUMN_KEY}`,
		);
	};

	render() {
		const { language, match, user } = this.props;
		const {
			questionsGroups,
			questions,
			currentQuestionsGroupId,
			translationService,
			quizData,
			attachedQuestions,
			ids,
			isChange,
		} = this.state;
		let attachedQuestionsColumns;

		if (translationService) {
			attachedQuestionsColumns = [
				{
					name: translationService.translate("TR_TYPE"),
					selector: "type",
				},
				{
					name: translationService.translate("TR_NAME"),
					selector: "name",
					// sortable: true,
				},
				{
					name: translationService.translate("TR_QUESTION"),
					// selector: 'text',
					selector: "convertedText",
					// sortable: true,
				},
				// {
				//   name: translationService.translate("TR_ACTIONS"),
				//   selector: 'actions',
				//   // sortable: false,
				// },
			];
		}

		if (attachedQuestions) {
			attachedQuestions.forEach(attachedQuestion => {
				attachedQuestion.type = (() => {
					switch (attachedQuestion.questionType) {
						case 0:
							return <MultiChoiseSvg />;
						case 1:
							return <MatchingSvg />;
						case 2:
							return <ShortAnswerSvg />;
						case 3:
							return <TrueFalseSvg />;
						default:
							break;
					}
				})();

				attachedQuestion.convertedText = (() => {
					return (
						<span className="topic-description-block">
							<ParserComponent text={attachedQuestion.text} />
						</span>
					);
				})();
				// attachedQuestion.actions = (() => {
				//   return <Link to="#" onClick={() => this.toggleQuestionBelongValue(attachedQuestion, false)}>
				//     {translationService.translate("TR_REMOVE")}
				//   </Link>
				// })();
			});
		}

		return quizData && translationService ? (
			<div>
				<h2 className="content-title word-break-break-word">
					<Link
						to={
							user.isOrganizationUser
								? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/curriculum`
								: `/${language}/${COURSE_DASHBOARD_KEY}/${match.params.courseId}/${CURICULUMN_KEY}`
						}
						title={translationService.translate("TR_BACK")}>
						<ArrowBackSvg />
					</Link>
					{quizData.name}
				</h2>
				<hr />
				<div className="content-body pt-0 data-table-search-right">
					<div className="content-sub-title">
						<h3>{translationService.translate("TR_ATTACHED_QUESTIONS")}</h3>
					</div>
					{attachedQuestions && attachedQuestions.length ? (
						<DataTableExtensions
							columns={attachedQuestionsColumns}
							data={attachedQuestions}
							export={false}
							print={false}
							filterHidden={false}
							filterPlaceholder={translationService.translate("TR_SEARCH")}>
							<DataTable
								noHeader
								defaultSortField="name"
								defaultSortAsc={false}
								pagination
								highlightOnHover
								paginationComponentOptions={{
									rowsPerPageText:
										translationService.translate("TR_ROWS_PER_PAGE"),
								}}
							/>
						</DataTableExtensions>
					) : null}
					<hr />
					<div className="guestion-grpoup-wrapper--quiz-attache-question toggle-content-container">
						{
							<div className="toggle-content">
								<div className="accordion-wrapper mt-3">
									{questionsGroups && questionsGroups.length ? (
										questionsGroups.map(
											(questionsGroup, questionsGroupIndex) => (
												<div
													key={questionsGroup.id}
													className="card mindalay-card">
													<div
														className="card-header cursor-pointer"
														aria-controls={`questionsGroup_${questionsGroup.id}`}
														aria-expanded={
															currentQuestionsGroupId === questionsGroup.id
														}
														onClick={() =>
															this.getQuestionsByGroupId(questionsGroup.id)
														}>
														<h5 className="mb-0 text-elipsis">
															<span>{questionsGroupIndex + 1}</span>
															{questionsGroup.name}
														</h5>
													</div>
													<Collapse
														in={currentQuestionsGroupId === questionsGroup.id}>
														<div
															id={`questionsGroup_${questionsGroup.id}`}
															className="light-blue-background">
															<div className="card-body">
																{questions.map(question =>
																	question.isBelongToCurrentQuiz ? null : (
																		<div
																			key={question.id}
																			className="card-header custom-card-header overflow-hidden cursor-default">
																			<div className="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					id={`question_${question.id}`}
																					name="question"
																					className="custom-control-input"
																					checked={
																						!!ids.filter(
																							el => el === question.id,
																						).length
																					}
																					onChange={event =>
																						this.toggleQuestionBelongValue(
																							event,
																							question,
																							true,
																						)
																					}
																				/>
																				<label
																					htmlFor={`question_${question.id}`}
																					className="custom-control-label"></label>
																			</div>
																			<div
																				className="mindalay--question-wrapper cursor-pointer"
																				onClick={() =>
																					this.toggleQuestionBelongValue(
																						question,
																						true,
																					)
																				}>
																				<div className="question-type-icon">
																					{(() => {
																						switch (question.questionType) {
																							case 0:
																								return <MultiChoiseSvg />;
																							case 1:
																								return <MatchingSvg />;
																							case 2:
																								return <ShortAnswerSvg />;
																							case 3:
																								return <TrueFalseSvg />;
																							default:
																								break;
																						}
																					})()}
																				</div>
																				{/* <div className="card-body-content w-100 ">
                                        <h5 title={question.name} className="mb-0 text-elipsis">{question.name}</h5>
                                        <p title={question.text} className="max-line-1">{question.text}</p>
                                      </div> */}
																				<div className="card-body-content w-100 ">
																					{question.name ? (
																						<h5
																							title={question.name}
																							className="mb-0 text-elipsis">
																							<ParserComponent
																								text={question.name}
																							/>
																						</h5>
																					) : // <span className="question-dropdown-block">
																					// </span>
																					null}
																					{question.text ? (
																						<span
																							title={question.text}
																							className="max-line-1">
																							<ParserComponent
																								text={question.text}
																							/>
																						</span>
																					) : // <span className="question-dropdown-block">
																					// </span>
																					null}
																					{/* <h5 title={question.name} className="mb-0 text-elipsis">{question.name}</h5>
                                        <p title={question.text} className="max-line-1">{question.text}</p> */}
																				</div>
																			</div>
																		</div>
																	),
																)}
															</div>
														</div>
													</Collapse>
												</div>
											),
										)
									) : (
										<Auxiliary>
											<p>
												{translationService.translate("TR_EMPTY_QUESTIONS_MSG")}
											</p>
											<Link
												to={
													user.isOrganizationUser
														? `/${language}/${QUESTION_KEY}`
														: `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${QUESTION_BANK_KEY}`
												}
												className="btn mindalay--btn-default mt-2">
												{translationService.translate("TR_GO_TO_QUESTIONS")}
											</Link>
										</Auxiliary>
									)}
								</div>
							</div>
						}
					</div>
				</div>
				{questionsGroups && questionsGroups.length ? (
					<div className="content-body">
						<ActionButton
							spinnerId={buttonSpinnerId}
							type="submit"
							disabled={!isChange}
							className="btn mindalay--btn-default float-right mb-4 px-5"
							name={translationService.translate("TR_SAVE")}
							clicked={this.onSubmit}
						/>
					</div>
				) : null}
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
	addButtonSpinner,
	removeButtonSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(QuizView);
