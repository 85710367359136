import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import HelpAlert from "../../../../Components/HelpComponent/helpAlert";
import { setPureLayoutBackUrl } from "../../../../Store/Actions/main";
import { CONSULTANT_USER_TYPE, INSTRUCTOR_USER_TYPE } from "../../../../Constants/mainKeys";
import {
  BECOME_CONSULTANT_KEY,
  BECOME_INSTRUCTOR_KEY,
  CONSULTANT_INFORMATION_KEY,
  CONSULTANT_KEY,
  COURSES_KEY,
  INFO_KEY,
  INSTRUCTOR_KEY,
  LEARN_IN_MINDALAY_KEY,
  MINDALAY_FOR_INSTITUTIONS_KEY,
  PURE_KEY,
  TEACH_IN_MINDALAY_KEY,
  VIEW_KEY
} from "../../../../Constants/urlKeys";
import ReurnUrl from "../../../../Services/returnUrl";

const HeaderActionsBar = (props) => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const setBackUrl = (url) => {
    dispatch(setPureLayoutBackUrl(url))
  }

  const returnUrlWithoutRedirect = (url) => {
    if (!url) { return; }
    ReurnUrl.setUrl(url);
  }

  return translationService && translationService.translations.length ? <div className="header-top-menu">
    <nav className="navbar navbar-expand-md navbar-light d-lg-block d-none">
      <div className="collapse navbar-collapse">
        <div className="navbar-nav justify-content-between w-100 text-center">
          <Link
            to={`/${language}/${LEARN_IN_MINDALAY_KEY}/${INFO_KEY}`}
            className={`nav-item nav-link flex-1 ${props.location.pathname.includes(LEARN_IN_MINDALAY_KEY) ? "active-menu" : ""}`}
          // onClick={() => returnUrlWithoutRedirect(`/${language}`)}
          >
            {translationService.translate("TR_LEARN_IN_MINDALAY")}
          </Link>
          {
            user
              ? (user.userTypeId === INSTRUCTOR_USER_TYPE || user.userTypeId === CONSULTANT_USER_TYPE)
                ? <Link
                  to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`}
                  className={`nav-item nav-link flex-1 ${props.location.pathname.includes(`${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`) ? "active-menu" : ""}`}
                >
                  {translationService.translate("TR_INSTRUCTOR")}
                </Link>
                : <Link
                  to={`/${language}/${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`}
                  className={`nav-item nav-link flex-1 ${props.location.pathname.includes(`${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`) ? "active-menu" : ""}`}
                  onClick={() => setBackUrl(`/${language}`)}
                >
                  {translationService.translate("TR_TEACH_IN_MINDALAY")}
                </Link>
              :
              <Link
                to={`/${language}/${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`}
                className={`nav-item nav-link flex-1 ${props.location.pathname.includes(`${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`) ? "active-menu" : ""}`}
                onClick={() => returnUrlWithoutRedirect(`/${language}/${PURE_KEY}/${BECOME_INSTRUCTOR_KEY}`)}
              >
                {translationService.translate("TR_TEACH_IN_MINDALAY")}
              </Link>
          }
          {
            user ? <Link
              to={
                user.userTypeId === CONSULTANT_USER_TYPE ?
                  `/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}` :
                  // `/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}`
                  `/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`
              }
              title={
                user.userTypeId === CONSULTANT_USER_TYPE && !user.dontDisturb
                  ? translationService.translate("TR_ACTIVE_CONSULTANT_INFO")
                  : user.userTypeId === CONSULTANT_USER_TYPE && user.dontDisturb
                    ? translationService.translate("TR_INACTIVE_CONSULTANT_INFO") : ""
              }
              className={`
              nav-item nav-link flex-1 
              position-relative 
              ${props.location.pathname.includes(`${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}`) ? "active-menu" : ""} 
              ${props.location.pathname.includes(`${BECOME_CONSULTANT_KEY}/${INFO_KEY}`) ? "active-menu" : ""} 
              `}
              onClick={() => setBackUrl(`/${language}`)}
            >
              {
                user.userTypeId === CONSULTANT_USER_TYPE ?
                  translationService.translate("TR_CONSULTANT")
                  : translationService.translate("TR_BECOME_A_CONSULTANT")
              }
              {
                user.userTypeId === CONSULTANT_USER_TYPE && user.dontDisturb ?
                  <p className={`d-inline dont-disturb-point is-passive`} />
                  : null
              }
              {
                user.userTypeId === CONSULTANT_USER_TYPE && !user.dontDisturb ?
                  <p className={`d-inline dont-disturb-point is-active`} />
                  : null
              }
              {/* <HelpAlert helpKey="become-consultant"></HelpAlert> */}
            </Link>
              : <Link
                to={`/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`}
                className={`nav-item nav-link flex-1 ${props.location.pathname.includes(BECOME_CONSULTANT_KEY) ? "active-menu" : ""}`}
                onClick={() => returnUrlWithoutRedirect(`/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}`)}
              >
                {translationService.translate("TR_BECOME_A_CONSULTANT")}
                {/* <HelpAlert helpKey="become-consultant"></HelpAlert> */}
              </Link>
          }
          <Link
            to={`/${language}/${MINDALAY_FOR_INSTITUTIONS_KEY}/${INFO_KEY}`}
            className={`nav-item nav-link flex-1 ${props.location.pathname.includes(MINDALAY_FOR_INSTITUTIONS_KEY) ? "active-menu" : ""}`}
          // onClick={() => returnUrlWithoutRedirect(`/${language}`)}
          >
            {translationService.translate("TR_MINDALAY_FOR_INSTITUTIONS")}
          </Link>
        </div>
      </div>
    </nav>
  </div> : null;
}

export default withRouter(HeaderActionsBar)