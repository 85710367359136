import React from "react";

const matchingSvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="33" height="19" viewBox="0 0 33 19">
    <g transform="translate(-611 -328)">
      <g transform="translate(617.16 336.478)">
        <path d="M143.79,236H125.324a1.062,1.062,0,1,0,0,2.124H143.79a1.062,1.062,0,1,0,0-2.124Z" transform="translate(-124.262 -236)" fill="#2980b9"></path>
      </g>
      <g transform="translate(617.16 328.513)">
        <path d="M143.79,86H125.324a1.062,1.062,0,1,0,0,2.124H143.79a1.062,1.062,0,1,0,0-2.124Z" transform="translate(-124.262 -86)" fill="#2980b9"></path>
      </g>
      <g transform="translate(617.16 344.443)">
        <path d="M143.79,386H125.324a1.062,1.062,0,1,0,0,2.124H143.79a1.062,1.062,0,1,0,0-2.124Z" transform="translate(-124.262 -386)" fill="#2980b9"></path>
      </g>
      <g transform="translate(610.562 328.141)">
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 -0.141)" fill="#2980b9"></circle>
      </g>
      <g transform="translate(640.562 328.141)">
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 -0.141)" fill="#2980b9"></circle>
      </g>
      <g transform="translate(610.562 335.628)">
        <g transform="translate(0 0)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 0.372)" fill="#2980b9">
          </circle>
        </g>
      </g>
      <g transform="translate(640.562 335.628)">
        <g transform="translate(0 0)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 0.372)" fill="#2980b9"></circle>
        </g>
      </g>
      <g transform="translate(610.562 343.864)">
        <g transform="translate(0 0)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 0.136)" fill="#2980b9"></circle>
        </g>
      </g>
      <g transform="translate(640.562 343.864)">
        <g transform="translate(0 0)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 0.136)" fill="#2980b9"></circle>
        </g>
      </g>
    </g>
  </svg>
}

export default matchingSvg;