import { GET_TRANSLATIONS_SUCCESS } from '../../Constants/reduxKeys';

let initialState = {
    translations: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                translations: action.payload
            };
        default:
            break;
    }
    return state;
};

export default reducer;

