import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import * as moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import { withRouter } from "react-router";
import Input from "../../Components/Inputs/input";
import Textarea from "../../Components/Inputs/textArea";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import OrganizationCourseBlock from "../Courses/organizationCourseBlock";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import MainService from "../../Services/mainService";
import ShowMoreText from "react-show-more-text";
import Parser from "html-react-parser";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import {
	ERROR_KEY,
	NUMBER_KEY,
	SUCCESS_KEY,
	WARNING_KEY,
} from "../../Constants/mainKeys";
import Search from "../../Components/Search/search";
import { Link } from "react-router-dom";
import { STUDENT_KEY } from "../../Constants/urlKeys";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import ModalComponentWithoutActionButton from "../../Components/ModalComponent/modalComponentWithoutActionButton";
import HelpService from "../../Services/helpService";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const StudentCourses = props => {
	const dispatch = useDispatch();
	const blockMessageMaxLength = 2000;

	const studentId = props.match.params.studentId || null;
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const currentQuestionMarkData = useSelector(
		state => state.main.currentQuestionMarkData,
	);

	const [translationService, setTranslationService] = useState(null);
	const [studentCourses, setStudentCourses] = useState([]);
	const [courseData, setCourseData] = useState(null);

	const [pauseStartDate, setPauseStartDate] = useState("");
	const [pauseEndDate, setPauseEndDate] = useState("");
	const [blockMessage, setBlockMessage] = useState("");
	const [isInvalidStartDate, setIsInvalidStartDate] = useState(false);
	const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isOpenPauseModal, setIsOpenPauseModal] = useState(false);
	const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
	const [isOpenQuizResultsModal, setIsOpenQuizResultsModal] = useState(null);
	const [courseQuizes, setCourseQuizes] = useState(null);
	const [topicQuizes, setTopicQuizes] = useState(false);
	const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(false);
	const [failedFields, setFailedFields] = useState(null);
	const [filteredCourses, setFilteredCourses] = useState([]);
	const [numberOfDisplayedCourses, setNumberOfDisplayedCourses] = useState(12);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		studentId && getStudentCourses(studentId);
	}, []);

	const getStudentCourses = studentId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.setStudentCourses(studentId)
			.then(response => {
				if (response.data) {
					setStudentCourses(response.data);
					setFilteredCourses(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const setSearchData = (data, searchValue) => {
		let coursesData = !data && !searchValue ? studentCourses : data;
		setFilteredCourses(coursesData);
	};

	const openPauseModal = courseData => {
		if (!courseData) {
			return;
		}
		if (courseData.pauseStartDate)
			setPauseStartDate(
				moment(
					MainService.convertUTCDateToLocalDate(
						new Date(courseData.pauseStartDate),
					),
				).format("YYYY-MM-DDTHH:mm:ss"),
			);
		if (courseData.pauseEndDate)
			setPauseEndDate(
				moment(
					MainService.convertUTCDateToLocalDate(
						new Date(courseData.pauseEndDate),
					),
				).format("YYYY-MM-DDTHH:mm:ss"),
			);
		setCourseData(courseData);
		setIsOpenPauseModal(true);
	};

	const openBlockModal = courseData => {
		if (!courseData) {
			return;
		}
		setCourseData(courseData);
		setIsOpenBlockModal(true);
	};

	const cancel = () => {
		setCourseData(null);
		setIsOpenPauseModal(false);
		setIsDisabledSubmitButton(false);
		setIsOpenBlockModal(false);
		setIsInvalidSubmit(false);
		setIsOpenQuizResultsModal(false);

		setPauseStartDate("");
		setPauseEndDate("");
		setBlockMessage("");
	};

	const checkDateFormatAndIsValid = (date, name, cb) => {
		if (!date || !name) {
			return;
		}
		const isValidDateFormat = MainService.isValidDateTime(date);
		const isValidDate = MainService.validateDate(date);
		if (!isValidDateFormat || !isValidDate) {
			cb(true);
		} else cb(false);
		if (
			pauseStartDate &&
			pauseEndDate &&
			!isInvalidStartDate &&
			!isInvalidEndDate
		) {
			const dateCompaire = MainService.checkDates(pauseStartDate, pauseEndDate);
			if (dateCompaire && isValidDate && isValidDateFormat) {
				cb(false);
			} else cb(true);
		}
	};

	const onDateChange = (event, cb) => {
		const failedFields = removeFailedFields(event.target.name);
		cb(event.target.value);
		setFailedFields(failedFields);
	};

	const onDescriptionChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const failedFields = removeFailedFields(event.target.name);
		setFailedFields(failedFields);
		setBlockMessage(event.target.value);
		setIsInvalidSubmit(false);
	};

	const removeFailedFields = name => {
		const failedFieldsItem = failedFields && { ...failedFields };
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFieldsItem && delete failedFieldsItem[fieldName];
		return failedFieldsItem;
	};

	const onPauseSubmit = event => {
		event && event.preventDefault();
		if (
			isInvalidStartDate ||
			isInvalidEndDate ||
			(!courseData.isPaused && (!pauseStartDate || !pauseEndDate))
		) {
			setIsInvalidSubmit(true);
		} else {
			const spinnerId = uuid();
			dispatch(addModalSpinner(spinnerId));
			setIsDisabledSubmitButton(true);
			const form = {
				pause: !courseData.isPaused,
				studentId: +studentId,
				courseId: courseData.id,
				pauseStartDate: new Date(pauseStartDate),
				pauseEndDate: new Date(pauseEndDate),
			};
			if (!form.pause) {
				for (const i in form) {
					if (i === "pauseStartDate") delete form[i];
					if (i === "pauseEndDate") delete form[i];
				}
			}
			ApiService.onPauseCourseForStudent(form)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					setIsDisabledSubmitButton(false);
					setIsOpenPauseModal(false);
					setIsInvalidSubmit(false);
					setPauseStartDate("");
					setPauseEndDate("");
					getStudentCourses(studentId);
					dispatch(removeModalSpinner(spinnerId));
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const onBlockSubmit = event => {
		event && event.preventDefault();
		if (
			!courseData.isBlocked &&
			(!blockMessage || !blockMessage.trim() || blockMessage.trim().length < 3)
		) {
			setIsInvalidSubmit(true);
		} else {
			const spinnerId = uuid();
			dispatch(addModalSpinner(spinnerId));
			setIsDisabledSubmitButton(true);
			const form = {
				block: !courseData.isBlocked,
				studentId: +studentId,
				courseId: courseData.id,
				blockMessage,
			};
			if (!form.block) {
				for (const i in form) {
					if (i === "blockMessage") delete form[i];
				}
			}
			ApiService.onBlockCourseForStudent(form)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					setIsOpenBlockModal(false);
					setIsDisabledSubmitButton(false);
					setIsInvalidSubmit(false);
					setBlockMessage("");
					getStudentCourses(studentId);
					dispatch(removeModalSpinner(spinnerId));
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const getQuizResults = courseData => {
		if (!courseData || !studentId) {
			return;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getQuizResults(courseData.id, studentId)
			.then(response => {
				if (!response.data.courseQuizes && !response.data.topicQuizes) {
					AlertService.alert(
						WARNING_KEY,
						translationService.translate("TR_QUIZ_RESULTS_IS_EMPTY"),
					);
				} else {
					setCourseQuizes(response.data?.courseQuizes);
					setTopicQuizes(response.data?.topicQuizes);
					setIsOpenQuizResultsModal(true);
					setCourseData(courseData);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		setIsDisabledSubmitButton(false);
		setIsOpenPauseModal(false);
		setIsOpenBlockModal(false);
		setIsInvalidSubmit(false);
		setIsOpenQuizResultsModal(false);
		setPauseStartDate("");
		setPauseEndDate("");
		setBlockMessage("");
	};

	return translationService ? (
		<Auxiliary>
			<div className="container">
				<div className="row">
					<div className="col-12 mt-3">
						<div className="content-title p-0">
							<div className="d-flex align-items-center">
								<Link
									to={`/${language}/student`}
									title={translationService.translate("TR_BACK")}>
									<ArrowBackSvg />
								</Link>
								<h2 className="content-title p-0">
									{translationService.translate("TR_STUDENT_COURSES")}
								</h2>
							</div>
						</div>
						<PageInfoBlock
							pageTitle={translationService.translate("TR_STUDENT_COURSES")}
							pageName="curator_lecturer_selection_committee_student_courses"
							translationService={translationService}
						/>
					</div>
				</div>
				<hr />
				{studentCourses && studentCourses.length ? (
					<Auxiliary>
						<div className="row">
							<div className="col-12">
								<div className="d-lg-flex d-block mb-4 mr-lg-2">
									<Search
										searchData={studentCourses}
										searchFieldName="name"
										emitSearchedData={(data, searchContent) =>
											setSearchData(data, searchContent)
										}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							{filteredCourses.map((course, index) => {
								return numberOfDisplayedCourses > index ? (
									<div
										key={course.id}
										className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
										<OrganizationCourseBlock
											isShowCommunications={false}
											course={course}
											openPauseModal={openPauseModal}
											openBlockModal={openBlockModal}
											getQuizResults={getQuizResults}
										/>
									</div>
								) : null;
							})}
						</div>
						{filteredCourses &&
						filteredCourses.length > numberOfDisplayedCourses ? (
							<button
								type="button"
								className="mindalay--btn-dark w-100 mb-3"
								onClick={() =>
									setNumberOfDisplayedCourses(numberOfDisplayedCourses + 12)
								}>
								{translationService.translate("TR_SHOW_MORE")}
							</button>
						) : null}
					</Auxiliary>
				) : (
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>
								{translationService.translate("TR_NO_COURSES_FOR_ORG_STUDENT")}
							</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>

			{isOpenPauseModal ? (
				<ModalComponent
					title={
						courseData.isPaused
							? translationService.translate("TR_CANCEL_PAUSE")
							: translationService.translate("TR_PAUSE")
					}
					isLarge={true}
					cancel={cancel}
					contentClassName="library-modal"
					aria-labelledby="example-modal-sizes-title-lg"
					actionButtonTitle={
						courseData.isPaused
							? translationService.translate("TR_CANCEL_PAUSE")
							: translationService.translate("TR_PAUSE")
					}
					onSubmit={onPauseSubmit}
					addButtonIsDisabled={isDisabledSubmitButton}>
					<form onSubmit={onPauseSubmit}>
						<div className="row p-3">
							<div className="col-12">
								<Auxiliary>
									{
										<div className="row">
											<div className="col-12 col-lg-6 ">
												<Input
													type="datetime-local"
													id="pauseStartDate"
													name="pauseStartDate"
													value={pauseStartDate ? pauseStartDate : ""}
													isInvalidField={isInvalidSubmit && !pauseStartDate}
													disabled={courseData.isPaused}
													inputClassName={
														isInvalidStartDate
															? "is-invalid error-bordered"
															: ""
													}
													labelValue={translationService.translate(
														"TR_PAUSE_START_DATE",
													)}
													onChange={event =>
														onDateChange(event, setPauseStartDate)
													}
													onBlur={() =>
														checkDateFormatAndIsValid(
															pauseStartDate,
															"pauseStartDate",
															setIsInvalidStartDate,
														)
													}
													failedFields={failedFields}
												/>
												{isInvalidStartDate ? (
													<small className="red-color">
														{translationService.translate("TR_INVALID_DATES")}
													</small>
												) : null}
											</div>
											<div className="col-12 col-lg-6">
												<Input
													id="pauseEndDate"
													name="pauseEndDate"
													type="datetime-local"
													value={pauseEndDate ? pauseEndDate : ""}
													isInvalidField={isInvalidSubmit && !pauseEndDate}
													disabled={courseData.isPaused}
													inputClassName={
														isInvalidEndDate ? "is-invalid error-bordered" : ""
													}
													labelValue={translationService.translate(
														"TR_PAUSE_END_DATE",
													)}
													onChange={event =>
														onDateChange(event, setPauseEndDate)
													}
													onBlur={() =>
														checkDateFormatAndIsValid(
															pauseEndDate,
															"pauseEndDate",
															setIsInvalidEndDate,
														)
													}
													failedFields={failedFields}
												/>
												{isInvalidEndDate ? (
													<small className="red-color">
														{translationService.translate("TR_INVALID_DATES")}
													</small>
												) : null}
											</div>
											<div className="col-12"></div>
										</div>
									}
								</Auxiliary>
							</div>
						</div>
					</form>
				</ModalComponent>
			) : null}
			{isOpenBlockModal ? (
				<ModalComponent
					title={
						courseData.isBlocked
							? translationService.translate("TR_UNBLOCK")
							: translationService.translate("TR_BLOCK")
					}
					isLarge={true}
					cancel={cancel}
					contentClassName="library-modal"
					aria-labelledby="example-modal-sizes-title-lg"
					actionButtonTitle={
						courseData.isBlocked
							? translationService.translate("TR_UNBLOCK")
							: translationService.translate("TR_BLOCK")
					}
					onSubmit={onBlockSubmit}
					addButtonIsDisabled={isDisabledSubmitButton}>
					<form onSubmit={onBlockSubmit}>
						<div className="row p-3">
							<div className="col-12">
								<Auxiliary>
									{!courseData.isBlocked ? (
										<div className="row">
											<div className="col-12">
												<Textarea
													id="blockMessage"
													name="blockMessage"
													min="0"
													rows="6"
													textAreaClassName="pr--5"
													blockClassName="mb-1"
													isInvalidField={
														isInvalidSubmit &&
														(!blockMessage ||
															!blockMessage.trim() ||
															!blockMessage.trim().length < 3)
													}
													max={blockMessageMaxLength}
													value={blockMessage}
													labelValue={translationService.translate(
														"TR_BLOCK_MESSAGE",
													)}
													onChange={event =>
														onDescriptionChange(event, blockMessageMaxLength)
													}
													failedFields={failedFields}
												/>
												<small className="mb-3 d-block">
													<i>{translationService.translate("TR_MIN_SYMBOL")}</i>
												</small>
											</div>
										</div>
									) : (
										<div className="course-view-description">
											<div>
												{courseData.blockMessage ? (
													<ShowMoreText
														lines={2}
														more={translationService.translate("TR_SHOW_MORE")}
														less={translationService.translate("TR_LESS")}
														className="content-css"
														anchorClass="my-anchor-css-class"
														expanded={false}
														truncatedEndingComponent={"... "}>
														<ParserComponent text={courseData.blockMessage} />
													</ShowMoreText>
												) : null}
											</div>
										</div>
									)}
								</Auxiliary>
							</div>
						</div>
					</form>
				</ModalComponent>
			) : null}

			{isOpenQuizResultsModal && courseData ? (
				<ModalComponentWithoutActionButton
					title={courseData.name ? courseData.name : ""}
					cancel={cancel}
					isLarge={true}
					modalSize="xl">
					<div className="row">
						<div className="col-12">
							<div className="quiz-results-block">
								{courseQuizes && courseQuizes.length ? (
									<div className="org-student-info-block-item">
										<div className="d-flex justify-content-between mb-2">
											<h5>{`${translationService.translate(
												"TR_COURSE_QUIZ",
											)}`}</h5>
										</div>
										<div className="mindayal-admin-table table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th className="text-nowrap">
															{translationService.translate("TR_QUIZ_NAME")}
														</th>
														<th className="text-nowrap">
															{translationService.translate(
																"TR_GRADING_METHOD_NAME",
															)}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_MAX_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_FINAL_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_MAX_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_PASS_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_FINAL_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_ACTION")}
														</th>
													</tr>
												</thead>
												<tbody>
													{courseQuizes?.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item.quizName}</td>
																<td>{item.gradingMethodName}</td>
																<td>{item.maxMark}</td>
																<td>{item.finalMark}</td>
																<td>{item.maxGrade}</td>
																<td>{item.passGrade}</td>
																<td>{item.finalGrade}</td>
																<td>
																	<Link
																		to={`/${language}/student/${studentId}/quiz/${item.quizId}`}
																		className="mindalay--btn-dark mindalay--btn-link mindalay--btn-small">
																		{translationService.translate(
																			"TR_VIEW_ATTEMPTS",
																		)}
																	</Link>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								) : null}
								{topicQuizes && topicQuizes?.length ? (
									<div className="org-student-info-block-item">
										<div className="d-flex justify-content-between mb-2">
											<h5>{`${translationService.translate(
												"TR_TOPIC_QUIZ",
											)}`}</h5>
										</div>
										<div className="mindayal-admin-table table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th className="text-nowrap">
															{translationService.translate("TR_TOPIC_NAME")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_QUIZ_NAME")}
														</th>
														<th className="text-nowrap">
															{translationService.translate(
																"TR_GRADING_METHOD_NAME",
															)}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_MAX_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_FINAL_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_MAX_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_PASS_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_FINAL_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_ACTION")}
														</th>
													</tr>
												</thead>
												<tbody>
													{topicQuizes.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item.topicName}</td>
																<td>{item.quizName}</td>
																<td>{item.gradingMethodName}</td>
																<td>{item.maxMark}</td>
																<td>{item.finalMark}</td>
																<td>{item.maxGrade}</td>
																<td>{item.passGrade}</td>
																<td>{item.finalGrade}</td>
																<td>
																	<Link
																		to={`/${language}/student/${studentId}/quiz/${item.quizId}`}
																		className="mindalay--btn-dark mindalay--btn-link mindalay--btn-small">
																		{translationService.translate(
																			"TR_VIEW_ATTEMPTS",
																		)}
																	</Link>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</ModalComponentWithoutActionButton>
			) : null}
		</Auxiliary>
	) : null;
};

export default withRouter(StudentCourses);
