import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../../Services/translationService";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	NUMBER_KEY,
	STUDENT_USER_TYPE,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../../Constants/mainKeys";
import uuid from "react-uuid";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import Input from "../../Components/Inputs/input";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ActionButton from "../../Components/ActionButton/actionButton";
import UploadButton from "../../Components/UploadButton/uploadButton";
import CloseSvg from "../../Components/Svg/closeSvg";
import MainService from "../../Services/mainService";
import { data } from "jquery";
import moment from "moment";
import { STUDENT_KEY } from "../../Constants/requestKeys";
import SmallQuestionMark from "../../Components/SmallQuestionMark/smallQuestionMark";
import HelpService from "../../Services/helpService";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";

const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

const buttonSpinnerId = uuid();

function StudentForm(props) {
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { userId } = props.match.params;
	const dispatch = useDispatch();
	const [values, setValues] = useState({
		firstname: "",
		lastname: "",
		middlename: "",
		email: "",
		phone: "",
		dob: "",
		image: "",
		organizationId: "",
		tziidDisplayname: "",
		sendEmail: true,
		sendNotification: true,
		username: "",
		mobilePhone: "",
		addressResidence: "",
		addressRegistration: "",
		defaultLanguageId: null,
		password: "",
		personalDocumentNumber: "",
		userTypeId: STUDENT_USER_TYPE,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(null);
	const [disabledFields, setDisabledFields] = useState(false);
	const [timeZones, setTimeZones] = useState([]);
	const usernameDebounce = useDebounce(values.username, 1000);
	const phoneDebounce = useDebounce(values.phone, 1000);
	const emailDebounce = useDebounce(values.email, 1000);
	const mobilePhoneDebounce = useDebounce(values.username, 1000);
	const [uploadedImage, setUploadedImage] = useState(null);
	let emailRegex =
		/^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()\.,;\s@"]+\.{0,1})+[^<>()\.,;:\s@"]{2,})$/;

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getTimeZone();
		// getUser();
	}, []);

	useEffect(() => {
		if (
			!userId &&
			(usernameDebounce ||
				phoneDebounce ||
				emailDebounce ||
				mobilePhoneDebounce)
		) {
			getUsers();
		}
	}, [
		userId,
		usernameDebounce,
		phoneDebounce,
		emailDebounce,
		mobilePhoneDebounce,
	]);

	const getTimeZone = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getTimeZone()
			.then(response => {
				if (
					response &&
					response.data &&
					response.data.timezones &&
					response.data.timezones.length
				) {
					setTimeZones(response.data.timezones);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getUsers = () => {
		ApiService.filterUsers({
			email: values.email,
			mobilePhone: values.mobilePhone,
			phone: values.phone,
			username: values.username,
			isStudent: true,
		})
			.then(response => {
				if (response && response.data && response.data.length) {
					setUsers(response.data);
					scrolToUsers();
				} else {
					setUsers([]);
				}
			})
			.catch();
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		let regex = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/u;
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		if (
			(fieldname === "firstname" || fieldname === "lastname") &&
			event.target.value.length &&
			!regex.test(event.target.value)
		) {
			return;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const uploadImage = event => {
		setIsInvalidSubmit(false);
		const file = event.target.files[0];
		mainService
			.readFile(file, VALID_IMAGE_TYPES_KEY)
			.then(image => {
				if (!image) {
					return;
				}
				setUploadedImage(image);
				setValues(values => ({ ...values, image: file }));
			})
			.catch(error => {
				AlertService.alert(
					WARNING_KEY,
					translationService.translate("TR_VALID_IMAGE_ERROR_MSG"),
				);
			});
	};

	const removeImg = () => {
		setIsInvalidSubmit(false);
		setUploadedImage(null);

		setValues(values => ({ ...values, image: null }));
	};

	const onNumberChange = (event, fieldname, maxValue = Infinity) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsInvalidSubmit(false);
		const phonePattern = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
		const mobilePhonePattern = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
		if (
			(fieldname === "phone" && !phonePattern.test()) ||
			(fieldname === "mobilePhone" && !mobilePhonePattern.test())
		) {
			return false;
		}
		if (event.target.value === "" || Number.isInteger(+event.target.value)) {
			setValues(values => ({ ...values, [fieldname]: event.target.value }));
		}
	};

	const onSelectOptionChange = (item, key) => {
		if (!item) return null;
		setIsInvalidSubmit(false);
		setValues(values => ({ ...values, [key]: item.value }));
	};

	const generateRandomPassword = length => {
		setIsInvalidSubmit(false);
		const charset =
			"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		let password = "";
		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * charset.length);
			password += charset[randomIndex];
		}
		return password;
	};
	const selecctUser = user => {
		if (!user) return false;
		setUser(user);
		const newValues = {};
		Object.entries(values).forEach(([key, value]) => {
			if (key === "password") {
			} else if (key === "image") {
				newValues[key] = user.image || user.avatarFilePath;
			} else {
				newValues[key] = user[key] ? user[key] : "";
			}
		});
		newValues.userId = user.id;
		setValues(newValues);
		if (user && (user.image || user.avatarFilePath)) {
			setUploadedImage(user.image || user.avatarFilePath);
		}
		setDisabledFields(true);
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	const scrolToUsers = () => {
		setTimeout(() => {
			let firstInvalidControl = document.getElementById("users");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		if (
			(values.email.trim().length && !emailRegex.test(values.email)) ||
			!values.lastname.trim().length ||
			!values.firstname.trim().length ||
			!values.personalDocumentNumber.trim().length ||
			!values.email.trim().length
		) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return;
		}
		// password: atob(data.password),
		dispatch(addButtonSpinner(buttonSpinnerId));
		const formData = new FormData();
		Object.entries(values).forEach(([key, value]) => {
			if (value) {
				if (key === "password") {
					formData.append(key, btoa(value));
				} else {
					formData.append(key, value);
				}
			}
		});
		// if (user) {
		// 	formData.append("id", user.id);
		// }
		ApiService.createStudent(formData)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setIsInvalidSubmit(false);
				props.history.push(`/${language}/students`);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	return (
		translationService && (
			<div className="container">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<Link
								to={`/${language}/users`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{!userId
									? translationService.translate("TR_CREATE_STUDENT")
									: translationService.translate("TR_UPDATE_STUDENT")}
							</h2>
						</div>
					</div>
				</div>
				<hr />
				{users && !!users.length && (
					<Auxiliary>
						<div className="row" id="users">
							<div className="col-12">
								<div className="border p-3 rounded">
									<label>
										{translationService.translate("TR_SELECTEXISTINGUSER")}
									</label>
									<div className="d-flex flex-wrap">
										{users.map((item, index) => (
											<div key={index} className="ml-2">
												<button
													className="btn mindalay--btn-default w-100 mt-2 ml-2 d-flex"
													onClick={() => {
														selecctUser(item);
													}}>
													{item.avatarFilePath && (
														<div
															className="navbar-profile-image background-image-cover background-image mr-2"
															style={{
																backgroundImage: `url(${item.avatarFilePath})`,
																cursor: "pointer",
																backgroundColor:
																	"var(--mindalay--secondary-color)",
																border:
																	"2px solid var(--mindalay--white-color)",
																boxSizing: "content-box",
																width: "20px",
																height: "20px",
																borderRadius: "50%",
																flex: "none",
															}}></div>
													)}
													{item.fullName}
												</button>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						<hr className="mx-2" />
					</Auxiliary>
				)}

				<form className="row" onSubmit={onSubmit}>
					<div className="col-12">
						<div className="row">
							<div className="col-12 col-md-4 mt-2">
								<div className="form-group">
									<label>{translationService.translate("TR_TIME_ZONE")}</label>
									<ReactSelectOption
										value={values.tziidDisplayname}
										isSearchable={true}
										selectedValue={(() => {
											const selectedItem = {
												...timeZones.find(
													data => data.displayName === values.tziidDisplayname,
												),
											};
											if (Object.keys(selectedItem).length) {
												selectedItem.label = selectedItem.name;
												selectedItem.value = selectedItem.displayName;
												return selectedItem;
											} else {
												return {
													value: null,
													label: translationService.translate("TR_CHOOSE"),
												};
											}
										})()}
										items={timeZones
											.filter(item => item.id !== values.tziidDisplayname)
											.map(data => ({
												...data,
												label: data.name,
												value: data.displayName,
											}))}
										onChange={item =>
											onSelectOptionChange(item, "tziidDisplayname")
										}
									/>
								</div>
							</div>
							<div className="col-12 col-md-4 mt-2">
								<div className="form-group">
									<label>{translationService.translate("TR_LANGUAGES")}</label>
									<ReactSelectOption
										value={values.defaultLanguageId}
										isSearchable={true}
										selectedValue={(() => {
											const selectedItem = {
												...languages.find(
													data => data.id === values.defaultLanguageId,
												),
											};
											if (Object.keys(selectedItem).length) {
												selectedItem.label = selectedItem.name;
												selectedItem.value = selectedItem.id;
												return selectedItem;
											} else {
												return {
													value: null,
													label: translationService.translate("TR_CHOOSE"),
												};
											}
										})()}
										items={languages
											.filter(item => item.id !== values.defaultLanguageId)
											.map(data => ({
												...data,
												label: data.name,
												value: data.id,
											}))}
										onChange={item =>
											onSelectOptionChange(item, "defaultLanguageId")
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div className="row mt-2">
							<div className="col-lg-4 col-md-6 col-12 mb-2">
								<div className="form-group position-relative mb-0">
									<div className="course-media-wrapper">
										{uploadedImage && (
											<div className="upload-file-content">
												<div
													className="img"
													style={{
														backgroundImage: `url(${
															uploadedImage ? uploadedImage : ""
														})`,
													}}
													alt="course-image"></div>
												{uploadedImage ? (
													<div onClick={() => removeImg()}>
														<CloseSvg />
													</div>
												) : null}
											</div>
										)}
									</div>
									<UploadButton
										textInputClasses="pl-100"
										text={translationService.translate("TR_UPLOAD_FILE")}
										clicked={uploadImage}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-4">
						<Input
							type="text"
							id="personalDocumentNumber"
							name="personalDocumentNumber"
							inputClassName="pr--5"
							value={values.personalDocumentNumber}
							fieldLength={64}
							isInvalidSubmit={
								isInvalidSubmit && !values.personalDocumentNumber.trim().length
							}
							labelValue={`${translationService.translate(
								"TR_DOCUMENT_NUMBER",
							)}*`}
							onChange={event => onChange(event, "personalDocumentNumber", 100)}
						/>
					</div>
					{/* userId && user && user.userTypeName === STUDENT_KEY ? "4" : */}
					<div className={`col-12 col-md-6 col-lg-4 my-1`}>
						<Input
							type="text"
							id="username"
							name="username"
							inputClassName="pr--5"
							value={values.username}
							disabled={disabledFields}
							fieldLength={100}
							// isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={`${translationService.translate("TR_USER_NAME")}*`}
							onChange={event => onChange(event, "username", 100)}
						/>
					</div>
					<div className={`col-12 col-md-6 col-lg-4 my-1`}>
						<div className="position-relative">
							<Input
								type="text"
								id="password"
								name="password"
								inputClassName="pr--5"
								value={values.password}
								disabled={disabledFields}
								// fieldLength={fieldsLength.nameMaxLength}
								// isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
								labelValue={`${translationService.translate("TR_PASSWORD")}*`}
								onChange={event => onChange(event, "password")}
							/>
							<button
								className="btn mindalay--btn-dark"
								style={{ position: "absolute", bottom: "6%", right: "6px" }}
								type="buton"
								disabled={disabledFields}
								onClick={e => {
									e.preventDefault();
									setIsInvalidSubmit(false);
									setValues(values => ({
										...values,
										password: generateRandomPassword(9),
									}));
								}}>
								{translationService.translate("TR_GENERATE_PASSWORD")}
							</button>
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="firstname"
							name="firstname"
							inputClassName="pr--5"
							value={values.firstname}
							fieldLength={100}
							isInvalidSubmit={
								isInvalidSubmit && !values.firstname.trim().length
							}
							labelValue={`${translationService.translate("TR_FIRSTNAME")}*`}
							onChange={event => onChange(event, "firstname", 100)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="lastname"
							name="lastname"
							inputClassName="pr--5"
							value={values.lastname}
							fieldLength={100}
							isInvalidSubmit={
								isInvalidSubmit && !values.lastname.trim().length
							}
							labelValue={`${translationService.translate("TR_LASTNAME")}*`}
							onChange={event => onChange(event, "lastname", 100)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="middlename"
							name="middlename"
							inputClassName="pr--5"
							value={values.middlename}
							fieldLength={100}
							// isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={translationService.translate("TR_MIDDLENAME")}
							onChange={event => onChange(event, "middlename", 100)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="email"
							name="email"
							inputClassName="pr--5"
							value={values.email}
							fieldLength={128}
							isInvalidSubmit={
								isInvalidSubmit &&
								(!values.email.trim().length || !emailRegex.test(values.email))
							}
							labelValue={`${translationService.translate("TR_EMAIL")}*`}
							onChange={event => onChange(event, "email", 128)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="date"
							id="dob"
							name="dob"
							value={moment(new Date(values.dob)).format("YYYY-MM-DD")}
							// isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={translationService.translate("TR_DOB")}
							onChange={event => onChange(event, "date")}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="phone"
							name="phone"
							inputClassName="pr--5"
							value={values.phone}
							fieldLength={32}
							// isInvalidSubmit={isInvalidSubmit && !values.phone.trim().length}
							labelValue={translationService.translate("TR_PHONE")}
							onChange={event => onNumberChange(event, "phone", 32)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="mobilePhone"
							name="mobilePhone"
							inputClassName="pr--5"
							value={values.mobilePhone}
							fieldLength={32}
							// isInvalidSubmit={
							// 	isInvalidSubmit && !values.mobilePhone.trim().length
							// }
							labelValue={translationService.translate("TR_MOBILEPHONE")}
							onChange={event => onNumberChange(event, "mobilePhone", 32)}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="addressResidence"
							name="addressResidence"
							inputClassName="pr--5"
							value={values.addressResidence}
							// fieldLength={fieldsLength.nameMaxLength}
							// isInvalidSubmit={
							// 	isInvalidSubmit && !values.addressResidence.trim().length
							// }
							labelValue={translationService.translate("TR_ADDRESSRESIDENCE")}
							onChange={event => onChange(event, "addressResidence")}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<Input
							type="text"
							id="addressRegistration"
							name="addressRegistration"
							inputClassName="pr--5"
							value={values.addressRegistration}
							// fieldLength={fieldsLength.nameMaxLength}
							// isInvalidSubmit={
							// 	isInvalidSubmit && !values.addressRegistration.trim().length
							// }
							labelValue={translationService.translate(
								"TR_ADDRESSREGISTRATION",
							)}
							onChange={event => onChange(event, "addressRegistration")}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<div>
							<label htmlFor="sendEmail">
								{translationService.translate("TR_SEND_EMAIL")}
							</label>
							<SmallQuestionMark
								blockClassName="ml-1"
								onMouseEnter={() => getQuestionMarkData("send_email")}
							/>
						</div>
						<InputCheckBox
							id={`sendEmail`}
							name={`sendEmail`}
							checked={values.sendEmail}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								setValues({
									...values,
									sendEmail: event.target.checked,
								});
							}}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<div>
							<label htmlFor="sendNotification">
								{translationService.translate("TR_SEND_NOTIFICATION")}
							</label>
							<SmallQuestionMark
								blockClassName="ml-1"
								onMouseEnter={() => getQuestionMarkData("send_notification")}
							/>
						</div>
						<InputCheckBox
							id={`sendNotification`}
							name={`sendNotification`}
							checked={values.sendNotification}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								setValues({
									...values,
									sendNotification: event.target.checked,
								});
							}}
						/>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div>
							<ActionButton
								spinnerId={buttonSpinnerId}
								clicked={onSubmit}
								type="submit"
								className="mindalay--btn-default float-right mb-4 px-5"
								name={translationService.translate("TR_SAVE")}
							/>
						</div>
					</div>
				</form>
			</div>
		)
	);
}
export default withRouter(StudentForm);
