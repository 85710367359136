import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import ModalComponent from "../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";
import Textarea from "../Components/Inputs/textArea";

function DocumentCategory(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [documentCategory, setDocumentCategory] = useState([]);
	const [values, setValues] = useState({
		description: "",
		name: "",
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDocumentCategory();
	}, []);

	const getDocumentCategory = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentCategory()
			.then(response => {
				if (response && response.data) {
					setDocumentCategory(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteDocumentCategory = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteDocumentCategory(item.id)
				.then(() => {
					setDocumentCategory(
						documentCategory.filter(_item => _item.id !== item.id),
					);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	const onClose = (close = true) => {
		setValues({
			name: "",
			description: "",
		});
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
	};

	const getModalData = item => {
		setOpenModal(true);
		if (item) setValues(item);
	};

	const onSave = () => {
		const spinerID = uuid();
		if (!values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createDocumentCategory(values)
			: ApiService.updateDocumentCategory(values)
		)
			.then(response => {
				if (response.data) {
					setDocumentCategory([...documentCategory, response.data]);
				} else if (values.id) {
					setDocumentCategory(
						documentCategory.map(item => {
							if (item.id === values.id) {
								return {
									...item,
									...values,
								};
							}
							return item;
						}),
					);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave();
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<Input
								type="text"
								id="name"
								name="name"
								inputClassName="pr--5"
								value={values.name}
								fieldLength={40}
								isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
								labelValue="Name*"
								onChange={event => onChange(event, "name", 40)}
							/>
						</div>
						<div className="col-12">
							<Textarea
								rows="4"
								id="description"
								name="description"
								value={values.description}
								labelValue={`${translationService.translate(
									"TR_WL_DESCRIPTION",
								)}`}
								placeholder={`${translationService.translate("TR_REASON")}...`}
								onChange={event => onChange(event, "description", 40)}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_DOCUMENT_CATEGORY")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default"
						onClick={() => {
							getModalData();
						}}>
						Create {translationService.translate("TR_DOCUMENT_CATEGORY")}
					</button>
				</div>
			</div>
			{documentCategory && documentCategory.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_WL_DESCRIPTION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{documentCategory.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.description}
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={"#"}
																title={translationService.translate("TR_EDIT")}
																onClick={e => {
																	e.stopPropagation();
																	getModalData(item);
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															<Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deleteDocumentCategory(item);
																}}>
																<DeleteSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
}

export default withRouter(DocumentCategory);
