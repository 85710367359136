import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Courses from "../Pages/Courses/courses";
import FileLibrary from "../Pages/FileLibrary/fileLibrary";
import FileLibraryForm from "../Pages/FileLibrary/fileLibraryForm";
import FileLibraryCategoryForm from "../Pages/FileLibrary/fileLibraryCategoryForm";
import fileLibraryCategoryView from "../Pages/FileLibrary/fileLibraryCategoryView";
import Questions from "../Pages/Questions/questions";
import QuestionForm from "../Pages/Questions/questionForm";
import { COMUNICATION_KEY, COURSES_KEY, INSTRUCTOR_KEY, PROFILE_KEY, VIEW_KEY } from "../Constants/urlKeys";
import Overview from "../Pages/Overview/overview";
import Finances from "../Pages/Overview/finances";
import NotFoundPage from "../Pages/404/notFoundPage";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";

const InstructorDashboardRoutes = () => {

  const language = useSelector(state => state.language.language);

  return <Switch>
    {/* Courses */}
    <Route path="/:language/consultant/courses/view" exact component={Courses} />
    <Route path="/:language/instructor/courses/view" exact component={Courses} />

    {/* Questions table, form */}
    <Route path="/:language/instructor/courses/question-bank/form/:questionType" exact component={QuestionForm} />
    <Route path="/:language/instructor/courses/question-bank/form/:questionType/:questionId" exact component={QuestionForm} />
    <Route path="/:language/instructor/courses/question-bank" exact component={Questions} />

    {/* File library table, form */}
    <Route path="/:language/instructor/courses/file-library/form/:id" exact component={FileLibraryForm} />
    <Route path="/:language/instructor/courses/file-library/form" exact component={FileLibraryForm} />
    <Route path="/:language/instructor/courses/file-library" exact component={FileLibrary} />

    {/* File library categories table, form */}
    <Route path="/:language/instructor/courses/filelibrarycategory/form/:id" exact component={FileLibraryCategoryForm} />
    <Route path="/:language/instructor/courses/filelibrarycategory/form" exact component={FileLibraryCategoryForm} />
    <Route path="/:language/instructor/courses/filelibrarycategory" exact component={fileLibraryCategoryView} />

    {/* Default route for courses menu item */}
    <Route path="/:language/instructor/courses" exact render={() => {
      return <Redirect to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`} />
    }} />

    {/* Finances Overview */}
    <Route path="/:language/instructor/finances" exact component={Finances} />
    <Route path="/:language/instructor/finances/paypalsuccess/returnurl" exact component={Finances} />
    <Route path="/:language/instructor/finances/paypalcancel/returnurl" exact component={Finances} />
    <Route path="/:language/instructor/finances/cryllexsuccess/returnurl" exact component={Finances} />
    <Route path="/:language/instructor/finances/cryllexcancel/returnurl" exact component={Finances} />
    <Route path="/:language/instructor/finances/stripesuccess/returnurl" exact component={Finances} />
    <Route path="/:language/instructor/finances/stripecancel/returnurl" exact component={Finances} />
    <Route path="/:language/instructor/finances/overview" exact component={Overview} />

    {/* AuthenticationByRefreshToken */}
    <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />

    {/* Server error page */}
    <Route path="/:language/under-maintenance" exact component={UnderMaintenancePage} />

    {/* Default route for comunication menu item */}
    <Route path="/:language/instructor/comunication" exact render={() => {
      return <Redirect to={`/${language}/${INSTRUCTOR_KEY}/${COMUNICATION_KEY}/${PROFILE_KEY}`} />
    }} />

    <Route component={NotFoundPage} />
  </Switch>;
}

export default InstructorDashboardRoutes;
