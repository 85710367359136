import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import ActionButton from "../../Components/ActionButton/actionButton";
import Input from "../../Components/Inputs/input";
import Paypal from "../../Components/Svg/PaymentSvg/paypal";
import Skrill from "../../Components/Svg/PaymentSvg/skrill";
import StripeIcon from "../../Components/Svg/PaymentSvg/stripe";
import WebMoney from "../../Components/Svg/PaymentSvg/webMoney";
import YandexMoney from "../../Components/Svg/PaymentSvg/yandexMoney";
import Cryllex from "./../../Components/Svg/PaymentSvg/cryllex";
import {
	CANCEL_KEY,
	CONSULTANT_KEY,
	STUDENT_KEY,
} from "../../Constants/requestKeys";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import {
	FINANCES_KEY,
	INSTRUCTOR_KEY,
	ORGANIZATION_ADMIN_KEY,
	OVERVIEW_KEY,
} from "../../Constants/urlKeys";
import { Link, withRouter } from "react-router-dom";
import {
	STUDENT_USER_TYPE,
	INSTRUCTOR_USER_TYPE,
	CONSULTANT_USER_TYPE,
	CRYLLEX_SUCCESS_KEY,
	CRYLLEX_CANCEL_KEY,
	REDIRECT_URL_KEY,
} from "./../../Constants/mainKeys";
import CloseSvg from "../../Components/Svg/closeSvg";
import { compose } from "redux";
import MainService from "../../Services/mainService";
// import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
// import InfoSvg from '../../Components/Svg/infoSvg';
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	addModalSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import {
	ERROR_KEY,
	FILL_BALANCE_KEY,
	NUMBER_KEY,
	PAYPAL_CANCEL_KEY,
	PAYPAL_SUCCESS_KEY,
	RETURN_URL_KEY,
	SUCCESS_KEY,
	STRIPE_SUCCESS_KEY,
	STRIPE_CANCEL_KEY,
	CURRENCY_KEY,
	DEFAULT_CURRENCY_KEY,
} from "../../Constants/mainKeys";

class Finances extends Component {
	_isMounted = false;

	state = {
		translationService: null,
		fillAmount: "",
		payOutAmount: "",
		email: "",
		fillSpinnerId: uuid(),
		payOutSpinnerId: uuid(),
		isInvalidFillSubmit: false,
		isInvalidPayOutSubmit: false,
		paymentFillSystemId: null,
		paymentPayOutSystemId: null,
		paymentSystems: [],
		balance: null,
		clientSecret: null,
		isShowStripeCard: false,
		isShowTransactionModal: false,
		payPalSuccessData: null,
		payPalCancelData: null,
		paymentResponseMessage: "",
		options: null,
		returtnUrl: null,
		stripeSuccessData: false,
		stripeCancelData: false,
		isInvalidEmail: false,
		emailMaxLength: 120,
		currencies: [],
		currencyId: null,
		isShowPasswordModal: false,
		password: "",
		isInvalidPasswordSubmit: false,
		minDeposit: 0,
	};

	componentDidMount() {
		this._isMounted = true;
		this.setTranslations();
		this.getPaymentSystems();
		// this.getCurrencies();
		if (
			!this.props.location.pathname.includes(PAYPAL_SUCCESS_KEY) &&
			!this.props.location.pathname.includes(PAYPAL_CANCEL_KEY)
		) {
			this.getPaymentHistory();
		}

		if (
			this.props.location.pathname.includes(PAYPAL_SUCCESS_KEY) &&
			this.props.location.pathname.includes(RETURN_URL_KEY)
		) {
			this.getPayPalResponse(SUCCESS_KEY, this.props.location.search);
		}
		if (
			this.props.location.pathname.includes(PAYPAL_CANCEL_KEY) &&
			this.props.location.pathname.includes(RETURN_URL_KEY)
		) {
			this.getPayPalResponse(CANCEL_KEY, this.props.location.search);
		}

		if (
			this.props.location.pathname.includes(STRIPE_SUCCESS_KEY) &&
			this.props.location.pathname.includes(RETURN_URL_KEY)
		) {
			this.getStripeResponse(SUCCESS_KEY);
		}
		if (
			this.props.location.pathname.includes(STRIPE_CANCEL_KEY) &&
			this.props.location.pathname.includes(RETURN_URL_KEY)
		) {
			this.getStripeResponse(CANCEL_KEY);
		}
		if (
			this.props.location.pathname.includes(CRYLLEX_SUCCESS_KEY) &&
			this.props.location.pathname.includes(RETURN_URL_KEY)
		) {
			this.getCryllexResponse(SUCCESS_KEY, this.props.location.search);
		}
		if (
			this.props.location.pathname.includes(CRYLLEX_CANCEL_KEY) &&
			this.props.location.pathname.includes(RETURN_URL_KEY)
		) {
			this.getCryllexResponse(CANCEL_KEY, this.props.location.search);
		}

		if (localStorage.getItem(FILL_BALANCE_KEY)) {
			this.setState({
				// fillAmount: Math.round(+localStorage.getItem(FILL_BALANCE_KEY)),
				fillAmount: localStorage.getItem(FILL_BALANCE_KEY),
			});
			localStorage.removeItem(FILL_BALANCE_KEY);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
	}

	// getCurrencies = () => {
	//   const spinnerId = uuid();
	//   this.props.addPartialViewSpinner(spinnerId);
	//   ApiService.getCurrency().then(response => {
	//     if (response.data) {
	//       const data = [...response.data];
	//       this.setState({ currencies: data })
	//       const currentCurrency = localStorage.getItem(CURRENCY_KEY) ? localStorage.getItem(CURRENCY_KEY) : DEFAULT_CURRENCY_KEY;
	//       if (data && data.length) {
	//         data.forEach(currency => {
	//           if (currentCurrency === currency.isocode) {
	//             this.setState({ currencyId: currency.id });
	//           }
	//         })
	//       }
	//     }
	//     this.props.removePartialViewSpinner(spinnerId);
	//   }).catch(error => this.getFail(error, spinnerId))
	// }

	changeUrl = () => {
		const { user, language } = this.props;
		this.props.history.push(
			`${
				user.userTypeId === STUDENT_USER_TYPE && !user.isOrganizationUser
					? `/${language}/${STUDENT_KEY}/${FINANCES_KEY}`
					: user.userTypeId === INSTRUCTOR_USER_TYPE && !user.isOrganizationUser
					? `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}`
					: user.userTypeId === CONSULTANT_USER_TYPE && !user.isOrganizationUser
					? `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}`
					: user.isOrganizationUser && user.isAdmin
					? `/${language}/${ORGANIZATION_ADMIN_KEY}/${FINANCES_KEY}`
					: `/${language}`
			}`,
		);
	};

	getPayPalResponse = (status, url) => {
		const { user } = this.props;
		const spinnerId = uuid();
		if (user.userTypeId !== STUDENT_USER_TYPE) {
			this.props.addPartialViewSpinner(spinnerId);
		} else {
			this.props.addPageSpinner(spinnerId);
		}
		ApiService.getPayPalResponse(status, url)
			.then(response => {
				if (this._isMounted && response.data) {
					status === SUCCESS_KEY &&
						this.setState({
							payPalSuccessData: response.data,
							isShowTransactionModal: true,
							balance: response.data,
						});
					status === CANCEL_KEY &&
						this.setState({
							payPalCancelData: response.data,
							isShowTransactionModal: true,
							balance: response.data,
						});
				}
				if (user.userTypeId !== STUDENT_USER_TYPE) {
					this.props.removePartialViewSpinner(spinnerId);
				} else {
					this.props.removePageSpinner(spinnerId);
				}
				if (user.isOrganizationUser) {
					this.checkRedirectUrlAndRedirect();
				} else {
					this.changeUrl();
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getCryllexResponse = (status, url) => {
		const { user } = this.props;
		const spinnerId = uuid();
		if (user.userTypeId !== STUDENT_USER_TYPE) {
			this.props.addPartialViewSpinner(spinnerId);
		} else {
			this.props.addPageSpinner(spinnerId);
		}
		ApiService.getCryllexResponse(status, url)
			.then(response => {
				if (this._isMounted && response.data) {
					status === SUCCESS_KEY &&
						this.setState({
							cryllexSuccessData: response.data,
							isShowTransactionModal: true,
							balance: response.data,
						});
					status === CANCEL_KEY &&
						this.setState({
							cryllexCancelData: response.data,
							isShowTransactionModal: true,
							balance: response.data,
						});
				}
				this.props.removePartialViewSpinner(spinnerId);
				if (user.isOrganizationUser) {
					this.checkRedirectUrlAndRedirect();
				} else {
					this.changeUrl();
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getStripeResponse = status => {
		const { user } = this.props;
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const paymentId = params.get("paymentId") || null;
		const spinnerId = uuid();
		ApiService.getStripeResponse(status, paymentId)
			.then(response => {
				if (response && response.data) {
					if (status === SUCCESS_KEY) {
						this.setState({
							stripeSuccessData: true,
							stripeCancelData: false,
							isShowTransactionModal: true,
						});
					} else {
						this.setState({
							stripeSuccessData: false,
							stripeCancelData: true,
							isShowTransactionModal: true,
						});
					}
					if (user.isOrganizationUser) {
						this.checkRedirectUrlAndRedirect();
					} else {
						this.changeUrl();
					}
					this.setState({ balance: response.data });
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this._isMounted &&
				this.setState({
					translationService: new TranslationService(this.props.translations),
				});
		}
	};

	getPaymentSystems = () => {
		const { user } = this.props;
		const spinnerId = uuid();
		if (user.userTypeId !== STUDENT_USER_TYPE) {
			this.props.addPartialViewSpinner(spinnerId);
		} else {
			this.props.addPageSpinner(spinnerId);
		}
		ApiService.getPaymentSystems()
			.then(response => {
				if (this._isMounted && response.data) {
					const data = [...response.data];
					this.setState({ paymentSystems: data });
				}
				if (user.userTypeId !== STUDENT_USER_TYPE) {
					this.props.removePartialViewSpinner(spinnerId);
				} else {
					this.props.removePageSpinner(spinnerId);
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getPaymentHistory = () => {
		const { user } = this.props;
		const spinnerId = uuid();
		if (user.userTypeId !== STUDENT_USER_TYPE) {
			this.props.addPartialViewSpinner(spinnerId);
		} else {
			this.props.addPageSpinner(spinnerId);
		}
		(user.isOrganizationUser
			? ApiService.getBalanceAsOrgUser()
			: ApiService.getBalance()
		)
			.then(response => {
				const data = { ...response.data };
				this._isMounted && data && this.setState({ balance: data });
				if (user.userTypeId !== STUDENT_USER_TYPE) {
					this.props.removePartialViewSpinner(spinnerId);
				} else {
					this.props.removePageSpinner(spinnerId);
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	// onNumberChange = (event) => {
	// 	if (+event.target.value.charAt(0) === 0) {
	// 		event.target.value = event.target.value.substring(1);
	// 	}
	// 	if (event.target.value.includes(".") || event.target.value.includes("e")) {
	// 		this.setState({ [event.target.name]: "" });
	// 		return false;
	// 	}
	// 	if (
	// 		event.target.value === "" ||
	// 		(typeof +event.target.value === NUMBER_KEY &&
	// 			Number(event.target.value) > 0)
	// 	) {
	// 		this.setState({ [event.target.name]: event.target.value });
	// 	}
	// 	this.setState({ [event.target.name]: event.target.value });
	// };

	onNumberChange = (event, decimalCount = 0) => {
		const { name, value } = event.target;
		let parsedValue = value.replace(/[^\d.]/g, "");
		const dotIndex = parsedValue.indexOf(".");

		if (dotIndex !== -1 && parsedValue.length - dotIndex > decimalCount + 1) {
			parsedValue = parsedValue.slice(0, dotIndex + decimalCount + 1);
		}

		if (parsedValue.startsWith("0") && parsedValue.charAt(1) !== ".") {
			parsedValue = parsedValue.replace(/^0+/, "");
		}

		this.setState({ [name]: parsedValue });
	};

	onEmailChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.setState({ [event.target.name]: event.target.value });
	};

	onPasswordChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.setState({
			isInvalidPasswordSubmit: false,
			[event.target.name]: event.target.value,
		});
	};

	checkFieldValidation = (field, fieldName) => {
		const isValid = MainService.isValidField(field, fieldName);
		switch (fieldName) {
			case "email":
				this.checkIsinvalidFieldName(isValid, "isInvalidEmail");
				break;
			default:
				break;
		}
	};

	checkIsinvalidFieldName = (isValid, name) => {
		switch (name) {
			case "isInvalidEmail":
				this.setState({ isInvalidEmail: !isValid });
				break;
			default:
				break;
		}
	};

	onChangePaymentSystem = (field, id, minDeposit = null) => {
		if (!id) return;
		this.setState({ minDeposit: minDeposit });
		if (minDeposit && minDeposit > this.state.fillAmount) {
			this.setState({ fillAmount: minDeposit });
		}
		this.setState({ [field]: id });
	};

	// changeCurrency = (selectedItem) => {
	//   const { translationService } = this.state;
	//   if (!selectedItem || !selectedItem.id) { return; }
	//   const spinnerId = uuid();
	//   ApiService.changeCurrency(selectedItem.id).then((response) => {
	//     this.setState({ currencyId: selectedItem.id })
	//     localStorage.setItem(CURRENCY_KEY, selectedItem.isocode);
	//     AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CURRENCY_SUCCESSFULLY_UPDATED"));
	//     this.getPaymentHistory();
	//   }).catch(error => this.getFail(error, spinnerId));
	// }

	onCheckPasswordBeforeCashout = event => {
		event && event.preventDefault();
		const { password } = this.state;
		if (!password || !password.trim()) {
			this.setState({ isInvalidPasswordSubmit: true });
		} else {
			this.onSubmitPayout();
		}
	};

	cancelCheckPasswordBeforeCashout = () => {
		this.setState({ password: "", isShowPasswordModal: false });
	};

	onShowPasswordModal = event => {
		event && event.preventDefault();
		const { paymentPayOutSystemId, payOutAmount, email } = this.state;
		if (!payOutAmount || !paymentPayOutSystemId || !email || !email.trim()) {
			this.setState({ isInvalidPayOutSubmit: true });
		} else {
			this.setState({ isShowPasswordModal: true });
		}
	};

	onSubmitFill = event => {
		event.preventDefault();
		const { user } = this.props;
		const { paymentFillSystemId, fillAmount, fillSpinnerId } = this.state;
		if (!fillAmount || !paymentFillSystemId) {
			this.setState({ isInvalidFillSubmit: true });
		} else {
			const data = {
				amount: fillAmount,
				paymentSystemId: paymentFillSystemId,
			};
			if (user.isOrganizationUser) {
				data.isInnerPayment = true;
			}

			this.props.addButtonSpinner(fillSpinnerId);
			ApiService.createDeposit(data)
				.then(response => {
					if (response && response.data && response.data.returnUrl) {
						window.location.href = response.data.returnUrl;
					}
					this.props.removeButtonSpinner(fillSpinnerId);
				})
				.catch(error => this.getFail(error, fillSpinnerId));

			// if (+paymentFillSystemId === 1) {
			//   //PayPal
			//   this.props.addButtonSpinner(fillSpinnerId);
			//   ApiService.paymentFillPayPal(data).then(response => {
			//     if (response.data) {
			//       const data = response.data;
			//       this.checkPaymentFillResponse(paymentFillSystemId, data);
			//     }
			//     this.props.removeButtonSpinner(fillSpinnerId);
			//   }).catch(error => this.getFail(error, fillSpinnerId));

			// } else if (+paymentFillSystemId === 3) {
			//   //stripe
			//   this.props.addButtonSpinner(fillSpinnerId);
			//   ApiService.paymentFillStripe(data).then(response => {
			//     if (response.data) {
			//       const data = response.data;
			//       this.checkPaymentFillResponse(paymentFillSystemId, data);
			//     }
			//     this.props.removeButtonSpinner(fillSpinnerId);
			//   }).catch(error => this.getFail(error, fillSpinnerId));
			// } else if (+paymentFillSystemId === 4) {
			//   // Cryllex
			//   this.props.addButtonSpinner(fillSpinnerId);
			//   ApiService.paymentFillCryllex(data).then(response => {
			//     if (response.data) {
			//       const data = response.data;
			//       this.checkPaymentFillResponse(paymentFillSystemId, data);
			//     }
			//     this.props.removeButtonSpinner(fillSpinnerId);
			//   }).catch(error => this.getFail(error, fillSpinnerId));
			// } else {
			//   this.props.addButtonSpinner(fillSpinnerId)
			//   ApiService.paymentFill(data).then(response => {
			//     if (response.data) {
			//       const data = response.data;
			//       this.checkPaymentFillResponse(paymentFillSystemId, data);
			//     }
			//     this.props.removeButtonSpinner(fillSpinnerId);
			//   }).catch(error => this.getFail(error, fillSpinnerId));
			// }
		}
	};

	// checkPaymentFillResponse = (id, data) => {
	//   switch (id) {
	//     case 1:
	//       // window.open(`${data.paypalReturnUrl}`, "_blank");
	//       if (data.paypalReturnUrl) window.location.href = data.paypalReturnUrl;
	//       break;
	//     case 3:
	//       if (data.location) window.location.href = data.location;
	//       break;
	//     case 4:
	//       if (data.location) window.location.href = data.location;
	//       break;
	//     default:
	//       break;
	//   }
	// }

	checkRedirectUrlAndRedirect = () => {
		if (localStorage.getItem(REDIRECT_URL_KEY)) {
			this.props.history.push(localStorage.getItem(REDIRECT_URL_KEY));
			{
				localStorage.removeItem(REDIRECT_URL_KEY);
			}
		}
	};

	onSubmitPayout = event => {
		event && event.preventDefault();
		const { user } = this.props;
		const { payOutSpinnerId, paymentPayOutSystemId, payOutAmount, email } =
			this.state;
		if (!payOutAmount || !paymentPayOutSystemId || !email || !email.trim()) {
			this.setState({ isInvalidPayOutSubmit: true });
		} else {
			const data = {
				amount: payOutAmount,
				email: email,
				paymentSystemId: paymentPayOutSystemId,
			};
			this.props.addButtonSpinner(payOutSpinnerId);
			ApiService.payOut(data)
				.then(response => {
					if (response.data.status === "SUCCESS") {
						this.setState({
							isShowTransactionModal: true,
							payPalSuccessData: true,
							paymentResponseMessage: response.data?.responseMessage,
							payOutAmount: "",
							email: "",
							balance: response.data.balance,
						});
					} else {
						this.setState({
							isShowTransactionModal: true,
							payPalCancelData: true,
							paymentResponseMessage: response.data?.responseMessage,
							payOutAmount: "",
							email: "",
						});
					}
					this.props.removeButtonSpinner(payOutSpinnerId);
				})
				.catch(error => this.getFail(error, payOutSpinnerId));

			// if (+paymentPayOutSystemId === 1) {
			//   this.props.addButtonSpinner(payOutSpinnerId);
			//   ApiService.payPalPayOut(data).then(response => {
			//     if (response.data.transactionStatus === "SUCCESS") {
			//       this.setState({
			//         isShowTransactionModal: true,
			//         payPalSuccessData: true,
			//         paymentResponseMessage: response.data?.responseMessage,
			//         payOutAmount: "",
			//         email: "",
			//         balance: response.data.balance,
			//       })
			//     } else {
			//       this.setState({
			//         isShowTransactionModal: true,
			//         payPalCancelData: true,
			//         paymentResponseMessage: response.data?.responseMessage,
			//         payOutAmount: "",
			//         email: "",
			//       })
			//     }
			//     this.props.removeButtonSpinner(payOutSpinnerId);
			//   }).catch(error => this.getFail(error, payOutSpinnerId));
			// } else if (+paymentPayOutSystemId === 3) {
			//   this.props.addButtonSpinner(payOutSpinnerId);
			//   ApiService.stripePayOut(data).then(response => {
			//     if (response.data.url) {
			//       window.location.href = response.data.url;
			//     }
			//     this.props.removeButtonSpinner(payOutSpinnerId);
			//   }).catch(error => this.getFail(error, payOutSpinnerId));
			// }
		}
	};

	showOrHideStripeCard = bool => {
		this.setState({ isShowStripeCard: bool });
	};

	closeModal = () => {
		this.setState({ isShowTransactionModal: false });
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
		spinnerId && this.props.removeButtonSpinner(spinnerId);
		spinnerId && this.props.removeModalSpinner(spinnerId);
	};

	render() {
		const {
			translationService,
			isInvalidFillSubmit,
			isInvalidPayOutSubmit,
			paymentSystems,
			fillSpinnerId,
			payOutSpinnerId,
			fillAmount,
			payOutAmount,
			paymentFillSystemId,
			paymentPayOutSystemId,
			balance,
			isShowTransactionModal,
			payPalSuccessData,
			payPalCancelData,
			paymentResponseMessage,
			stripeSuccessData,
			stripeCancelData,
			isInvalidEmail,
			email,
			emailMaxLength,
			isShowPasswordModal,
			password,
			isInvalidPasswordSubmit,
			minDeposit,
		} = this.state;

		const { user, language } = this.props;

		const paymentSystemsComponents = [
			{
				id: 1,
				name: "PayPal",
				value: <Paypal />,
			},
			{
				id: 2,
				name: "Skrill",
				value: <Skrill />,
			},
			{
				id: 3,
				name: "Stripe",
				value: <StripeIcon />,
			},
			{
				id: 4,
				name: "Cryllex",
				value: <Cryllex width="90px" fill="#787878" />,
			},
			{
				id: 5,
				value: <WebMoney />,
			},
			{
				id: 6,
				value: <YandexMoney />,
			},
		];

		return translationService && user ? (
			<div className={`container ${user.isOrganizationUser ? "mt-4" : ""}`}>
				<div
					className={`${
						user.userTypeId === STUDENT_USER_TYPE ? "m-section" : ""
					}`}>
					{isShowPasswordModal ? (
						<ModalComponent
							title={translationService.translate("TR_PASSWORD")}
							actionButtonTitle={translationService.translate("TR_SEND")}
							cancel={this.cancelCheckPasswordBeforeCashout}
							onSubmit={this.onCheckPasswordBeforeCashout}>
							<div className="row">
								<div className="col-12">
									<form onSubmit={this.onCheckPasswordBeforeCashout}>
										<Input
											type="password"
											id="password"
											name="password"
											min="0"
											fieldLength={emailMaxLength}
											value={password}
											labelValue={`${translationService.translate(
												"TR_PASSWORD",
											)} *`}
											onChange={event =>
												this.onPasswordChange(event, emailMaxLength)
											}
											isInvalidSubmit={isInvalidPasswordSubmit}
										/>
									</form>
								</div>
							</div>
						</ModalComponent>
					) : null}
					{isShowTransactionModal &&
					(payPalSuccessData ||
						payPalCancelData ||
						stripeSuccessData ||
						stripeCancelData) ? (
						<div className="info-alert-container">
							<div className="layer" onClick={this.closeModal} />
							<div className="info-alert-block">
								<div className="position-relative">
									<div className="close-svg" onClick={this.closeModal}>
										<CloseSvg />
									</div>
								</div>
								{payPalSuccessData || stripeSuccessData ? (
									<div className="info-description">
										<div className="payment-modal-status-icon-block">
											<i className="fas fa-check transaction-successful"></i>
										</div>
										<h2 className="content-title text-center my-4">
											{translationService.translate(
												"TR_TRANSACTION_SUCCESSFUL",
											)}
										</h2>
										<p className="text-center">{paymentResponseMessage}</p>
									</div>
								) : null}
								{payPalCancelData || stripeCancelData ? (
									<div className="info-description">
										<div className="payment-modal-status-icon-block">
											<i className="fas fa-times transaction-unsuccessful"></i>
										</div>
										<h2 className="content-title text-center my-4">
											{translationService.translate(
												"TR_TRANSACTION_UNSUCCESSFUL",
											)}
										</h2>
										<p className="text-center">{paymentResponseMessage}</p>
									</div>
								) : null}
								<hr />
								<div className="col-12">
									<div className="w-100 text-center">
										<Link
											to={
												user.userTypeId === STUDENT_USER_TYPE &&
												!user.isOrganizationUser
													? `/${language}/${STUDENT_KEY}/${OVERVIEW_KEY}`
													: user.userTypeId === INSTRUCTOR_USER_TYPE &&
													  !user.isOrganizationUser
													? `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}`
													: user.userTypeId === CONSULTANT_USER_TYPE &&
													  !user.isOrganizationUser
													? `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}`
													: user.isOrganizationUser && user.isAdmin
													? `/${language}/${ORGANIZATION_ADMIN_KEY}/${OVERVIEW_KEY}`
													: user.userTypeId === STUDENT_USER_TYPE &&
													  user.isOrganizationUser
													? `/${language}/${ORGANIZATION_ADMIN_KEY}/${OVERVIEW_KEY}`
													: `/${language}`
											}
											className="mindalay--btn-default mb-2 d-block">
											{translationService.translate(
												"TR_VIEW_TANSACTION_HISTORY",
											)}
										</Link>
										<Link
											to={`/${language}`}
											className="mindalay--btn-default d-block">
											{translationService.translate("TR_HOME")}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : null}
					<div className="row">
						<div className="col-12">
							<h2 className="section-title">
								{translationService.translate("TR_FINANCES")}
							</h2>
							<hr />
						</div>
						<div className="col-md-7 col-12 order-md-0 order-12">
							<div className="row">
								<div className="col-12 mb-5">
									<div className="content-sub-title">
										<h3 className="course-title">
											{translationService.translate("TR_DEPOSIT")}
										</h3>
									</div>
									<div className="content-background p-3">
										<form onSubmit={this.onSubmitFill}>
											<div className="icon-input">
												<Input
													type="text"
													id="fillAmount"
													name="fillAmount"
													min="0"
													blockClassName="mb-0"
													placeholder={
														balance && balance.isocode ? balance.isocode : ""
													}
													value={fillAmount}
													labelValue={`${translationService.translate(
														"TR_AMOUNT",
													)} *`}
													onChange={event =>
														this.onNumberChange(
															event,
															balance &&
																balance.currency &&
																balance.currency.decimalCount
																? balance.currency.decimalCount
																: 0,
														)
													}
													isInvalidSubmit={isInvalidFillSubmit}
												/>
												{balance && balance.currency ? (
													<span>{balance.currency?.symbol}</span>
												) : null}
											</div>
											{minDeposit && balance && balance.currency ? (
												<small className="d-block mt-1">
													{translationService.translate("TR_MIN_AMOUNT")}{" "}
													{balance.currency?.symbol}
													{minDeposit}.
												</small>
											) : null}

											<div className="payments-logos-container d-flex flex-wrap mt-2">
												{paymentSystemsComponents && paymentSystems.length
													? paymentSystemsComponents.map(
															paymentSystemsComponent => {
																return paymentSystems.map(paymentSystem => {
																	// if ((paymentSystem.name === paymentSystemsComponent.name && paymentSystem.type === 0) ||
																	//   (paymentSystem.name === paymentSystemsComponent.name && paymentSystem.type === 2)) {
																	if (
																		paymentSystem.name &&
																		paymentSystemsComponent.name &&
																		paymentSystem.name.toUpperCase() ===
																			paymentSystemsComponent.name.toUpperCase() &&
																		(paymentSystem.type === 0 ||
																			paymentSystem.type === 2)
																	) {
																		return (
																			<div
																				key={paymentSystemsComponent.id}
																				onClick={() =>
																					this.onChangePaymentSystem(
																						"paymentFillSystemId",
																						paymentSystemsComponent.id,
																						paymentSystem.minDeposit,
																					)
																				}
																				className={`payment-logo-block ${
																					paymentSystemsComponent.id ===
																					paymentFillSystemId
																						? "active-payment-system"
																						: ""
																				}
                                    ${
																			!paymentFillSystemId &&
																			isInvalidFillSubmit
																				? "is-invalid error-bordered error-payment-system"
																				: ""
																		}`}>
																				{paymentSystemsComponent.value}
																			</div>
																		);
																	} else {
																		return false;
																	}
																});
															},
													  )
													: null}
											</div>
											<ActionButton
												type="submit"
												name={translationService.translate("TR_DEPOSIT")}
												spinnerId={fillSpinnerId}
												disabled={
													!paymentFillSystemId ||
													!fillAmount ||
													(minDeposit && fillAmount < minDeposit)
												}
												className="mindalay--btn-dark w-100 mt-3"
											/>
										</form>
									</div>
								</div>
								{
									// user.isOrganizationUser && user.isAdmin ?
									user.isOrganizationUser ? null : (
										<div className="col-12">
											<div className="content-sub-title">
												<h3 className="course-title">
													{translationService.translate("TR_CASH_OUT")}
												</h3>
											</div>
											<div className="content-background p-3">
												<form
													//  onSubmit={this.onShowPasswordModal}
													onSubmit={this.onSubmitPayout}>
													<div className="icon-input">
														<Input
															type="number"
															id="payOutAmount"
															name="payOutAmount"
															min="0"
															placeholder={
																balance && balance.isocode
																	? balance.isocode
																	: ""
															}
															value={payOutAmount}
															labelValue={`${translationService.translate(
																"TR_AMOUNT",
															)} *`}
															onChange={event => this.onNumberChange(event)}
															isInvalidSubmit={isInvalidPayOutSubmit}
														/>
														<span>$</span>
													</div>
													<Input
														type="email"
														id="email"
														name="email"
														value={email}
														placeholder={translationService.translate(
															"TR_YOUR_PAYOUT_PROVIDER_EMAIL",
														)}
														labelValue={`${translationService.translate(
															"TR_EMAIL",
														)} *`}
														onChange={event =>
															this.onEmailChange(event, emailMaxLength)
														}
														onBlur={() =>
															this.checkFieldValidation(email, "email")
														}
														isInvalidField={
															(isInvalidPayOutSubmit && !email.trim()) ||
															isInvalidEmail
														}
													/>
													<div className="payments-logos-container d-flex flex-wrap">
														{paymentSystemsComponents && paymentSystems.length
															? paymentSystemsComponents.map(
																	paymentSystemsComponent => {
																		return paymentSystems.map(paymentSystem => {
																			if (
																				paymentSystem.name ===
																					paymentSystemsComponent.name &&
																				(paymentSystem.type === 1 ||
																					paymentSystem.type === 2)
																			) {
																				return (
																					<div
																						key={paymentSystemsComponent.id}
																						onClick={() =>
																							this.onChangePaymentSystem(
																								"paymentPayOutSystemId",
																								paymentSystemsComponent.id,
																							)
																						}
																						className={`payment-logo-block ${
																							paymentSystemsComponent.id ===
																							paymentPayOutSystemId
																								? "active-payment-system"
																								: ""
																						}
                                  ${
																		!paymentPayOutSystemId &&
																		isInvalidPayOutSubmit
																			? "is-invalid error-bordered error-payment-system"
																			: ""
																	}`}>
																						{paymentSystemsComponent.value}
																					</div>
																				);
																			} else {
																				return false;
																			}
																		});
																	},
															  )
															: null}
													</div>
													<ActionButton
														type="submit"
														name={translationService.translate("TR_CASH_OUT")}
														spinnerId={payOutSpinnerId}
														disabled={paymentPayOutSystemId ? false : true}
														className="mindalay--btn-secondary w-100 mt-3"
													/>
												</form>
											</div>
										</div>
									)
								}
							</div>
						</div>
						<div className="col-md-5 col-12">
							<div className="row">
								{balance ? (
									<div className="col-12">
										<div className="content-sub-title">
											<h3 className="course-title">
												{translationService.translate(
													"TR_YOUR_CURRENT_BALANCE",
												)}
											</h3>
										</div>
										{/* {
                        user.isOrganizationUser && user.isAdmin && currencies && currencies.length && currencies.length > 1 ?
                          <div className="my-3">
                            <div className="form-group fit-content">
                              <div>
                                <label>{translationService.translate("TR_CHANGE_CURRENCY")}</label>
                                <div className="info-title d-inline-block">
                                  <div>
                                    <InfoSvg />
                                    <span style={{ display: "none" }}>
                                      {translationService.translate("TR_CHANGE_CURRENCY_INFO")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <ReactSelectOption
                                value={currencyId}
                                selectedValue={(() => {
                                  const selectedItem = { ...currencies.find(data => data.id === currencyId) };
                                  if (selectedItem) {
                                    selectedItem.value = selectedItem.id;
                                    selectedItem.label = selectedItem.isocode;
                                  }
                                  return selectedItem;
                                })()}
                                items={currencies.map(data => ({ ...data, label: data.isocode, value: data.id }))}
                                onChange={item => this.changeCurrency(item)}
                              />
                            </div>
                          </div>
                          : null
                      } */}
										<div className="mindalay--overview-container m-0 mx-0 ">
											<div className="mindalay--overview-header d-lg-flex align-items-lg-center justify-content-lg-between border-none">
												{
													<div className="mindalay--overview-header-wrapper">
														<div className="mindalay--overview-total-wrapper">
															{
																<div className="mindalay--overview-total">
																	<p className="mindalay--overview-info-title">
																		{translationService.translate(
																			"TR_TOTAL_BALANCE",
																		)}
																	</p>
																	{balance.balanceAsString ? (
																		<span className="mindalay--overview-info-value">
																			{balance.balanceAsString}
																		</span>
																	) : null}
																	<br />
																	{balance.balanceByMainCurrencyAsString &&
																	balance.balanceAsString !==
																		balance.balanceByMainCurrencyAsString ? (
																		<span className="mindalay--overview-local-value">
																			({balance.balanceByMainCurrencyAsString})
																		</span>
																	) : null}
																</div>
															}
															{balance.freezedAmountAsString ? (
																<Auxiliary>
																	<span></span>
																	{typeof balance.freezedAmount ===
																	NUMBER_KEY ? (
																		<div className="mindalay--overview-this-month">
																			<p className="mindalay--overview-info-title">
																				{translationService.translate(
																					"TR_FREEZ_AMOUNT",
																				)}
																			</p>
																			{balance.freezedAmountAsString ? (
																				<span className="mindalay--overview-info-value">
																					{balance.freezedAmountAsString}
																				</span>
																			) : null}
																			<br />
																			{balance.freezedAmountByMainCurrencyAsString ? (
																				<span className="mindalay--overview-local-value">
																					(
																					{
																						balance.freezedAmountByMainCurrencyAsString
																					}
																					)
																				</span>
																			) : null}
																		</div>
																	) : null}
																</Auxiliary>
															) : null}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		) : null;
	}
}
const mapStateToProps = state => ({
	translations: state.translation.translations,
	user: state.user.user,
	language: state.language.language,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	addModalSpinner,
	removeModalSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(Finances);
