export const setCookie = (name, val) => {
  const date = new Date();
  const value = val;
  date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value} ; expires=${date.toUTCString()} ; path=/`;
}

export const setCookieForaMonth = (name, val) => {
  var date = new Date();
  const value = val;
  date.setMonth(date.getMonth() + 1);
  document.cookie = `${name}=${value} ; expires=${date.toUTCString()} ; path=/`;
}

export const setCookieForAnHour = (name, val) => {
  var date = new Date();
  const value = val;
  date.setTime(date.getTime() + 1 * 3600 * 1000);
  // var time = date.getTime();
  // time += 3600 * 1000;
  // date.setTime(time);
  document.cookie = `${name}=${value} ; expires=${date.toUTCString()} ; path=/`;
}

export const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
}

export const deleteCookie = (name) => {
  const date = new Date();
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
  document.cookie = `${name}= ; expires=${date.toUTCString()} ; path=/`;
}