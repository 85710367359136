import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from './../../../../Store/Actions/generalLeftMenu';
import { COURSES_KEY, MINDALAY_ADMIN, UNVERIFIED_KEY, VERIFIED_KEY, DISCOUNT_KEY } from "../../../../Constants/urlKeys";

const CoursesSubMenu = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${VERIFIED_KEY}`}
            className={props.location.pathname.includes(`/${VERIFIED_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_VERIFIED_COURSES")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${UNVERIFIED_KEY}`}
            className={props.location.pathname.includes(`/${UNVERIFIED_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_UNVERIFIED_COURSES")}
          </NavLink>
        </li>

        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${DISCOUNT_KEY}`}
            className={props.location.pathname.includes(`/${DISCOUNT_KEY}`) ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_COURSES_FOR_DISCOUNT")}
          </NavLink>
        </li>

      </ul>
    </div>
  </aside> : null;
}

export default withRouter(CoursesSubMenu);