import React from "react";
import { withRouter } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import AdminFooter from "../LayoutSections/Footer/adminFooter";
// import AdminHeader from "../LayoutSections/Header/adminHeader";
import OrganizationAdminRoutes from "../../Routing/organizationAdminRoutes";
import OrganizationAdminLeftMenu from "../LayoutSections/LeftMenu/OrganizationAdminLeftMenu";
import OrganizationUserHeader from "../LayoutSections/Header/organizationUserHeader";
import HelpLeftMenu from "../../Pages/Help/Components/HelpLeftMenu";

const OrganizationAdminlayout = (props) => {
  return (
    <Auxiliary>
      {/* <AdminHeader /> */}
      <OrganizationUserHeader />
      {props.location.pathname.includes("/help") ? null : <OrganizationAdminLeftMenu />}
      <section className={`${props.location.pathname.includes("/help") ? "" : "instructior-dashboard-body--large"} `}>
        <div className="top-section wrapper pb-5">
          <OrganizationAdminRoutes />
        </div>
        <AdminFooter />
      </section>
    </Auxiliary>
  );
}

export default withRouter(OrganizationAdminlayout);

