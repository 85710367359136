// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { withRouter } from "react-router-dom";
// import ApiService from "../../../Services/apiService";
// import AlertService from "../../../Services/alertService";
// import UploadButton from "../../../Components/UploadButton/uploadButton";
// import MainService from "../../../Services/mainService";
// import TranslationService from "../../../Services/translationService";
// import ModalComponent from "../../../Components/ModalComponent/modalComponent";
// import { addButtonSpinner, removeButtonSpinner, addModalSpinner, removeModalSpinner } from "../../../Store/Actions/spinner";
// import {
//   ERROR_KEY,
//   FILE_KEY,
//   IMAGE_KEY,
//   VIDEO_KEY,
//   FILE_LIBRARY_CATEGORY_TYPE_KEY,
//   VALID_FILE_TYPES_KEY,
//   VALID_IMAGE_TYPES_KEY,
//   VALID_VIDEO_TYPES_KEY,
//   VALID_AUDIO_TYPES_KEY,
//   AUDIO_KEY,
//   OBJECT_KEY,
// } from "../../../Constants/mainKeys";
// import Input from "../../../Components/Inputs/input";
// import ActionButton from "../../../Components/ActionButton/actionButton";
// import uuid from "react-uuid";
// import Auxiliary from "../../../hoc/auxiliary/auxiliary";
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import FileSvg from './../../../Components/Svg/fileSvg';

// const spinnerId = uuid();
// const AddFileLibraryModal = props => {

//   const dispatch = useDispatch();
//   const mainService = new MainService();
//   const { translations } = useSelector(state => state.translation);
//   const { modalSpinners } = useSelector(state => state.spinner);
//   const nameMaxLength = 200;
//   const [translationService, setTranslationService] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const [fileEntities, setFileEntities] = useState([]);
//   const [invalidFileExc, setInvalidFileExc] = useState(null);
//   const [categoryId, setCategoryId] = useState(null);
//   const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
//   const [name, setName] = useState("");
//   const [failedFields, setFailedFields] = useState(false);
//   const [currentCategories, setCurrentCategories] = useState([]);
//   const [breadCrumbData, setBreadCrumbData] = useState([]);
//   const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);
//   const [fileReadPercent, setFileReadPercent] = useState(0);

//   useEffect(() => {
//     setTranslationService(new TranslationService(translations));
//   }, [translations]);

//   useEffect(() => {
//     getFileCategories();
//   }, []);

//   const getFileCategories = () => {
//     const spinnerId = uuid();
//     dispatch(addModalSpinner(spinnerId));
//     setIsDisabledAddButton(true);
//     ApiService.getCategoriesByType(+FILE_LIBRARY_CATEGORY_TYPE_KEY).then(response => {
//       if (response.data) {
//         getAllCategories(response.data);
//         setCurrentCategories(response.data);
//         setCategories(response.data)
//         if (response.data.length === 1 && !response.data[0]?.children?.length) {
//           setCategoryId(response.data[0].id);
//         }
//       }
//       dispatch(removeModalSpinner(spinnerId));
//       setIsDisabledAddButton(false);
//     }).catch(error => getFail(error, spinnerId));
//   }

//   const getAllCategories = (categories) => {
//     let newCategories = [];
//     categories.forEach(category => {
//       newCategories.push(category);
//       if (category.children && category.children.length) {
//         newCategories = [...newCategories, ...getAllCategories(category.children)];
//       }
//     });
//     return newCategories;
//   }

//   const uploadFile = async (event) => {

//     let fileEntities = [];
//     let validFileTypes = null;
//     switch (props.fileType) {
//       case IMAGE_KEY:
//         validFileTypes = VALID_IMAGE_TYPES_KEY;
//         break;
//       case VIDEO_KEY:
//         validFileTypes = VALID_VIDEO_TYPES_KEY;
//         break;
//       case AUDIO_KEY:
//         validFileTypes = VALID_AUDIO_TYPES_KEY;
//         break;
//       case FILE_KEY:
//         validFileTypes = VALID_FILE_TYPES_KEY;
//         break;
//     }

//     if (event.target.files) {
//       for (let i in event.target.files) {
//         const file = event.target.files[i];
//         if (file && typeof file === OBJECT_KEY) {
//           const fileName = file.name;
//           const lastDotIndex = fileName.lastIndexOf('.');
//           const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

//           let fileType = null;

//           if (VALID_IMAGE_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
//             fileType = IMAGE_KEY;
//           }
//           else if (VALID_VIDEO_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
//             fileType = VIDEO_KEY;
//           }
//           else if (VALID_AUDIO_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
//             fileType = AUDIO_KEY;
//           }
//           else if (VALID_FILE_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
//             fileType = FILE_KEY;
//           }
//           await mainService.readFile(file, validFileTypes, setFileReadPercent).then(uploadedFile => {
//             fileEntities.push({
//               fileName,
//               fileType,
//               file,
//               uploadedFile
//             });
//           }).catch(error => error && setFileException());
//         }
//       }
//       setFileEntities(fileEntities);
//     }




//   }

//   console.log(fileEntities);

//   const onChange = (event, maxLength = null) => {
//     if (maxLength && maxLength < event.target.value.length) { return; }
//     setFailedFields(removeFailedFields(event.target.name))
//     setName(event.target.value);
//   };

//   const setFileException = () => {
//     setFileEntities([]);
//     setFileReadPercent(0);
//     setInvalidFileExc(translationService.translate("TR_INVALID_FILE_FORMAT_EXC"));
//     AlertService.alert(ERROR_KEY, translationService.translate("TR_INVALID_FILE_FORMAT_EXC"));
//   }

//   const addFile = (event) => {
//     event && event.stopPropagation();
//     event && event.preventDefault();
//     if (!categoryId || !fileEntities) {
//       setIsInvalidSubmit(true);
//       return false;
//     } else {
//       dispatch(addModalSpinner(spinnerId));
//       setIsDisabledAddButton(true);
//       const form = {
//         categoryId: categoryId,
//         fileEntity: fileEntities,
//         name: props.name || fileEntities.name || "",
//         [props.fileType]: true,
//       };
//       const formData = new FormData();
//       for (let i in form) {
//         if (Array.isArray(form[i])) {
//           form[i].forEach((item, index) => {
//             formData.append(fileEntities[index], item)
//           })
//         } else {
//           formData.append(i, form[i]);
//         }
//       }
//       ApiService.libraryFileCreateFromCourse(formData).then(response => {
//         const file = response.data;
//         props.added(file);
//         dispatch(removeModalSpinner(spinnerId));
//         setIsDisabledAddButton(false);
//       }).catch(error => getFail(error, spinnerId));
//     }
//   }

//   const createCategory = (event) => {
//     event.stopPropagation();
//     event.preventDefault();
//     const categoryType = +FILE_LIBRARY_CATEGORY_TYPE_KEY;
//     if (!name || !categoryType) {
//       setIsInvalidSubmit(true);
//     } else {
//       dispatch(addButtonSpinner(spinnerId));
//       ApiService.categoryCreate({ name, categoryType }).then(response => {
//         response && getFileCategories();
//         dispatch(removeButtonSpinner(spinnerId));
//         setIsInvalidSubmit(false);
//       }).catch(error => getFail(error, spinnerId));
//     }
//   }

//   const getFiles = category => {
//     if (category) {
//       const breadCrumbDataCopy = [...breadCrumbData];
//       const breadCrumbCategoryIndex = breadCrumbDataCopy.findIndex(data => data.id === category.id);
//       if (breadCrumbCategoryIndex === -1) {
//         breadCrumbDataCopy.push({ id: category.id, name: category.name, children: category.children });
//       } else {
//         breadCrumbDataCopy.splice(breadCrumbCategoryIndex + 1);
//       }
//       setCategoryId(category.id);
//       setCurrentCategories(category.children);
//       setBreadCrumbData(breadCrumbDataCopy);
//     }
//   }

//   const clearFilter = () => {
//     setCategories(categories);
//     setCurrentCategories(categories);
//     setBreadCrumbData([]);
//   }

//   const removeFailedFields = name => {
//     const failedField = failedFields && { ...failedFields };
//     const fieldName = name.replace(name[0], name[0].toUpperCase());
//     failedField && delete failedField[fieldName];
//     return failedField;
//   };

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//     spinnerId && dispatch(removeButtonSpinner(spinnerId));
//     spinnerId && dispatch(removeModalSpinner(spinnerId));
//     setIsDisabledAddButton(false);
//   }

//   return translationService ?
//     <ModalComponent
//       title={translationService.translate("TR_ADD_NEW_FILE")}
//       isLarge={true}
//       actionButtonTitle={translationService.translate("TR_ADD")}
//       onSubmit={addFile}
//       addButtonIsDisabled={!categories.length || isDisabledAddButton}
//       cancel={() => {
//         dispatch(removeModalSpinner(spinnerId));
//         props.cancel && props.cancel();
//       }}
//     >
//       <div className="row">
//         {
//           categories && categories.length ?
//             <Auxiliary>
//               {
//                 breadCrumbData && breadCrumbData.length
//                   ? <div className="col-12 mb-3">
//                     <ul className="bread-crump d-flex flex-wrap file-category">
//                       <li className="bread-crump-item pointer" onClick={clearFilter}>
//                         {translationService.translate("TR_HOME")}
//                         <i className="fas fa-chevron-right mr-3 ml-3"></i>
//                       </li>
//                       {
//                         breadCrumbData.map(item => <li key={item.id} className="bread-crump-item pointer word-break-break-word" onClick={() => getFiles(item)}>
//                           {item.name}<i className="fas fa-chevron-right mr-3 ml-3"></i>
//                         </li>)
//                       }
//                     </ul>
//                   </div>
//                   : null
//               }
//               <div className="col-12">
//                 {
//                   currentCategories && currentCategories.length
//                     ? currentCategories.map(category => <button
//                       key={category.id}
//                       type="button"
//                       // disabled={(categories?.length === 1 && (!currentCategories[0]?.children || (currentCategories[0]?.children || !currentCategories[0]?.children?.length))) ? true : false}
//                       className={`mindalay--btn-default mr-2 mindalay--btn-small  folder-button ${isInvalidSubmit && !categoryId ? "is-invalid error-bordered" : ""}`}
//                       onClick={() => getFiles(category)}
//                     >{category.name}</button>)
//                     : null
//                 }
//               </div>
//             </Auxiliary>
//             :
//             <form className="w-100" onSubmit={createCategory}>
//               <div className="col-12">
//                 <span className="d-block mb-3">{translationService.translate("TR_YOU_HAVE_TO_CREATE_FOLDER_FIRST")}</span>
//                 <div className="row">
//                   <div className="col-12">
//                     <Input
//                       type="text"
//                       id="name"
//                       name="name"
//                       value={name}
//                       isInvalidSubmit={isInvalidSubmit}
//                       labelValue={`${translationService.translate("TR_NAME")} *`}
//                       onChange={(event) => onChange(event, nameMaxLength)}
//                       failedFields={failedFields}
//                     />
//                   </div>
//                   <div className="col-12">
//                     <ActionButton
//                       spinnerId={spinnerId}
//                       type="submit"
//                       className="mindalay--btn-default float-right"
//                       name={translationService.translate("TR_CREATE")}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </form>
//         }
//         {
//           categories.length ?
//             <div className="col-12">
//               <div className="form-group d-md-flex">
//                 <div className="upload-file flex-1 mt-3">
//                   <UploadButton
//                     className="mindalay--btn-default"
//                     textInputClasses="pl-100"
//                     isInvalidSubmit={isInvalidSubmit}
//                     value={fileEntities}
//                     isMultiple={props.isMultiple || false}
//                     text={translationService.translate('TR_UPLOAD_FILE')}
//                     clicked={uploadFile}
//                   />
//                   {
//                     invalidFileExc ? <small className="fail">{invalidFileExc}</small> : null
//                   }
//                 </div>
//                 <input type="file" hidden />
//               </div>
//             </div>
//             : null
//         }
//         {
//           fileReadPercent > 0 && fileReadPercent < 100 ?
//             <div className="col-12">
//               <ProgressBar now={fileReadPercent} />
//             </div>
//             : null
//         }

//         {
//           fileEntities && fileEntities.map((fileData, fileDataIndex) => {
//             return <Auxiliary key={fileDataIndex}>
//               {
//                 (fileData.uploadedFile) && fileData.fileType === IMAGE_KEY
//                   ? <div className="col-lg-4 col-sm-6 col-12 mb-3 mt-1">
//                     <div className="uploaded-file-library" style={{ backgroundImage: `url(${fileData.uploadedFile})` }}></div>
//                   </div>
//                   : null
//               }
//               {
//                 (fileData.uploadedFile) && fileData.fileType === VIDEO_KEY
//                   ? <div className="col-lg-4 col-sm-6 col-12 mb-3 mt-1">
//                     <video src={fileData.uploadedFile} className="uploaded-file-library" controls ></video>
//                   </div>
//                   : null
//               }
//               {
//                 (fileData.uploadedFile) && fileData.fileType === AUDIO_KEY
//                   ? <div className="col-lg-4 col-sm-6 col-12 mb-3 mt-1">
//                     <audio src={fileData.uploadedFile} controls />
//                   </div>
//                   : null
//               }
//               {
//                 (fileData.uploadedFile) && fileData.fileType === FILE_KEY
//                   ? <div className="col-lg-4 col-sm-6 col-12 mb-3 mt-1">
//                     <div className="uploaded-type-file-library p-4">
//                       <div className="type-file">
//                         <FileSvg />
//                       </div>
//                       <p>{fileData.fileName}</p>
//                     </div>
//                   </div> : null
//               }
//             </Auxiliary>;
//           })
//         }

//       </div>
//     </ModalComponent>
//     : null;
// }

// export default withRouter(AddFileLibraryModal);
// Gigona asel vor comment anenq, stex iragorcaca bulk uploady file-eri, piti ir motic endpoint poxer vori jamanaky chuni hima 04.06.2024  



import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ApiService from "../../../Services/apiService";
import AlertService from "../../../Services/alertService";
import UploadButton from "../../../Components/UploadButton/uploadButton";
import MainService from "../../../Services/mainService";
import TranslationService from "../../../Services/translationService";
import ModalComponent from "../../../Components/ModalComponent/modalComponent";
import { addButtonSpinner, removeButtonSpinner, addModalSpinner, removeModalSpinner } from "../../../Store/Actions/spinner";
import {
  ERROR_KEY,
  FILE_KEY,
  IMAGE_KEY,
  VIDEO_KEY,
  FILE_LIBRARY_CATEGORY_TYPE_KEY,
  VALID_FILE_TYPES_KEY,
  VALID_IMAGE_TYPES_KEY,
  VALID_VIDEO_TYPES_KEY,
  VALID_AUDIO_TYPES_KEY,
  AUDIO_KEY,
} from "../../../Constants/mainKeys";
import Input from "../../../Components/Inputs/input";
import ActionButton from "../../../Components/ActionButton/actionButton";
import uuid from "react-uuid";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import ProgressBar from 'react-bootstrap/ProgressBar';

const spinnerId = uuid();
const AddFileLibraryModal = props => {

  const dispatch = useDispatch();
  const mainService = new MainService();
  const translations = useSelector(state => state.translation.translations);
  const modalSpinners = useSelector(state => state.spinner.modalSpinners);
  const nameMaxLength = 200;
  const [translationService, setTranslationService] = useState(null);
  const [categories, setCategories] = useState([]);
  const [fileEntity, setFileEntity] = useState(null);
  const [file, setFile] = useState(null);
  const [invalidFileExc, setInvalidFileExc] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [name, setName] = useState("");
  const [failedFields, setFailedFields] = useState(false);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [breadCrumbData, setBreadCrumbData] = useState([]);
  const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);
  const [fileReadPercent, setFileReadPercent] = useState(0);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getFileCategories();
  }, []);

  const getFileCategories = () => {
    const spinnerId = uuid();
    dispatch(addModalSpinner(spinnerId));
    setIsDisabledAddButton(true);
    ApiService.getCategoriesByType(+FILE_LIBRARY_CATEGORY_TYPE_KEY).then(response => {
      if (response.data) {
        getAllCategories(response.data);
        setCurrentCategories(response.data);
        setCategories(response.data)
        if (response.data.length === 1 && !response.data[0]?.children?.length) {
          setCategoryId(response.data[0].id);
        }
      }
      dispatch(removeModalSpinner(spinnerId));
      setIsDisabledAddButton(false);
    }).catch(error => getFail(error, spinnerId));
  }

  const getAllCategories = (categories) => {
    let newCategories = [];
    categories.forEach(category => {
      newCategories.push(category);
      if (category.children && category.children.length) {
        newCategories = [...newCategories, ...getAllCategories(category.children)];
      }
    });
    return newCategories;
  }

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    let validFileTypes = null;
    switch (props.fileType) {
      case IMAGE_KEY:
        validFileTypes = VALID_IMAGE_TYPES_KEY;
        break;
      case VIDEO_KEY:
        validFileTypes = VALID_VIDEO_TYPES_KEY;
        break;
      case AUDIO_KEY:
        validFileTypes = VALID_AUDIO_TYPES_KEY;
        break;
      case FILE_KEY:
        validFileTypes = VALID_FILE_TYPES_KEY;
        break;
    }

    if (file) {
      setInvalidFileExc(null);
      mainService.readFile(file, validFileTypes, setFileReadPercent).then(uploadFile => {
        if (uploadFile) {
          setFileEntity(file);
          setFile(uploadFile);
        }
      }).catch(error => error && setFileException());
    }
  }

  const onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setFailedFields(removeFailedFields(event.target.name))
    setName(event.target.value);
  };

  const setFileException = () => {
    setFileEntity(null);
    setFile(null);
    setFileReadPercent(0);
    setInvalidFileExc(translationService.translate("TR_INVALID_FILE_FORMAT_EXC"));
    AlertService.alert(ERROR_KEY, translationService.translate("TR_INVALID_FILE_FORMAT_EXC"));
  }

  const addFile = (event) => {
    event && event.stopPropagation();
    event && event.preventDefault();
    if (!categoryId || !fileEntity) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      dispatch(addModalSpinner(spinnerId));
      setIsDisabledAddButton(true);
      const form = {
        categoryId: categoryId,
        fileEntity: fileEntity,
        name: props.name || fileEntity.name || "",
        [props.fileType]: true,
      };
      const formData = new FormData();
      for (let i in form) {
        formData.append(i, form[i]);
      }
      ApiService.libraryFileCreateFromCourse(formData).then(response => {
        const file = response.data;
        props.added(file);
        dispatch(removeModalSpinner(spinnerId));
        setIsDisabledAddButton(false);
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const createCategory = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const categoryType = +FILE_LIBRARY_CATEGORY_TYPE_KEY;
    if (!name || !categoryType) {
      setIsInvalidSubmit(true);
    } else {
      dispatch(addButtonSpinner(spinnerId));
      ApiService.categoryCreate({ name, categoryType }).then(response => {
        response && getFileCategories();
        dispatch(removeButtonSpinner(spinnerId));
        setIsInvalidSubmit(false);
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const getFiles = category => {
    if (category) {
      const breadCrumbDataCopy = [...breadCrumbData];
      const breadCrumbCategoryIndex = breadCrumbDataCopy.findIndex(data => data.id === category.id);
      if (breadCrumbCategoryIndex === -1) {
        breadCrumbDataCopy.push({ id: category.id, name: category.name, children: category.children });
      } else {
        breadCrumbDataCopy.splice(breadCrumbCategoryIndex + 1);
      }
      setCategoryId(category.id);
      setCurrentCategories(category.children);
      setBreadCrumbData(breadCrumbDataCopy);
    }
  }

  const clearFilter = () => {
    setCategories(categories);
    setCurrentCategories(categories);
    setBreadCrumbData([]);
  }

  const removeFailedFields = name => {
    const failedField = failedFields && { ...failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedField && delete failedField[fieldName];
    return failedField;
  };

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removeButtonSpinner(spinnerId));
    spinnerId && dispatch(removeModalSpinner(spinnerId));
    setIsDisabledAddButton(false);
  }

  return translationService ?
    <ModalComponent
      title={translationService.translate("TR_ADD_NEW_FILE")}
      isLarge={true}
      actionButtonTitle={translationService.translate("TR_ADD")}
      onSubmit={addFile}
      addButtonIsDisabled={!categories.length || isDisabledAddButton}
      cancel={() => {
        dispatch(removeModalSpinner(spinnerId));
        props.cancel && props.cancel();
      }}
    >
      <div className="row">
        {
          categories && categories.length ?
            <Auxiliary>
              {
                breadCrumbData && breadCrumbData.length
                  ? <div className="col-12 mb-3">
                    <ul className="bread-crump d-flex flex-wrap file-category">
                      <li className="bread-crump-item pointer" onClick={clearFilter}>
                        {translationService.translate("TR_HOME")}
                        <i className="fas fa-chevron-right mr-3 ml-3"></i>
                      </li>
                      {
                        breadCrumbData.map(item => <li key={item.id} className="bread-crump-item pointer word-break-break-word" onClick={() => getFiles(item)}>
                          {item.name}<i className="fas fa-chevron-right mr-3 ml-3"></i>
                        </li>)
                      }
                    </ul>
                  </div>
                  : null
              }
              <div className="col-12">
                {
                  currentCategories && currentCategories.length
                    ? currentCategories.map(category => <button
                      key={category.id}
                      type="button"
                      // disabled={(categories?.length === 1 && (!currentCategories[0]?.children || (currentCategories[0]?.children || !currentCategories[0]?.children?.length))) ? true : false}
                      className={`mindalay--btn-default mr-2 mindalay--btn-small  folder-button ${isInvalidSubmit && !categoryId ? "is-invalid error-bordered" : ""}`}
                      onClick={() => getFiles(category)}
                    >{category.name}</button>)
                    : null
                }
              </div>
            </Auxiliary>
            :
            <form className="w-100" onSubmit={createCategory}>
              <div className="col-12">
                <span className="d-block mb-3">{translationService.translate("TR_YOU_HAVE_TO_CREATE_FOLDER_FIRST")}</span>
                <div className="row">
                  <div className="col-12">
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      isInvalidSubmit={isInvalidSubmit}
                      labelValue={`${translationService.translate("TR_NAME")} *`}
                      onChange={(event) => onChange(event, nameMaxLength)}
                      failedFields={failedFields}
                    />
                  </div>
                  <div className="col-12">
                    <ActionButton
                      spinnerId={spinnerId}
                      type="submit"
                      className="mindalay--btn-default float-right"
                      name={translationService.translate("TR_CREATE")}
                    />
                  </div>
                </div>
              </div>
            </form>
        }
        {
          categories.length ?
            <div className="col-12">
              <div className="form-group d-md-flex">
                <div className="upload-file flex-1 mt-3">
                  <UploadButton
                    className="mindalay--btn-default"
                    textInputClasses="pl-100"
                    isInvalidSubmit={isInvalidSubmit}
                    value={fileEntity}
                    text={translationService.translate('TR_UPLOAD_FILE')}
                    clicked={uploadFile}
                  />
                  {
                    invalidFileExc ? <small className="fail">{invalidFileExc}</small> : null
                  }
                </div>
                <input type="file" hidden />
              </div>
            </div>
            : null
        }
        {
          fileReadPercent > 0 && fileReadPercent < 100 ?
            <div className="col-12">
              <ProgressBar now={fileReadPercent} />
            </div>
            : null
        }

        <div className="col-12">
          {
            file && props.fileType === IMAGE_KEY ? <div className="upload" style={{ backgroundImage: `url(${file})` }}></div> : null
          }
          {
            file && props.fileType === VIDEO_KEY ?
              <video
                src={file}
                className="upload"
                autoPlay={false}
                controls
                controlsList="nodownload nopictonpicture"
                disablePictureInPicture={true} />
              : null
          }
          {
            file && props.fileType === AUDIO_KEY ? <audio src={file} controls /> : null
          }
          {
            fileEntity && props.fileType === FILE_KEY ? <p className="upload">{fileEntity.name}</p> : null
          }
        </div>

      </div>
    </ModalComponent>
    : null;
}

export default withRouter(AddFileLibraryModal);
