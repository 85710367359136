import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { CONSULTANT_USER_TYPE, INSTRUCTOR_USER_TYPE } from "../../../../Constants/mainKeys";
import { CONSULTANT_KEY, FINANCES_KEY, INSTRUCTOR_KEY, OVERVIEW_KEY } from "../../../../Constants/urlKeys";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from './../../../../Store/Actions/generalLeftMenu';

const FinancesSubMenu = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  const isConsultant = user.userTypeId === CONSULTANT_USER_TYPE ? true : false;
  const isInstructor = user.userTypeId === INSTRUCTOR_USER_TYPE ? true : false;

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        <li className="side-left-submenu-item">
          <NavLink
            to={
              isConsultant ? `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}` :
                isInstructor ? `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}` : "#"
            }
            activeClassName={
              props.location.pathname === `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}` ||
                props.location.pathname === `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}`
                ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_BALANCE")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={
              isConsultant ? `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}` :
                isInstructor ? `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}` : "#"
            }
            activeClassName={
              props.location.pathname === `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}` ||
                props.location.pathname === `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}`
                ? "active-text" : ""}
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_PAYMENT_HISTORY")}
          </NavLink>
        </li>
      </ul>
    </div>
  </aside> : null;
}

export default withRouter(FinancesSubMenu);