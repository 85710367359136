import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "../../../Services/translationService";
import ApiService from "../../../Services/apiService";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
	addModalSpinner,
	removeModalSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import ModalComponent from "../../../Components/ModalComponent/modalComponent";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import { Collapse } from "react-bootstrap";
import SettingsSvg from "../../../Components/Svg/settingsSvg";
import MainService from "../../../Services/mainService";
import RolesService from "../../../Services/rolesService";

const EducationalUnits = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [educationalUnits, setEducationalUnits] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [selectItems, setSelectItems] = useState([]);
	const [values, setValues] = useState([]);
	const [isActivStudents, setIsActivStudents] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const { role, user } = useSelector(state => state.user);
	const [roleService, setRoleService] = useState(null);
	const [moadlTitle, setModalTitle] = useState("divisions");
	const [subjectId, setSubjectId] = useState("");
	const [ids, setIds] = useState(
		localStorage.getItem("collapseIds")
			? JSON.parse(localStorage.getItem("collapseIds"))
			: [],
	);
	const [currentDivision, setCurrentDivision] = useState(null);
	const [replacementLecturers, setReplacementLecturers] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [deletedLectureId, setDeletedLectureId] = useState("");
	const [oldAndNewLecturerUserIds, setOldAndNewLecturerUserIds] = useState([]);
	const [isWornig, setIsWornig] = useState(false);
	const [currentLectures, setCurrentLectures] = useState(null);
	const [isPending, setIsPending] = useState(false);

	useEffect(() => {
		if (ids && ids.length) {
			localStorage.setItem("collapseIds", JSON.stringify(ids));
		} else {
			localStorage.removeItem("collapseIds");
		}
	}, [ids]);

	useEffect(() => {
		function handleClickOutside() {
			setCurrentDivision(null);
		}
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDivisions(1);
	}, []);
	const getEducationalLecturers = (item, isEmpty = false) => {
		if (isEmpty) {
			setCurrentLectures(null);
			return;
		}
		setIsPending(true);
		ApiService.getEducationalLecturers(item.id, false)
			.then(response => {
				if (response.data && response.data.length) {
					setCurrentLectures({
						...item,
						lectures: response.data,
					});
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				setIsPending(false);
			});
	};

	const onChangeIds = id => {
		if (ids.includes(id)) {
			const newIds = ids.filter(el => el !== id);
			setIds(newIds);
		} else {
			setIds([...ids, id]);
		}
	};

	const handlePageClick = event => {
		getDivisions(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getDivisions = currentPage => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getEducationalUnits(currentPage, pageSize)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setEducationalUnits(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const deleteEducationalUnit = item => {
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")}`,
			`${translationService.translate(
				"TR_YOU_WANT_TO_DELETE_EDUCATIONAL_UNIT",
			)}`,
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteEducationalUnit(item.id)
				.then(() => {
					setEducationalUnits(
						educationalUnits.filter(_item => _item.id !== item.id),
					);
					dispatch(removePartialViewSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const onClose = () => {
		setIsChange(false);
		setSubjectId("");
		setValues([]);
		setSelectItems([]);
		setIsActivStudents(false);
		setIsWornig(false);
		setIsWornig(false);
		setOpenModal(false);
		setModalTitle("divisions");
		setReplacementLecturers([]);
		setDeletedLectureId("");
		setDisabled(false);
		setOldAndNewLecturerUserIds([]);
	};

	const onSave = () => {
		const spinerID = uuid();
		setIsChange(false);
		dispatch(addModalSpinner(spinerID));
		(moadlTitle === "divisions"
			? ApiService.setEducationalDivisions({
					educationalUnitId: openModal.id,
					divisionIds: values,
			  })
			: ApiService.setEducationalLecturers({
					educationalUnitId: openModal.id,
					lecturerUserIds: values,
					oldAndNewLecturerUserIds: oldAndNewLecturerUserIds,
			  })
		)
			.then(res => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose();
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	const getReplacementLecturers = item => {
		if (!item || !subjectId) return false;
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		ApiService.getReplacementLecturers(item.id, subjectId)
			.then(response => {
				if (response && response.data && response.data.length) {
					setDisabled(true);
					setDeletedLectureId(item.id);
					setIsWornig(true);
					setReplacementLecturers(response.data);
					// setOldAndNewLecturerUserIds([
					// 	...oldAndNewLecturerUserIds,
					// 	{ oldLecturerUserId: item.id, newLecturerUserId:"" },
					// ]);
				} else {
					const selectedValue = values.filter(el => el !== item.id);
					setValues(selectedValue);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	const getModalData = (
		item,
		moadlTitle = "divisions",
		openModal = true,
		isActiv = true,
	) => {
		if (!item) return;
		setSubjectId(item.id);
		if (openModal) setOpenModal(item);
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		(moadlTitle === "divisions"
			? ApiService.getEducationalDivisions(item.id, isActiv)
			: ApiService.getEducationalLecturers(item.id, isActiv)
		)
			.then(response => {
				if (response.data && response.data.length) {
					setSelectItems(response.data);
					let attachedStudents = response.data
						.filter(
							el =>
								el?.isBelongToCurrentEducationalUnit ||
								el?.isBelongToCurrentEducationalUnit,
						)
						.map(el => el.id);
					setValues(attachedStudents);
				} else {
					setSelectItems([]);
					setValues([]);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};
	const isCheked = (arr = [], deletedLectureId, id) => {
		if (!arr.length) return false;
		const chekedItems = arr.find(
			item => item.oldLecturerUserId === deletedLectureId,
		);
		if (chekedItems) {
			return chekedItems?.newLecturerUserId === id;
		} else {
			return false;
		}
	};
	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={onSave}
					addButtonIsDisabled={!isChange}
					isLarge={true}
					title={
						moadlTitle === "divisions"
							? translationService.translate("TR_ATTACH_DETACH_DIVISIONS")
							: translationService.translate("TR_ATTACH_DETACH_LECTURERS")
					}
					actionButtonTitle={
						selectItems?.length ? translationService.translate("TR_SAVE") : ""
					}>
					{selectItems?.length ? (
						<div className="row">
							<div className="col-12">
								<div className="custom-control custom-switch">
									<div className="d-flex align-items-center">
										<input
											type="checkbox"
											id="save"
											className="custom-control-input"
											checked={isActivStudents}
											onChange={event => {
												setIsActivStudents(event.target.checked);
												getModalData(
													openModal,
													moadlTitle === "divisions" ? "divisions" : "",
													false,
													!event.target.checked,
												);
											}}
										/>
										<label htmlFor="save" className="custom-control-label">
											{translationService.translate(
												"TR_SHOW_ONLY_EDUCATIONAL_UNIT_DIVISIONS",
											)}
										</label>
									</div>
								</div>
								<hr />
							</div>
							{!isActivStudents && selectItems?.length && (
								<div className="col-12">
									<InputCheckBox
										id={"select_all"}
										name={"select_all"}
										checked={values.length === selectItems.length}
										disabled={disabled}
										labelValue={translationService.translate("TR_SELECT_ALL")}
										onChange={event => {
											setIsChange(true);
											if (event.target.checked) {
												const selectedValue = selectItems.map(el => el.id);
												setValues(selectedValue);
											} else {
												setValues([]);
											}
										}}
									/>
								</div>
							)}
							{selectItems &&
								!!selectItems.length &&
								selectItems.map((item, i) => (
									<div className="col-12 col-md-6" key={i}>
										<InputCheckBox
											id={`students_${item.id}`}
											name={`students_${item.id}`}
											disabled={disabled}
											checked={values.filter(el => el === item.id).length}
											labelValue={item?.divisionName || item?.lecturerName}
											labelClassName={
												item.id === deletedLectureId && isWornig
													? "text-danger"
													: ""
											}
											onChange={event => {
												setIsChange(true);
												if (event.target.checked) {
													setValues([...values, item.id]);
												} else {
													if (
														moadlTitle === "lecturers" &&
														item?.isBelongToCurrentEducationalUnit
													) {
														getReplacementLecturers(item);
													} else {
														const selectedValue = values.filter(
															el => el !== item.id,
														);
														setValues(selectedValue);
													}
												}
											}}
										/>
									</div>
								))}

							{replacementLecturers && !!replacementLecturers.length && (
								<div className="col-12">
									<hr />
									<label>Choose other lecturer</label>
									<div className="row">
										{replacementLecturers.map((item, i) => (
											<div className="col-12 col-md-6" key={i}>
												<InputCheckBox
													id={`replacementLecturers_${item.id}`}
													name={`replacementLecturers_${item.id}`}
													checked={isCheked(
														oldAndNewLecturerUserIds,
														deletedLectureId,
														item.id,
													)}
													labelValue={item?.lecturerName}
													onChange={event => {
														if (event.target.checked) {
															const selectedValue = values.filter(
																el => el !== deletedLectureId,
															);
															setSelectItems(
																selectItems.map(item => {
																	if (item.id === deletedLectureId) {
																		return {
																			...item,
																			isBelongToCurrentEducationalUnit: false,
																		};
																	}
																	return item;
																}),
															);
															setIsChange(true);
															setDisabled(false);
															setIsWornig(false);
															setValues(selectedValue);
															setOldAndNewLecturerUserIds([
																...oldAndNewLecturerUserIds,
																{
																	oldLecturerUserId: deletedLectureId,
																	newLecturerUserId: item.id,
																},
															]);
														} else {
															setSelectItems(
																selectItems.map(item => {
																	if (item.id === deletedLectureId) {
																		return {
																			...item,
																			isBelongToCurrentEducationalUnit: true,
																		};
																	}
																	return item;
																}),
															);
															setIsChange(false);
															setDisabled(true);
															setIsWornig(true);
															setValues([...values, deletedLectureId]);

															setOldAndNewLecturerUserIds(
																oldAndNewLecturerUserIds.filter(
																	el =>
																		el.oldLecturerUserId !== deletedLectureId,
																),
															);
														}
													}}
												/>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_EDUCATIONAL_UNIT_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle="Educational units"
						pageName="admin_educational_unit"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{/* {roleService.checkRole("group_create") && ( */}
			<div className="row">
				<div className="col-12">
					<div className=" mb-3 d-flex justify-content-end">
						<Link
							to={`/${language}/educational-unit-form`}
							className="mindalay--btn-default">
							{translationService.translate("TR_CREATEANEDUCATIONALUNIT")}
						</Link>
					</div>
				</div>
			</div>
			{/* )} */}
			{educationalUnits && educationalUnits.length ? (
				<div className="row">
					<div className="col-12 position-relative" style={{ zIndex: 2 }}>
						<EducetionAcardion
							open={onChangeIds}
							ids={ids}
							currentDivision={currentDivision}
							setCurrentDivision={setCurrentDivision}
							data={educationalUnits}
							translationService={translationService}
							roleService={roleService}
							deleteEducationalUnit={deleteEducationalUnit}
							openModal={getModalData}
							setModalTitle={setModalTitle}
							getEducationalLecturers={getEducationalLecturers}
							currentLectures={currentLectures}
							isPending={isPending}
						/>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pagination ? (
				<div className="row mt-3">
					<div className="col-12 mt-4">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(EducationalUnits);

const EducetionAcardion = ({
	data,
	translationService,
	ids = [],
	open,
	background = "white-background",
	roleService,
	openModal,
	deleteEducationalUnit,
	setModalTitle,
	currentDivision,
	setCurrentDivision,
	getEducationalLecturers,
	currentLectures,
	isPending,
}) => {
	const language = useSelector(state => state.language.language);
	if (Array.isArray(data) && !data?.length) return null;

	return (
		data &&
		!!data.length && (
			<div
				className="accordion-wrapper m-0"
				style={{ position: "relative", zIndex: 0 }}>
				<div className="row" style={{ position: "relative", zIndex: 0 }}>
					{data.map((item, i) => {
						return (
							<div
								key={i}
								className={`col-12 ${item?.children ? "" : "mt-2"}`}
								style={{ position: "relative", zIndex: `${0 - i}` }}>
								<div className="card mindalay-card">
									<div
										className="card-header"
										style={{ zIndex: "0 !important" }}>
										<div
											className=""
											aria-controls={`section_${item.id}`}
											aria-expanded={ids.includes(item.id)}
											onClick={() => open(item.id)}>
											<div className="d-flex justify-content-between">
												<h5 className="text-elipsis fz-md w-auto text-initial d-flex">
													{item.name}

													{item.lecturerAttachmentOn && (
														<div className="ml-3">
															<p className="text-warning">
																<b
																	className="mr-2"
																	style={{
																		color: "var(--mindalay--brand-color)",
																	}}>
																	|
																</b>
																{translationService.translate("TR_SUBJECTS")}
															</p>
														</div>
													)}
													{item.divisionAttachmentOn && (
														<div className="ml-3">
															<p className="text-success">
																<b
																	className="mr-2"
																	style={{
																		color: "var(--mindalay--brand-color)",
																	}}>
																	|
																</b>
																{translationService.translate("TR_GROUPS")}
															</p>
														</div>
													)}
												</h5>
												{item.lecturerAttachmentOn ? (
													<>
														<i
															className="fa fa-info-circle transaction-successful text-warning"
															style={{
																fontSize: "25px",
																marginRight: "35px",
															}}
															onClick={event => {
																event.stopPropagation();
																if (
																	!currentLectures ||
																	currentLectures.id !== item.id
																) {
																	if (!isPending) {
																		setCurrentDivision(null);
																		getEducationalLecturers(item);
																	}
																} else {
																	getEducationalLecturers(item, true);
																}
															}}
														/>
													</>
												) : null}
												{item.divisionAttachmentOn ? (
													<>
														{item.divisions && item.divisions ? (
															<i
																className="fa fa-info-circle transaction-successful"
																style={{
																	fontSize: "25px",
																	marginRight: "35px",
																}}
																onClick={event => {
																	event.stopPropagation();
																	if (
																		!currentDivision ||
																		currentDivision.id !== item.id
																	) {
																		getEducationalLecturers(item, true);
																		setCurrentDivision(item);
																	} else {
																		setCurrentDivision(null);
																	}
																}}
															/>
														) : null}
													</>
												) : null}
											</div>
										</div>

										<div className={`dropleft setting-dropdown`}>
											<div
												className="setting-dropdown-btn"
												id={`demo-${item.id}`}
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												<SettingsSvg />
											</div>
											<div
												id={`demo-${item.id}`}
												className="dropdown-menu collapse">
												{!item.lecturerAttachmentOn && (
													<Link
														to={`/${language}/educational-unit-form`}
														className="dropdown-item"
														onClick={() => {
															localStorage.setItem("parentId", item.id);
														}}>
														{translationService.translate("TR_CREATE_CHILD")}
													</Link>
												)}
												{item.lecturerAttachmentOn && (
													<Link
														to="#"
														className="dropdown-item"
														onClick={() => {
															setModalTitle("lecturers");
															openModal(item, "");
														}}>
														{translationService.translate("TR_ATTACH_LECTURER")}
													</Link>
												)}
												{item.divisionAttachmentOn && (
													<Link
														to="#"
														className="dropdown-item"
														onClick={() => {
															openModal(item);
														}}>
														{translationService.translate("TR_ATTACH_DIVISION")}
													</Link>
												)}
												{/* {!!roleService.checkRole("group_update") && ( */}
												<Link
													to={`/${language}/educational-unit-form/${item.id}`}
													className="dropdown-item"
													onClick={() => {}}>
													{translationService.translate("TR_EDIT")}
												</Link>
												{/* )} */}
												{/* {!!roleService.checkRole("group_delete") && ( */}
												<Link
													to="#"
													className="dropdown-item"
													onClick={event => {
														deleteEducationalUnit(item);
														event && event.preventDefault();
													}}>
													{translationService.translate("TR_DELETE")}
												</Link>
												{/* )} */}
											</div>
										</div>
										<div
											className={`border rounded px-3 py-2 d-${
												currentLectures && currentLectures?.id === item.id
													? "block"
													: "none"
											}`}
											style={{
												cursor: "default",
												position: "absolute",
												width: "250px",
												backgroundColor: "#ffffff",
												right: 0,
												zIndex: 2,
												maxHeight: "500px",
												overflowY: "auto",
											}}
											onClick={event => event.stopPropagation()}>
											<ul>
												{currentLectures?.lectures &&
												currentLectures?.lectures?.length ? (
													currentLectures?.lectures.map((_item, _index) => {
														return (
															<li key={_index} className="my-1">
																{!_index ? (
																	<div>
																		<div className="d-flex justify-content-between">
																			<span className="mb-2 d-block">
																				{translationService.translate(
																					"TR_ATTACHED_LECTURERS",
																				)}
																			</span>
																			<i
																				className="fas fa-times cursor-pointer"
																				style={{ fontSize: "20px" }}
																				onClick={() => {
																					setCurrentDivision(null);
																					getEducationalLecturers(null, true);
																				}}></i>
																		</div>
																		<hr className="mb-2 mt-1" />
																	</div>
																) : null}
																<span className="mb-2 d-block">
																	{_index + 1}: {_item.lecturerName}
																</span>
															</li>
														);
													})
												) : (
													<li className="my-1">
														<div className="d-flex justify-content-between">
															<span className="mb-2">
																No attached lecturers yet.
															</span>
															<i
																className="fas fa-times ml-3 cursor-pointer"
																style={{ fontSize: "20px" }}
																onClick={() => {
																	setCurrentDivision(null);
																	getEducationalLecturers(null, true);
																}}></i>
														</div>
													</li>
												)}
											</ul>
										</div>
										<div
											className={`border rounded px-3 py-2 d-${
												currentDivision && currentDivision.id === item.id
													? "block"
													: "none"
											}`}
											style={{
												cursor: "default",
												position: "absolute",
												width: "250px",
												backgroundColor: "#ffffff",
												right: 0,
												zIndex: 2,
												maxHeight: "500px",
												overflowY: "auto",
											}}
											onClick={event => event.stopPropagation()}>
											<ul>
												{item.divisions && item.divisions.length ? (
													item.divisions.map((_item, _index) => {
														return (
															<li key={_index} className="my-1">
																{!_index ? (
																	<div>
																		<div className="d-flex justify-content-between">
																			<span className="mb-2 d-block">
																				{translationService.translate(
																					"TR_ATTACHED_DIVISIONS",
																				)}
																			</span>
																			<i
																				className="fas fa-times cursor-pointer"
																				style={{ fontSize: "20px" }}
																				onClick={() => {
																					setCurrentDivision(null);
																					getEducationalLecturers(item, true);
																				}}></i>
																		</div>
																		<hr className="mb-2 mt-1" />
																	</div>
																) : null}
																<span className="mb-2 d-block">
																	{_index + 1} {_item.name}
																</span>
															</li>
														);
													})
												) : (
													<li className="my-1">
														<div className="d-flex justify-content-between">
															<span className="mb-2">
																No attached divisions yet.
															</span>
															<i
																className="fas fa-times ml-3 cursor-pointer"
																style={{ fontSize: "20px" }}
																onClick={() => {
																	setCurrentDivision(null);
																	getEducationalLecturers(item, true);
																}}></i>
														</div>
													</li>
												)}
											</ul>
										</div>
									</div>

									<Collapse in={ids.includes(item.id)}>
										<div
											id={`topic_${item.id}`}
											className={`${
												background === "white-background"
													? "white-background"
													: "light-blue-background"
											} content-block m-0`}
											style={{ padding: "0 0 0 1.25rem" }}>
											{!!item.description && (
												<div className="my-3 text-muted">
													{item.description}
												</div>
											)}
											{item.children && !!item.children.length && (
												<EducetionAcardion
													open={open}
													ids={ids}
													data={item.children}
													translationService={translationService}
													background={
														background === "white-background"
															? "light-blue-background"
															: "white-background"
													}
													roleService={roleService}
													openModal={openModal}
													deleteEducationalUnit={deleteEducationalUnit}
													setModalTitle={setModalTitle}
													currentDivision={currentDivision}
													setCurrentDivision={setCurrentDivision}
													getEducationalLecturers={getEducationalLecturers}
													currentLectures={currentLectures}
													isPending={isPending}
												/>
											)}
										</div>
									</Collapse>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		)
	);
};
