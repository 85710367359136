import React, { Component } from 'react';
import { connect } from 'react-redux';
import Textarea from '../../../Components/Inputs/textArea';
import InfoSvg from '../../../Components/Svg/infoSvg';
import StarFilled from '../../../Components/Svg/starFilled';
import StarSvg from '../../../Components/Svg/starSvg';
import TranslationService from '../../../Services/translationService';
import { BOOLEAN_KEY, ERROR_KEY, RATINGS, SUCCESS_KEY } from "../../../Constants/mainKeys";
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import ApiService from '../../../Services/apiService';
import AlertService from '../../../Services/alertService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import MainService from '../../../Services/mainService';
import moment from 'moment';
import { addModalSpinner, removeModalSpinner } from './../../../Store/Actions/spinner';
import ModalComponent from './../../../Components/ModalComponent/modalComponent';
import ReactSelectOption from '../../../Components/SelectOption/reactSelectOption';
import ShowMoreText from "react-show-more-text";
import EditSvg from './../../../Components/Svg/editSvg';
import { Link } from 'react-router-dom';
import InputCheckBox from '../../../Components/Inputs/inputCheckBox';

class FeedbackForStudent extends Component {
  ratings = RATINGS;

  state = {
    form: {
      description: "",
      subject: "",
      withRefund: false
    },
    translationService: null,
    rating: 0,
    entitytype: 4,
    feedbackDescription: '',
    subjects: [],
    isInvalidSubmit: false,
    defaultValue: { label: "", value: "" },
    descriptionMaxLength: 2000,
  }

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  onChangeRating = (rating) => {
    this.setState({ rating });
  }

  onChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value, failedFields })
  };

  onDescriptionChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  openModal = () => {
    const spinnerId = uuid();
    this.props.addModalSpinner(spinnerId);
    ApiService.getConsultationTicketSubjects().then(response => {
      this.props.removeModalSpinner(spinnerId);
      const data = { ...response.data };
      data?.libraryData && this.setState({ subjects: [...data.libraryData], isShowModal: true });
    }).catch(error => this.getFail(error, spinnerId));
  }

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      isShowModal: false,
      isInvalidComplaintSubmit: false,
      form: {
        ...prevState.form,
        description: "",
        subject: "",
        withRefund: false
      }
    }))
  }

  onSelectChange = (selectedCategory, name) => {
    if (!selectedCategory || !name) return;
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: selectedCategory.name,
      }
    }));
  }

  makeAcomplaint = (consultationId) => {
    const { translationService } = this.state;
    const spinnerId = uuid();
    const form = { ...this.state.form };
    if (!form.description || !form.description.trim() || !form.subject) {
      this.setState({ isInvalidComplaintSubmit: true });
    } else {
      this.props.addModalSpinner(spinnerId);
      const data = {
        entityType: 7,
        entityId: consultationId,
        description: form.description.trim(),
        subject: form.subject,
        withRefund: form.withRefund
      }
      ApiService.makeAcomplaint(data).then(response => {
        if (response.data) {
          this.props.changeComplaintData(response.data);
        }
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_TICKET_SUCCESSFULLY_SENT"));
        this.props.removeModalSpinner(spinnerId);
        this.closeModal();
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  onCheckboxChange = event => {
    typeof event.target.checked === BOOLEAN_KEY && this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.checked
      }
    }));
  }

  onSubmit = (event) => {
    const { feedBackDataId } = this.props;
    event.preventDefault();
    const spinnerId = uuid();
    const { contract } = this.props;
    var { rating, feedbackDescription, entitytype } = this.state;
    if (!feedbackDescription || !feedbackDescription.trim() || !rating) {
      this.setState({ isInvalidSubmit: true })
    } else {
      const data = {
        entitytype,
        // entityid: contract.consultantId, //before
        entityid: contract.consultation.id, //after
        title: contract.consultation.name,
        reviewText: feedbackDescription.trim(),
        rating,
        id: feedBackDataId
      }
      if (feedBackDataId) {
        for (const i in data) {
          if (i === "title") delete data[i];
          if (i === "entityid") delete data[i];
          if (i === "entitytype") delete data[i];
        }
      } else { delete data.id }
      this.props.addPageSpinner(spinnerId);
      (!feedBackDataId ? ApiService.sendFeedBackFromStudent(data) : ApiService.updateFeedBackFromStudent(data)).then(() => {
        this.props.removePageSpinner(spinnerId);
        this.props.getContractById(contract.id);
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  annulFeedbackData = () => {
    const { feedBackData } = this.props;
    this.setState({
      feedbackDescription: feedBackData.reviewText,
      rating: feedBackData.rating
    }, () => {
      this.props.annulFeedbackData();
    })
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    spinnerId && this.props.removeModalSpinner(spinnerId);
  }

  render() {
    const { feedBackData, user, contract, feedBackDataId, complaintData } = this.props;
    const { description, subject, withRefund } = this.state.form;
    const {
      translationService,
      rating,
      feedbackDescription,
      isInvalidSubmit,
      isShowModal,
      isInvalidComplaintSubmit,
      failedFields,
      defaultValue,
      subjects,
      descriptionMaxLength
    } = this.state;

    return (
      translationService && user ?
        <Auxiliary>
          {
            isShowModal ?
              <ModalComponent
                title={translationService.translate("TR_OPEN_A_TICKET")}
                isLarge={true}
                cancel={this.closeModal}
                contentClassName='library-modal'
                aria-labelledby="example-modal-sizes-title-lg"
                actionButtonTitle={translationService.translate("TR_SUBMIT")}
                onSubmit={() => this.makeAcomplaint(contract.consultation.id)}
              >
                <form>
                  <div className="row p-3">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          <Textarea
                            rows="4"
                            id="description"
                            name="description"
                            textAreaClassName="pr--5"
                            max={descriptionMaxLength}
                            value={description}
                            isInvalidField={isInvalidComplaintSubmit && !description.trim() ? true : false}
                            labelValue={`${translationService.translate("TR_DESCRIPTION")}*`}
                            onChange={event => this.onDescriptionChange(event, descriptionMaxLength)}
                            isInvalidSubmit={isInvalidComplaintSubmit}
                            failedFields={failedFields}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <label>{`${translationService.translate("TR_COMPLAINT_TYPE")} *`}</label>
                          <ReactSelectOption
                            defaultValue={defaultValue}
                            value={subject ? true : false}
                            selectedValue={(() => {
                              const selectedValue = { ...subjects.find(data => data.name === subject) };
                              if (selectedValue) {
                                selectedValue.label = selectedValue.displayName;
                                selectedValue.value = selectedValue.name;
                              }
                              return selectedValue;
                            })()}
                            isInvalidSubmit={isInvalidComplaintSubmit}
                            items={subjects.map(data => ({ ...data, label: data.displayName, value: data.name }))}
                            onChange={item => this.onSelectChange(item, "subject")}
                            failedFields={failedFields}
                          />
                        </div>
                      </div>
                      {
                        complaintData && complaintData.canRequestRefund ?
                          <div className="row">
                            <div className="col-12 mt-3">
                              <InputCheckBox
                                id="withRefund"
                                name="withRefund"
                                checked={withRefund}
                                labelValue={translationService.translate("TR_REQUEST_A_REFUND")}
                                onChange={this.onCheckboxChange}
                                failedFields={failedFields}
                              />
                            </div>
                          </div>
                          : <div className="row">
                            <div className="col-12 mt-3">
                              <p>{translationService.translate("TR_REFUND_REQUEST_SENT")}</p>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </form>
              </ModalComponent>
              : null
          }
          {
            !feedBackData ?
              <div className="tab-pane" id="consultation-contract--feedback-tab" aria-labelledby="consultation-contract--feedback-tab">
                <div className="consultation-feeback-wrapper">
                  <p className="flex-1 mb-3 light-blue-background p-3 blue-color blue-border mindalay--information">
                    <InfoSvg />{translationService.translate("TR_BEFORE_YOU_RATE_INFO")}
                  </p>
                  <form>
                    <div className="rating-process-container d-flex">
                      {
                        this.ratings && this.ratings.map(r => {
                          if (rating < r) {
                            return <div key={r} onClick={() => this.onChangeRating(r)}><StarSvg /></div>
                          } else {
                            return <div key={r} onClick={() => this.onChangeRating(r)}><StarFilled /></div>
                          }
                        })
                      }
                    </div>
                    {
                      isInvalidSubmit && !rating ?
                        <small className="small-error-msg d-block text-center red">{translationService.translate("TR_RATE_THE_CONSULTANT")}</small>
                        : null
                    }
                    <Textarea
                      id="feedbackDescription"
                      rows="5"
                      name="feedbackDescription"
                      value={feedbackDescription}
                      max={descriptionMaxLength}
                      placeholder={translationService.translate("TR_TYPE_YOUR_FEEDBACK")}
                      isInvalidField={isInvalidSubmit && !feedbackDescription.trim() ? true : false}
                      labelValue={translationService.translate("TR_FEEDBACK")}
                      isInvalidSubmit={isInvalidSubmit}
                      onChange={(event) => this.onChange(event, descriptionMaxLength)}
                    />
                    <div className="text-right">
                      <button type="submit" className="mindalay--btn-default" onClick={this.onSubmit}>
                        {translationService.translate(!feedBackDataId ? "TR_SUBMIT_FEEDBACK" : "TR_UPDATE_FEEDBACK")}</button>
                      {
                        feedBackDataId ? <button
                          type="submit"
                          className="mindalay--btn-default ml-2"
                          onClick={() => this.props.cancelUpdateFeedback()}
                        >
                          {translationService.translate("TR_CANCEL")}
                        </button> : null
                      }
                    </div>
                  </form>
                </div>
              </div>
              :
              <div className="course-feedback p-4">
                <div className="feedback-wrapper">
                  <p className="title">{feedBackData.title}</p>
                  <div className="d-flex">
                    <div style={{ backgroundImage: `url(${feedBackData.reviewerAvatarFilePath})` }} className="image-wrapper" ></div>
                    <div className="feedback-body">
                      <div className="d-flex align-items-center">
                        <h4 className="feedback-usernam">{feedBackData.reviewerName}</h4>
                        {
                          !feedBackData.isApproved && !feedBackData.isDeclined ?
                            <div className="info-title d-inline-block ml-1">
                              <div>
                                <InfoSvg />
                                <span style={{ display: "none" }}>
                                  {translationService.translate("TR_REVIEW_TEXT_INFO")}
                                </span>
                              </div>
                            </div> : null
                        }
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="rating"><div style={{ width: `${feedBackData.rating * 20}%` }}></div></div>
                        <p className="ml-2">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBackData.createdOn))).format("MMM DD, H:mm")}</p>
                        {
                          feedBackData.isDeclined ?
                            <div className="table-action-btn-group d-flex align-items-center ml-2 ">
                              <Link
                                to="#"
                                className="edit-btn table-action-btn-mini-svg"
                                onClick={this.annulFeedbackData}
                              >
                                <EditSvg />
                              </Link>
                            </div>
                            : null
                        }
                      </div>
                      <div className="mt-3">
                        <ShowMoreText
                          lines={2}
                          more={translationService.translate("TR_SHOW_MORE")}
                          less={translationService.translate("TR_LESS")}
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <p>{feedBackData.reviewText}</p>
                        </ShowMoreText>
                      </div>
                    </div>
                  </div>
                  {
                    feedBackData.replyText ?
                      <div className="feedback-reply-body">
                        <div className="d-flex">
                          <div style={{ backgroundImage: `url(${feedBackData.replierAvatarFilePath})` }} className="image-wrapper" ></div>
                          <div className="feedback-body">
                            <h4 className="feedback-usernam">{feedBackData.replierName}</h4>
                            <div className="d-flex align-items-center">
                              <p className="ml-2">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBackData.createdOn))).format("MMM DD, H:mm")}</p>
                            </div>
                            <div className="mt-3">
                              <ShowMoreText
                                lines={2}
                                more={translationService.translate("TR_SHOW_MORE")}
                                less={translationService.translate("TR_LESS")}
                                className="content-css"
                                anchorClass="my-anchor-css-class"
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                <p className='word-break-break-word'>{feedBackData.replyText}</p>
                              </ShowMoreText>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null
                  }
                </div>
              </div>
          }
          {
            !complaintData.refunded
              ? <Auxiliary>
                <hr />
                {
                  <div className="text-right px-4 pb-3">
                    <button
                      type="button"
                      className="mindalay--btn-secondary mindalay--btn-small"
                      onClick={() => this.openModal(contract)}>
                      {translationService.translate("TR_OPEN_A_TICKET")}
                    </button>
                  </div>
                }
              </Auxiliary>
              : null
          }
        </Auxiliary>
        : null

    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  addModalSpinner,
  removeModalSpinner
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForStudent);

